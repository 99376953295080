import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Hide as HideV1 } from '@jane/shared/reefer-v1';
import { Hide as HideV2 } from '@jane/shared/reefer-v2';
import type { HideProps } from '@jane/shared/reefer-v2';

export const Hide = (props: HideProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HideV2 {...props} />;
  }
  return <HideV1 {...props} />;
};

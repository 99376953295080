import styled from '@emotion/styled';
import type { Row } from '@tanstack/react-table';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { SpecialsTableContext } from '@jane/business-admin/providers';
import type { AbbreviatedSpecialV2 } from '@jane/business-admin/types';
import {
  Box,
  CheckboxField,
  Flex,
  Link,
  SwitchField,
  Typography,
} from '@jane/shared/reefer';

const CellContents = styled(Flex)({
  minWidth: 400,
  maxWidth: 430,
  wordWrap: 'break-word',
  whiteSpace: 'normal',
});

const CellLabel = styled(Typography)({
  '@supports (-webkit-line-clamp: 2)': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface SpecialCellProps {
  onChangeEnabled?: () => void;
  onToggleSelected: React.MouseEventHandler<HTMLInputElement>;
  row: Row<AbbreviatedSpecialV2>;
  showToggle?: boolean;
}
export const SpecialCell = ({
  row,
  onToggleSelected,
  showToggle = true,
  onChangeEnabled,
}: SpecialCellProps) => {
  const { pathname } = useLocation();
  const url = `${pathname}/${row.original.id}`;
  const { bulkEditModalOpen } = useContext(SpecialsTableContext);

  return (
    <Flex alignItems="center" justifyContent="space-between" maxWidth={400}>
      <CellContents alignItems="center">
        {bulkEditModalOpen ? (
          <Box pr={24}>
            <CheckboxField
              label={`${row.original.id}-checkbox`}
              name={`${row.original.id}-checkbox`}
              labelHidden
              checked={row.getIsSelected()}
              onClick={onToggleSelected}
            />
          </Box>
        ) : null}
        {!bulkEditModalOpen && showToggle && onChangeEnabled ? (
          <Box pr={0}>
            <SwitchField
              defaultChecked={!!row.original.enabled}
              name={`${row.original.id}-enabled`}
              label="Enabled?"
              labelHidden
              onClick={(evt) => {
                onChangeEnabled();
                evt.stopPropagation();
              }}
            />
          </Box>
        ) : null}
        <Flex flexDirection="column" alignItems="flex-start">
          <Link
            ariaLabel={`View details for '${row.original.title}'`}
            to={bulkEditModalOpen ? '' : url}
            onClick={(event) => {
              // In bulk edit mode, allow propagation so row checkbox is selected
              if (!bulkEditModalOpen) {
                event.stopPropagation();
              }
            }}
            variant="minimal"
          >
            <CellLabel variant="body-bold" mx={24} title={row.original.title}>
              {row.original.title}
            </CellLabel>
            <CellLabel variant="body" color="grays-mid" mx={24}>
              {row.original.description}
            </CellLabel>
          </Link>
        </Flex>
      </CellContents>
    </Flex>
  );
};

import { useFormContext } from 'react-hook-form';

import { Box } from '../box';
import { Form } from '../forms';
import { SearchFieldInternal } from '../forms/fields/searchField';
import { Popover } from '../popover';
import { StyledSearchField } from './typeAhead.styles';
import type { TypeAheadProps } from './typeAhead.types';
import { TypeAheadContext } from './typeAheadContext';
import { TypeAheadResults } from './typeAheadResults/typeAheadResults';

/**
 * The TypeAhead component combines the searchField and popover to create a reusable search/filter ui.
 */

export function TypeAhead({
  alignment = { horizontal: 'left', vertical: 'bottom' },
  ariaLabel,
  className,
  customOptions,
  'data-testid': testId,
  debounceDelay,
  formName,
  hideDeselect = false,
  hideSearchIcon = false,
  id,
  isDebounced,
  labelHidden,
  listAriaLabel,
  loading,
  onBlur,
  onChange,
  onPopoverClose,
  onSelection,
  options = [],
  optionType = 'button',
  popoverFooter,
  popoverTarget,
  preResultsText,
  query = '',
  selection = [],
  style,
  width,
  ...spacingProps
}: TypeAheadProps) {
  const methods = useFormContext();

  const handleSelection = (value: string | string[]) => {
    onSelection && onSelection(value);
    formName && methods && methods.setValue(formName, value);
  };

  const handlePopoverClose = () => {
    onPopoverClose && onPopoverClose();
  };

  return (
    <TypeAheadContext.Provider
      value={{
        listAriaLabel,
        loading,
        onSelection: handleSelection,
        optionType,
        options,
        query,
        selection,
        width,
      }}
    >
      {popoverTarget ? (
        <Popover
          alignment={alignment}
          className={className}
          id={id}
          label={ariaLabel}
          target={popoverTarget}
          data-testid={testId}
          style={style}
          {...spacingProps}
        >
          <Box pt={16}>
            <StyledSearchField>
              {formName ? (
                <Form.SearchField
                  defaultValue={query}
                  debounceDelay={debounceDelay}
                  isDebounced={isDebounced}
                  label={ariaLabel}
                  labelHidden={labelHidden}
                  name={formName}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              ) : (
                <SearchFieldInternal
                  value={query}
                  defaultValue={query}
                  debounceDelay={debounceDelay}
                  hideSearchIcon={hideSearchIcon}
                  isDebounced={isDebounced}
                  label={ariaLabel}
                  labelHidden={labelHidden ?? false}
                  name="Typeahead Search"
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            </StyledSearchField>
          </Box>
          <Box py={16}>
            {customOptions ? (
              customOptions
            ) : (
              <TypeAheadResults
                hideDeselect={hideDeselect}
                popoverFooter={popoverFooter}
                preResultsText={preResultsText}
              />
            )}
          </Box>
        </Popover>
      ) : (
        <Popover
          alignment={alignment}
          className={className}
          closeOnTargetClick={false}
          id={id}
          onClose={handlePopoverClose}
          label={ariaLabel}
          targetWidth="100%"
          target={
            formName ? (
              <Form.SearchField
                defaultValue={query}
                debounceDelay={debounceDelay}
                isDebounced={isDebounced}
                label={ariaLabel}
                labelHidden={labelHidden}
                name={formName}
                onBlur={onBlur}
                onChange={onChange}
              />
            ) : (
              <SearchFieldInternal
                value={query}
                defaultValue={query}
                debounceDelay={debounceDelay}
                hideSearchIcon={hideSearchIcon}
                isDebounced={isDebounced}
                label={ariaLabel}
                labelHidden={labelHidden ?? false}
                name="Typeahead Search"
                onBlur={onBlur}
                onChange={onChange}
                width={width}
              />
            )
          }
          data-testid={testId}
          {...spacingProps}
        >
          <Box py={16}>
            {customOptions ? (
              customOptions
            ) : (
              <TypeAheadResults
                hideDeselect={hideDeselect}
                popoverFooter={popoverFooter}
                preResultsText={preResultsText}
              />
            )}
          </Box>
        </Popover>
      )}
    </TypeAheadContext.Provider>
  );
}

import type {
  DeepReadonly,
  MenuProduct,
  PendingCartProduct,
  PriceId,
  ReservationCartProduct,
  StoreSpecial,
} from '@jane/shared/models';
import { defaultWeightIdForMenuProduct } from '@jane/shared/util';

interface InitialPriceIdArgs {
  appliedWeightFilter?: PriceId | '';
  cartProduct?:
    | DeepReadonly<PendingCartProduct>
    | DeepReadonly<ReservationCartProduct>;
  menuProduct: MenuProduct;
  sortedByPrice?: boolean;
  special?: StoreSpecial;
}
export default function initialPriceIdForProduct({
  menuProduct,
  cartProduct,
  special,
  appliedWeightFilter,
  sortedByPrice,
}: InitialPriceIdArgs): PriceId {
  if (cartProduct) {
    return cartProduct.price_id;
  }

  return defaultWeightIdForMenuProduct({
    menuProduct,
    special,
    appliedWeightFilter,
    sortedByPrice,
  });
}

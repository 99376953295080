import styled from '@emotion/styled';
import type { ReactElement } from 'react';

import type { MarginProperties } from '../../styling';
import { spacing } from '../../styling';
import type { ColorKey } from '../../types';
import {
  getColorValue,
  textColorForBackgroundColor,
} from '../../utils/componentStyles';
import { Flex } from '../flex';
import type { IconProps } from '../icon';

export type TagSizes = 'sm' | 'lg';

export interface StyledTagProps extends MarginProperties {
  background: ColorKey;
  color?: ColorKey;
  endIcon?: ReactElement<IconProps>;
  icon?: ReactElement<IconProps>;
  size: TagSizes;
}
export const StyledTag = styled(Flex)<StyledTagProps>(
  ({
    background = 'grays-mid',
    color,
    endIcon,
    icon,
    size,
    theme,
    ...marginProps
  }) => ({
    background: getColorValue(background, theme),
    borderRadius: size === 'sm' ? theme.borderRadius.sm : theme.borderRadius.lg,
    color: color
      ? getColorValue(color, theme)
      : textColorForBackgroundColor(background, theme),
    height: 'fit-content',
    width: 'fit-content',
    ...spacing({
      pl: ((size === 'sm' ? 8 : 16) / (icon ? 2 : 1)) as 16 | 8 | 4,
      pr: ((size === 'sm' ? 8 : 16) / (endIcon ? 2 : 1)) as 16 | 8 | 4,
      py: size === 'sm' ? 0 : 8,
      ...marginProps,
    }),
    // Fix bug where tag height is too small on mobile for small sized tag
    '> p': {
      lineHeight: '24px',
    },
  })
);

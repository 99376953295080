/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Cbd16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.72688 12.814C8.01621 12.814 7.37054 12.645 6.78988 12.307C6.20921 11.969 5.74554 11.475 5.39888 10.825C5.06088 10.1663 4.89188 9.369 4.89188 8.433C4.89188 7.50567 5.06521 6.71267 5.41188 6.054C5.76721 5.38667 6.23954 4.87967 6.82888 4.533C7.41821 4.17767 8.06821 4 8.77888 4C9.31621 4 9.79288 4.10833 10.2089 4.325C10.6249 4.54167 10.9672 4.793 11.2359 5.079L10.4299 6.054C10.2132 5.82867 9.97054 5.651 9.70188 5.521C9.44188 5.38233 9.14721 5.313 8.81788 5.313C8.35854 5.313 7.94688 5.43867 7.58288 5.69C7.22754 5.93267 6.94588 6.28367 6.73788 6.743C6.53854 7.20233 6.43888 7.75267 6.43888 8.394C6.43888 9.36467 6.65121 10.1273 7.07588 10.682C7.50054 11.228 8.06821 11.501 8.77888 11.501C9.16021 11.501 9.49821 11.423 9.79288 11.267C10.0875 11.1023 10.3562 10.89 10.5989 10.63L11.4049 11.579C11.0582 11.9777 10.6595 12.2853 10.2089 12.502C9.76688 12.71 9.27288 12.814 8.72688 12.814Z"
      fill="#666666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V4C15 2.34315 13.6569 1 12 1ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z"
      fill="#666666"
    />
  </svg>
);

const Cbd24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={22}
      height={22}
      rx={3}
      stroke="#0E0E0E"
      strokeWidth={2}
    />
    <path
      d="M13.3387 17.18C12.5187 17.18 11.7737 16.985 11.1037 16.595C10.4337 16.205 9.89871 15.635 9.49871 14.885C9.10871 14.125 8.91371 13.205 8.91371 12.125C8.91371 11.055 9.11371 10.14 9.51371 9.38C9.92371 8.61 10.4687 8.025 11.1487 7.625C11.8287 7.215 12.5787 7.01 13.3987 7.01C14.0187 7.01 14.5687 7.135 15.0487 7.385C15.5287 7.635 15.9237 7.925 16.2337 8.255L15.3037 9.38C15.0537 9.12 14.7737 8.915 14.4637 8.765C14.1637 8.605 13.8237 8.525 13.4437 8.525C12.9137 8.525 12.4387 8.67 12.0187 8.96C11.6087 9.24 11.2837 9.645 11.0437 10.175C10.8137 10.705 10.6987 11.34 10.6987 12.08C10.6987 13.2 10.9437 14.08 11.4337 14.72C11.9237 15.35 12.5787 15.665 13.3987 15.665C13.8387 15.665 14.2287 15.575 14.5687 15.395C14.9087 15.205 15.2187 14.96 15.4987 14.66L16.4287 15.755C16.0287 16.215 15.5687 16.57 15.0487 16.82C14.5387 17.06 13.9687 17.18 13.3387 17.18Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  sm: Cbd16,
  md: Cbd24,
  lg: Cbd24,
  xl: Cbd24,
  xxl: Cbd24,
  xxxl: Cbd24,
};

export const CbdIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'cbd-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

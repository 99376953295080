import { useEffect, useMemo } from 'react';

import {
  useCreateStoreBoostConfig,
  useUpdateStoreBoostConfig,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type { BoostConfig } from '@jane/business-admin/types';
import {
  ModalNames,
  parseInvalidTemplateUrlError,
  parseValidationErrors,
} from '@jane/business-admin/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Button,
  Form,
  FormValidationError,
  Modal,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { BoostSubdomainConfigModalContent } from './BoostSubdomainConfigModalContent';
import { BoostWordpressConfigModalContent } from './BoostWordpressConfigModalContent';

const FORM_ERROR_NAME = 'boost-config-error';

const SHARED_KEYS = [
  'custom_product_detail_title_suffix',
  'disable_jane_title_and_description_meta',
  'favicon_url',
  'google_sso_client_id',
  'google_sso_client_secret',
  'integration_method',
  'show_age_gate',
  'enabled',
];

const WORDPRESS_KEYS = [
  'footer',
  'header',
  'page_head',
  'partner_menu_url',
  ...SHARED_KEYS,
];

const SUBDOMAIN_KEYS = [
  'content_selector',
  'google_search_console_api_key',
  'google_search_console_api_secret',
  'host',
  'path',
  'preview_template_html',
  'preview_template_url',
  'published_template_html',
  'published_template_url',
  ...SHARED_KEYS,
];

const createErrorMessage = ({ isCreateMode }: { isCreateMode: boolean }) =>
  `Error ${
    isCreateMode ? 'creating' : 'updating'
  } Boost config. Please try again.`;

export const BoostConfigModal = ({
  open,
  setOpen,
  subtitle,
  boostConfig,
  storeId,
  onDelete,
}: {
  boostConfig?: Partial<BoostConfig>;
  onDelete: (configId: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  storeId: number;
  subtitle: string;
}) => {
  const hasBoostSubdomainAccess = useFlag(FLAGS.scUseNewBoostTables);
  const showBoostSubdomainOption = useFlag(FLAGS.scShowBoostSubdomainOption);

  const isCreateMode = !boostConfig;
  const catchSubmitErrors = useCatchErrorsWithManager(
    createErrorMessage({ isCreateMode })
  );

  const toast = useToast();
  const formName = 'Boost config form';
  const formMethods = useForm({
    defaultValues: {
      enabled: isCreateMode ? true : boostConfig.enabled || true,
      integration_method: isCreateMode
        ? 'wordpress'
        : boostConfig?.integration_method || 'wordpress',
      show_age_gate: isCreateMode ? false : boostConfig?.show_age_gate || false,
      partner_menu_url: isCreateMode ? '' : boostConfig?.partner_menu_url || '',
      favicon_url: isCreateMode ? '' : boostConfig?.favicon_url || '',
      page_head: isCreateMode ? '' : boostConfig?.page_head || '',
      header: isCreateMode ? '' : boostConfig?.header || '',
      footer: isCreateMode ? '' : boostConfig?.footer || '',
      template_overrides: isCreateMode
        ? false
        : !!boostConfig?.content_selector,
      disable_jane_title_and_description_meta: isCreateMode
        ? false
        : boostConfig?.disable_jane_title_and_description_meta || false,
      custom_product_detail_title_suffix: isCreateMode
        ? ''
        : boostConfig?.custom_product_detail_title_suffix || '',
      google_sso_client_id: isCreateMode
        ? ''
        : boostConfig?.google_sso_client_id || '',
      google_sso_client_secret: isCreateMode
        ? ''
        : boostConfig?.google_sso_client_secret || '',
      google_search_console_api_key: isCreateMode
        ? ''
        : boostConfig?.google_search_console_api_key,
      google_search_console_api_secret: isCreateMode
        ? ''
        : boostConfig?.google_search_console_api_secret,
      preview_template_url: boostConfig?.preview_template_url,
      preview_template_html: boostConfig?.preview_template_html,
      published_template_url: boostConfig?.published_template_url,
      published_template_html: boostConfig?.published_template_html,
      host: boostConfig?.host,
      path: boostConfig?.path,
      content_selector: boostConfig?.content_selector,
    },
  });
  const {
    watch,
    formState: { isDirty },
  } = formMethods;

  const {
    mutateAsync: updateBoostConfig,
    isSuccess: isUpdateSuccess,
    isLoading: isSavingUpdate,
  } = useUpdateStoreBoostConfig(
    storeId.toString(),
    boostConfig?.id?.toString() ?? ''
  );

  const {
    mutateAsync: createBoostConfig,
    isSuccess: isCreateSuccess,
    isLoading: isSavingCreate,
  } = useCreateStoreBoostConfig(storeId.toString());

  const integrationMethod = watch('integration_method');

  const isSuccess = useMemo(
    () =>
      (isCreateMode && isCreateSuccess) || (!isCreateMode && isUpdateSuccess),
    [isUpdateSuccess, isCreateSuccess, isCreateMode]
  );

  useEffect(() => {
    if (isSuccess) {
      toast.add({
        label: `Boost config ${isCreateMode ? 'created' : 'updated'}`,
        variant: 'success',
      });
      setOpen(false);
    }
  }, [isSuccess, setOpen]);

  const formatData = (formData: BoostConfig, integrationMethod: string) => {
    if (!showBoostSubdomainOption) {
      formData['integration_method'] = 'wordpress';
    }

    if (integrationMethod === 'subdomain' && !formData['content_selector']) {
      formData['content_selector'] = '#app';
    }

    if (
      integrationMethod === 'wordpress' &&
      formData['partner_menu_url']?.endsWith('/')
    ) {
      formData['partner_menu_url'] = formData['partner_menu_url'].slice(
        0,
        formData['partner_menu_url'].length - 1
      );
    }

    const keys = Object.keys(formData);
    const filtered =
      integrationMethod === 'wordpress'
        ? keys.filter((key) => WORDPRESS_KEYS.includes(key))
        : keys.filter((key) => SUBDOMAIN_KEYS.includes(key));

    const reduced: BoostConfig = filtered.reduce((obj, key) => {
      // @ts-expect-error - for the life of me cannot make `obj[key]` happy - it works as expected though.
      obj[key] = formData[key as keyof typeof formData];
      return obj;
    }, {} as BoostConfig);

    const encodedData =
      integrationMethod === 'wordpress'
        ? {
            ...reduced,
            header: formData.header || '',
            footer: formData.footer || '',
            page_head: formData.page_head || '',
          }
        : {
            ...reduced,
          };

    return encodedData;
  };

  const onSubmit = (formData: BoostConfig) => {
    const requestData = formatData(formData, formData['integration_method']);

    const submitMethod = () =>
      isCreateMode
        ? createBoostConfig(requestData)
        : updateBoostConfig(requestData);

    return catchSubmitErrors({
      submitMethod,
      requestData,
      callback: (validationErrors) => {
        const customValidationErrors =
          parseInvalidTemplateUrlError(validationErrors);
        if (customValidationErrors) {
          throw new FormValidationError(
            FORM_ERROR_NAME,
            customValidationErrors
          );
        } else {
          throw new Error(createErrorMessage({ isCreateMode }));
        }
      },
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  return (
    <ConfirmDiscardWrapper open={open} setOpen={setOpen} hasChanges={isDirty}>
      <Form.BaseForm
        name={formName}
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={
            boostConfig ? ModalNames.EditBoostConfig : ModalNames.AddBoostConfig
          }
          subtitle={subtitle}
          actions={
            <>
              {!isCreateMode && (
                <Button
                  label="Delete"
                  variant="destructive"
                  onClick={() => {
                    onDelete && onDelete(boostConfig?.id || 0);
                  }}
                  mr={16}
                />
              )}
              <Form.SubmitButton
                variant="primary"
                label="Save"
                loading={isSavingCreate || isSavingUpdate}
              />
            </>
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          {hasBoostSubdomainAccess ? (
            <>
              {showBoostSubdomainOption ? (
                <>
                  <Form.SelectField
                    label="Configuration type"
                    name="integration_method"
                    options={[
                      { label: 'WordPress', value: 'wordpress' },
                      { label: 'Subdomain', value: 'subdomain' },
                    ]}
                    required
                    width="50%"
                    mb={16}
                    disabled={!isCreateMode}
                  />
                  <Modal.ContentDivider />
                </>
              ) : null}
              {integrationMethod === 'wordpress' && (
                <BoostWordpressConfigModalContent />
              )}
              {integrationMethod === 'subdomain' && (
                <BoostSubdomainConfigModalContent isCreateMode={isCreateMode} />
              )}
            </>
          ) : (
            <BoostWordpressConfigModalContent />
          )}
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmDiscardWrapper>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Shape24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2366 1.04512C12.2881 0.281185 13.7119 0.281185 14.7633 1.04512L23.8249 7.62871C24.8763 8.39264 25.3163 9.74674 24.9147 10.9828L21.4535 21.6353C21.0519 22.8714 19.9 23.7082 18.6003 23.7082H7.39964C6.09996 23.7082 4.94809 22.8714 4.54647 21.6353L1.08527 10.9828C0.683645 9.74674 1.12362 8.39264 2.17508 7.62871L11.2366 1.04512ZM13.5878 2.66315C13.2373 2.40851 12.7627 2.40851 12.4122 2.66315L3.35065 9.24674C3.00016 9.50139 2.85351 9.95275 2.98738 10.3648L6.44858 21.0173C6.58245 21.4293 6.96641 21.7082 7.39964 21.7082H18.6003C19.0335 21.7082 19.4175 21.4293 19.5514 21.0173L23.0126 10.3648C23.1464 9.95275 22.9998 9.50139 22.6493 9.24674L13.5878 2.66315Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Shape24,
  sm: Shape24,
  lg: Shape24,
  xl: Shape24,
  xxl: Shape24,
  xxxl: Shape24,
};

export const ShapeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'shape-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Pencil24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 16L12 6L18 12L8 22L2 16Z" fill="#FFC220" />
    <path d="M12 6L15 3L21 9L18 12L12 6Z" fill="#DADADA" />
    <path
      d="M15 3L16.5858 1.41421C17.3668 0.633165 18.6332 0.633166 19.4142 1.41421L22.5858 4.58579C23.3668 5.36684 23.3668 6.63317 22.5858 7.41421L21 9L15 3Z"
      fill="#FF6334"
    />
    <path d="M0 24L2 16H4V18H6V20H8V22L0 24Z" fill="#FFC220" />
    <path d="M0 24L2 16H4V18H6V20H8V22L0 24Z" fill="white" fillOpacity={0.8} />
    <path d="M0 24L1 20L4 23L0 24Z" fill="#0E0E0E" />
  </svg>
);

const SIZE_MAP = {
  md: Pencil24,
  sm: Pencil24,
  lg: Pencil24,
  xl: Pencil24,
  xxl: Pencil24,
  xxxl: Pencil24,
};

export const PencilIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pencil-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import clsx from 'clsx';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import type { CSSProperties, ChangeEvent, FocusEvent } from 'react';

import checkSmallIconSVG from '../../../../../assets/icons/dynamic/check-16.svg';
import dashIconSVG from '../../../../../assets/icons/dynamic/subtract-16.svg';
import { Box } from '../../../box';
import type { CheckboxFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import styles from './checkboxField.module.css';

export interface CheckboxFieldPropsInternal extends CheckboxFieldProps {
  externalChecked?: boolean;
  onUpdate?: (value: boolean) => void;
}

export const CheckboxFieldInternal = forwardRef<
  HTMLInputElement,
  CheckboxFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      checked: checkedProp,
      children,
      className,
      'data-testid': testId,
      defaultChecked = false,
      disabled = false,
      externalChecked,
      helperText,
      id,
      indeterminate = false,
      label,
      labelHidden = false,
      name,
      onBlur,
      onChange,
      onChangeIndeterminate,
      onClick,
      onUpdate,
      width = 'auto',
      ...props
    },
    forwardedRef
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(
      forwardedRef,
      () => innerRef.current as HTMLInputElement
    );

    const [checked, setChecked] = useState(checkedProp || defaultChecked);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onBlur && onBlur(event.target.checked);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onChange && onChange(event.target.checked);
      event.stopPropagation();
    };

    /**
     * These `useEffect` hooks are used only for checkbox groups, where a parent checkbox
     * controls a set of child checkboxes.
     * The parent enters the indeterminate state when only some of its children are checked,
     * necessitating the addition of the first `useEffect` hook.
     * The children's checked state can be controlled by checking, or unchecking, the parent,
     * necessitating the addition of the second `useEffect` hook.
     */
    useEffect(() => {
      if (
        innerRef.current &&
        innerRef.current.indeterminate !== indeterminate
      ) {
        innerRef.current.indeterminate = indeterminate;
        onChangeIndeterminate && onChangeIndeterminate(indeterminate);
        innerRef.current.checked = indeterminate;
        setChecked(indeterminate);
      }
    }, [indeterminate, onChangeIndeterminate]);

    useEffect(() => {
      if (
        typeof checkedProp === 'boolean' &&
        checkedProp !== checked &&
        !indeterminate &&
        innerRef.current
      ) {
        innerRef.current.checked = checkedProp;
        setChecked(checkedProp);
        onChange && onChange(checkedProp);
      }
    }, [checkedProp, checked, indeterminate, onChange]);

    useEffect(() => {
      if (
        externalChecked !== undefined &&
        externalChecked !== checked &&
        checkedProp === undefined
      ) {
        setChecked(externalChecked);
        onUpdate && onUpdate(externalChecked);
      }
    }, [externalChecked, onUpdate, checked, checkedProp]);

    return (
      <FieldWrapper
        className={className}
        disabled={disabled}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        labelPosition="r"
        name={name}
        render={(renderProps) => (
          <Box height="24px" position="relative">
            <input
              className={clsx(styles.checkboxField_input)}
              autoFocus={autoFocus}
              checked={checked}
              disabled={disabled}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              onClick={onClick}
              ref={innerRef}
              type="checkbox"
              {...renderProps}
            />
            <div
              role="presentation"
              style={
                {
                  '--checked-icon': `url(${checkSmallIconSVG})`,
                  '--indeterminate-icon': `url(${dashIconSVG})`,
                } as CSSProperties
              }
            />
          </Box>
        )}
        data-testid={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

/**
 * Checkbox fields allow users to choose an option, or not.
 *
 * Use this component *outside forms* for inputs of `type`:
 * `checkbox`
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.CheckboxField`](/story/components-forms-form-textfield--default).*
 */
export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  (props, ref) => <CheckboxFieldInternal ref={ref} {...props} />
);

import type { ReeferBaseProps } from '../../../types';
import { Typography } from '../../typography/typography';
import type { TabDirection } from '../tabs';
import { StyledRouterTab, StyledTab, TabLabel } from './tab.styles';

export interface TabProps extends ReeferBaseProps {
  /** Aria label */
  ariaLabel?: string;

  /** Optionally accepts children that render to the right of the label */
  children?: React.ReactNode;

  /** Direction of the tabs, defaults to horizontal */
  direction?: TabDirection;

  /** Whether to disable the tab */
  disabled?: boolean;

  /** Tabs component stretches across the whole container (controlled by parent) */
  full?: boolean;

  /** Tab label and name */
  label: string | React.ReactNode;

  /** Tab `onClick` handler (controlled by parent) */
  onClick?(): void;

  /** Whether tab is selected (controlled by parent) */
  selected?: boolean;

  /** An internal route: if passed, it renders a `<Link>` from `react-router-dom` */
  to?: string;

  /** Value that is returned to the onChange handler when clicked */
  value?: string;

  /** Width of each tab (controlled by parent) */
  width?: number | string;
}

/**
 * Each individual tab within the `Tabs` component is defined using the `Tab.Tab` component.
 */

export function Tab({
  ariaLabel,
  children,
  className,
  'data-testid': testId,
  direction = 'horizontal',
  disabled = false,
  full,
  id,
  label,
  onClick,
  selected = false,
  style,
  to,
  value,
  width,
  ...props
}: TabProps) {
  const sharedProps = {
    'aria-label': ariaLabel,
    className,
    'data-testid': testId,
    full,
    id,
    direction,
    selected,
    style,
    width,
    ...props,
  };

  const tabLabel = (
    <TabLabel
      direction={direction}
      justifyContent={direction === 'horizontal' ? 'center' : 'space-between'}
      selected={selected}
    >
      <Typography variant={selected ? 'body-bold' : 'body'} as="span">
        {label}
      </Typography>
      {children}
    </TabLabel>
  );

  if (!to || disabled) {
    return (
      <StyledTab
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        value={value}
        type="button"
        {...sharedProps}
      >
        {tabLabel}
      </StyledTab>
    );
  }
  return (
    <StyledRouterTab onClick={onClick} to={to} {...sharedProps}>
      {tabLabel}
    </StyledRouterTab>
  );
}

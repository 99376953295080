/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Vape24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.95752 13.2937C3.88669 12.7122 4.00005 12.1919 4.00005 12.0006C4.00005 11.8097 3.88718 11.2912 2.96357 10.7113C2.92049 10.6851 2.87808 10.6571 2.83643 10.6273C2.82985 10.6226 2.82331 10.6179 2.81681 10.6131C1.06629 9.34048 4.80197e-07 7.51501 5.68248e-07 5.50063C6.56456e-07 3.48266 1.07011 1.65426 2.8262 0.381314C3.72052 -0.266959 4.97105 -0.0674937 5.61932 0.826831C6.26759 1.72116 6.06813 2.97168 5.1738 3.61995C4.26323 4.28 4 4.98511 4 5.50063C4 6.00679 4.25375 6.69571 5.12482 7.3453C6.81366 8.41823 8.00005 10.0478 8.00005 12.0006C8.00005 13.953 6.8141 15.5823 5.12576 16.6553C4.25393 17.3051 4 17.9943 4 18.5006C4 19.0162 4.26323 19.7213 5.1738 20.3813C6.06812 21.0296 6.26759 22.2801 5.61932 23.1744C4.97104 24.0688 3.72052 24.2682 2.8262 23.62C1.0701 22.347 -8.82085e-08 20.5186 0 18.5006C8.78553e-08 16.4907 1.06156 14.6689 2.80514 13.3966C2.83423 13.375 2.86407 13.3541 2.89463 13.3338C2.91543 13.32 2.9364 13.3066 2.95752 13.2937Z"
      fill="#CE349A"
    />
    <path
      d="M10.9575 13.2937C11.8867 12.7122 12 12.1919 12 12.0006C12 11.8097 11.8872 11.2912 10.9636 10.7113C10.9205 10.6851 10.8781 10.6571 10.8364 10.6273C10.8299 10.6226 10.8233 10.6179 10.8168 10.6131C9.06629 9.34048 8 7.51501 8 5.50063C8 3.48266 9.07011 1.65426 10.8262 0.381314C11.7205 -0.266959 12.971 -0.0674937 13.6193 0.826831C14.2676 1.72116 14.0681 2.97168 13.1738 3.61995C12.2632 4.28 12 4.98511 12 5.50063C12 6.00679 12.2537 6.69571 13.1248 7.3453C14.8137 8.41823 16 10.0478 16 12.0006C16 13.953 14.8141 15.5823 13.1258 16.6553C12.2539 17.3051 12 17.9943 12 18.5006C12 19.0162 12.2632 19.7213 13.1738 20.3813C14.0681 21.0296 14.2676 22.2801 13.6193 23.1744C12.971 24.0688 11.7205 24.2682 10.8262 23.62C9.0701 22.347 8 20.5186 8 18.5006C8 16.4907 9.06156 14.6689 10.8051 13.3966C10.8342 13.375 10.8641 13.3541 10.8946 13.3338C10.9154 13.32 10.9364 13.3066 10.9575 13.2937Z"
      fill="#CE349A"
    />
    <path
      d="M18.9575 13.2937C19.8867 12.7122 20 12.1919 20 12.0006C20 11.8097 19.8872 11.2912 18.9636 10.7113C18.9205 10.6851 18.8781 10.6571 18.8364 10.6273C18.8299 10.6226 18.8233 10.6179 18.8168 10.6131C17.0663 9.34048 16 7.51501 16 5.50063C16 3.48266 17.0701 1.65426 18.8262 0.381314C19.7205 -0.266959 20.971 -0.0674937 21.6193 0.826831C22.2676 1.72116 22.0681 2.97168 21.1738 3.61995C20.2632 4.28 20 4.98511 20 5.50063C20 6.00679 20.2537 6.69571 21.1248 7.3453C22.8137 8.41823 24 10.0478 24 12.0006C24 13.953 22.8141 15.5823 21.1258 16.6553C20.2539 17.3051 20 17.9943 20 18.5006C20 19.0162 20.2632 19.7213 21.1738 20.3813C22.0681 21.0296 22.2676 22.2801 21.6193 23.1744C20.971 24.0688 19.7205 24.2682 18.8262 23.62C17.0701 22.347 16 20.5186 16 18.5006C16 16.4907 17.0616 14.6689 18.8051 13.3966C18.8342 13.375 18.8641 13.3541 18.8946 13.3338C18.9154 13.32 18.9364 13.3066 18.9575 13.2937Z"
      fill="#CE349A"
    />
  </svg>
);

const Vape48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.42212 26.3712C9.12559 25.3052 9.33341 24.3512 9.33341 24.0005C9.33341 23.6506 9.12649 22.7 7.4332 21.6368C7.35423 21.5888 7.27647 21.5375 7.20011 21.4829C7.18805 21.4743 7.17607 21.4656 7.16414 21.4569C3.95487 19.1237 2 15.7771 2 12.0841C2 8.38451 3.96186 5.03249 7.18135 2.69878C8.82095 1.5103 11.1136 1.87598 12.3021 3.51555C13.4906 5.15513 13.1249 7.44773 11.4853 8.63621C9.8159 9.84629 9.33332 11.139 9.33332 12.0841C9.33332 13.012 9.79853 14.275 11.3955 15.4659C14.4917 17.433 16.6667 20.4204 16.6667 24.0005C16.6667 27.58 14.4925 30.567 11.3972 32.534C9.79886 33.7253 9.33332 34.9888 9.33332 35.9171C9.33332 36.8623 9.8159 38.1549 11.4853 39.365C13.1249 40.5535 13.4906 42.8461 12.3021 44.4857C11.1136 46.1252 8.82094 46.4909 7.18135 45.3024C3.96185 42.9687 2 39.6167 2 35.9171C2 32.2324 3.94618 28.8924 7.14275 26.5599C7.19609 26.5203 7.25078 26.4819 7.30681 26.4447C7.34494 26.4194 7.38338 26.3949 7.42212 26.3712Z"
      fill="#CE349A"
    />
    <path
      d="M22.0888 26.3712C23.7922 25.3052 24 24.3512 24 24.0005C24 23.6506 23.7931 22.7 22.0998 21.6368C22.0209 21.5888 21.9431 21.5375 21.8667 21.4829C21.8547 21.4743 21.8427 21.4656 21.8308 21.4569C18.6215 19.1237 16.6666 15.7771 16.6666 12.0841C16.6666 8.38451 18.6285 5.03249 21.848 2.69878C23.4876 1.5103 25.7802 1.87598 26.9687 3.51555C28.1572 5.15513 27.7915 7.44773 26.1519 8.63621C24.4825 9.84629 24 11.139 24 12.0841C24 13.012 24.4652 14.275 26.0621 15.4659C29.1583 17.433 31.3334 20.4204 31.3334 24.0005C31.3334 27.58 29.1591 30.567 26.0638 32.534C24.4655 33.7253 24 34.9888 24 35.9171C24 36.8623 24.4825 38.1549 26.1519 39.365C27.7915 40.5535 28.1572 42.8461 26.9687 44.4857C25.7802 46.1252 23.4876 46.4909 21.848 45.3024C18.6285 42.9687 16.6666 39.6167 16.6666 35.9171C16.6666 32.2324 18.6128 28.8924 21.8094 26.5599C21.8627 26.5203 21.9174 26.4819 21.9734 26.4447C22.0116 26.4194 22.05 26.3949 22.0888 26.3712Z"
      fill="#CE349A"
    />
    <path
      d="M36.7554 26.3712C38.4589 25.3052 38.6667 24.3512 38.6667 24.0005C38.6667 23.6506 38.4598 22.7 36.7665 21.6368C36.6875 21.5888 36.6097 21.5375 36.5334 21.4829C36.5213 21.4743 36.5093 21.4656 36.4974 21.4569C33.2881 19.1237 31.3333 15.7771 31.3333 12.0841C31.3333 8.38451 33.2951 5.03249 36.5146 2.69878C38.1542 1.5103 40.4468 1.87598 41.6353 3.51555C42.8238 5.15513 42.4581 7.44773 40.8186 8.63621C39.1492 9.84629 38.6666 11.139 38.6666 12.0841C38.6666 13.012 39.1318 14.275 40.7287 15.4659C43.825 17.433 46 20.4204 46 24.0005C46 27.58 43.8258 30.567 40.7305 32.534C39.1321 33.7253 38.6666 34.9888 38.6666 35.9171C38.6666 36.8623 39.1492 38.1549 40.8186 39.365C42.4581 40.5535 42.8238 42.8461 41.6353 44.4857C40.4468 46.1252 38.1542 46.4909 36.5146 45.3024C33.2951 42.9687 31.3333 39.6167 31.3333 35.9171C31.3333 32.2324 33.2795 28.8924 36.476 26.5599C36.5294 26.5203 36.5841 26.4819 36.6401 26.4447C36.6782 26.4194 36.7167 26.3949 36.7554 26.3712Z"
      fill="#CE349A"
    />
  </svg>
);

const SIZE_MAP = {
  md: Vape24,
  sm: Vape24,
  xl: Vape48,
  lg: Vape48,
  xxl: Vape48,
  xxxl: Vape48,
};

export const VapeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'vape-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

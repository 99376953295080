// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EjK0YaloN8oI_Puq96T7 {\n  height: var(--modalContent-height);\n  overflow-y: var(--modalContent-overflow-y);\n}\n\n.EjK0YaloN8oI_Puq96T7::-webkit-scrollbar {\n  display: none;\n}\n\n.KLDjZbD86SgDoa3fr97r {\n  padding: 24px;\n}\n\n@media (min-width: 768px) {\n  .KLDjZbD86SgDoa3fr97r {\n    padding: 40px;\n  }\n}\n\n._TmQoUqXbQqbMeTkN5vy {\n  border: none;\n}\n\n.qu7dVPro7HomnYBPO98_ {\n  margin-bottom: 24px;\n  margin-left: -24px;\n  margin-right: -24px;\n  margin-top: 24px;\n}\n\n@media (min-width: 768px) {\n  .qu7dVPro7HomnYBPO98_ {\n    margin-bottom: 40px;\n    margin-left: -40px;\n    margin-right: -40px;\n    margin-top: 40px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/modal/modalContent/modalContent.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,0CAA0C;AAC5C;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;EAClB;AACF","sourcesContent":[".modalContent {\n  height: var(--modalContent-height);\n  overflow-y: var(--modalContent-overflow-y);\n}\n\n.modalContent::-webkit-scrollbar {\n  display: none;\n}\n\n.modalContent__withPadding {\n  padding: 24px;\n}\n\n@media (min-width: 768px) {\n  .modalContent__withPadding {\n    padding: 40px;\n  }\n}\n\n.content-divider {\n  border: none;\n}\n\n.divider-padding {\n  margin-bottom: 24px;\n  margin-left: -24px;\n  margin-right: -24px;\n  margin-top: 24px;\n}\n\n@media (min-width: 768px) {\n  .divider-padding {\n    margin-bottom: 40px;\n    margin-left: -40px;\n    margin-right: -40px;\n    margin-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "EjK0YaloN8oI_Puq96T7",
	"modalContent__withPadding": "KLDjZbD86SgDoa3fr97r",
	"content-divider": "_TmQoUqXbQqbMeTkN5vy",
	"divider-padding": "qu7dVPro7HomnYBPO98_"
};
export default ___CSS_LOADER_EXPORT___;

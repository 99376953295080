import type { ReeferBaseProps } from '../../types';
import { AnimatedContainer, LoadingContainer, Wavvy } from './loading.styles';

export type LoadingColor = 'white' | 'purple' | 'black';
export type LoadingSize = 'xs' | 'sm' | 'lg';

export interface LoadingProps extends ReeferBaseProps {
  /** Accepts one of the three to determine loading color, defaults to black */
  color?: LoadingColor;

  /** Size determines the height and width of the loading animation, defaults to sm */
  size?: LoadingSize;
}

export function Loading({
  className,
  color = 'black',
  'data-testid': testId,
  id,
  size = 'sm',
  style,
}: LoadingProps) {
  return (
    <LoadingContainer
      role="alert"
      aria-busy="true"
      aria-live="polite"
      className={className}
      data-testid={testId}
      id={id}
      title="loading animation"
      size={size}
      style={style}
    >
      <AnimatedContainer size={size}>
        <Wavvy size={size} color={color} />
      </AnimatedContainer>
    </LoadingContainer>
  );
}

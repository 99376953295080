import { deepMerge, logPrefix } from '../internal/utils';
import { effects } from '../styling';
import { BASE_CONFIG as defaultTheme } from '../theme';
import type {
  ColorGroup,
  Components,
  CustomComponents,
  CustomThemeConfig,
  ReeferTheme,
} from '../types';
import type { BorderRadius } from '../types/borderRadius';

type CustomizableColorGroups = Omit<
  ReeferTheme['colors'],
  | 'text'
  | 'background'
  | 'brand'
  | 'tertiary'
  | 'system'
  | 'grays'
  | 'transparent'
>;

const calculateColorGroup = (color: string): ColorGroup => {
  return {
    dark: effects.darken(color, 0.2),
    light: effects.lighten(color, 0.8),
    main: color as string,
  };
};

const mergeColors = (
  customColors: CustomThemeConfig['colors'],
  defaultColors: ReeferTheme['colors']
) => {
  if (!customColors) return defaultColors;

  const mergedColors = { ...defaultColors };
  const { text, background, ...rest } = customColors;

  Object.keys(rest).forEach((colorKey: string) => {
    const color = rest[colorKey as keyof CustomThemeConfig['colors']];
    if (color) {
      mergedColors[colorKey as keyof CustomizableColorGroups] =
        calculateColorGroup(color);
    }
  });

  if (text) {
    mergedColors.text = {
      info: (text.info || defaultColors.text.info) as string,
      inverse: (text.inverse || defaultColors.text.inverse) as string,
      main: (text.main || defaultColors.text.main) as string,
      light: effects.lighten(
        text.main || defaultColors.text.main,
        0.5
      ) as string,
    };
  }
  if (background) mergedColors.background = background as string;

  return mergedColors;
};

const validateBorderRadius = (borderRadius: BorderRadius) => {
  const smRadius = parseFloat(borderRadius.sm as string);
  const lgRadius = parseFloat(borderRadius.lg as string);

  if (smRadius > lgRadius) {
    throw Error(
      `${logPrefix(
        'ReeferThemeProvider'
      )} Border radius 'sm' cannot be larger than 'lg'. Please adjust your borderRadius styles.`
    );
  }
};

export const generateTheme = (customTheme?: CustomThemeConfig): ReeferTheme => {
  if (!customTheme) return defaultTheme;
  const mergedTheme: ReeferTheme = {
    ...defaultTheme,
    borderRadius: deepMerge<BorderRadius>(
      {},
      defaultTheme.borderRadius,
      customTheme.borderRadius || {}
    ),
    colors: mergeColors(customTheme.colors, defaultTheme.colors),
    components: deepMerge<Components, CustomComponents>(
      {} as Components,
      defaultTheme.components,
      customTheme.components || {}
    ),
  };

  validateBorderRadius(mergedTheme.borderRadius);

  return mergedTheme;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qu7H1XMOIIQ5M6ErSjip {\n  align-items: center;\n  border-radius: var(--border-radius-sm);\n  background-color: transparent;\n  display: inline-flex;\n  justify-content: space-between;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  padding: 16px;\n  width: auto;\n}\n\n/* Full width */\n\n.JnOaqrBgXw8_B2xbpuhA {\n  width: 100%;\n}\n\n/* Variants */\n\n.I3SFP3MA_IgFgg55E8ax {\n  background-color: var(--colors-error-light);\n}\n\n.jScEAIbUHOc9FsZno5q4 {\n  background-color: var(--colors-grays-ultralight);\n}\n\n.GtMUjMMuOQcZWq51YezL {\n  background-color: var(--colors-success-light);\n}\n\n.hQ4_ZvlvQfEsuqnni4Rp {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/banner/banner.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sCAAsC;EACtC,6BAA6B;EAC7B,oBAAoB;EACpB,8BAA8B;EAC9B,mCAAmC;EACnC,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;EAC7B,aAAa;EACb,WAAW;AACb;;AAEA,eAAe;;AAEf;EACE,WAAW;AACb;;AAEA,aAAa;;AAEb;EACE,2CAA2C;AAC7C;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,aAAa;AACf","sourcesContent":[".banner {\n  align-items: center;\n  border-radius: var(--border-radius-sm);\n  background-color: transparent;\n  display: inline-flex;\n  justify-content: space-between;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  padding: 16px;\n  width: auto;\n}\n\n/* Full width */\n\n.banner__full {\n  width: 100%;\n}\n\n/* Variants */\n\n.banner__error {\n  background-color: var(--colors-error-light);\n}\n\n.banner__info {\n  background-color: var(--colors-grays-ultralight);\n}\n\n.banner__success {\n  background-color: var(--colors-success-light);\n}\n\n.banner_content {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "qu7H1XMOIIQ5M6ErSjip",
	"banner__full": "JnOaqrBgXw8_B2xbpuhA",
	"banner__error": "I3SFP3MA_IgFgg55E8ax",
	"banner__info": "jScEAIbUHOc9FsZno5q4",
	"banner__success": "GtMUjMMuOQcZWq51YezL",
	"banner_content": "hQ4_ZvlvQfEsuqnni4Rp"
};
export default ___CSS_LOADER_EXPORT___;

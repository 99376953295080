import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

import { getTwentyFourHours } from '@jane/business-admin/util';
import { Flex, Form, useFormContext } from '@jane/shared/reefer';

import {
  validateEndDateAndTime,
  validateStartDateAndTime,
} from './ScheduleTimeValidation';

const Hidable = styled.div<{ visible: boolean }>(({ visible }) => ({
  display: visible ? 'block' : 'none',
}));

export const DateAndTimePicker = ({
  timezone,
  variant,
}: {
  timezone: string | undefined;
  variant: 'start' | 'end';
}) => {
  const { clearErrors, watch } = useFormContext();
  const hourOptions = useMemo(() => {
    return getTwentyFourHours();
  }, []);

  const [endOnStoreClose, isEnabled] = watch([
    'use_store_close_time',
    `enabled_date_${variant}_checked`,
  ]);
  const endOnStoreCloseLabel =
    'On selected schedule End Date, special should end when the store closes\
     (rather than using the daily schedule).';

  const [startDate, endDate, startTime, endTime] = watch([
    'start_date',
    'end_date',
    'start_time',
    'end_time',
  ]);

  const validate = (val: any) => {
    if (isEnabled && !val) {
      return 'This field is required.';
    }
    return variant === 'start'
      ? validateStartDateAndTime({
          endDate,
          endTime,
          startDate,
          startTime,
          timezone,
        })
      : validateEndDateAndTime({
          endDate,
          endTime,
          startDate,
          startTime,
          timezone,
        });
  };

  return (
    <Hidable visible={isEnabled} aria-hidden={!isEnabled}>
      <Flex width="100%" gap={16} mt={24}>
        <Form.DateTimeField
          label={`${capitalize(variant)} date`}
          name={`${variant}_date`}
          width="50%"
          onChange={() => {
            clearErrors();
          }}
          validate={validate}
        />
        {variant === 'start' || !endOnStoreClose ? (
          <Form.SelectField
            label={`${capitalize(variant)} time`}
            name={`${variant}_time`}
            options={hourOptions}
            width="50%"
            onChange={() => {
              clearErrors();
            }}
            validate={validate}
          />
        ) : null}
      </Flex>
      {variant === 'end' ? (
        <Form.CheckboxField
          label={endOnStoreCloseLabel}
          name="use_store_close_time"
          mt={16}
        />
      ) : null}
    </Hidable>
  );
};

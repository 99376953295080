import { forwardRef } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Button as ButtonV1 } from '@jane/shared/reefer-v1';
import { Button as ButtonV2 } from '@jane/shared/reefer-v2';
import type { ButtonProps, IconButtonProps } from '@jane/shared/reefer-v2';

const MainButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <ButtonV2 {...props} ref={ref} />;
    }
    return <ButtonV1 {...props} ref={ref} />;
  }
);

const IconButton = (props: IconButtonProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ButtonV2.Icon {...props} />;
  }
  return <ButtonV1.Icon {...props} />;
};

export const Button = Object.assign(MainButton, {
  Icon: IconButton,
});

import clsx from 'clsx';
import has from 'lodash/has';
import type { CSSProperties, ElementType } from 'react';

import { ObjectKeys, getCSSVar } from '../../internal/utils';
import type {
  ColorKey,
  TypographyConfig,
  TypographyVariant,
} from '../../theme';
import { TYPOGRAPHY_VARIANTS } from '../../theme';
import { getColorCSSVar } from '../../utils';
import type { BoxProps } from '../box';
import { Box } from '../box';
import { useReeferTheme } from '../providers/reeferThemeProvider';
import styles from './typography.module.css';

export type TypographyComponent =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span'
  | 'label'
  | 'legend';

export type TypographyFont = 'generic' | 'branded' | undefined;

export interface TypographyProps<T extends string = TypographyVariant>
  extends Omit<
    BoxProps,
    | 'accessibilityComponentName'
    | 'ariaLabel'
    | 'ariaLabelledBy'
    | 'as'
    | 'onClick'
  > {
  /** Component changes the actual HTML tag that is rendered */
  as?: TypographyComponent;

  /**
   * Changes between branded and default (generic) font families. For `title` and `title-bold` variants, `branded` defaults
   * to `true` and you must explicitly pass `branded` as `false` to turn switch to the `generic` font.
   * For all other variants, the default is `false`
   */
  branded?: boolean;

  /** Color changes the text color  */
  color?: ColorKey;

  /** For attribute, for labels only, must match id of input label is for. **/
  htmlFor?: string;

  /** Ellipsizes text after it has wrapped to N number of lines */
  maxLines?: number | 'none';

  /** Add `text-decoration` `line-through` to text */
  strikeThrough?: boolean;

  /** Aligns text */
  textAlign?: 'center' | 'left' | 'right' | 'initial';

  /** Truncates text at given width with an ellipsis **/
  truncateAt?: string;

  /** Variant changes the style of the typography */
  variant?: T;

  /** Set CSS `white-space` attribute, supports any valid `white-space` value. **/
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces'
    | 'inherit';
}

/**
 * Typography component allows you to style your text the way you need, while rendering the appropriate HTML tag
 * for accessibility. This component is **responsive** - font size will change appropriately based on device size.
 * Defaults to `variant='body'`.
 */
export function Typography<T extends string = TypographyVariant>({
  as,
  branded,
  children,
  className,
  color,
  htmlFor,
  maxLines,
  maxWidth = 'none',
  strikeThrough,
  style,
  textAlign,
  truncateAt,
  variant,
  whiteSpace,
  ...boxProps
}: TypographyProps<T>) {
  const theme = useReeferTheme();
  const { componentMapping, variants: themeVariants } = theme.components
    .Typography as TypographyConfig<T>;

  const variants = ObjectKeys(themeVariants) || TYPOGRAPHY_VARIANTS;
  const defaultVariant = has(variants, 'body') ? 'body' : variants[0];
  const finalVariant = variant || defaultVariant;
  const defaultFont = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--font-family-default');

  const renderAs: ElementType = as || componentMapping[finalVariant];

  const { branded: originalBranded } = themeVariants[finalVariant as T];

  const isBranded = branded !== undefined ? branded : originalBranded;

  const whitespaceValue = truncateAt ? whiteSpace || 'noWrap' : whiteSpace;

  const clampedLine = maxLines === 1;
  const supportsWebkitLineClamp =
    CSS.supports && CSS.supports('-webkit-line-clamp', '1');
  const clampedLines = typeof maxLines === 'number' && maxLines > 1;

  return (
    <Box
      as={renderAs}
      className={clsx(
        className,
        styles.typography,
        [styles[`typography__text_align_${textAlign}`]],
        {
          [styles.typography__branded]: isBranded,
          [styles.typography__bold]: finalVariant.endsWith('bold'),
          [styles.typography__bold_generic]:
            !isBranded && finalVariant === 'title-bold',
          [styles.typography__strikethrough]: strikeThrough,
          [styles.typography__truncate]: !!truncateAt,
          [styles['clamped-line']]: clampedLine,
          [styles['clamped-lines']]: clampedLines,
          [styles.typography__default_font_settings]:
            defaultFont.includes('Jane Default'),
        }
      )}
      htmlFor={htmlFor}
      maxWidth={truncateAt ? truncateAt : maxWidth}
      overflow={
        !!truncateAt || clampedLine || (clampedLines && supportsWebkitLineClamp)
          ? 'hidden'
          : undefined
      }
      style={
        {
          '--typography-color': getColorCSSVar(color),
          '--typography-font-size-desktop': getCSSVar(
            `--typography-${finalVariant}-font-size-desktop`
          ),
          '--typography-font-size-mobile': getCSSVar(
            `--typography-${finalVariant}-font-size-mobile`
          ),
          '--typography-font-weight-desktop': getCSSVar(
            `--typography-${finalVariant}-font-weight-desktop`
          ),
          '--typography-font-weight-mobile': getCSSVar(
            `--typography-${finalVariant}-font-weight-mobile`
          ),
          '--typography-letter-spacing-desktop': getCSSVar(
            `--typography-${finalVariant}-letter-spacing-desktop`
          ),
          '--typography-letter-spacing-mobile': getCSSVar(
            `--typography-${finalVariant}-letter-spacing-mobile`
          ),
          '--typography-line-height-desktop': getCSSVar(
            `--typography-${finalVariant}-line-height-desktop`
          ),
          '--typography-line-height-mobile': getCSSVar(
            `--typography-${finalVariant}-line-height-mobile`
          ),
          '--typography-text-transform-desktop': getCSSVar(
            `--typography-${finalVariant}-text-transform-desktop`
          ),
          '--typography-text-transform-mobile': getCSSVar(
            `--typography-${finalVariant}-text-transform-mobile`
          ),
          '--typography-webkit-line-clamp': maxLines,
          ...(whitespaceValue && {
            '--typography-white-space': whitespaceValue,
          }),
          ...style,
        } as CSSProperties
      }
      {...boxProps}
    >
      {children}
    </Box>
  );
}

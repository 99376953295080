import type { CloudPrinterSetting as StorePrinterSettingV2 } from '@jane/shared/models';
import {
  Flex,
  InfoIcon,
  Link,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';

import { CardSection } from '../../../../../CardSection';

export const LoadingOrderTickets = () => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" width="100%">
      <Flex flexDirection="row">
        <Flex width="50%">
          <Skeleton animate>
            <Typography variant="caps" color="grays-mid" mb={4}>
              ORDER TICKETS INFORMATION
            </Typography>
            <Skeleton.Bone height="20px" width="200px" mb={12} />
            <Skeleton.Bone height="20px" width="200px" mb={12} />
            <Skeleton.Bone height="20px" width="200px" mb={12} />
          </Skeleton>
        </Flex>
        <Flex flexDirection="column">
          <Typography variant="caps" color="grays-mid" mb={4}>
            ORDER TICKETS SETTINGS
          </Typography>
          <Skeleton animate>
            <Skeleton.Bone height="20px" width="200px" mb={12} />
          </Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const OrderTickets = ({
  isInternal,
  storePrintersSettings,
}: {
  isInternal: boolean;
  storePrintersSettings: StorePrinterSettingV2;
}) => {
  if (!storePrintersSettings) {
    return null;
  }

  const orderTicketInfoSelected =
    storePrintersSettings.print_date_of_birth ||
    storePrintersSettings.print_fulfillment_type ||
    storePrintersSettings.print_medical_id_number ||
    storePrintersSettings.print_pos_identifier ||
    storePrintersSettings.print_pos_product_name ||
    storePrintersSettings.print_store_name;
  const orderTicketSettingsSelected =
    storePrintersSettings.print_items_grouped_by_kind;

  return (
    <Flex flexDirection="column" justifyContent="space-between" width="100%">
      <Flex flexDirection="row">
        {!orderTicketInfoSelected && !orderTicketSettingsSelected ? (
          <Typography>
            Order ticket settings have not been specified for this store.
          </Typography>
        ) : (
          <>
            <CardSection width="50%" label="ORDER TICKETS INFORMATION">
              {orderTicketInfoSelected ? (
                <>
                  {storePrintersSettings.print_store_name && (
                    <Typography>Store name</Typography>
                  )}
                  {storePrintersSettings.print_date_of_birth && (
                    <Typography>DOB</Typography>
                  )}
                  {storePrintersSettings.print_medical_id_number && (
                    <Typography>Medical ID number</Typography>
                  )}
                  {storePrintersSettings.print_fulfillment_type && (
                    <Typography>Fulfillment type</Typography>
                  )}
                  {storePrintersSettings.print_pos_identifier && (
                    <Typography>POS identifier</Typography>
                  )}
                  {storePrintersSettings.print_pos_product_name && (
                    <Typography>POS product name</Typography>
                  )}
                </>
              ) : (
                <Typography>None selected</Typography>
              )}
            </CardSection>
            <CardSection width="50%" label="ORDER TICKETS SETTINGS">
              {orderTicketSettingsSelected ? (
                <Typography>Group items by category</Typography>
              ) : (
                <Typography>None</Typography>
              )}
            </CardSection>
          </>
        )}
      </Flex>
      {!isInternal && (
        <Flex background="grays-ultralight" borderRadius="sm" mt={24} p={16}>
          <InfoIcon />
          <Typography ml={16}>
            Settings are saved for all printers linked to the current store.
            Update these anytime by contacting{' '}
            <Link href="https://iheartjane029301.my.site.com/s/support">
              Jane Partner Success.
            </Link>
          </Typography>
        </Flex>
      )}
    </Flex>
  );
};

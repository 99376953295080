import type { DrawerFooterProps } from '../drawer.types';
import { StyledDrawerFooter } from './drawerFooter.styles';

export function DrawerFooter({
  children,
  className,
  'data-testid': testId,
  id,
  noPadding = false,
  style,
}: DrawerFooterProps) {
  return (
    <StyledDrawerFooter
      className={className}
      data-testid={testId}
      id={id}
      noPadding={noPadding}
      style={style}
    >
      {children}
    </StyledDrawerFooter>
  );
}

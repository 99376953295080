import { useCallback, useEffect, useMemo, useState } from 'react';

import { Skeleton } from '../skeleton';
import fallback from './assets/fallbackImage.svg';
import { getResponsiveImageAttributes } from './getResponsiveImageAttributes';
import { StyledImage, StyledImageWrapper } from './image.styles';
import type { ImageProps } from './image.types';

/**
 * Image component used to render images throughout the apps
 */

export function Image({
  altText,
  aspectRatio,
  border,
  borderRadius = 'none',
  className,
  'data-testid': testId,
  fallbackSrc = fallback,
  height = 'auto',
  id,
  loading,
  objectFit = 'cover',
  objectPosition,
  responsive = false,
  style,
  sizes,
  src,
  srcSet,
  width = '100%',
}: ImageProps) {
  const [imageError, setImageError] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);
  const [imgSrcSet, setImgSrcSet] = useState<string | undefined>(srcSet);
  const [imgSizes, setImgSizes] = useState<string | undefined>(sizes);

  if (borderRadius === 'circular' && height !== width) {
    throw Error(
      'Height and width need to be equivalent to use a circular border radius.'
    );
  }

  const handleImageError = useCallback(() => {
    setImageError(true);
    setImgSrc(fallbackSrc);
    setImgSrcSet(undefined);
    setImgSizes(undefined);
  }, [fallbackSrc]);

  const responsiveImg = useMemo(
    () =>
      responsive
        ? getResponsiveImageAttributes({
            imageSize: sizes ?? 'default',
            src,
          })
        : null,
    [responsive, sizes, src]
  );

  useEffect(() => {
    if (responsiveImg && !responsiveImg.invalid) {
      setImgSrc(responsiveImg.imageSrc);
      setImgSrcSet(responsiveImg.srcSet);
      setImgSizes(responsiveImg.sizes);
    }
  }, [responsiveImg]);

  useEffect(() => {
    if (typeof src !== 'string') {
      handleImageError();
    }
  }, [src, handleImageError]);

  // if an image is responsive, we need to wait for the correct image source
  if (
    !imageError &&
    responsive &&
    !responsiveImg?.invalid &&
    responsiveImg?.imageSrc !== imgSrc
  )
    return <Skeleton.Bone height={height} width={width} />;

  return (
    <StyledImageWrapper
      border={border}
      borderRadius={borderRadius}
      className={className}
      id={id}
      height={height}
      style={style}
      width={width}
    >
      <StyledImage
        alt={imageError ? '' : altText}
        aspectRatio={aspectRatio}
        border={border}
        borderRadius={borderRadius}
        height={height}
        loading={loading}
        objectFit={objectFit}
        objectPosition={objectPosition}
        onError={handleImageError}
        sizes={imgSizes}
        src={imgSrc}
        srcSet={imgSrcSet}
        title={imageError ? 'Failed to load this image.' : altText}
        width={width}
        data-testid={testId}
      />
    </StyledImageWrapper>
  );
}

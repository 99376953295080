import type { PopoverContentProps } from '../popover.types';
import { StyledPopoverContent } from './popoverContent.styles';

export function PopoverContent({
  children,
  className,
  'data-testid': testId,
  maxHeight,
  id,
  label,
  padding = true,
  style,
}: PopoverContentProps) {
  return (
    <StyledPopoverContent
      aria-label={label}
      className={className}
      data-testid={testId}
      id={id}
      maxHeight={maxHeight}
      padding={padding}
      style={style}
    >
      {children}
    </StyledPopoverContent>
  );
}

import { forwardRef } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  CheckboxField as CheckboxFieldV1,
  DateTimeField as DateTimeFieldV1,
  FieldWrapper as FieldWrapperV1,
  NumberField as NumberFieldV1,
  RadioFieldGroup as RadioFieldGroupV1,
  SearchField as SearchFieldV1,
  SelectField as SelectFieldV1,
  SliderField as SliderFieldV1,
  SwitchField as SwitchFieldV1,
  TextAreaField as TextAreaFieldV1,
  TextField as TextFieldV1,
} from '@jane/shared/reefer-v1';
import {
  CheckboxField as CheckboxFieldV2,
  DateTimeField as DateTimeFieldV2,
  FieldWrapper as FieldWrapperV2,
  NumberField as NumberFieldV2,
  RadioFieldGroup as RadioFieldGroupV2,
  SearchField as SearchFieldV2,
  SelectField as SelectFieldV2,
  SliderField as SliderFieldV2,
  SwitchField as SwitchFieldV2,
  TextAreaField as TextAreaFieldV2,
  TextField as TextFieldV2,
} from '@jane/shared/reefer-v2';
import type {
  CheckboxFieldProps,
  DateTimeFieldProps,
  FieldWrapperProps,
  NumberFieldProps,
  RadioFieldGroupProps,
  SearchFieldProps,
  SelectFieldProps,
  SliderFieldProps,
  SwitchFieldProps,
  TextAreaFieldProps,
  TextFieldProps,
} from '@jane/shared/reefer-v2';

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  (props: CheckboxFieldProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <CheckboxFieldV2 {...props} ref={ref} />;
    }
    return <CheckboxFieldV1 {...props} ref={ref} />;
  }
);

export const DateTimeField = forwardRef<HTMLInputElement, DateTimeFieldProps>(
  (props: DateTimeFieldProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <DateTimeFieldV2 {...props} ref={ref} />;
    }
    return <DateTimeFieldV1 {...props} ref={ref} />;
  }
);

export const FieldWrapper = (props: FieldWrapperProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FieldWrapperV2 {...props} />;
  }
  return <FieldWrapperV1 {...props} />;
};

export const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>(
  (props: NumberFieldProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <NumberFieldV2 {...props} ref={ref} />;
    }
    return <NumberFieldV1 {...props} ref={ref} />;
  }
);

export const RadioFieldGroup = forwardRef<
  HTMLInputElement,
  RadioFieldGroupProps
>((props: RadioFieldGroupProps, ref) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <RadioFieldGroupV2 {...props} ref={ref} />;
  }
  return <RadioFieldGroupV1 {...props} ref={ref} />;
});

interface SearchFieldPropsWithValue extends SearchFieldProps {
  value?: string;
}
export const SearchField = forwardRef<
  HTMLInputElement,
  SearchFieldPropsWithValue
>((props: SearchFieldPropsWithValue, ref) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SearchFieldV2 {...props} ref={ref} />;
  }
  return <SearchFieldV1 {...props} ref={ref} />;
});

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (props: SelectFieldProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <SelectFieldV2 {...props} ref={ref} />;
    }
    return <SelectFieldV1 {...props} ref={ref} />;
  }
);

export const SliderField = forwardRef<HTMLInputElement, SliderFieldProps>(
  (props: SliderFieldProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <SliderFieldV2 {...props} ref={ref} />;
    }
    return <SliderFieldV1 {...props} ref={ref} />;
  }
);

export const SwitchField = forwardRef<HTMLInputElement, SwitchFieldProps>(
  (props: SwitchFieldProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <SwitchFieldV2 {...props} ref={ref} />;
    }
    return <SwitchFieldV1 {...props} ref={ref} />;
  }
);

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>((props: TextAreaFieldProps, ref) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TextAreaFieldV2 {...props} ref={ref} />;
  }
  return <TextAreaFieldV1 {...props} ref={ref} />;
});

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <TextFieldV2 {...props} ref={ref} />;
    }
    return <TextFieldV1 {...props} ref={ref} />;
  }
);

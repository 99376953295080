/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const HeartFilled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 4.82404C11.0759 3.64175 9.2884 2 6.47771 2C2.86629 2 0 5.16303 0 8.29057C0 11.419 1.60957 13.8686 4.82972 16.687C8.03875 19.4971 9.14078 19.78 11.9858 21.2611C14.8602 19.78 15.9633 19.4971 19.1713 16.687C22.3914 13.8686 24 11.419 24 8.29057C24 5.16303 21.1347 2 17.5223 2C14.7126 2 12.9241 3.64175 12 4.82404Z"
      fill="#FFC220"
    />
  </svg>
);

const SIZE_MAP = {
  md: HeartFilled24,
  sm: HeartFilled24,
  lg: HeartFilled24,
  xl: HeartFilled24,
  xxl: HeartFilled24,
  xxxl: HeartFilled24,
};

export const HeartFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'heart-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

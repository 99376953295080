/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Tincture24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.92893 21.2688C1.02369 17.3866 1.02369 11.0922 4.92893 7.20993L10.59 1.58223C11.3701 0.806738 12.63 0.806738 13.41 1.58223L19.0711 7.20993C22.9763 11.0922 22.9763 17.3866 19.0711 21.2688C15.1658 25.1511 8.83418 25.1511 4.92893 21.2688Z"
      fill="#8E00E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 15.1805C6.55228 15.1805 7 15.6282 7 16.1805C7 17.8373 8.34315 19.1805 10 19.1805C10.5523 19.1805 11 19.6282 11 20.1805C11 20.7328 10.5523 21.1805 10 21.1805C7.23858 21.1805 5 18.9419 5 16.1805C5 15.6282 5.44772 15.1805 6 15.1805Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const Tincture48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.85786 40.4737C2.04738 33.1044 2.04738 21.1564 9.85786 13.7871L21.1799 3.10463C22.7401 1.6326 25.2599 1.6326 26.8201 3.10464L38.1421 13.7871C45.9526 21.1564 45.9526 33.1044 38.1421 40.4737C30.3316 47.8429 17.6684 47.8429 9.85786 40.4737Z"
      fill="#8E00E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 31C11.5871 31 12.3333 31.7462 12.3333 32.6667C12.3333 35.4281 14.5719 37.6667 17.3333 37.6667C18.2538 37.6667 19 38.4129 19 39.3333C19 40.2538 18.2538 41 17.3333 41C12.731 41 9 37.269 9 32.6667C9 31.7462 9.74619 31 10.6667 31Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Tincture24,
  sm: Tincture24,
  xl: Tincture48,
  lg: Tincture48,
  xxl: Tincture48,
  xxxl: Tincture48,
};

export const TinctureIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'tincture-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Grow24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_7797_13268)">
      <rect
        x={11.0225}
        y={11.0006}
        width={2}
        height={10}
        fill="#4BA279"
        className="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 24.0006C18.911 22.973 15.5356 22.4073 12 22.4073C8.46437 22.4073 5.08904 22.973 2 24.0006C3.78387 20.4518 7.59121 18.0006 12 18.0006C16.4088 18.0006 20.2161 20.4518 22 24.0006Z"
        fill="#4BA279"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 24.0006C18.911 22.973 15.5356 22.4073 12 22.4073C8.46437 22.4073 5.08904 22.973 2 24.0006C3.78387 20.4518 7.59121 18.0006 12 18.0006C16.4088 18.0006 20.2161 20.4518 22 24.0006Z"
        className="details"
        fill="black"
        fillOpacity={0.5}
      />
      <path
        d="M9.5 8.00061C9.5 3.00061 10.75 0.000610352 12 0.000610352C13.25 0.000610352 14.5 3.00061 14.5 8.00061C14.5 13.0006 13.25 13.0006 12 13.0006C10.75 13.0006 9.5 13.0006 9.5 8.00061Z"
        fill="#4BA279"
      />
      <path
        d="M-1.90735e-06 8.00061C-1.90735e-06 6.58655 8 6.00061 12 9.8543V13.5006C10.0041 13.5006 8.00818 11.8543 6.01225 10.8543C2.85443 9.27217 -1.90735e-06 9.00061 -1.90735e-06 8.00061Z"
        fill="#4BA279"
      />
      <path
        d="M24 8.00061C24 6.58655 16 6.00061 12 9.8543V13.5006C13.9959 13.5006 15.9918 11.8543 17.9878 10.8543C21.1456 9.27217 24 9.00061 24 8.00061Z"
        fill="#4BA279"
      />
      <path
        d="M11.0484 14.9238C9.36658 14.9238 6.89489 17.6095 6.16915 16.8726C5.25473 15.9442 8.12083 12.0006 13 12.0006C13 13.7081 12.7301 14.9238 11.0484 14.9238Z"
        fill="#4BA279"
      />
      <path
        d="M12.9516 14.9238C14.6334 14.9238 17.1051 17.6095 17.8309 16.8726C18.7453 15.9442 15.8792 12.0006 11 12.0006C11 13.7081 11.2699 14.9238 12.9516 14.9238Z"
        fill="#4BA279"
      />
    </g>
    <defs>
      <clipPath id="clip0_7797_13268">
        <rect
          width={24}
          height={24}
          className="details"
          fill="white"
          transform="translate(0 0.000610352)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Grow48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={23}
      y={23}
      width={2}
      height={17}
      fill="#4BA279"
      className="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3333 46.0006C36.67 44.1167 30.4818 43.0796 23.9998 43.0796C17.5177 43.0796 11.3295 44.1167 5.66614 46.0007C8.9366 39.4944 15.9168 35.0005 23.9997 35.0005C32.0826 35.0005 39.0628 39.4944 42.3333 46.0006Z"
      fill="#4BA279"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3333 46.0006C36.67 44.1167 30.4818 43.0796 23.9998 43.0796C17.5177 43.0796 11.3295 44.1167 5.66614 46.0007C8.9366 39.4944 15.9168 35.0005 23.9997 35.0005C32.0826 35.0005 39.0628 39.4944 42.3333 46.0006Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M20 16.1538C20 7.30769 22 2 24 2C26 2 28 7.30769 28 16.1538C28 25 26 25 24 25C22 25 20 25 20 16.1538Z"
      fill="#4BA279"
    />
    <path
      d="M2 15.9666C2 13.3869 16.6667 12.318 24 19.3482V26C20.3409 26 16.6817 22.9967 13.0225 21.1724C7.23313 18.2862 2 17.7908 2 15.9666Z"
      fill="#4BA279"
    />
    <path
      d="M46 15.9666C46 13.3869 31.3333 12.318 24 19.3482V26C27.6591 26 31.3183 22.9967 34.9775 21.1724C40.7669 18.2862 46 17.7908 46 15.9666Z"
      fill="#4BA279"
    />
    <path
      d="M23.0967 29.8464C19.7332 29.8464 14.7898 35.2177 13.3383 33.7441C11.5095 31.8873 17.2417 24 27 24C27 27.415 26.4603 29.8464 23.0967 29.8464Z"
      fill="#4BA279"
    />
    <path
      d="M24.9033 29.8464C28.2668 29.8464 33.2102 35.2177 34.6617 33.7441C36.4905 31.8873 30.7583 24 21 24C21 27.415 21.5397 29.8464 24.9033 29.8464Z"
      fill="#4BA279"
    />
  </svg>
);

const SIZE_MAP = {
  md: Grow24,
  sm: Grow24,
  xl: Grow48,
  lg: Grow48,
  xxl: Grow48,
  xxxl: Grow48,
};

export const GrowIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'grow-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

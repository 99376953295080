import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Typography as TypographyV1 } from '@jane/shared/reefer-v1';
import { Typography as TypographyV2 } from '@jane/shared/reefer-v2';
import type {
  TypographyProps,
  TypographyVariant,
} from '@jane/shared/reefer-v2';

export const Typography = <T extends string = TypographyVariant>(
  props: TypographyProps<T>
) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TypographyV2<T> {...props} />;
  }
  return <TypographyV1<T> {...props} />;
};

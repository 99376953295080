import get from 'lodash/get';
import { useMemo } from 'react';

import type { SpecialRulesV2 } from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT, MODAL_CARD_WIDTH } from '@jane/business-admin/util';
import type { ProductThresholdConditions } from '@jane/shared/models';
import {
  Card,
  Flex,
  Form,
  Modal,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { RULE_OPTIONS } from '@jane/shared/util';

import { ConditionType, FORM_FIELD_DEFAULT_MARGIN } from '../form';
import { PriceConditions } from './PriceConditions';
import { ProductsConditions } from './ProductsConditions';
import { BrandConditions } from './brandConditions/BrandConditions';
import { CategoryConditions } from './categoryConditions/CategoryConditions';
import { convertRulesToString } from './convertRulesToString';
import { ConditionByCheckboxes } from './generic_components/ConditionByCheckboxes';
import { ConditionByModal } from './generic_components/ConditionByModal';
import { LoadingCondition } from './generic_components/LoadingCondition';
import { useUpdateIncludedRule } from './hooks/useUpdateIncludedRule';

interface Props {
  fieldPrefix: string;
  headerText?: string;
  isLoading: boolean;
  subHeaderText?: string;
}
export const ConditionsCard = ({
  fieldPrefix,
  headerText,
  isLoading,
  subHeaderText,
}: Props) => {
  const { setValue, watch, getValues } = useFormContext();
  const applyToWatch = watch(`${fieldPrefix}.apply_to`);
  const specialTypeWatch = watch('special_type');
  const rulesWatch = watch(`${fieldPrefix}.rules`);

  const specialTypeFormatted = useMemo(() => {
    if (!specialTypeWatch || specialTypeWatch === 'select') return null;
    return specialTypeWatch.split('_').join(' ');
  }, [specialTypeWatch]);

  const prices = useMemo(() => {
    return get(rulesWatch, 'product_threshold', {});
  }, [convertRulesToString(rulesWatch)]);

  const onPricesUpdate = (prices: ProductThresholdConditions) => {
    setValue(`${fieldPrefix}.rules.product_threshold`, prices, {
      shouldDirty: true,
    });
  };

  const onCategoriesUpdate = (
    kinds: any,
    includesOrExcludes: 'includes' | 'excludes'
  ) => {
    const rules =
      rulesWatch && rulesWatch[includesOrExcludes]
        ? rulesWatch[includesOrExcludes]
        : null;

    const firstCondition = rules?.[0] ?? {};

    const newRules = {
      ...rulesWatch,
      [includesOrExcludes]: [
        {
          ...firstCondition,
          kinds: kinds,
        },
      ],
    };

    if (specialTypeWatch === 'cart_total') {
      const cartTotalThresholdValue = getValues(
        'conditions.cart_total.threshold'
      );

      newRules['settings'] = {
        cart_threshold: cartTotalThresholdValue,
      };
    }

    setValue(`${fieldPrefix}.rules`, newRules, {
      shouldDirty: true,
    });
  };

  const { data: weights, onUpdate: onWeightsUpdate } = useUpdateIncludedRule(
    'weights',
    rulesWatch,
    (newRules: SpecialRulesV2) =>
      setValue(`${fieldPrefix}.rules`, newRules, { shouldDirty: true })
  );

  const { data: lineage, onUpdate: onLineageUpdate } = useUpdateIncludedRule(
    'lineages',
    rulesWatch,
    (newRules: SpecialRulesV2) =>
      setValue(`${fieldPrefix}.rules`, newRules, { shouldDirty: true })
  );

  if (
    specialTypeWatch !== 'product' &&
    specialTypeWatch !== 'cart_total' &&
    specialTypeWatch !== 'bundle'
  ) {
    return null;
  }

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Typography
            variant="header-bold"
            mb={subHeaderText ? 0 : FORM_FIELD_DEFAULT_MARGIN}
          >
            {headerText ? (
              headerText
            ) : (
              <>
                Conditions{' '}
                {!isLoading &&
                  specialTypeFormatted &&
                  `for ${specialTypeFormatted} special`}
              </>
            )}
          </Typography>
          {subHeaderText && (
            <Typography color={'grays-mid'} mb={FORM_FIELD_DEFAULT_MARGIN}>
              {subHeaderText}
            </Typography>
          )}
          <Flex flexDirection="column">
            <Typography variant="body-bold" mb={FORM_FIELD_DEFAULT_MARGIN}>
              Apply to
            </Typography>
            {isLoading ? (
              <Skeleton direction="row" animate>
                <Skeleton.Bone height={BODY_LINE_HEIGHT} width="20%" />
                <Skeleton.Bone height={BODY_LINE_HEIGHT} ml={12} width="20%" />
                <Skeleton.Bone height={BODY_LINE_HEIGHT} ml={12} width="20%" />
              </Skeleton>
            ) : (
              <Form.RadioFieldGroup
                name={`${fieldPrefix}.apply_to`}
                row={true}
                defaultChecked="all"
                options={[
                  {
                    id: RULE_OPTIONS[0].value,
                    label: 'All Products',
                    value: RULE_OPTIONS[0].value,
                  },
                  {
                    id: RULE_OPTIONS[1].value,
                    label: 'Selected Products',
                    value: RULE_OPTIONS[1].value,
                  },
                  {
                    id: RULE_OPTIONS[2].value,
                    label: 'Advanced',
                    value: RULE_OPTIONS[2].value,
                  },
                ]}
              />
            )}
          </Flex>
          <Modal.ContentDivider />
          {isLoading ? (
            <>
              <LoadingCondition />
              <LoadingCondition />
              <LoadingCondition />
            </>
          ) : (
            <>
              {applyToWatch === RULE_OPTIONS[2].value && (
                <>
                  <CategoryConditions
                    fieldPrefix={fieldPrefix}
                    type={ConditionType.Categories}
                    onConditionsUpdate={onCategoriesUpdate}
                  />
                  <ConditionByModal
                    onOpenModal={() =>
                      alert('TODO: Add a category when modal opens')
                    }
                    type={ConditionType.ExcludeCategories}
                    conditionsLoading={false}
                    conditions={[]}
                    onConditionsUpdate={(data) => console.log(data)}
                    lastCondition={true}
                  />
                  <BrandConditions
                    fieldPrefix={fieldPrefix}
                    type={ConditionType.Brands}
                  />
                  <BrandConditions
                    fieldPrefix={fieldPrefix}
                    type={ConditionType.ExcludeBrands}
                  />
                  <ConditionByCheckboxes
                    fieldPrefix={fieldPrefix}
                    conditions={lineage}
                    onConditionsUpdate={onLineageUpdate}
                    type={ConditionType.Lineage}
                  />
                </>
              )}
              {applyToWatch === RULE_OPTIONS[1].value && (
                <ProductsConditions
                  fieldPrefix={fieldPrefix}
                  type={ConditionType.Products}
                />
              )}
              <ConditionByCheckboxes
                fieldPrefix={fieldPrefix}
                conditions={weights}
                onConditionsUpdate={onWeightsUpdate}
                type={ConditionType.Weight}
              />
              <PriceConditions
                fieldPrefix={fieldPrefix}
                conditions={prices}
                onConditionsUpdate={onPricesUpdate}
                type={ConditionType.Price}
                lastCondition={applyToWatch === RULE_OPTIONS[1].value}
              />
              {applyToWatch !== RULE_OPTIONS[1].value && (
                <ProductsConditions
                  fieldPrefix={fieldPrefix}
                  type={ConditionType.ExcludeProducts}
                />
              )}
            </>
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};

import type {
  QueryFunctionContext,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import { search } from '@jane/search/data-access';
import type {
  AlgoliaProduct,
  JaneSearchState,
  SearchResponse,
} from '@jane/search/types';
import {
  buildGoldUserSegmentsFilter,
  buildMenuProductFilterString,
  composeFilters,
} from '@jane/search/util';
import { useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { Id } from '@jane/shared/models';
import type { UserSegmentIds } from '@jane/shared/types';

interface UseProductDetailsQueryProps {
  enabled: boolean;
  janeDeviceId: string;
  productIds: number[];
  productsPerPage: number;
  searchState: JaneSearchState<AlgoliaProduct>;
  storeId: Id;
}

type UseProductDetailsQueryResponse = UseInfiniteQueryResult<QueryFnResponse>;

export const useProductDetailsQuery = ({
  enabled,
  janeDeviceId,
  productIds,
  productsPerPage,
  searchState,
  storeId,
}: UseProductDetailsQueryProps): UseProductDetailsQueryResponse => {
  const goldSegmentationFlag = useFlag(FLAGS.janeGoldUserSegmentation);
  const { data: userSegments } = useUserSegmentIds(janeDeviceId);
  const goldFilterValues = searchState.filters?.['has_brand_discount'] ?? [];
  const isFilteredByGold = goldFilterValues?.length > 0;
  const enableSegmentation = goldSegmentationFlag && isFilteredByGold;

  const query = useInfiniteQuery({
    enabled,
    getNextPageParam: (
      lastPage: QueryFnResponse,
      allPages: QueryFnResponse[]
    ): number | undefined => {
      if (!productIds) {
        return undefined;
      }
      const nextPageParam = lastPage.pageParam + 1;
      const hasNextPage = nextPageParam * productsPerPage < productIds.length;
      return hasNextPage ? nextPageParam : undefined;
    },
    queryKey: [
      'smart-sort-product-details',
      storeId,
      productIds,
      productsPerPage,
      userSegments,
      enableSegmentation,
    ],
    queryFn: async (context: QueryFunctionContext) =>
      queryFn(context, {
        enableSegmentation,
        storeId,
        productIds,
        productsPerPage,
        userSegments,
      }),
  });
  return query;
};

export interface QueryFnResponse {
  pageParam: number;
  products: AlgoliaProduct[];
  searchResultFacets: SearchResponse['facets'];
}
const queryFn = async (
  context: QueryFunctionContext,
  {
    enableSegmentation,
    productIds,
    productsPerPage,
    storeId,
    userSegments,
  }: {
    enableSegmentation: boolean;
    productIds: number[] | undefined;
    productsPerPage: number;
    storeId: Id;
    userSegments?: UserSegmentIds;
  }
): Promise<QueryFnResponse> => {
  const { pageParam = 0 } = context;
  if (!productIds || !storeId) {
    return {
      pageParam,
      products: [],
      searchResultFacets: {},
    };
  }
  const productsStart = pageParam * productsPerPage;
  const productsEnd = (pageParam + 1) * productsPerPage;
  const productsPage = productIds.slice(productsStart, productsEnd);
  const staticFilters = composeFilters(
    buildMenuProductFilterString(storeId, productIds),
    enableSegmentation ? buildGoldUserSegmentsFilter(userSegments) : ''
  );
  const result = await search<AlgoliaProduct>({
    indexPrefix: 'menu-products-',
    facets: ['*'],
    staticFilters,
    hitsPerPage: productsPage.length,
  });

  return {
    pageParam,
    products: result.hits,
    searchResultFacets: {},
  };
};

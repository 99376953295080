import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

import { hidden } from '../../styling';
import type { ReeferBaseProps } from '../../types';

export interface InnerHideProps extends ReeferBaseProps {
  /** Render the `Hide` component as this HTML element. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;

  /** Hides the children of this component */
  isHidden?: boolean;
}

export type HideProps = PropsWithChildren<InnerHideProps>;

const HiddenSection = styled.div(({ hidden: isHidden }) => ({
  ...hidden(isHidden),
}));

/**
 * Hide is a simple helper component that can be used to programmatically hide content.
 * This can be useful for accessibility purposes, as well as many other things.
 */
export function Hide({
  as = 'div',
  children,
  className,
  'data-testid': testId,
  id,
  isHidden = false,
  style,
}: HideProps) {
  return (
    <HiddenSection
      as={as}
      className={className}
      data-testid={testId}
      hidden={isHidden}
      id={id}
      style={style}
    >
      {children}
    </HiddenSection>
  );
}

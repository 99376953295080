import { createContext, useContext } from 'react';

import type { ToastContentProps } from '../toast.types';

export interface ToastContextProps {
  add: (content: Omit<ToastContentProps, 'id'>) => void;
  clear: () => void;
  toasts: ToastContentProps[];
}

export const ToastContext = createContext<ToastContextProps>({
  add: (content: Omit<ToastContentProps, 'id'>) => null,
  clear: () => null,
  toasts: [],
});

export const useToast = () => useContext(ToastContext);

import type { SVGProps } from 'react';

import type { IconProps } from '@jane/shared/reefer';
import { Icon } from '@jane/shared/reefer';

const Eyedropper = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m16.856 10.856.458.458a1.501 1.501 0 0 1 0 2.122l-.659.659a.75.75 0 0 1-1.06 0l-5.69-5.69a.75.75 0 0 1 0-1.06l.66-.66a1.5 1.5 0 0 1 2.12 0l.459.459 2.586-2.587c1.012-1.011 2.66-1.073 3.698-.09a2.625 2.625 0 0 1 .053 3.764l-2.625 2.625Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14.871 13.371-5.25 5.25a2.998 2.998 0 0 1-2.898.777l-2.194.958a.75.75 0 0 1-.83-.157v0a.539.539 0 0 1-.113-.596l1.016-2.326a2.999 2.999 0 0 1 .777-2.898l5.25-5.25"
    />
  </svg>
);

export const EyedropperIcon = ({ ...props }: Omit<IconProps, 'icon'>) => {
  return <Icon icon={Eyedropper} {...props} />;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width: 768px) {\n  .mwfSBw9PEMUPLFvzF8Tw {\n    flex-direction: column;\n  }\n}\n\n.fuocFgu45P0UcxFPsZ5d:not(:focus):not(:active) {\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  overflow: hidden;\n  opacity: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n}\n\n._Wxj_KJOiEYXvlk74q8H {\n  order: 3;\n}\n\n@media (max-width: 768px) {\n  .GsS5AIptxshYpZsRQ2ya {\n    margin-top: 16px;\n  }\n}\n\n.VdTQGOZh3rk28Q9PZJld {\n  opacity: 0.3;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/forms/fields/sliderField/doubleSliderField.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,YAAY;AACd","sourcesContent":["@media (min-width: 768px) {\n  .doubleSlider_fieldset {\n    flex-direction: column;\n  }\n}\n\n.doubleSlider_legend__isHidden:not(:focus):not(:active) {\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  overflow: hidden;\n  opacity: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n}\n\n.doubleSlider_helperText {\n  order: 3;\n}\n\n@media (max-width: 768px) {\n  .doubleSlider_helperText__labelHidden {\n    margin-top: 16px;\n  }\n}\n\n.doubleSlider_sliders__disabled {\n  opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doubleSlider_fieldset": "mwfSBw9PEMUPLFvzF8Tw",
	"doubleSlider_legend__isHidden": "fuocFgu45P0UcxFPsZ5d",
	"doubleSlider_helperText": "_Wxj_KJOiEYXvlk74q8H",
	"doubleSlider_helperText__labelHidden": "GsS5AIptxshYpZsRQ2ya",
	"doubleSlider_sliders__disabled": "VdTQGOZh3rk28Q9PZJld"
};
export default ___CSS_LOADER_EXPORT___;

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const LocationPin24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4162 17.4975C16.3381 15.0049 18 11.9478 18 9C18 5.68707 15.3129 3 12 3C8.68707 3 6 5.68707 6 9C6 11.9478 7.66186 15.0049 9.58383 17.4975C10.4484 18.6187 11.3166 19.5629 12 20.2532C12.6834 19.5629 13.5516 18.6187 14.4162 17.4975ZM12 23C12 23 20 16.25 20 9C20 4.5825 16.4175 1 12 1C7.5825 1 4 4.5825 4 9C4 16.25 12 23 12 23Z"
      fill="#0E0E0E"
    />
    <path
      d="M14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: LocationPin24,
  sm: LocationPin24,
  lg: LocationPin24,
  xl: LocationPin24,
  xxl: LocationPin24,
  xxxl: LocationPin24,
};

export const LocationPinIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'location-pin-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import styled from '@emotion/styled';

import { mediaQueries, shadows, spacing } from '../../../styling';
import {
  DESKTOP_PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../drawer.styles';

interface StyledDrawerFooterProps {
  noPadding?: boolean;
}

export const StyledDrawerFooter = styled.div<StyledDrawerFooterProps>(
  ({ noPadding }) => ({
    boxShadow: shadows.dividerTop,
    justifySelf: 'flex-end',
    ...spacing({
      px: noPadding ? 0 : MOBILE_PADDING_HORIZONTAL,
      py: noPadding ? 0 : PADDING_VERTICAL,
    }),
    [mediaQueries.tablet('min')]: {
      ...spacing({
        px: noPadding ? 0 : DESKTOP_PADDING_HORIZONTAL,
      }),
    },
  })
);

import get from 'lodash/get';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { useFetchBrands } from '@jane/business-admin/data-access';
import type { Brand } from '@jane/business-admin/types';
import { ModalNames } from '@jane/business-admin/util';
import {
  Button,
  Flex,
  Modal,
  SearchField,
  Skeleton,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../../ConfirmWrapperWithTracking';
import { ConditionType } from '../../form';
import { convertRulesToString } from '.././convertRulesToString';
import type { Condition } from '../generic_components/ConditionByModal';
import { ConditionByModal } from '../generic_components/ConditionByModal';
import { LoadingCondition } from '../generic_components/LoadingCondition';
import { BrandCheckboxRow } from './BrandCheckboxRow';

const BrandPickerLoading = () => {
  const length10Array = Array(10)
    .fill(null)
    .map((_, index) => index);

  return (
    <>
      {length10Array.map((i) => (
        <Skeleton
          animate
          height={72}
          style={{
            boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
          }}
          key={i}
        >
          <Flex py={12} px={24} height={72} alignItems="center">
            <Skeleton.Bone borderRadius="circular" width={46} height={46} />
            <Skeleton.Bone width="90%" ml={24} />
          </Flex>
        </Skeleton>
      ))}
    </>
  );
};

export const BrandConditions = ({
  fieldPrefix,
  type,
}: {
  fieldPrefix: string;
  type: ConditionType.Brands | ConditionType.ExcludeBrands;
}) => {
  const { id: storeId = '' } = useParams<'id'>();
  const [query, setQuery] = useState('');
  const [brandConditionsModalOpen, setBrandConditionsModalOpen] =
    useState(false);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const wrapperRef = useRef(null);

  const {
    data: allBrands,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchBrands(storeId, { name: query, order: 'asc' });

  const { ref } = useInView({
    root: wrapperRef.current,
    threshold: 0,
    onChange: (inView) => {
      if (inView && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  const { setValue, watch } = useFormContext();

  const rulesWatch = watch(`${fieldPrefix}.rules`);

  const includesOrExcludes =
    type === ConditionType.Brands ? 'includes' : 'excludes';

  const rules =
    rulesWatch && rulesWatch[includesOrExcludes]
      ? rulesWatch[includesOrExcludes]
      : null;

  const brandMap = useMemo(() => {
    return get(rulesWatch, `${includesOrExcludes}.0.brands`, []);
  }, [convertRulesToString(rulesWatch)]);

  const selectedBrandsWithDetails = allBrands?.filter((brand) =>
    brandMap.includes(brand.name)
  );

  const brandConditions = selectedBrandsWithDetails?.map((brand) => ({
    value: brand['productBrandId'].toString(),
    label: brand['name'],
  }));

  const onSubmit = (data: Condition[] | Brand[], fromModal?: boolean) => {
    const values = fromModal
      ? (data as Brand[]).map(({ name }) => `${name}`)
      : (data as Condition[]).map(({ label }) => `${label}`);

    const firstCondition = rules?.[0] ?? {};

    setValue(
      `${fieldPrefix}.rules`,
      {
        ...rulesWatch,
        [includesOrExcludes]: [{ ...firstCondition, brands: values }],
      },
      { shouldDirty: true }
    );

    setQuery('');
    setBrandConditionsModalOpen(false);
  };

  const select = (brand: Brand) => {
    // We are de-selecting a brand, so we filter it out
    const newSelectedBrands = selectedBrands.filter(
      (selectedBrand) => selectedBrand.id !== brand.id
    );

    // We are selecting a brand since our newly selected brand was not found in the filter operation and our lengths remain the same
    if (selectedBrands.length === newSelectedBrands.length) {
      newSelectedBrands.push(brand);
    }

    setSelectedBrands(newSelectedBrands);
  };

  const isSelected = (brand: Brand) => {
    return selectedBrands.some(
      (selectedBrand) => selectedBrand.id === brand.id
    );
  };

  useEffect(() => {
    if (!selectedBrandsWithDetails) {
      return;
    }

    setSelectedBrands(selectedBrandsWithDetails);
  }, [JSON.stringify(selectedBrandsWithDetails)]);

  return (
    <>
      {!brandConditions ? (
        <LoadingCondition />
      ) : (
        <ConditionByModal
          onOpenModal={() => setBrandConditionsModalOpen(true)}
          type={type}
          conditions={brandConditions}
          conditionsLoading={isFetching}
          onConditionsUpdate={(data) => onSubmit(data)}
        />
      )}
      <ConfirmWrapperWithTracking
        open={brandConditionsModalOpen}
        setOpen={() => {
          setQuery('');
          setBrandConditionsModalOpen(false);
        }}
        hasChanges={selectedBrands.length !== brandConditions?.length}
        modalName={ModalNames.BrandConditions}
        overlayClose={true}
      >
        <>
          <Modal.Header
            title="Select brands"
            actions={
              <Button
                variant="primary"
                label="Save"
                onClick={() => onSubmit(selectedBrands, true)}
                disabled={selectedBrands.length === 0}
              />
            }
          />
          <Modal.Content ref={wrapperRef}>
            <SearchField
              borderRadius="sm"
              label="Search Brands"
              name="brand-search-field"
              onChange={setQuery}
              placeholder="Search for a brand"
              mb={24}
            />
            {isFetching ? (
              <BrandPickerLoading />
            ) : (
              <>
                {allBrands?.map((brand, i) => (
                  <BrandCheckboxRow
                    key={brand.id}
                    brand={brand}
                    onClick={() => select(brand)}
                    selected={isSelected(brand)}
                    divider={i > 0}
                    ref={i === allBrands.length - 1 ? ref : undefined}
                  />
                ))}
              </>
            )}
          </Modal.Content>
        </>
      </ConfirmWrapperWithTracking>
    </>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Check16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.711 2.79681C16.0994 3.18949 16.0959 3.82264 15.7032 4.211L6.60319 13.211C6.21358 13.5963 5.58642 13.5963 5.19681 13.211L0.296811 8.36485C-0.0958652 7.97649 -0.0993633 7.34333 0.288997 6.95066C0.677358 6.55798 1.31051 6.55448 1.70319 6.94284L5.9 11.0935L14.2968 2.789C14.6895 2.40064 15.3226 2.40413 15.711 2.79681Z"
      fill="#0E0E0E"
    />
  </svg>
);

const Check24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7071 4.29289C23.0976 4.68342 23.0976 5.31658 22.7071 5.70711L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071L1.29289 11.7071C0.902368 11.3166 0.902368 10.6834 1.29289 10.2929C1.68342 9.90237 2.31658 9.90237 2.70711 10.2929L9 16.5858L21.2929 4.29289C21.6834 3.90237 22.3166 3.90237 22.7071 4.29289Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  sm: Check16,
  md: Check24,
  lg: Check24,
  xl: Check24,
  xxl: Check24,
  xxxl: Check24,
};

export const CheckIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'check-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

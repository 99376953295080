import { getNodeText } from '../../../internal/utils';
import { NewTabLabel } from '../../link/link.styles';
import type { IconButtonProps } from '../button.types';
import { iconWithProps } from '../button.utils';
import {
  StyledFakeIconButton,
  StyledIconButton,
  StyledIconLink,
  StyledIconRouterLink,
} from './iconButton.styles';

/**
 * `Button.Icons` components are similar to `Button` components, but only display an icon as their label.
 */

export function IconButton({
  ariaLabel,
  className,
  'data-testid': testId,
  disabled = false,
  form,
  full,
  href,
  icon,
  id,
  label,
  onClick,
  size = 'default',
  style,
  target,
  to,
  type = 'button',
  variant = 'minimal',
  ...props
}: IconButtonProps) {
  const fakeButton = !onClick && type === 'button';

  const stringLabel = getNodeText(label);

  const sharedProps = {
    'aria-label': ariaLabel,
    className,
    'data-testid': testId,
    disabled,
    full,
    id,
    name: stringLabel,
    onClick,
    size,
    style,
    variant,
    ...props,
  };

  const accessibleIcon = iconWithProps(icon, stringLabel);

  if (to) {
    return (
      <StyledIconRouterLink target={target || '_self'} to={to} {...sharedProps}>
        {accessibleIcon}
      </StyledIconRouterLink>
    );
  }

  if (href) {
    return (
      <StyledIconLink
        target={target || '_blank'}
        rel="noopener noreferrer"
        href={href}
        {...sharedProps}
      >
        <NewTabLabel>Opens in new window</NewTabLabel>
        {accessibleIcon}
      </StyledIconLink>
    );
  }

  if (!href && !to && fakeButton) {
    return (
      <StyledFakeIconButton {...sharedProps}>
        {accessibleIcon}
      </StyledFakeIconButton>
    );
  }

  return (
    <StyledIconButton form={form} type={type} {...sharedProps}>
      {accessibleIcon}
    </StyledIconButton>
  );
}

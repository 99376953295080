/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Eye24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9999 12C15.9999 14.2091 14.209 16 11.9999 16C9.79074 16 7.99988 14.2091 7.99988 12C7.99988 9.79086 9.79074 8 11.9999 8C14.209 8 15.9999 9.79086 15.9999 12ZM13.9999 12C13.9999 13.1046 13.1044 14 11.9999 14C10.8953 14 9.99988 13.1046 9.99988 12C9.99988 10.8954 10.8953 10 11.9999 10C13.1044 10 13.9999 10.8954 13.9999 12Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5816 9.95719C23.5155 11.1589 23.5155 12.8419 22.5815 14.0436C16.4089 21.9859 7.59085 21.9853 1.41822 14.0428C0.484288 12.8411 0.484287 11.1581 1.41824 9.95636C7.59087 2.01409 16.4089 2.01472 22.5816 9.95719ZM21.0024 12.8163C18.1972 16.4258 14.9866 18.0001 12 18C9.01333 17.9999 5.80267 16.4251 2.9974 12.8155C2.62456 12.3358 2.62457 11.6634 2.99739 11.1837C5.80261 7.57422 9.01319 5.99986 11.9998 6C14.9864 6.00015 18.1971 7.57486 21.0024 11.1845C21.3752 11.6642 21.3752 12.3366 21.0024 12.8163Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Eye24,
  sm: Eye24,
  lg: Eye24,
  xl: Eye24,
  xxl: Eye24,
  xxxl: Eye24,
};

export const EyeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'eye-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const HideEye24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.707 4.70711C21.0975 4.31658 21.0975 3.68342 20.707 3.29289C20.3165 2.90237 19.6833 2.90237 19.2928 3.29289L3.29279 19.2929C2.90226 19.6834 2.90226 20.3166 3.29279 20.7071C3.68331 21.0976 4.31648 21.0976 4.707 20.7071L20.707 4.70711Z"
      fill="#0E0E0E"
    />
    <path
      d="M1.41824 9.95636C5.40211 4.83035 10.4879 3.01298 15.2533 4.50395L13.6061 6.15115C13.066 6.04955 12.5294 6.00003 11.9998 6C9.01319 5.99985 5.80261 7.57421 2.99739 11.1837C2.62457 11.6634 2.62456 12.3358 2.9974 12.8155C3.60617 13.5988 4.23403 14.2863 4.87455 14.8827L3.46009 16.2972C2.75403 15.6346 2.07132 14.8832 1.41822 14.0428C0.484288 12.8411 0.484287 11.1581 1.41824 9.95636Z"
      fill="#0E0E0E"
    />
    <path
      d="M12 18C11.4704 18 10.9338 17.9504 10.3937 17.8489L8.74648 19.496C13.5118 20.987 18.5977 19.1696 22.5815 14.0436C23.5155 12.8419 23.5155 11.1589 22.5816 9.95719C21.9285 9.11683 21.2457 8.36539 20.5397 7.70285L19.1252 9.1173C19.7657 9.71366 20.3936 10.4012 21.0024 11.1845C21.3752 11.6642 21.3752 12.3366 21.0024 12.8163C18.1972 16.4258 14.9866 18.0001 12 18Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: HideEye24,
  sm: HideEye24,
  lg: HideEye24,
  xl: HideEye24,
  xxl: HideEye24,
  xxxl: HideEye24,
};

export const HideEyeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'hide-eye-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

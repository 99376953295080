/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Lemon24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13041_31273)">
      <path
        d="M21.7304 2.27011C24.4333 4.97299 22.5259 5.18954 23.0453 6.36092C24.9804 10.7246 23.9602 16.2576 20.1086 20.1091C16.2571 23.9606 10.7241 24.9809 6.36043 23.0458C5.18905 22.5264 3.17058 22.6318 2.26962 21.7309C1.36866 20.8299 1.4741 18.8114 0.954663 17.6401C-0.980375 13.2763 0.0398365 7.74336 3.89135 3.89184C7.74287 0.0403242 13.2759 -0.979887 17.6396 0.955151C18.8109 1.47458 19.0275 -0.432772 21.7304 2.27011Z"
        fill="#FFC220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51312 7.13543C5.06529 6.6876 5.06529 5.96153 5.51312 5.5137C6.90339 4.12343 8.72285 3.22517 10.5056 2.77948C12.2757 2.33695 14.1288 2.31163 15.6061 2.80408C16.2069 3.00436 16.5317 3.65378 16.3314 4.2546C16.1311 4.85543 15.4817 5.18014 14.8809 4.97986C13.9256 4.66145 12.5352 4.63613 11.0619 5.00447C9.60117 5.36964 8.17717 6.09311 7.13485 7.13543C6.68702 7.58326 5.96095 7.58326 5.51312 7.13543Z"
        fill="#FFC220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51312 7.13543C5.06529 6.6876 5.06529 5.96153 5.51312 5.5137C6.90339 4.12343 8.72285 3.22517 10.5056 2.77948C12.2757 2.33695 14.1288 2.31163 15.6061 2.80408C16.2069 3.00436 16.5317 3.65378 16.3314 4.2546C16.1311 4.85543 15.4817 5.18014 14.8809 4.97986C13.9256 4.66145 12.5352 4.63613 11.0619 5.00447C9.60117 5.36964 8.17717 6.09311 7.13485 7.13543C6.68702 7.58326 5.96095 7.58326 5.51312 7.13543Z"
        fill="white"
        fillOpacity={0.8}
      />
    </g>
    <defs>
      <clipPath id="clip0_13041_31273">
        <rect
          width={24}
          height={24}
          fill="white"
          transform="translate(0 0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Lemon24,
  sm: Lemon24,
  lg: Lemon24,
  xl: Lemon24,
  xxl: Lemon24,
  xxxl: Lemon24,
};

export const LemonIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'lemon-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import styled from '@emotion/styled';

import { mediaQueries } from '@jane/shared/reefer-emotion';

import type {
  CarouselTrackProps,
  CarouselWrapperProps,
} from '../carousel.types';

export const CarouselWrapper = styled.div<CarouselWrapperProps>(
  ({
    desktopHeight,
    desktopWidth,
    mobileHeight,
    mobileWidth,
    variant,
    isLightbox,
    theme,
  }) => ({
    height: mobileHeight,
    maxHeight: mobileHeight,
    position: 'relative',
    width: mobileWidth,
    overflow: 'hidden',
    backfaceVisibility: 'hidden',
    transform: 'translate3d(0, 0, 0)',
    cursor: isLightbox ? 'auto' : 'pointer',
    [mediaQueries.tablet('min')]: {
      ...(variant === 'product'
        ? {
            height: desktopHeight,
            maxHeight: desktopHeight,
            width: desktopWidth,
            borderRadius: theme.borderRadius.lg,
          }
        : {
            height: isLightbox ? '640px' : desktopHeight,
            maxHeight: isLightbox ? '640px' : desktopHeight,
            width: isLightbox ? '812px' : '100vw',
            borderRadius: isLightbox ? theme.borderRadius.lg : 'none',
          }),
    },
  })
);

export const CarouselButtonWrapper = styled.span({
  display: 'none',
  [mediaQueries.tablet('min')]: {
    display: 'block',
  },
});

export const CarouselTrack = styled.div<CarouselTrackProps>(
  ({ activeIndex }) => ({
    position: 'relative',
    whiteSpace: 'nowrap',
    transition: 'transform 0.3s',
    transform: `translateX(-${activeIndex * 100}%)`,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  })
);

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Card24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.81241 16.9957L4.01364 18.4275C4.32109 20.6151 6.34376 22.1393 8.5314 21.8318L20.4146 20.1618C22.6023 19.8543 24.1265 17.8316 23.819 15.644L22.8448 8.71212C22.6165 7.08771 21.4425 5.82911 19.9575 5.41457C19.9855 5.60566 20 5.80113 20 6V7.6093C20.4544 7.91516 20.782 8.40535 20.8643 8.99046L21.8385 15.9223C21.9922 17.0162 21.2301 18.0275 20.1363 18.1812L8.25306 19.8513C7.15924 20.005 6.1479 19.2429 5.99417 18.1491L5.83268 17H4C3.93712 17 3.87458 16.9985 3.81241 16.9957Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4H4C2.89543 4 2 4.89543 2 6V13C2 14.1046 2.89543 15 4 15H16C17.1046 15 18 14.1046 18 13V6C18 4.89543 17.1046 4 16 4ZM4 2C1.79086 2 0 3.79086 0 6V13C0 15.2091 1.79086 17 4 17H16C18.2091 17 20 15.2091 20 13V6C20 3.79086 18.2091 2 16 2H4Z"
      fill="#0E0E0E"
    />
    <path
      d="M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M11 12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12C9 11.4477 9.44772 11 10 11C10.5523 11 11 11.4477 11 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M14 12C14 12.5523 13.5523 13 13 13C12.4477 13 12 12.5523 12 12C12 11.4477 12.4477 11 13 11C13.5523 11 14 11.4477 14 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z"
      fill="#0E0E0E"
    />
    <path
      d="M13 7C13 6.44772 13.4477 6 14 6H16C16.5523 6 17 6.44772 17 7V8C17 8.55228 16.5523 9 16 9H14C13.4477 9 13 8.55228 13 8V7Z"
      fill="#0E0E0E"
    />
  </svg>
);

const Card32 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.11338 22.6207L5.38327 24.494C5.79564 27.3564 8.50854 29.3507 11.4427 28.9484L27.381 26.7632C30.3152 26.361 32.3595 23.7145 31.9471 20.8521L30.6405 11.7823C30.3343 9.65687 28.7596 8.01009 26.7679 7.4677C26.8054 7.71772 26.8249 7.97349 26.8249 8.23369V10.3393C27.4344 10.7395 27.8738 11.3809 27.9841 12.1465L29.2907 21.2163C29.4969 22.6475 28.4748 23.9707 27.0077 24.1719L11.0694 26.357C9.60229 26.5582 8.24584 25.561 8.03966 24.1299L7.82305 22.6263H5.36498C5.28065 22.6263 5.19677 22.6244 5.11338 22.6207Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4599 5.61684H5.36498C3.88348 5.61684 2.68249 6.78845 2.68249 8.23369V17.3926C2.68249 18.8379 3.88348 20.0095 5.36498 20.0095H21.4599C22.9414 20.0095 24.1424 18.8379 24.1424 17.3926V8.23369C24.1424 6.78845 22.9414 5.61684 21.4599 5.61684ZM5.36498 3C2.40198 3 0 5.3432 0 8.23369V17.3926C0 20.2831 2.40198 22.6263 5.36498 22.6263H21.4599C24.4229 22.6263 26.8249 20.2831 26.8249 17.3926V8.23369C26.8249 5.3432 24.4229 3 21.4599 3H5.36498Z"
      fill="#0E0E0E"
    />
    <path
      d="M6.70623 16.0842C6.70623 16.8068 6.10573 17.3926 5.36498 17.3926C4.62423 17.3926 4.02374 16.8068 4.02374 16.0842C4.02374 15.3616 4.62423 14.7758 5.36498 14.7758C6.10573 14.7758 6.70623 15.3616 6.70623 16.0842Z"
      fill="#0E0E0E"
    />
    <path
      d="M10.73 16.0842C10.73 16.8068 10.1295 17.3926 9.38872 17.3926C8.64797 17.3926 8.04747 16.8068 8.04747 16.0842C8.04747 15.3616 8.64797 14.7758 9.38872 14.7758C10.1295 14.7758 10.73 15.3616 10.73 16.0842Z"
      fill="#0E0E0E"
    />
    <path
      d="M14.7537 16.0842C14.7537 16.8068 14.1532 17.3926 13.4125 17.3926C12.6717 17.3926 12.0712 16.8068 12.0712 16.0842C12.0712 15.3616 12.6717 14.7758 13.4125 14.7758C14.1532 14.7758 14.7537 15.3616 14.7537 16.0842Z"
      fill="#0E0E0E"
    />
    <path
      d="M18.7774 16.0842C18.7774 16.8068 18.1769 17.3926 17.4362 17.3926C16.6954 17.3926 16.0949 16.8068 16.0949 16.0842C16.0949 15.3616 16.6954 14.7758 17.4362 14.7758C18.1769 14.7758 18.7774 15.3616 18.7774 16.0842Z"
      fill="#0E0E0E"
    />
    <path
      d="M22.8012 16.0842C22.8012 16.8068 22.2007 17.3926 21.4599 17.3926C20.7192 17.3926 20.1187 16.8068 20.1187 16.0842C20.1187 15.3616 20.7192 14.7758 21.4599 14.7758C22.2007 14.7758 22.8012 15.3616 22.8012 16.0842Z"
      fill="#0E0E0E"
    />
    <path
      d="M17.4362 9.54211C17.4362 8.81949 18.0367 8.23369 18.7774 8.23369H21.4599C22.2007 8.23369 22.8012 8.81949 22.8012 9.54211V10.8505C22.8012 11.5732 22.2007 12.159 21.4599 12.159H18.7774C18.0367 12.159 17.4362 11.5732 17.4362 10.8505V9.54211Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Card24,
  sm: Card24,
  lg: Card32,
  xl: Card32,
  xxl: Card32,
  xxxl: Card32,
};

export const CardIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'card-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

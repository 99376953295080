import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import wavvy from '../../../../../../../shared/assets/reefer/loading/wavvy.svg';
import type { LoadingColor, LoadingSize } from './loading';

interface StyleLoadingProps {
  color?: LoadingColor;
  size: LoadingSize;
}

const wavvyWidth = {
  xs: '24px',
  sm: '48px',
  lg: '72px',
};

const wavvyHeight = {
  xs: '16px',
  sm: '24px',
  lg: '36px',
};

const calculateAnimation = (size: LoadingSize) => {
  const wavvyAnimation = keyframes`
  0% { left: 0px; }
  100% { left: -${wavvyWidth[size]}; }
  `;

  return `${wavvyAnimation} 800ms linear infinite`;
};

export const LoadingContainer = styled.div<StyleLoadingProps>(({ size }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: wavvyWidth[size],
  overflow: 'hidden',
}));

export const AnimatedContainer = styled.span<StyleLoadingProps>(({ size }) => ({
  position: 'relative',
  width: '100%',
  animation: calculateAnimation(size),
}));

export const Wavvy = styled.div<StyleLoadingProps>(
  ({ theme, color, size }) => ({
    backgroundColor:
      color === 'purple'
        ? theme.colors.primary.main
        : color === 'black'
        ? '#000000'
        : '#FFFFFF',
    maskImage: `url(${wavvy})`,
    maskRepeat: 'no-repeat',
    maskPosition: '0 50%',
    maskSize: `calc(${wavvyWidth[size]}*2)`,
    height: wavvyHeight[size],
    width: 'calc(100% + 100px)',
  })
);

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Merch24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6963_13049)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1427 15.737L16.4985 7.95312C16.6579 7.42632 17.2131 7.12739 17.7406 7.28428L22.0368 8.56187C22.568 8.71983 22.8695 9.27964 22.7089 9.81006L20.356 17.5847C19.6932 19.7745 18.0593 21.5452 15.9178 22.3943L12.4488 23.7698C10.7857 24.4293 8.89811 23.6274 8.23284 21.9787C7.56758 20.3301 8.37653 18.459 10.0397 17.7996L13.5087 16.4241C13.8146 16.3028 14.048 16.0499 14.1427 15.737Z"
        fill="#4BA279"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14271 8.73703L9.49848 0.953122C9.65791 0.426321 10.2131 0.127391 10.7406 0.284277L15.0368 1.56187C15.568 1.71983 15.8695 2.27964 15.7089 2.81006L13.356 10.5847C12.6932 12.7745 11.0593 14.5452 8.91784 15.3943L5.44885 16.7698C3.78568 17.4293 1.89811 16.6274 1.23284 14.9787C0.567575 13.3301 1.37653 11.459 3.0397 10.7996L6.50869 9.42412C6.81461 9.30282 7.04803 9.04986 7.14271 8.73703Z"
        fill="#4BA279"
      />
      <path
        d="M16.5486 7.82117C16.529 7.86333 16.5122 7.90736 16.4984 7.95311L15.9598 9.7326L22.1685 11.5952L22.7088 9.81005C22.7221 9.76627 22.7322 9.7223 22.7392 9.67837L16.5486 7.82117Z"
        className="details"
        fill="black"
        fillOpacity={0.5}
      />
      <path
        d="M21.9155 12.4312L15.7068 10.5686L15.1274 12.4829L21.3362 14.3455L21.9155 12.4312Z"
        className="details"
        fill="black"
        fillOpacity={0.5}
      />
      <path
        d="M9.56491 0.787781C9.53835 0.839925 9.51602 0.895132 9.49848 0.953097L8.97053 2.69753L15.1793 4.56015L15.7089 2.81003C15.7259 2.75407 15.7377 2.69779 15.7446 2.64168L9.56491 0.787781Z"
        className="details"
        fill="black"
        fillOpacity={0.5}
      />
      <path
        d="M14.9262 5.39618L8.71751 3.53356L8.13817 5.44782L14.3469 7.31044L14.9262 5.39618Z"
        className="details"
        fill="black"
        fillOpacity={0.5}
      />
    </g>
    <defs>
      <clipPath id="clip0_6963_13049">
        <rect
          width={24}
          height={24}
          fill="white"
          transform="translate(0 0.000610352)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Merch48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8959 30.8506L32.4158 15.7914C32.5745 15.2625 33.1319 14.9624 33.6608 15.121L43.0419 17.934C43.571 18.0926 43.8712 18.6502 43.7124 19.1793L39.1927 34.238C37.9877 38.2527 35.0169 41.499 31.1234 43.0557L24.8161 45.5774C21.7922 46.7864 18.3602 45.3163 17.1507 42.2938C15.9411 39.2713 17.4119 35.841 20.4359 34.632L26.7431 32.1103C27.2993 31.8879 27.7237 31.4242 27.8959 30.8506Z"
      fill="#4BA279"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1685 18.0174L19.6885 2.95815C19.8472 2.42927 20.4046 2.12916 20.9335 2.28776L30.3146 5.10073C30.8437 5.25939 31.1439 5.817 30.9851 6.34607L26.4653 21.4048C25.2604 25.4195 22.2896 28.6658 18.396 30.2224L12.0888 32.7441C9.06486 33.9531 5.63292 32.483 4.42335 29.4605C3.21377 26.438 4.6846 23.0077 7.70854 21.7987L14.0158 19.277C14.572 19.0547 14.9964 18.5909 15.1685 18.0174Z"
      fill="#4BA279"
    />
    <path
      d="M32.2701 16.3383C32.2346 16.4156 32.204 16.4963 32.1788 16.5802L31.1996 19.8425L42.4882 23.2573L43.4705 19.9845C43.4946 19.9043 43.513 19.8237 43.5258 19.7431L32.2701 16.3383Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M42.0282 24.7901L30.7396 21.3753L29.6862 24.8847L40.9748 28.2995L42.0282 24.7901Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M19.5726 3.44373C19.5243 3.53932 19.4837 3.64054 19.4518 3.74681L18.4919 6.94493L29.7804 10.3597L30.7435 7.15119C30.7743 7.04859 30.7957 6.9454 30.8083 6.84254L19.5726 3.44373Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M29.3204 11.8925L18.0318 8.47765L16.9785 11.9871L28.2671 15.4019L29.3204 11.8925Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Merch24,
  sm: Merch24,
  xl: Merch48,
  lg: Merch48,
  xxl: Merch48,
  xxxl: Merch48,
};

export const MerchIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'merch-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { DocsWrapper as DocsWrapperV1 } from '@jane/shared/reefer-v1';
import { DocsWrapper as DocsWrapperV2 } from '@jane/shared/reefer-v2';
import type { DocsWrapperProps } from '@jane/shared/reefer-v2';

export const DocsWrapper = (props: DocsWrapperProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DocsWrapperV2 {...props} />;
  }
  return <DocsWrapperV1 {...props} />;
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  ToastProvider as ToastProviderV1,
  useToast as useToastV1,
} from '@jane/shared/reefer-v1';
import {
  ToastProvider as ToastProviderV2,
  useToast as useToastV2,
} from '@jane/shared/reefer-v2';
import type { ToastProviderProps } from '@jane/shared/reefer-v2';

export const ToastProvider = (props: ToastProviderProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ToastProviderV2 {...props} />;
  }
  return <ToastProviderV1 {...props} />;
};

export const useToast = () => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  const toastV1 = useToastV1();
  const toastV2 = useToastV2();

  if (reeferMigration) {
    return toastV2;
  }
  return toastV1;
};

import type { FieldValues, UseFormProps } from 'react-hook-form';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  FormFieldWrapper as FormFieldWrapperV1,
  Form as FormV1,
  useForm as useFormV1,
} from '@jane/shared/reefer-v1';
import {
  FormFieldWrapper as FormFieldWrapperV2,
  Form as FormV2,
  useForm as useFormV2,
} from '@jane/shared/reefer-v2';
import type {
  BaseFormProps,
  FormCheckboxFieldProps,
  FormDateTimeFieldProps,
  FormErrorBannerProps,
  FormFieldWrapperProps,
  FormNumberFieldProps,
  FormProps,
  FormRadioFieldGroupProps,
  FormSearchFieldProps,
  FormSelectFieldProps,
  FormSliderFieldProps,
  FormSubmitButtonProps,
  FormSwitchFieldProps,
  FormTextAreaFieldProps,
  FormTextFieldProps,
} from '@jane/shared/reefer-v2';

const HookForm = (props: FormProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2 {...props} />;
  }
  return <FormV1 {...props} />;
};

const BaseForm = <T extends FieldValues>(props: BaseFormProps<T>) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.BaseForm<T> {...props} />;
  }
  return <FormV1.BaseForm<T> {...props} />;
};

const FormCheckboxField = (props: FormCheckboxFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.CheckboxField {...props} />;
  }
  return <FormV1.CheckboxField {...props} />;
};

const FormDateTimeField = (props: FormDateTimeFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.DateTimeField {...props} />;
  }
  return <FormV1.DateTimeField {...props} />;
};

const FormErrorBanner = (props: FormErrorBannerProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.ErrorBanner {...props} />;
  }
  return <FormV1.ErrorBanner {...props} />;
};

const FormNumberField = (props: FormNumberFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.NumberField {...props} />;
  }
  return <FormV1.NumberField {...props} />;
};

const FormRadioFieldGroup = (props: FormRadioFieldGroupProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.RadioFieldGroup {...props} />;
  }
  return <FormV1.RadioFieldGroup {...props} />;
};

const FormSearchField = (props: FormSearchFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.SearchField {...props} />;
  }
  return <FormV1.SearchField {...props} />;
};

const FormSelectField = (props: FormSelectFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.SelectField {...props} />;
  }
  return <FormV1.SelectField {...props} />;
};

const FormSliderField = (props: FormSliderFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.SliderField {...props} />;
  }
  return <FormV1.SliderField {...props} />;
};

const FormSubmitButton = (props: FormSubmitButtonProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.SubmitButton {...props} />;
  }
  return <FormV1.SubmitButton {...props} />;
};

const FormSwitchField = (props: FormSwitchFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.SwitchField {...props} />;
  }
  return <FormV1.SwitchField {...props} />;
};

const FormTextAreaField = (props: FormTextAreaFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.TextAreaField {...props} />;
  }
  return <FormV1.TextAreaField {...props} />;
};

const FormTextField = (props: FormTextFieldProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormV2.TextField {...props} />;
  }
  return <FormV1.TextField {...props} />;
};

export const Form = Object.assign(HookForm, {
  BaseForm: BaseForm,
  CheckboxField: FormCheckboxField,
  DateTimeField: FormDateTimeField,
  ErrorBanner: FormErrorBanner,
  NumberField: FormNumberField,
  RadioFieldGroup: FormRadioFieldGroup,
  SearchField: FormSearchField,
  SelectField: FormSelectField,
  SliderField: FormSliderField,
  SubmitButton: FormSubmitButton,
  SwitchField: FormSwitchField,
  TextAreaField: FormTextAreaField,
  TextField: FormTextField,
});

export const FormFieldWrapper = (props: FormFieldWrapperProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FormFieldWrapperV2 {...props} />;
  }
  return <FormFieldWrapperV1 {...props} />;
};

export const useForm = <T extends FieldValues>(
  props?: Omit<UseFormProps<T>, 'mode'>
) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  const formV1 = useFormV1<T>(props);
  const formV2 = useFormV2<T>(props);

  if (reeferMigration) {
    return formV2;
  }
  return formV1;
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Bipoc24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.4853 3.92916C18.1422 1.58602 14.3432 1.58602 12 3.92916L10.5858 5.34338L19.0711 13.8287L20.4853 12.4144C22.8285 10.0713 22.8285 6.27231 20.4853 3.92916Z"
      fill="#FF9900"
    />
    <path
      d="M20.4853 3.92916C18.1422 1.58602 14.3432 1.58602 12 3.92916L10.5858 5.34338L19.0711 13.8287L20.4853 12.4144C22.8285 10.0713 22.8285 6.27231 20.4853 3.92916Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M2.80747 15.9498C3.58851 16.7308 4.85484 16.7308 5.63589 15.9498L14.1212 7.46452L11.2927 4.63609L2.80747 13.1214C2.02642 13.9024 2.02642 15.1688 2.80747 15.9498Z"
      fill="#FF9900"
    />
    <path
      d="M2.80747 15.9498C3.58851 16.7308 4.85484 16.7308 5.63589 15.9498L14.1212 7.46452L11.2927 4.63609L2.80747 13.1214C2.02642 13.9024 2.02642 15.1688 2.80747 15.9498Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M8.46445 21.6065C9.2455 22.3876 10.5118 22.3876 11.2929 21.6065L19.7782 13.1213L16.9497 10.2928L8.46445 18.7781C7.6834 19.5592 7.6834 20.8255 8.46445 21.6065Z"
      fill="#FF9900"
    />
    <path
      d="M8.46445 21.6065C9.2455 22.3876 10.5118 22.3876 11.2929 21.6065L19.7782 13.1213L16.9497 10.2928L8.46445 18.7781C7.6834 19.5592 7.6834 20.8255 8.46445 21.6065Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M4.92905 19.4852C5.7101 20.2662 6.97643 20.2662 7.75748 19.4852L16.2428 10.9999L13.4143 8.17149L4.92905 16.6568C4.148 17.4378 4.148 18.7042 4.92905 19.4852Z"
      fill="#FF9900"
    />
    <path
      d="M4.92905 19.4852C5.7101 20.2662 6.97643 20.2662 7.75748 19.4852L16.2428 10.9999L13.4143 8.17149L4.92905 16.6568C4.148 17.4378 4.148 18.7042 4.92905 19.4852Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M3.51468 3.92892C5.85783 1.58577 9.65682 1.58577 12 3.92892L13.4142 5.34313L4.9289 13.8284L3.51468 12.4142C1.17154 10.0711 1.17154 6.27207 3.51468 3.92892Z"
      fill="#FF9900"
    />
    <path
      d="M3.51468 3.92892C5.85783 1.58577 9.65682 1.58577 12 3.92892L13.4142 5.34313L4.9289 13.8284L3.51468 12.4142C1.17154 10.0711 1.17154 6.27207 3.51468 3.92892Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M21.1925 15.9498C20.4115 16.7308 19.1452 16.7308 18.3641 15.9498L9.87882 7.46452L12.7073 4.63609L21.1925 13.1214C21.9736 13.9024 21.9736 15.1688 21.1925 15.9498Z"
      fill="#FF9900"
    />
    <path
      d="M21.1925 15.9498C20.4115 16.7308 19.1452 16.7308 18.3641 15.9498L9.87882 7.46452L12.7073 4.63609L21.1925 13.1214C21.9736 13.9024 21.9736 15.1688 21.1925 15.9498Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M15.5356 21.6065C14.7545 22.3876 13.4882 22.3876 12.7071 21.6065L4.22184 13.1213L7.05027 10.2928L15.5356 18.7781C16.3166 19.5592 16.3166 20.8255 15.5356 21.6065Z"
      fill="#FF9900"
    />
    <path
      d="M15.5356 21.6065C14.7545 22.3876 13.4882 22.3876 12.7071 21.6065L4.22184 13.1213L7.05027 10.2928L15.5356 18.7781C16.3166 19.5592 16.3166 20.8255 15.5356 21.6065Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M19.071 19.4852C18.2899 20.2662 17.0236 20.2662 16.2425 19.4852L7.75724 10.9999L10.5857 8.17149L19.071 16.6568C19.852 17.4378 19.852 18.7042 19.071 19.4852Z"
      fill="#FF9900"
    />
    <path
      d="M19.071 19.4852C18.2899 20.2662 17.0236 20.2662 16.2425 19.4852L7.75724 10.9999L10.5857 8.17149L19.071 16.6568C19.852 17.4378 19.852 18.7042 19.071 19.4852Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M7.75742 11.0001C8.53847 11.7811 9.8048 11.7811 10.5858 11.0001L14.8285 6.75745L12.0001 3.92903L7.75742 8.17167C6.97637 8.95271 6.97637 10.219 7.75742 11.0001Z"
      fill="#FF9900"
    />
    <path
      d="M7.75742 11.0001C8.53847 11.7811 9.8048 11.7811 10.5858 11.0001L14.8285 6.75745L12.0001 3.92903L7.75742 8.17167C6.97637 8.95271 6.97637 10.219 7.75742 11.0001Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M16.2428 8.17154C15.4618 7.39049 14.1954 7.39049 13.4144 8.17154L14.8286 6.75732L16.2428 8.17154Z"
      fill="#FF9900"
    />
    <path
      d="M16.2428 8.17154C15.4618 7.39049 14.1954 7.39049 13.4144 8.17154L14.8286 6.75732L16.2428 8.17154Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Bipoc24,
  sm: Bipoc24,
  lg: Bipoc24,
  xl: Bipoc24,
  xxl: Bipoc24,
  xxxl: Bipoc24,
};

export const BipocIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bipoc-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import type { ReactNode } from 'react';

import { SortableElement } from './sortableElement';
import type { Direction, SortableItem } from './types';

export interface SortableListProps<T> {
  direction?: Direction;
  disabled?: boolean;
  hideItem?: (item: SortableItem<T>) => boolean;
  items: SortableItem<T>[];
  onChange: (items: SortableItem<T>[]) => void;
  renderItem: (item: SortableItem<T>) => ReactNode;
  showDragHandle?: boolean;
}
export function SortableList<T>({
  direction = 'horizontal',
  disabled,
  items,
  onChange,
  renderItem,
  hideItem,
  showDragHandle = true,
}: SortableListProps<T>) {
  const isHorizontal = direction === 'horizontal';
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex(
        (item) => `${item.id}` === `${active.id}`
      );
      const newIndex = items.findIndex((item) => `${item.id}` === `${over.id}`);
      const newItems = arrayMove(items, oldIndex, newIndex);
      onChange(newItems);
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    })
  );

  return (
    <DndContext
      modifiers={[
        isHorizontal ? restrictToHorizontalAxis : restrictToVerticalAxis,
        restrictToParentElement,
      ]}
      onDragEnd={onDragEnd}
      accessibility={{
        screenReaderInstructions: { draggable: '' },
      }}
      sensors={sensors}
    >
      <SortableContext
        strategy={
          isHorizontal
            ? horizontalListSortingStrategy
            : verticalListSortingStrategy
        }
        items={items.map((item) => item.id)}
      >
        <ul
          style={{
            display: 'flex',
            flexDirection: isHorizontal ? 'row' : 'column',
            gap: '24px',
          }}
        >
          {items
            .filter((item) => !(hideItem && hideItem(item)))
            .map((item) => (
              <SortableElement
                disabled={disabled}
                key={item.id}
                id={item.id}
                showDragHandle={showDragHandle}
                direction={direction}
              >
                {renderItem(item)}
              </SortableElement>
            ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
}

import { useState } from 'react';

import {
  getJaneGoldFAQPath,
  getJaneGoldLandingPath,
} from '@jane/shared-ecomm/util';
import type { BrandSpecial } from '@jane/shared/models';
import {
  Box,
  Card,
  ConditionalWrapper,
  Flex,
  Grid,
  JaneGoldLogo,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { JumboDiscountBadge } from '../jumboDiscountBadge';

interface BrandSpecialDetailHeaderProps {
  brandSpecial: BrandSpecial;
  isJaneGoldMember?: boolean;
}

export const BrandSpecialDetailHeader = ({
  brandSpecial,
  isJaneGoldMember,
}: BrandSpecialDetailHeaderProps) => {
  const [showTerms, setShowTerms] = useState(false);
  const isMobile = useMobileMediaQuery({});

  const linkProps = isJaneGoldMember
    ? { href: getJaneGoldFAQPath() }
    : { href: getJaneGoldLandingPath() };

  return (
    <ConditionalWrapper
      condition={!isMobile}
      wrapper={(children) => (
        <Card width="100%" flat border="grays-light">
          {children}
        </Card>
      )}
    >
      <Box p={isMobile ? 0 : 40} mt={isMobile ? 8 : 0}>
        <Grid.Container direction={isMobile ? 'col' : 'row'} spacing={0}>
          <Grid.Item xs={isMobile ? 12 : 3}>
            {brandSpecial?.photo?.urls?.original ? (
              <Card.Image
                alt={brandSpecial.title}
                ariaLabel="view brand special details"
                src={brandSpecial.photo.urls.original}
                height={isMobile ? '200px' : '136px'}
                sizes="card-lg"
                responsive
              />
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center"
                height="100%"
                pr={isMobile ? 8 : 24}
                py={isMobile ? 40 : 0}
              >
                <JumboDiscountBadge color="gold" textColor="grays-black">
                  {brandSpecial?.discount_label}
                </JumboDiscountBadge>
              </Flex>
            )}
          </Grid.Item>
          <Grid.Item xs={isMobile ? 12 : 9}>
            <Flex
              flexDirection="column"
              pl={isMobile ? 0 : 40}
              pt={isMobile ? 24 : 0}
            >
              <JaneGoldLogo height={24} width={60} />
              <Typography
                branded
                mb={isMobile ? 8 : 16}
                mt={isMobile ? 8 : 4}
                variant={isMobile ? 'header-bold' : 'title-bold'}
                as="h1"
              >
                {brandSpecial?.title}
              </Typography>
              <Typography as="h2">{brandSpecial?.description}</Typography>
              <Flex flexWrap="wrap">
                {!!brandSpecial?.terms?.length && (
                  <Link
                    onClick={() => setShowTerms((prevValue) => !prevValue)}
                    mr={8}
                    typography="body"
                  >
                    Terms and conditions
                  </Link>
                )}
                <Link {...linkProps} typography="body">
                  Learn more about Jane Gold
                </Link>
              </Flex>
            </Flex>
          </Grid.Item>

          {!!brandSpecial?.terms?.length && showTerms ? (
            <Grid.Item xs={12}>
              <Box
                ml="auto"
                mt={8}
                pl={isMobile ? 0 : 40}
                width={isMobile ? '100%' : '75%'}
              >
                <Flex flexWrap="wrap">
                  <Typography as="span" mr={8}>
                    {brandSpecial?.terms}
                  </Typography>
                  <Link onClick={() => setShowTerms(false)} typography="body">
                    Hide
                  </Link>
                </Flex>
              </Box>
            </Grid.Item>
          ) : (
            <></>
          )}
        </Grid.Container>
      </Box>
    </ConditionalWrapper>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Action */\n.ZSH0tqUdbmsB_as73APU {\n  margin-top: auto;\n  width: 100%;\n  padding: 0;\n}\n\n.Vz29ManFGwRZlM4JeDFU {\n  border-radius: 0;\n}\n\n.Vz29ManFGwRZlM4JeDFU:before {\n  border-radius: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/card/cardAction/cardAction.module.css"],"names":[],"mappings":"AAAA,WAAW;AACX;EACE,gBAAgB;EAChB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["/* Action */\n.cardAction {\n  margin-top: auto;\n  width: 100%;\n  padding: 0;\n}\n\n.cardAction_button {\n  border-radius: 0;\n}\n\n.cardAction_button:before {\n  border-radius: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardAction": "ZSH0tqUdbmsB_as73APU",
	"cardAction_button": "Vz29ManFGwRZlM4JeDFU"
};
export default ___CSS_LOADER_EXPORT___;

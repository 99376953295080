import * as t from 'io-ts';

import { tCustomRowSetting, tPhoto } from '@jane/shared/models';

export const tFilterResponse = t.interface({
  filters: t.interface({
    brands: t.array(t.string),
    categories: t.array(t.string),
    subcategories: t.array(t.string),
    lineages: t.array(t.string),
    weights: t.array(t.string),
    content: t.array(t.interface({ value: t.string, name: t.string })),
    custom_rows: t.array(tCustomRowSetting),
  }),
});

export type FilterResponse = t.TypeOf<typeof tFilterResponse>;

const tProductSearchData = t.interface({
  id: t.number,
  brand: t.string,
  brand_subtype: t.string,
  category: t.string,
  description: t.string,
  full_name: t.string,
  image_urls: t.array(t.string),
  is_visible: t.boolean,
  lineage: t.string,
  name: t.string,
  photos: t.array(tPhoto),
  price_each: t.string,
  price_eighth_ounce: t.string,
  price_gram: t.string,
  price_half_gram: t.string,
  price_half_ounce: t.string,
  price_ounce: t.string,
  price_quarter_ounce: t.string,
  price_two_gram: t.string,
  product_id: t.number,
  store_id: t.number,
  subcategory: t.string,
});

export type ProductSearchData = t.TypeOf<typeof tProductSearchData>;

export const tSearchResponse = t.interface({
  menu_products: t.array(t.any),
  selected_menu_products: t.array(t.any),
  meta: t.interface({
    page: t.number,
    page_total: t.number,
    number_of_pages: t.number,
    per_page: t.number,
    total: t.number,
    all_product_ids: t.array(t.any),
  }),
});

export type SearchResponse = t.TypeOf<typeof tSearchResponse>;

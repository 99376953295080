import styled from '@emotion/styled';

import { shadows, spacing } from '../../styling';
import type { MarginProperties } from '../../styling';
import type { ColorKey } from '../../types';
import {
  getBorderRadiusValue,
  getColorValue,
  getComponentStyles,
} from '../../utils/componentStyles';

interface StyledCardProps
  extends React.HTMLProps<HTMLDivElement>,
    MarginProperties {
  border: ColorKey | 'none';
  borderWidth?: string;
  flat: boolean;
  height?: string | number;
  width?: string | number;
}

export const StyledCard = styled.div<StyledCardProps>(
  {
    display: 'inline-flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
  },
  ({
    border,
    borderWidth,
    flat,
    height,
    width,
    theme,
    onClick,
    ...marginProps
  }) => {
    const cardStyles = getComponentStyles('Card', theme);
    const borderRadius = getBorderRadiusValue(cardStyles.borderRadius, theme);

    return {
      border:
        border === 'none'
          ? 'none'
          : `${borderWidth} solid ${getColorValue(border, theme)}`,
      borderRadius,
      boxShadow: flat ? 'none' : shadows.item,
      cursor: 'initial',
      height: height ? height : 'auto',
      outline: 'none',
      width: width ? width : 'auto',
      ...spacing(marginProps),
      ...(onClick && {
        ':focus-visible': {
          background: `radial-gradient(transparent, transparent, ${theme.colors.grays.ultralight})`,
          transition: '250ms all',
        },
        cursor: 'pointer',
      }),
    };
  }
);

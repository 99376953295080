import type { UserSegmentIds } from '@jane/shared/types';

import { janeApi } from '../api';
import { createUseQueryHook } from '../utils';

type Jdid = string;

const enableWithJdid = (jdid: Jdid) => !!jdid;

const queryKeys = {
  userSegments: (jdid: Jdid) => ['userSegments', jdid],
};

interface UserSegmentsResponse {
  user_segments: UserSegmentIds;
}

export interface UserSegmentsProps {
  jdid: string;
}
export const userSegmentsUrl = '/user_segments';

const fetchUserSegments = async (jdid: Jdid) => {
  const response = await janeApi.get<UserSegmentsResponse>(
    `/user_segments?jdid=${jdid}`
  );
  return response.user_segments || [];
};

export const useUserSegmentIds = createUseQueryHook<Jdid, UserSegmentIds>(
  fetchUserSegments,
  queryKeys.userSegments,
  enableWithJdid
);

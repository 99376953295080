import { useCallback, useRef, useState } from 'react';

import { useWindowListener } from '../../hooks/useWindowListener';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../styling';
import { fakeButtonProps, handleEnterKey } from '../../utils/handleEnterKey';
import {
  MobileOverlay,
  PopoverContainer,
  StyledAlignment,
  StyledContent,
  StyledTarget,
} from './popover.styles';
import type { PopoverProps } from './popover.types';
import { PopoverContext } from './popoverContext/popoverContext';
import { useHandleMobilePopover } from './useHandleMobilePopover';

/**
 * Popover is used to display content on top of another, used for dropdown menus or for filtering.
 */

export function Popover({
  alignment = { horizontal: 'left', vertical: 'bottom' },
  children,
  className,
  closeOnTargetClick = true,
  'data-testid': testId,
  disableMobileStyling = false,
  id,
  label,
  noMinWidth = false,
  onClose,
  openOn = 'click',
  style,
  target,
  targetWidth,
  variant = 'action',
  ...props
}: PopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const isTablet = useTabletMediaQuery({});
  const isMobile = useMobileMediaQuery({});

  const closePopover = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);

  const openPopover = () => {
    setIsOpen(true);
  };

  const handleClick = () => {
    if (openOn !== 'disabled') {
      if (isOpen && closeOnTargetClick) closePopover();
      if (!isOpen) openPopover();
    }
  };

  const handleMouseEnter = () => {
    openOn === 'hover' && setIsOpen(true);
  };

  const handleMouseLeave = () => {
    openOn === 'hover' && setIsOpen(false);
  };

  /** close when user clicks outside of popover */
  const handleClickAway = (event: MouseEvent) => {
    const domPath = event.composedPath && event.composedPath();

    const eventTarget = domPath ? domPath[0] : (event.target as HTMLElement);

    const popoverContainsTarget = popoverRef.current?.contains(
      eventTarget as Node
    );

    if (!popoverContainsTarget && isOpen) {
      closePopover();
    }
  };

  /** allows keyboard users to close the popover using esc key  */
  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        if (isOpen) {
          closePopover();
        }
      }
      return;
    },
    [closePopover, isOpen]
  );

  /** openOn changed to 'click' for mobile screens */
  const openOnHandler = () => {
    if (openOn === 'hover' && !isTablet) {
      openOn = 'click';
    }
    return;
  };

  /** different states of children depending how PopoverContext is used */
  const renderedChildren =
    typeof children === 'function'
      ? children({ closePopover, isOpen })
      : children;

  openOnHandler();

  useWindowListener('keydown', handleEscapeKey);

  useWindowListener('click', handleClickAway);

  // on mobile, it resizes iframe when popover is open
  // allowing the drawer to appear at the bottom of the screen
  useHandleMobilePopover(isOpen, isMobile && !disableMobileStyling);

  return (
    <PopoverContext.Provider value={{ closePopover, isOpen }}>
      {isOpen && !disableMobileStyling && <MobileOverlay />}
      <PopoverContainer
        className={className}
        disableMobileStyling={disableMobileStyling}
        id={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        targetWidth={targetWidth}
        ref={popoverRef}
        style={style}
        {...props}
      >
        <StyledTarget
          aria-label={label}
          openOn={openOn}
          onClick={handleClick}
          onKeyUp={(event) => handleEnterKey(event, openPopover)}
          test-id={testId}
          {...(closeOnTargetClick && { ...fakeButtonProps })}
          {...(isOpen && {
            'aria-controls': 'popover-content',
          })}
        >
          {target}
        </StyledTarget>
        {isOpen && (
          <StyledAlignment
            alignment={alignment}
            noMinWidth={noMinWidth}
            variant={variant}
          >
            <StyledContent
              alignment={alignment}
              disableMobileStyling={disableMobileStyling}
              id="popover-content"
              onClick={(e) => {
                // Prevent clicks within popover content from triggering the
                // "Click away" behavior that closes popovers.
                e.stopPropagation();
              }}
            >
              {renderedChildren}
            </StyledContent>
          </StyledAlignment>
        )}
      </PopoverContainer>
    </PopoverContext.Provider>
  );
}

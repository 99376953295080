import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Box as BoxV1 } from '@jane/shared/reefer-v1';
import { Box as BoxV2 } from '@jane/shared/reefer-v2';
import type { BoxProps } from '@jane/shared/reefer-v2';

export const Box = (props: BoxProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BoxV2 {...props} />;
  }
  return <BoxV1 {...props} />;
};

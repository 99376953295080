import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Image as ImageV1 } from '@jane/shared/reefer-v1';
import { Image as ImageV2 } from '@jane/shared/reefer-v2';
import type { ImageProps } from '@jane/shared/reefer-v2';

export const Image = (props: ImageProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ImageV2 {...props} />;
  }
  return <ImageV1 {...props} />;
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Popover as PopoverV1,
  usePopoverContext as usePopoverContextV1,
} from '@jane/shared/reefer-v1';
import {
  Popover as PopoverV2,
  usePopoverContext as usePopoverContextV2,
} from '@jane/shared/reefer-v2';
import type { PopoverContentProps, PopoverProps } from '@jane/shared/reefer-v2';

export const BasePopover = (props: PopoverProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PopoverV2 {...props} />;
  }
  return <PopoverV1 {...props} />;
};

const PopoverContent = (props: PopoverContentProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PopoverV2.Content {...props} />;
  }
  return <PopoverV1.Content {...props} />;
};

const PopoverDivider = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PopoverV2.Divider {...props} />;
  }
  return <PopoverV1.Divider {...props} />;
};

export const Popover = Object.assign(BasePopover, {
  Content: PopoverContent,
  Divider: PopoverDivider,
});

export const usePopoverContext = () => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  const popoverContextV1 = usePopoverContextV1();
  const popoverContextV2 = usePopoverContextV2();

  if (reeferMigration) {
    return popoverContextV2;
  }
  return popoverContextV1;
};

import { getTextWithHighlights } from '../../../utils/getTextWithHighlights';
import { CheckboxField } from '../../forms/fields/checkboxField';
import { usePopoverContext } from '../../popover/popoverContext/popoverContext';
import { Typography } from '../../typography';
import {
  StyledListButton,
  StyledListButtonLabels,
  StyledListCheckbox,
  StyledSearchListItem,
} from '../typeAhead.styles';
import type { TypeAheadOption } from '../typeAhead.types';
import { useTypeAheadContext } from '../typeAheadContext';

export const ResultListItem = ({
  className,
  'data-testid': testId,
  id,
  label,
  subLabel,
}: TypeAheadOption) => {
  const { optionType, query, onSelection, selection, width } =
    useTypeAheadContext();
  const { closePopover } = usePopoverContext();

  const selectionArray = selection as string[];

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.target as HTMLButtonElement;
    onSelection && onSelection(button.value);
    closePopover();
  };

  const checkboxHandler = (checked: boolean, optionLabel: string) => {
    let newFilters = [...selectionArray];
    if (checked) {
      newFilters = [...selectionArray, optionLabel];
    } else {
      newFilters = selectionArray.filter((option) => option !== optionLabel);
    }
    onSelection && onSelection(newFilters);
  };

  const renderedLabel = getTextWithHighlights(label, query);
  const renderedSubLabel = getTextWithHighlights(subLabel || '', query);

  return (
    <StyledSearchListItem
      className={className}
      data-testid={testId}
      id={id}
      width={width}
    >
      {optionType === 'button' ? (
        <StyledListButton onClick={buttonHandler} value={label} type="button">
          <StyledListButtonLabels>
            <Typography as="span">{renderedLabel}</Typography>
            {renderedSubLabel && (
              <Typography as="span" color="text-light" ml={8}>
                {renderedSubLabel}
              </Typography>
            )}
          </StyledListButtonLabels>
        </StyledListButton>
      ) : (
        <StyledListCheckbox>
          <CheckboxField
            name={label}
            onChange={(checked) => checkboxHandler(checked, label)}
            label={renderedLabel}
            checked={selection?.includes(label)}
          />
        </StyledListCheckbox>
      )}
    </StyledSearchListItem>
  );
};

import { getAccessibilityProps } from '../../../internal/utils';
import type { CardGroupProps } from '../card.types';
import { StyledCardGroup } from './cardGroup.styles';

/**
 * The `Card.Group` component is a wrapper component used for displaying a styled card group in either a row or column with an optional `border`, `box-shadow` and `separator` lines between content.
 * */

export function CardGroup({
  ariaLabel,
  ariaLabelledBy,
  border = 'none',
  borderWidth = '1px',
  children,
  className,
  direction = 'column',
  flat = false,
  height,
  id,
  role,
  'data-testid': testId,
  width,
  ...props
}: CardGroupProps) {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, role },
    'Card.Content'
  );

  return (
    <StyledCardGroup
      border={border}
      borderWidth={borderWidth}
      className={className}
      data-testid={testId}
      direction={direction}
      flat={flat}
      height={height}
      id={id}
      width={width}
      {...a11yProps}
      {...props}
    >
      {children}
    </StyledCardGroup>
  );
}

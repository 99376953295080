// These are hardcoded for consistency with the timezone strings that ActiveSupport::Timezone produces in the backend

export const us_timezones: string[] = [
  'America/Adak',
  'Hawaii',
  'Alaska',
  'America/Anchorage',
  'America/Metlakatla',
  'America/Nome',
  'America/Sitka',
  'America/Yakutat',
  'Pacific Time (US & Canada)',
  'America/Boise',
  'Arizona',
  'Mountain Time (US & Canada)',
  'America/Indiana/Knox',
  'America/Indiana/Tell_City',
  'America/Menominee',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'Central Time (US & Canada)',
  'America/Detroit',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'Eastern Time (US & Canada)',
  'Indiana (East)',
  'America/Puerto_Rico',
];

import styled from '@emotion/styled';
import type { Row } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';

import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import { useImage } from '@jane/shared/components';
import {
  Box,
  CheckboxField,
  Flex,
  Image,
  Link,
  Typography,
} from '@jane/shared/reefer';

const StyledTypography = styled(Typography)({
  '@supports (-webkit-line-clamp: 2)': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const StyledFlex = styled(Flex)({
  maxWidth: 400,
  wordWrap: 'break-word',
  whiteSpace: 'normal',
});

export const ProductCell = ({
  handleClick,
  isBulkEditTable = false,
  row,
}: {
  handleClick: React.MouseEventHandler<HTMLInputElement>;
  isBulkEditTable?: boolean;
  row: Row<MenuProductForProductsTable>;
}) => {
  // @ts-expect-error - issue stems from disparate types for `MenuProduct` - this version of MenuProduct is a weird abbreviation.
  const { imageSrc } = useImage(row.original);
  const { pathname } = useLocation();

  const url = `${pathname}/${row.original.id}`;

  return (
    <Link
      to={url}
      onClick={(event) => {
        event.stopPropagation();
      }}
      variant="minimal"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        ml={64}
        maxWidth={400}
      >
        <StyledFlex alignItems="center">
          {isBulkEditTable ? (
            <Box pr={24}>
              <CheckboxField
                label={`${row.original.id}-checkbox`}
                name={`${row.original.id}-checkbox`}
                labelHidden
                checked={row.getIsSelected()}
                onClick={handleClick}
              />
            </Box>
          ) : null}
          <Image
            height="48px"
            width="48px"
            border
            borderRadius="12px"
            src={imageSrc}
            altText={row.original.name}
          />
          <StyledTypography
            variant="body-bold"
            mx={24}
            {...{ title: row.original.name }}
          >
            {row.original.name}
          </StyledTypography>
        </StyledFlex>
      </Flex>
    </Link>
  );
};

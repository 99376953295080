import { Box, Loading } from '@jane/shared/reefer';
import type { PaddingProperties } from '@jane/shared/reefer';

import {
  BlockSkeleton,
  CardRowSkeleton,
  CarouselGridSkeleton,
  CarouselListSkeleton,
  CarouselSkeleton,
  FilterSkeleton,
  ProductGridSkeleton,
} from './skeletonComponents';
import {
  CheckoutSkeleton,
  FullPageLoading,
  MenuProductDetailSkeleton,
  MenuSkeleton,
  MenuTitleSkeleton,
  ProductDetailSkeleton,
  ProductSearchSkeleton,
  StoreMenuSkeleton,
} from './skeletonPages';

export type LoadingWrapperVariant =
  | 'wavy'
  | 'block'
  | 'card-row'
  | 'carousel'
  | 'carousel-list'
  | 'carousel-grid'
  | 'checkout'
  | 'filters'
  | 'full-page'
  | 'menu'
  | 'menu-product-detail'
  | 'menu-title'
  | 'product-grid'
  | 'product-detail'
  | 'product-search'
  | 'store-menu';

export interface LoadingWrapperProps extends PaddingProperties {
  children?: React.ReactNode | (() => React.ReactNode);
  height?: string | number;
  isEmbedded?: boolean;
  isLoading: boolean;
  variant?: LoadingWrapperVariant;
}

export const LoadingWrapper = ({
  children,
  height = '300px',
  isEmbedded,
  isLoading,
  variant = 'wavy',
  ...props
}: LoadingWrapperProps) => {
  const loader = () => {
    switch (variant) {
      case 'block':
        return <BlockSkeleton height={height} />;
      case 'product-search':
        return <ProductSearchSkeleton />;
      case 'product-grid':
        return <ProductGridSkeleton />;
      case 'card-row':
        return <CardRowSkeleton />;
      case 'carousel':
        return <CarouselSkeleton />;
      case 'carousel-list':
        return <CarouselListSkeleton />;
      case 'carousel-grid':
        return <CarouselGridSkeleton />;
      case 'checkout':
        return <CheckoutSkeleton />;
      case 'filters':
        return <FilterSkeleton />;
      case 'full-page':
        return <FullPageLoading />;
      case 'menu':
        return <MenuSkeleton isEmbedded={isEmbedded} />;
      case 'product-detail':
        return <ProductDetailSkeleton />;
      case 'menu-product-detail':
        return <MenuProductDetailSkeleton />;
      case 'menu-title':
        return <MenuTitleSkeleton />;
      case 'store-menu':
        return <StoreMenuSkeleton />;
      default:
        return (
          <Box width="100%" p={24} position="relative">
            <Loading color="black" />
          </Box>
        );
    }
  };

  if (isLoading)
    return (
      <Box width="100%" data-testid="loading" {...props}>
        {loader()}
      </Box>
    );

  return (
    <Box width="100%" {...props}>
      {typeof children === 'function' ? children() : children}
    </Box>
  );
};

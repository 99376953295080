import type { AdFlight, AppMode, DmMeta, Id } from '@jane/shared/models';

import { getSharedDmQueryParams } from '../utils/getSharedDmQueryParams';
import { dmApi } from './dmApi';

const cartToppersUrl = (storeId: Id) => `/stores/${storeId}/toppers`;

export interface FetchCartToppersParams {
  appMode: AppMode;
  cartProductIds: Id[];
  janeDeviceId: string;
  maxProducts: number;
  storeId: Id;
}

export interface FetchCartToppersResponse {
  cartToppers: BaseCartTopper[];
  maxPrice: number;
}

export interface BaseCartTopper {
  dmMeta?: DmMeta;
  flight?: AdFlight;
  product_id: number;
}

export const fetchCartToppers = async ({
  appMode,
  cartProductIds,
  janeDeviceId,
  maxProducts,
  storeId,
}: FetchCartToppersParams): Promise<FetchCartToppersResponse> => {
  const queryParams = new URLSearchParams({
    ...getSharedDmQueryParams(),
    app_mode: appMode,
    jane_device_id: janeDeviceId,
    max_products: `${maxProducts}`,
    product_ids_in_cart: cartProductIds.join(','),
    store_id: `${storeId}`,
  });
  const url = `${cartToppersUrl(storeId)}?${queryParams.toString()}`;

  const {
    flights = [],
    products,
    max_price_per_product,
  } = await dmApi.get<FetchCartToppersApiResponse>(url, {
    headers: { 'Content-Type': 'text/plain' },
  });

  const rankedProducts = combineFlightsAndProducts(flights, products);

  return { cartToppers: rankedProducts, maxPrice: max_price_per_product };
};

export interface FetchCartToppersApiResponse {
  flights?: AdFlight[];
  max_price_per_product: number;
  products: ApiProduct[];
}

export interface ApiProduct {
  ad_token: string;
  flight_id?: number;
  my_high_d: string;
  product_id: number;
}

const combineFlightsAndProducts = (
  flights: AdFlight[],
  products: ApiProduct[]
): BaseCartTopper[] => {
  const flightsMap: Record<number, AdFlight> = {};
  if (flights) {
    flights.forEach((flight) => {
      flightsMap[flight.id] = flight;
    });
  }
  const rankedProducts =
    products?.map(({ product_id, flight_id, my_high_d, ad_token }) => ({
      dmMeta: {
        adToken: ad_token,
        myHighD: my_high_d,
      },
      flight: flight_id ? flightsMap[flight_id] : undefined,
      product_id,
    })) || [];
  return rankedProducts;
};

import capitalize from 'lodash/capitalize';
import { useContext, useMemo } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { StoreV2 } from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT } from '@jane/business-admin/util';
import type { StoreSpecial } from '@jane/shared/models';
import {
  Flex,
  Form,
  Modal,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import { DiscountTypeAndDiscountAmountField } from './DiscountTypeAndDiscountAmountField';
import { SpecialTypeCriteria } from './SpecialTypeCriteria';
import {
  FORM_FIELD_DEFAULT_MARGIN,
  MAX_MESSAGE_LENGTH,
  SPECIAL_TYPES,
} from './form';

const gtiStoreIds = [725, 756, 874, 1341, 1531, 6011, 6012, 6013, 6014, 6015];

export const LoadingDetailsCard = () => {
  return (
    <Flex flexDirection="column">
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_status`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="20%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="20%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_name_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={48} width="100%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_special_type_select`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="30%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_special_type_criteria`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="30%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        {Array(2)
          .fill(null)
          .map((_, index) => {
            return (
              <Skeleton animate width="100%" key={`loading_2${index}`}>
                <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="60%" />
                <Skeleton.Bone height={BODY_LINE_HEIGHT} width="80%" />
              </Skeleton>
            );
          })}
      </Flex>
      <Flex mb={20} width="100%">
        <Skeleton animate width="100%" key={`loading_description_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={76} width="100%" />
        </Skeleton>
      </Flex>

      <Modal.ContentDivider />

      <Flex mt={20} mb={40} gap={24} width="100%">
        <Skeleton animate width="100%" key={`loading_reservation_modes`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_terms_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={76} width="100%" />
        </Skeleton>
      </Flex>
    </Flex>
  );
};

export const DetailsCard = ({
  special,
  isCreateMode,
  isLoading,
  store,
}: {
  isCreateMode: boolean;
  isEditable: boolean;
  isLoading: boolean;
  special?: StoreSpecial | null;
  store: StoreV2 | undefined;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced, posSource },
  } = useContext(SpecialsModalContext);
  const { watch } = useFormContext();
  const {
    description = '',
    reservation_modes = { delivery: false, pickup: false },
    terms = '',
    special_type = '',
  } = {
    ...special,
  };

  // TODO: maybe update to useWatch()?
  const descriptionWatch = watch('description');
  const specialTypeWatch = watch('special_type');

  const messageLength = useMemo(
    () =>
      descriptionWatch?.length || (!isCreateMode && description?.length) || 0,
    [descriptionWatch, description]
  );

  const canStoreUseQualifiedGroups = (store?: StoreV2) => {
    return store?.state !== 'Ohio' || gtiStoreIds.includes(store?.id);
  };

  const specialTypeOptions = (store?: StoreV2) => {
    const commonValues = [
      {
        value: SPECIAL_TYPES.BulkPricing,
        label: 'Bulk Pricing',
      },
      {
        value: SPECIAL_TYPES.Bundle,
        label: 'Buy X Get Y',
      },
      {
        value: SPECIAL_TYPES.Product,
        label: 'Product',
      },
      {
        value: SPECIAL_TYPES.CartTotal,
        label: 'Cart Total',
      },
    ];

    if (canStoreUseQualifiedGroups(store)) {
      commonValues.push({
        value: SPECIAL_TYPES.QualifiedGroup,
        label: 'Qualified Group',
      });
    }

    const selectValue = { value: 'select', label: 'Select' };

    if (isCreateMode) {
      return [selectValue].concat(commonValues);
    }

    return commonValues;
  };

  const formatReservationModes = () => {
    const modes =
      reservation_modes &&
      ['delivery', 'pickup']
        .filter(
          (key) => reservation_modes[key as keyof typeof reservation_modes]
        )
        .map((mode) => capitalize(mode));

    return modes?.join(' and ') || '';
  };

  return (
    <>
      <Typography variant="header-bold" mb={FORM_FIELD_DEFAULT_MARGIN}>
        Details
      </Typography>
      {!isCreateMode && posSynced && (
        <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={4} flexDirection="column">
          <Typography variant="body-bold">Status</Typography>
          <Typography variant="body">
            Synced from {isJanePosSynced ? 'Jane' : posSource} POS
          </Typography>
        </Flex>
      )}
      {isLoading ? (
        <LoadingDetailsCard />
      ) : (
        <>
          <Typography color="grays-black" variant="body-bold">
            Name
          </Typography>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            <Form.TextField
              label="Name"
              name="name"
              required
              labelHidden
              maxLength={posSynced ? 40 : undefined}
            />
          </Flex>
          <Typography color="grays-black" variant="body-bold">
            Special type
          </Typography>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            {isCreateMode ? (
              <>
                <Form.SelectField
                  required
                  label="Special type"
                  labelHidden
                  name="special_type"
                  options={specialTypeOptions(store)}
                  width={'100%'}
                  validate={(value: string) => {
                    return value !== 'select'
                      ? true
                      : 'Must choose a valid special type';
                  }}
                />

                {/* Needed to get the spacing right */}
                <Flex width="100%">&nbsp;</Flex>
              </>
            ) : (
              <Flex width="50%" flexDirection="column">
                <Typography color="grays-black" variant="body" mt={4} as="p">
                  {
                    specialTypeOptions(store).find(
                      (type) =>
                        type.value === (specialTypeWatch || special_type)
                    )?.label
                  }
                </Typography>
              </Flex>
            )}
          </Flex>
          <Flex gap={24}>
            <SpecialTypeCriteria isCreateMode={isCreateMode} />
          </Flex>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            <DiscountTypeAndDiscountAmountField isCreateMode={isCreateMode} />
          </Flex>
          <Flex width="100%" flexDirection="column">
            <Flex gap={4}>
              <Typography color="grays-black" variant="body-bold">
                Description
              </Typography>
              <Typography color="grays-mid" variant="body">
                ({messageLength}/{MAX_MESSAGE_LENGTH} characters used)
              </Typography>
            </Flex>
            <Form.TextAreaField
              label="Description"
              labelHidden
              name="description"
              width="100%"
              maxLength={MAX_MESSAGE_LENGTH}
              rows={4}
              data-testid="special-description"
            />
          </Flex>

          <Modal.ContentDivider />

          <Flex
            width="100%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Typography color="grays-black" variant="body-bold">
              Apply to reservation
            </Typography>
            <Flex mb={FORM_FIELD_DEFAULT_MARGIN}>
              {isCreateMode ||
              (!isCreateMode && (isJanePosSynced || !posSynced)) ? (
                <Flex gap={12} mt={12}>
                  <Form.CheckboxField
                    name="reservation_modes.delivery"
                    onChange={() => null}
                    label="Delivery"
                  />
                  <Form.CheckboxField
                    name="reservation_modes.pickup"
                    onChange={() => null}
                    label="Pickup"
                  />
                </Flex>
              ) : (
                <Typography>{formatReservationModes() || 'N/A'}</Typography>
              )}
            </Flex>

            <Flex
              width="100%"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Typography color="grays-black" variant="body-bold">
                Stacking
              </Typography>
              <Flex mt={12} flexDirection="row">
                <Form.RadioFieldGroup
                  row
                  name="stacking_setting"
                  options={[
                    {
                      id: 'stacking-setting-yes',
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      id: 'stacking-setting-no',
                      label: 'No',
                      value: 'no',
                    },
                    {
                      id: 'stacking-setting-combinable',
                      label: 'Only with other stackable specials',
                      value: 'combinable',
                    },
                  ]}
                />
              </Flex>
            </Flex>

            <Typography color="grays-black" variant="body-bold">
              Terms and conditions
            </Typography>
            <Flex flexDirection="column" width={'100%'}>
              {isCreateMode ||
              (!isCreateMode && (isJanePosSynced || !posSynced)) ? (
                <Form.TextAreaField
                  label="Terms and conditions"
                  labelHidden
                  name="terms"
                  width="100%"
                  rows={4}
                />
              ) : (
                <Typography>{terms || 'N/A'}</Typography>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

import { getAccessibilityProps } from '../../../internal/utils';
import { fakeButtonProps, handleEnterKey } from '../../../utils';
import { Image } from '../../image';
import type { CardImageProps } from '../card.types';
import { StyledImage } from './cardImage.styles';

/**
 * The `Card.Image` component can be included as a child of a `Card` component to add an image
 * that spans the full `Card` width. Should be used outside `Card.Content`.
 * */

export const CardImage = ({
  alt,
  ariaLabel,
  ariaLabelledBy,
  aspectRatio,
  className,
  fallbackSrc,
  height,
  id,
  loading = 'eager',
  width,
  onClick,
  responsive = false,
  role,
  sizes,
  src,
  srcSet,
  'data-testid': testId,
}: CardImageProps) => {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, onClick, role },
    'Flex'
  );

  return (
    <StyledImage
      className={className}
      data-testid={testId}
      id={id}
      {...a11yProps}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
    >
      <Image
        altText={alt}
        aspectRatio={aspectRatio}
        fallbackSrc={fallbackSrc}
        height={height}
        loading={loading}
        width={width || 'auto'}
        sizes={sizes}
        src={src}
        srcSet={srcSet}
        responsive={responsive}
      />
    </StyledImage>
  );
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Tabs as TabsV1 } from '@jane/shared/reefer-v1';
import { Tabs as TabsV2 } from '@jane/shared/reefer-v2';
import type { TabProps, TabsProps } from '@jane/shared/reefer-v2';

export const BaseTabs = (props: TabsProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TabsV2 {...props} />;
  }
  return <TabsV1 {...props} />;
};

const Tab = (props: TabProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TabsV2.Tab {...props} />;
  }
  return <TabsV1.Tab {...props} />;
};

export const Tabs = Object.assign(BaseTabs, {
  Tab,
});

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Pepper24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13041_31288)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5918 4.02176C19.9779 4.37385 19.4229 5.19833 18.9854 7.00863L17.9492 6.75823C18.4022 4.88356 19.0477 3.67843 20.0614 3.09704C21.0748 2.51584 22.2911 2.65522 23.6055 3.00909C23.8897 3.08561 24.0581 3.37808 23.9816 3.66234C23.9051 3.94659 23.6126 4.11499 23.3283 4.03846C22.0443 3.69276 21.206 3.66949 20.5918 4.02176Z"
        fill="#94B200"
      />
      <path
        d="M13.3124 10.2111C14.4547 6.37599 15.6561 3.74671 19.2591 4.61734C21.6798 5.20229 23.0734 8.41258 19.073 15.7145C13.0625 26.6853 -1.00203 24.375 0.623465 18.5841C6.75123 18.9714 10.7874 18.6882 13.3124 10.2111Z"
        fill="#FF3333"
      />
      <path
        d="M13.3124 10.2111C14.4547 6.37599 15.6561 3.74671 19.2591 4.61734C21.6798 5.20229 23.0734 8.41258 19.073 15.7145C13.0625 26.6853 -1.00203 24.375 0.623465 18.5841C6.75123 18.9714 10.7874 18.6882 13.3124 10.2111Z"
        fill="black"
        fillOpacity={0.2}
      />
      <path
        d="M21.5945 8.08882C19.0278 8.08882 16.446 7.40498 15 6.10561C15.9274 4.74759 17.2017 4.1036 19.2589 4.60073C20.5689 4.91726 21.578 6.00252 21.5945 8.08882Z"
        fill="#94B200"
      />
      <path
        d="M8.89196 6.15777C8.91566 6.34741 9.01838 6.44223 9.20012 6.44223C9.37395 6.44223 9.47667 6.34741 9.50828 6.15777C9.5873 5.68368 9.66236 5.31231 9.73347 5.04366C9.80459 4.7671 9.90731 4.55771 10.0416 4.41548C10.176 4.27325 10.3854 4.16263 10.6698 4.08362C10.9543 3.9967 11.3493 3.90583 11.855 3.81101C12.0447 3.77941 12.1395 3.67669 12.1395 3.50285C12.1395 3.32902 12.0447 3.2263 11.855 3.19469C11.3414 3.09987 10.9424 3.01691 10.658 2.94579C10.3814 2.86678 10.172 2.75616 10.0298 2.61393C9.89546 2.4717 9.79274 2.26231 9.72162 1.98576C9.65841 1.7092 9.5873 1.32993 9.50828 0.847932C9.47667 0.658295 9.37395 0.563477 9.20012 0.563477C9.01838 0.563477 8.91566 0.662246 8.89196 0.859785C8.80504 1.33388 8.72998 1.70525 8.66676 1.9739C8.60355 2.24256 8.50083 2.448 8.3586 2.59022C8.22428 2.73245 8.01489 2.84307 7.73043 2.92209C7.44597 3.00111 7.0509 3.09197 6.5452 3.19469C6.35556 3.2263 6.26074 3.32902 6.26074 3.50285C6.26074 3.67669 6.35951 3.77941 6.55705 3.81101C7.06275 3.89793 7.45388 3.98485 7.73043 4.07177C8.01489 4.15078 8.22428 4.2614 8.3586 4.40363C8.50083 4.54586 8.60355 4.75525 8.66676 5.0318C8.72998 5.30046 8.80504 5.67578 8.89196 6.15777Z"
        fill="#FF3333"
      />
      <path
        d="M8.89196 6.15777C8.91566 6.34741 9.01838 6.44223 9.20012 6.44223C9.37395 6.44223 9.47667 6.34741 9.50828 6.15777C9.5873 5.68368 9.66236 5.31231 9.73347 5.04366C9.80459 4.7671 9.90731 4.55771 10.0416 4.41548C10.176 4.27325 10.3854 4.16263 10.6698 4.08362C10.9543 3.9967 11.3493 3.90583 11.855 3.81101C12.0447 3.77941 12.1395 3.67669 12.1395 3.50285C12.1395 3.32902 12.0447 3.2263 11.855 3.19469C11.3414 3.09987 10.9424 3.01691 10.658 2.94579C10.3814 2.86678 10.172 2.75616 10.0298 2.61393C9.89546 2.4717 9.79274 2.26231 9.72162 1.98576C9.65841 1.7092 9.5873 1.32993 9.50828 0.847932C9.47667 0.658295 9.37395 0.563477 9.20012 0.563477C9.01838 0.563477 8.91566 0.662246 8.89196 0.859785C8.80504 1.33388 8.72998 1.70525 8.66676 1.9739C8.60355 2.24256 8.50083 2.448 8.3586 2.59022C8.22428 2.73245 8.01489 2.84307 7.73043 2.92209C7.44597 3.00111 7.0509 3.09197 6.5452 3.19469C6.35556 3.2263 6.26074 3.32902 6.26074 3.50285C6.26074 3.67669 6.35951 3.77941 6.55705 3.81101C7.06275 3.89793 7.45388 3.98485 7.73043 4.07177C8.01489 4.15078 8.22428 4.2614 8.3586 4.40363C8.50083 4.54586 8.60355 4.75525 8.66676 5.0318C8.72998 5.30046 8.80504 5.67578 8.89196 6.15777Z"
        fill="black"
        fillOpacity={0.2}
      />
      <path
        d="M3.73537 13.5499C3.50784 13.5499 3.38143 13.4266 3.35615 13.1801C3.2803 12.5607 3.19814 12.0741 3.10965 11.7201C3.02749 11.3599 2.89476 11.0912 2.71146 10.9143C2.52817 10.731 2.25323 10.5951 1.88665 10.5066C1.52638 10.4118 1.02707 10.3138 0.388706 10.2127C0.129569 10.1748 0 10.0452 0 9.824C0 9.60278 0.113768 9.47321 0.341303 9.43529C0.992307 9.3152 1.5011 9.20775 1.86769 9.11295C2.24059 9.01182 2.51869 8.87593 2.70198 8.70528C2.8916 8.52831 3.02749 8.26601 3.10965 7.91839C3.19814 7.57076 3.2803 7.08409 3.35615 6.45837C3.38143 6.21187 3.50784 6.08862 3.73537 6.08862C3.96923 6.08862 4.0988 6.20871 4.12408 6.44889C4.20624 7.08725 4.28841 7.58656 4.37058 7.94683C4.45906 8.30709 4.59495 8.57887 4.77824 8.76216C4.96154 8.94546 5.23647 9.08135 5.60306 9.16983C5.96964 9.25832 6.47844 9.3468 7.12944 9.43529C7.23057 9.44793 7.31274 9.48901 7.37594 9.55854C7.44546 9.62806 7.48023 9.71655 7.48023 9.824C7.48023 10.0389 7.36646 10.1685 7.13892 10.2127C6.4816 10.3328 5.96964 10.4434 5.60306 10.5445C5.23647 10.6393 4.96154 10.7752 4.77824 10.9522C4.59495 11.1292 4.45906 11.3946 4.37058 11.7486C4.28841 12.0962 4.20624 12.5797 4.12408 13.1991C4.11144 13.3002 4.07036 13.3856 4.00083 13.4551C3.93131 13.5183 3.84282 13.5499 3.73537 13.5499Z"
        fill="#FF3333"
      />
      <path
        d="M3.73537 13.5499C3.50784 13.5499 3.38143 13.4266 3.35615 13.1801C3.2803 12.5607 3.19814 12.0741 3.10965 11.7201C3.02749 11.3599 2.89476 11.0912 2.71146 10.9143C2.52817 10.731 2.25323 10.5951 1.88665 10.5066C1.52638 10.4118 1.02707 10.3138 0.388706 10.2127C0.129569 10.1748 0 10.0452 0 9.824C0 9.60278 0.113768 9.47321 0.341303 9.43529C0.992307 9.3152 1.5011 9.20775 1.86769 9.11295C2.24059 9.01182 2.51869 8.87593 2.70198 8.70528C2.8916 8.52831 3.02749 8.26601 3.10965 7.91839C3.19814 7.57076 3.2803 7.08409 3.35615 6.45837C3.38143 6.21187 3.50784 6.08862 3.73537 6.08862C3.96923 6.08862 4.0988 6.20871 4.12408 6.44889C4.20624 7.08725 4.28841 7.58656 4.37058 7.94683C4.45906 8.30709 4.59495 8.57887 4.77824 8.76216C4.96154 8.94546 5.23647 9.08135 5.60306 9.16983C5.96964 9.25832 6.47844 9.3468 7.12944 9.43529C7.23057 9.44793 7.31274 9.48901 7.37594 9.55854C7.44546 9.62806 7.48023 9.71655 7.48023 9.824C7.48023 10.0389 7.36646 10.1685 7.13892 10.2127C6.4816 10.3328 5.96964 10.4434 5.60306 10.5445C5.23647 10.6393 4.96154 10.7752 4.77824 10.9522C4.59495 11.1292 4.45906 11.3946 4.37058 11.7486C4.28841 12.0962 4.20624 12.5797 4.12408 13.1991C4.11144 13.3002 4.07036 13.3856 4.00083 13.4551C3.93131 13.5183 3.84282 13.5499 3.73537 13.5499Z"
        fill="black"
        fillOpacity={0.2}
      />
    </g>
    <defs>
      <clipPath id="clip0_13041_31288">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Pepper24,
  sm: Pepper24,
  lg: Pepper24,
  xl: Pepper24,
  xxl: Pepper24,
  xxxl: Pepper24,
};

export const PepperIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pepper-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import styled from '@emotion/styled';

import { Typography } from '../../../typography';

interface StyledHelperTextProps {
  disabled?: boolean;
}

export const StyledHelperText = styled(Typography)<StyledHelperTextProps>(
  ({ disabled }) => ({
    opacity: disabled ? '0.3' : 'initial',
  })
);

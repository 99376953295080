import styled from '@emotion/styled';

export const PromptArrow = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
  border: `1px solid ${theme.colors.grays.light}`,
  borderRadius: '2px',
  position: 'absolute',
  height: '12px',
  width: '12px',
  top: '2px',
  left: '2px',
  transform: 'rotate(45deg)',
  WebkitTransform: 'rotate(45deg)',
}));

export const PromptContainer = styled.div<{ right: number; top: number }>(
  ({ right, top }) => ({
    zIndex: 10001,
    position: 'absolute',
    top,
    right,
  })
);

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Loading as LoadingV1 } from '@jane/shared/reefer-v1';
import { Loading as LoadingV2 } from '@jane/shared/reefer-v2';
import type { LoadingProps } from '@jane/shared/reefer-v2';

export const Loading = (props: LoadingProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LoadingV2 {...props} />;
  }
  return <LoadingV1 {...props} />;
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  AccountIcon as AccountIconV1,
  AddIcon as AddIconV1,
  AlertIcon as AlertIconV1,
  AllProductsIcon as AllProductsIconV1,
  AppleIcon as AppleIconV1,
  ArchiveIcon as ArchiveIconV1,
  ArrowLeftIcon as ArrowLeftIconV1,
  ArrowRightIcon as ArrowRightIconV1,
  ArrowsCircleIcon as ArrowsCircleIconV1,
  BagFilledIcon as BagFilledIconV1,
  BagIcon as BagIconV1,
  BankCircledIcon as BankCircledIconV1,
  BankIcon as BankIconV1,
  BellIcon as BellIconV1,
  BipocIcon as BipocIconV1,
  BlissfulIcon as BlissfulIconV1,
  BlossomIcon as BlossomIconV1,
  BrokenImageIcon as BrokenImageIconV1,
  CardIcon as CardIconV1,
  CashIcon as CashIconV1,
  CbdHexIcon as CbdHexIconV1,
  CbdIcon as CbdIconV1,
  CheckCircledIcon as CheckCircledIconV1,
  CheckIcon as CheckIconV1,
  ChevronDownIcon as ChevronDownIconV1,
  ChevronLeftIcon as ChevronLeftIconV1,
  ChevronRightIcon as ChevronRightIconV1,
  ChevronUpIcon as ChevronUpIconV1,
  ClockIcon as ClockIconV1,
  ColumnsIcon as ColumnsIconV1,
  CopyIcon as CopyIconV1,
  CrossIcon as CrossIconV1,
  DecreaseIcon as DecreaseIconV1,
  DefaultCategoryIcon as DefaultCategoryIconV1,
  DismissFilledIcon as DismissFilledIconV1,
  DismissIcon as DismissIconV1,
  DragIcon as DragIconV1,
  EdibleIcon as EdibleIconV1,
  EdibleLiquidIcon as EdibleLiquidIconV1,
  EditIcon as EditIconV1,
  ErrorIcon as ErrorIconV1,
  ExternalIcon as ExternalIconV1,
  ExtractIcon as ExtractIconV1,
  EyeIcon as EyeIconV1,
  FacebookIcon as FacebookIconV1,
  FiltersIcon as FiltersIconV1,
  FlowerIcon as FlowerIconV1,
  FriendsIcon as FriendsIconV1,
  GearIcon as GearIconV1,
  GoogleIcon as GoogleIconV1,
  GovernmentIcon as GovernmentIconV1,
  GridIcon as GridIconV1,
  GrowIcon as GrowIconV1,
  HalfStarIcon as HalfStarIconV1,
  HeartFilledIcon as HeartFilledIconV1,
  HeartIcon as HeartIconV1,
  HideEyeIcon as HideEyeIconV1,
  HomeIcon as HomeIconV1,
  HopIcon as HopIconV1,
  HybridIcon as HybridIconV1,
  Icon as IconV1,
  IdIcon as IdIconV1,
  IncreaseIcon as IncreaseIconV1,
  IndicaIcon as IndicaIconV1,
  InfoIcon as InfoIconV1,
  JaneAppIcon as JaneAppIconV1,
  JaneGoldIcon as JaneGoldIconV1,
  LeafIcon as LeafIconV1,
  LemonIcon as LemonIconV1,
  LgbtqIcon as LgbtqIconV1,
  LightbulbIcon as LightbulbIconV1,
  LightningIcon as LightningIconV1,
  ListIcon as ListIconV1,
  LocationArrowIcon as LocationArrowIconV1,
  LocationPinFilledIcon as LocationPinFilledIconV1,
  LocationPinIcon as LocationPinIconV1,
  LockCircleIcon as LockCircleIconV1,
  LockSquareIcon as LockSquareIconV1,
  MapIcon as MapIconV1,
  MedicalIcon as MedicalIconV1,
  MenuFilledIcon as MenuFilledIconV1,
  MenuIcon as MenuIconV1,
  MerchIcon as MerchIconV1,
  MoreIcon as MoreIconV1,
  MoveIcon as MoveIconV1,
  NotHighIcon as NotHighIconV1,
  NoteIcon as NoteIconV1,
  PainFreeIcon as PainFreeIconV1,
  PauseIcon as PauseIconV1,
  PawprintIcon as PawprintIconV1,
  PencilIcon as PencilIconV1,
  PepperIcon as PepperIconV1,
  PercentageIcon as PercentageIconV1,
  PhotosIcon as PhotosIconV1,
  PinIcon as PinIconV1,
  PizzaIcon as PizzaIconV1,
  PlantIcon as PlantIconV1,
  PlayIcon as PlayIconV1,
  PrerollIcon as PrerollIconV1,
  ProfileCircledIcon as ProfileCircledIconV1,
  ProfileIcon as ProfileIconV1,
  RadiusPinIcon as RadiusPinIconV1,
  RedoIcon as RedoIconV1,
  RelaxedIcon as RelaxedIconV1,
  RelaxedWaveIcon as RelaxedWaveIconV1,
  SativaIcon as SativaIconV1,
  ScanIcon as ScanIconV1,
  SearchFilledIcon as SearchFilledIconV1,
  SearchIcon as SearchIconV1,
  SendIcon as SendIconV1,
  ShapeIcon as ShapeIconV1,
  SleepIcon as SleepIconV1,
  SoilIcon as SoilIconV1,
  SortDownIcon as SortDownIconV1,
  SortUpIcon as SortUpIconV1,
  SparkleIcon as SparkleIconV1,
  StarCircledIcon as StarCircledIconV1,
  StarFilledIcon as StarFilledIconV1,
  StarIcon as StarIconV1,
  StoreIcon as StoreIconV1,
  SubtractIcon as SubtractIconV1,
  TagFilledIcon as TagFilledIconV1,
  TagIcon as TagIconV1,
  TargetIcon as TargetIconV1,
  ThumbsUpIcon as ThumbsUpIconV1,
  TinctureIcon as TinctureIconV1,
  TopicalIcon as TopicalIconV1,
  TrashIcon as TrashIconV1,
  TwitterXIcon as TwitterXIconV1,
  UnarchiveIcon as UnarchiveIconV1,
  UndoIcon as UndoIconV1,
  VapeIcon as VapeIconV1,
  VerifiedIcon as VerifiedIconV1,
  VeteranIcon as VeteranIconV1,
  WheelchairIcon as WheelchairIconV1,
  WomanIcon as WomanIconV1,
} from '@jane/shared/reefer-v1';
import {
  AccountIcon as AccountIconV2,
  AddIcon as AddIconV2,
  AlertIcon as AlertIconV2,
  AllProductsIcon as AllProductsIconV2,
  AppleIcon as AppleIconV2,
  ArchiveIcon as ArchiveIconV2,
  ArrowLeftIcon as ArrowLeftIconV2,
  ArrowRightIcon as ArrowRightIconV2,
  ArrowsCircleIcon as ArrowsCircleIconV2,
  BagFilledIcon as BagFilledIconV2,
  BagIcon as BagIconV2,
  BankCircledIcon as BankCircledIconV2,
  BankIcon as BankIconV2,
  BellIcon as BellIconV2,
  BipocIcon as BipocIconV2,
  BlissfulIcon as BlissfulIconV2,
  BlossomIcon as BlossomIconV2,
  BrokenImageIcon as BrokenImageIconV2,
  CardIcon as CardIconV2,
  CashIcon as CashIconV2,
  CbdHexIcon as CbdHexIconV2,
  CbdIcon as CbdIconV2,
  CheckCircledIcon as CheckCircledIconV2,
  CheckIcon as CheckIconV2,
  ChevronDownIcon as ChevronDownIconV2,
  ChevronLeftIcon as ChevronLeftIconV2,
  ChevronRightIcon as ChevronRightIconV2,
  ChevronUpIcon as ChevronUpIconV2,
  ClockIcon as ClockIconV2,
  ColumnsIcon as ColumnsIconV2,
  CopyIcon as CopyIconV2,
  CrossIcon as CrossIconV2,
  DecreaseIcon as DecreaseIconV2,
  DefaultCategoryIcon as DefaultCategoryIconV2,
  DismissFilledIcon as DismissFilledIconV2,
  DismissIcon as DismissIconV2,
  DragIcon as DragIconV2,
  EdibleIcon as EdibleIconV2,
  EdibleLiquidIcon as EdibleLiquidIconV2,
  EditIcon as EditIconV2,
  ErrorIcon as ErrorIconV2,
  ExternalIcon as ExternalIconV2,
  ExtractIcon as ExtractIconV2,
  EyeIcon as EyeIconV2,
  FacebookIcon as FacebookIconV2,
  FiltersIcon as FiltersIconV2,
  FlowerIcon as FlowerIconV2,
  FriendsIcon as FriendsIconV2,
  GearIcon as GearIconV2,
  GoogleIcon as GoogleIconV2,
  GovernmentIcon as GovernmentIconV2,
  GridIcon as GridIconV2,
  GrowIcon as GrowIconV2,
  HalfStarIcon as HalfStarIconV2,
  HeartFilledIcon as HeartFilledIconV2,
  HeartIcon as HeartIconV2,
  HideEyeIcon as HideEyeIconV2,
  HomeIcon as HomeIconV2,
  HopIcon as HopIconV2,
  HybridIcon as HybridIconV2,
  Icon as IconV2,
  IdIcon as IdIconV2,
  IncreaseIcon as IncreaseIconV2,
  IndicaIcon as IndicaIconV2,
  InfoIcon as InfoIconV2,
  JaneAppIcon as JaneAppIconV2,
  JaneGoldIcon as JaneGoldIconV2,
  LeafIcon as LeafIconV2,
  LemonIcon as LemonIconV2,
  LgbtqIcon as LgbtqIconV2,
  LightbulbIcon as LightbulbIconV2,
  LightningIcon as LightningIconV2,
  ListIcon as ListIconV2,
  LocationArrowIcon as LocationArrowIconV2,
  LocationPinFilledIcon as LocationPinFilledIconV2,
  LocationPinIcon as LocationPinIconV2,
  LockCircleIcon as LockCircleIconV2,
  LockSquareIcon as LockSquareIconV2,
  MapIcon as MapIconV2,
  MedicalIcon as MedicalIconV2,
  MenuFilledIcon as MenuFilledIconV2,
  MenuIcon as MenuIconV2,
  MerchIcon as MerchIconV2,
  MoreIcon as MoreIconV2,
  MoveIcon as MoveIconV2,
  NotHighIcon as NotHighIconV2,
  NoteIcon as NoteIconV2,
  PainFreeIcon as PainFreeIconV2,
  PauseIcon as PauseIconV2,
  PawprintIcon as PawprintIconV2,
  PencilIcon as PencilIconV2,
  PepperIcon as PepperIconV2,
  PercentageIcon as PercentageIconV2,
  PhotosIcon as PhotosIconV2,
  PinIcon as PinIconV2,
  PizzaIcon as PizzaIconV2,
  PlantIcon as PlantIconV2,
  PlayIcon as PlayIconV2,
  PrerollIcon as PrerollIconV2,
  ProfileCircledIcon as ProfileCircledIconV2,
  ProfileIcon as ProfileIconV2,
  RadiusPinIcon as RadiusPinIconV2,
  RedoIcon as RedoIconV2,
  RelaxedIcon as RelaxedIconV2,
  RelaxedWaveIcon as RelaxedWaveIconV2,
  SativaIcon as SativaIconV2,
  ScanIcon as ScanIconV2,
  SearchFilledIcon as SearchFilledIconV2,
  SearchIcon as SearchIconV2,
  SendIcon as SendIconV2,
  ShapeIcon as ShapeIconV2,
  SleepIcon as SleepIconV2,
  SoilIcon as SoilIconV2,
  SortDownIcon as SortDownIconV2,
  SortUpIcon as SortUpIconV2,
  SparkleIcon as SparkleIconV2,
  StarCircledIcon as StarCircledIconV2,
  StarFilledIcon as StarFilledIconV2,
  StarIcon as StarIconV2,
  StoreIcon as StoreIconV2,
  SubtractIcon as SubtractIconV2,
  TagFilledIcon as TagFilledIconV2,
  TagIcon as TagIconV2,
  TargetIcon as TargetIconV2,
  ThumbsUpIcon as ThumbsUpIconV2,
  TinctureIcon as TinctureIconV2,
  TopicalIcon as TopicalIconV2,
  TrashIcon as TrashIconV2,
  TwitterXIcon as TwitterXIconV2,
  UnarchiveIcon as UnarchiveIconV2,
  UndoIcon as UndoIconV2,
  VapeIcon as VapeIconV2,
  VerifiedIcon as VerifiedIconV2,
  VeteranIcon as VeteranIconV2,
  WheelchairIcon as WheelchairIconV2,
  WomanIcon as WomanIconV2,
} from '@jane/shared/reefer-v2';

export const AccountIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AccountIconV2 {...props} />;
  }
  return <AccountIconV1 {...props} />;
};
export const AddIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AddIconV2 {...props} />;
  }
  return <AddIconV1 {...props} />;
};
export const AlertIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AlertIconV2 {...props} />;
  }
  return <AlertIconV1 {...props} />;
};
export const ArchiveIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ArchiveIconV2 {...props} />;
  }
  return <ArchiveIconV1 {...props} />;
};
export const ArrowLeftIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ArrowLeftIconV2 {...props} />;
  }
  return <ArrowLeftIconV1 {...props} />;
};
export const ArrowRightIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ArrowRightIconV2 {...props} />;
  }
  return <ArrowRightIconV1 {...props} />;
};
export const ArrowsCircleIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ArrowsCircleIconV2 {...props} />;
  }
  return <ArrowsCircleIconV1 {...props} />;
};
export const BagIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BagIconV2 {...props} />;
  }
  return <BagIconV1 {...props} />;
};
export const BagFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BagFilledIconV2 {...props} />;
  }
  return <BagFilledIconV1 {...props} />;
};
export const BankIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BankIconV2 {...props} />;
  }
  return <BankIconV1 {...props} />;
};
export const BankCircledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BankCircledIconV2 {...props} />;
  }
  return <BankCircledIconV1 {...props} />;
};
export const BellIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BellIconV2 {...props} />;
  }
  return <BellIconV1 {...props} />;
};
export const BrokenImageIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BrokenImageIconV2 {...props} />;
  }
  return <BrokenImageIconV1 {...props} />;
};
export const CardIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CardIconV2 {...props} />;
  }
  return <CardIconV1 {...props} />;
};
export const CashIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CashIconV2 {...props} />;
  }
  return <CashIconV1 {...props} />;
};
export const CbdIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CbdIconV2 {...props} />;
  }
  return <CbdIconV1 {...props} />;
};
export const CheckIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CheckIconV2 {...props} />;
  }
  return <CheckIconV1 {...props} />;
};
export const CheckCircledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CheckCircledIconV2 {...props} />;
  }
  return <CheckCircledIconV1 {...props} />;
};
export const ChevronDownIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ChevronDownIconV2 {...props} />;
  }
  return <ChevronDownIconV1 {...props} />;
};
export const ChevronLeftIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ChevronLeftIconV2 {...props} />;
  }
  return <ChevronLeftIconV1 {...props} />;
};
export const ChevronRightIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ChevronRightIconV2 {...props} />;
  }
  return <ChevronRightIconV1 {...props} />;
};
export const ChevronUpIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ChevronUpIconV2 {...props} />;
  }
  return <ChevronUpIconV1 {...props} />;
};
export const ClockIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ClockIconV2 {...props} />;
  }
  return <ClockIconV1 {...props} />;
};
export const ColumnsIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ColumnsIconV2 {...props} />;
  }
  return <ColumnsIconV1 {...props} />;
};
export const CopyIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CopyIconV2 {...props} />;
  }
  return <CopyIconV1 {...props} />;
};
export const DecreaseIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DecreaseIconV2 {...props} />;
  }
  return <DecreaseIconV1 {...props} />;
};
export const DismissIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DismissIconV2 {...props} />;
  }
  return <DismissIconV1 {...props} />;
};
export const DismissFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DismissFilledIconV2 {...props} />;
  }
  return <DismissFilledIconV1 {...props} />;
};
export const DragIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DragIconV2 {...props} />;
  }
  return <DragIconV1 {...props} />;
};
export const EditIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <EditIconV2 {...props} />;
  }
  return <EditIconV1 {...props} />;
};
export const ErrorIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ErrorIconV2 {...props} />;
  }
  return <ErrorIconV1 {...props} />;
};
export const ExternalIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ExternalIconV2 {...props} />;
  }
  return <ExternalIconV1 {...props} />;
};
export const EyeIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <EyeIconV2 {...props} />;
  }
  return <EyeIconV1 {...props} />;
};
export const FiltersIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FiltersIconV2 {...props} />;
  }
  return <FiltersIconV1 {...props} />;
};
export const FlowerIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FlowerIconV2 {...props} />;
  }
  return <FlowerIconV1 {...props} />;
};
export const GovernmentIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <GovernmentIconV2 {...props} />;
  }
  return <GovernmentIconV1 {...props} />;
};
export const GridIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <GridIconV2 {...props} />;
  }
  return <GridIconV1 {...props} />;
};
export const HeartIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HeartIconV2 {...props} />;
  }
  return <HeartIconV1 {...props} />;
};
export const HeartFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HeartFilledIconV2 {...props} />;
  }
  return <HeartFilledIconV1 {...props} />;
};
export const HideEyeIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HideEyeIconV2 {...props} />;
  }
  return <HideEyeIconV1 {...props} />;
};
export const HomeIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HomeIconV2 {...props} />;
  }
  return <HomeIconV1 {...props} />;
};
export const HybridIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HybridIconV2 {...props} />;
  }
  return <HybridIconV1 {...props} />;
};
export const Icon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <IconV2 {...props} />;
  }
  return <IconV1 {...props} />;
};
export const IdIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <IdIconV2 {...props} />;
  }
  return <IdIconV1 {...props} />;
};
export const IncreaseIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <IncreaseIconV2 {...props} />;
  }
  return <IncreaseIconV1 {...props} />;
};
export const IndicaIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <IndicaIconV2 {...props} />;
  }
  return <IndicaIconV1 {...props} />;
};
export const InfoIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <InfoIconV2 {...props} />;
  }
  return <InfoIconV1 {...props} />;
};
export const ListIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ListIconV2 {...props} />;
  }
  return <ListIconV1 {...props} />;
};
export const LocationArrowIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LocationArrowIconV2 {...props} />;
  }
  return <LocationArrowIconV1 {...props} />;
};
export const LocationPinIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LocationPinIconV2 {...props} />;
  }
  return <LocationPinIconV1 {...props} />;
};
export const LocationPinFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LocationPinFilledIconV2 {...props} />;
  }
  return <LocationPinFilledIconV1 {...props} />;
};
export const LockCircleIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LockCircleIconV2 {...props} />;
  }
  return <LockCircleIconV1 {...props} />;
};
export const LockSquareIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LockSquareIconV2 {...props} />;
  }
  return <LockSquareIconV1 {...props} />;
};
export const MapIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MapIconV2 {...props} />;
  }
  return <MapIconV1 {...props} />;
};
export const MedicalIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MedicalIconV2 {...props} />;
  }
  return <MedicalIconV1 {...props} />;
};
export const MenuIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MenuIconV2 {...props} />;
  }
  return <MenuIconV1 {...props} />;
};
export const MenuFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MenuFilledIconV2 {...props} />;
  }
  return <MenuFilledIconV1 {...props} />;
};
export const MoreIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MoreIconV2 {...props} />;
  }
  return <MoreIconV1 {...props} />;
};
export const MoveIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MoveIconV2 {...props} />;
  }
  return <MoveIconV1 {...props} />;
};
export const NoteIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <NoteIconV2 {...props} />;
  }
  return <NoteIconV1 {...props} />;
};
export const PauseIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PauseIconV2 {...props} />;
  }
  return <PauseIconV1 {...props} />;
};
export const PercentageIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PercentageIconV2 {...props} />;
  }
  return <PercentageIconV1 {...props} />;
};
export const PhotosIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PhotosIconV2 {...props} />;
  }
  return <PhotosIconV1 {...props} />;
};
export const PinIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PinIconV2 {...props} />;
  }
  return <PinIconV1 {...props} />;
};
export const PlantIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PlantIconV2 {...props} />;
  }
  return <PlantIconV1 {...props} />;
};
export const PlayIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PlayIconV2 {...props} />;
  }
  return <PlayIconV1 {...props} />;
};
export const ProfileIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ProfileIconV2 {...props} />;
  }
  return <ProfileIconV1 {...props} />;
};
export const ProfileCircledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ProfileCircledIconV2 {...props} />;
  }
  return <ProfileCircledIconV1 {...props} />;
};
export const RadiusPinIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <RadiusPinIconV2 {...props} />;
  }
  return <RadiusPinIconV1 {...props} />;
};
export const RedoIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <RedoIconV2 {...props} />;
  }
  return <RedoIconV1 {...props} />;
};
export const SativaIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SativaIconV2 {...props} />;
  }
  return <SativaIconV1 {...props} />;
};
export const ScanIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ScanIconV2 {...props} />;
  }
  return <ScanIconV1 {...props} />;
};
export const SearchIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SearchIconV2 {...props} />;
  }
  return <SearchIconV1 {...props} />;
};
export const SearchFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SearchFilledIconV2 {...props} />;
  }
  return <SearchFilledIconV1 {...props} />;
};
export const SendIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SendIconV2 {...props} />;
  }
  return <SendIconV1 {...props} />;
};
export const ShapeIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ShapeIconV2 {...props} />;
  }
  return <ShapeIconV1 {...props} />;
};
export const SleepIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SleepIconV2 {...props} />;
  }
  return <SleepIconV1 {...props} />;
};
export const SortDownIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SortDownIconV2 {...props} />;
  }
  return <SortDownIconV1 {...props} />;
};
export const SortUpIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SortUpIconV2 {...props} />;
  }
  return <SortUpIconV1 {...props} />;
};
export const SparkleIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SparkleIconV2 {...props} />;
  }
  return <SparkleIconV1 {...props} />;
};
export const StarIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <StarIconV2 {...props} />;
  }
  return <StarIconV1 {...props} />;
};
export const StarCircledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <StarCircledIconV2 {...props} />;
  }
  return <StarCircledIconV1 {...props} />;
};
export const StarFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <StarFilledIconV2 {...props} />;
  }
  return <StarFilledIconV1 {...props} />;
};
export const StoreIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <StoreIconV2 {...props} />;
  }
  return <StoreIconV1 {...props} />;
};
export const SubtractIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SubtractIconV2 {...props} />;
  }
  return <SubtractIconV1 {...props} />;
};
export const TagIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TagIconV2 {...props} />;
  }
  return <TagIconV1 {...props} />;
};
export const TagFilledIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TagFilledIconV2 {...props} />;
  }
  return <TagFilledIconV1 {...props} />;
};
export const TargetIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TargetIconV2 {...props} />;
  }
  return <TargetIconV1 {...props} />;
};
export const ThumbsUpIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ThumbsUpIconV2 {...props} />;
  }
  return <ThumbsUpIconV1 {...props} />;
};
export const TrashIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TrashIconV2 {...props} />;
  }
  return <TrashIconV1 {...props} />;
};
export const UnarchiveIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <UnarchiveIconV2 {...props} />;
  }
  return <UnarchiveIconV1 {...props} />;
};
export const UndoIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <UndoIconV2 {...props} />;
  }
  return <UndoIconV1 {...props} />;
};
export const VerifiedIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <VerifiedIconV2 {...props} />;
  }
  return <VerifiedIconV1 {...props} />;
};
export const WheelchairIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <WheelchairIconV2 {...props} />;
  }
  return <WheelchairIconV1 {...props} />;
};
export const AllProductsIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AllProductsIconV2 {...props} />;
  }
  return <AllProductsIconV1 {...props} />;
};
export const AppleIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AppleIconV2 {...props} />;
  }
  return <AppleIconV1 {...props} />;
};
export const BipocIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BipocIconV2 {...props} />;
  }
  return <BipocIconV1 {...props} />;
};
export const BlissfulIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BlissfulIconV2 {...props} />;
  }
  return <BlissfulIconV1 {...props} />;
};
export const BlossomIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BlossomIconV2 {...props} />;
  }
  return <BlossomIconV1 {...props} />;
};
export const CbdHexIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CbdHexIconV2 {...props} />;
  }
  return <CbdHexIconV1 {...props} />;
};
export const CrossIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CrossIconV2 {...props} />;
  }
  return <CrossIconV1 {...props} />;
};
export const DefaultCategoryIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DefaultCategoryIconV2 {...props} />;
  }
  return <DefaultCategoryIconV1 {...props} />;
};
export const EdibleIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <EdibleIconV2 {...props} />;
  }
  return <EdibleIconV1 {...props} />;
};
export const EdibleLiquidIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <EdibleLiquidIconV2 {...props} />;
  }
  return <EdibleLiquidIconV1 {...props} />;
};
export const ExtractIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ExtractIconV2 {...props} />;
  }
  return <ExtractIconV1 {...props} />;
};
export const FacebookIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FacebookIconV2 {...props} />;
  }
  return <FacebookIconV1 {...props} />;
};
export const FriendsIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FriendsIconV2 {...props} />;
  }
  return <FriendsIconV1 {...props} />;
};
export const GearIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <GearIconV2 {...props} />;
  }
  return <GearIconV1 {...props} />;
};
export const GoogleIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <GoogleIconV2 {...props} />;
  }
  return <GoogleIconV1 {...props} />;
};
export const GrowIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <GrowIconV2 {...props} />;
  }
  return <GrowIconV1 {...props} />;
};
export const HalfStarIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HalfStarIconV2 {...props} />;
  }
  return <HalfStarIconV1 {...props} />;
};
export const HopIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HopIconV2 {...props} />;
  }
  return <HopIconV1 {...props} />;
};
export const JaneAppIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JaneAppIconV2 {...props} />;
  }
  return <JaneAppIconV1 {...props} />;
};
export const JaneGoldIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JaneGoldIconV2 {...props} />;
  }
  return <JaneGoldIconV1 {...props} />;
};
export const LeafIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LeafIconV2 {...props} />;
  }
  return <LeafIconV1 {...props} />;
};
export const LemonIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LemonIconV2 {...props} />;
  }
  return <LemonIconV1 {...props} />;
};
export const LgbtqIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LgbtqIconV2 {...props} />;
  }
  return <LgbtqIconV1 {...props} />;
};
export const LightbulbIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LightbulbIconV2 {...props} />;
  }
  return <LightbulbIconV1 {...props} />;
};
export const LightningIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LightningIconV2 {...props} />;
  }
  return <LightningIconV1 {...props} />;
};
export const MerchIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MerchIconV2 {...props} />;
  }
  return <MerchIconV1 {...props} />;
};
export const NotHighIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <NotHighIconV2 {...props} />;
  }
  return <NotHighIconV1 {...props} />;
};
export const PainFreeIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PainFreeIconV2 {...props} />;
  }
  return <PainFreeIconV1 {...props} />;
};
export const PawprintIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PawprintIconV2 {...props} />;
  }
  return <PawprintIconV1 {...props} />;
};
export const PencilIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PencilIconV2 {...props} />;
  }
  return <PencilIconV1 {...props} />;
};
export const PepperIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PepperIconV2 {...props} />;
  }
  return <PepperIconV1 {...props} />;
};
export const PizzaIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PizzaIconV2 {...props} />;
  }
  return <PizzaIconV1 {...props} />;
};
export const PrerollIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PrerollIconV2 {...props} />;
  }
  return <PrerollIconV1 {...props} />;
};
export const RelaxedIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <RelaxedIconV2 {...props} />;
  }
  return <RelaxedIconV1 {...props} />;
};
export const RelaxedWaveIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <RelaxedWaveIconV2 {...props} />;
  }
  return <RelaxedWaveIconV1 {...props} />;
};
export const SoilIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SoilIconV2 {...props} />;
  }
  return <SoilIconV1 {...props} />;
};
export const TinctureIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TinctureIconV2 {...props} />;
  }
  return <TinctureIconV1 {...props} />;
};
export const TopicalIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TopicalIconV2 {...props} />;
  }
  return <TopicalIconV1 {...props} />;
};
export const TwitterXIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TwitterXIconV2 {...props} />;
  }
  return <TwitterXIconV1 {...props} />;
};
export const VapeIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <VapeIconV2 {...props} />;
  }
  return <VapeIconV1 {...props} />;
};
export const VeteranIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <VeteranIconV2 {...props} />;
  }
  return <VeteranIconV1 {...props} />;
};
export const WomanIcon = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <WomanIconV2 {...props} />;
  }
  return <WomanIconV1 {...props} />;
};

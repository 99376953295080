import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import { useFormContext } from 'react-hook-form';

import { ALL_DAYS } from '@jane/business-admin/util';
import { CheckboxField, Flex, Typography } from '@jane/shared/reefer';

import { ScheduleOverrideHourPicker } from './ScheduleOverrideHourPicker';

const BottomBorderedFlex = styled(Flex)(({ theme }) => ({
  borderColor: theme.colors.grays.light,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  paddingBottom: '16px',
  marginBottom: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '16px',
  paddingRight: '16px',
}));

export const ScheduleOverride = () => {
  const { setValue, getValues, watch } = useFormContext();

  const copyToOtherDays = ({
    dayToCopyFrom,
    daysToCopyTo,
  }: {
    dayToCopyFrom: string;
    daysToCopyTo: string[];
  }) => {
    const startKey = `start_time_${dayToCopyFrom}`;
    const endKey = `end_time_${dayToCopyFrom}`;
    const allDayKey = `enabled_${dayToCopyFrom}_all_day`;

    const values = getValues('schedule');

    daysToCopyTo.forEach((copyToDay) => {
      setValue(`schedule.enabled_${copyToDay}`, true, { shouldDirty: true });
      setValue(`schedule.enabled_${copyToDay}_all_day`, values[allDayKey]);
      setValue(`schedule.start_time_${copyToDay}`, values[startKey] || '');
      setValue(`schedule.end_time_${copyToDay}`, values[endKey] || '');
    });
  };

  return (
    <Flex flexDirection="column">
      <BottomBorderedFlex>
        <Typography variant="caps" color="grays-mid">
          Day
        </Typography>
        <Flex width="80%" alignItems="center">
          <Typography variant="caps" color="grays-mid" width={155}>
            All Day
          </Typography>
          <Typography variant="caps" color="grays-mid" width={220}>
            Start Time
          </Typography>
          <Typography variant="caps" color="grays-mid" width={220}>
            End Time
          </Typography>
        </Flex>
      </BottomBorderedFlex>
      {ALL_DAYS.map((day, index) => {
        const enabledKey = `schedule.enabled_${day}`;
        const values = watch('schedule');

        const checked = values[`enabled_${day}`] === true;
        const alignment = index > 1 ? true : false;

        return (
          <BottomBorderedFlex key={day}>
            <CheckboxField
              checked={checked}
              label={capitalize(day)}
              name={enabledKey}
              onChange={(val) => {
                setValue(enabledKey, val);
              }}
            />
            <ScheduleOverrideHourPicker
              alignment={alignment}
              copy={copyToOtherDays}
              day={day}
              disabled={!checked}
            />
          </BottomBorderedFlex>
        );
      })}
    </Flex>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Preroll24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8749 0.516364C22.6074 0.0331526 21.9989 -0.141763 21.5157 0.12568C20.6824 0.586901 20.0174 1.33834 20.0003 2.34639C19.9838 3.31401 20.5763 4.14887 21.3792 4.78457C21.9451 5.2326 22.0013 5.5387 21.9999 5.62065C21.9992 5.6622 21.9796 5.86889 21.5157 6.1257C21.0325 6.39316 20.8576 7.0017 21.125 7.4849C21.3925 7.9681 22.001 8.14298 22.4842 7.87552C23.3176 7.41425 23.9824 6.66277 23.9996 5.65474C24.0161 4.68711 23.4236 3.85223 22.6207 3.21651C22.0547 2.76846 21.9986 2.46237 22 2.38047C22.0007 2.339 22.0202 2.13235 22.4842 1.87554C22.9674 1.6081 23.1423 0.999575 22.8749 0.516364Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3899 6.42508C19.0543 7.18102 19.8132 8.26567 20.5262 9.50063C21.2392 10.7356 21.7991 11.9352 22.1216 12.8885C22.2801 13.3572 22.3988 13.8169 22.4314 14.214C22.4475 14.4098 22.4482 14.6495 22.3867 14.8898C22.3247 15.1323 22.1689 15.4804 21.7942 15.6968C21.739 15.7286 21.6822 15.7556 21.6244 15.7778L5.14241 22.9991C4.66405 23.2434 4.18104 23.1381 3.88003 23.0179C3.55242 22.8871 3.25054 22.6701 2.99053 22.4395C2.46226 21.9711 1.92666 21.2852 1.47366 20.5006C1.02066 19.716 0.694526 18.9093 0.552977 18.2175C0.483308 17.8771 0.446252 17.5071 0.496816 17.158C0.543271 16.8373 0.693577 16.3663 1.14426 16.0741L15.6391 5.41099C15.6872 5.37206 15.739 5.33634 15.7942 5.30448C16.1689 5.08813 16.5483 5.12726 16.7893 5.19481C17.0281 5.26172 17.2354 5.38213 17.3969 5.49395C17.7246 5.72077 18.0633 6.05342 18.3899 6.42508Z"
      fill="#FF5500"
    />
    <path
      d="M13.8301 9.90251C14.1062 10.3808 13.9423 10.9924 13.464 11.2685C12.9857 11.5447 12.3742 11.3808 12.098 10.9025C11.8219 10.4242 11.9857 9.81263 12.464 9.53649C12.9423 9.26034 13.5539 9.42422 13.8301 9.90251Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M17.9282 11.0006C18.2043 11.4789 18.0405 12.0905 17.5622 12.3666C17.0839 12.6428 16.4723 12.4789 16.1962 12.0006C15.92 11.5223 16.0839 10.9107 16.5622 10.6346C17.0405 10.3584 17.6521 10.5223 17.9282 11.0006Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M16.464 16.4647C16.9423 16.1886 17.1062 15.577 16.8301 15.0987C16.5539 14.6204 15.9423 14.4565 15.464 14.7327C14.9857 15.0088 14.8219 15.6204 15.098 16.0987C15.3742 16.577 15.9857 16.7408 16.464 16.4647Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M12.732 14.0006C13.0082 14.4789 12.8443 15.0904 12.366 15.3666C11.8877 15.6427 11.2761 15.4789 11 15.0006C10.7238 14.5223 10.8877 13.9107 11.366 13.6345C11.8443 13.3584 12.4559 13.5223 12.732 14.0006Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M5.43779 19.3666C5.91608 19.0905 6.07996 18.4789 5.80381 18.0006C5.52767 17.5223 4.91608 17.3584 4.43779 17.6346C3.9595 17.9107 3.79562 18.5223 4.07176 19.0006C4.34791 19.4789 4.9595 19.6427 5.43779 19.3666Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M8.26793 14.2685C8.54407 14.7468 8.38019 15.3584 7.9019 15.6346C7.42361 15.9107 6.81202 15.7468 6.53587 15.2685C6.25973 14.7902 6.42361 14.1787 6.9019 13.9025C7.38019 13.6264 7.99178 13.7902 8.26793 14.2685Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M9.9019 19.0987C10.3802 18.8225 10.5441 18.2109 10.2679 17.7326C9.99178 17.2544 9.38019 17.0905 8.9019 17.3666C8.42361 17.6428 8.25973 18.2544 8.53587 18.7326C8.81202 19.2109 9.42361 19.3748 9.9019 19.0987Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const Preroll48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8959 2.98137C43.3957 2.06282 42.2576 1.73032 41.3539 2.23871C39.7954 3.11546 38.5518 4.54389 38.5197 6.46012C38.4889 8.29951 39.597 9.88651 41.0987 11.0949C42.157 11.9466 42.2621 12.5285 42.2595 12.6843C42.2581 12.7632 42.2216 13.1561 41.3538 13.6443C40.4501 14.1528 40.1231 15.3095 40.6233 16.2281C41.1235 17.1466 42.2616 17.479 43.1653 16.9706C44.7238 16.0938 45.9672 14.6653 45.9994 12.7491C46.0302 10.9097 44.9222 9.32262 43.4204 8.11416C42.362 7.26244 42.257 6.68059 42.2596 6.52491C42.2609 6.44608 42.2974 6.05325 43.1652 5.56507C44.0689 5.05668 44.396 3.89992 43.8959 2.98137Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.5079 14.2134C36.7504 15.6503 38.1698 17.7122 39.5032 20.0597C40.8367 22.4073 41.8838 24.6877 42.4869 26.4998C42.7834 27.3908 43.0055 28.2646 43.0664 29.0196C43.0965 29.3918 43.0977 29.8474 42.9828 30.3041C42.8668 30.7651 42.5754 31.4269 41.8746 31.8382C41.7714 31.8988 41.6652 31.95 41.5571 31.9923L10.7321 45.7195C9.83747 46.1837 8.93413 45.9835 8.37119 45.7551C7.75847 45.5066 7.19388 45.0939 6.70761 44.6556C5.71963 43.7652 4.71793 42.4615 3.87072 40.97C3.02351 39.4785 2.41358 37.9449 2.14885 36.63C2.01855 35.9828 1.94925 35.2795 2.04381 34.6159C2.1307 34.0062 2.4118 33.1109 3.25468 32.5555L30.3632 12.2856C30.4533 12.2116 30.55 12.1437 30.6532 12.0832C31.3541 11.6719 32.0637 11.7463 32.5145 11.8747C32.961 12.0019 33.3486 12.2308 33.6507 12.4433C34.2635 12.8745 34.897 13.5069 35.5079 14.2134Z"
      fill="#FF5500"
    />
    <path
      d="M26.9799 20.8238C27.4964 21.733 27.1899 22.8956 26.2954 23.4205C25.4009 23.9455 24.2571 23.634 23.7406 22.7248C23.2242 21.8156 23.5307 20.653 24.4252 20.128C25.3197 19.6031 26.4635 19.9146 26.9799 20.8238Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M34.6444 22.9112C35.1608 23.8204 34.8543 24.983 33.9598 25.5079C33.0653 26.0329 31.9215 25.7213 31.4051 24.8121C30.8886 23.9029 31.1951 22.7403 32.0896 22.2154C32.9841 21.6905 34.1279 22.002 34.6444 22.9112Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M31.9061 33.2981C32.8006 32.7732 33.107 31.6106 32.5906 30.7014C32.0742 29.7922 30.9303 29.4807 30.0358 30.0056C29.1413 30.5305 28.8348 31.6931 29.3513 32.6023C29.8677 33.5115 31.0115 33.823 31.9061 33.2981Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M24.9264 28.6139C25.4428 29.5231 25.1364 30.6857 24.2418 31.2107C23.3473 31.7356 22.2035 31.4241 21.6871 30.5149C21.1706 29.6057 21.4771 28.4431 22.3716 27.9181C23.2661 27.3932 24.4099 27.7047 24.9264 28.6139Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M11.2845 38.8144C12.179 38.2895 12.4855 37.1269 11.9691 36.2177C11.4526 35.3085 10.3088 34.997 9.41431 35.5219C8.51979 36.0468 8.21331 37.2094 8.72976 38.1186C9.2462 39.0278 10.39 39.3393 11.2845 38.8144Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M16.5775 29.1233C17.094 30.0325 16.7875 31.1951 15.893 31.7201C14.9984 32.245 13.8546 31.9335 13.3382 31.0243C12.8217 30.1151 13.1282 28.9525 14.0227 28.4276C14.9173 27.9026 16.0611 28.2141 16.5775 29.1233Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M19.6334 38.3051C20.5279 37.7802 20.8344 36.6176 20.318 35.7084C19.8015 34.7992 18.6577 34.4877 17.7632 35.0126C16.8687 35.5375 16.5622 36.7001 17.0786 37.6093C17.5951 38.5185 18.7389 38.83 19.6334 38.3051Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Preroll24,
  sm: Preroll24,
  xl: Preroll48,
  lg: Preroll48,
  xxl: Preroll48,
  xxxl: Preroll48,
};

export const PrerollIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'preroll-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const JaneGold16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={8} fill="#FFC220" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6666 7.99992C12.6666 8.19817 12.5059 8.35889 12.3076 8.35889C10.1268 8.35889 8.35889 10.1268 8.35889 12.3076C8.35889 12.5059 8.19817 12.6666 7.99992 12.6666C7.80166 12.6666 7.64094 12.5059 7.64094 12.3076C7.64094 10.1268 5.87304 8.35889 3.69223 8.35889C3.49397 8.35889 3.33325 8.19817 3.33325 7.99992C3.33325 7.80166 3.49397 7.64094 3.69223 7.64094C5.87304 7.64094 7.64094 5.87304 7.64094 3.69223C7.64094 3.49397 7.80166 3.33325 7.99992 3.33325C8.19817 3.33325 8.35889 3.49397 8.35889 3.69223C8.35889 5.87304 10.1268 7.64094 12.3076 7.64094C12.5059 7.64094 12.6666 7.80166 12.6666 7.99992Z"
      fill="white"
    />
  </svg>
);

const JaneGold24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#FFC220" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 12C19 12.2974 18.7589 12.5385 18.4615 12.5385C15.1903 12.5385 12.5385 15.1903 12.5385 18.4615C12.5385 18.7589 12.2974 19 12 19C11.7026 19 11.4615 18.7589 11.4615 18.4615C11.4615 15.1903 8.80969 12.5385 5.53846 12.5385C5.24108 12.5385 5 12.2974 5 12C5 11.7026 5.24108 11.4615 5.53846 11.4615C8.80969 11.4615 11.4615 8.80969 11.4615 5.53846C11.4615 5.24108 11.7026 5 12 5C12.2974 5 12.5385 5.24108 12.5385 5.53846C12.5385 8.80969 15.1903 11.4615 18.4615 11.4615C18.7589 11.4615 19 11.7026 19 12Z"
      fill="white"
    />
  </svg>
);

const JaneGold48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="#FFC220" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 24C38 24.5948 37.5178 25.0769 36.9231 25.0769C30.3806 25.0769 25.0769 30.3806 25.0769 36.9231C25.0769 37.5178 24.5948 38 24 38C23.4052 38 22.9231 37.5178 22.9231 36.9231C22.9231 30.3806 17.6194 25.0769 11.0769 25.0769C10.4822 25.0769 10 24.5948 10 24C10 23.4052 10.4822 22.9231 11.0769 22.9231C17.6194 22.9231 22.9231 17.6194 22.9231 11.0769C22.9231 10.4822 23.4052 10 24 10C24.5948 10 25.0769 10.4822 25.0769 11.0769C25.0769 17.6194 30.3806 22.9231 36.9231 22.9231C37.5178 22.9231 38 23.4052 38 24Z"
      fill="white"
    />
  </svg>
);

const JaneGold64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32} cy={32} r={32} fill="#FFC220" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.6666 31.9999C50.6666 32.7929 50.0237 33.4358 49.2307 33.4358C40.5074 33.4358 33.4358 40.5074 33.4358 49.2307C33.4358 50.0237 32.7929 50.6666 31.9999 50.6666C31.2069 50.6666 30.564 50.0237 30.564 49.2307C30.564 40.5074 23.4924 33.4358 14.7691 33.4358C13.9761 33.4358 13.3333 32.7929 13.3333 31.9999C13.3333 31.2069 13.9761 30.564 14.7691 30.564C23.4924 30.564 30.564 23.4924 30.564 14.7691C30.564 13.9761 31.2069 13.3333 31.9999 13.3333C32.7929 13.3333 33.4358 13.9761 33.4358 14.7691C33.4358 23.4924 40.5074 30.564 49.2307 30.564C50.0237 30.564 50.6666 31.2069 50.6666 31.9999Z"
      fill="white"
    />
  </svg>
);

const SIZE_MAP = {
  sm: JaneGold16,
  md: JaneGold24,
  xl: JaneGold48,
  lg: JaneGold48,
  xxl: JaneGold64,
  xxxl: JaneGold64,
};

export const JaneGoldIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'jane-gold-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import type { ColorKey, IconComponentProps } from '@jane/shared/reefer';
import {
  BipocIcon,
  BlissfulIcon,
  BlossomIcon,
  CardIcon,
  CbdIcon,
  CrossIcon,
  EdibleIcon,
  ExtractIcon,
  FlowerIcon,
  FriendsIcon,
  GearIcon,
  GrowIcon,
  HeartFilledIcon,
  HopIcon,
  HybridIcon,
  IndicaIcon,
  JaneGoldIcon,
  LeafIcon,
  LemonIcon,
  LgbtqIcon,
  LightbulbIcon,
  LightningIcon,
  MerchIcon,
  NotHighIcon,
  PainFreeIcon,
  PencilIcon,
  PepperIcon,
  PizzaIcon,
  PrerollIcon,
  RelaxedIcon,
  RelaxedWaveIcon,
  SativaIcon,
  SleepIcon,
  SoilIcon,
  TinctureIcon,
  TopicalIcon,
  VapeIcon,
  VeteranIcon,
  WheelchairIcon,
  WomanIcon,
} from '@jane/shared/reefer';

export const PRODUCT_ROOT_TYPES = [
  'gear',
  'pre-roll',
  'extract',
  'edible',
  'merch',
  'grow',
  'tincture',
  'flower',
  'topical',
  'vape',
];

export const PRODUCTS_PER_PAGE_TABLE = 60;
export const DESKTOP_PRODUCTS_PER_PAGE_GRID = 60;
export const MOBILE_PRODUCTS_PER_PAGE_GRID = 30;
export const PRODUCTS_PER_PAGE_CAROUSEL = 16;

export const bucketFilterKeys = ['bucket_price', 'aggregate_rating'];
export const rangeFilterKeys = ['percent_cbd', 'percent_thc'];

export type FilterIcons = { [label: string]: IconComponentProps };
export type IconColors = { [label: string]: ColorKey };

export const CATEGORY_ICONS: FilterIcons = {
  flower: FlowerIcon,
  vape: VapeIcon,
  'pre-roll': PrerollIcon,
  edible: EdibleIcon,
  extract: ExtractIcon,
  tincture: TinctureIcon,
  topical: TopicalIcon,
  gear: GearIcon,
  merch: MerchIcon,
  grow: GrowIcon,
};

export const FEELING_ICONS: FilterIcons = {
  Relaxed: RelaxedIcon,
  Sleepy: SleepIcon,
  Blissful: BlissfulIcon,
  Hungry: PizzaIcon,
  'Pain free': PainFreeIcon,
  'Not high': NotHighIcon,
  Energetic: LightningIcon,
  Creative: PencilIcon,
};

export const ACTIVITY_ICONS: FilterIcons = {
  'Ease my mind': RelaxedWaveIcon,
  'Get relief': CrossIcon,
  'Get some sleep': SleepIcon,
  'Stimulate my mind': LightbulbIcon,
  'Hang with friends': FriendsIcon,
  'Get Active': LightningIcon,
  // TOOD(Kyrsten): Figure out how to pass color along here
  'Get intimate': HeartFilledIcon,
};

export const FLAVOR_ICONS: FilterIcons = {
  citrusy: LemonIcon,
  spicy: PepperIcon,
  floral: BlossomIcon,
  hoppy: HopIcon,
  earthy: SoilIcon,
  herbal: LeafIcon,
};

export const LINEAGE_ICONS: FilterIcons = {
  sativa: SativaIcon,
  indica: IndicaIcon,
  hybrid: HybridIcon,
  cbd: CbdIcon,
};

export const JANE_GOLD_ICON: FilterIcons = {
  true: JaneGoldIcon,
};

export const OWNERSHIP_ICONS: FilterIcons = {
  lgbtq: LgbtqIcon,
  bipoc: BipocIcon,
  veteran: VeteranIcon,
  woman: WomanIcon,
};

export const SERVICES_ICONS: FilterIcons = {
  credit: CardIcon,
  accessible: WheelchairIcon,
};

export const customDealLabels = {
  'Daily deals': 'Daily deals',
  'Medical discounts': 'Medical',
  'Senior discounts': 'Senior',
  'Student discounts': 'Student',
  'Veteran discounts': 'Veteran',
};

export const customOwnershipLabels = {
  lgbtq: 'LGBTQ+',
  bipoc: 'BIPOC',
};

export const customServicesLabel = {
  credit: 'Accepts credit cards',
};

export const LINEAGE_COLORS: IconColors = {
  sativa: 'ember',
  indica: 'grape',
  hybrid: 'grays-black',
  cbd: 'grays-mid',
};

export const TERPENE_COLORS: IconColors = {
  limonene: 'gold',
  nerolidol: 'gold',
  valencene: 'gold',
  caryophyllene: 'ember-dark',
  'caryophyllene oxide': 'ember-dark',
  sabinene: 'ember-dark',
  linalool: 'pacific',
  geraniol: 'pacific',
  'geranyl acetate': 'pacific',
  humulene: 'success',
  myrcene: 'success',
  pinene: 'tangerine-dark',
  camphene: 'tangerine-dark',
  camphor: 'tangerine-dark',
  carene: 'tangerine-dark',
  cedrene: 'tangerine-dark',
  guaiol: 'tangerine-dark',
  eucalyptol: 'tangerine-dark',
  terpineol: 'tangerine-dark',
  terpinolene: 'tangerine-dark',
  phellandrene: 'tangerine-dark',
  bisabolol: 'emerald',
  borneol: 'emerald',
  cymene: 'emerald',
  fenchol: 'emerald',
  menthol: 'emerald',
  isoborneol: 'emerald',
  isopulegol: 'emerald',
  pulegone: 'emerald',
  ocimene: 'emerald',
  phytol: 'emerald',
};

// Santa Cruz
export const DEFAULT_COORDINATES = {
  lat: 36.973844,
  long: -122.031069,
};

export const CATEGORY_DESCRIPTIONS: { [key: string]: string } = {
  edible:
    'Cannabis-infused food products broken down through the digestion process. Personal tolerance for edibles may differ from other cannabis consumption methods, as they have a longer and more gradual onset than other products (45 min - 2 hours).',
  extract:
    'Cannabis concentrates produced with a solvent used to isolate active cannabinoids. Extracts have high potencies when compared to flower products and require specialized equipment to consume directly. Extracts are best enjoyed through vaporization and have an immediate onset.',
  flower:
    'The smokable part of the cannabis plant that is most popular. Cannabis flower is rich in trichomes, resin glands containing the cannabinoids and terpenes that together produce anywhere from relaxing to stimulating effects depending on the potency and ratios of each active compound.',
  'pre-roll': `Finely ground cannabis flower rolled ahead of time, commonly known as Joints or Blunts. More adventurous consumers might experiment with infused Pre Rolls that contain cannabis concentrates to amplify the flowers' entourage effects in enhanced flavor and potency.`,
  tincture:
    'Cannabis-infused products intended to be absorbed under the tongue. A distinct property of  Tinctures is that cannabis enters the bloodstream directly through the membrane under the tongue. As such, tinctures typically have a very quick onset (5 - 20 mins).',
  topical:
    'Cannabis-infused products intended to be applied locally and absorbed slowly through the skin. Topicals are non-psychoactive and most beneficial for application to the immediate area. Some products may have an accelerated absorption formula, but the typical cannabis topical has a gradual onset of approximately 30 min.',
  vape: 'Cannabis-infused vapor inhaled from a liquid cannabis concentrate heated with an electric powered device or battery. Vaporization allows for that inhalation of higher-potency cannabis with the delivery method that is an alternative to smoking, but with the same immediate onset.',
};

import { ObjectKeys } from '../../internal/utils';
import type {
  BaseTypographyVariantConfig,
  BoldTypographyVariant,
  BoldTypographyVariantConfig,
  TypographyConfig,
  TypographyVariantConfig,
} from '../../types';

const WEIGHT_SEMIBOLD = 600;
const WEIGHT_REGULAR = 400;
const LETTER_SPACING_NORMAL = 'normal';

const baseVariantConfig: BaseTypographyVariantConfig = {
  body: {
    desktopStyles: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    fontWeight: WEIGHT_REGULAR,
    fontSize: '15px',
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '24px',
    textTransform: 'none',
  },
  caps: {
    desktopStyles: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    fontWeight: WEIGHT_REGULAR,
    letterSpacing: '0.1em',
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  header: {
    desktopStyles: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    fontWeight: WEIGHT_REGULAR,
    fontSize: '20px',
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '24px',
    textTransform: 'none',
  },
  mini: {
    desktopStyles: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    fontWeight: WEIGHT_REGULAR,
    fontSize: '13px',
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '16px',
    textTransform: 'none',
  },
  title: {
    branded: true,
    desktopStyles: {
      fontSize: '36px',
      lineHeight: '40px',
    },
    fontWeight: WEIGHT_REGULAR,
    fontSize: '28px',
    letterSpacing: LETTER_SPACING_NORMAL,
    lineHeight: '32px',
    textTransform: 'none',
  },
};

const boldVariantConfig = ObjectKeys(baseVariantConfig)
  .map<BoldTypographyVariantConfig>((value) => {
    const variantKey: BoldTypographyVariant = `${value}-bold`;
    const obj: TypographyVariantConfig<string> = {
      [variantKey]: {
        ...baseVariantConfig[value],
        fontWeight: WEIGHT_SEMIBOLD,
      },
    };
    return obj as TypographyVariantConfig<BoldTypographyVariant>;
  })
  .reduce((prev, curr) => ({ ...prev, ...curr }));

export const Typography: TypographyConfig = {
  componentMapping: {
    body: 'p',
    caps: 'p',
    header: 'h2',
    mini: 'p',
    title: 'h1',
    'body-bold': 'p',
    'caps-bold': 'p',
    'header-bold': 'h2',
    'mini-bold': 'p',
    'title-bold': 'h1',
  },
  fonts: {
    branded: {
      fontFamily: 'Euclid Circular B',
    },
    default: {
      fontFamily: 'Jane Default',
    },
  },
  variants: {
    ...baseVariantConfig,
    ...boldVariantConfig,
  },
};

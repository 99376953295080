import type { GridItemProps } from '../grid.types';
import { StyledGridItem, StyledGridItemContainer } from './gridItem.styles';

/**
 * Each individual grid item within the `Grid.Container` component is defined using the `Grid.Item` compoennt.
 */
export function GridItem({
  'data-testid': testId,
  ariaLabel,
  onClick,
  children,
  className,
  id,
  spacing = 0,
  style,
  direction = 'row',
  xs,
  sm,
  md,
  lg,
  xl,
  ...paddingProps
}: GridItemProps) {
  return (
    <StyledGridItemContainer
      aria-label={ariaLabel}
      className={className}
      id={id}
      onClick={onClick}
      data-testid={testId}
      direction={direction}
      spacing={spacing}
      style={style}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      <StyledGridItem {...paddingProps}>{children}</StyledGridItem>
    </StyledGridItemContainer>
  );
}

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Hybrid16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.76895 12.502V4H6.27695V7.419H9.72195V4H11.23V12.502H9.72195V8.732H6.27695V12.502H4.76895Z"
      fill="#666666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V4C15 2.34315 13.6569 1 12 1ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z"
      fill="#666666"
    />
  </svg>
);

const Hybrid24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={22}
      height={22}
      rx={3}
      stroke="#0E0E0E"
      strokeWidth={2}
    />
    <path
      d="M8.27186 17V7.19H10.0119V11.135H13.9869V7.19H15.7269V17H13.9869V12.65H10.0119V17H8.27186Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  sm: Hybrid16,
  md: Hybrid24,
  lg: Hybrid24,
  xl: Hybrid24,
  xxl: Hybrid24,
  xxxl: Hybrid24,
};

export const HybridIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'hybrid-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { janeApiV2 } from '@jane/shared/data-access';

type IframeHeaderResponse = { header?: string; ok: boolean } | null;

const iframeHeaderUrl = () => `/managers/iframe_header`;

const fetchIframeHeader = async (
  url: string
): Promise<IframeHeaderResponse> => {
  if (!url) {
    return null;
  }
  const data = await janeApiV2.post<IframeHeaderResponse>(iframeHeaderUrl(), {
    url,
  });

  return data;
};

export const useFetchIframeHeader = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (url: string) =>
      queryClient.ensureQueryData({
        queryFn: async () => await fetchIframeHeader(url),
        queryKey: ['iframe_header', url],
      }),
  });
};

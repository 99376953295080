import clsx from 'clsx';
import type { CSSProperties } from 'react';

import { CheckboxField } from '@jane/shared/reefer-v2';

import type { TableCellProps } from '../table.types';
import styles from './tableCell.module.css';

export function TableCell({
  checkbox,
  children,
  className,
  'data-testid': testId,
  height = '72px',
  id,
  onClick,
  style,
  width = 'auto',
}: TableCellProps) {
  return (
    <td
      className={clsx(className, styles.tableCell, {
        [styles.tableCell__clickable]: !!onClick,
      })}
      data-testid={testId}
      id={id}
      style={
        {
          '--tableCell-height': height,
          '--tableCell-width': width,
          ...style,
        } as CSSProperties
      }
    >
      {checkbox && (
        <CheckboxField
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          label=""
          name="cell-checkbox"
          labelHidden
          width={width}
        />
      )}
      {!checkbox && !!onClick && <button onClick={onClick}>{children}</button>}
      {!checkbox && !onClick && children}
    </td>
  );
}

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { ButtonToggle as ButtonToggleV1 } from '@jane/shared/reefer-v1';
import { ButtonToggle as ButtonToggleV2 } from '@jane/shared/reefer-v2';
import type {
  ButtonToggleButtonProps,
  ButtonToggleProps,
} from '@jane/shared/reefer-v2';

const MainButtonToggle = (props: ButtonToggleProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ButtonToggleV2 {...props} />;
  }
  return <ButtonToggleV1 {...props} />;
};

const ButtonToggleButton = (props: ButtonToggleButtonProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ButtonToggleV2.Button {...props} />;
  }
  return <ButtonToggleV1.Button {...props} />;
};

export const ButtonToggle = Object.assign(MainButtonToggle, {
  Button: ButtonToggleButton,
});

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  AeropayLogoSecondary as AeropayLogoSecondaryV1,
  AeropayLogo as AeropayLogoV1,
  AeropayLogoWhite as AeropayLogoWhiteV1,
  BoaLogo as BoaLogoV1,
  CanpayLogo as CanpayLogoV1,
  CapitalOneLogo as CapitalOneLogoV1,
  ChaseLogo as ChaseLogoV1,
  CitiLogo as CitiLogoV1,
  JaneGoldLogoBig as JaneGoldLogoBigV1,
  JaneGoldLogo as JaneGoldLogoV1,
  JaneGoldShadowLogo as JaneGoldShadowLogoV1,
  JanePayLogo as JanePayLogoV1,
  JanePayLogoWhite as JanePayLogoWhiteV1,
  MerrcoLogo as MerrcoLogoV1,
  PncLogo as PncLogoV1,
  PoweredByAeropayLogo as PoweredByAeropayLogoV1,
  SquareLogo as SquareLogoV1,
  UsaaLogo as UsaaLogoV1,
  WfLogo as WfLogoV1,
} from '@jane/shared/reefer-v1';
import {
  AeropayLogo as AeropayLogoV2,
  AeropayLogoWhite as AeropayLogoWhiteV2,
  BoaLogo as BoaLogoV2,
  CanpayLogo as CanpayLogoV2,
  CapitalOneLogo as CapitalOneLogoV2,
  ChaseLogo as ChaseLogoV2,
  CitiLogo as CitiLogoV2,
  JaneGoldLogoBig as JaneGoldLogoBigV2,
  JaneGoldLogo as JaneGoldLogoV2,
  JaneGoldShadowLogo as JaneGoldShadowLogoV2,
  JanePayLogo as JanePayLogoV2,
  JanePayLogoWhite as JanePayLogoWhiteV2,
  MerrcoLogo as MerrcoLogoV2,
  PncLogo as PncLogoV2,
  PoweredByAeropayLogo as PoweredByAeropayLogoV2,
  SquareLogo as SquareLogoV2,
  UsaaLogo as UsaaLogoV2,
  WfLogo as WfLogoV2,
} from '@jane/shared/reefer-v2';
import type { LogoProps } from '@jane/shared/reefer-v2';

export const AeropayLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AeropayLogoV2 {...props} />;
  }
  return <AeropayLogoV1 {...props} />;
};

export const AeropayLogoSecondary = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AeropayLogoSecondaryV1 {...props} />;
  }
  return <AeropayLogoSecondaryV1 {...props} />;
};

export const AeropayLogoWhite = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <AeropayLogoWhiteV2 {...props} />;
  }
  return <AeropayLogoWhiteV1 {...props} />;
};

export const BoaLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BoaLogoV2 {...props} />;
  }
  return <BoaLogoV1 {...props} />;
};

export const CanpayLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CanpayLogoV2 {...props} />;
  }
  return <CanpayLogoV1 {...props} />;
};

export const CapitalOneLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CapitalOneLogoV2 {...props} />;
  }
  return <CapitalOneLogoV1 {...props} />;
};

export const ChaseLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ChaseLogoV2 {...props} />;
  }
  return <ChaseLogoV1 {...props} />;
};

export const CitiLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CitiLogoV2 {...props} />;
  }
  return <CitiLogoV1 {...props} />;
};

export const JaneGoldLogoBig = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JaneGoldLogoBigV2 {...props} />;
  }
  return <JaneGoldLogoBigV1 {...props} />;
};

export const JaneGoldLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JaneGoldLogoV2 {...props} />;
  }
  return <JaneGoldLogoV1 {...props} />;
};

export const JanePayLogoWhite = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JanePayLogoWhiteV2 {...props} />;
  }
  return <JanePayLogoWhiteV1 {...props} />;
};

export const JaneGoldShadowLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JaneGoldShadowLogoV2 {...props} />;
  }
  return <JaneGoldShadowLogoV1 {...props} />;
};

export const JanePayLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JanePayLogoV2 {...props} />;
  }
  return <JanePayLogoV1 {...props} />;
};

export const MerrcoLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MerrcoLogoV2 {...props} />;
  }
  return <MerrcoLogoV1 {...props} />;
};

export const PncLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PncLogoV2 {...props} />;
  }
  return <PncLogoV1 {...props} />;
};

export const PoweredByAeropayLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PoweredByAeropayLogoV2 {...props} />;
  }
  return <PoweredByAeropayLogoV1 {...props} />;
};

export const SquareLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SquareLogoV2 {...props} />;
  }
  return <SquareLogoV1 {...props} />;
};

export const UsaaLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <UsaaLogoV2 {...props} />;
  }
  return <UsaaLogoV1 {...props} />;
};

export const WfLogo = (props: LogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <WfLogoV2 {...props} />;
  }
  return <WfLogoV1 {...props} />;
};

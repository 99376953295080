import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Skeleton as SkeletonV1 } from '@jane/shared/reefer-v1';
import { Skeleton as SkeletonV2 } from '@jane/shared/reefer-v2';
import type { SkeletonBoneProps, SkeletonProps } from '@jane/shared/reefer-v2';

export const BaseSkeleton = (props: SkeletonProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SkeletonV2 {...props} />;
  }
  return <SkeletonV1 {...props} />;
};

const SkeletonBone = (props: SkeletonBoneProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SkeletonV2.Bone {...props} />;
  }
  return <SkeletonV1.Bone {...props} />;
};

export const Skeleton = Object.assign(BaseSkeleton, {
  Bone: SkeletonBone,
});

import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import type { Store } from '@jane/shared/models';

export const JANE_DEFINED_ROWS = [
  'buy_again_row',
  'sale',
  'best_selling',
  'specials',
  'magic_row',
];

export const getUniqueCustomRow = (customRow: string, storeId?: Store['id']) =>
  storeId ? `${customRow}-${storeId}` : customRow;

export const formatUniqueCustomRow = (
  customRow: string,
  storeId?: Store['id']
) => (storeId && customRow ? customRow.replace(`-${storeId}`, '') : customRow);

const SORT_BY_PRICE_SUBSTRING = 'by-price';

export const isSortedByPrice = (
  searchState?: JaneSearchState<AlgoliaProduct>
) => {
  return Boolean(
    searchState?.currentSort?.suffix?.includes(SORT_BY_PRICE_SUBSTRING)
  );
};

export const REVIEWS_REQUIRED_BEFORE_DISPLAY = 5;
export const MIN_REVIEWS_QUERY = `review_count >= ${REVIEWS_REQUIRED_BEFORE_DISPLAY}`;

export const LINEAGES = ['indica', 'sativa', 'hybrid', 'cbd'];

export const makeSeeAllLinkSearchState = (
  names: string[],
  outerSearchState: JaneSearchState<AlgoliaProduct>,
  isAd?: boolean
): JaneSearchState<AlgoliaProduct> => {
  if (isAd) {
    return {
      ...outerSearchState,
      filters: {
        // sponsored rows have a single name
        brand: [names[0]],
      },
    };
  }

  const filters = { ...outerSearchState.filters } ?? {};
  const categories: string[] = [];
  const rootTypes: string[] = [];

  names.forEach((name) => {
    if (LINEAGES.includes(name) && !categories.includes(name)) {
      categories.push(name);
      if (!rootTypes.includes('flower')) {
        rootTypes.push('flower');
      }
    } else if (!rootTypes.includes(name)) {
      rootTypes.push(name);
    }
  });

  if (categories.length > 0) {
    filters['category'] = categories;
  }
  if (rootTypes.length > 0) {
    filters['root_types'] = rootTypes;
  }
  return { ...outerSearchState, filters };
};

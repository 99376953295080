import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type {
  ColumnDef,
  RowSelectionState,
  SortingState,
  Table as TanstackTable,
} from '@tanstack/react-table';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  useBulkUpdateSpecial,
  useFetchSpecials,
  useToggleSpecialEnabled,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useLoadNextPageOnScroll,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { SpecialsTableContext } from '@jane/business-admin/providers';
import type { AbbreviatedSpecialV2 } from '@jane/business-admin/types';
import { SpecialStatus } from '@jane/business-admin/types';
import { specialsSortParams } from '@jane/business-admin/util';
import { Box, Flex, Form, Link, Skeleton, useForm } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';
import { Button } from '@jane/shared/reefer-v2';
import type { Id } from '@jane/shared/types';

import { EmptyState } from './EmptyStates';
import { SpecialStatusFilter } from './SpecialStatusFilter';
import { SpecialTypeFilter } from './SpecialTypeFilter';
import { buildStoreSpecialsColumns } from './storeSpecialColumns';

const TableWrapper = styled.div({
  overflowX: 'auto',
  height: 'calc(100vh - 193px)',
});

const StyledTable = styled(Table)({
  overflowY: 'auto',
  // Avoids 1px of text showing up when user scrolls the table
  marginTop: '-1px',
});

const ROW_HEIGHT = '72';
const StyledRow = styled(Table.Row)(
  {
    'td:last-child': {
      overflow: 'visible',
    },
  },
  ({ useDefaultCursor }: { useDefaultCursor?: boolean }) => ({
    height: `${ROW_HEIGHT}px`,
    cursor: useDefaultCursor ? 'default' : 'pointer',
  })
);

const length10Array = Array(10)
  .fill(null)
  .map((_, index) => index);

const LoadingRow = ({
  index = 0,
  table,
}: {
  index?: number;
  table: TanstackTable<any>;
}) => (
  <StyledRow key={`${index}-row-loading`}>
    {table.getAllColumns().map((column) => (
      <Table.Cell key={`${column.id}-loading-cell`}>
        <Skeleton animate>
          <Skeleton.Bone />
        </Skeleton>
      </Table.Cell>
    ))}
  </StyledRow>
);

interface SpecialsTableProps {
  onEditSpecial?: (special: Partial<AbbreviatedSpecialV2>) => void;
  onEditStores?: (special: Partial<AbbreviatedSpecialV2>) => void;
  openModal: ({ id }: { id?: Id }) => void;
  setBulkEditModalOpen: () => void;
}

export const SpecialsTable = ({
  onEditSpecial,
  onEditStores,
  openModal,
  setBulkEditModalOpen,
}: SpecialsTableProps) => {
  const [searchParams] = useSearchParams();
  const { id: storeId = '' } = useParams<'id'>();
  const [statusFilter, setStatusFilter] = useState<SpecialStatus>(
    (searchParams.get('status') as SpecialStatus) || SpecialStatus.live
  );

  const {
    bulkEditModalOpen,
    setBulkSelectedSpecials,
    columnFilters,
    setColumnFilters,
  } = useContext(SpecialsTableContext);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedSpecials, setSelectedSpecials] = useState<RowSelectionState>(
    {}
  );
  const formMethods = useForm({
    defaultValues: {
      query: columnFilters.find(({ id }) => id === 'title')?.value || '',
    },
  });

  const filterParams = useMemo(() => {
    return Object.fromEntries(
      columnFilters
        .filter(({ id }) => ['query', 'title', 'special_type'].includes(id))
        .map(({ id, value }) => {
          if (id === 'title') {
            return ['query', value];
          } else if (typeof value === 'string') {
            return [id, value];
          }

          return [id, (value as string[]).join(',')];
        })
    ) as Record<string, string | undefined>;
  }, [JSON.stringify(columnFilters)]);

  const showResultCounts = useMemo(
    () =>
      !!Object.values(filterParams).find((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }

        return !!value;
      }),
    [JSON.stringify(filterParams)]
  );

  const loadNextRef = useRef(null);

  const {
    data,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useFetchSpecials({
    store_id: storeId,
    status: statusFilter,
    ...filterParams,
    ...specialsSortParams(sorting),
  });

  const {
    mutateAsync: toggleSpecialEnabled,
    isLoading: toggleEnabledLoading,
    isSuccess: toggleEnabledSuccess,
    isError: toggleEnabledError,
  } = useToggleSpecialEnabled(storeId);

  useMutationStatusToasts({
    isMutating: toggleEnabledLoading,
    isSuccess: toggleEnabledSuccess,
    isError: toggleEnabledError,
    successMessage: 'Special successfully toggled',
    errorMessage: 'Error toggling special',
  });

  // Could be from updating special or bulk
  const isMutating = useIsMutating();

  const isFetchingFirstPage = useMemo(
    () =>
      isMutating > 0 ||
      toggleEnabledLoading ||
      (isFetching && !isFetchingNextPage),
    [isMutating, isFetching, isFetchingNextPage, toggleEnabledLoading]
  );

  const specialData: AbbreviatedSpecialV2[] = useMemo(
    () => data?.pages?.flatMap((page) => page.specials) ?? [],
    [JSON.stringify(data)]
  );

  const countsByStatus: Record<SpecialStatus, number> | undefined = useMemo(
    () => data?.pages?.[0]?.meta?.counts_by_status,
    [JSON.stringify(data?.pages?.[0]?.meta?.counts_by_status)]
  );

  const allIds = useMemo(
    () =>
      (data?.pages?.[0]?.meta?.all_ids || []).reduce<RowSelectionState>(
        (previousValue, id) => ({
          ...previousValue,
          [id.toString()]: true,
        }),
        {}
      ),
    [JSON.stringify(data?.pages?.[0]?.meta?.all_ids)]
  );

  const {
    mutateAsync: archiveSpecial,
    isLoading: archiveSpecialLoading,
    isSuccess: archiveSpecialSuccess,
    isError: archiveSpecialError,
  } = useBulkUpdateSpecial(storeId);

  useMutationStatusToasts({
    isMutating: archiveSpecialLoading,
    isSuccess: archiveSpecialSuccess,
    isError: archiveSpecialError,
    successMessage: 'Special successfully archived',
    errorMessage: 'Error archiving special',
  });

  const catchSubmitErrors = useCatchErrorsWithManager('');

  const onArchiveSpecial = (id: number) => {
    const params = {
      special_ids: [id],
      archive: true,
    };
    catchSubmitErrors({
      submitMethod: () => archiveSpecial(params),
      requestData: params,
      // Error handled by toasts
      callback: () => null,
      onValidationError: () => null,
    });
  };

  const hasArchivedFilter = useMemo(
    () => statusFilter === SpecialStatus.archived,
    [statusFilter]
  );
  const hasUpcomingFilter = useMemo(
    () => statusFilter === SpecialStatus.upcoming,
    [statusFilter]
  );

  const totalRowCount = data?.pages?.[0]?.meta?.total ?? 0;

  const columns: ColumnDef<AbbreviatedSpecialV2>[] = useMemo(
    () =>
      buildStoreSpecialsColumns({
        hasArchivedFilter,
        isFetched,
        sorting,
        toggleSpecialEnabled,
        totalRowCount,
        onArchiveSpecial,
        bulkEditModalOpen,
      }),
    [isFetched, hasArchivedFilter, sorting, totalRowCount, bulkEditModalOpen]
  );

  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({
    archived_at: false,
    location_count: false,
    next_occurrenct: false,
  });

  const table = useReactTable({
    data: specialData,
    columns,
    state: {
      columnFilters,
      columnVisibility,
      sorting,
      rowSelection: selectedSpecials,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onRowSelectionChange: setSelectedSpecials,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: true,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString() || '0',
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
  });

  const currentSelections = table.getState().rowSelection;

  useEffect(() => {
    setBulkSelectedSpecials(
      Object.keys(currentSelections).map((special_id) => Number(special_id))
    );
  }, [currentSelections]);

  const titleColumn = useMemo(() => table?.getColumn('title'), [table]);

  const specialTypeColumn = useMemo(
    () => table?.getColumn('special_type'),
    [table]
  );

  useLoadNextPageOnScroll(
    {
      fetchNextPage,
      triggerRef: loadNextRef,
      rootMargin: '300px',
    },
    [data]
  );

  // Hide start/end columns and display 'archived on' column when status is "archived"
  // Display 'starts in' column with countdown on Upcoming tab
  useEffect(() => {
    setColumnVisibility({
      archived_at: hasArchivedFilter,
      start_date: !hasArchivedFilter,
      end_date: !hasArchivedFilter,
      next_occurrence: hasUpcomingFilter,
    });
  }, [hasArchivedFilter, hasUpcomingFilter, table]);

  return (
    <Box width="100%" mt={bulkEditModalOpen ? 40 : 0}>
      <Form.BaseForm
        name="specials"
        formMethods={formMethods}
        onSubmit={() => null}
      >
        <Flex justifyContent="space-between" mb={24} mx={64}>
          <Flex minWidth="320px" gap={16}>
            <Form.SearchField
              onChange={(value) => {
                titleColumn?.setFilterValue(value);
              }}
              placeholder="Search specials & promo codes"
              label="Search specials & promo codes"
              labelHidden
              name="query"
              isDebounced
              debounceDelay={300}
              width={325}
            />
            <SpecialTypeFilter column={specialTypeColumn} />
          </Flex>
          <Flex justifyContent="flex-end" gap={16}>
            {bulkEditModalOpen ? (
              <>
                <Link onClick={() => table.setRowSelection(allIds)}>
                  Select all
                </Link>
                <Link onClick={() => table.setRowSelection({})}>
                  Select none
                </Link>
              </>
            ) : (
              <>
                {searchParams.get('status') !== 'archived' && (
                  <Button
                    variant="secondary"
                    label="Bulk edit"
                    onClick={() => setBulkEditModalOpen()}
                  />
                )}
                <Button
                  variant="primary"
                  label="Create special"
                  onClick={() => openModal({})}
                />
              </>
            )}
          </Flex>
        </Flex>
        {!bulkEditModalOpen && (
          <SpecialStatusFilter
            isFetching={isFetching}
            value={statusFilter}
            onChange={setStatusFilter}
            showResultCounts={showResultCounts}
            countsByStatus={countsByStatus}
          />
        )}
        <TableWrapper>
          {!isFetchingFirstPage && Object.keys(allIds).length === 0 ? (
            <EmptyState />
          ) : (
            <StyledTable freezeFirstColumn scrollable>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeaderCell key={header.id}>
                        {header.isPlaceholder ? null : (
                          <Box
                            minWidth={
                              header.column.columnDef.id === 'title'
                                ? '400px'
                                : '100px'
                            }
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                        )}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>

              <Table.Body>
                {isFetchingFirstPage
                  ? length10Array.map((n) => (
                      <LoadingRow key={n} index={0} table={table} />
                    ))
                  : table.getRowModel().rows.map((row) => (
                      <StyledRow
                        key={row.id}
                        useDefaultCursor={false}
                        onClick={(event: React.BaseSyntheticEvent) => {
                          if (bulkEditModalOpen) {
                            row.toggleSelected();
                          } else {
                            // HACK: Prevent the edit modal from opening if the click event came from the context
                            // menu cell's popover target button. Working with Reefer team to find a better solution
                            if (event.target.parentNode.role === 'button') {
                              return;
                            }
                            onEditSpecial && onEditSpecial(row.original);
                          }
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        ))}
                      </StyledRow>
                    ))}
              </Table.Body>
              {isFetched && isSuccess ? (
                <>
                  {hasNextPage && (
                    <Table.Body>
                      <LoadingRow table={table} />
                    </Table.Body>
                  )}
                  <tfoot ref={loadNextRef}></tfoot>
                </>
              ) : (
                <></>
              )}
            </StyledTable>
          )}
        </TableWrapper>
      </Form.BaseForm>
    </Box>
  );
};

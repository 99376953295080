import { useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  BODY_LINE_HEIGHT,
  MODAL_CARD_WIDTH,
  VALIDATION_OPTIONS,
} from '@jane/business-admin/util';
import { ImageEditor } from '@jane/shared-b2b/components';
import { validateImageFile } from '@jane/shared-b2b/util';
import {
  Banner,
  Card,
  Flex,
  InfoIcon,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { ImagePreview } from '@jane/shared/util';

import { BannerText, ErrorBanner } from '../../../../shared/images/ErrorBanner';

export const BANNER_TEXT = 'One image allowed. Image cannot exceed 10MB.';

interface Props {
  isLoading: boolean;
}

const LoadingImages = () => {
  return (
    <Skeleton animate width="25%" key={`loading_special_image`}>
      <Skeleton.Bone height={'214px'} my={16} width="100%" borderRadius="xs" />
    </Skeleton>
  );
};

export const ImagesCard = ({ isLoading }: Props) => {
  const [uploadCount, setUploadCount] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { setError, trigger } = useFormContext();

  const makeOnFilesAdded = (onChange: (newState: string[]) => void) => {
    return async (fileList: FileList | null) => {
      setErrorMessages([]);

      if (fileList) {
        setUploadCount(fileList.length);

        // if multiple photos are selected, upload the first one
        const file = Array.from(fileList)[0];

        // add VALIDATION_OPTIONS as second param if you want any image validations run
        const error = await validateImageFile(file, VALIDATION_OPTIONS);
        if (!error) {
          try {
            const image = await ImagePreview.parse(file);

            setUploadCount((count: number) => count - 1);
            // ImageEditor expects an array of url strings
            onChange([image.url]);
          } catch (error) {
            setError('special-image-upload-error', {
              message: 'Error uploading special image',
            });
            setUploadCount((count: number) => count - 1);

            setErrorMessages((oldState: string[]) => [
              ...oldState,
              `${file.name} - Encountered an error while uploading, please try again.`,
            ]);
            // manually force form validation since this isn't a <Form> component
            trigger('photo');
          }
        } else {
          setError('special-image-upload-error', {
            message: 'Error uploading special image',
          });
          // update error messages
          const { filename, errors } = error;
          const messages = errors.map((e) => `${filename} - ${e.message}`);

          setErrorMessages((oldState: string[]) => [...oldState, ...messages]);
          setUploadCount((count: number) => count - 1);

          trigger('photo');
        }
      }
    };
  };

  const getSpecialImages = ({ value, onChange }: any) => {
    return (
      <ImageEditor
        images={value}
        onChange={onChange}
        isUploading={!!errorMessages.length && uploadCount > 1}
        onFilesAdded={makeOnFilesAdded(onChange)}
        showUploadGridItem={!value.length}
      />
    );
  };
  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <>
            <Flex data-testid="special-image-header-text">
              <Typography variant="header-bold" mb={40}>
                Image
              </Typography>
              <Typography ml={4} variant="header" color="grays-mid">
                (Optional)
              </Typography>
            </Flex>
            {isLoading ? (
              <Skeleton animate>
                <Skeleton.Bone width="30%" height={BODY_LINE_HEIGHT} />
              </Skeleton>
            ) : (
              <>
                {errorMessages.length > 0 ? (
                  <Flex mb={24}>
                    <ErrorBanner
                      closeBanner={() => setErrorMessages([])}
                      messages={errorMessages}
                    />
                  </Flex>
                ) : null}
                <Controller
                  defaultValue={[]}
                  name="photo"
                  render={({ field: { onChange, value } }) => {
                    return isLoading ? (
                      <Flex
                        justifyContent={'space-around'}
                        width={'calc(100% + 3%)'}
                      >
                        <LoadingImages />
                      </Flex>
                    ) : (
                      getSpecialImages({
                        onChange,
                        value,
                      })
                    );
                  }}
                />
                <Flex
                  background="grays-ultralight"
                  borderRadius="sm"
                  mt={24}
                  p={8}
                >
                  <Banner
                    icon={<InfoIcon />}
                    typography="body"
                    variant="info"
                    label={<BannerText>{BANNER_TEXT}</BannerText>}
                    full
                  />
                </Flex>
              </>
            )}
          </>
        </Flex>
      </Card.Content>
    </Card>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Edit24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1213 3.70705C17.9498 2.53547 16.0503 2.53548 14.8787 3.70705L4.62677 13.959C4.29745 14.2883 4.04932 14.6898 3.90204 15.1316L2.05134 20.6837C1.93157 21.043 2.02509 21.4392 2.29292 21.7071C2.56075 21.9749 2.95692 22.0684 3.31625 21.9486L8.86835 20.0979C9.31018 19.9506 9.71166 19.7025 10.041 19.3732L20.2929 9.12126C21.4645 7.94969 21.4645 6.05019 20.2929 4.87862L19.1213 3.70705ZM16.2929 5.12126C16.6834 4.73074 17.3166 4.73074 17.7071 5.12126L18.8787 6.29284C19.2692 6.68336 19.2692 7.31652 18.8787 7.70705L9.50001 17.0857L6.91423 14.5L16.2929 5.12126ZM5.68704 16.1012L4.58117 19.4188L7.89878 18.3129L5.68704 16.1012Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Edit24,
  sm: Edit24,
  lg: Edit24,
  xl: Edit24,
  xxl: Edit24,
  xxxl: Edit24,
};

export const EditIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'edit-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uy3HF0zEa7_r1jtniqQR {\n  position: relative;\n}\n\n.VDPb3w7pKw8Tet0Dppii {\n  background: transparent;\n  height: auto;\n  position: absolute;\n  width: 100%;\n  z-index: 10;\n}\n\n.YnOYW1I9PsY_MYwzbng_ {\n  background-color: var(--colors-grays-white);\n  border-radius: var(--border-radius-sm);\n  box-shadow: var(--shadows-hard);\n  overflow-y: hidden;\n  position: relative;\n  margin-top: 12px;\n}\n\n.qO_s6sGU4tcxJXzJpLTJ {\n  height: 100%;\n  max-height: 40vh;\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/forms/fields/textField/autocomplete/textFieldAutocomplete.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,2CAA2C;EAC3C,sCAAsC;EACtC,+BAA+B;EAC/B,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".textFieldAutocomplete_container {\n  position: relative;\n}\n\n.textFieldAutocomplete_alignment {\n  background: transparent;\n  height: auto;\n  position: absolute;\n  width: 100%;\n  z-index: 10;\n}\n\n.textFieldAutocomplete_options {\n  background-color: var(--colors-grays-white);\n  border-radius: var(--border-radius-sm);\n  box-shadow: var(--shadows-hard);\n  overflow-y: hidden;\n  position: relative;\n  margin-top: 12px;\n}\n\n.textFieldAutocomplete_list {\n  height: 100%;\n  max-height: 40vh;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textFieldAutocomplete_container": "Uy3HF0zEa7_r1jtniqQR",
	"textFieldAutocomplete_alignment": "VDPb3w7pKw8Tet0Dppii",
	"textFieldAutocomplete_options": "YnOYW1I9PsY_MYwzbng_",
	"textFieldAutocomplete_list": "qO_s6sGU4tcxJXzJpLTJ"
};
export default ___CSS_LOADER_EXPORT___;

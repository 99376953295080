import styled from '@emotion/styled';

import checkSmall from '../../../../assets/icons/dynamic/check-16.svg';
import dash from '../../../../assets/icons/dynamic/subtract-16.svg';
import { shadows } from '../../../../styling';

export const StyledCheckboxContainer = styled.div({
  height: '24px',
  position: 'relative',
});

export const StyledInput = styled.input(
  {
    '&:indeterminate, &:checked': {
      backgroundColor: 'transparent',
    },
    appearance: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    height: '24px',
    outline: 'none',
    position: 'relative',
    width: '24px',
    zIndex: '1',
  },
  ({ theme: { colors } }) => ({
    '&:disabled': {
      cursor: 'default',
    },
    '&:focus-visible': {
      '&:after': {
        border: `1px solid ${colors.primary.main}`,
        bottom: '-3px',
        backgroundColor: 'transparent',
        content: `''`,
        borderRadius: '7px',
        display: 'inherit',
        left: '-3px',
        position: 'absolute',
        right: '-3px',
        top: '-3px',
      },
    },
  })
);

export const StyledCheckbox = styled.div(
  {
    ':after': {
      content: '""',
      display: 'block',
      height: '100%',
      'input:checked + &': {
        backgroundImage: `url(${checkSmall})`,
      },
      'input:checked + &, input:indeterminate + &': {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: `brightness(100)`,
      },
      position: 'absolute',
      'input:indeterminate + &': {
        backgroundImage: `url(${dash})`,
      },
      top: '0',
      width: '100%',
    },
    borderRadius: '4px',
    height: '24px',
    'input:checked + &, input:indeterminate + &': {
      border: 'none',
      boxShadow: 'none',
    },
    position: 'absolute',
    top: '0',
    width: '24px',
  },
  ({ theme: { colors } }) => ({
    backgroundColor: colors.grays.white,
    border: `2px solid ${colors.grays.mid}`,
    boxShadow: shadows.inner,
    'input:checked + &, input:indeterminate + &': {
      backgroundColor: colors.primary.main,
    },
  })
);

import type { Config } from '../type';
import { getApiPath } from './util';

export const config = {
  actionCablePath: process.env['NX_ACTIONCABLE_URL'],
  aeropayEnv: process.env['NX_AEROPAY_ENV'],
  aeropayConsumerId: process.env['NX_AEROPAY_CONSUMER_ID'],
  algoliaApiKey: process.env['NX_ALGOLIA_SEARCH_API_KEY'],
  algoliaAppId: process.env['NX_ALGOLIA_APP_ID'],
  algoliaEnv: process.env['NX_ALGOLIA_ENV'],
  algoliaUrl: process.env['NX_ALGOLIA_URL'],
  apiPath: getApiPath(process.env['NX_API_URL']),
  apiPathBase: getApiPath(process.env['NX_API_URL_BASE']),
  apiPathV2: getApiPath(process.env['NX_API_URL_V2']),
  appVersion: process.env['NX_APP_VERSION'],
  appleClientId: process.env['NX_APPLE_CLIENT_ID'],
  branchIoKey: process.env['NX_BRANCH_IO_KEY'],
  brandServerUrl: process.env['NX_BRAND_SERVER_URL'],
  brazeAPIKey: process.env['NX_BRAZE_API_KEY'],
  buildVersion: process.env['NX_BUILD_VERSION'],
  businessUrl: process.env['NX_BUSINESS_URL'],
  canPayV2RemotePaySDK: process.env['NX_CANPAYV2RP_JS_SDK_URL'],
  cartLimitDuplicateRuleUserIds:
    process.env['NX_CART_LIMIT_DUPLICATE_RULE_USER_IDS'],
  deployPublicPath: process.env['NX_DEPLOY_PUBLIC_PATH'],
  deployPublicPathWithoutUriScheme:
    process.env['NX_DEPLOY_PUBLIC_PATH_WITHOUT_URI_SCHEME'],
  dev: process.env['NX_DEV_MODE'],
  disableRecaptcha: Boolean(process.env['NX_DISABLE_RECAPTCHA']),
  dmSdkApiKey: process.env['NX_DM_SDK_API_KEY'],
  dmEndpoint: process.env['NX_DM_ENDPOINT'],
  dmMixpanelToken: process.env['NX_DM_MIXPANEL_CUSTOMER_TOKEN'],
  dmServiceToken: process.env['NX_DM_SERVICE_TOKEN'],
  dmUrl: process.env['NX_DM_URL'],
  facebookAppId: process.env['NX_FACEBOOK_APP_ID'],
  fakeAdStoreIds: process.env['NX_FAKE_AD_STORE_IDS'],
  features: process.env['NX_FEATURES'],
  googleAnalyticsBusinessTrackingId: '',
  googleAnalyticsCustomerTrackingId: '',
  googleClientId: process.env['NX_GOOGLE_CLIENT_ID'],
  googlePlacesKey: process.env['NX_GOOGLE_PLACES_API_KEY'],
  janeSupportManagerIds: process.env['NX_JANE_SUPPORT_MANAGER_IDS'],
  kioskRefreshTimeout: process.env['NX_KIOSK_REFRESH_TIMEOUT'],
  kioskTimeout: 30000,
  kioskV2Url: process.env['NX_KIOSK_V2_URL'],
  kioskMixpanelCustomerToken: process.env['NX_KIOSK_MIXPANEL_CUSTOMER_TOKEN'],
  logMixpanelToConsole: process.env['NX_LOG_MIXPANEL_TO_CONSOLE'],
  mapboxAccessToken: process.env['NX_MAPBOX_ACCESS_TOKEN'],
  mixpanelUserSessionApiHost: process.env['NX_MIXPANEL_USER_SESSION_API_HOST'],
  monerisEnv: process.env['NX_MONERIS_ENVIRONMENT'],
  monerisJsSdkUrl: process.env['NX_MONERIS_JS_SDK_URL'],
  nodeEnv: process.env['NODE_ENV'],
  recaptchaKey: process.env['NX_RECAPTCHA_KEY'],
  retailTimeout: process.env['NX_RETAIL_TIMEOUT'],
  rootsOnlyCustomRowStoreIds: process.env['NX_ROOTS_ONLY_CUSTOM_ROW_STORE_IDS'],
  roundingBetaStoreIds: process.env['NX_ROUNDING_BETA_STORE_IDS'],
  squareApplicationId: process.env['NX_SQUARE_APPLICATION_ID'],
  squareWebPaymentsSdkUrl: process.env['NX_SQUARE_WEB_PAYMENTS_SDK_URL'],
  storageKey: 'user_session',
  streamChatApiToken: process.env['NX_STREAM_CHAT_API_KEY'],
  strongholdEnv: process.env['NX_STRONGHOLD_ENVIRONMENT'],
  strongholdIntegrationId: process.env['NX_STRONGHOLD_INTEGRATION_ID'],
  test: process.env['NX_TEST_MODE'],
  trackJsApplication: process.env['NX_TRACKJS_APPLICATION'],
  trackJsDomain: process.env['NX_TRACKJS_DOMAIN'],
  trackJsToken: process.env['NX_TRACKJS_TOKEN'],
} as unknown as Config;

import type { MarginProperties } from '../../../../styling';
import type { ReeferBaseProps } from '../../../../types';
import type { FieldProps } from '../field.types';
import { StyledHelperText } from './helperText.styles';

export interface HelperTextProps
  extends MarginProperties,
    ReeferBaseProps,
    Pick<FieldProps, 'helperText'> {
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
}

export const HelperText = ({
  children,
  className,
  'data-testid': testId,
  id,
  disabled,
  ...marginProps
}: HelperTextProps) => {
  if (!children) {
    return null;
  }
  return (
    <StyledHelperText
      className={className}
      color="text-light"
      data-testid={testId}
      disabled={disabled}
      id={`${id}-helper-text`}
      {...marginProps}
    >
      {children}
    </StyledHelperText>
  );
};

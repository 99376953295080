import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { List as ListV1 } from '@jane/shared/reefer-v1';
import { List as ListV2 } from '@jane/shared/reefer-v2';
import type { ListItemProps, ListProps } from '@jane/shared/reefer-v2';

const BaseList = (props: ListProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ListV2 {...props} />;
  }
  return <ListV1 {...props} />;
};

const ListItem = (props: ListItemProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ListV2.Item {...props} />;
  }
  return <ListV1.Item {...props} />;
};

export const List = Object.assign(BaseList, {
  Item: ListItem,
});

import clsx from 'clsx';
import type { CSSProperties } from 'react';

import { CheckboxField, SortUpIcon } from '@jane/shared/reefer-v2';

import type { TableHeaderCellProps } from '../table.types';
import styles from './tableHeaderCell.module.css';

export function TableHeaderCell({
  checkbox,
  children,
  className,
  'data-testid': testId,
  height = '72px',
  id,
  sort,
  style,
  width = 'auto',
}: TableHeaderCellProps) {
  return (
    <th
      className={clsx(className, styles.tableHeaderCell, {
        [styles.tableHeaderCell__sorted]: sort?.active,
        [styles.tableHeaderCell__clickable]: sort && sort.key,
      })}
      data-testid={testId}
      id={id}
      style={
        {
          '--tableHeaderCell-height': height,
          '--tableHeaderCell-width': width,
          ...style,
        } as CSSProperties
      }
      {...(checkbox && { 'data-id': 'checkbox-cell' })}
      {...(sort && {
        onClick: () =>
          sort.onClick(
            sort.key,
            sort.active && sort.direction === 'asc' ? 'desc' : 'asc'
          ),
      })}
    >
      {checkbox && (
        <CheckboxField
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          label=""
          name="cell-checkbox"
          {...(checkbox.indeterminate !== undefined && {
            indeterminate: checkbox.indeterminate,
          })}
        />
      )}
      {!checkbox && (
        <div
          className={clsx(styles.tableHeaderCell_label, {
            [styles.tableHeaderCell_label__sorted]: sort?.active,
          })}
        >
          {children}
          {sort && (
            <SortUpIcon
              color={sort.active ? 'purple-dark' : 'purple-light'}
              rotate={sort.active && sort.direction === 'asc' ? 'up' : 'down'}
              altText={`sort-by`}
            />
          )}
        </div>
      )}
    </th>
  );
}

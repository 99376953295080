import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import '../../styling/base.css';
import '../../styling/fonts.css';
import { BASE_CONFIG as defaultTheme } from '../../theme';
import { generateTheme } from '../../theme/generateTheme';
import { loadCustomFonts } from '../../theme/loadFonts';
import type { CustomThemeConfig, ReeferTheme } from '../../types';

export type RenderProviderProp = (
  theme: ReeferTheme,
  children: ReactNode
) => any;

export interface ReeferThemeProviderProps extends PropsWithChildren {
  renderProvider?: RenderProviderProp;

  /** Supply a theme that will override the default Reefer theme */
  theme?: CustomThemeConfig;
}

/**
 * The `ReeferThemeProvider` component is used to provide theme configuration
 * to its child components.
 */
export function ReeferThemeProvider({
  children,
  renderProvider,
  theme,
}: ReeferThemeProviderProps) {
  const [finalTheme, setFinalTheme] = useState<ReeferTheme>();

  useEffect(() => {
    let isSubscribed = true;
    if (theme) {
      const loadFontsAndSetTheme = async () => {
        const customTheme = generateTheme(theme);
        if (theme.components?.Typography) {
          try {
            await loadCustomFonts(theme.components.Typography);
          } catch (e: unknown) {
            // If we get an error while trying to load fonts, fall back
            // to default Reefer typography
            // eslint-disable-next-line no-console
            console.log((e as Error).message);
            customTheme.components.Typography =
              defaultTheme.components.Typography;
          }
        } else {
          customTheme.components.Typography =
            defaultTheme.components.Typography;
        }
        if (isSubscribed) {
          setFinalTheme(customTheme);
        }
      };
      loadFontsAndSetTheme();
    } else {
      if (isSubscribed) {
        setFinalTheme(defaultTheme);
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [theme]);

  /**
   * Don't render until we have the final theme.
   * This will prevent flashing of the default theme before showing
   * a custom theme.
   */
  if (!finalTheme) {
    return null;
  }

  if (!renderProvider) return children;

  return renderProvider(finalTheme, children);
}

import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { spacingCSSVars } from '../../../utils';
import { Box } from '../../box';
import type { ListItemProps } from '../list.types';
import styles from './listItem.module.css';

export const ListItem = ({
  alignment = 'left',
  children,
  className,
  'data-testid': testId,
  disableHover = false,
  href,
  id,
  onClick,
  onMouseEnter,
  px = 8,
  py = 12,
  selected,
  to,
  style,
  ...spacingProps
}: ListItemProps) => {
  return (
    <li
      className={clsx(styles.listItem, {
        [styles[`listItem__align_${alignment}`]]: !!alignment,
        [styles.listItem__clickable]: !!onClick || !!href || !!to,
        [styles.listItem__disableHover]: disableHover,
        [styles.listItem__selected]: selected,
        ...(className ? { [className]: !!className } : null),
      })}
      data-testid={testId}
      id={id}
      style={style}
    >
      {!href && !to && (
        <Box
          ariaLabel="list-item"
          onClick={onClick}
          height="100%"
          width="100%"
          px={px}
          py={py}
          {...spacingProps}
        >
          {children}
        </Box>
      )}

      {!!href && (
        <a
          className={clsx(styles.listItem_link)}
          href={href}
          onClick={onClick}
          rel="noopener noreferrer"
          style={{
            ...spacingCSSVars({ px, py, ...spacingProps }),
          }}
          target="_blank"
        >
          {children}
        </a>
      )}

      {!!to && (
        <RouterLink
          className={clsx(styles.listItem_link)}
          to={to}
          onClick={onClick}
          style={{
            ...spacingCSSVars({ px, py, ...spacingProps }),
          }}
          tab-index={0}
        >
          {children}
        </RouterLink>
      )}
    </li>
  );
};

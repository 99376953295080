// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H4h2ixibCikMwRdFucuR {\n  border: none;\n  height: 1px;\n  background-color: var(--colors-grays-light);\n  margin: 24px -24px;\n\n  @media (min-width: 768px) {\n    margin: 16px -16px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/popover/popoverDivider/popoverDivider.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,2CAA2C;EAC3C,kBAAkB;;EAElB;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".popover_divider {\n  border: none;\n  height: 1px;\n  background-color: var(--colors-grays-light);\n  margin: 24px -24px;\n\n  @media (min-width: 768px) {\n    margin: 16px -16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover_divider": "H4h2ixibCikMwRdFucuR"
};
export default ___CSS_LOADER_EXPORT___;

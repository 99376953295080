import * as t from 'io-ts';

import {
  tBrandCondition,
  tKindCondition,
  tProductThresholdConditions,
  tWeightCondition,
} from '@jane/shared/models';

import { tId } from './common';

type ValueOf<T> = T[keyof T];

export const SpecialStatus = {
  live: 'live',
  upcoming: 'upcoming',
  expired: 'expired',
  disabled: 'disabled',
  archived: 'archived',
} as const;

export type SpecialStatus = ValueOf<typeof SpecialStatus>;

const tAbbreviatedSpecialV2Optional = t.partial({
  archived: t.boolean,
  archived_at: t.union([t.string, t.null]),
  description: t.union([t.string, t.null]),
  promo_code: t.union([t.string, t.null]),
  enabled: t.boolean,
  promo_code_max_number_of_uses: t.union([t.number, t.null]),
  multiple_use_promo_code: t.union([t.boolean, t.null]),
  reservation_modes: t.union([
    t.partial({
      kiosk: t.union([t.boolean, t.undefined]),
      delivery: t.union([t.boolean, t.undefined]),
      pickup: t.union([t.boolean, t.undefined]),
      curbside: t.union([t.boolean, t.undefined]),
    }),
    t.undefined,
  ]),
  terms: t.union([t.string, t.null]),
  pos_synced: t.boolean,
  pos_source: t.union([t.string, t.null]),
  pos_special_id: t.union([t.string, t.null]),
  pos_special_link: t.union([t.string, t.null]),
  discount_dollar_amount: t.number,
  discount_percent: t.number,
  discount_target_price: t.number,
  store_ids: t.array(tId),
  next_occurrence: t.union([t.number, t.null, t.string]),
});

const tAbbreviatedSpecialV2Required = t.interface({
  id: tId,
  title: t.string,
  discount_type: t.string,
  start_time: t.union([t.string, t.null]),
  end_time: t.union([t.string, t.null]),
  special_type: t.keyof({
    product: null,
    cart_total: null,
    qualified_group: null,
    bundle: null,
    bulk_pricing: null,
  }),
});

export const tAbbreviatedSpecialV2 = t.intersection([
  tAbbreviatedSpecialV2Optional,
  tAbbreviatedSpecialV2Required,
]);

const tSpecialsResponseMeta = t.interface({
  page: t.number,
  page_total: t.number,
  per_page: t.number,
  total: t.number,
  all_ids: t.array(t.number),
  counts_by_status: t.interface({
    live: t.number,
    upcoming: t.number,
    expired: t.number,
    disabled: t.number,
    archived: t.number,
  }),
});

export const tSpecialsIndexResponse = t.interface({
  specials: t.array(tAbbreviatedSpecialV2),
  meta: tSpecialsResponseMeta,
});

export const tSpecialRuleItemV2 = t.partial({
  brands: t.union([t.array(tBrandCondition), t.undefined]),
  product_ids: t.union([t.array(t.number), t.undefined]),
  kinds: t.union([t.array(tKindCondition), t.undefined]),
  lineages: t.union([t.array(t.string), t.undefined]),
  weights: t.union([t.array(tWeightCondition), t.undefined]),
});

// NOTE: There is a tSpecialRules in shared/models but that structure is not right
export const tSpecialRulesV2 = t.partial({
  includes: t.union([t.array(tSpecialRuleItemV2), t.undefined]),
  excludes: t.union([t.array(tSpecialRuleItemV2), t.undefined]),
  product_threshold: t.union([tProductThresholdConditions, t.undefined]),
});

export type AbbreviatedSpecialV2 = t.TypeOf<typeof tAbbreviatedSpecialV2>;
export type SpecialsIndexResponse = t.TypeOf<typeof tSpecialsIndexResponse>;
export type SpecialRuleItemV2 = t.TypeOf<typeof tSpecialRuleItemV2>;
export type SpecialRulesV2 = t.TypeOf<typeof tSpecialRulesV2>;

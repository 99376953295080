import styled from '@emotion/styled';
import ReactModal from 'react-modal';

import { mediaQueries } from '../../styling';
import {
  getColorValue,
  textColorForBackgroundColor,
} from '../../utils/componentStyles';
import type { ModalStyleProps } from './modal.types';

function shadowAppSelector(): HTMLElement {
  const shadowHost = document.getElementById('shadow-host')?.shadowRoot;
  let shadowApp = shadowHost?.getElementById('shadow-app-sibling');
  shadowApp = shadowApp || document.body;
  return shadowApp;
}

// assigns new className to wrap the modal and define styles
export function ReactModalAdapter({
  className,
  open,
  parentSelector,
  style,
  ...props
}: ModalStyleProps) {
  parentSelector = parentSelector || shadowAppSelector;

  return (
    <ReactModal
      portalClassName={className}
      className={className}
      overlayClassName={className}
      closeTimeoutMS={200}
      isOpen={open}
      parentSelector={parentSelector}
      style={{}} // Don't pass style to ReactModal, these are different types
      {...props}
    />
  );
}

/** Sets width/height of the standard modal */
const STANDARD_SIZE = '896px';

/** Sets max-height/width and margins (24px on mobile, 64px on desktop) */
const MOBILE_MAX_SIZE = 'calc(100% - 48px)';
const DESKTOP_MAX_SIZE = 'calc(100% - 128px)';

export const MODAL_VARIANTS = {
  dialogue: {
    height: 'auto',
    maxHeight: MOBILE_MAX_SIZE,
    maxWidth: MOBILE_MAX_SIZE,
    width: 'auto',
    [mediaQueries.desktop('sm', 'min')]: {
      maxHeight: DESKTOP_MAX_SIZE,
      maxWidth: DESKTOP_MAX_SIZE,
    },
  },
  flex: {
    height: MOBILE_MAX_SIZE,
    width: MOBILE_MAX_SIZE,
    [mediaQueries.desktop('sm', 'min')]: {
      height: DESKTOP_MAX_SIZE,
      width: DESKTOP_MAX_SIZE,
    },
  },
  'full-screen': {
    height: '100%',
    width: '100%',
  },
  standard: {
    height: STANDARD_SIZE,
    maxHeight: MOBILE_MAX_SIZE,
    maxWidth: MOBILE_MAX_SIZE,
    width: STANDARD_SIZE,
    [mediaQueries.desktop('sm', 'min')]: {
      maxHeight: DESKTOP_MAX_SIZE,
      maxWidth: STANDARD_SIZE,
    },
  },
  'standard-dialogue': {
    height: 'auto',
    maxHeight: MOBILE_MAX_SIZE,
    maxWidth: MOBILE_MAX_SIZE,
    width: STANDARD_SIZE,
    [mediaQueries.desktop('sm', 'min')]: {
      maxHeight: DESKTOP_MAX_SIZE,
      maxWidth: STANDARD_SIZE,
    },
  },
};

export const StyledModal = styled(ReactModalAdapter)(
  ({ theme, background, topOverride, top, maxHeight, variant, overflow }) => {
    return {
      '.ReactModal__Content': {
        WebkitOverflowScrolling: 'touch',
        background: getColorValue(background, theme),
        borderRadius: variant === 'full-screen' ? 0 : theme.borderRadius.lg,
        color: textColorForBackgroundColor(background, theme),
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        left: '50%',
        minWidth: '300px',
        overflow,
        position: 'absolute',
        top: top,
        transform: 'translate(-50%, -50%)',
        ...(topOverride && {
          top: topOverride,
          transform: 'translateX(-50%)',
        }),
        ...(variant && MODAL_VARIANTS[variant]),
        ...(maxHeight && { maxHeight: maxHeight }),
        ':focus': {
          outline: 'none',
        },
        ':focus-visible': {
          outline: 'none',
        },
      },
      '.ReactModal__Overlay': {
        background: 'rgba(0,0,0,0.4)',
        bottom: '0px',
        left: '0px',
        opacity: 0,
        position: 'fixed',
        right: '0px',
        top: '0px',
        transition: 'opacity 200ms ease',
        zIndex: '99999999',
      },
      '.ReactModal__Overlay--after-open': {
        opacity: 1,
      },
      '.ReactModal__Overlay--before-close': {
        opacity: 0,
      },
    };
  }
);

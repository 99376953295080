import { getAccessibilityProps } from '../../internal/utils';
import { fakeButtonProps, handleEnterKey } from '../../utils';
import { StyledCard } from './card.styles';
import type { CardProps } from './card.types';

/**
 * The `Card` component is a wrapper component used for displaying a styled card with an optional `border` and `box-shadow`.
 * `Card` contents can be included via sub-components `Card.Action`, `Card.Content` and `Card.Image`.
 * */

export function Card({
  ariaLabel,
  ariaLabelledBy,
  border = 'none',
  borderWidth = '1px',
  children,
  className,
  'data-testid': testId,
  flat = false,
  height,
  id,
  onClick,
  style,
  role,
  width,
  ...props
}: CardProps) {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, onClick, role },
    'Card'
  );

  return (
    <StyledCard
      border={border}
      borderWidth={borderWidth}
      data-testid={testId}
      className={className}
      flat={flat}
      height={height}
      id={id}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      style={style}
      width={width}
      {...a11yProps}
      {...props}
    >
      {children}
    </StyledCard>
  );
}

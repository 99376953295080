import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Banner as BannerV1 } from '@jane/shared/reefer-v1';
import { Banner as BannerV2 } from '@jane/shared/reefer-v2';
import type { BannerProps } from '@jane/shared/reefer-v2';

export const Banner = (props: BannerProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BannerV2 {...props} />;
  }
  return <BannerV1 {...props} />;
};

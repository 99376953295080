import { fakeButtonProps, handleEnterKey } from '../../utils/handleEnterKey';
import { StyledContainer } from './icon.styles';
import type { IconProps } from './icon.types';

/**
 * Reefer offers a set of beautiful icons for use across application.
 */
export function Icon({
  altText,
  className,
  color,
  'data-testid': testId,
  icon: Icon,
  id,
  height,
  width,
  onClick,
  rotate = 'up',
  size = 'md',
  style,
  title,
  ...marginProps
}: IconProps) {
  // Accessibility check
  if (onClick && !altText) {
    throw Error(
      `[reefer/icon]: Icons with onClick functionality should provide an "altText" prop.`
    );
  }

  return (
    <StyledContainer
      className={className}
      data-testid={testId}
      size={size}
      height={height}
      rotate={rotate}
      width={width}
      title={title}
      color={color === 'default' ? undefined : color}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      style={style}
      {...(onClick && fakeButtonProps)}
      {...marginProps}
    >
      <Icon id={id} role="img" aria-hidden={!altText} aria-label={altText} />
    </StyledContainer>
  );
}

import { Action } from '@thoughtspot/visual-embed-sdk';
import {
  LiveboardEmbed,
  useEmbedRef,
} from '@thoughtspot/visual-embed-sdk/react';
import { useContext } from 'react';

import { AnalyticsPageContext } from '@jane/business-admin/providers';
import { Loading } from '@jane/shared/reefer';

type AnalyticsRoutes =
  | 'kpis'
  | 'sales'
  | 'customers'
  | 'fulfillment'
  | 'on-menu-merchandising'
  | 'off-menu-advertising'
  | 'jane-gold'
  | 'web-traffic'
  | 'marketing-attribution'
  | 'printers'
  | 'product-reviews'
  | 'store-reviews'
  | 'trends'
  | 'pricing'
  | 'marketplace';

type Names =
  | 'KPIs'
  | 'Sales'
  | 'Customers'
  | 'Fulfillment'
  | 'On-Menu Merchandising'
  | 'Off-Menu Advertising'
  | 'Jane Gold'
  | 'Web Traffic'
  | 'Marketing Attribution'
  | 'Product Reviews'
  | 'Store Reviews'
  | 'Trends'
  | 'Pricing'
  | 'Printers'
  | 'Marketplace';

export const routeToLiveBoardName: Record<AnalyticsRoutes, Names> = {
  kpis: 'KPIs',
  sales: 'Sales',
  customers: 'Customers',
  fulfillment: 'Fulfillment',
  'on-menu-merchandising': 'On-Menu Merchandising',
  'off-menu-advertising': 'Off-Menu Advertising',
  'jane-gold': 'Jane Gold',
  'web-traffic': 'Web Traffic',
  'marketing-attribution': 'Marketing Attribution',
  'product-reviews': 'Product Reviews',
  'store-reviews': 'Store Reviews',
  trends: 'Trends',
  pricing: 'Pricing',
  printers: 'Printers',
  marketplace: 'Marketplace',
} as const;

export const Liveboard = ({ liveboardRoute }: { liveboardRoute: string }) => {
  const embedRef = useEmbedRef<typeof LiveboardEmbed>();
  const analyticsContext = useContext(AnalyticsPageContext);

  const nameInFocus =
    routeToLiveBoardName[liveboardRoute as keyof typeof routeToLiveBoardName];

  const relevantSubPayload = Object.values(
    analyticsContext.authorizedLiveboards
  )
    .flat()
    .find((obj) => obj?.name === nameInFocus);

  const liveboardId = relevantSubPayload?.id;

  const visibleActions = [
    Action.Download,
    Action.DownloadAsPdf,
    Action.DownloadAsPng,
    Action.DownloadAsCsv,
    Action.DownloadAsXlsx,
  ];

  if (analyticsContext.isAuthenticating)
    return <Loading size="lg" color="black" />;

  if (!liveboardId || analyticsContext.authState !== 'Success') return null;

  return (
    <LiveboardEmbed
      visibleActions={visibleActions}
      liveboardId={liveboardId}
      ref={embedRef}
      frameParams={{
        height: 'calc(100vh - 80px)',
        width: '100%',
      }}
    />
  );
};

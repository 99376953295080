import type { MouseEventHandler, ReactNode } from 'react';

import { getAccessibilityProps } from '../../internal/utils';
import type { MarginProperties, PaddingProperties } from '../../styling';
import type { BorderRadiusKeys, ColorKey, ReeferBaseProps } from '../../types';
import type { AccessibilityProps } from '../../types/accessibility';
import { fakeButtonProps, handleEnterKey } from '../../utils';
import { StyledBox } from './box.styles';

export interface BoxProps
  extends AccessibilityProps,
    MarginProperties,
    PaddingProperties,
    ReeferBaseProps {
  /**
   * Used to identify the component in the accessibility related error message that's thrown when the component is clickable and accessibility props are missing.
   * You do not need to over-ride this; it is used internally in Reefer.
   * */
  accessibilityComponentName?: string;

  /** Render the `Box` as this HTML element. Defaults to `div`. */
  as?: keyof JSX.IntrinsicElements;

  /** Add a background color to the component */
  background?: ColorKey;

  /** Add a 1px solid border around the component */
  border?: ColorKey | 'none';

  /** Add a border radius to the component using available theme sizes */
  borderRadius?: BorderRadiusKeys;

  /** Optionally change the border width, defaults to 1px */
  borderWidth?: string;

  /** CSS bottom attribute of element */
  bottom?: string | number;

  /** Child elements of the component */
  children?: ReactNode;

  /** Set a height, accepts any valid `height` value */
  height?: string | number;

  /** For attribute, for labels only, must match id of input label is for. **/
  htmlFor?: string;

  /** CSS left attribute of element */
  left?: string | number;

  /** Set item `max-height`, accepts any valid `max-height` value */
  maxHeight?: string | number;

  /** Set item `max-width`, accepts any valid `max-width` value */
  maxWidth?: string | number;

  /** Set item `min-height`, accepts any valid `min-height` value */
  minHeight?: string | number;

  /** Set item `min-width`, accepts any valid `min-width` value */
  minWidth?: string | number;

  /** An optional `onClick` handler */
  onClick?: MouseEventHandler<HTMLElement>;

  /** Set item `overflow`, accepts any valid `overflow` value */
  overflow?: string;

  /** CSS position attribute of element */
  position?:
    | 'static'
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'inherit';

  /** CSS right attribute of element */
  right?: string | number;

  /** HTML `title` attribute, displays the supplied text as a tooltip. Note: Use
   * `title` sparingly as it is not very accessible and doesn't show up on touch
   * interfaces. */
  title?: string;

  /** CSS top attribute of element */
  top?: string | number;

  /** Set item `width`, accepts any valid `width` value */
  width?: string | number;

  /** Set item `z-index`, accepts any valid `z-index` value */
  zIndex?: string | number;
}

/**
 * The `Box` component is a wrapping container component with `margin` and `padding`
 * spacing props. Additional style props including `background`, `border`, `borderRadius`, `height` and `width` are also available.
 * */

export const Box = ({
  accessibilityComponentName = 'Box',
  ariaLabel,
  ariaLabelledBy,
  as = 'div',
  background,
  border,
  borderRadius,
  borderWidth = '1px',
  bottom,
  children,
  className,
  'data-testid': testId,
  height,
  htmlFor,
  id,
  left,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  onClick,
  position,
  right,
  role,
  style,
  top,
  width,
  zIndex,
  ...props
}: BoxProps) => {
  const a11yProps = getAccessibilityProps(
    { ariaLabel, ariaLabelledBy, onClick, role },
    accessibilityComponentName
  );

  return (
    <StyledBox
      {...(onClick && fakeButtonProps)}
      as={as}
      background={background}
      border={border}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      bottom={bottom}
      className={className}
      data-testid={testId}
      height={height}
      htmlFor={htmlFor}
      id={id}
      left={left}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minHeight={minHeight}
      minWidth={minWidth}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      position={position}
      right={right}
      style={style}
      top={top}
      width={width}
      zIndex={zIndex}
      {...a11yProps}
      {...props}
    >
      {children}
    </StyledBox>
  );
};

import type { CSSObject } from '@emotion/react';
import type { CSSProperties } from 'react';

import type { BoxProps } from '../components';
import type { Spacing } from './spacing';

export const combine = (
  ...styles: (CSSObject | false | null | undefined)[]
): CSSObject =>
  styles.reduce<CSSObject>(
    (result, style) => (style ? { ...result, ...style } : result),
    {}
  );
export type FlexProperties = Pick<
  CSSProperties,
  | 'alignItems'
  | 'alignContent'
  | 'flexDirection'
  | 'justifyContent'
  | 'justifyItems'
  | 'flexWrap'
> & {
  /** Set `gap`, the spacing between rows and columns */
  gap?: Spacing;

  /** Set `display` as `inline-flex` on the item */
  inline?: boolean;
};

export const flex = ({
  inline,
  alignItems,
  alignContent,
  gap,
  flexDirection,
  flexWrap,
  justifyContent,
  justifyItems,
}: FlexProperties = {}): CSSObject =>
  combine(
    {
      display: inline ? 'inline-flex' : 'flex',
    },
    {
      alignItems,
      alignContent,
      gap,
      flexDirection,
      flexWrap,
      justifyContent,
      justifyItems,
    }
  );

interface FlexPropertyArgs {
  /** The length of the item. Legal values: "auto", "inherit", or a number followed by "%", "px", "em" or any other length unit */
  basis?: string;

  /** Set `flex-grow`, how the item grows relative to other flex items*/
  grow?: boolean | number;

  /** Documented above under FlexProperties */
  inline?: boolean;

  /** Set `flex-shrink`, how the item shrinks relative to other flex items*/
  shrink?: boolean | number;
}

export type FlexItemProperties = Pick<
  CSSProperties,
  'alignSelf' | 'justifySelf'
> &
  Pick<BoxProps, 'height' | 'width'> &
  FlexPropertyArgs;

const flexProperty = ({ grow, shrink, basis }: FlexPropertyArgs) => {
  if ([grow, shrink, basis].every((el) => el === undefined)) return null;

  const growVal = typeof grow === 'number' ? grow : grow ? 1 : 0;
  const shrinkVal = typeof shrink === 'number' ? shrink : !shrink ? 0 : 1;
  const basisVal = !basis ? 'auto' : basis;

  return { flex: `${growVal} ${shrinkVal} ${basisVal}` };
};

export const flexItem = ({
  grow,
  shrink,
  basis,
  inline,
  alignSelf,
  justifySelf,
  width,
  height,
}: FlexItemProperties = {}): CSSObject =>
  combine(
    inline && { display: 'inline-flex' },
    flexProperty({ basis, grow, shrink }),
    { alignSelf, justifySelf, width, height }
  );

import { Link as RouterLink } from 'react-router-dom';

import { fakeButtonProps, handleEnterKey } from '../../../utils';
import type { ListItemProps } from '../list.types';
import {
  StyledListItem,
  StyledListItemLink,
  StyledListItemRouterLink,
} from './listItem.styles';

export const ListItem = ({
  alignment = 'left',
  children,
  className,
  'data-testid': testId,
  disableHover = false,
  href,
  id,
  onClick,
  onMouseEnter,
  px = 8,
  py = 12,
  selected,
  to,
  ...spacingProps
}: ListItemProps) => {
  const sharedProps = {
    alignment,
    disableHover,
    selected,
  };

  if (href) {
    return (
      <StyledListItem
        className={className}
        data-testid={testId}
        hasLink={!!href}
        id={id}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onKeyUp={(event) => handleEnterKey(event, onClick)}
        {...(onClick && fakeButtonProps)}
        {...sharedProps}
      >
        <StyledListItemLink
          href={href}
          variant="minimal"
          px={px}
          py={py}
          {...spacingProps}
        >
          {children}
        </StyledListItemLink>
      </StyledListItem>
    );
  }

  if (to) {
    return (
      <StyledListItem
        className={className}
        data-testid={testId}
        hasLink={!!to}
        id={id}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onKeyUp={(event) => handleEnterKey(event, onClick)}
        {...(onClick && fakeButtonProps)}
        {...sharedProps}
      >
        <StyledListItemRouterLink px={px} py={py} {...spacingProps}>
          <RouterLink className="router-link" to={to}>
            {children}
          </RouterLink>
        </StyledListItemRouterLink>
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      className={className}
      data-testid={testId}
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      px={px}
      py={py}
      {...spacingProps}
      {...sharedProps}
    >
      {children}
    </StyledListItem>
  );
};

import styled from '@emotion/styled';

import { shadows, spacing } from '../../../../../styling';

export const AutocompleteContainer = styled.div({
  position: 'relative',
});

export const StyledAlignment = styled.div({
  background: 'transparent',
  height: 'auto',
  position: 'absolute',
  width: '100%',
  zIndex: 10,
});

export const StyledOptionsContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: shadows.hard,
  overflowY: 'hidden',
  position: 'relative',
  ...spacing({ mt: 12 }),
}));

export const StyledListContainer = styled.div({
  height: '100%',
  maxHeight: '40vh',
  overflowY: 'auto',
});

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ThumbsUp24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9487 6.30562C12.4602 4.77117 12.9191 3.54359 13.3847 2.8711C13.5062 2.69556 13.5943 2.6087 13.644 2.56739C13.6781 2.59109 13.7273 2.63091 13.7929 2.69649C14.2668 3.17043 14.4125 3.91544 14.3453 4.88845C14.3129 5.35718 14.2358 5.81915 14.1591 6.25847L14.1402 6.36618C14.0756 6.73279 14 7.16167 14 7.48939C14 7.76543 14.1141 8.0292 14.3153 8.21821C14.5165 8.40722 14.7869 8.50466 15.0624 8.48744C16.3537 8.40673 18.1505 8.41942 19.6413 8.73099C20.3894 8.88735 20.9926 9.10553 21.4083 9.37453C21.8089 9.63376 21.9701 9.89258 22.0136 10.1538C22.2432 11.5311 22.2451 13.58 21.8364 15.4148C21.4133 17.3145 20.6355 18.629 19.6061 19.0702C18.1015 19.715 15.8156 19.8224 13.448 19.4986C11.3558 19.2125 9.36142 18.6122 8 17.9051V10.306C8.45676 10.1863 8.9966 9.99073 9.53839 9.64459C10.4998 9.03036 11.3835 8.00128 11.9487 6.30562ZM7.51579 8.36499C7.82327 8.28342 8.14337 8.16251 8.46161 7.9592C9.00021 7.61509 9.61654 6.9775 10.0513 5.67317C10.5398 4.20761 11.0809 2.68519 11.7403 1.73268C12.0635 1.26584 12.5475 0.736406 13.2465 0.582955C14.0307 0.410818 14.7041 0.779266 15.2071 1.28228C16.3143 2.38948 16.4187 3.89447 16.3405 5.02618C16.3049 5.54277 16.2269 6.03619 16.1563 6.4469C17.3619 6.43332 18.7754 6.50682 20.0504 6.77329C20.9176 6.95452 21.7887 7.23847 22.4949 7.69544C23.2162 8.16219 23.8244 8.8532 23.9864 9.82499C24.2568 11.4476 24.2549 13.7559 23.7886 15.8496C23.3367 17.8786 22.3645 20.064 20.3939 20.9085C18.3985 21.7637 15.6844 21.823 13.177 21.4802C11.0766 21.1929 8.97712 20.6034 7.37745 19.8298C6.8289 20.5414 5.96799 20.9999 5 20.9999H3C1.34315 20.9999 0 19.6567 0 17.9999V9.99989C0 8.34304 1.34315 6.99989 3 6.99989H5C6.05378 6.99989 6.98066 7.54321 7.51579 8.36499ZM13.5835 2.53506C13.5835 2.53506 13.5882 2.53525 13.5983 2.53999C13.5886 2.53816 13.5835 2.53506 13.5835 2.53506ZM2 9.99989C2 9.44761 2.44772 8.99989 3 8.99989H5C5.55228 8.99989 6 9.44761 6 9.99989V17.9999C6 18.5522 5.55228 18.9999 5 18.9999H3C2.44772 18.9999 2 18.5522 2 17.9999V9.99989Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: ThumbsUp24,
  sm: ThumbsUp24,
  lg: ThumbsUp24,
  xl: ThumbsUp24,
  xxl: ThumbsUp24,
  xxxl: ThumbsUp24,
};

export const ThumbsUpIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'thumbs-up-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../../styling';
import { mobileInputStyling } from '../field.styles';
import type { StyledFieldProps } from '../field.types';

interface StyledTextAreaFieldProps {
  disableMobileInputStyling?: boolean;
  resize: boolean;
}

export const StyledTextAreaContainer = styled.div({
  display: 'flex',
  position: 'relative',
});

/**
 * A separate element for styling the `textarea` border.
 * When the border is applied to the `textarea` itself,
 * the resize control icon overflows the rounded corner.
 */
export const StyledTextAreaBorder = styled.div<StyledFieldProps>(
  {
    height: '100%',
    position: 'absolute',
    top: '0',
    width: '100%',
  },
  ({
    theme: { borderRadius, colors },
    disableMobileInputStyling,
    radiusSize,
    readOnly,
  }) => ({
    background: readOnly ? colors.grays.ultralight : colors.grays.white,
    border: `1px solid ${colors.grays.light}`,
    borderRadius: borderRadius[radiusSize],
    'textarea:active + &, textarea:focus + &, textarea:focus-visible + &': {
      borderColor: colors.primary.main,
    },
    'textarea:read-only + &, textarea:disabled + &': {
      borderColor: colors.grays.light,
    },
    [mediaQueries.tablet('max')]: [
      !disableMobileInputStyling && {
        ...mobileInputStyling(),
        backgroundColor: 'transparent',
      },
    ],
  })
);

export const StyledTextArea = styled.textarea<StyledTextAreaFieldProps>(
  {
    /**
     * Add correct "padding" around the `textarea`, while correctly "padding"
     * the resize control icon, without causing the `textarea` to overflow its
     * container. (Padding styles don't affect that icon.)
     */
    border: '12px solid transparent',
    minHeight: '48px',
    outline: 'none',
    position: 'relative',
    width: '100%',
    zIndex: '1',
  },
  ({ disableMobileInputStyling, resize, theme: { borderRadius } }) => ({
    backgroundColor: 'transparent',
    borderRadius: borderRadius.sm,
    resize: resize ? 'vertical' : 'none',
    /**
     * Text entry fields require `padding: 12px 16px`. Applying this spacing
     * with the border style above, displays the resize control icon with that spacing.
     * This looked poor. This padding keeps spacing consistent, without affecting
     * the resize control icon.
     */
    ...spacing({ px: 4 }),
    [mediaQueries.tablet('max')]: [
      !disableMobileInputStyling && {
        border: 'none',
        ...spacing({ px: 0 }),
      },
    ],
  })
);

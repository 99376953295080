import styled from '@emotion/styled';
import { useState } from 'react';

import { useAuthentication } from '@jane/shared-ecomm/providers';
import { useShouldShowGold } from '@jane/shared/hooks';
import {
  Box,
  Button,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';

import { LogInModal } from '../logInModal';
import { Prompt } from '../prompt';
import { PROMPTS } from '../prompt/prompt.util';

const NoWrapButton = styled(Button)({
  whiteSpace: 'nowrap',
});

const NoWrapLink = styled(Link)({
  whiteSpace: 'nowrap',
});

interface HeaderSignInPromptProps {
  color?: ColorKey;
}

export const HeaderSignInPrompt = ({
  color = 'text-main',
}: HeaderSignInPromptProps) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const janeGold = useShouldShowGold();
  const isMobile = useMobileMediaQuery({});
  const { defaultLogin, register, resetError, resetPassword, ...authProps } =
    useAuthentication();

  const goldText =
    'Sign in to earn cash back and:discover products just for you';
  const nonGoldText =
    'Sign in to discover cannabis:products tailored just for you!';
  const text = janeGold ? goldText : nonGoldText;

  return (
    <>
      <Prompt
        prompt={PROMPTS.signIn}
        dismissible
        target={
          <NoWrapLink
            color={color}
            onClick={() => setShowLoginModal(true)}
            variant="minimal"
            typography="body-bold"
            branded
          >
            Sign in
          </NoWrapLink>
        }
      >
        <Prompt.Content>
          <Flex
            flexDirection="row"
            gap={16}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            {!isMobile && (
              <Box>
                <Typography whiteSpace="nowrap" variant="mini">
                  {text.split(':')[0]}
                </Typography>
                <Typography whiteSpace="nowrap" variant="mini">
                  {text.split(':')[1]}
                </Typography>
              </Box>
            )}

            <NoWrapButton
              label="Sign in"
              onClick={() => setShowLoginModal(true)}
            />
          </Flex>
        </Prompt.Content>
      </Prompt>
      {showLoginModal && (
        <LogInModal
          {...authProps}
          location="header"
          onSignInSubmit={defaultLogin}
          onCreateAccountSubmit={register}
          onForgotPasswordSubmit={resetPassword}
          open
          onRequestClose={() => setShowLoginModal(false)}
        />
      )}
    </>
  );
};

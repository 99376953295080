/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Woman24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8ZM10 15.748V17H9C7.89543 17 7 17.8954 7 19C7 20.1046 7.89543 21 9 21H10V22C10 23.1046 10.8954 24 12 24C13.1046 24 14 23.1046 14 22V21H15C16.1046 21 17 20.1046 17 19C17 17.8954 16.1046 17 15 17H14V15.748C17.4505 14.8599 20 11.7277 20 8C20 3.58172 16.4183 0 12 0C7.58172 0 4 3.58172 4 8C4 11.7277 6.54955 14.8599 10 15.748Z"
      fill="#8E00E6"
    />
  </svg>
);

const SIZE_MAP = {
  md: Woman24,
  sm: Woman24,
  lg: Woman24,
  xl: Woman24,
  xxl: Woman24,
  xxxl: Woman24,
};

export const WomanIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'woman-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

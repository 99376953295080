/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Wheelchair24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3413 10.8818C21.0536 10.5866 20.6517 10.4308 20.2409 10.4533L15.2692 10.73L18.0053 7.61395C18.395 7.17007 18.5064 6.57836 18.3557 6.04962C18.2762 5.68731 18.0568 5.35765 17.7197 5.14703C17.7093 5.13964 11.1796 1.3464 11.1796 1.3464C10.6465 1.03666 9.97459 1.1049 9.51446 1.51531L6.3248 4.36054C5.73714 4.88472 5.68567 5.78605 6.20988 6.37375C6.73413 6.96138 7.6355 7.013 8.22313 6.48864L10.6413 4.33156L12.6404 5.49246L9.1117 9.51135C7.64845 9.74999 6.33189 10.4243 5.29838 11.3964L7.14112 13.2392C7.97414 12.4817 9.0802 12.0194 10.2922 12.0194C12.8773 12.0194 14.9805 14.1227 14.9805 16.7078C14.9805 17.9198 14.5182 19.0258 13.7609 19.8588L15.6035 21.7016C16.831 20.3967 17.5843 18.6408 17.5843 16.7078C17.5843 15.5562 17.317 14.4672 16.8416 13.4987L18.766 13.3915L18.2979 19.1339C18.2339 19.9188 18.8184 20.6068 19.6033 20.6709C19.6426 20.6741 19.6818 20.6756 19.7207 20.6756C20.4556 20.6756 21.0796 20.1109 21.1403 19.3655L21.7412 11.9929C21.7747 11.5821 21.6289 11.1769 21.3413 10.8818Z"
      fill="#0E0E0E"
    />
    <path
      d="M18.7003 4.77365C20.0185 4.77365 21.087 3.70508 21.087 2.38685C21.087 1.06869 20.0185 0 18.7003 0C17.382 0 16.3135 1.06869 16.3135 2.38685C16.3135 3.70508 17.382 4.77365 18.7003 4.77365Z"
      fill="#0E0E0E"
    />
    <path
      d="M10.2922 21.3962C7.70705 21.3962 5.60381 19.293 5.60381 16.7078C5.60381 15.7335 5.90275 14.828 6.41338 14.0774L4.55075 12.2148C3.58021 13.4533 3 15.0124 3 16.7078C3 20.7352 6.2648 24 10.2922 24C11.9877 24 13.5466 23.4198 14.7851 22.4492L12.9225 20.5867C12.172 21.0972 11.2664 21.3962 10.2922 21.3962Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Wheelchair24,
  sm: Wheelchair24,
  lg: Wheelchair24,
  xl: Wheelchair24,
  xxl: Wheelchair24,
  xxxl: Wheelchair24,
};

export const WheelchairIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'wheelchair-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

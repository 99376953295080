import styled from '@emotion/styled';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { type Control, Controller, useFieldArray } from 'react-hook-form';

import { Flex, Form } from '@jane/shared/reefer';

const LabelFieldContainer = styled.div`
  width: 150px;
`;

const LabelField = styled(Form.TextField)`
  div {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const UrlFieldContainer = styled.div`
  width: 100%;
  margin-right: 175px;
`;

const UrlField = styled(Form.TextField)`
  div {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }

  &:focus-within {
    div {
      div:last-child {
        border-left: 1px solid #5e1ff0;
      }
    }
  }
`;

type FormValues = {
  menuItems: {
    destination?: string;
    label: string;
    links?: {
      destination: string;
      label: string;
    }[];
  }[];
};

export const PlusSubMenuItems = ({
  parentIndex,
  control,
  addSubItemClicked,
  setAddSubItemClicked,
}: {
  addSubItemClicked: number | null;
  control: Control<FormValues>;
  parentIndex: number;
  setAddSubItemClicked: Dispatch<SetStateAction<number | null>>;
}) => {
  const { fields, append } = useFieldArray({
    control,
    name: `menuItems.${parentIndex}.links`,
  });

  useEffect(() => {
    if (parentIndex === addSubItemClicked) {
      append({ label: '', destination: '' });
      setAddSubItemClicked(null);
    }
  }, [addSubItemClicked, append, parentIndex, setAddSubItemClicked]);

  return (
    <Flex flexDirection="column" gap={8} ml={96}>
      {fields.map((item, idx) => {
        return (
          <Flex key={item.id}>
            <Controller
              name={`menuItems.${parentIndex}.links.${idx}.label`}
              control={control}
              rules={{ required: true, pattern: /\S+/ }}
              render={({ field }) => (
                <LabelFieldContainer>
                  <LabelField
                    name={`menuItems.${parentIndex}.links.${idx}.label`}
                    label=""
                    defaultValue={field.value}
                    placeholder="Add label"
                    onChange={(value) => field.onChange(value)}
                  />
                </LabelFieldContainer>
              )}
            />
            <Controller
              name={`menuItems.${parentIndex}.links.${idx}.destination`}
              control={control}
              rules={{ required: true, pattern: /\S+/ }}
              render={({ field }) => (
                <UrlFieldContainer>
                  <UrlField
                    label=""
                    defaultValue={field.value}
                    name={`menuItems.${parentIndex}.links.${idx}.destination`}
                    placeholder="Add URL"
                    width="100%"
                    onChange={(value) => field.onChange(value)}
                  />
                </UrlFieldContainer>
              )}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

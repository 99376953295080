import styled from '@emotion/styled';
import { useCallback } from 'react';

import type { UpsertBloomMenuTemplateArgs } from '@jane/business-admin/data-access';
import {
  useFetchCurrentBloomMenu,
  useUpsertBloomMenuTemplate,
} from '@jane/business-admin/data-access';
import { businessPaths, normalizeUrl } from '@jane/business-admin/util';
import { useNavigateBack } from '@jane/shared/hooks';
import {
  Button,
  ChevronLeftIcon,
  Flex,
  Form,
  Typography,
  useToast,
} from '@jane/shared/reefer';

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

export const PlusFooter = () => {
  const goBack = useNavigateBack(businessPaths.plus());
  const toast = useToast();
  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const { mutate: updateBloomMenuTemplate, isLoading } =
    useUpsertBloomMenuTemplate({
      menuId: bloomMenuQuery.data?.id,
    });

  const importFooter = useCallback(
    ({ source_content_selector, source_url }: UpsertBloomMenuTemplateArgs) => {
      updateBloomMenuTemplate(
        {
          source_content_selector,
          source_url: normalizeUrl(source_url),
          template_type: 'footer',
        },
        {
          onSuccess: () => {
            toast.add({
              label: 'Footer imported!',
              variant: 'success',
            });
          },
          onError: () => {
            toast.add({
              label: 'An error occurred.',
              variant: 'error',
            });
          },
        }
      );
    },
    [toast, updateBloomMenuTemplate]
  );

  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Form name="footer_form" onSubmit={importFooter}>
        <BorderedFlex p={16} gap={16} flexDirection="row" alignItems="center">
          <ChevronLeftIcon onClick={() => goBack()} altText="Go back" />
          <Typography variant="header-bold" branded>
            Footer
          </Typography>

          <Flex ml="auto" flexDirection="row">
            <Button
              disabled={isLoading}
              loading={isLoading}
              ml="auto"
              label="Import"
              type="submit"
              variant="primary"
            />
          </Flex>
        </BorderedFlex>

        <BorderedFlex justifyContent="center" py={40}>
          <Flex alignItems="center" flexDirection="column" maxWidth={660}>
            <Form.TextField
              startIcon={<Typography>https://</Typography>}
              label="Source"
              name="source_url"
              helperText="Provide the URL of your footer website to directly import the footer"
              mb={24}
              endIcon={<Typography color="grays-mid">URL</Typography>}
            />
            <Form.TextField
              defaultValue="footer"
              label="Content selector"
              name="source_content_selector"
              helperText="Provide the CSS content selector of the footer element to import"
              endIcon={<Typography color="grays-mid">Selector</Typography>}
            />
          </Flex>
        </BorderedFlex>
      </Form>
    </Flex>
  );
};

import { forwardRef } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Modal as ModalV1 } from '@jane/shared/reefer-v1';
import { Modal as ModalV2 } from '@jane/shared/reefer-v2';
import type {
  ModalContentDividerProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
} from '@jane/shared/reefer-v2';

export const BaseModal = (props: ModalProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ModalV2 {...props} />;
  }
  return <ModalV1 {...props} />;
};

const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  (props: ModalContentProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <ModalV2.Content {...props} ref={ref} />;
    }
    return <ModalV1.Content {...props} ref={ref} />;
  }
);

const ModalContentDivider = (props: ModalContentDividerProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ModalV2.ContentDivider {...props} />;
  }
  return <ModalV1.ContentDivider {...props} />;
};

const ModalFooter = (props: ModalFooterProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ModalV2.Footer {...props} />;
  }
  return <ModalV1.Footer {...props} />;
};

const ModalHeader = (props: ModalHeaderProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ModalV2.Header {...props} />;
  }
  return <ModalV1.Header {...props} />;
};

export const Modal = Object.assign(BaseModal, {
  Content: ModalContent,
  ContentDivider: ModalContentDivider,
  Footer: ModalFooter,
  Header: ModalHeader,
});

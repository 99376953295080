/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Dismiss16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L9.41423 8.00002L13.7071 3.70714C14.0976 3.31661 14.0976 2.68345 13.7071 2.29293C13.3166 1.9024 12.6834 1.9024 12.2929 2.29293L8.00002 6.5858L3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L6.5858 8.00002L2.29289 12.2929C1.90237 12.6835 1.90237 13.3166 2.29289 13.7071C2.68342 14.0977 3.31658 14.0977 3.70711 13.7071L8.00002 9.41423L12.2929 13.7071Z"
      fill="#0E0E0E"
    />
  </svg>
);

const Dismiss24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7097 5.69208C20.0968 5.30499 20.0968 4.6774 19.7097 4.29031C19.3226 3.90323 18.695 3.90323 18.3079 4.29031L12 10.5982L5.69208 4.29031C5.30499 3.90323 4.6774 3.90323 4.29031 4.29031C3.90323 4.6774 3.90323 5.30499 4.29031 5.69208L10.5982 12L4.29031 18.3079C3.90323 18.695 3.90323 19.3226 4.29031 19.7097C4.6774 20.0968 5.30499 20.0968 5.69208 19.7097L12 13.4018L18.3079 19.7097C18.695 20.0968 19.3226 20.0968 19.7097 19.7097C20.0968 19.3226 20.0968 18.695 19.7097 18.3079L13.4018 12L19.7097 5.69208Z"
      fill="#0E0E0E"
    />
  </svg>
);

const Dismiss32 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5645 6.53811C28.1452 5.95748 28.1452 5.0161 27.5645 4.43547C26.9839 3.85484 26.0425 3.85484 25.4619 4.43547L16 13.8973L6.53821 4.43547C5.95758 3.85484 5.01619 3.85484 4.43557 4.43547C3.85494 5.0161 3.85494 5.95748 4.43557 6.53811L13.8974 16L4.43547 25.4619C3.85484 26.0425 3.85484 26.9839 4.43547 27.5645C5.0161 28.1452 5.95748 28.1452 6.53811 27.5645L16 18.1026L25.462 27.5645C26.0426 28.1452 26.984 28.1452 27.5646 27.5645C28.1452 26.9839 28.1452 26.0425 27.5646 25.4619L18.1027 16L27.5645 6.53811Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  sm: Dismiss16,
  md: Dismiss24,
  lg: Dismiss32,
  xl: Dismiss32,
  xxl: Dismiss32,
  xxxl: Dismiss32,
};

export const DismissIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'dismiss-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { getNodeText } from '../../../internal/utils';
import { marginCSSVars } from '../../../utils';
import { iconWithProps } from '../button';
import styles from '../button.module.css';
import type { IconButtonProps } from '../button.types';

/**
 * `Button.Icons` components are similar to `Button` components, but only display an icon as their label.
 */
export function IconButton({
  ariaLabel,
  className: classNameProp,
  data,
  'data-testid': testId,
  disabled = false,
  form,
  full,
  href,
  icon,
  id,
  label,
  onClick,
  size = 'default',
  style,
  target,
  to,
  type = 'button',
  variant = 'minimal',
  ...marginProps
}: IconButtonProps) {
  const fakeButton = !onClick && type === 'button';
  const stringifiedLabel = getNodeText(label);

  const getDataProps = (data: IconButtonProps['data']) => {
    const dataProps = {} as Record<string, string | number | boolean>;

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        dataProps[`data-${key}`] = data[key];
      }
    }

    return dataProps;
  };

  const sharedProps = {
    'aria-label': ariaLabel,
    'data-testid': testId,
    disabled,
    name: stringifiedLabel,
    onClick,
    style: {
      ...marginCSSVars(marginProps),
      ...style,
    },
    ...getDataProps(data),
  };

  const accessibleIcon = iconWithProps(icon, stringifiedLabel);

  const className = clsx(
    classNameProp,
    styles.button,
    styles.iconButton,
    styles[`iconButton__${size}`],
    styles[`button__${variant}`],
    {
      [styles.button__full]: full,
      [styles.button__disabled]: disabled,
    }
  );

  if (to) {
    return (
      <Link
        className={className}
        id={id}
        target={target || '_self'}
        to={to}
        {...sharedProps}
      >
        {accessibleIcon}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        className={className}
        id={id}
        target={target || '_blank'}
        rel="noopener noreferrer"
        href={href}
        {...sharedProps}
      >
        <span className={styles.button_newTabLabel}>Opens in new window</span>
        {accessibleIcon}
      </a>
    );
  }

  if (!href && !to && fakeButton) {
    return (
      <div className={className} id={id} {...sharedProps}>
        {accessibleIcon}
      </div>
    );
  }

  return (
    <button
      className={className}
      form={form}
      id={id}
      type={type}
      {...sharedProps}
    >
      {accessibleIcon}
    </button>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ya4JjBGFE5MjBTuFVgLH:not(:focus):not(:active) {\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  overflow: hidden;\n  opacity: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n}\n\n@media (max-width: 768px) {\n  .F6r84zQCaRsQvJVOgunl:not(:focus):not(:active) {\n    clip: rect(0 0 0 0);\n    clip-path: inset(50%);\n    height: 1px;\n    overflow: hidden;\n    opacity: 0;\n    position: absolute;\n    white-space: nowrap;\n    width: 1px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/hide/hide.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE;IACE,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;EACZ;AACF","sourcesContent":[".isHidden:not(:focus):not(:active) {\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  overflow: hidden;\n  opacity: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n}\n\n@media (max-width: 768px) {\n  .isHidden__tabletMax:not(:focus):not(:active) {\n    clip: rect(0 0 0 0);\n    clip-path: inset(50%);\n    height: 1px;\n    overflow: hidden;\n    opacity: 0;\n    position: absolute;\n    white-space: nowrap;\n    width: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isHidden": "Ya4JjBGFE5MjBTuFVgLH",
	"isHidden__tabletMax": "F6r84zQCaRsQvJVOgunl"
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchMultipleProductDetails } from '@jane/business-admin/data-access';
import type { MenuProductForProductsTable } from '@jane/business-admin/types';

// TODO: Would this be better as a bulk endpoint?
export const useGetProductDetails = (
  product_ids?: number[],
  useMenuProductId = false
) => {
  const { id: storeId = '' } = useParams<'id'>();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState<MenuProductForProductsTable[]>();
  const productsQueries = useFetchMultipleProductDetails(
    storeId,
    product_ids || [],
    useMenuProductId
  );

  useEffect(() => {
    const totalQueries = product_ids?.length || 0;
    let finishedQueries = 0;
    const returnedProducts = productsQueries.flatMap((query) => {
      if (query.isError) {
        setHasError(true);
      }
      if (query.isLoading) {
        setIsLoading(true);
      }
      if (query.isSuccess) {
        finishedQueries++;
        if (finishedQueries === totalQueries) {
          setIsLoading(false);
        }
        if (!query.data.product) {
          return [];
        }
        const product = query.data?.product;
        return product;
      }
      return [];
    });
    setData(returnedProducts);
  }, [JSON.stringify(productsQueries), product_ids?.length]);

  return { data, hasError, isLoading };
};

import type { ReactNode } from 'react';

import { Flex } from '../../../flex/flex';
import type { FieldProps } from '../field.types';
import {
  StyledFieldWrapper,
  StyledHelperText,
  StyledHelperTextMobile,
  StyledLabel,
} from './fieldWrapper.styles';

export interface RenderProps {
  'aria-describedby'?: string;
  'aria-labelledby': string;
}

export interface FieldWrapperProps extends Omit<FieldProps, 'autoFocus'> {
  /** Disables mobile text input styling */
  disableMobileInputStyling?: boolean;

  /** Customise helper text id */
  helperTextId?: string;

  /** If the input it is wrapping is a text input (for mobile styling) */
  isWrappingTextInput?: boolean;

  /** Where to position the field label, 't' for top, or 'r' for right. */
  labelPosition?: 't' | 'r';

  /** Render function */
  render: (renderProps: RenderProps) => ReactNode;

  /** Whether to reposition the helper text beside the label on mobile */
  repositionMobileHelperText?: boolean;
}

/**
 * This is a wrapper component for common functionality of fields.
 */
export const FieldWrapper = ({
  children,
  className,
  disabled = false,
  'data-testid': testId,
  disableMobileInputStyling = false,
  helperText,
  helperTextId: helperTextIdProp,
  id,
  isWrappingTextInput = false,
  label,
  labelHidden = false,
  labelPosition = 't',
  name,
  render,
  repositionMobileHelperText = false,
  style,
  width = 'auto',
  typography,
  ...props
}: FieldWrapperProps) => {
  const labelId = `${name}-label`;
  let helperTextId;
  let describedBy;
  if (helperText || helperTextIdProp) {
    helperTextId = helperTextIdProp || name;
    describedBy = `${helperTextId}-helper-text`;
    if (repositionMobileHelperText) {
      describedBy = `${describedBy} ${helperTextId}-mobile-helper-text`;
    }
  }
  const labelVariant = labelPosition === 't' ? 'body-bold' : 'body';

  return (
    <StyledFieldWrapper
      className={className}
      data-testid={testId}
      disabled={disabled}
      disableMobileInputStyling={disableMobileInputStyling}
      id={id}
      isWrappingTextInput={isWrappingTextInput}
      style={style}
      width={width}
      {...props}
    >
      <Flex
        flexDirection={labelPosition === 't' ? 'column' : 'row-reverse'}
        alignItems={labelPosition === 't' ? undefined : 'center'}
        justifyContent="flex-end"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <StyledLabel
            as="label"
            color="inherit"
            disableMobileInputStyling={disableMobileInputStyling}
            labelHidden={labelHidden}
            htmlFor={name}
            id={labelId}
            labelPosition={labelPosition}
            variant={typography || labelVariant}
          >
            {label}
          </StyledLabel>
          {repositionMobileHelperText && !labelHidden && (
            <StyledHelperTextMobile
              disabled={disabled}
              id={`${helperTextId}-mobile`}
              mb={12}
            >
              {helperText}
            </StyledHelperTextMobile>
          )}
        </Flex>

        {render({
          'aria-describedby': describedBy,
          'aria-labelledby': labelId,
        })}
      </Flex>
      {children}
      <StyledHelperText
        disabled={disabled}
        id={helperTextId}
        labelHidden={labelHidden}
        mt={!labelHidden && !repositionMobileHelperText ? 12 : 0}
        repositionMobileHelperText={repositionMobileHelperText}
      >
        {helperText}
      </StyledHelperText>
    </StyledFieldWrapper>
  );
};

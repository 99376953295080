/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const StarFilled16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.14216 0.559708C7.49306 -0.183314 8.50694 -0.183314 8.85784 0.559708L10.5889 4.22509C10.7282 4.52014 10.9976 4.72465 11.3092 4.77197L15.1799 5.35974C15.9645 5.47889 16.2778 6.48656 15.7101 7.06492L12.9092 9.91802C12.6837 10.1477 12.5808 10.4786 12.6341 10.8029L13.2953 14.8315C13.4293 15.6482 12.609 16.271 11.9072 15.8854L8.44516 13.9833C8.16647 13.8302 7.83353 13.8302 7.55484 13.9833L4.09276 15.8854C3.39095 16.271 2.57071 15.6482 2.70474 14.8315L3.36594 10.8029C3.41916 10.4786 3.31628 10.1477 3.09082 9.91802L0.289936 7.06492C-0.27784 6.48656 0.0354662 5.47889 0.820113 5.35974L4.69083 4.77197C5.00242 4.72465 5.27177 4.52014 5.41112 4.22509L7.14216 0.559708Z"
      fill="#FFC220"
    />
  </svg>
);

const StarFilled24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7132 0.839562C11.2396 -0.274971 12.7604 -0.274971 13.2868 0.839562L15.8833 6.33764C16.0923 6.78022 16.4964 7.08698 16.9637 7.15795L22.7698 8.03961C23.9468 8.21833 24.4168 9.72983 23.5651 10.5974L19.3638 14.877C19.0256 15.2215 18.8713 15.7179 18.9511 16.2043L19.9429 22.2473C20.1439 23.4723 18.9136 24.4064 17.8609 23.8281L12.6677 20.975C12.2497 20.7453 11.7503 20.7453 11.3323 20.975L6.13914 23.8281C5.08643 24.4064 3.85606 23.4723 4.05711 22.2473L5.04891 16.2043C5.12875 15.7179 4.97442 15.2215 4.63622 14.877L0.434904 10.5974C-0.416759 9.72983 0.0531993 8.21833 1.23017 8.03961L7.03625 7.15795C7.50363 7.08698 7.90766 6.78022 8.11667 6.33764L10.7132 0.839562Z"
      fill="#FFC220"
    />
  </svg>
);

const SIZE_MAP = {
  sm: StarFilled16,
  md: StarFilled24,
  lg: StarFilled24,
  xl: StarFilled24,
  xxl: StarFilled24,
  xxxl: StarFilled24,
};

export const StarFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'star-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import React, { forwardRef, useEffect, useState } from 'react';

import { Box } from '../../../box';
import { ConditionalWrapper } from '../../../conditionalWrapper';
import { Flex } from '../../../flex/flex';
import { Typography } from '../../../typography';
import type { RadioFieldGroupProps, RadioFieldOption } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import { HelperText } from '../helperText/helperText';
import { StyledInput, StyledRadio } from './radioFieldGroup.styles';

export interface RadioFieldGroupPropsInternal extends RadioFieldGroupProps {
  externalValue?: string;
  onUpdate?: (value: string | boolean) => void;
}

/**
 * Radio fields allow users to choose one option from a set of options.
 *
 * Use this component *outside forms* for inputs of `type`:
 * `radio`
 *
 * `RadioFieldGroup` options support a `wrapper` attribute for displaying individual inputs in a more complex UI if needed.
 * An example illustrating the usage of `options` with `wrappers` can be seen [here](/story/components-table-examples-radio-column--default).
 *
 * When using `RadioFieldGroup`'s option `wrapper` attribute, please keep in mind the following:
 *
 * Using `helperText` may disrupt a `wrapper`'s layout. Use the two together with caution.
 *
 * When ` option wrappers ` are not in use, the `RadioFieldGroup` is wrapped in a `fieldset` tag. The component's `margin` props are applied to this `fieldset`.
 * To avoid layout and semantic issues, this `fieldset` tag does not wrap the group when `wrappers` are in use, and so `margin` props will have no affect.
 *
 * To avoid layout issues, validation error messages are not displayed when `wrappers` are in use. You may display the error in question with an `ErrorBanner` in these circumstances.
 * Due to the mechanics of radio inputs, error messages do not generally display for `RadioFieldGroups` so this would really only be relevant if a custom `validate` function was applied.
 * When ` option wrappers` are not in use, the fields will still be validated according to applied rules.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.RadioFieldGroup`](/story/components-forms-form-radiofieldgroup--default).*
 */

export const RadioFieldGroupInternal = forwardRef<
  HTMLInputElement,
  RadioFieldGroupPropsInternal
>(
  (
    {
      autoFocus = false,
      children,
      className,
      defaultChecked,
      disabled = false,
      externalValue,
      helperText,
      id,
      labelHidden = false,
      legend,
      name,
      onBlur,
      onChange,
      onUpdate,
      options,
      optionWidth = 'auto',
      row = false,
      'data-testid': testId,
      ...props
    },
    forwardedRef
  ) => {
    const defaultOption = options.find(
      (option) => option.id === defaultChecked
    );
    const [radioValue, setRadioValue] = useState<RadioFieldOption | undefined>(
      defaultOption
    );

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== radioValue?.value) {
        const option = options.find((option) => option.value === externalValue);
        if (option) {
          setRadioValue(option);
          onUpdate && onUpdate(option.value);
        }
      }
    }, [externalValue, onUpdate, options, radioValue]);

    if (!options || options.length === 0) {
      return null;
    }

    const wrapperExists = options.find((option) => option.wrapper);

    return (
      <ConditionalWrapper
        condition={!wrapperExists}
        wrapper={(wrapperChildren) => (
          <Box
            as="fieldset"
            className={className}
            id={id}
            {...props}
            data-testid={testId}
          >
            {legend && (
              <Typography as="legend" mb={24}>
                {legend}
              </Typography>
            )}
            {wrapperChildren}
          </Box>
        )}
        data-testid={testId}
      >
        <ConditionalWrapper
          condition={!!row}
          wrapper={(wrapperChildren) => (
            <Flex flexDirection="row">{wrapperChildren}</Flex>
          )}
        >
          {options.map((option) => {
            return (
              <ConditionalWrapper
                key={option.id}
                condition={!!option.wrapper}
                wrapper={option.wrapper}
              >
                <FieldWrapper
                  disabled={disabled}
                  label={option.label}
                  labelHidden={labelHidden}
                  labelPosition="r"
                  name={option.id}
                  mb={!option.wrapper ? 24 : 0}
                  mr={row ? 24 : 0}
                  render={(renderProps) => (
                    <Box height={24} position="relative">
                      <StyledInput
                        autoFocus={
                          options[0].id === option.id ? autoFocus : false
                        }
                        disabled={disabled}
                        key={option.id}
                        id={option.id}
                        name={name}
                        checked={radioValue?.id === option.id}
                        onBlur={() => {
                          onBlur && onBlur(option.value);
                        }}
                        onChange={() => {
                          setRadioValue(option);
                          onChange && onChange(option.value);
                        }}
                        type="radio"
                        value={
                          typeof option.value === 'boolean'
                            ? option.id
                            : option.value
                        }
                        {...renderProps}
                      />
                      <StyledRadio role="presentation" />
                    </Box>
                  )}
                  data-testid={option.id}
                  width={optionWidth}
                ></FieldWrapper>
              </ConditionalWrapper>
            );
          })}
        </ConditionalWrapper>
        {!wrapperExists && children}
        {helperText && (
          <HelperText disabled={disabled} id={helperText && name}>
            {helperText}
          </HelperText>
        )}
      </ConditionalWrapper>
    );
  }
);

export const RadioFieldGroup = forwardRef<
  HTMLInputElement,
  RadioFieldGroupProps
>((props, ref) => <RadioFieldGroupInternal ref={ref} {...props} />);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* CSS Reset: https://www.joshwcomeau.com/css/custom-css-reset/ */\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\n* {\n  margin: 0;\n}\n\nhtml,\nbody {\n  height: 100%;\n}\n\nbody {\n  line-height: 1.5;\n  -webkit-font-smoothing: antialiased;\n  font-family: 'Jane Default', 'Source Sans Pro', Arial, Helvetica, sans-serif;\n}\n\ninput,\nbutton,\ntextarea,\nselect {\n  font: inherit;\n}\n\np,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  overflow-wrap: break-word;\n}\n\nfieldset,\nlegend,\nol,\nul {\n  margin: 0;\n  padding: 0;\n}\n\nli {\n  list-style-type: none;\n}\n\nfieldset {\n  border: none;\n}\n\n#XCBSrIHtXNkXFPQLMCHZ,\n#ZhTlxkCXS4syaTiw9Trg {\n  isolation: isolate;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/styles/reset.css"],"names":[],"mappings":"AAAA,iEAAiE;AACjE;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,4EAA4E;AAC9E;;AAEA;;;;EAIE,aAAa;AACf;;AAEA;;;;;;;EAOE,yBAAyB;AAC3B;;AAEA;;;;EAIE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,kBAAkB;AACpB","sourcesContent":["/* CSS Reset: https://www.joshwcomeau.com/css/custom-css-reset/ */\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\n* {\n  margin: 0;\n}\n\nhtml,\nbody {\n  height: 100%;\n}\n\nbody {\n  line-height: 1.5;\n  -webkit-font-smoothing: antialiased;\n  font-family: 'Jane Default', 'Source Sans Pro', Arial, Helvetica, sans-serif;\n}\n\ninput,\nbutton,\ntextarea,\nselect {\n  font: inherit;\n}\n\np,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  overflow-wrap: break-word;\n}\n\nfieldset,\nlegend,\nol,\nul {\n  margin: 0;\n  padding: 0;\n}\n\nli {\n  list-style-type: none;\n}\n\nfieldset {\n  border: none;\n}\n\n#root,\n#__next {\n  isolation: isolate;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "XCBSrIHtXNkXFPQLMCHZ",
	"__next": "ZhTlxkCXS4syaTiw9Trg"
};
export default ___CSS_LOADER_EXPORT___;

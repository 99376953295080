import { useLocation } from 'react-router-dom';

import {
  ArchiveIcon,
  Button,
  CopyIcon,
  Flex,
  List,
  MoreIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import { ArchiveWithConfirm } from './ArchiveWithConfirm';

export const ActionsMenuCell = ({
  onArchiveSpecial,
  isArchived,
  id,
}: {
  id: number;
  isArchived?: boolean;
  onArchiveSpecial: () => void;
}) => {
  const { pathname } = useLocation();
  const url = `${pathname}/duplicate/${id}`;

  return (
    <Popover
      label="menu"
      alignment={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      target={<Button.Icon icon={<MoreIcon />} />}
    >
      {() => (
        <Popover.Content>
          <List label="special-row-actions-list">
            <List.Item to={url}>
              <Flex gap={8}>
                <CopyIcon />
                <Typography>Duplicate</Typography>
              </Flex>
            </List.Item>
            {!isArchived && (
              <ArchiveWithConfirm
                onArchiveSpecial={onArchiveSpecial}
                archiveButton={(onArchiveClick) => (
                  <List.Item onClick={onArchiveClick}>
                    <Flex gap={8}>
                      <ArchiveIcon />
                      <Typography>Archive</Typography>
                    </Flex>
                  </List.Item>
                )}
              />
            )}
          </List>
        </Popover.Content>
      )}
    </Popover>
  );
};

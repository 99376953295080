import { Children } from 'react';
import type { ReactElement } from 'react';

import { Typography } from '../typography';
import { StyledOrderedList } from './breadcrumbs.styles';
import type { BreadcrumbsProps } from './breadcrumbs.types';

const Separator = () => (
  <Typography as="span" mx={8} color="text-light">
    &#47;
  </Typography>
);

/**
 * The breadcrumbs component renders a list of children with a separator between them.
 */
export const Breadcrumbs = ({
  children,
  className,
  'data-testid': testId,
  id,
  style,
}: BreadcrumbsProps) => {
  const arrayOfChildren = Children.toArray(children).map((child, i) => (
    <li key={`list-item-${i}`}>{child}</li>
  ));

  const withSeparator = arrayOfChildren.reduce(
    (prev: ReactElement[], curr: ReactElement, i) => {
      if (i < arrayOfChildren.length - 1) {
        return [...prev, curr, <Separator key={`separator-${i}`} />];
      } else {
        return [...prev, curr];
      }
    },
    []
  );

  return (
    <StyledOrderedList
      className={className}
      data-testid={testId}
      id={id}
      style={style}
    >
      {withSeparator}
    </StyledOrderedList>
  );
};

import styled from '@emotion/styled';
import type React from 'react';

import type { FormProps } from '../form';

interface StyledFormProps
  extends React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    Pick<FormProps, 'height' | 'width' | 'maxHeight' | 'maxWidth'> {}

export const StyledForm = styled.form<StyledFormProps>(
  ({ height = '100%', width, maxHeight, maxWidth }) => ({
    height,
    maxHeight,
    maxWidth,
    width,
  })
);

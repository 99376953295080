import { createContext } from 'react';

import type { ReeferBaseProps } from '../../types';
import { StyledSkeleton } from './skeleton.styles';

export interface SkeletonProps extends ReeferBaseProps {
  /** Animate all child Skeleton.Bones, defaults to `false` */
  animate?: boolean;

  /** Skeleton.Bones and any additional wrapping containers to create the required layout */
  children: React.ReactNode;

  /** Determines the flexDirection of the main Skeleton element */
  direction?: 'column' | 'row';

  /** Determines the height of the main Skeleton element, accepts any valid height property */
  height?: string | number;

  /** Determines the width of the main Skeleton element, accepts any valid width property */
  width?: string | number;
}

/**
 * Skeleton Context to pass 'animate' to Skeleton.Bones
 */

export const SkeletonContext = createContext({
  animate: true,
});

/**
 * Skeleton to build component load, save or error states
 */

export function Skeleton({
  animate = false,
  children,
  className,
  'data-testid': testId,
  direction = 'column',
  id,
  height = 'fit-content',
  style,
  width = '100%',
}: SkeletonProps) {
  return (
    <SkeletonContext.Provider value={{ animate }}>
      <StyledSkeleton
        className={className}
        direction={direction}
        height={height}
        id={id}
        width={width}
        role="presentation"
        style={style}
        data-testid={testId}
      >
        {children}
      </StyledSkeleton>
    </SkeletonContext.Provider>
  );
}

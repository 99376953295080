import type { ImageSizes } from '.';
import { mediaQueries } from '../../styling';

/**
 * srcWidths:
 * these values define the image sizes available to the DOM
 * along with the sizes defined below, the DOM will determine the best size of image to render
 */

export const defaultSrcWidths = [400, 600, 800, 1000, 1200, 1400, 1600];
export const cardSrcWidths = [200, 300];
export const largeCardSrcWidths = [200, 300, 400, 500];
export const thumbnailSrcWidths = [100, 200];

/**
 * sizes:
 * these values use media queries because they help the DOM to determine how the image will be rendered
 * and help determine which size is needed for the end user experience
 */

// uses default src widths, but the rendered size is always full width of the screen
export const heroSizes = '100vw';

// tablet size and below, the image is 100vw, else best resolution
export const defaultSizes = `${mediaQueries.tablet(
  'max'
)} 100vw, 600px, 800px, 1000px, 1200px, 1400px, 1600px`;

// mobile and below, render 200px width, else best resolution
export const cardSizes = `${mediaQueries.mobile('lg', 'max')} 200px, 300px`;

export const largeCardSizes = `${mediaQueries.mobile(
  'lg',
  'max'
)} 200px, 300px, 400px, 500px`;

// tablet and below, render 100px width, else best resolution
export const thumbnailSizes = `${mediaQueries.tablet('max')} 100px, 200px`;

interface GeneratedSizes {
  [size: ImageSizes]: { sizes: string; srcWidths: number[] };
}

export const IMAGE_SIZE_MAP: GeneratedSizes = {
  thumbnail: {
    sizes: thumbnailSizes,
    srcWidths: thumbnailSrcWidths,
  },
  card: {
    sizes: cardSizes,
    srcWidths: cardSrcWidths,
  },
  'card-lg': {
    sizes: largeCardSizes,
    srcWidths: largeCardSrcWidths,
  },
  default: {
    sizes: defaultSizes,
    srcWidths: defaultSrcWidths,
  },
  hero: {
    sizes: heroSizes,
    srcWidths: defaultSrcWidths,
  },
};

const IMAGE_SIZES = ['thumbnail', 'card', 'card-lg', 'default', 'hero'];

export const getImageSizesAndWidths = (sizes?: ImageSizes) => {
  if (!sizes) {
    return IMAGE_SIZE_MAP['default'];
  }

  if (sizes && IMAGE_SIZES.includes(sizes)) {
    return IMAGE_SIZE_MAP[sizes];
  }

  return {
    sizes,
    srcWidths: IMAGE_SIZE_MAP['default'].srcWidths,
  };
};

import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

import type { ReeferBaseProps } from '../../../types';
import { Banner } from '../../banner';
import { ErrorIcon } from '../../icon';

export interface FormErrorBannerProps extends ReeferBaseProps {
  /** Name used to refer to form level error - must match `Form` component `formErrorName` **/
  name?: string;
}

/**
 * Use `Form.ErrorBanner` to display a form level error in any position within a form.
 *
 * A form can only display one form level error at a time. A form level error is applied
 * when an error is raised `onSubmit`. (This managed within the `Form` component.) Form level errors
 * could arise for many reason, but the most common would server side validation errors.
 *
 * `FormErrorBanner` is not included by default within Reefer's `Form` component to give
 * the flexibility to position it at any position in the form, or to omit altogether when
 * appropriate. ie. You must add this subcomponent to the form yourself, just like like the form fields.
 *
 * When the form contains no other errors, its form level error will be removed and `FormErrorBanner`
 * will no longer be displayed.
 *
 * See [Handling Errors onSubmit Usage Docs](/story/components-forms-usage--docs#handling-errors-onsubmit)
 * for more details about how to use this component.
 */
export function FormErrorBanner({
  className,
  'data-testid': testId,
  id,
  name = 'form',
}: FormErrorBannerProps) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }: { message: string }) => (
        <Banner
          className={className}
          data-testid={testId}
          id={id}
          full
          icon={<ErrorIcon />}
          label={message}
          mt={16}
          mb={16}
          variant="error"
        />
      )}
    />
  );
}

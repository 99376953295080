import type { StoreSpecial } from '@jane/shared/models';

import { convert24HourTimeTo12HourTime } from './ScheduleTimeValidation';

export const hasScheduleOverrides = (schedule: any, isCreateMode?: boolean) => {
  if (isCreateMode) {
    return false;
  }

  const allDayEveryDay =
    schedule.enabled_sunday &&
    schedule.enabled_sunday_all_day &&
    schedule.enabled_monday &&
    schedule.enabled_monday_all_day &&
    schedule.enabled_tuesday &&
    schedule.enabled_tuesday_all_day &&
    schedule.enabled_wednesday &&
    schedule.enabled_wednesday_all_day &&
    schedule.enabled_thursday &&
    schedule.enabled_thursday_all_day &&
    schedule.enabled_friday &&
    schedule.enabled_friday_all_day &&
    schedule.enabled_saturday &&
    schedule.enabled_saturday_all_day;

  const anyScheduleOverrides =
    schedule.end_time_sunday ||
    schedule.end_time_monday ||
    schedule.end_time_tuesday ||
    schedule.end_time_wednesday ||
    schedule.end_time_thursday ||
    schedule.end_time_friday ||
    schedule.end_time_saturday ||
    schedule.start_time_sunday ||
    schedule.start_time_monday ||
    schedule.start_time_tuesday ||
    schedule.start_time_wednesday ||
    schedule.start_time_thursday ||
    schedule.start_time_friday ||
    schedule.start_time_saturday;

  return !allDayEveryDay || anyScheduleOverrides;
};

export const parseSchedule = (
  special?: StoreSpecial,
  isCreateMode?: boolean
) => {
  if (isCreateMode) {
    return {
      enabled_sunday: true,
      enabled_monday: true,
      enabled_tuesday: true,
      enabled_wednesday: true,
      enabled_thursday: true,
      enabled_friday: true,
      enabled_saturday: true,
      enabled_sunday_all_day: true,
      enabled_monday_all_day: true,
      enabled_tuesday_all_day: true,
      enabled_wednesday_all_day: true,
      enabled_thursday_all_day: true,
      enabled_friday_all_day: true,
      enabled_saturday_all_day: true,
      end_time_sunday: '',
      start_time_sunday: '',
      end_time_monday: '',
      start_time_monday: '',
      end_time_tuesday: '',
      start_time_tuesday: '',
      end_time_wednesday: '',
      start_time_wednesday: '',
      end_time_thursday: '',
      start_time_thursday: '',
      end_time_friday: '',
      start_time_friday: '',
      end_time_saturday: '',
      start_time_saturday: '',
      use_store_close_time_for_end: false,
    };
  }
  return {
    enabled_sunday: special?.schedule?.enabled_sunday || false,
    enabled_monday: special?.schedule?.enabled_monday || false,
    enabled_tuesday: special?.schedule?.enabled_tuesday || false,
    enabled_wednesday: special?.schedule?.enabled_wednesday || false,
    enabled_thursday: special?.schedule?.enabled_thursday || false,
    enabled_friday: special?.schedule?.enabled_friday || false,
    enabled_saturday: special?.schedule?.enabled_saturday || false,
    enabled_sunday_all_day:
      special?.schedule?.enabled_sunday_all_day ||
      (!special?.schedule?.end_time_sunday &&
        !special?.schedule?.start_time_sunday) ||
      false,
    enabled_monday_all_day:
      special?.schedule?.enabled_monday_all_day ||
      (!special?.schedule?.end_time_monday &&
        !special?.schedule?.start_time_monday) ||
      false,
    enabled_tuesday_all_day:
      special?.schedule?.enabled_tuesday_all_day ||
      (!special?.schedule?.end_time_tuesday &&
        !special?.schedule?.start_time_tuesday) ||
      false,
    enabled_wednesday_all_day:
      special?.schedule?.enabled_wednesday_all_day ||
      (!special?.schedule?.end_time_wednesday &&
        !special?.schedule?.start_time_wednesday) ||
      false,
    enabled_thursday_all_day:
      special?.schedule?.enabled_thursday_all_day ||
      (!special?.schedule?.end_time_thursday &&
        !special?.schedule?.start_time_thursday) ||
      false,
    enabled_friday_all_day:
      special?.schedule?.enabled_friday_all_day ||
      (!special?.schedule?.end_time_friday &&
        !special?.schedule?.start_time_friday) ||
      false,
    enabled_saturday_all_day:
      special?.schedule?.enabled_saturday_all_day ||
      (!special?.schedule?.end_time_saturday &&
        !special?.schedule?.start_time_saturday) ||
      false,
    end_time_sunday:
      convert24HourTimeTo12HourTime(special?.schedule?.end_time_sunday) || '',
    end_time_monday:
      convert24HourTimeTo12HourTime(special?.schedule?.end_time_monday) || '',
    end_time_tuesday:
      convert24HourTimeTo12HourTime(special?.schedule?.end_time_tuesday) || '',
    end_time_wednesday:
      convert24HourTimeTo12HourTime(special?.schedule?.end_time_wednesday) ||
      '',
    end_time_thursday:
      convert24HourTimeTo12HourTime(special?.schedule?.end_time_thursday) || '',
    end_time_friday:
      convert24HourTimeTo12HourTime(special?.schedule?.end_time_friday) || '',
    end_time_saturday:
      convert24HourTimeTo12HourTime(special?.schedule?.end_time_saturday) || '',
    start_time_sunday:
      convert24HourTimeTo12HourTime(special?.schedule?.start_time_sunday) || '',
    start_time_monday:
      convert24HourTimeTo12HourTime(special?.schedule?.start_time_monday) || '',
    start_time_tuesday:
      convert24HourTimeTo12HourTime(special?.schedule?.start_time_tuesday) ||
      '',
    start_time_wednesday:
      convert24HourTimeTo12HourTime(special?.schedule?.start_time_wednesday) ||
      '',
    start_time_thursday:
      convert24HourTimeTo12HourTime(special?.schedule?.start_time_thursday) ||
      '',
    start_time_friday:
      convert24HourTimeTo12HourTime(special?.schedule?.start_time_friday) || '',
    start_time_saturday:
      convert24HourTimeTo12HourTime(special?.schedule?.start_time_saturday) ||
      '',
    use_store_close_time_for_end:
      special?.use_store_close_time ||
      special?.schedule?.use_store_close_time_for_end ||
      false,
  };
};

import { Card as BaseCard } from './card';
import type {
  CardActionProps,
  CardContentProps,
  CardGroupProps,
  CardImageProps,
  CardProps,
} from './card.types';
import { CardAction } from './cardAction/cardAction';
import { CardContent } from './cardContent/cardContent';
import { CardGroup } from './cardGroup/cardGroup';
import { CardImage } from './cardImage/cardImage';

export type {
  CardActionProps,
  CardContentProps,
  CardGroupProps,
  CardImageProps,
  CardProps,
};

export const Card = Object.assign(BaseCard, {
  Action: CardAction,
  Content: CardContent,
  Group: CardGroup,
  Image: CardImage,
});

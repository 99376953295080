/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Subtract16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8C14 8.55228 13.5523 9 13 9L3 9C2.44772 9 2 8.55228 2 8C2 7.44772 2.44771 7 3 7L13 7C13.5523 7 14 7.44772 14 8Z"
      fill="#0E0E0E"
    />
  </svg>
);

const Subtract24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  sm: Subtract16,
  md: Subtract24,
  lg: Subtract24,
  xl: Subtract24,
  xxl: Subtract24,
  xxxl: Subtract24,
};

export const SubtractIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'subtract-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

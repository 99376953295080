/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Blossom24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13041_31279)">
      <ellipse cx={6} cy={12.0005} rx={6} ry={3.38824} fill="#4485DA" />
      <ellipse cx={18} cy={12.0005} rx={6} ry={3.38824} fill="#4485DA" />
      <ellipse
        cx={11.9999}
        cy={6.00049}
        rx={6}
        ry={3.38824}
        transform="rotate(90 11.9999 6.00049)"
        fill="#4485DA"
      />
      <ellipse
        cx={11.9999}
        cy={18.0005}
        rx={6}
        ry={3.38824}
        transform="rotate(90 11.9999 18.0005)"
        fill="#4485DA"
      />
      <ellipse
        cx={16.2426}
        cy={7.75793}
        rx={6}
        ry={3.38824}
        transform="rotate(135 16.2426 7.75793)"
        fill="#4485DA"
      />
      <ellipse
        cx={7.75751}
        cy={16.2428}
        rx={6}
        ry={3.38824}
        transform="rotate(135 7.75751 16.2428)"
        fill="#4485DA"
      />
      <ellipse
        cx={16.2426}
        cy={16.2429}
        rx={6}
        ry={3.38824}
        transform="rotate(-135 16.2426 16.2429)"
        fill="#4485DA"
      />
      <ellipse
        cx={7.75745}
        cy={7.75751}
        rx={6}
        ry={3.38824}
        transform="rotate(-135 7.75745 7.75751)"
        fill="#4485DA"
      />
      <circle cx={12} cy={12.0005} r={3.27273} fill="#FFC220" />
    </g>
    <defs>
      <clipPath id="clip0_13041_31279">
        <rect
          width={24}
          height={24}
          fill="white"
          transform="translate(0 0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Blossom24,
  sm: Blossom24,
  lg: Blossom24,
  xl: Blossom24,
  xxl: Blossom24,
  xxxl: Blossom24,
};

export const BlossomIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'blossom-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

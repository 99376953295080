import debounce from 'lodash/debounce';
import type { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { SearchIcon } from '../../../icon';
import type { SearchFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import {
  DismissFilledIconButton,
  SearchIconWrapper,
  StyledInput,
  StyledInputWrapper,
} from './searchField.styles';

export interface SearchFieldPropsInternal extends SearchFieldProps {
  onUpdate?: (value: string) => void;
  value?: string;
}

export const SearchFieldInternal = forwardRef<
  HTMLInputElement,
  SearchFieldPropsInternal
>(
  (
    {
      autocomplete,
      autoFocus = false,
      borderRadius = 'sm',
      children,
      className,
      debounceDelay = 300,
      defaultValue = '',
      disabled = false,
      disableMobileInputStyling = false,
      enterKeyHint,
      helperText,
      hideSearchIcon = false,
      id,
      isDebounced = true,
      label,
      labelHidden = true,
      name,
      onBlur,
      onChange,
      onEnterPress,
      onFocus,
      onUpdate,
      placeholder,
      'data-testid': testId,
      width = 'auto',
      value: externalValue,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== value) {
        setValue(externalValue);
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, isDebounced]);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      onFocus && onFocus(event.target.value);
      setFocused(true);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(event.target.value);
      setValue(event.target.value);
      setFocused(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.target.value);
      setValue(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (onEnterPress && event.key === 'Enter') {
        onEnterPress();
      }
    };

    const debouncedSearch = useMemo(
      () =>
        debounce((query) => {
          onChange && onChange(query);
        }, debounceDelay),
      [onChange, debounceDelay]
    );

    const handleDebouncedChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(event.target.value);
        setValue(event.target.value);
      },
      [debouncedSearch]
    );

    const handleClear = () => {
      onChange && onChange('');
      setValue('');
    };

    return (
      <FieldWrapper
        className={className}
        disabled={disabled}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        labelPosition="t"
        name={name}
        isWrappingTextInput
        disableMobileInputStyling={disableMobileInputStyling}
        render={(renderProps) => (
          <StyledInputWrapper>
            {!hideSearchIcon && (
              <SearchIconWrapper
                disableMobileInputStyling={disableMobileInputStyling}
              >
                <SearchIcon
                  mr={4}
                  color={focused ? 'primary' : 'grays-black'}
                />
              </SearchIconWrapper>
            )}

            <StyledInput
              autoComplete={autocomplete}
              autoFocus={autoFocus}
              disabled={disabled}
              enterKeyHint={enterKeyHint}
              hideSearchIcon={hideSearchIcon}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={isDebounced ? handleDebouncedChange : handleChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              radiusSize={borderRadius}
              ref={ref}
              type="search"
              value={value}
              disableMobileInputStyling={disableMobileInputStyling}
              {...renderProps}
            />
            {value.length > 0 && (
              <DismissFilledIconButton
                type="button"
                tabIndex={0}
                onClick={handleClear}
                aria-label="Clear search input"
              />
            )}
          </StyledInputWrapper>
        )}
        data-testid={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

/**
 * Search fields allow users to enter text into a UI to perform a search.
 *
 * Use this component *outside forms* for inputs of `type="search"`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms,
 * see [`Form.SearchField`](/story/components-forms-form-searchfield--default).*
 */
export const SearchField = forwardRef<
  HTMLInputElement,
  SearchFieldProps & { value?: string }
>((props, ref) => <SearchFieldInternal ref={ref} {...props} />);

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { TypeAhead as TypeAheadV1 } from '@jane/shared/reefer-v1';
import { TypeAhead as TypeAheadV2 } from '@jane/shared/reefer-v2';
import type { TypeAheadProps } from '@jane/shared/reefer-v2';

export const TypeAhead = (props: TypeAheadProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TypeAheadV2 {...props} />;
  }
  return <TypeAheadV1 {...props} />;
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Info24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11706_30040)">
      <path
        d="M14.1311 7.6C14.0191 8.272 13.5871 8.656 12.6271 8.656C11.6671 8.656 11.3151 8.24 11.3151 7.68C11.3151 7.568 11.3311 7.424 11.3951 7.04C11.5071 6.368 11.9391 5.984 12.8991 5.984C13.8591 5.984 14.2111 6.4 14.2111 6.96C14.2111 7.072 14.1951 7.216 14.1311 7.6Z"
        fill="#0E0E0E"
      />
      <path
        d="M15.0431 16.352C13.9711 17.568 13.0751 18.192 11.9711 18.192C10.6591 18.192 9.98714 17.44 9.98714 16.272C9.98714 15.92 10.0191 15.648 10.1471 14.992L10.7871 11.568H8.29114L8.59514 9.744H13.4591L12.2271 16.304L12.4991 16.384L14.7391 14.096L16.0191 15.232L15.0431 16.352Z"
        fill="#0E0E0E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="#0E0E0E"
      />
    </g>
    <defs>
      <clipPath id="clip0_11706_30040">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Info24,
  sm: Info24,
  lg: Info24,
  xl: Info24,
  xxl: Info24,
  xxxl: Info24,
};

export const InfoIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'info-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

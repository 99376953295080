import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { JaneLogo as JaneLogoV1 } from '@jane/shared/reefer-v1';
import { JaneLogo as JaneLogoV2 } from '@jane/shared/reefer-v2';
import type { JaneLogoProps } from '@jane/shared/reefer-v2';

export const JaneLogo = (props: JaneLogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <JaneLogoV2 {...props} />;
  }
  return <JaneLogoV1 {...props} />;
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Move24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7128 0.712815C12.3223 0.322291 11.6891 0.32229 11.2986 0.712815L8.71281 3.2986C8.08285 3.92857 8.52902 5.00571 9.41992 5.00571H11.0057V11.0057H5.00571L5.00571 9.41992C5.00571 8.52902 3.92856 8.08285 3.2986 8.71282L0.712815 11.2986C0.322291 11.6891 0.322291 12.3223 0.712815 12.7128L3.2986 15.2986C3.92856 15.9286 5.00571 15.4824 5.00571 14.5915L5.00571 13.0057H11.0057V19.0057H9.41992C8.52902 19.0057 8.08285 20.0829 8.71282 20.7128L11.2986 23.2986C11.6891 23.6891 12.3223 23.6891 12.7128 23.2986L15.2986 20.7128C15.9286 20.0829 15.4824 19.0057 14.5915 19.0057H13.0057V13.0057H19.0057V14.5915C19.0057 15.4824 20.0829 15.9286 20.7128 15.2986L23.2986 12.7128C23.6891 12.3223 23.6891 11.6891 23.2986 11.2986L20.7128 8.71282C20.0828 8.08285 19.0057 8.52902 19.0057 9.41992V11.0057H13.0057V5.00571H14.5915C15.4824 5.00571 15.9286 3.92857 15.2986 3.2986L12.7128 0.712815Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Move24,
  sm: Move24,
  lg: Move24,
  xl: Move24,
  xxl: Move24,
  xxxl: Move24,
};

export const MoveIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'move-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

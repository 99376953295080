import styled from '@emotion/styled';

import type { TabDirection } from './tabs';

interface StyledTabsProps {
  direction?: TabDirection;
  full?: boolean;
}

export const StyledTabs = styled.div<StyledTabsProps>(
  ({ full, direction }) => ({
    '&:hover': {
      overflowX: 'auto',
      scrollbarWidth: 'thin',
    },
    display: 'flex',
    flexDirection: direction === 'horizontal' ? 'row' : 'column',
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    scrollbarWidth: 'none',
    width: full ? '100%' : 'auto',
  })
);

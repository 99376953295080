/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Sleep24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C12 0.89543 12.8954 0 14 0H22C22.7688 0 23.4696 0.440691 23.8026 1.13362C24.1357 1.82655 24.042 2.64905 23.5617 3.24939L18.1613 10H22C23.1046 10 24 10.8954 24 12C24 13.1046 23.1046 14 22 14H14C13.2312 14 12.5304 13.5593 12.1974 12.8664C11.8644 12.1734 11.958 11.3509 12.4383 10.7506L17.8388 4H14C12.8954 4 12 3.10457 12 2ZM1.15218e-05 14C1.15218e-05 12.8954 0.895442 12 2.00001 12H8.00001C8.75756 12 9.45008 12.428 9.78887 13.1056C10.1277 13.7831 10.0545 14.594 9.60001 15.2L6.00001 20H8.00001C9.10458 20 10 20.8954 10 22C10 23.1046 9.10458 24 8.00001 24H2.00001C1.24247 24 0.549941 23.572 0.211157 22.8944C-0.127627 22.2169 -0.054515 21.406 0.400012 20.8L4.00001 16H2.00001C0.895442 16 1.15218e-05 15.1046 1.15218e-05 14Z"
      fill="#5E1FF0"
    />
  </svg>
);

const SIZE_MAP = {
  md: Sleep24,
  sm: Sleep24,
  lg: Sleep24,
  xl: Sleep24,
  xxl: Sleep24,
  xxxl: Sleep24,
};

export const SleepIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sleep-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import {
  useFetchCurrentBloomMenu,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import { businessPaths } from '@jane/business-admin/util';
import { useNavigateBack } from '@jane/shared/hooks';
import {
  AddIcon,
  Button,
  ChevronDownIcon,
  ChevronLeftIcon,
  Flex,
  Form,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { PlusSubMenuItems } from './PlusSubMenuItems';

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

interface ContentContainerProps {
  hasMenuItems: boolean;
}

const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  gap: ${({ hasMenuItems }) =>
    hasMenuItems
      ? '4px'
      : '24px'}; // 4px instead of 16px to account for an empty space above the form field
  flex: 1 0 0;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
`;

const EmptyStateContainer = styled.div`
  display: flex;
  padding: 28px 0px;
  justify-content: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px dashed #dadada;
  background: #f7f7f7;
`;

const LabelFieldContainer = styled.div`
  width: 152px;
`;

const LabelField = styled(Form.TextField)`
  width: 152px;
  div {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const UrlFieldContainer = styled.div`
  width: 100%;
`;

const UrlField = styled(Form.TextField)`
  div {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }

  &:focus-within {
    div {
      div:last-child {
        border-left: 1px solid #5e1ff0;
      }
    }
  }
`;

const AddSubItemButton = styled(Button)`
  div {
    font-weight: normal;
  }
`;

const AddMenuItemButtonContainer = styled.div`
  width: 170px;
`;
const AddMenuItemContainer = styled.div`
  cursor: pointer;
`;

type FormValues = {
  menuItems: {
    destination?: string;
    label: string;
    links?: {
      destination: string;
      label: string;
    }[];
  }[];
};

export const PlusHamburgerMenuSettings = () => {
  const [addSubItemClicked, setAddSubItemClicked] = useState<number | null>(
    null
  );
  const goBack = useNavigateBack(businessPaths.plus());

  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const { mutate: updateBloomMenu } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );

  const {
    control,
    reset,
    formState: { isValid, isDirty },
    handleSubmit,
    watch,
  } = useForm<FormValues>();

  const { fields, append } = useFieldArray({
    control,
    name: 'menuItems',
  });

  const toast = useToast();

  const publish = (data: FormValues) => {
    updateBloomMenu(
      {
        theme: {
          ...bloomMenuQuery.data?.theme?.bloom,
          hamburger_menu: data.menuItems,
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          toast.add({
            label: 'Your settings have been saved!',
            variant: 'success',
          });
          reset({ menuItems: data.menuItems });
        },
      }
    );
  };
  const onPreview = () => {
    console.log('preview');
  };
  const onMenuAdd = () => {
    append({ label: '', destination: '', links: [] });
  };
  const onSubmit = () => {
    console.log('submit');
  };

  const isDisabled = true;
  const isLoading = false;

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      reset({
        menuItems: bloomMenuQuery.data.theme.bloom?.hamburger_menu || [],
      });
    }
  }, [
    bloomMenuQuery.data?.theme.bloom?.hamburger_menu,
    bloomMenuQuery.isSuccess,
    reset,
  ]);

  const menuItems = watch(`menuItems`);

  return (
    <Flex flexDirection="column" minHeight="100vh">
      <BorderedFlex p={16} gap={16} flexDirection="row" alignItems="center">
        <ChevronLeftIcon onClick={() => goBack()} altText="Go back" />
        <Typography variant="header-bold" branded>
          Hamburger menu settings
        </Typography>
        <Flex ml="auto" flexDirection="row">
          <Button
            ml="auto"
            mr={16}
            label="Preview"
            type="button"
            variant="secondary"
            onClick={onPreview}
            disabled={isDisabled}
            loading={isLoading}
          />
          <Button
            ml="auto"
            label="Publish"
            type="submit"
            variant="primary"
            disabled={fields.length === 0 || !isValid || !isDirty}
            loading={isLoading}
            onClick={handleSubmit(publish)}
          />
        </Flex>
      </BorderedFlex>
      <Flex px={40} pt={24} pb={48} gap={24} flexDirection="column">
        <Typography variant="body">
          Set up the items that will appear in the hamburger menu. Drag each
          menu option into the order you prefer.
        </Typography>
        <ContentContainer hasMenuItems={fields.length > 0}>
          <AddMenuItemButtonContainer>
            <Button
              label="Add menu item"
              type="button"
              variant="secondary"
              onClick={onMenuAdd}
              startIcon={<AddIcon size="sm" />}
            />
          </AddMenuItemButtonContainer>
          {fields.length === 0 ? (
            <EmptyStateContainer>
              <Typography variant="body" color="grays-mid">
                Your menu is empty
              </Typography>
            </EmptyStateContainer>
          ) : (
            <Flex flexDirection="column" gap={8}>
              <Form name="url-form" onSubmit={onSubmit}>
                <Flex gap={4} flexDirection="column">
                  {fields.map((item, idx) => {
                    return (
                      <div key={item.id}>
                        <Flex alignItems="center">
                          <Controller
                            name={`menuItems.${idx}.label`}
                            control={control}
                            rules={{ required: true, pattern: /\S+/ }}
                            render={({ field }) => (
                              <LabelFieldContainer>
                                <LabelField
                                  startIcon={
                                    menuItems[idx].links?.length ? (
                                      <ChevronDownIcon
                                        color="purple"
                                        size="sm"
                                        mr={12}
                                      />
                                    ) : null
                                  }
                                  name={`menuItems.${idx}.label`}
                                  label=""
                                  defaultValue={field.value}
                                  placeholder="Add label"
                                  onChange={(value) => field.onChange(value)}
                                />
                              </LabelFieldContainer>
                            )}
                          />
                          <Controller
                            name={`menuItems.${idx}.destination`}
                            control={control}
                            render={({ field }) => (
                              <UrlFieldContainer>
                                <UrlField
                                  label=""
                                  defaultValue={field.value}
                                  name={`menuItems.${idx}.destination`}
                                  placeholder="Add URL"
                                  width="100%"
                                  onChange={(value) => field.onChange(value)}
                                />
                              </UrlFieldContainer>
                            )}
                          />
                          <AddSubItemButton
                            variant="primary-inverse"
                            label="Add sub-menu item"
                            onClick={() => setAddSubItemClicked(idx)}
                            mt={16}
                            ml={8}
                          />
                        </Flex>
                        <PlusSubMenuItems
                          parentIndex={idx}
                          control={control}
                          addSubItemClicked={addSubItemClicked}
                          setAddSubItemClicked={setAddSubItemClicked}
                        />
                      </div>
                    );
                  })}
                </Flex>
              </Form>
              <Flex mt={16}>
                <AddMenuItemContainer onClick={onMenuAdd}>
                  <Typography variant="body" color="purple">
                    Add menu item
                  </Typography>
                </AddMenuItemContainer>
              </Flex>
            </Flex>
          )}
        </ContentContainer>
      </Flex>
    </Flex>
  );
};

import * as t from 'io-ts';

import { tApplicabilityRules } from '@jane/shared/models';
import type { MenuProduct } from '@jane/shared/models';

export type MenuProductToPreview = MenuProduct & {
  kind_subtype: string | null;
  subcategory: string | null;
};

const tMenuRowOptional = t.partial({
  applicability_rules: t.array(tApplicabilityRules),
  custom_display_name: t.union([t.string, t.null]),
  enabled: t.boolean,
  id: t.number,
  is_category: t.boolean,
  menu_products_ids: t.type({
    all: t.array(t.number),
    in_stock: t.array(t.number),
  }),
  // TODO: Should probably be tMenuProduct type, but there are io-ts validation errors
  menu_products_to_preview: t.array(t.any),
  roots_only: t.boolean,
  store_id: t.number,
});

const tMenuRowRequired = t.interface({
  ranking: t.number,
  row_type: t.string,
});

export const tMenuRow = t.intersection([tMenuRowOptional, tMenuRowRequired]);
export type MenuRow = t.TypeOf<typeof tMenuRow>;

const tCustomizableType = t.union([
  t.literal('ProductType'),
  t.literal('Lineage'),
]);

const tCustomLabelRequired = t.interface({
  customizable_type: tCustomizableType,
  customizable_id: t.number,
  custom_label: t.string,
});

const tCustomLabel = t.intersection([
  t.partial({
    id: t.number,
    product_type: t.union([t.string, t.undefined]),
    product_subtype: t.union([t.string, t.undefined]),
  }),
  tCustomLabelRequired,
]);

export type CustomLabel = t.TypeOf<typeof tCustomLabel>;

const tReservationModeLabelRequired = t.interface({
  pickup_label: t.string,
  delivery_label: t.string,
  curbside_label: t.string,
});

const tReservationLabels = t.intersection([
  t.partial({ id: t.union([t.number, t.undefined]) }),
  tReservationModeLabelRequired,
]);

export type ReservationModeLabels = t.TypeOf<typeof tReservationLabels>;

const tDisabledFeelingsRequired = t.interface({ tag_id: t.number });
const tDisabledFeelings = t.intersection([
  t.partial({ id: t.number }),
  tDisabledFeelingsRequired,
]);

export type DisabledFeelings = t.TypeOf<typeof tDisabledFeelings>;

const tComplianceLanguageSettings = t.interface({
  specials: t.string,
  bundle: t.string,
  sales_badge: t.string,
});
export type ComplianceLanguageSettings = t.TypeOf<
  typeof tComplianceLanguageSettings
>;

const tFiltersAndLabels = t.interface({
  compliance_language_settings_labels: tComplianceLanguageSettings,
  reservation_mode_labels: tReservationLabels,
  custom_labels: t.array(tCustomLabel),
  disabled_feeling_and_activity_tags: t.array(tDisabledFeelings),
});

export type FiltersAndLabels = t.TypeOf<typeof tFiltersAndLabels>;

const tCommsBanner = t.partial({
  alt_text: t.string,
  banner_type: t.union([t.literal('image'), t.literal('text')]),
  button_fill_color: t.string,
  button_link: t.string,
  button_text: t.string,
  button_text_color: t.string,
  created_at: t.string,
  enabled: t.boolean,
  header_text_color: t.string,
  id: t.number,
  image: t.string,
  image_opacity: t.number,
  is_expanded: t.boolean,
  message: t.string,
  store_id: t.number,
  title: t.string,
  updated_at: t.string,
});

export type CommunicationBanner = t.TypeOf<typeof tCommsBanner>;

const tViewDefaultType = t.union([
  t.literal('grid_view'),
  t.literal('list_view'),
]);

// TODO: these will eventually be stripped from the payload.
const tAppearanceOptionalFields = t.partial({
  white_label: t.boolean,
  menu_filter_setting: t.interface({
    id: t.number,
    store_id: t.number,
    collapse_desktop: t.boolean,
  }),
});

const tAppearanceRequiredField = t.interface({
  white_label_theme_color: t.string,
  white_label_nav_color: t.string,
  view_default: tViewDefaultType,
  pdp_hide_description: t.boolean,
  pdp_hide_effects: t.boolean,
});

const tAppearance = t.intersection([
  tAppearanceRequiredField,
  tAppearanceOptionalFields,
]);

export type Appearance = t.TypeOf<typeof tAppearance>;

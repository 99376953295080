// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OxlkSdYI6kE3lHyq8eKs {\n  border-radius: var(--image-border-radius);\n  display: flex;\n  position: relative;\n  overflow: hidden;\n  flex-shrink: 0;\n  height: var(--image-height);\n  width: var(--image-width);\n}\n\n.OxlkSdYI6kE3lHyq8eKs:before {\n  position: absolute;\n  content: '';\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n\n.PFhIV1EScL8ftQDPAo9t {\n  border: 1px inset rgba(0, 0, 0, 0.05);\n}\n\n.rSl5H7vnwEAMsXGo_XQZ {\n  aspect-ratio: var(--image-aspect-ratio);\n  height: 100%;\n  object-fit: var(--image-object-fit);\n  object-position: var(--image-object-position);\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/image/image.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,uCAAuC;EACvC,YAAY;EACZ,mCAAmC;EACnC,6CAA6C;EAC7C,WAAW;AACb","sourcesContent":[".image {\n  border-radius: var(--image-border-radius);\n  display: flex;\n  position: relative;\n  overflow: hidden;\n  flex-shrink: 0;\n  height: var(--image-height);\n  width: var(--image-width);\n}\n\n.image:before {\n  position: absolute;\n  content: '';\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n\n.image__border {\n  border: 1px inset rgba(0, 0, 0, 0.05);\n}\n\n.image_img {\n  aspect-ratio: var(--image-aspect-ratio);\n  height: 100%;\n  object-fit: var(--image-object-fit);\n  object-position: var(--image-object-position);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "OxlkSdYI6kE3lHyq8eKs",
	"image__border": "PFhIV1EScL8ftQDPAo9t",
	"image_img": "rSl5H7vnwEAMsXGo_XQZ"
};
export default ___CSS_LOADER_EXPORT___;

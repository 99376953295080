import { createContext, useContext } from 'react';

import type { PopoverContextProps } from '../popover.types';

/**
 * Popover context hook is made available to allow child elements to
 * control when the popover is closed.
 */

export const PopoverContext = createContext<PopoverContextProps>({
  closePopover: () => {
    return;
  },
  isOpen: false,
});

export const usePopoverContext = () => useContext(PopoverContext);

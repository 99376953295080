import * as t from 'io-ts';

export const tBoostConfig = t.intersection([
  t.interface({
    id: t.number,
    favicon_url: t.string,
    show_age_gate: t.boolean,
    disable_jane_title_and_description_meta: t.boolean,
    google_sso_client_id: t.union([t.string, t.null]),
    google_sso_client_secret: t.union([t.string, t.null]),
    custom_product_detail_title_suffix: t.string,
    integration_method: t.string,
    legacy_id: t.union([t.number, t.null]),
  }),
  t.partial({
    boost_menu_id: t.number,
    partner_menu_url: t.union([t.string, t.null]),
    sitemap_url: t.string,
    header: t.string,
    footer: t.string,
    page_head: t.string,
    enabled: t.boolean,
  }),
  t.partial({
    boost_menu_id: t.number,
    preview_template_url: t.string,
    preview_template_html: t.string,
    published_template_url: t.string,
    published_template_html: t.string,
    host: t.string,
    path: t.string,
    content_selector: t.string,
    google_search_console_api_key: t.string,
    google_search_console_api_secret: t.string,
  }),
]);
export type BoostConfig = t.TypeOf<typeof tBoostConfig>;

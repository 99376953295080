import { useIsMutating } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import omit from 'lodash/omit';
import pluralise from 'pluralise';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useCreateSpecial,
  useFetchStoreSpecial,
  useStoreSettings,
  useUpdateSpecial,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useHasPermissions,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import {
  SpecialsModalProvider,
  StoreDetailsContext,
} from '@jane/business-admin/providers';
import { SpecialStatus } from '@jane/business-admin/types';
import {
  EventNames,
  MODAL_CARD_WIDTH,
  ModalNames,
  businessPaths,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import type { SpecialSchedule, StoreSpecial } from '@jane/shared/models';
import {
  Card,
  ErrorIcon,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';
import { RULE_OPTIONS } from '@jane/shared/util';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import { SwitchWithBorder } from '../../../../SwitchWithBorder';
import { DetailsCard } from './DetailsCard';
import { ImagesCard } from './ImagesCard';
import { PromoCodeCard } from './PromoCodeCard';
import { ConditionsCard } from './conditions/ConditionsCard';
import {
  convertConditionsToRules,
  convertRulesToConditions,
} from './conditions/utils/bundleTypeHelpers';
import {
  SHOW_PROMO_CODE_SPECIAL_TYPES,
  getApplyToSelection,
  parseRulesFromApplyTo,
} from './form';
import { ScheduleCard } from './schedule/ScheduleCard';
import {
  convert12HourTimeTo24HourTime,
  convert24HourTimeTo12HourTime,
} from './schedule/ScheduleTimeValidation';
import {
  hasScheduleOverrides,
  parseSchedule,
} from './schedule/scheduleHelpers';

dayjs.extend(utc);
dayjs.extend(timezone);

const FORM_ERROR_NAME = 'special-edit-error';

export const SpecialsModal = () => {
  const { storeId, storeName } = useContext(StoreDetailsContext);
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [headerError, setHeaderError] = useState<string>();
  const { special_id: specialId = '' } = useParams<'special_id'>();
  const previousStatus = localStorage.getItem('currentSpecialStatus');
  const isCreateMode = specialId === 'create';

  const { pathname } = useLocation();
  const isDuplicateMode = pathname.includes('duplicate');

  const genericErrorMessage = `Error ${
    isDuplicateMode ? 'duplicating' : isCreateMode ? 'creating' : 'updating'
  } special. Please try again.`;

  const navigate = useNavigate();
  const toast = useToast();

  const { data, isFetching: specialLoading } = useFetchStoreSpecial(
    storeId,
    specialId,
    isCreateMode
  );
  const special = useMemo(() => data?.special, [data]);
  // Navigate and show error if there is no special when loading is finished
  useEffect(() => {
    if (!isCreateMode && !specialLoading && !special) {
      toast.add({
        label: 'Special could not be found.',
        variant: 'error',
      });
      navigate(
        businessPaths.storeSpecials(
          storeId,
          (previousStatus as SpecialStatus) || SpecialStatus.live
        )
      );
      setIsOpen(false);
    }
  }, [specialLoading, special]);

  const {
    mutateAsync: createSpecial,
    isLoading: isCreating,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useCreateSpecial(storeId);
  const {
    mutateAsync: updateSpecial,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateSpecial(storeId, specialId);

  useEffect(() => {
    if (specialId) setIsOpen(true);
  }, [specialId]);

  const userCanEditSpecials = useHasPermissions([Permission.EditSpecials]);

  const catchSubmitErrors = useCatchErrorsWithManager(genericErrorMessage);

  const fullEndDate = special?.end_time ? special?.end_time.split(' ') : [];
  const fullStartDate = special?.start_time
    ? special?.start_time.split(' ')
    : [];
  const [endDate, endTime] = fullEndDate;
  const [startDate, startTime] = fullStartDate;

  const getRules = (special?: StoreSpecial) => {
    if (isCreateMode) {
      return {
        required_rules: {
          apply_to: RULE_OPTIONS[0].value,
          rules: {},
        },
        default_rules: {
          rules: {},
          apply_to: RULE_OPTIONS[0].value,
        },
        discounted_rules: {
          apply_to: RULE_OPTIONS[0].value,
          rules: {},
        },
      };
    }

    if (special?.special_type === 'bundle') {
      // If special is bundle, convert bundle conditions to rules so our Conditions UI can handle it
      return convertConditionsToRules(special.conditions.bundle);
    } else {
      return {
        default_rules: {
          rules: special?.rules,
          apply_to: getApplyToSelection(special?.rules),
        },
      };
    }
  };

  const getBundleDisplay = (special?: StoreSpecial) => {
    if (isCreateMode) {
      return {
        threshold_number_of_items_in_cart: isCreateMode && 1, // Minimum products requirement
        max_number_of_discounted_products: 1, // Max products discounted
        settings: {
          allow_discounts_on_required_products: false, // Allow qualifying products to be discounted
        },
        max_applications_per_cart: 1, // Max uses per order
      };
    }

    if (special?.special_type === 'bundle') {
      return {
        threshold_number_of_items_in_cart:
          special?.conditions?.bundle?.independent
            ?.threshold_number_of_items_in_cart,
        settings: special?.conditions?.bundle?.settings,
        max_number_of_discounted_products:
          special?.conditions?.bundle?.dependent
            ?.max_number_of_discounted_products,
        max_applications_per_cart:
          special?.conditions?.bundle?.max_applications_per_cart,
      };
    }
    return {};
  };

  const fetchedSpecialValues = {
    name: special?.title || '',
    special_type: special?.special_type || '',
    conditions: special?.conditions || {},
    discount_dollar_amount: special?.discount_dollar_amount || '',
    discount_percent: special?.discount_percent || '',
    discount_target_price: special?.discount_target_price || '',
    discount_type: special?.discount_type || '',
    enabled: !!special?.enabled,
    description: special?.description || '',
    reservation_modes: special?.reservation_modes,
    stacking_setting: special?.stacking_setting,
    terms: special?.terms || '',
    photo:
      special?.photo && special.photo['urls']['small']
        ? [special.photo['urls']['small']]
        : undefined,
    promo_code: special?.promo_code,
    multiple_use_promo_code:
      special?.promo_code && special?.multiple_use_promo_code,
    promo_code_max_number_of_uses:
      special?.promo_code_max_number_of_uses || undefined,
    display: {
      promo_code_enabled: !!special?.promo_code,
      ...getRules(special),
      bundle: getBundleDisplay(special),
    },
    schedule: parseSchedule(special, isCreateMode),
    has_schedule_overrides: hasScheduleOverrides(
      parseSchedule(special, isCreateMode),
      isCreateMode
    ),
    enabled_date_start_checked:
      special?.enabled_date_start_checked ||
      fullStartDate.filter(Boolean).length > 1,
    enabled_date_end_checked:
      special?.enabled_date_end_checked ||
      fullEndDate.filter(Boolean).length > 1,
    end_date: endDate,
    end_time: convert24HourTimeTo12HourTime(endTime),
    start_date: startDate,
    start_time: convert24HourTimeTo12HourTime(startTime),
    enabled_date_end: special?.enabled_date_end,
    enabled_date_start: special?.enabled_date_start,
    use_store_close_time:
      special?.use_store_close_time ||
      special?.schedule?.use_store_close_time_for_end,
  };

  const formMethods = useForm({
    defaultValues: {
      name: isCreateMode ? '' : special?.title || '',
      special_type: isCreateMode ? '' : special?.special_type || '',
      conditions: isCreateMode
        ? {
            cart_total: {
              threshold: 0,
            },
            bulk_pricing: {
              target_weight: 'ounce',
              max_applications_per_cart: 1,
            },
            qualified_group: {
              type: 'senior',
              required_age: 65,
            },
            bundle: {
              settings: {
                allow_discounts_on_required_products: false, // Allow qualifying products to be discounted
              },
              dependent: {
                max_number_of_discounted_products: 1, // Max products discounted
              },
              independent: {
                threshold_number_of_items_in_cart: 1, // Minimum products requirement
              },
              max_applications_per_cart: 1, // Max uses per order
            },
          }
        : special?.conditions || {},
      discount_type: isCreateMode ? 'percent' : special?.discount_type || '',
      discount_dollar_amount: isCreateMode
        ? 0
        : special?.discount_dollar_amount || 0,
      discount_percent: isCreateMode ? 0 : special?.discount_percent || 0,
      discount_target_price: isCreateMode
        ? 0
        : special?.discount_target_price || 0,
      enabled: isCreateMode ? false : !!special?.enabled,
      description: isCreateMode ? '' : special?.description || '',
      reservation_modes: isCreateMode
        ? {
            delivery: true,
            pickup: true,
          }
        : {
            delivery: !!special?.reservation_modes?.delivery,
            pickup: !!special?.reservation_modes?.pickup,
          },
      terms: isCreateMode ? '' : special?.terms || '',
      display: {
        promo_code_enabled: isCreateMode ? false : !!special?.promo_code,
        ...getRules(special),
        bundle: getBundleDisplay(special),
      },
      photo: isCreateMode
        ? undefined
        : special?.photo && special.photo['urls']['small']
        ? [special.photo['urls']['small']]
        : undefined,
      promo_code: isCreateMode ? '' : special?.promo_code,
      multiple_use_promo_code: isCreateMode
        ? false
        : !!(special?.promo_code && special?.multiple_use_promo_code),
      promo_code_max_number_of_uses: isCreateMode
        ? undefined
        : special?.promo_code_max_number_of_uses || undefined,
      stacking_setting: isCreateMode ? 'yes' : special?.stacking_setting,
      schedule: parseSchedule(special, isCreateMode),
      has_schedule_overrides: hasScheduleOverrides(
        parseSchedule(special, isCreateMode),
        isCreateMode
      ),
      enabled_date_end_checked: isCreateMode
        ? false
        : special?.enabled_date_end_checked ||
          fullEndDate.filter(Boolean).length > 1,
      enabled_date_start_checked: isCreateMode
        ? false
        : special?.enabled_date_start_checked ||
          fullStartDate.filter(Boolean).length > 1,
      enabled_date_end: isCreateMode ? '' : special?.enabled_date_end,
      enabled_date_start: isCreateMode ? '' : special?.enabled_date_start,
      end_date: endDate,
      end_time: convert24HourTimeTo12HourTime(endTime),
      start_date: startDate,
      start_time: convert24HourTimeTo12HourTime(startTime),
      use_store_close_time: isCreateMode
        ? false
        : special?.use_store_close_time ||
          special?.schedule?.use_store_close_time_for_end ||
          false,
    },
    values: isCreateMode ? undefined : fetchedSpecialValues,
  });

  useMutationStatusToasts({
    isMutating: isCreating || isUpdating,
    isSuccess: isCreateSuccess || isUpdateSuccess,
    isError: isCreateError || isUpdateError,
    successMessage: `Special successfully ${
      isCreateMode ? 'created' : 'updated'
    }`,
    errorMessage: genericErrorMessage,
  });

  const {
    formState: { isDirty, dirtyFields },
    watch,
    trigger,
  } = formMethods;

  const specialTypeWatch = watch('special_type');
  const requiredProductsCount = watch(
    'display.bundle.threshold_number_of_items_in_cart'
  );
  const discountedProductsCount = watch(
    'display.bundle.max_number_of_discounted_products'
  );

  const getSubheaders = useMemo(() => {
    if (specialTypeWatch !== 'bundle') {
      return {
        requiredSubhead: undefined,
        discountedSubhead: undefined,
      };
    }

    let requiredSubhead;
    if (requiredProductsCount && requiredProductsCount > 0) {
      requiredSubhead = `${requiredProductsCount} ${pluralise(
        requiredProductsCount,
        'product'
      )} required to unlock Special.`;
    }

    let discountedSubhead;
    if (discountedProductsCount && discountedProductsCount > 0) {
      discountedSubhead = `${discountedProductsCount} ${pluralise(
        discountedProductsCount,
        'product'
      )} maximum to discount.`;
    }

    return {
      requiredSubhead,
      discountedSubhead,
    };
  }, [requiredProductsCount, discountedProductsCount, specialTypeWatch]);

  const isEditable = useMemo(() => userCanEditSpecials, [userCanEditSpecials]);
  const specialIsLoading: boolean = useMemo(
    () => storeSettingsLoading || (!isCreateMode && specialLoading),
    [storeSettingsLoading, specialLoading, isCreateMode]
  );

  const isValidatingPromoCode = useIsMutating({
    mutationKey: ['validate_promo_code', storeId],
  });
  const [hasDuplicationErrors, setHasDuplicationErrors] = useState(false);
  // Trigger form validation when duplicating special
  useEffect(() => {
    if (isDuplicateMode && !specialIsLoading && !isValidatingPromoCode) {
      // Slight delay to make sure form is loaded and rendered
      setTimeout(async () => {
        const initialErrors = await trigger(undefined, { shouldFocus: true });
        setHasDuplicationErrors(!initialErrors);
      }, 500);
    }
  }, [specialIsLoading, isDuplicateMode, isValidatingPromoCode]);

  const formatSchedule = (schedule: SpecialSchedule) => {
    const formatted = Object.entries(schedule).reduce((acc, [key, value]) => {
      if (key.startsWith('start_time') || key.startsWith('end_time')) {
        acc[key] = convert12HourTimeTo24HourTime(value);
      } else {
        acc[key] = value;
      }

      return acc;
    }, {} as any);

    return formatted;
  };

  const defaultSchedule = {
    enabled_sunday: true,
    enabled_monday: true,
    enabled_tuesday: true,
    enabled_wednesday: true,
    enabled_thursday: true,
    enabled_friday: true,
    enabled_saturday: true,
    enabled_sunday_all_day: true,
    enabled_monday_all_day: true,
    enabled_tuesday_all_day: true,
    enabled_wednesday_all_day: true,
    enabled_thursday_all_day: true,
    enabled_friday_all_day: true,
    enabled_saturday_all_day: true,
    end_time_sunday: null,
    start_time_sunday: null,
    end_time_monday: null,
    start_time_monday: null,
    end_time_tuesday: null,
    start_time_tuesday: null,
    end_time_wednesday: null,
    start_time_wednesday: null,
    end_time_thursday: null,
    start_time_thursday: null,
    end_time_friday: null,
    start_time_friday: null,
    end_time_saturday: null,
    start_time_saturday: null,
    use_store_close_time_for_end: false,
  };

  const onSubmit = (formData: any) => {
    setHeaderError(undefined);
    const includePromoCode =
      SHOW_PROMO_CODE_SPECIAL_TYPES.includes(formData['special_type']) &&
      formData['display']['promo_code_enabled'] === true;

    const includeScheduleOverride = formData['has_schedule_overrides'] === true;

    const updateOrDestroySpecialPhoto = () => {
      const photoId = special?.photo && special.photo['id'];
      return {
        id: photoId,
        file: formData['photo'][0] || '',
        // delete photo
        _destroy: photoId && !formData['photo'][0],
      };
    };

    const requestData: any = {
      ...omit(formData, ['photo']),
      title: formData['name'],
      promo_code: includePromoCode ? formData['promo_code'] : null,
      multiple_use_promo_code: includePromoCode
        ? !!formData['multiple_use_promo_code']
        : false,
      promo_code_max_number_of_uses: includePromoCode
        ? formData['promo_code_max_number_of_uses']
        : null,
      schedule: includeScheduleOverride
        ? formatSchedule(formData['schedule'])
        : defaultSchedule,
      end_date: formData['end_date'] || null,
      enabled_date_end: convert12HourTimeTo24HourTime(formData['end_time']),
      start_date: formData['start_date'] || null,
      enabled_date_start: convert12HourTimeTo24HourTime(formData['start_time']),
      discount_dollar_amount: formData['discount_dollar_amount'] || 0,
      discount_percent: formData['discount_percent'] || 0,
      discount_target_price: formData['discount_target_price'] || 0,
      photo_attributes: updateOrDestroySpecialPhoto(),
      use_store_close_time: !!formData['use_store_close_time'],
    };
    if (formData['special_type'] === 'bundle') {
      // If special is bundle, convert temporary rules to conditions so bundle special can be updated
      const discountedRules = parseRulesFromApplyTo(
        formData.display.discounted_rules.rules,
        formData.display.discounted_rules.apply_to
      );
      const requiredRules = parseRulesFromApplyTo(
        formData.display.required_rules.rules,
        formData.display.required_rules.apply_to
      );
      requestData['conditions'] = {
        bundle: convertRulesToConditions({
          discountedRules,
          requiredRules,
          max_applications_per_cart:
            formData.display.bundle.max_applications_per_cart,
          max_number_of_discounted_products:
            formData.display.bundle.max_number_of_discounted_products,
          threshold_number_of_items_in_cart:
            formData.display.bundle.threshold_number_of_items_in_cart,
          settings: formData.display.bundle.settings,
        }),
      };
    } else {
      requestData['rules'] = parseRulesFromApplyTo(
        formData.display.default_rules.rules,
        formData.display.default_rules.apply_to
      );
    }

    const submitMethod = () => {
      track({
        action: isDuplicateMode
          ? 'duplicate'
          : isCreateMode
          ? 'create'
          : 'update',
        event: EventNames.EditedSpecial,
        changed_attributes: Object.keys(dirtyFields),
      });

      return isCreateMode || isDuplicateMode
        ? createSpecial(requestData)
        : updateSpecial(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        setHeaderError(genericErrorMessage);
        throw new Error(genericErrorMessage);
      },
    });
  };

  const handleClose = () => {
    navigate(
      businessPaths.storeSpecials(
        storeId,
        (previousStatus as SpecialStatus) || SpecialStatus.live
      )
    );
  };

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      handleClose();
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  return (
    <ConfirmWrapperWithTracking
      open={isOpen}
      setOpen={handleClose}
      hasChanges={isDirty}
      variant="full-screen"
      background="grays-ultralight"
      modalName={ModalNames['CreateSpecial']}
    >
      <Form.BaseForm
        name="special settings"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={`${
            isDuplicateMode ? 'Duplicate' : isCreateMode ? 'Create' : 'Edit'
          } Special`}
          subtitle={storeName}
          actions={
            <Flex alignItems="center">
              {headerError && (
                <Flex mr={12}>
                  <ErrorIcon color="error" mr={6} />
                  <Typography color="error">{headerError}</Typography>
                </Flex>
              )}
              <SwitchWithBorder
                label="Enabled"
                name="enabled"
                mr={8}
                disabled={specialIsLoading}
              />
              {isEditable && (
                <Form.SubmitButton
                  label="Publish"
                  variant="primary"
                  data-testid="special-edit-save"
                />
              )}
            </Flex>
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <SpecialsModalProvider
            hasDuplicationErrors={hasDuplicationErrors}
            storeSettings={storePayload}
            posSyncMap={{
              posSynced: !!special?.pos_synced,
              posSource: special?.pos_source || '',
              isJanePosSynced: special
                ? (special.pos_synced && special.pos_source === 'janepos') ||
                  false
                : false,
            }}
          >
            <Flex alignItems="center" flexDirection="column">
              <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
                <Card.Content>
                  <Flex p={24} flexDirection="column">
                    <DetailsCard
                      store={storePayload?.store}
                      special={special}
                      isEditable={isEditable}
                      isCreateMode={isCreateMode}
                      isLoading={specialIsLoading}
                    />
                  </Flex>
                </Card.Content>
              </Card>
              <ConditionsCard
                fieldPrefix={
                  specialTypeWatch === 'bundle'
                    ? 'display.required_rules'
                    : 'display.default_rules'
                }
                isLoading={specialIsLoading}
                headerText={
                  (specialTypeWatch === 'bundle' &&
                    'Conditions for required products') ||
                  undefined
                }
                subHeaderText={getSubheaders['requiredSubhead']}
              />
              {specialTypeWatch === 'bundle' && (
                <ConditionsCard
                  fieldPrefix={'display.discounted_rules'}
                  isLoading={specialIsLoading}
                  headerText={
                    (specialTypeWatch === 'bundle' &&
                      'Conditions for discounted products') ||
                    undefined
                  }
                  subHeaderText={getSubheaders['discountedSubhead']}
                />
              )}
              <PromoCodeCard
                isDuplicateMode={isDuplicateMode}
                isCreateMode={isCreateMode}
                isLoading={specialIsLoading}
              />
              <ScheduleCard
                isLoading={specialIsLoading && storeSettingsLoading}
                storePayload={storePayload}
              />
              <ImagesCard isLoading={specialIsLoading} />
            </Flex>
          </SpecialsModalProvider>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import type {
  Appearance,
  CommunicationBanner,
  FiltersAndLabels,
  MenuProductToPreview,
  MenuRow,
} from '@jane/business-admin/types';
import { tMenuRow } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';

import { STORES_URL } from '..';

interface MenuRowCreateParams {
  applicability_rules?: { filter_type: string; filter_value: string }[];
  custom_display_name: string;
  enabled: boolean;
  menu_products?: number[];
  row_type: string;
}
const createMenuRow = async (
  storeId: string,
  data: MenuRowCreateParams
): Promise<null> => {
  return await janeApiV2.post<null>(
    `${STORES_URL}/${storeId}/menu/menu_row`,
    data
  );
};
export const useCreateMenuRow = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: MenuRowCreateParams) => createMenuRow(storeId, data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

const updateMenuRow = async (
  storeId: string,
  data: MenuRowCreateParams,
  rowId: number
): Promise<null> => {
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/menu/menu_row/${rowId}`,
    data
  );
};
export const useUpdateMenuRow = (storeId: string, rowId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: MenuRowCreateParams) =>
      updateMenuRow(storeId, data, rowId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

const deleteMenuRow = async (storeId: string, rowId: number): Promise<null> => {
  return await janeApiV2.delete<null>(
    `${STORES_URL}/${storeId}/menu/menu_row/${rowId}`
  );
};
export const useDeleteMenuRow = (storeId: string, rowId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteMenuRow(storeId, rowId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

interface UpdateAppearanceParams {
  pdp_hide_description: boolean;
  pdp_hide_effects: boolean;
  view_default: 'list_view' | 'grid_view';
  white_label_nav_color: string;
  white_label_theme_color: string;
}

const updateAppearance = async (
  storeId: string,
  data: UpdateAppearanceParams
) => {
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/menu/appearance`,
    {
      appearance: data,
    }
  );
};

export const useUpdateAppearance = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateAppearanceParams) =>
      updateAppearance(storeId, data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

export interface UpdateCommsBannerParams {
  alt_text?: string;
  banner_type: string;
  button_fill_color?: string;
  button_link?: string;
  button_text?: string;
  button_text_color?: string;
  enabled: boolean;
  header_text_color?: string;
  image?: string;
  image_opacity?: number;
  is_expanded: boolean;
  message: string;
  stores?: number[] | string[];
  title: string;
}

const updateCommsBanner = async (
  storeId: string,
  data: UpdateCommsBannerParams
) => {
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/menu/communication_banner`,
    {
      communication_banner: data,
    }
  );
};

export const useUpdateCommsBanner = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateCommsBannerParams) =>
      updateCommsBanner(storeId, data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

export type UpdateMenuRowOrderBody = {
  enabled?: boolean;
  id?: number;
  product_type: string;
  ranking: number;
}[];

const updateMenuRowOrder = async (
  data: UpdateMenuRowOrderBody,
  storeId?: string
): Promise<null> => {
  return await janeApiV2.put<null>(`${STORES_URL}/${storeId}/menu/menu_rows`, {
    menu_rankings: data,
  });
};

export const useUpdateMenuRowOrder = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateMenuRowOrderBody) =>
      updateMenuRowOrder(data, storeId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

const updateFiltersAndLabels = async (
  data: FiltersAndLabels,
  storeId?: string
): Promise<null> => {
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/menu/filters_and_labels`,
    {
      filters_and_labels: data,
    }
  );
};

export const useUpdateFiltersAndLabels = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FiltersAndLabels) =>
      updateFiltersAndLabels(data, storeId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['menu', storeId]);
    },
  });
};

export type MenuResponse = {
  appearance: Appearance;
  communication_banner: CommunicationBanner;
  filters_and_labels: FiltersAndLabels;
  menu_rows: MenuRow[];
};

const fetchMenu = async (storeId?: string): Promise<MenuResponse> => {
  const url = `${STORES_URL}/${storeId}/menu`;
  const tPayload = t.interface({
    menu_rows: t.array(tMenuRow),
  });
  const data = await fetchWithDecode(
    janeApiV2.get<MenuResponse>(url),
    tPayload,
    url
  );

  return data;
};

export const useStoreMenu = (storeId?: string) => {
  return useQuery<MenuResponse>({
    queryFn: () => fetchMenu(storeId),
    queryKey: ['menu', storeId],
    staleTime: Infinity,
    useErrorBoundary: false,
  });
};

type ProductsForMenuRowResponse = MenuProductToPreview[];

const fetchAllProductsForMenuRow = async (
  storeId?: string,
  menuRowId?: string
): Promise<ProductsForMenuRowResponse> => {
  const url = `${STORES_URL}/${storeId}/menu/menu_row/${menuRowId}/fetch_products`;
  const tPayload = t.any;
  const data = await fetchWithDecode(
    janeApiV2.get<ProductsForMenuRowResponse>(url),
    tPayload,
    url
  );

  return data;
};

// TODO: need to catch fetch errors deeper in the component so the component doesn't break if they fail.
// ticket here: https://app.clickup.com/t/8678ktyqh
export const useGetAllProductsForMenuRow = (
  storeId?: string,
  menuRowId?: string,
  disabled = false
) => {
  return useQuery<ProductsForMenuRowResponse>({
    queryFn: () => fetchAllProductsForMenuRow(storeId, menuRowId),
    queryKey: ['menu', storeId, menuRowId],
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled: !disabled,
  });
};

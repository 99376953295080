import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useProductSearch,
  useProductSearchFilters,
} from '@jane/business-admin/data-access';
import { useDebouncedTrack } from '@jane/business-admin/hooks';
import {
  EventNames,
  FilterNames,
  ModalNames,
  SearchSubjects,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { useImage } from '@jane/shared/components';
import {
  AlertIcon,
  Button,
  CheckboxField,
  ChevronDownIcon,
  Flex,
  Form,
  Icon,
  Image,
  List,
  Modal,
  Popover,
  Skeleton,
  Tag,
  Typography,
  useForm,
  useFormContext,
} from '@jane/shared/reefer';
import type { PopoverContextProps } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';
import { labelForVal } from '@jane/shared/util';

import { ConfirmWrapperWithTracking } from './ConfirmWrapperWithTracking';
import { CollapsedBorderTable } from './TableWithBorderSeparator';

const FORM_ERROR_NAME = 'product-search-modal-errors';

const FilterDropdown = ({
  capitalizeLabel = false,
  label,
  options,
  selected,
  onChange,
}: {
  capitalizeLabel?: boolean;
  label: string;
  onChange?: (changedOption: string, allSelections: string[]) => void;
  options: any[];
  selected: string[];
}) => {
  const formMethods = useFormContext();
  const { setValue, getValues } = formMethods;
  const popoverLabel = `${label}Filter`;
  const listLabel = `${label}List`;

  const existingValues = getValues(label);

  return (
    <Popover
      target={
        <Flex>
          <Button
            full
            variant={selected?.length === 0 ? 'tertiary' : 'tertiary-selected'}
            label={
              <Flex gap={8}>
                <Typography variant="body-bold">{capitalize(label)}</Typography>
                {selected?.length > 0 ? (
                  <Tag
                    label={`${selected.length}`}
                    background="primary-light"
                    color="primary-dark"
                  />
                ) : null}
              </Flex>
            }
            endIcon={<ChevronDownIcon size="sm" />}
          />
        </Flex>
      }
      label=""
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label={popoverLabel} padding={false}>
          <List label={listLabel}>
            <List.Item
              key={`${label}-top`}
              px={24}
              pt={24}
              onClick={() => {
                setValue(label, [], {
                  shouldDirty: false,
                  shouldTouch: false,
                });
                closePopover();
              }}
            >
              <Typography variant="body-bold">{capitalize(label)}</Typography>
            </List.Item>
            {options.map((option) => (
              <List.Item
                key={`${label}-${option}`}
                px={24}
                onClick={() => {
                  let newValues;

                  if (existingValues?.includes(option)) {
                    newValues = existingValues.filter(
                      (clickedValue: string) => {
                        return clickedValue !== option;
                      }
                    );
                  } else {
                    newValues = [...existingValues, option];
                  }

                  setValue(label, newValues, {
                    shouldDirty: false,
                    shouldTouch: false,
                  });

                  onChange && onChange(option, newValues);
                }}
              >
                <Flex flexDirection="row" gap={16}>
                  <CheckboxField
                    label={`${label}-${option}-checkbox`}
                    name={`${label}-${option}-checkbox`}
                    labelHidden
                    defaultChecked={existingValues?.includes(option)}
                    checked={existingValues?.includes(option)}
                  />
                  <Typography>
                    {label === 'weight'
                      ? labelForVal(option)
                      : capitalizeLabel
                      ? capitalize(option)
                      : option}
                  </Typography>
                </Flex>
              </List.Item>
            ))}
          </List>
        </Popover.Content>
      )}
    </Popover>
  );
};

const ProductSearchFilters = ({ id }: { id: string }) => {
  const { pathname } = useLocation();
  const formMethods = useFormContext();

  const { watch, setValue, getValues } = formMethods;

  const clearFilters = () => {
    setValue('brand', [], { shouldDirty: false, shouldTouch: false });
    setValue('category', [], { shouldDirty: false, shouldTouch: false });
    setValue('lineage', [], { shouldDirty: false, shouldTouch: false });
    setValue('search_name', '', { shouldDirty: false, shouldTouch: false });
    setValue('subcategory', [], { shouldDirty: false, shouldTouch: false });
    setValue('weight', [], { shouldDirty: false, shouldTouch: false });
  };

  const hasFilters =
    getValues([
      'brand',
      'category',
      'lineage',
      'search_name',
      'subcategory',
      'weight',
    ]).filter(Boolean).length > 0;

  const [brand, kind, lineage, subtype, weight] = watch([
    'brand',
    'category',
    'lineage',
    'subcategory',
    'weight',
  ]);
  const { data: filterData } = useProductSearchFilters(id, kind);

  const [searchFilter, setSearchFilter] = useState('');
  const brands = filterData?.filters?.brands || [];
  const categories = filterData?.filters?.categories || [];
  const subcategories = filterData?.filters?.subcategories || [];
  const lineages = filterData?.filters?.lineages || [];
  const weights = filterData?.filters?.weights || [];

  const trackFilterChange =
    (filterName: FilterNames) =>
    (changedOption: string, allSelections: string[]) => {
      const isSelection = allSelections.includes(changedOption);
      const commonProps = {
        all_selected_values: allSelections,
        filter_name: filterName,
        modal_name: ModalNames.ProductSearch,
        url: normalizePath(pathname, id),
      };

      if (isSelection) {
        track({
          event: EventNames.SelectedFilter,
          selected_value: changedOption,
          ...commonProps,
        });
      } else {
        track({
          event: EventNames.DeselectedFilter,
          deselected_value: changedOption,
          ...commonProps,
        });
      }
    };

  return (
    <Flex gap={16} flexWrap="wrap">
      <Form.SearchField
        label="Store search"
        name="search_name"
        placeholder="Search product name or brand"
        defaultValue={searchFilter}
        autoFocus={true}
        onChange={(val) => setSearchFilter(val.toLowerCase())}
        isDebounced={true}
        width={350}
      />
      <FilterDropdown
        label="brand"
        options={brands}
        selected={brand}
        onChange={trackFilterChange(FilterNames.ProductBrand)}
      />
      <FilterDropdown
        label="category"
        options={categories}
        selected={kind}
        capitalizeLabel
        onChange={trackFilterChange(FilterNames.ProductCategory)}
      />
      <FilterDropdown
        label="subcategory"
        options={subcategories}
        selected={subtype}
        capitalizeLabel
        onChange={trackFilterChange(FilterNames.ProductSubcategory)}
      />
      <FilterDropdown
        label="lineage"
        options={lineages}
        selected={lineage}
        capitalizeLabel
        onChange={trackFilterChange(FilterNames.ProductLineage)}
      />
      <FilterDropdown
        label="weight"
        options={weights}
        selected={weight}
        onChange={trackFilterChange(FilterNames.ProductWeight)}
      />
      {hasFilters ? (
        <Button
          label="Clear filters"
          onClick={clearFilters}
          variant="minimal"
        />
      ) : null}
    </Flex>
  );
};

const TableRow = ({
  menuProduct,
  onSelectionChange,
  onlyShowInStockProducts,
  selectedProducts,
  weightFilter,
}: {
  menuProduct: any;
  onSelectionChange: (product: any, value: boolean) => void;
  onlyShowInStockProducts: boolean;
  selectedProducts: ProductSearchSelection[];
  weightFilter: string[];
}) => {
  const { imageSrc } = useImage(menuProduct);

  const theme = useTheme();

  const prices: { [key: string]: string } = {
    price_each: menuProduct.price_each,
    price_eighth_ounce: menuProduct.price_eighth_ounce,
    price_gram: menuProduct.price_gram,
    price_half_gram: menuProduct.price_half_gram,
    price_half_ounce: menuProduct.price_half_ounce,
    price_ounce: menuProduct.price_ounce,
    price_quarter_ounce: menuProduct.price_quarter_ounce,
    price_two_gram: menuProduct.price_two_gram,
  };

  const priceNums = Object.values(prices)
    .filter(Boolean)
    .map((val) => parseFloat(val));
  const min = Math.min(...priceNums);
  const max = Math.max(...priceNums);
  const range =
    max === min
      ? `$${max.toFixed(2)}`
      : `$${min.toFixed(2)} – $${max.toFixed(2)}/${priceNums.length} weights`;

  const productId = menuProduct.product_id.toString();

  const isChecked = useMemo(() => {
    return selectedProducts.some(
      ({ product_id }) => product_id.toString() === productId.toString()
    );
  }, [JSON.stringify(selectedProducts), productId]);

  const onRowClick = useCallback(() => {
    onSelectionChange(menuProduct, !isChecked);
  }, [isChecked]);

  return (
    <Table.Row onClick={onRowClick}>
      <Table.Cell width="48px">
        <Form.CheckboxField
          label={menuProduct.name}
          name={productId}
          onChange={(value) => onSelectionChange(menuProduct, value)}
          onClick={(event) => event.stopPropagation()}
          labelHidden
          checked={isChecked}
        />
      </Table.Cell>
      <Table.Cell width="88px">
        <Image
          altText={menuProduct.full_name}
          src={imageSrc}
          borderRadius={theme.borderRadius.sm?.toString()}
          border={true}
          height="64px"
          width="64px"
        />
      </Table.Cell>
      <Table.Cell>
        <Typography>{menuProduct.name}</Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>{menuProduct.brand}</Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>{capitalize(menuProduct.category)}</Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>{capitalize(menuProduct.subcategory)}</Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>{capitalize(menuProduct.lineage)}</Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>
          {weightFilter?.length > 0
            ? weightFilter
                .map((filter) => `$${parseFloat(prices[filter]).toFixed(2)}`)
                .join(', ')
            : range}
        </Typography>
      </Table.Cell>
      <>
        {!onlyShowInStockProducts ? (
          <Table.Cell>
            {!menuProduct.in_stock ? (
              <span title="Out of stock">
                <Icon icon={AlertIcon} color="error" altText="Out of stock" />
              </span>
            ) : null}
          </Table.Cell>
        ) : null}
      </>
    </Table.Row>
  );
};

const TableWrapper = styled.div({
  overflowY: 'auto',
  position: 'relative',
  boxSizing: 'border-box',
  '@media (min-width: 1375px)': {
    height: '98%',
  },
  '@media (min-width: 1024px) and (max-width: 1374px)': {
    height: '88%',
  },
  '@media (min-width: 710px) and (max-width: 1023px)': {
    height: '88%',
  },
  '@media (min-width: 501px) and (max-width: 709px)': {
    height: '80%',
  },
  '@media (min-width: 379px) and (max-width: 500px)': {
    height: '75%',
  },
  '@media (max-width: 378px)': {
    height: '70%',
  },
});

const TableHeader = ({
  onToggleAll,
  onlyShowInStockProducts,
  productIds,
  productTypography,
}: {
  onToggleAll?: (selected: boolean) => void;
  onlyShowInStockProducts: boolean;
  productIds: string[];
  productTypography: string;
}) => {
  return (
    <Table.Head>
      <Table.Row>
        <Table.HeaderCell>
          {/* <SelectAllCheckbox
            applicableIds={productIds}
            name="select-all"
            label="Select All"
            labelHidden
            onChange={onToggleAll}
          /> */}
        </Table.HeaderCell>
        <Table.HeaderCell />
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            {productTypography}
          </Typography>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            Brand
          </Typography>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            Category
          </Typography>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            Subcategory
          </Typography>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            Lineage
          </Typography>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            Price/Weight
          </Typography>
        </Table.HeaderCell>
        <>{!onlyShowInStockProducts ? <Table.HeaderCell /> : null}</>
      </Table.Row>
    </Table.Head>
  );
};

const SkeletonTable = ({
  onlyShowInStockProducts,
}: {
  onlyShowInStockProducts: boolean;
}) => {
  const length10Array = Array(10)
    .fill(null)
    .map((_, index) => index);

  return (
    <Skeleton animate direction="column" width="100%">
      <CollapsedBorderTable>
        <TableHeader
          onlyShowInStockProducts={onlyShowInStockProducts}
          productIds={[]}
          productTypography={'x Products'}
        />
        <Table.Body>
          {length10Array.map((i) => (
            <Table.Row key={i}>
              <Table.Cell width="48px">
                <CheckboxField label="loading" name="loading" labelHidden />
              </Table.Cell>
              <Table.Cell width="88px">
                <Skeleton.Bone width="64px" height="64px" />
              </Table.Cell>
              <Table.Cell>
                <Skeleton.Bone width="100%" height="18px" />
              </Table.Cell>
              <Table.Cell>
                <Skeleton.Bone width="100%" height="18px" />
              </Table.Cell>
              <Table.Cell>
                <Skeleton.Bone width="100%" height="18px" />
              </Table.Cell>
              <Table.Cell>
                <Skeleton.Bone width="100%" height="18px" />
              </Table.Cell>
              <Table.Cell>
                <Skeleton.Bone width="100%" height="18px" />
              </Table.Cell>
              <Table.Cell>
                <Skeleton.Bone width="100%" height="18px" />
              </Table.Cell>
              <>
                {!onlyShowInStockProducts ? (
                  <Table.Cell>
                    <Skeleton.Bone width="100%" height="18px" />
                  </Table.Cell>
                ) : null}
              </>
            </Table.Row>
          ))}
        </Table.Body>
      </CollapsedBorderTable>
    </Skeleton>
  );
};

export type ProductSearchSelection = {
  id?: string;
  menu_product_id: number;
  product_id: string;
};
export const ProductSearchModalUpdated = ({
  closeModal,
  onSubmit,
  onlyShowInStockProducts = true,
  rowId,
  selectedProducts,
}: {
  closeModal: () => void;
  onSubmit: (selections: ProductSearchSelection[]) => void;
  onlyShowInStockProducts?: boolean;
  rowId?: number;
  selectedProducts: ProductSearchSelection[];
}) => {
  const { id = '' } = useParams<'id'>();
  const loadNextRef = useRef(null);
  const [selections, setSelections] = useState<any[]>(selectedProducts || []);
  const formMethods = useForm({
    defaultValues: {
      brand: [],
      category: [],
      lineage: [],
      search_name: '',
      subcategory: [],
      weight: [],
    },
  });
  const {
    watch,
    formState: { isDirty },
  } = formMethods;

  const [brand, kind, lineage, name, subtype, weight] = watch([
    'brand',
    'category',
    'lineage',
    'search_name',
    'subcategory',
    'weight',
  ]);

  const {
    data: searchData,
    isFetching,
    isFetched,
    isSuccess,
    fetchNextPage,
  } = useProductSearch({
    storeId: id,
    rowId: rowId?.toString(),
    brand: brand?.length > 0 ? brand.join(',') : '',
    kind: kind?.length > 0 ? kind.join(',') : '',
    lineage: lineage?.length > 0 ? lineage.join(',') : '',
    name: name,
    subtype: subtype?.length > 0 ? subtype.join(',') : '',
    weight: weight?.length > 0 ? weight.join(',') : '',
    onlyShowInStock: onlyShowInStockProducts,
  });

  // Track search input here as an effect hook in order to have access to `isSuccess`,
  // rather than in the nested component with the text field
  const debouncedTrack = useDebouncedTrack(1500);
  const trackSearch = (value: string) => {
    // Prevent from firing when the form initializes
    if (!isDirty && !value) return;

    debouncedTrack({
      event: EventNames.Search,
      arguments: value,
      subject: SearchSubjects.Products,
      successful: isSuccess,
    });
  };

  useEffect(() => {
    trackSearch(name);
  }, [name]);

  const selectedCount = selections.length;
  const totalCount = useMemo(
    () => searchData?.pages[0].meta.total,
    [JSON.stringify(searchData)]
  );

  const [initialSelectedMenuProducts, setInitialSelectedMenuProducts] =
    useState<any[]>([]);

  useEffect(() => {
    setInitialSelectedMenuProducts(
      searchData?.pages[0].selected_menu_products || []
    );
  }, [searchData]);

  const menuProductIds =
    searchData?.pages.flatMap((page) =>
      page.menu_products.map(({ product_id }) => product_id.toString())
    ) || [];

  const selectedMenuProductIds =
    initialSelectedMenuProducts.map(({ product_id }) =>
      product_id.toString()
    ) || [];

  const allMenuProductIds = [...menuProductIds, ...selectedMenuProductIds];

  const isTogglingAll = useRef(false);

  const handleSelectionChange = (product: any, value: boolean) => {
    const alreadyFound = selections.find(
      ({ product_id }) =>
        product.product_id.toString() === product_id.toString()
    );
    // TODO: Not sure why but it tries to add things twice when we do select all, suspect it's because of the onChange
    // on the checkbox being fired, but can't find a better solution than this
    if (value === true && alreadyFound) {
      return;
    }
    setSelections((currentState) => {
      const newState = value
        ? [...currentState, product]
        : currentState.filter(
            (productInState) =>
              productInState.product_id.toString() !==
              product.product_id.toString()
          );

      return newState;
    });

    if (!isTogglingAll.current) {
      track({
        event: value
          ? EventNames.SelectedCheckbox
          : EventNames.DeselectedCheckbox,
        checkbox_label: product.name,
        modal_name: ModalNames.ProductSearch,
      });
    }
  };

  useEffect(() => {
    isTogglingAll.current = false;
  }, [selections]);

  const handleSubmit = () => {
    onSubmit(selections);
    closeModal();
  };

  // Initialize infinite scroll observer
  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        const lastProduct = entries[0];

        if (lastProduct.isIntersecting) {
          fetchNextPage();
          observer.unobserve(lastProduct.target);
        }
      },
      { rootMargin: '300px' }
    );
  }, [fetchNextPage]);

  // When searchData changes re-observe last product
  useEffect(() => {
    if (loadNextRef.current) {
      observer.observe(loadNextRef.current);
    }
    return () => observer.disconnect();
  }, [observer, searchData]);

  const onSelectAll = useCallback(() => {
    const selectAll = searchData?.pages[0].meta.all_product_ids || [];
    setSelections(selectAll);
  }, [JSON.stringify(searchData)]);

  const clearSelections = () => {
    setSelections([]);
    setInitialSelectedMenuProducts([]);
  };

  return (
    <ConfirmWrapperWithTracking
      open
      setOpen={closeModal}
      variant="flex"
      hasChanges={isDirty}
      modalName={ModalNames.ProductSearch}
    >
      <Form.BaseForm
        name="product search modal"
        onSubmit={handleSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Select products"
          actions={<Form.SubmitButton variant="primary" label="Save" ml={16} />}
        />
        <Modal.Content overflowY="hidden">
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <ProductSearchFilters id={id} />
          <TableWrapper>
            {isFetched ? (
              <CollapsedBorderTable>
                <TableHeader
                  onlyShowInStockProducts={onlyShowInStockProducts}
                  productIds={allMenuProductIds}
                  productTypography={`${searchData?.pages[0].meta.total} Products`}
                  onToggleAll={(checked: boolean) => {
                    isTogglingAll.current = true;

                    track({
                      event: checked
                        ? EventNames.SelectedCheckbox
                        : EventNames.DeselectedCheckbox,
                      checkbox_label: `${
                        checked ? 'select' : 'deselect'
                      } all products`,
                      modal_name: ModalNames.ProductSearch,
                    });
                  }}
                />
                <Table.Body>
                  {initialSelectedMenuProducts.map((selectedProduct) => (
                    <TableRow
                      key={selectedProduct.product_id.toString()}
                      onlyShowInStockProducts={onlyShowInStockProducts}
                      menuProduct={selectedProduct}
                      selectedProducts={selections}
                      onSelectionChange={handleSelectionChange}
                      weightFilter={weight}
                    />
                  ))}
                </Table.Body>
                <Table.Body>
                  {searchData?.pages.flatMap((page) =>
                    page.menu_products
                      .filter(
                        (product) =>
                          !initialSelectedMenuProducts.find(
                            (initialProduct) =>
                              product.product_id === initialProduct.product_id
                          )
                      )
                      .map((product) => (
                        <TableRow
                          onlyShowInStockProducts={onlyShowInStockProducts}
                          key={product.product_id.toString()}
                          menuProduct={product}
                          selectedProducts={selections}
                          onSelectionChange={handleSelectionChange}
                          weightFilter={weight}
                        />
                      ))
                  )}
                </Table.Body>
                {/* infinite scroll trigger only after 1st fetch */}
                {!isFetching && isSuccess ? (
                  <tfoot style={{ height: '10px' }} ref={loadNextRef}></tfoot>
                ) : null}
              </CollapsedBorderTable>
            ) : (
              <SkeletonTable
                onlyShowInStockProducts={onlyShowInStockProducts}
              />
            )}
          </TableWrapper>
        </Modal.Content>
        <Modal.Footer>
          <Flex justifyContent="space-between" alignItems="center" mt={4}>
            <Typography>{`${selectedCount} products selected`}</Typography>
            <Flex gap={16}>
              <Button
                variant="tertiary"
                label="Select all"
                onClick={onSelectAll}
                disabled={selectedCount === totalCount}
              />
              <Button
                variant="tertiary"
                label="Clear all selected"
                disabled={!selectedCount}
                onClick={clearSelections}
              />
            </Flex>
          </Flex>
        </Modal.Footer>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

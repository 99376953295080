import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { tSpecialsIndexResponse } from '@jane/business-admin/types';
import type { SpecialsIndexResponse } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Id, StackingOptions, StoreSpecial } from '@jane/shared/models';

import { STORES_URL } from './stores';

type fetchSpecialsProps = {
  order?: string;
  page?: string;
  per_page?: string;
  query?: string;
  sort?: string;
  special_type?: string;
  status?: string;
  store_id?: string;
};

const fetchSpecials = async (
  params: fetchSpecialsProps
): Promise<SpecialsIndexResponse> => {
  const urlParams = new URLSearchParams();
  params.status && urlParams.append('status', params.status);
  params.query && urlParams.append('query', params.query);
  params.sort && urlParams.append('sort', params.sort);
  params.order && urlParams.append('order', params.order);
  params.special_type && urlParams.append('special_type', params.special_type);
  params.page && urlParams.append('page', params.page);
  params.per_page && urlParams.append('per_page', params.per_page);

  const urlBase = params.store_id
    ? `/business/stores/${params.store_id}/specials`
    : '/business/specials';
  const url = `${urlBase}?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<SpecialsIndexResponse>(url),
    tSpecialsIndexResponse,
    url
  );

  return data;
};

export const useFetchSpecials = (params: Partial<fetchSpecialsProps>) =>
  useInfiniteQuery<SpecialsIndexResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchSpecials({
        ...params,
        page: pageParam.toString(),
      });

      return {
        ...data,
        pageParam,
      };
    },
    queryKey: ['store_specials', params.store_id, params],
    getNextPageParam: (lastPage) => {
      const numPages = Math.ceil(
        lastPage?.meta.total / lastPage?.meta.per_page
      );
      const hasNextPage = lastPage?.meta.page < numPages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    staleTime: Infinity,
    useErrorBoundary: true,
  });

const toggleSpecialEnabled = async (
  storeId: string,
  specialId: string,
  enabled: boolean
): Promise<null> => {
  const url = `/business/stores/${storeId}/specials/${specialId}`;

  return await janeApiV2.put<null>(url, {
    enabled,
  });
};

export const useToggleSpecialEnabled = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      enabled,
      specialId,
    }: {
      enabled: boolean;
      specialId: string;
    }) => toggleSpecialEnabled(storeId, specialId, enabled),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials']);
    },
  });
};

const fetchStoreSpecial = (
  storeId: string,
  specialId: string | number
): Promise<{ special: StoreSpecial }> =>
  janeApiV2.get(`${STORES_URL}/${storeId}/specials/${specialId}`);
export const useFetchStoreSpecial = (
  storeId: string,
  specialId: string | number,
  disabled?: boolean
) =>
  useQuery({
    queryKey: ['store_specials', storeId, specialId],
    queryFn: () => fetchStoreSpecial(storeId, specialId),
    enabled: !disabled,
    // Don't keep cached data, causes form defaultValues to be outdated
    cacheTime: 0,
  });

type ValidatePromoCodeParams = {
  promoCode: string;
  specialId?: string;
};
const validatePromoCode = (
  storeId: string,
  promoCode: string,
  specialId?: string
): Promise<any> => {
  const urlParams = new URLSearchParams();
  promoCode && urlParams.append('promo_code', promoCode);
  specialId && urlParams.append('special_id', specialId);

  return janeApiV2.get(
    `${STORES_URL}/${storeId}/specials/validate_promo_code?${urlParams.toString()}`
  );
};
export const useValidatePromoCode = (storeId: string, specialId?: string) =>
  useMutation({
    mutationKey: ['validate_promo_code', storeId, specialId],
    mutationFn: ({ promoCode, specialId }: ValidatePromoCodeParams) =>
      validatePromoCode(storeId, promoCode, specialId),
  });

interface CreateSpecialResponse {
  special: StoreSpecial;
}

const createSpecial = (
  storeId: string,
  special: StoreSpecial
): Promise<CreateSpecialResponse> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/specials`, {
    ...special,
  });
export const useCreateSpecial = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: StoreSpecial) => createSpecial(storeId, params),
    mutationKey: ['store_specials', storeId],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials', storeId]);
    },
  });
};

export interface UpdateSpecialParams {
  specialId?: number | string;
}

const updateSpecial = (
  storeId: Id,
  id: Id,
  special: StoreSpecial
): Promise<CreateSpecialResponse> =>
  janeApiV2.patch(`${STORES_URL}/${storeId}/specials/${id}`, {
    ...special,
  });
export const useUpdateSpecial = (storeId: Id, id: Id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: StoreSpecial) => updateSpecial(storeId, id, params),
    mutationKey: ['store_specials', storeId, id],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials']);
    },
  });
};

export interface BulkUpdateSpecialParams {
  archive?: boolean;
  attributes?: { enabled?: boolean; stacking_setting?: StackingOptions };
  special_ids: Id[];
}
export interface BulkUpdateResponse {
  success: string[];
}

const bulkUpdateSpecial = (
  storeId: Id,
  params: BulkUpdateSpecialParams
): Promise<BulkUpdateResponse> =>
  janeApiV2.patch(`${STORES_URL}/${storeId}/specials/bulk`, params);
export const useBulkUpdateSpecial = (storeId: Id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: BulkUpdateSpecialParams) =>
      bulkUpdateSpecial(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      // NOTE: No idea why reset actually clears the table data, but invalidate keeps old entries around
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials', storeId]);
    },
  });
};

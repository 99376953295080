// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lidOgskp_5sykcf8vWyd {\n  display: flex;\n  flex-direction: row;\n  overflow-x: auto;\n  overflow-y: hidden;\n  position: relative;\n  scrollbar-width: none;\n  width: auto;\n}\n\n.lidOgskp_5sykcf8vWyd:hover {\n  overflow-x: auto;\n  scrollbar-width: thin;\n}\n\n.k00j1hn8gYkAtF9zu1ry {\n  flex-direction: column;\n}\n\n.aJq4WiPZM40q1_S2ksOC {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/tabs/tabs.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".tabs {\n  display: flex;\n  flex-direction: row;\n  overflow-x: auto;\n  overflow-y: hidden;\n  position: relative;\n  scrollbar-width: none;\n  width: auto;\n}\n\n.tabs:hover {\n  overflow-x: auto;\n  scrollbar-width: thin;\n}\n\n.tabs__direction__vertical {\n  flex-direction: column;\n}\n\n.tabs__full {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "lidOgskp_5sykcf8vWyd",
	"tabs__direction__vertical": "k00j1hn8gYkAtF9zu1ry",
	"tabs__full": "aJq4WiPZM40q1_S2ksOC"
};
export default ___CSS_LOADER_EXPORT___;

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const SortDown24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7071 11.7071C16.9931 11.4211 17.0787 10.991 16.9239 10.6173C16.7691 10.2436 16.4045 10 16 10H8.00003C7.59557 10 7.23093 10.2436 7.07615 10.6173C6.92137 10.991 7.00692 11.4211 7.29292 11.7071L11.2929 15.7071C11.4805 15.8946 11.7348 16 12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071L16.7071 11.7071Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: SortDown24,
  sm: SortDown24,
  lg: SortDown24,
  xl: SortDown24,
  xxl: SortDown24,
  xxxl: SortDown24,
};

export const SortDownIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sort-down-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

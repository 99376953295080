/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const External24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 5C4 4.44772 4.44772 4 5 4H10C10.5523 4 11 3.55228 11 3C11 2.44772 10.5523 2 10 2H5C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V14C22 13.4477 21.5523 13 21 13C20.4477 13 20 13.4477 20 14V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5Z"
      fill="black"
    />
    <path
      d="M14 2C13.4477 2 13 2.44772 13 3C13 3.55228 13.4477 4 14 4H18.5858L11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L20 5.41421V10C20 10.5523 20.4477 11 21 11C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14Z"
      fill="black"
    />
  </svg>
);

const SIZE_MAP = {
  md: External24,
  sm: External24,
  lg: External24,
  xl: External24,
  xxl: External24,
  xxxl: External24,
};

export const ExternalIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'external-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

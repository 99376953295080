/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Sativa16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V4C15 2.34315 13.6569 1 12 1ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z"
      fill="#666666"
    />
    <path
      d="M8.02001 12.814C7.44801 12.814 6.89334 12.7057 6.35601 12.489C5.82734 12.2724 5.35934 11.9647 4.95201 11.566L5.83601 10.539C6.13934 10.825 6.48601 11.059 6.87601 11.241C7.26601 11.4144 7.65601 11.501 8.04601 11.501C8.53134 11.501 8.89968 11.4014 9.15101 11.202C9.40234 11.0027 9.52801 10.7384 9.52801 10.409C9.52801 10.0537 9.40234 9.79802 9.15101 9.64202C8.90834 9.48602 8.59634 9.32569 8.21501 9.16102L7.04501 8.65402C6.76767 8.53269 6.49467 8.37669 6.22601 8.18602C5.96601 7.99535 5.74934 7.75269 5.57601 7.45802C5.41134 7.16335 5.32901 6.80802 5.32901 6.39202C5.32901 5.94135 5.45034 5.53835 5.69301 5.18302C5.94434 4.81902 6.28234 4.53302 6.70701 4.32502C7.14034 4.10835 7.63434 4.00002 8.18901 4.00002C8.68301 4.00002 9.15967 4.09969 9.61901 4.29902C10.0783 4.48969 10.4727 4.74969 10.802 5.07902L10.035 6.04102C9.76634 5.81569 9.48034 5.63802 9.17701 5.50802C8.88234 5.37802 8.55301 5.31302 8.18901 5.31302C7.79034 5.31302 7.46534 5.40402 7.21401 5.58602C6.97134 5.75935 6.85001 6.00202 6.85001 6.31402C6.85001 6.53069 6.91068 6.71269 7.03201 6.86002C7.16201 6.99869 7.33101 7.12002 7.53901 7.22402C7.74701 7.31935 7.96801 7.41469 8.20201 7.51002L9.35901 7.99102C9.86167 8.20769 10.2733 8.49369 10.594 8.84902C10.9147 9.19569 11.075 9.67669 11.075 10.292C11.075 10.7514 10.9537 11.1717 10.711 11.553C10.4683 11.9344 10.1173 12.242 9.65801 12.476C9.20734 12.7014 8.66134 12.814 8.02001 12.814Z"
      fill="#666666"
    />
  </svg>
);

const Sativa24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={22}
      height={22}
      rx={3}
      stroke="#0E0E0E"
      strokeWidth={2}
    />
    <path
      d="M12.5231 17.18C11.8631 17.18 11.2231 17.055 10.6031 16.805C9.99309 16.555 9.45309 16.2 8.98309 15.74L10.0031 14.555C10.3531 14.885 10.7531 15.155 11.2031 15.365C11.6531 15.565 12.1031 15.665 12.5531 15.665C13.1131 15.665 13.5381 15.55 13.8281 15.32C14.1181 15.09 14.2631 14.785 14.2631 14.405C14.2631 13.995 14.1181 13.7 13.8281 13.52C13.5481 13.34 13.1881 13.155 12.7481 12.965L11.3981 12.38C11.0781 12.24 10.7631 12.06 10.4531 11.84C10.1531 11.62 9.90309 11.34 9.70309 11C9.51309 10.66 9.41809 10.25 9.41809 9.77C9.41809 9.25 9.55809 8.785 9.83809 8.375C10.1281 7.955 10.5181 7.625 11.0081 7.385C11.5081 7.135 12.0781 7.01 12.7181 7.01C13.2881 7.01 13.8381 7.125 14.3681 7.355C14.8981 7.575 15.3531 7.875 15.7331 8.255L14.8481 9.365C14.5381 9.105 14.2081 8.9 13.8581 8.75C13.5181 8.6 13.1381 8.525 12.7181 8.525C12.2581 8.525 11.8831 8.63 11.5931 8.84C11.3131 9.04 11.1731 9.32 11.1731 9.68C11.1731 9.93 11.2431 10.14 11.3831 10.31C11.5331 10.47 11.7281 10.61 11.9681 10.73C12.2081 10.84 12.4631 10.95 12.7331 11.06L14.0681 11.615C14.6481 11.865 15.1231 12.195 15.4931 12.605C15.8631 13.005 16.0481 13.56 16.0481 14.27C16.0481 14.8 15.9081 15.285 15.6281 15.725C15.3481 16.165 14.9431 16.52 14.4131 16.79C13.8931 17.05 13.2631 17.18 12.5231 17.18Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  sm: Sativa16,
  md: Sativa24,
  lg: Sativa24,
  xl: Sativa24,
  xxl: Sativa24,
  xxxl: Sativa24,
};

export const SativaIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sativa-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

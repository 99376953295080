import styled from '@emotion/styled';
import type { InputHTMLAttributes } from 'react';

import DismissFilledIcon from '../../../../assets/icons/dynamic/dismiss-filled-24.svg';
import { mediaQueries, spacing } from '../../../../styling';
import { mobileInputStyling } from '../field.styles';
import type { StyledFieldProps } from '../field.types';

interface StyledInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    StyledFieldProps {
  disableMobileInputStyling: boolean;
  hideSearchIcon: boolean;
}

export const StyledInputWrapper = styled.div({
  position: 'relative',
});

export const StyledInput = styled.input<StyledInputProps>(
  ({
    theme: { borderRadius, colors },
    hideSearchIcon,
    disableMobileInputStyling,
    radiusSize,
  }) => ({
    background: colors.grays.white,
    border: `1px solid ${colors.grays.light}`,
    borderRadius: borderRadius[radiusSize],
    display: 'flex',
    height: '48px',
    margin: 0,
    outline: 'none',
    width: '100%',
    ...spacing({ pl: hideSearchIcon ? 12 : 48, pr: 48, py: 12 }),
    '&::-ms-clear': {
      display: 'none',
    },
    '&::-webkit-search-cancel-button': {
      WebkitAppearance: 'none',
    },
    '&:active, &:focus, &:focus-visible': {
      borderColor: colors.primary.main,
    },
    '&:read-only, &:disabled': {
      borderColor: colors.grays.light,
      pointerEvents: 'none',
    },
    WebkitAppearance: 'none',
    [mediaQueries.tablet('max')]: [
      !disableMobileInputStyling && {
        ...mobileInputStyling(),
        ...spacing({ pl: hideSearchIcon ? 0 : 32 }),
        backgroundColor: 'transparent',
      },
    ],
  })
);

export const SearchIconWrapper = styled.div<{
  disableMobileInputStyling?: boolean;
}>(
  {
    position: 'absolute',
    top: '50%',
    ...spacing({ mt: -12 }),
    height: '24px',
    left: 16,
  },
  ({ disableMobileInputStyling }) =>
    !disableMobileInputStyling && {
      [mediaQueries.tablet('max')]: [
        {
          left: 0,
        },
      ],
    }
);

export const DismissFilledIconButton = styled.button(({ theme }) => ({
  height: '24px',
  position: 'absolute',
  right: 16,
  top: '50%',
  width: '24px',
  ...spacing({ mt: -12 }),
  ':focus-visible': {
    '&:after': {
      content: `''`,
      display: 'inherit',
      left: '-3px',
      bottom: '-3px',
      position: 'absolute',
      border: `1px solid ${theme.colors.primary.main}`,
      backgroundColor: 'transparent',
      top: '-3px',
      borderRadius: '50%',
      right: '-3px',
    },
  },
  backgroundColor: 'transparent',
  backgroundImage: `url(${DismissFilledIcon})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
}));

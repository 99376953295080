import type { ReactElement } from 'react';

import type { MarginProperties } from '../../styling';
import type { ColorKey, ReeferBaseProps } from '../../types';
import type { IconProps } from '../icon';
import { Typography } from '../typography/typography';
import type { TagSizes } from './tag.styles';
import { StyledTag } from './tag.styles';

export interface TagProps extends MarginProperties, ReeferBaseProps {
  /** Background changes the background color, defaults to 'grays-mid'  */
  background?: ColorKey;

  /** Color changes the text color, defaults to black with light backgrounds and white with dark backgrounds */
  color?: ColorKey;

  /* Icon that will show up on the right side of the tag */
  endIcon?: ReactElement<IconProps>;

  /* Icon that will show up on the left side of the tag */
  icon?: ReactElement<IconProps>;

  /** Label is the text content of the tag */
  label: string;

  /** Size of the tag */
  size?: TagSizes;
}

/**
 * Small colored tag with some text in it, used to add labels or context to other elements
 */
export function Tag({
  background = 'grays-mid',
  className,
  color,
  'data-testid': testId,
  endIcon,
  icon,
  id,
  label,
  size = 'sm',
  style,
  ...props
}: TagProps) {
  return (
    <StyledTag
      alignItems="center"
      background={background}
      className={className}
      color={color}
      data-testid={testId}
      id={id}
      size={size}
      style={style}
      {...props}
    >
      {icon && icon}
      <Typography
        variant={
          size === 'lg' || label?.length === 1 ? 'body-bold' : 'mini-bold'
        }
        color="inherit"
      >
        {label}
      </Typography>
      {endIcon && endIcon}
    </StyledTag>
  );
}

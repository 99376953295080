/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const CheckCircled64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.4142 30.5858L29 36.1716L40.5858 24.5858C41.3668 23.8047 42.6332 23.8047 43.4142 24.5858C44.1953 25.3668 44.1953 26.6332 43.4142 27.4142L30.4142 40.4142C29.6332 41.1953 28.3668 41.1953 27.5858 40.4142L20.5858 33.4142C19.8047 32.6332 19.8047 31.3668 20.5858 30.5858C21.3668 29.8047 22.6332 29.8047 23.4142 30.5858Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM60 32C60 47.464 47.464 60 32 60C16.536 60 4 47.464 4 32C4 16.536 16.536 4 32 4C47.464 4 60 16.536 60 32Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  xxl: CheckCircled64,
  xl: CheckCircled64,
  lg: CheckCircled64,
  md: CheckCircled64,
  sm: CheckCircled64,
  xxxl: CheckCircled64,
};

export const CheckCircledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'check-circled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

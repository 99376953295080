import { forwardRef } from 'react';

import { useDrawerContext } from '../drawer.context';
import type { DrawerContentProps } from '../drawer.types';
import { StyledDrawerContent } from './drawerContent.styles';

export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  (
    {
      children,
      className,
      'data-testid': testId,
      id,
      noPadding = false,
      style,
    },
    ref
  ) => {
    const { fitScreen } = useDrawerContext();
    return (
      <StyledDrawerContent
        className={className}
        data-testid={testId}
        fitScreen={fitScreen}
        id={id}
        noPadding={noPadding}
        ref={ref}
        style={style}
      >
        {children}
      </StyledDrawerContent>
    );
  }
);

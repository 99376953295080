import { useEffect, useMemo, useState } from 'react';

import { useDeleteMenuRow } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type { MenuRow } from '@jane/business-admin/types';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import { JANE_DEFINED_ROWS } from '@jane/search/util';
import { ConfirmDeleteModal } from '@jane/shared/components';
import { Feature, isFeatureEnabled } from '@jane/shared/config';
import type { ButtonProps } from '@jane/shared/reefer';
import {
  Button,
  Flex,
  Form,
  Modal,
  useFormContext,
  useToast,
} from '@jane/shared/reefer';
import { rootOnlyCustomRowsAllowed } from '@jane/shared/util';

import { SwitchWithBorder } from '../../../../SwitchWithBorder';

interface DeleteButtonProps extends ButtonProps {
  onDelete: () => void;
}
const DeleteButtonWithConfirmation = ({
  onDelete,
  ...buttonProps
}: DeleteButtonProps) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  return (
    <>
      <Button
        {...buttonProps}
        variant="destructive"
        label="Delete"
        onClick={() => setConfirmDeleteModalOpen(true)}
      />
      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          setOpen={setConfirmDeleteModalOpen}
          confirmDelete={onDelete}
        />
      )}
    </>
  );
};

interface Props {
  closeModal: () => void;
  formErrorName: string;
  isCreateMode: boolean;
  row?: MenuRow;
  storeId: string;
}
export const ModalHeader = ({
  closeModal,
  formErrorName,
  isCreateMode,
  row,
  storeId,
}: Props) => {
  const { mutateAsync: deleteMenuRow, isSuccess: deleteSuccess } =
    useDeleteMenuRow(storeId, row?.id || 0);
  const toast = useToast();
  const {
    setError,
    formState: { isDirty },
  } = useFormContext();
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error deleting menu row. Please try again.`
  );

  const showRootsOnlyToggle =
    isFeatureEnabled(Feature.RootsOnlyCustomRows) &&
    rootOnlyCustomRowsAllowed(storeId);

  const showDeleteButton = useMemo(() => {
    if (isCreateMode) return false;
    return !JANE_DEFINED_ROWS.includes(row?.row_type || '');
  }, [row, isCreateMode]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.add({
        label: `Menu row deleted!`,
        variant: 'success',
      });
      closeModal();
    }
  }, [deleteSuccess]);

  const handleDelete = () => {
    track({
      event: EventNames.DeleteStoreEntity,
      modal_name: ModalNames.EditMenuRow,
      entity_name: 'menu_row',
    });
    return catchSubmitErrors({
      submitMethod: () => deleteMenuRow(),
      requestData: {},
      onValidationError: () => null,
      callback: () => {
        setError(formErrorName, {
          message: 'Error deleting menu row. Please try again.',
        });
      },
    });
  };

  return (
    <Modal.Header
      title={`${isCreateMode ? 'Add' : 'Edit'} Menu Row`}
      actions={
        <Flex alignItems="center" gap={16}>
          {showRootsOnlyToggle && (
            <SwitchWithBorder name="roots_only" label="Visible in Roots API" />
          )}
          <SwitchWithBorder
            name="enabled"
            label="Visible"
            onChange={(visible) => {
              const { enabled = false } = row || {};

              if (!isDirty && visible === enabled) return;

              track({
                event: EventNames.ToggleVisibility,
                final_state: visible ? 'visible' : 'hidden',
                object: 'menu row',
                successful: true,
                trigger_source_id: 'row workspace',
              });
            }}
          />
          {showDeleteButton && (
            <DeleteButtonWithConfirmation onDelete={handleDelete} />
          )}
          <Form.SubmitButton variant="primary" label="Publish" />
        </Flex>
      }
    />
  );
};

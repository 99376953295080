import type { ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import { StyledToastOverlay, StyledToastWrapper } from '../toast.styles';
import type { ToastContentProps, ToastsRenderer } from '../toast.types';
import { renderToast } from './renderToast';

export const renderToasts = ({
  position = 'top-right',
  remove,
  toasts,
  verticalOffset = 5,
}: ToastsRenderer): ReactPortal =>
  createPortal(
    <StyledToastOverlay>
      <StyledToastWrapper verticalOffset={verticalOffset} position={position}>
        {toasts.map((toast: ToastContentProps) =>
          renderToast({
            ...toast,
            onClose: remove,
            position,
          })
        )}
      </StyledToastWrapper>
    </StyledToastOverlay>,
    document.body
  );

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Bell24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0001 1C11.0001 0.447715 11.4478 0 12.0001 0C12.5524 0 13.0001 0.447715 13.0001 1V2H13.0502C16.8885 2 20.0001 5.11154 20.0001 8.94983V10.4452C20.0001 12.7631 20.6862 15.0292 21.9719 16.9578C22.8389 18.2582 21.9067 20 20.3438 20H16.0001C16.0001 22.2091 14.2092 24 12.0001 24C9.79094 24 8.00007 22.2091 8.00007 20H3.65637C2.09349 20 1.16129 18.2582 2.02822 16.9578C3.31397 15.0292 4.00007 12.7631 4.00007 10.4452V8.94983C4.00007 5.11154 7.11162 2 10.9499 2H11.0001V1ZM10.0001 20C10.0001 21.1046 10.8955 22 12.0001 22C13.1046 22 14.0001 21.1046 14.0001 20H10.0001ZM12.0001 4H10.9499C8.21619 4 6.00007 6.21611 6.00007 8.94983V10.4452C6.00007 13.1311 5.21292 15.7575 3.73682 18H20.2633C18.7872 15.7575 18.0001 13.1311 18.0001 10.4452V8.94983C18.0001 6.21611 15.784 4 13.0502 4H12.0001Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Bell24,
  sm: Bell24,
  lg: Bell24,
  xl: Bell24,
  xxl: Bell24,
  xxxl: Bell24,
};

export const BellIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bell-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { spacing } from '../../../styling';
import { BASE_CONFIG } from '../../../theme';
import type { SkeletonBoneProps } from './skeletonBone';

type SkeletonBoneFillProps = {
  animate: boolean;
};

const animateSkeleton = keyframes`
  0% { background-position: 100% 0%; }
  100% { background-position: 0% 0%; }
`;

export const StyledSkeletonBone = styled.div<SkeletonBoneProps>(
  ({ width, height, borderRadius, ...marginProps }) => ({
    height,
    minWidth: width,
    width,
    ...(borderRadius && {
      borderRadius:
        borderRadius === 'circular'
          ? '50%'
          : BASE_CONFIG.borderRadius[borderRadius],
    }),
    ...spacing(marginProps),
    overflow: 'hidden',
  })
);

export const SkeletonBoneFill = styled.div<SkeletonBoneFillProps>(
  ({ animate, theme }) => ({
    backgroundColor: theme.colors.grays.light,
    height: '100%',
    width: '100%',
    ...(animate && {
      animation: `${animateSkeleton} 2s linear infinite`,
      backgroundImage: `linear-gradient(90deg,${theme.colors.grays.white},${theme.colors.grays.light},${theme.colors.grays.white},${theme.colors.grays.light})`,
      backgroundSize: '300% 100%',
    }),
  })
);

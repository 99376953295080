/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const SortUp24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7071 14.2929C16.9931 14.5789 17.0787 15.009 16.9239 15.3827C16.7691 15.7564 16.4045 16 16 16L8.00003 16C7.59557 16 7.23093 15.7564 7.07615 15.3827C6.92137 15.009 7.00692 14.5789 7.29292 14.2929L11.2929 10.2929C11.4805 10.1054 11.7348 10 12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929L16.7071 14.2929Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: SortUp24,
  sm: SortUp24,
  lg: SortUp24,
  xl: SortUp24,
  xxl: SortUp24,
  xxxl: SortUp24,
};

export const SortUpIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sort-up-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Menu24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 7C24 6.44772 23.5523 6 23 6H1C0.447715 6 0 6.44772 0 7C0 7.55228 0.447716 8 1 8H23C23.5523 8 24 7.55228 24 7Z"
      fill="#0E0E0E"
    />
    <path
      d="M24 17C24 16.4477 23.5523 16 23 16H1C0.447715 16 0 16.4477 0 17C0 17.5523 0.447716 18 1 18H23C23.5523 18 24 17.5523 24 17Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Menu24,
  sm: Menu24,
  lg: Menu24,
  xl: Menu24,
  xxl: Menu24,
  xxxl: Menu24,
};

export const MenuIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'menu-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Hop24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3795 1.98776C13.0523 2.22065 12.7911 2.73189 12.7911 4.4841H11.2087C11.2087 2.78374 11.4079 1.44877 12.4618 0.698608C12.9621 0.342458 13.5735 0.181358 14.2311 0.0981653C14.8909 0.0147007 15.6852 0.000488281 16.6034 0.000488281C17.0403 0.000488281 17.3946 0.354722 17.3946 0.791692C17.3946 1.22866 17.0403 1.58289 16.6034 1.58289C15.6802 1.58289 14.9783 1.59866 14.4297 1.66806C13.879 1.73773 13.5696 1.85238 13.3795 1.98776Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3795 1.98776C13.0523 2.22065 12.7911 2.73189 12.7911 4.4841H11.2087C11.2087 2.78374 11.4079 1.44877 12.4618 0.698608C12.9621 0.342458 13.5735 0.181358 14.2311 0.0981653C14.8909 0.0147007 15.6852 0.000488281 16.6034 0.000488281C17.0403 0.000488281 17.3946 0.354722 17.3946 0.791692C17.3946 1.22866 17.0403 1.58289 16.6034 1.58289C15.6802 1.58289 14.9783 1.59866 14.4297 1.66806C13.879 1.73773 13.5696 1.85238 13.3795 1.98776Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M16.7986 10.4476C18.9079 11.3549 20.9592 11.3456 22.1223 11.3404C22.2053 11.34 22.2838 11.3396 22.3575 11.3396C22.3575 9.80607 21.8516 6.41696 19.3219 4.18311C18.2617 3.24697 17.2075 2.73633 16.189 2.45898C17.1932 3.42947 17.7541 4.8561 17.7541 6.73793C17.7541 8.13207 17.3658 9.38038 16.7986 10.4476Z"
      fill="#94B200"
    />
    <path
      d="M7.39624 6.73795C7.39624 3.28538 9.69795 2.13452 11.9997 2.13452C14.3014 2.13452 16.6031 3.14152 16.6031 6.73795C16.6031 10.3344 13.5341 12.9238 11.9997 13.6431C10.4652 12.9238 7.39624 10.1905 7.39624 6.73795Z"
      fill="#94B200"
    />
    <path
      d="M7.22878 10.4356C6.64847 9.36088 6.24552 8.11058 6.24552 6.73797C6.24552 4.85363 6.84831 3.4134 7.89511 2.43652C6.85046 2.70843 5.76724 3.22109 4.67774 4.18315C2.14794 6.41701 1.64209 9.8061 1.64209 11.3397C1.71577 11.3397 1.79429 11.34 1.87733 11.3404C3.04549 11.3457 5.10972 11.355 7.22878 10.4356Z"
      fill="#94B200"
    />
    <path
      d="M11.5113 14.685L11.9997 14.914L12.4882 14.685C13.0216 14.435 13.6698 14.0215 14.3255 13.4682C14.3383 13.4918 14.3512 13.5154 14.3642 13.5391C14.6574 14.0711 14.9661 14.5425 15.2846 14.9604C14.7343 17.4163 12.9635 18.5231 11.9997 19.1254C11.0411 18.5262 9.28377 17.3281 8.72363 14.9489C9.03902 14.5339 9.34477 14.0663 9.63528 13.5391C9.65147 13.5097 9.66746 13.4804 9.68327 13.4512C10.3313 14.0101 10.9747 14.4335 11.5113 14.685Z"
      fill="#94B200"
    />
    <path
      d="M7.84062 11.4224C6.09613 12.2114 4.38913 12.4221 3.12475 12.4756C2.41107 14.7093 3.01725 16.2782 3.37739 17.0954C4.53286 16.8037 6.84381 16.2203 8.6274 12.9835C8.6949 12.861 8.75858 12.7403 8.8186 12.6213C8.61436 12.4036 8.41442 12.1735 8.22227 11.9321C8.09244 11.769 7.96475 11.599 7.84062 11.4224Z"
      fill="#94B200"
    />
    <path
      d="M15.1953 12.6492C15.2512 12.7591 15.3101 12.8705 15.3723 12.9834C17.1559 16.2202 19.4669 16.8036 20.6223 17.0953C20.9825 16.2781 21.5887 14.7092 20.875 12.4755C19.6183 12.4223 17.9244 12.2138 16.1911 11.4365C16.0555 11.63 15.9153 11.8155 15.7722 11.9925C15.5858 12.2232 15.3925 12.4423 15.1953 12.6492Z"
      fill="#94B200"
    />
    <path
      d="M10.2749 19.3215C10.0655 19.5879 9.84618 19.8274 9.62168 20.043C9.36754 20.2871 9.10998 20.4972 8.85938 20.6786C9.52768 22.5321 11.1088 23.4991 11.9999 24.0004C12.8976 23.4954 14.4956 22.5965 15.1551 20.6893C14.8999 20.5054 14.6372 20.2918 14.3781 20.043C14.1572 19.8308 13.9412 19.5955 13.7349 19.3342C13.3115 19.6628 12.9208 19.9069 12.6333 20.0865L11.9999 20.4824L11.3899 20.1012C11.0908 19.9143 10.6978 19.6597 10.2749 19.3215Z"
      fill="#94B200"
    />
    <path
      d="M5.21704 17.7158C5.51639 19.1287 6.29629 20.0321 6.77761 20.5481C7.44157 20.2319 8.52855 19.7144 9.42214 18.5434C8.80261 17.8943 8.20823 17.0536 7.82542 15.9744C7.34522 16.4476 6.85556 16.8227 6.37398 17.1218C5.97092 17.3721 5.57992 17.5647 5.21704 17.7158Z"
      fill="#94B200"
    />
    <path
      d="M16.1884 15.9883C16.6641 16.455 17.1488 16.8256 17.6256 17.1217C18.0287 17.372 18.4197 17.5646 18.7825 17.7157C18.4832 19.1286 17.7033 20.032 17.222 20.548C16.5626 20.234 15.486 19.7215 14.5959 18.5674C14.7522 18.4065 14.9067 18.2331 15.0563 18.0462C15.5102 17.4787 15.9093 16.7995 16.1884 15.9883Z"
      fill="#94B200"
    />
  </svg>
);

const SIZE_MAP = {
  md: Hop24,
  sm: Hop24,
  lg: Hop24,
  xl: Hop24,
  xxl: Hop24,
  xxxl: Hop24,
};

export const HopIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'hop-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

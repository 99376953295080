import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../../styling';
import { mobileInputStyling } from '../field.styles';
import type { StyledFieldProps } from '../field.types';

export const StyledInput = styled.input<StyledFieldProps>(
  {
    '&::-webkit-calendar-picker-indicator': {
      bottom: 0,
      height: 'auto',
      left: 0,
      background: 'transparent',
      position: 'absolute',
      color: 'transparent',
      top: 0,
      right: 0,
      width: 'auto',
    },
    '&:disabled': {
      opacity: `0.3`,
    },
    height: '48px',
    outline: 'none',
    position: 'relative',
    width: '100%',
  },
  ({
    disableMobileInputStyling,
    theme: { borderRadius, colors },
    radiusSize,
  }) => ({
    background: colors.grays.white,
    border: `1px solid ${colors.grays.light}`,
    borderRadius: borderRadius[radiusSize],
    ...spacing({ px: 16, py: 12 }),
    '&:active, &:focus, &:focus-visible': {
      borderColor: colors.primary.main,
    },
    '&:read-only, &:disabled': {
      borderColor: colors.grays.light,
    },
    [mediaQueries.tablet('max')]: [
      !disableMobileInputStyling && {
        ...mobileInputStyling(),
        backgroundColor: 'transparent',
      },
    ],
  })
);

import type { BaseFormProps } from './form/baseForm/baseForm';
import { BaseForm } from './form/baseForm/baseForm';
import type { FormProps } from './form/form';
import { Form as HookForm } from './form/form';
import type { FormError } from './form/formValidationError';
import type { FormCheckboxFieldProps } from './formCheckboxField/formCheckboxField';
import { FormCheckboxField } from './formCheckboxField/formCheckboxField';
import type { FormDateTimeFieldProps } from './formDateTimeField/formDateTimeField';
import { FormDateTimeField } from './formDateTimeField/formDateTimeField';
import type { FormErrorBannerProps } from './formErrorBanner/formErrorBanner';
import { FormErrorBanner } from './formErrorBanner/formErrorBanner';
import type { FormNumberFieldProps } from './formNumberField/formNumberField';
import { FormNumberField } from './formNumberField/formNumberField';
import type {
  FormRadioFieldGroupOption,
  FormRadioFieldGroupProps,
} from './formRadioFieldGroup/formRadioFieldGroup';
import { FormRadioFieldGroup } from './formRadioFieldGroup/formRadioFieldGroup';
import type { FormSearchFieldProps } from './formSearchField/formSearchField';
import { FormSearchField } from './formSearchField/formSearchField';
import type { FormSelectFieldProps } from './formSelectField/formSelectField';
import { FormSelectField } from './formSelectField/formSelectField';
import type { FormSliderFieldProps } from './formSliderField/formSliderField';
import { FormSliderField } from './formSliderField/formSliderField';
import type { FormSubmitButtonProps } from './formSubmitButton/formSubmitButton';
import { FormSubmitButton } from './formSubmitButton/formSubmitButton';
import type { FormSwitchFieldProps } from './formSwitchField/formSwitchField';
import { FormSwitchField } from './formSwitchField/formSwitchField';
import type { FormTextAreaFieldProps } from './formTextAreaField/formTextAreaField';
import { FormTextAreaField } from './formTextAreaField/formTextAreaField';
import type { FormTextFieldProps } from './formTextField/formTextField';
import { FormTextField } from './formTextField/formTextField';

export type {
  BaseFormProps,
  FormCheckboxFieldProps,
  FormDateTimeFieldProps,
  FormError,
  FormErrorBannerProps,
  FormNumberFieldProps,
  FormProps,
  FormRadioFieldGroupOption,
  FormRadioFieldGroupProps,
  FormSearchFieldProps,
  FormSelectFieldProps,
  FormSliderFieldProps,
  FormSubmitButtonProps,
  FormSwitchFieldProps,
  FormTextAreaFieldProps,
  FormTextFieldProps,
};

export const Form = Object.assign(HookForm, {
  BaseForm: BaseForm,
  CheckboxField: FormCheckboxField,
  DateTimeField: FormDateTimeField,
  ErrorBanner: FormErrorBanner,
  NumberField: FormNumberField,
  RadioFieldGroup: FormRadioFieldGroup,
  SearchField: FormSearchField,
  SelectField: FormSelectField,
  SliderField: FormSliderField,
  SubmitButton: FormSubmitButton,
  SwitchField: FormSwitchField,
  TextAreaField: FormTextAreaField,
  TextField: FormTextField,
});

export { FormFieldWrapper } from './formFieldWrapper/formFieldWrapper';
export { FormValidationError } from './form/formValidationError';
export { useForm } from './form/formContext';

export enum ModalNames {
  AddBoostConfig = 'Add Configuration',
  AddCheckoutAgreement = 'Add Checkout Agreement',
  AddPrinter = 'Add Printer',
  AdditionalDismissalMessages = 'Additional Dismissal Messages',
  Appearance = 'Appearance',
  ArrangeMenuRows = 'ArrangeMenuRows',
  BrandConditions = 'Brand Conditions',
  BulkEditProducts = 'BulkEditProducts',
  BulkEditSpecials = 'Bulk Edit Specials',
  BulkEditStackingSettings = 'Bulk Edit Stacking Settings',
  CartLimitRules = 'Cart Limit Rules',
  CartLimitStoreSelect = 'Cart Limit Store Select',
  CartLimits = 'Cart Limits',
  CategoryConditions = 'Category Conditions',
  CheckoutAgreementReportModal = 'Checkout Agreement Report Modal',
  CheckoutAgreements = 'Checkout Agreements',
  CheckoutOptions = 'Checkout Options',
  CommsBanner = 'Modify Communication Banner',
  CommsBannerPublishTo = 'Communication Banner Publish To',
  CreateCartLimitPolicy = 'Create Cart Limit Policy',
  CreateMenuRow = 'Create Menu Row',
  CreateSpecial = 'Create Special',
  DeliverySettings = 'Delivery Settings',
  Discounts = 'Discounts',
  EditBoostConfig = 'Edit configuration',
  EditCartLimitPolicy = 'Edit Cart Limit Policy',
  EditCheckoutAgreement = 'Edit Checkout Agreement',
  EditMenuRow = 'Edit Menu Row',
  EditPrinter = 'Edit Printer',
  EditProduct = 'Edit Product',
  EditStaffMember = 'Edit Staff Member',
  FiltersAndLabels = 'Filters and Labels',
  Geofence = 'Geofence',
  GoogleAnalytics = 'Google Analytics',
  InviteStaffMember = 'Invite Staff Member',
  KioskNotifications = 'Kiosk Notifications',
  KioskSettings = 'Kiosk Settings',
  LoyaltySettings = 'Loyalty Settings',
  MenuRowsModal = 'Menu Rows Modal',
  Notifications = 'Notifications',
  OrderTickets = 'Order Tickets',
  Payments = 'Payments',
  PickupAndDeliveryNotifications = 'Pickup And Delivery Notifications',
  PrinterStoreSelector = 'Printer Store Selector',
  ProductAutoPublish = 'Product Auto-publish',
  ProductSearch = 'Product Search',
  SmsSettings = 'SmsSettings',
  SpecialsStacking = 'Specials Stacking',
  StaffMemberStoreSelect = 'Staff Member Store Select',
  StoreDetails = 'Store Details',
  StoreHours = 'Store Hours',
  Subdomain = 'Subdomain',
  TaxesAndFees = 'Taxes and Fees',
  UpdateMenuProduct = 'Update Menu Product',
  UpdateStaffMember = 'Update Staff Member',
  UpdateUnpublishedProduct = 'Update Unpublished Product',
}

export enum CardNames {
  CartLimits = 'Cart Limits',
  ChatSettings = 'Chat Settings',
  EmailNotifications = 'Email Notifications',
  FulfillmentSettings = 'Fulfillment Settings',
  OrderAhead = 'Order Ahead',
}

export enum FilterNames {
  ProductBrand = 'Product Brand',
  ProductBrandSubtype = 'Product Brand Subtype',
  ProductCategory = 'Product Category',
  ProductLineage = 'Product Lineage',
  ProductSubcategory = 'Product Subcategory',
  ProductWeight = 'Product Weight',
  StoreState = 'Store State',
  StoreType = 'Store Type',
}

export enum NavigationSourceIds {
  AnalyticsSideNav = 'Analytics Side Nav',
  Automatic = 'Automatic',
  BusinessNav = 'Business Nav',
  ForgotPasswordLink = 'Forgot Password Link',
  Login = 'Login',
  ProductDetailModal = 'Product Detail Modal',
  StoreCard = 'Store Card',
  StoreDetailsSidenav = 'Store Details Sidenav',
  StoreDetailsTabs = 'Store Details Tabs',
  StoreSwitcherClick = 'Store Switcher (Click)',
  StoreSwitcherKeyboard = 'Store Switcher (Keyboard)',
  TableToggle = 'Table Toggle',
}

export enum ExternalLinkSourceIds {
  BusinessNavHamburger = 'Business Nav Hamburger',
  CheckoutAgreementDetails = 'Checkout Agreement Details',
  GoogleAnalyticsModal = 'Google Analytics Modal',
  InfoPopover = 'Info Popover',
  NotificationsPopover = 'Notifications Popover',
}

export enum EventNames {
  AccessDenied = 'Access Denied',
  BulkEdited = 'Bulk Edited',
  ChangedStore = 'Changed Store',
  ClickedButton = 'Clicked Button',
  ClosedModal = 'Closed Modal',
  CompletedRegistration = 'Completed Registration',
  ContactedUs = 'Contacted Us',
  DeleteStoreEntity = 'Delete Store Entity',
  DeselectedCheckbox = 'Deselected Checkbox',
  DeselectedFilter = 'Deselected Filter',
  DeselectedObject = 'Deselected Object',
  DiscardChanges = 'Discard Changes',
  EditSelectedProducts = 'Edit Selected Products',
  EditedMenu = 'Edited Menu',
  EditedMenuProduct = 'Edited Menu Product',
  EditedPassword = 'Edited Password',
  EditedPhoto = 'Edited Photo',
  EditedPrinter = 'Edited Printer',
  EditedSpecial = 'Edited Special',
  EditedStaffMember = 'Edited Staff Member',
  EditedStoreMenuConfig = 'Edited Store Menu Config',
  EditedStoreProduct = 'Edited Store Product',
  EditedStoreSettings = 'Edited Store Settings',
  EditedUserProfile = 'Edited User Profile',
  HorizontalScroll = 'Horizontal Scroll',
  JanePayGetInTouch = 'Jane Pay Get In Touch',
  LoggedIn = 'Logged In',
  LoggedOut = 'Logged Out',
  ModifiedSetting = 'Modified Setting',
  Navigated = 'Navigated',
  NavigatedToLegacy = 'Navigated Back to Legacy',
  OpenExternalLink = 'Open External Link',
  OpenedModal = 'Opened Modal',
  OpenedNotificationLink = 'Opened Notifications Link',
  OpenedStoreSwitcher = 'Opened Store Switcher',
  ReorderedMenuRows = 'Reordered Menu Rows',
  Search = 'Search',
  SelectedCheckbox = 'Selected Checkbox',
  SelectedFilter = 'Selected Filter',
  SelectedObject = 'Selected Object',
  SubmitFeedbackForm = 'Submit Feedback Form',
  ToggleEnabled = 'Toggle Enabled',
  ToggleVisibility = 'Toggle Visibility',
  ViewedNotification = 'Viewed Notification',
}

export enum SettingNames {
  BundleLabel = 'Bundle Label',
  CommsBannerAltText = 'Communication Banner Alt Text',
  CommsBannerButtonLink = 'Communication Banner Button Link',
  CommsBannerButtonText = 'Communication Banner Button Text',
  CommsBannerDisplayStyle = 'Communication Banner Display Style',
  CommsBannerEnabled = 'Communication Banner Enabled',
  CommsBannerHeader = 'Communication Banner Header',
  CommsBannerImageFile = 'Communication Banner Image File',
  CommsBannerMessage = 'Communications Banner Message',
  CommsBannerType = 'Communication Banner Type',
  DefaultMenuFormat = 'Default Menu Format',
  FulfillmentLabelCurbside = 'Fulfillment Label - Curbside',
  FulfillmentLabelDelivery = 'Fulfillment Label - Delivery',
  FulfillmentLabelPickup = 'Fulfillment Label - Pickup',
  GeofenceCartMinimum = 'Geofence Cart Minimum',
  GeofenceDeliveryFee = 'Geofence Delivery Fee',
  GeofenceDisplayWindowsByDay = 'Geofence Display Windows By Day',
  GeofenceGeoJSONText = 'Geofence GeoJSON Text',
  GeofenceIntervalHours = 'Geofence Interval Hours',
  GeofenceIntervalMinutes = 'Geofence Interval Minutes',
  GeofenceLastCallMinutes = 'Geofence Last Call Minutes',
  GeofenceLeadTime = 'Geofence Lead Time',
  GeofenceMaxDays = 'Geofence Max Days',
  GeofenceMaxHours = 'Geofence Max Hours',
  GeofenceMaxMinutes = 'Geofence Max Minutes',
  GeofenceMaxOrdersPerWindow = 'Geofence Max Orders Per Window',
  GeofenceMinimumDays = 'Geofence Minimum Days',
  GeofenceMinimumHours = 'Geofence Minimum Hours',
  GeofenceMinimumMinutes = 'Geofence Minimum Minutes',
  GeofenceName = 'Geofence Name',
  GeofenceWindowHours = 'Geofence Window Hours',
  GeofenceWindowMinutes = 'Geofence Window Minutes',
  HiddenBrand = 'Hidden Brand',
  HiddenBrandSubtype = 'Hidden Brand Subtype',
  HiddenCBD = 'Hidden Product CBD',
  HiddenCBDA = 'Hidden Product CBDA',
  HiddenCategory = 'Hidden Category',
  HiddenLineage = 'Hidden Lineage',
  HiddenProductDescription = 'Hidden Product Description',
  HiddenProductDosage = 'Hidden Product Dosage',
  HiddenProductName = 'Hidden Product Name',
  HiddenProductSize = 'Hidden Product Size',
  HiddenSubcategory = 'Hidden Subcategory',
  HiddenTHC = 'Hidden Product THC',
  HiddenTHCA = 'Hidden Product THCA',
  LineageLabelCBD = 'Lineage Label - CBD',
  LineageLabelHybrid = 'Lineage Label - Hybrid',
  LineageLabelIndica = 'Lineage Label - Indica',
  LineageLabelSativa = 'Lineage Label - Sativa',
  MenuNavigationColor = 'Menu Navigation Color',
  MenuRowDisplayName = 'Menu Row Display Name',
  MenuRowOrder = 'Menu Row Order',
  MenuThemeColor = 'Menu Theme Color',
  OrderTicketsGroupByCategory = 'Order Tickets - Group by category',
  PdpMenuRows = 'PDP Menu Rows',
  PrinterMACAddress = 'Printer MAC Address',
  PrinterName = 'Printer Name',
  ProductCategory = 'Product Category',
  ProductDetailPageHideDescription = 'Product Detail Page - Hide Descriptions',
  ProductDetailPageHideEffects = 'Product Detail Page - Hide top feelings',
  ProductImages = 'Product Images',
  ProductSubcategory = 'Product Subcategory',
  ProductSubcategoryLabel = 'Product Subcategory Label',
  PublishedBrand = 'Published Brand',
  PublishedBrandSubtype = 'Published Brand Subtype',
  PublishedCBD = 'Published Product CBD',
  PublishedCBDA = 'Published Product CBDA',
  PublishedCategory = 'Published Category',
  PublishedLineage = 'Published Lineage',
  PublishedProductDescription = 'Published Product Description',
  PublishedProductDosage = 'Published Product Dosage',
  PublishedProductName = 'Published Product Name',
  PublishedProductSize = 'Published Product Size',
  PublishedSubcategory = 'Published Subcategory',
  PublishedTHC = 'Published Product THC',
  PublishedTHCA = 'Published Product THCA',
  SalesBadgeLabel = 'Sales Badge Label',
  SpecialsLabel = 'Specials Label',
  UnpublishedBrand = 'Unpublished Brand',
  UnpublishedBrandSubtype = 'Unpublished Brand Subtype',
  UnpublishedCBD = 'Unpublished Product CBD',
  UnpublishedCBDA = 'Unpublished Product CBDA',
  UnpublishedCategory = 'Unpublished Category',
  UnpublishedLineage = 'Unpublished Lineage',
  UnpublishedProductDescription = 'Unpublished Product Description',
  UnpublishedProductDosage = 'Unpublished Product Dosage',
  UnpublishedProductName = 'Unpublished Product Name',
  UnpublishedProductSize = 'Unpublished Product Size',
  UnpublishedSubcategory = 'Unpublished Subcategory',
  UnpublishedTHC = 'Unpublished Product THC',
  UnpublishedTHCA = 'Unpublished Product THCA',
}

export enum ErrorReasons {
  InvalidParams = '400 Bad Request - Invalid Parameters',
  ServerError = 'Server Error',
}

export enum SearchSubjects {
  PrinterStoreSelector = 'Printer Store Selector',
  Products = 'Products',
  Specials = 'Specials',
  Stores = 'Stores',
}

interface LoggedIn {
  event: EventNames.LoggedIn;
  to_url: string;
}

interface LoggedOut {
  event: EventNames.LoggedOut;
  from_url: string;
}

interface Navigated {
  event: EventNames.Navigated;
  from_url: string;
  to_url: string;
  trigger_source_id: string;
}

interface AccessDenied {
  event: EventNames.AccessDenied;
  reason: string;
}

interface ContactedUs {
  event: EventNames.ContactedUs;
}

interface OpenExternalLink {
  event: EventNames.OpenExternalLink;
  from_url: string;
  to_url: string;
  trigger_source_id: string;
}

interface NavigatedToLegacy {
  event: EventNames.NavigatedToLegacy;
  from_url: string;
  to_url: string;
}

interface OpenedStoreSwitcher {
  event: EventNames.OpenedStoreSwitcher;
  trigger_source_id:
    | NavigationSourceIds.StoreSwitcherClick
    | NavigationSourceIds.StoreSwitcherKeyboard;
}

interface ChangedStore {
  event: EventNames.ChangedStore;
  from_store_id: string;
  to_store_id: string;
}

interface EditedUserProfile {
  changed_attributes: string[];
  event: EventNames.EditedUserProfile;
}

interface EditedPassword {
  event: EventNames.EditedPassword;
}

interface OpenedModal {
  event: EventNames.OpenedModal;
  modal_name: string;
  url: string;
}

interface ClosedModal {
  event: EventNames.ClosedModal;
  modal_name: string;
  url: string;
}

interface ClickedButton {
  button_label: string;
  event: EventNames.ClickedButton;
  modal_name: ModalNames | string;
}

interface DiscardChanges {
  event: EventNames.DiscardChanges;
  modal_name: string;
}

interface EditedStoreSettings {
  card_name?: CardNames | string;
  changed_attributes: string[];
  event: EventNames.EditedStoreSettings;
  modal_name?: ModalNames | string;
}

interface DeleteStoreEntity {
  entity_name: string;
  event: EventNames.DeleteStoreEntity;
  modal_name: ModalNames | string;
}

interface EditedPhoto {
  action: 'upload' | 'rearrange' | 'delete';
  event: EventNames.EditedPhoto;
  file_size: string;
  file_type: string;
  settings_name: string;
  url: string;
}

interface SubmitFeedbackForm {
  event: EventNames.SubmitFeedbackForm;
  source: string;
}

interface SelectedFilter {
  all_selected_values: string[];
  event: EventNames.SelectedFilter;
  filter_name: FilterNames | string;
  modal_name?: ModalNames;
  selected_value: string;
  url: string;
}

interface SelectedObject {
  event: EventNames.SelectedObject;
  objects: string | string[];
  trigger_source_id: string;
}

interface DeselectedObject {
  event: EventNames.DeselectedObject;
  objects: string | string[];
  trigger_source_id: string;
}

interface EditedStoreProduct {
  action: 'edited' | 'hid' | 'archived' | 'published' | string;
  changed_attributes: string[];
  error_reason?: string;
  event: EventNames.EditedStoreProduct;
  modal_name: ModalNames | string;
  successful: boolean;
  type: string;
  url: string;
}

interface DeselectedFilter {
  all_selected_values: string[];
  deselected_value: string;
  event: EventNames.DeselectedFilter;
  filter_name: FilterNames | string;
  modal_name?: ModalNames;
  url: string;
}

interface EditedMenu {
  card_name?: CardNames | string;
  changed_attributes: string[];
  event: EventNames.EditedMenu;
  modal_name?: ModalNames | string;
}

interface EditedStoreMenuConfig {
  action?: 'create' | 'update' | 'delete';
  changed_attributes: string[];
  error_reason?: ErrorReasons;
  event: EventNames.EditedStoreMenuConfig;
  modal_name: ModalNames | string;
  setting_name: string;
  successful: boolean;
  type?: string;
  url: string;
}

interface EditedStaffMember {
  action: 'create' | 'update' | 'delete';
  changed_attributes: string[];
  event: EventNames.EditedStaffMember;
}

interface EditedPrinter {
  action: 'create' | 'update' | 'delete';
  changed_attributes: string[];
  event: EventNames.EditedPrinter;
  modal_name: ModalNames;
}

interface EditedMenuProduct {
  action: 'update';
  changed_attributes: string[];
  event: EventNames.EditedMenuProduct;
}

interface CompletedRegistration {
  attributes: string[];
  event: EventNames.CompletedRegistration;
}

interface NotificationEvent {
  critical_state: boolean;
  notification_header: string | null;
  notification_subtext: string | null;
  notification_type: string;
  to_url: string | null;
}

interface ViewedNotification extends NotificationEvent {
  event: EventNames.ViewedNotification;
}

interface OpenedNotificationLink extends NotificationEvent {
  event: EventNames.OpenedNotificationLink;
}

interface JanePayGetInTouch {
  event: EventNames.JanePayGetInTouch;
  notification_type: string;
  store_id?: string | number | null;
}

interface ToggleVisibility {
  error_reason?: string;
  event: EventNames.ToggleVisibility;
  final_state: 'hidden' | 'visible';
  object: string;
  successful: boolean;
  trigger_source_id: string;
}

interface ToggleEnabled {
  error_reason?: string;
  event: EventNames.ToggleEnabled;
  final_state: 'enabled' | 'disabled';
  object: string;
  successful: boolean;
  trigger_source_id: string;
}

interface ModifiedSetting {
  action?: string;
  event: EventNames.ModifiedSetting;
  modal_name?: ModalNames;
  revert: boolean;
  setting_name: SettingNames;
}

interface HorizontalScroll {
  event: EventNames.HorizontalScroll;
  trigger_source_id: string;
}

interface SelectedCheckbox {
  checkbox_label: string;
  event: EventNames.SelectedCheckbox;
  modal_name?: ModalNames;
}

interface DeselectedCheckbox {
  checkbox_label: string;
  event: EventNames.DeselectedCheckbox;
  modal_name?: ModalNames;
}

// `successful` and `error_reason` apply more to searches that make API requests
interface Search {
  arguments: string;
  error_reason?: string;
  event: EventNames.Search;
  subject: SearchSubjects;
  successful?: boolean;
}

interface EditSelectedProducts {
  event: EventNames.EditSelectedProducts;
  final_product_count: number;
  initial_product_count: number;
}

interface BulkEdited {
  action: 'make visible' | 'hide' | 'delete' | 'archive' | 'publish' | string;
  event: EventNames.BulkEdited;
  objects: string[];
}

interface EditSpecial {
  action?: 'create' | 'duplicate' | 'update';
  changed_attributes: string[];
  event: EventNames.EditedSpecial;
}

interface EditSpecial {
  event: EventNames.EditedSpecial;
}

export type TrackedEvent =
  | LoggedIn
  | LoggedOut
  | Navigated
  | AccessDenied
  | ContactedUs
  | OpenExternalLink
  | NavigatedToLegacy
  | OpenedStoreSwitcher
  | ChangedStore
  | EditedUserProfile
  | EditedPassword
  | OpenedModal
  | ClosedModal
  | DiscardChanges
  | EditedStoreSettings
  | DeleteStoreEntity
  | EditedPhoto
  | ClickedButton
  | SubmitFeedbackForm
  | SelectedFilter
  | DeselectedFilter
  | EditedMenu
  | EditedMenuProduct
  | EditedPrinter
  | EditedStoreMenuConfig
  | EditedStaffMember
  | CompletedRegistration
  | ViewedNotification
  | OpenedNotificationLink
  | ToggleVisibility
  | ToggleEnabled
  | ModifiedSetting
  | HorizontalScroll
  | SelectedCheckbox
  | DeselectedCheckbox
  | Search
  | EditSelectedProducts
  | BulkEdited
  | SelectedObject
  | DeselectedObject
  | EditSpecial
  | EditedStoreProduct
  | JanePayGetInTouch;

import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import type { ReeferBaseProps } from '../../../types';

/**
 * Popover.Divider is a border used to divide popover content when necessary.
 */

const StyledDivider = styled.hr(({ theme }) => ({
  backgroundColor: theme.colors.grays.light,
  border: 'none',
  height: 1,
  ...spacing({ mx: -24, my: 24 }),
  [mediaQueries.tablet('min')]: {
    ...spacing({ mx: -16, my: 16 }),
  },
}));

export const PopoverDivider = ({
  'data-testid': testId,
  id,
}: ReeferBaseProps) => <StyledDivider data-testid={testId} id={id} />;

import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import type { StyledPopoverContentProps } from '../popover.types';

export const StyledPopoverContent = styled.div<StyledPopoverContentProps>(
  ({ maxHeight, padding }) => ({
    '::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: maxHeight || '90vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    ...(padding && {
      ...spacing({ p: 24 }),
      [mediaQueries.tablet('min')]: {
        ...spacing({ p: 16 }),
      },
    }),
    [mediaQueries.tablet('min')]: {
      maxHeight: maxHeight || '420px',
    },
  })
);

import { useState } from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { useManager } from '@jane/business-admin/data-access';
import { useLogoutAndNavigateToLogin } from '@jane/business-admin/hooks';
import {
  EventNames,
  ExternalLinkSourceIds,
  NavigationSourceIds,
  businessPaths,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Button,
  Flex,
  HeartFilledIcon,
  InfoIcon,
  List,
  MenuIcon,
  Popover,
  ProfileIcon,
  Tag,
  Typography,
} from '@jane/shared/reefer';
import type { PopoverContextProps } from '@jane/shared/reefer';

import { ChangePasswordModal } from './ChangePasswordModal';
import { ContactUsModal } from './ContactUsModal';
import { EditProfileModal } from './EditProfileModal';
import { NotificationsPopover } from './NotificationsPopover';

const NavigationLink = ({ to, title }: { title: ReactNode; to: string }) => {
  const { id: storeId = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const regularStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 15,
    padding: '0 20px',
    height: 45,
    border: '2px solid',
    borderColor: 'transparent',
    textDecoration: 'none',
  };

  const activeStyle: CSSProperties = {
    ...regularStyle,
    borderColor: '#5E1FF0',
  };

  const trackNavigation = () => {
    track({
      event: EventNames.Navigated,
      from_url: normalizePath(pathname, storeId),
      to_url: to,
      trigger_source_id: NavigationSourceIds.BusinessNav,
    });
  };

  return (
    // Have to use this link instead of the Reefer link, since we're using React Router 6
    <NavLink
      onClick={trackNavigation}
      to={to}
      style={({ isActive }) => (isActive ? activeStyle : regularStyle)}
    >
      <Typography as="span" color="grays-black" variant="body-bold">
        {title}
      </Typography>
    </NavLink>
  );
};

const trackExternalLink = (
  storeId: string,
  sourceId: ExternalLinkSourceIds,
  from_url: string,
  to_url: string
) => {
  track({
    event: EventNames.OpenExternalLink,
    from_url: normalizePath(from_url, storeId),
    to_url: normalizePath(to_url, storeId),
    trigger_source_id: sourceId,
  });
};

const HamburgerPopover = () => {
  const { id: storeId = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const retailDashboardLink = 'https://retail.iheartjane.com';
  return (
    <Popover
      target={
        <Button.Icon
          icon={<MenuIcon color="grays-black" />}
          variant="minimal"
        />
      }
      openOn="click"
      alignment={{ horizontal: 'left' }}
      label="Application Menu"
    >
      <Popover.Content label="info" padding={false}>
        <List label="application list options">
          <List.Item
            onClick={() =>
              trackExternalLink(
                storeId,
                ExternalLinkSourceIds.BusinessNavHamburger,
                pathname,
                retailDashboardLink
              )
            }
            href={retailDashboardLink}
            px={24}
            pb={24}
          >
            <Typography>Jane Retail</Typography>
          </List.Item>
        </List>
      </Popover.Content>
    </Popover>
  );
};

const LeftNav = () => {
  const plusEnabled = useFlag(FLAGS.bloomPlusBetaNav);
  const hasAnalyticsAccess = useFlag(FLAGS.scAnalyticsBetaAccess);

  return (
    <Flex alignItems="center" as="ul">
      <Flex px={12}>
        <li>
          <HamburgerPopover />
        </li>
      </Flex>
      <Flex>
        <li>
          <NavigationLink to={businessPaths.stores()} title="Stores" />
        </li>
        <li>
          <NavigationLink to={businessPaths.specials()} title="Specials" />
        </li>
        {hasAnalyticsAccess ? (
          <li>
            <NavigationLink to={businessPaths.analytics()} title="Analytics" />
          </li>
        ) : null}
        {plusEnabled && (
          <li>
            <Flex alignItems="center">
              <NavigationLink
                to={businessPaths.plus()}
                title={
                  <Flex>
                    Plus <Tag ml={8} background="grays-black" label="Beta" />
                  </Flex>
                }
              />
            </Flex>
          </li>
        )}
      </Flex>
    </Flex>
  );
};

const UserPopover = () => {
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);
  const logout = useLogoutAndNavigateToLogin();
  const { data: profile, isFetched } = useManager();

  return (
    <>
      <Popover
        target={
          <Button.Icon
            icon={<ProfileIcon color="grays-black" />}
            variant="minimal"
          />
        }
        mx={20}
        openOn="click"
        alignment={{ horizontal: 'right' }}
        label={'Account Settings'}
      >
        {({ closePopover }: PopoverContextProps) => (
          <Popover.Content label="test" padding={false}>
            <List label="test-list">
              {isFetched && (
                <List.Item px={24} pt={24}>
                  <Typography variant="body-bold">
                    {profile?.manager?.name}
                  </Typography>
                </List.Item>
              )}
              <List.Item
                px={24}
                onClick={() => {
                  closePopover();
                  setEditProfileModalOpen(true);
                }}
              >
                <Typography>Edit Profile</Typography>
              </List.Item>
              <List.Item
                onClick={() => {
                  closePopover();
                  setChangePasswordModalOpen(true);
                }}
                px={24}
              >
                <Typography>Change Password</Typography>
              </List.Item>
              {/*
              NOTE: This just sends an email with no contact info to PS - not what we want
              <List.Item
                onClick={() => {
                  closePopover();
                  setContactUsModalOpen(true);
                }}
                px={24}
              >
                <Typography>Contact Us</Typography>
              </List.Item> */}
              <List.Item onClick={logout} px={24} pb={24}>
                <Typography color="error">Logout</Typography>
              </List.Item>
            </List>
          </Popover.Content>
        )}
      </Popover>
      <EditProfileModal
        open={editProfileModalOpen}
        setOpen={setEditProfileModalOpen}
      />
      <ChangePasswordModal
        open={changePasswordModalOpen}
        setOpen={setChangePasswordModalOpen}
      />
      <ContactUsModal
        open={contactUsModalOpen}
        setOpen={setContactUsModalOpen}
      />
    </>
  );
};

const InfoPopover = () => {
  const { id: storeId = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const janePartnerHelpLibraryLink =
    'https://docs.iheartjane.com/partner-success';
  const supportTicketLink = 'https://janesupport.force.com/s/support';
  const statusLink = 'https://status.iheartjane.com';
  const businessSolutionsLink = businessPaths.businessSolutions();

  return (
    <Popover
      target={
        <Button.Icon
          icon={<InfoIcon color="grays-black" />}
          variant="minimal"
        />
      }
      openOn="click"
      alignment={{ horizontal: 'right' }}
      label="Info"
    >
      <Popover.Content label="info" padding={false}>
        <List label="info list options" py={12}>
          <List.Item
            onClick={() =>
              trackExternalLink(
                storeId,
                ExternalLinkSourceIds.InfoPopover,
                pathname,
                janePartnerHelpLibraryLink
              )
            }
            href={janePartnerHelpLibraryLink}
            px={24}
          >
            <Typography>Jane Partner Help Library</Typography>
          </List.Item>
          <List.Item
            onClick={() =>
              trackExternalLink(
                storeId,
                ExternalLinkSourceIds.InfoPopover,
                pathname,
                supportTicketLink
              )
            }
            href={supportTicketLink}
            px={24}
          >
            <Typography>Submit a Support Ticket</Typography>
          </List.Item>
          <List.Item
            onClick={() =>
              trackExternalLink(
                storeId,
                ExternalLinkSourceIds.InfoPopover,
                pathname,
                statusLink
              )
            }
            href={statusLink}
            px={24}
          >
            <Typography>System Status</Typography>
          </List.Item>
          <List.Item
            onClick={() =>
              trackExternalLink(
                storeId,
                ExternalLinkSourceIds.InfoPopover,
                pathname,
                businessSolutionsLink
              )
            }
            to={businessSolutionsLink}
            px={24}
          >
            <Typography>Explore Solutions</Typography>
          </List.Item>
        </List>
      </Popover.Content>
    </Popover>
  );
};

const RightNav = () => (
  <Flex alignItems="center" as="ul">
    <li>
      <NotificationsPopover />
    </li>
    <li>
      <InfoPopover />
    </li>
    <li>
      <UserPopover />
    </li>
    <li>
      <HeartFilledIcon ml={20} />
    </li>
  </Flex>
);

export const BusinessNav = () => (
  <Flex
    ariaLabel="Main Navigation"
    as="nav"
    justifyContent="space-between"
    height={80}
    px={32}
  >
    <LeftNav />
    <RightNav />
  </Flex>
);

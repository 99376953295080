import type { CSSObject } from '@emotion/styled';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { spacing } from '../../../styling';
import {
  getBorderRadiusValue,
  getComponentStyles,
} from '../../../utils/componentStyles';
import type {
  IconButtonStyleProps,
  IconButtonStylePropsWithTheme,
} from '../button.types';
import { getButtonVariantStyle } from '../buttonVariant.styles';

const baseIconButtonStyle: CSSObject = {
  alignItems: 'center',
  borderWidth: 0,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
  textAlign: 'center' as const,
  textDecoration: 'none',
  transition: 'all 250ms',
};

const dynamicIconButtonStyles: (
  params: IconButtonStylePropsWithTheme
) => CSSObject = ({ full, theme, variant, disabled, size, ...marginProps }) => {
  const buttonStyles = getComponentStyles('Button', theme, variant);
  const borderRadius = getBorderRadiusValue(buttonStyles.borderRadius, theme);
  const sizeMap = theme.components.Button.icons.sizes[size];

  return {
    borderRadius: sizeMap.borderRadius ?? borderRadius,
    height: sizeMap.height,
    width: full ? '100%' : sizeMap.height,
    ...(disabled && { opacity: '0.3', pointerEvents: 'none' }),
    ...spacing(marginProps),
    ...getButtonVariantStyle({
      theme,
      variant,
    }),
  };
};

export const StyledIconButton = styled.button<IconButtonStyleProps>(
  baseIconButtonStyle,
  dynamicIconButtonStyles
);

export const StyledFakeIconButton = styled.div<IconButtonStyleProps>(
  baseIconButtonStyle,
  dynamicIconButtonStyles
);

export const StyledIconLink = styled.a<IconButtonStyleProps>(
  baseIconButtonStyle,
  dynamicIconButtonStyles
);

export const StyledIconRouterLink = styled(Link)<IconButtonStyleProps>(
  baseIconButtonStyle,
  dynamicIconButtonStyles
);

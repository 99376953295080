import { createContext, useCallback, useMemo } from 'react';

import { useVisualViewportHeight } from '../../hooks';
import { MODAL_VARIANTS, StyledModal } from './modal.styles';
import type { ModalProps } from './modal.types';

/**
 * Modal Context to pass "variant" and "onRequestClose" to the subcomponents
 * when necessary
 */
export const ModalContext = createContext<
  Required<Pick<ModalProps, 'variant' | 'onRequestClose'>>
>({
  onRequestClose: () => {
    return;
  },
  variant: 'standard',
});

/**
 * `Modal` component that allows for simple dialogue modals, complex modals
 * with headers and footers, or full-screen modals.
 */
export function Modal({
  appId = 'app',
  background,
  children,
  centerByVisualViewport = false,
  className,
  contentLabel,
  'data-testid': testId,
  onAfterClose,
  onAfterOpen,
  onRequestClose,
  open,
  overflow = 'hidden',
  overlayClose,
  parentId = 'parent',
  parentSelector,
  style,
  topOverride,
  variant = 'standard',
}: ModalProps) {
  if (variant === 'full-screen' && !background) {
    background = 'grays-ultralight';
  } else if (!background) {
    background = 'grays-white';
  }

  const { keyboardHeight } = useVisualViewportHeight();
  /** Only offset by keyboard height when centerByVisualViewport=true */
  const onScreenKeyboardHeight = centerByVisualViewport ? keyboardHeight : 0;

  const contextValue = useMemo(
    () => ({ onRequestClose, variant }),
    [onRequestClose, variant]
  );

  const selectVariantMaxHeight = useCallback(() => {
    if (variant === 'full-screen' || variant === 'flex') {
      return MODAL_VARIANTS[variant].height;
    }
    return MODAL_VARIANTS[variant].maxHeight;
  }, [variant]);

  return (
    <ModalContext.Provider value={contextValue}>
      <StyledModal
        appElement={document.getElementById(appId) as HTMLElement}
        background={background}
        centerByVisualViewport={centerByVisualViewport}
        className={className}
        contentLabel={contentLabel}
        data-testid={testId}
        id={parentId}
        maxHeight={`calc(${selectVariantMaxHeight()} - ${onScreenKeyboardHeight}px)`}
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
          onAfterOpen?.();
        }}
        onAfterClose={() => {
          document.body.style.overflow = 'unset';
          onAfterClose?.();
        }}
        onRequestClose={onRequestClose}
        open={open}
        overflow={overflow}
        parentSelector={parentSelector}
        shouldCloseOnOverlayClick={overlayClose ? true : false}
        style={style}
        top={`calc(50% - ${onScreenKeyboardHeight / 2}px)`}
        topOverride={topOverride}
        variant={variant}
      >
        {children}
      </StyledModal>
    </ModalContext.Provider>
  );
}

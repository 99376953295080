import { StyledList } from './list.styles';
import type { ListProps } from './list.types';

/**
 * Lists are a continuous group of text or other elements. They are composed of items containing primary and supplemental actions, which are represented by icons and text.
 */

export function List({
  children,
  className,
  'data-testid': testId,
  id,
  label,
  m = 0,
  p = 0,
  style,
  ...props
}: ListProps) {
  return (
    <StyledList
      aria-label={label}
      className={className}
      id={id}
      test-id={testId}
      m={m}
      p={p}
      style={style}
      {...props}
    >
      {children}
    </StyledList>
  );
}

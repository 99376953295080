import { Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import {
  AccessRequired,
  Authenticated,
  ComingSoon,
  RumErrorBoundary as ErrorBoundary,
  ErrorView,
  FeedbackButton,
  Liveboard,
  ModalDecider,
  NotFoundPage,
  RequireAnonymousUser,
  RequireLoggedInUser,
  RequirePlusAccess,
  RequireThoughtspotUserBucket,
  SpecialsModal,
  SquareOAuth,
  UnauthorizedAccess,
} from '@jane/business-admin/components';
import {
  useAuthenticated,
  useHasPermissions,
} from '@jane/business-admin/hooks';
import {
  PlusStores,
  StoreMenu,
  StoreProducts,
  StoreSettingsSpecials,
  StoreStaff,
} from '@jane/business-admin/pages';
import { ThoughtspotBucketNames } from '@jane/business-admin/types';
import { Permission } from '@jane/shared/auth';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { load } from '@jane/shared/util';

const BusinessSolutions = load(() => import('./features/BusinessSolutions'));
const Login = load(() => import('./features/Login'));
const Plus = load(() => import('./features/Plus'));
const PlusFonts = load(() => import('./features/PlusFonts'));
const PlusFooter = load(() => import('./features/PlusFooter'));
const PlusColors = load(() => import('./features/PlusColors'));
const PlusLogo = load(() => import('./features/PlusLogo'));
const PlusHamburgerMenuSettings = load(
  () => import('./features/PlusHamburgerMenuSettings')
);
const PlusAgeGate = load(() => import('./features/PlusAgeGate'));
const RequestPasswordReset = load(
  () => import('./features/RequestPasswordReset')
);
const ResetPassword = load(() => import('./features/ResetPassword'));
const ManagerRegistration = load(
  () => import('./features/ManagerRegistration')
);
const Stores = load(() => import('./features/Stores'));
const StoreDetail = load(() => import('./features/StoreDetail'));
const StoreSettings = load(() => import('./features/StoreSettings'));
const StoreSettingsDetails = load(
  () => import('./features/StoreSettingsDetails')
);
const StoreSettingsCheckout = load(
  () => import('./features/StoreSettingsCheckout')
);
const StoreSettingsSubdomain = load(
  () => import('./features/StoreSettingsSubdomain')
);
const StoreSettingsIntegrations = load(
  () => import('./features/StoreSettingsIntegrations')
);
const StoreSettingsFulfillments = load(
  () => import('./features/StoreSettingsFulfillments')
);
const StoreSettingsNotifications = load(
  () => import('./features/StoreSettingsNotifications')
);
const StoreSettingsHardware = load(
  () => import('./features/StoreSettingsHardware')
);
const Analytics = load(() => import('./features/Analytics'));
const TermsOfService = load(() => import('./features/TermsOfService'));
const PrivacyPolicy = load(() => import('./features/PrivacyPolicy'));
const StoreSpecials = load(() => import('./features/StoreSpecials'));

const Shell = load(() => import('./features/Shell'));

const Routes = () => {
  const isLoggedIn = useAuthenticated();
  const hasEditMenuPermissions = useHasPermissions([
    Permission.EditMenuCustomizations,
  ]);
  const hasEditPrintersPermission = useHasPermissions([
    Permission.EditPrinters,
  ]);

  const hasSpecialsAccess = useFlag(FLAGS.scSpecialsBetaAccess);

  const hasAnalyticsFlagEnabled = useFlag(FLAGS.scAnalyticsBetaAccess);
  const hasAnalyticsPermission = useHasPermissions([Permission.ViewAnalytics]);
  const hasAnalyticsAccess = hasAnalyticsFlagEnabled && hasAnalyticsPermission;

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Navigate to="login" replace={true} />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/terms',
      element: <TermsOfService />,
    },
    {
      path: '/register',
      element: (
        <RequireAnonymousUser>
          <ManagerRegistration />
        </RequireAnonymousUser>
      ),
    },
    {
      path: '/login',
      element: (
        <RequireAnonymousUser>
          <Login />
        </RequireAnonymousUser>
      ),
    },
    {
      path: '/forgot-your-password',
      element: (
        <RequireAnonymousUser>
          <RequestPasswordReset />
        </RequireAnonymousUser>
      ),
    },
    {
      path: '/reset-password/:id',
      element: (
        <RequireAnonymousUser>
          <ResetPassword />
        </RequireAnonymousUser>
      ),
    },
    {
      path: '/stores',
      element: (
        <RequireLoggedInUser>
          <AccessRequired requireClient={true}>
            <Shell>
              <ErrorBoundary fallback={ErrorView} scope="stores">
                <Outlet />
              </ErrorBoundary>
            </Shell>
            <FeedbackButton />
          </AccessRequired>
        </RequireLoggedInUser>
      ),
      children: [
        {
          index: true,
          element: <Stores />,
        },
        {
          path: ':id',
          element: <StoreDetail />,
          children: [
            {
              path: 'settings',
              element: <StoreSettings />,
              children: [
                {
                  path: 'details',
                  element: <StoreSettingsDetails />,
                },
                {
                  path: 'checkout',
                  element: <StoreSettingsCheckout />,
                },
                {
                  path: 'specials',
                  element: <StoreSettingsSpecials />,
                },
                {
                  path: 'subdomain',
                  element: <StoreSettingsSubdomain />,
                },
                {
                  path: 'integrations',
                  element: <StoreSettingsIntegrations />,
                },
                {
                  path: 'fulfillments',
                  element: <StoreSettingsFulfillments />,
                },
                {
                  path: 'notifications',
                  element: <StoreSettingsNotifications />,
                },
                {
                  path: 'hardware',
                  element: hasEditPrintersPermission ? (
                    <StoreSettingsHardware />
                  ) : (
                    <UnauthorizedAccess resourceName="printers" />
                  ),
                },
                {
                  // Navigate to details by default for now
                  path: '/stores/:id/settings/',
                  element: <Navigate to="details" replace={true} />,
                },
              ],
            },
            {
              // Navigate to details by default for now
              path: '/stores/:id/',
              element: <Navigate to="settings/details" replace={true} />,
            },
            {
              path: 'menu',
              element: hasEditMenuPermissions ? (
                <StoreMenu />
              ) : (
                <UnauthorizedAccess resourceName="menu customizations" />
              ),
            },
            {
              path: 'products/:type',
              element: <StoreProducts />,
              children: [
                {
                  path: ':product_id',
                  element: (
                    // Decides whether to use Product or UnpublishedProduct Modal
                    <ModalDecider />
                  ),
                },
              ],
            },
            {
              path: 'specials',
              element: hasSpecialsAccess ? (
                <StoreSpecials />
              ) : (
                <ComingSoon classicViewPath="/stores/:id/specials" />
              ),
              children: [
                {
                  path: ':special_id',
                  element: <SpecialsModal />,
                },
                {
                  path: 'duplicate/:special_id',
                  element: <SpecialsModal />,
                },
              ],
            },
            {
              path: 'staff',
              element: <StoreStaff />,
            },
            {
              path: 'products',
              element: <Navigate to="published" />,
            },
            {
              path: 'specials',
              element: <Navigate to="live" />,
            },
          ],
        },
      ],
    },
    {
      path: '/square-oauth',
      element: <SquareOAuth />,
    },
    {
      path: '/specials',
      element: (
        <RequireLoggedInUser>
          <Shell>
            <Outlet />
          </Shell>
        </RequireLoggedInUser>
      ),
      children: [
        {
          index: true,
          element: <ComingSoon classicViewPath="/specials" />,
        },
      ],
    },
    {
      path: '/marketing',
      element: (
        <RequireLoggedInUser>
          <Shell>
            <Outlet />
          </Shell>
        </RequireLoggedInUser>
      ),
      children: [
        {
          path: 'business-solutions',
          element: <BusinessSolutions />,
        },
        {
          // Navigate to business-solutions by default
          path: '/marketing/',
          element: <Navigate to="business-solutions" replace={true} />,
        },
      ],
    },
    {
      path: '/analytics',
      element: (
        <RequireLoggedInUser>
          <Shell>
            {hasAnalyticsAccess ? (
              <Analytics />
            ) : (
              <UnauthorizedAccess resourceName="analytics" variant="view" />
            )}
          </Shell>
        </RequireLoggedInUser>
      ),
      children: [
        {
          path: 'kpis',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="kpis" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'sales',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="sales" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'customers',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="customers" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'fulfillment',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="fulfillment" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'on-menu-merchandising',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="on-menu-merchandising" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'off-menu-advertising',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.OFF_MENU}
              upsellScreenTitle="Off-Menu Advertising"
              upsellScreenDescription="Attract shoppers from the open web and gain insights into your reach with the most accurate and verified sales data within the industry."
            >
              <Liveboard liveboardRoute="off-menu-advertising" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'jane-gold',
          // TODO(mgreen): check whether manager has stores w/ gold and display upsell if false
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="jane-gold" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'web-traffic',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="web-traffic" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'marketing-attribution',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="marketing-attribution" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'product-reviews',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="product-reviews" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'store-reviews',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
            >
              <Liveboard liveboardRoute="store-reviews" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'trends',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.PREMIUM_CONTENT}
              unauthorizedResourceName="the trends dashboard"
            >
              <Liveboard liveboardRoute="trends" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'pricing',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.PREMIUM_CONTENT}
              unauthorizedResourceName="the pricing dashboard"
            >
              <Liveboard liveboardRoute="pricing" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'marketplace',
          element: (
            <RequireThoughtspotUserBucket
              bucketName={ThoughtspotBucketNames.PREMIUM_CONTENT}
              unauthorizedResourceName="the marketplace dashboard"
            >
              <Liveboard liveboardRoute="marketplace" />
            </RequireThoughtspotUserBucket>
          ),
        },
        {
          path: 'printers',
          element: (
            <RequireThoughtspotUserBucket
              internalOnly
              bucketName={ThoughtspotBucketNames.DEFAULT_LIVEBOARDS}
              unauthorizedResourceName="the printers dashboard"
            >
              <Liveboard liveboardRoute="printers" />
            </RequireThoughtspotUserBucket>
          ),
        },
      ],
    },
    {
      path: '/plus',
      element: (
        <RequireLoggedInUser>
          <RequirePlusAccess>
            <Shell>
              <Outlet />
            </Shell>
          </RequirePlusAccess>
        </RequireLoggedInUser>
      ),
      children: [
        {
          index: true,
          element: <Plus />,
        },
        {
          path: ':menu_id/logo',
          element: <PlusLogo />,
        },
        {
          path: ':menu_id/fonts',
          element: <PlusFonts />,
        },
        {
          path: ':menu_id/footer',
          element: <PlusFooter />,
        },
        {
          path: ':menu_id/hamburger-menu-settings',
          element: <PlusHamburgerMenuSettings />,
        },
        {
          path: ':menu_id/age-gate',
          element: <PlusAgeGate />,
        },
        {
          path: ':menu_id/colors',
          element: <PlusColors />,
        },
        {
          path: ':menu_id/stores',
          element: <PlusStores />,
        },
      ],
    },
    {
      path: '*',
      element: isLoggedIn ? (
        <Shell>
          <NotFoundPage />
        </Shell>
      ) : (
        <Navigate to="login" />
      ),
    },
  ];

  const element = useRoutes(routes);

  return element;
};

const App = () => (
  <Authenticated>
    <Suspense fallback={<Shell></Shell>}>
      <Routes />
    </Suspense>
  </Authenticated>
);
export default App;

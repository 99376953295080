import { SpecialStatus } from '@jane/business-admin/types';
import { config } from '@jane/shared/config';

export enum AnalyticsRouteMapping {
  Customers = '/analytics/customers',
  Fulfillment = '/analytics/fulfillment',
  'Jane Gold' = '/analytics/jane-gold',
  KPIs = '/analytics/kpis',
  'Marketing Attribution' = '/analytics/marketing-attribution',
  Marketplace = '/analytics/marketplace',
  'Off-Menu Advertising' = '/analytics/off-menu-advertising',
  'On-Menu Merchandising' = '/analytics/on-menu-merchandising',
  Pricing = '/analytics/pricing',
  Printers = '/analytics/printers',
  'Product Reviews' = '/analytics/product-reviews',
  Sales = '/analytics/sales',
  'Store Reviews' = '/analytics/store-reviews',
  Trends = '/analytics/trends',
  'Web Traffic' = '/analytics/web-traffic',
}

export const businessPaths = {
  root() {
    return '/';
  },
  login() {
    return '/login';
  },
  loggedInRoot() {
    return this.stores();
  },
  stores() {
    return '/stores';
  },
  storeSettingsDetails(id: string | undefined) {
    return `/stores/${id}/settings/details`;
  },
  storeSettingsCheckout(id: string | undefined) {
    return `/stores/${id}/settings/checkout`;
  },
  storeSettingsSpecials(id: string | undefined) {
    return `/stores/${id}/settings/specials`;
  },
  storeSettingsSubdomain(id: string | undefined) {
    return `/stores/${id}/settings/subdomain`;
  },
  storeSettingsIntegrations(id: string | undefined) {
    return `/stores/${id}/settings/integrations`;
  },
  storeSettingsFulfillments(id: string | undefined) {
    return `/stores/${id}/settings/fulfillments`;
  },
  storeSettingsNotifications(id: string | undefined) {
    return `/stores/${id}/settings/notifications`;
  },
  storeSettingsHardware(id: string | undefined) {
    return `/stores/${id}/settings/hardware`;
  },
  storeMenu(id: string | undefined) {
    return `/stores/${id}/menu`;
  },
  storeProducts(id: string, type: string) {
    return `/stores/${id}/products/${type}`;
  },
  storeSpecials(id: string, status?: SpecialStatus) {
    return `/stores/${id}/specials?status=${
      status ? status : SpecialStatus.live
    }`;
  },
  storeSpecialDetail(id: string, specialId: string) {
    return `/stores/${id}/specials/${specialId}`;
  },
  storeStaff(id: string | undefined) {
    return `/stores/${id}/staff`;
  },
  specials() {
    return '/specials';
  },
  plus() {
    return '/plus';
  },
  plusFonts() {
    return '/plus/fonts';
  },
  plusLogo() {
    return '/plus/logo';
  },
  plusColors() {
    return '/plus/colors';
  },
  plusStores() {
    return '/plus/stores';
  },
  businessSolutions() {
    return '/marketing/business-solutions';
  },
  analytics() {
    return '/analytics';
  },
  analyticsRoutes(liveboardName: string) {
    return AnalyticsRouteMapping[
      liveboardName as keyof typeof AnalyticsRouteMapping
    ];
  },
  legacyRoot() {
    return (
      config.businessUrl ?? 'https://staging-business.nonprod-iheartjane.com'
    );
  },
};

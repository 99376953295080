import { getWebpackConfigUtils } from '@jane/shared/runtime-config';

import { config as devConfig } from './env/development';
import { config as prodConfig } from './env/production';
import { config as testConfig } from './env/test';
import type { Config } from './type';

function getEnvConfig(env: string) {
  switch (env) {
    case 'test':
      return testConfig;
    case 'production':
      return prodConfig;
    default:
      return devConfig;
  }
}

export function getConfig(env = 'development'): Config {
  const envConfig = getEnvConfig(env);
  const mergedConfig = {
    ...envConfig,
    appDir: 'app',
    brandLaunchDarklyClientID: process.env['NX_BRAND_LAUNCH_DARKLY_CLIENT_ID'],
    brandMixpanelToken: process.env['NX_BRAND_MIXPANEL_PROJECT_TOKEN'],
    businessLaunchDarklyClientID:
      process.env['NX_BUSINESS_LAUNCH_DARKLY_CLIENT_ID'],
    businessUrl: process.env['NX_BUSINESS_URL'],
    brandPortalUrl: process.env['NX_BRAND_PORTAL_URL'],
    configDir: 'config',
    datadogEnv: process.env['NX_DATADOG_ENV'],
    distDir: 'dist',
    env,
    [env]: true,
    framelessEmbedApiUrl: process.env['NX_FRAMELESS_EMBED_API_URL'],
    framelessEmbedMode: getWebpackConfigUtils().isFramelessEmbedMode,
    googleClientId: process.env['NX_GOOGLE_CLIENT_ID'],
    googlePlacesKey: process.env['NX_GOOGLE_PLACES_API_KEY'],
    googleFontsKey: process.env['NX_GOOGLE_FONTS_API_KEY'],
    launchDarklyClientID: process.env['NX_LAUNCH_DARKLY_CLIENT_ID'],
    mfaForceSMS: process.env['NX_MFA_FORCE_SMS'],
    port: process.env['NX_PORT'],
    recaptchaKey: process.env['NX_RECAPTCHA_KEY'],
    useEmailOverSMS: process.env['NX_USE_EMAIL_OVER_SMS'],
  };

  return mergedConfig as Config;
}

import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import truncate from 'lodash/truncate';
import uniqBy from 'lodash/uniqBy';

import type {
  AppMode,
  MenuProduct,
  PendingCartProduct,
  PriceId,
  SpecialType,
  Store,
  StoreSpecial,
  WeightCondition,
} from '@jane/shared/models';
import type { StoreSpecial as ZStoreSpecial } from '@jane/shared/types';

import { EXTRACT, OTHERS } from './storeMenu';

export const WEIGHT_OPTIONS = uniqBy([...OTHERS, ...EXTRACT], 'id');

export const QUALIFIED_GROUP_TYPE_MAP = {
  student: 'Student',
  veteran: 'Veteran',
  medical: 'Medical',
  senior: 'Senior',
  pediatric: 'Pediatric',
  ssi: 'SSI',
  snap: 'SNAP',
  pace: 'PACE',
  pacenet: 'PACENET',
  wic: 'WIC',
  chip: 'CHIP',
  industry: 'Industry',
  medicare: 'Medicare',
  medicaid: 'Medicaid',
  member: 'Member',
  locals: 'Locals',
  'PA Gov': 'PA Gov',
};

export const doesSpecialApply = (
  appMode: AppMode,
  special: StoreSpecial,
  menuProduct: MenuProduct,
  weight?: PriceId
) => {
  const notKiosk = appMode !== 'kiosk' || special.reservation_modes?.kiosk;
  const isProductSpecial = special.special_type === 'product';

  // safeguard against group special
  if (!isProductSpecial) return false;

  if (!weight) {
    // whitelist special based on the BE endpoint as the source of truth
    return menuProduct.special_id === special.id && notKiosk;
  }

  const specialWeights = special.conditions.product?.weights || [];
  return (
    menuProduct.applicable_special_ids?.includes(special.id) &&
    (specialWeights.length === 0 ||
      specialWeights.includes(weight as WeightCondition))
  );
};

export const findSpecialForProduct = (
  menuProduct: MenuProduct,
  specials: StoreSpecial[] = [],
  appMode: AppMode
) =>
  specials
    .filter(Boolean)
    .find((special: StoreSpecial) =>
      doesSpecialApply(appMode, special, menuProduct)
    );

const abbreviateLabel = (label: string, type: string) => {
  const matches = label.match(/(?<dollar>\$?)(?<rate>\d+.?\d+)(?<percent>%?)/i);

  if (isNil(matches)) return 'Deal';

  if (matches?.groups?.['dollar']) {
    if (type === 'target_price') {
      return `$${matches?.groups?.['rate']}`;
    } else {
      return `$${matches?.groups?.['rate']} OFF`;
    }
  } else {
    return `${Math.round(parseFloat(matches?.groups?.['rate'] || ''))}% OFF`;
  }
};

export const getDiscountLabel = (
  special: StoreSpecial | ZStoreSpecial,
  store: Pick<Store, 'store_compliance_language_settings'>
) => {
  switch (special.special_type) {
    case 'bundle':
      return store?.store_compliance_language_settings?.['bundle']
        ? truncate(
            capitalize(
              store.store_compliance_language_settings['bundle'] || ''
            ),
            { length: 10 }
          )
        : 'Bundle';
    default:
      return abbreviateLabel(
        special.discount_amount || '',
        special.discount_type
      );
  }
};

export const getSpecialType = (
  specialType: SpecialType,
  store: Pick<Store, 'store_compliance_language_settings'>
) => {
  switch (specialType) {
    case 'cart_total':
      return 'Cart Total';
    case 'product':
      return 'Product';
    case 'bundle':
      return (
        capitalize(
          store?.store_compliance_language_settings?.['bundle'] || ''
        ) || 'Bundle'
      );
    case 'bulk_pricing':
      return 'Bulk';
    default:
      return 'Deal';
  }
};

// Annotate discounted products that are also qualifiers so we can skip rendering them
export const getDiscountedProductsForBundle = (
  discountedProducts: PendingCartProduct[],
  qualifiedProducts: PendingCartProduct[]
) => {
  return discountedProducts.map((product) => {
    const qualifiedProduct = find(qualifiedProducts, {
      id: product.id,
      price_id: product.price_id,
    });

    if (qualifiedProduct) {
      return {
        ...product,
        count: qualifiedProduct.count,
        discountableIsAlsoQualifier: true,
      };
    }

    return product;
  });
};

type SpecialReservationMode = 'pickup' | 'delivery';
export const hasSingleReservationMode = (
  reservationModes:
    | StoreSpecial['reservation_modes']
    | ZStoreSpecial['reservation_modes']
): SpecialReservationMode | false => {
  const availableModes = Object.fromEntries(
    Object.entries(reservationModes || {}).filter(
      ([, isAvailable]) => isAvailable
    )
  );

  // currently we're supporting only pickup and delivery
  const modes = Object.keys(availableModes).filter(
    (mode) => mode !== 'kiosk' && mode !== 'curbside'
  );

  if (modes.length === 0 || modes.length > 1) return false;
  return modes[0] as SpecialReservationMode;
};

import { useContext } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Form, Typography, useFormContext } from '@jane/shared/reefer';

export const Bundle = ({
  isCreateMode,
  name,
}: SpecialTypeProps['bundle'] & {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
  } = useContext(SpecialsModalContext);

  const { getValues } = useFormContext();
  const bundleValue = getValues('conditions.bundle');

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      gap={24}
      flexDirection="column"
    >
      {isCreateMode || (!isCreateMode && (isJanePosSynced || !posSynced)) ? (
        <>
          <Flex width="100%" gap={24} mb={24}>
            <Form.NumberField
              width={'100%'}
              name="display.bundle.threshold_number_of_items_in_cart"
              label={'Minimum products requirement'}
              min={1}
              required
            />
            <Form.NumberField
              width={'100%'}
              name="display.bundle.max_number_of_discounted_products"
              label={'Maximum products discounted'}
              min={1}
              required
            />
          </Flex>
          <Flex width="100%" gap={24} mb={24}>
            <Form.NumberField
              width={'100%'}
              name="display.bundle.max_applications_per_cart"
              label={'Maximum uses per order'}
            />
            <Flex flexDirection="column" width="100%">
              <Typography variant="body-bold" mb={24}>
                Discount qualifying products
              </Typography>
              <Form.CheckboxField
                width={'100%'}
                name="display.bundle.settings.allow_discounts_on_required_products"
                label={'Allow qualifying products to be discounted'}
              />
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Flex gap={24}>
            <Flex width="50%" flexDirection="column">
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-bundle-min-products-required"
              >
                Minimum products requirement
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {bundleValue.independent.threshold_number_of_items_in_cart ||
                  'N/A'}
              </Typography>
            </Flex>
            <Flex width="50%" flexDirection="column">
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-bundle-max-products-discounted"
              >
                Maximum products discounted
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {bundleValue.dependent.max_number_of_discounted_products ||
                  'N/A'}
              </Typography>
            </Flex>
          </Flex>
          <Flex gap={24} mb={12}>
            <Flex width="50%" flexDirection="column" mb={12}>
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-bundle-max-uses"
              >
                Maximum uses per order
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {bundleValue.max_applications_per_cart || 'N/A'}
              </Typography>
            </Flex>
            <Flex width="50%" flexDirection="column" mb={12}>
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-bundle-allow-discounts"
              >
                Allow qualifying products to be discounted
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {bundleValue.settings.allow_discounts_on_required_products
                  ? 'Allowed'
                  : 'N/A'}
              </Typography>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

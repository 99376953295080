import { useEffect, useMemo } from 'react';

import { useSaveStoreDetails } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type {
  OwnershipIdentification,
  StoreV2,
} from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';
import {
  ca_provinces,
  country_codes,
  getJaneAllowedTimeZones,
  titleCase,
  us_states,
} from '@jane/shared/util';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';

interface StoreDetailsFormData {
  additional_address?: string;
  address?: string;
  city?: string;
  country_code?: string;
  description?: string;
  name?: string;
  ownership_identifications?: {
    bipoc: boolean;
    lgbtq: boolean;
    veteran: boolean;
    woman: boolean;
  };
  phone?: string;
  state?: string;
  timezone_identifier?: string;
  type?: string;
  wheelchair_access?: boolean;
  zip?: string;
}

const FORM_ERROR_NAME = 'details-error';

export const StoreDetailsModal = ({
  open,
  setOpen,
  store,
  ownership,
}: {
  open: boolean;
  ownership: OwnershipIdentification;
  setOpen: (open: boolean) => void;
  store: StoreV2;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating store details. Please try again.'
  );
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
    watch,
    setValue,
  } = formMethods;
  const selectedCountry = watch('country_code');

  const toast = useToast();
  const { mutateAsync: saveStoreDetails, isSuccess: saveStoreSuccess } =
    useSaveStoreDetails(store.id);
  const formName = 'Edit store details form';

  useEffect(() => {
    if (saveStoreSuccess) {
      toast.add({
        label: 'Store details updated',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveStoreSuccess]);

  const onSubmit = (data: StoreDetailsFormData) => {
    const recreational = data.type === 'recreational' ? true : false;
    const requestData = {
      store: {
        ...data,
        recreational,
      },
      ownership_identifications: data.ownership_identifications || {
        bipoc: false,
        lgbtq: false,
        veteran: false,
        woman: false,
      },
    };
    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.StoreDetails,
        changed_attributes: Object.keys(dirtyFields),
      });
      return saveStoreDetails(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: {
        ownership_identifications: Record<string, unknown>;
        store?: Record<string, unknown>;
      }) => {
        const storeErrors = parseValidationErrors(
          validationErrors?.store || {}
        );
        const ownershipErrors = parseValidationErrors(
          validationErrors?.ownership_identifications || {}
        );
        throw new FormValidationError(FORM_ERROR_NAME, [
          ...storeErrors,
          ...ownershipErrors,
        ]);
      },
    });
  };

  const typeOptions = [
    {
      label: 'Recreational',
      value: 'recreational',
    },
    {
      label: 'Medical',
      value: 'medical',
    },
  ];

  const countryOptions = useMemo(() => {
    return country_codes.map(({ name, abbreviation }) => ({
      label: name,
      value: abbreviation || '',
    }));
  }, []);

  const statesByCountry: Record<string, Array<any>> = {
    US: us_states.map((state) => {
      // Server-side validation is case sensitive. Changing the name of D.C. in the client side module will impact
      // other monolith apps, so we just tweak it here:
      if (state.name === 'District Of Columbia') {
        state.name = 'District of Columbia';
      }
      return state;
    }),
    CA: ca_provinces,
  };

  const stateOptions = useMemo(() => {
    let states;

    if (selectedCountry) {
      states = statesByCountry[selectedCountry];
    } else {
      states = store.country_code
        ? statesByCountry[store.country_code]
        : statesByCountry['US'];
    }

    return states.map(({ name }: { name: string }) => ({
      label: name,
      value: name,
    }));
  }, [selectedCountry, store]);

  const timezoneOptions = useMemo(() => {
    return getJaneAllowedTimeZones()
      .map((timezone) => ({
        label: titleCase(timezone),
        value: timezone,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  // Select the first state in the newly selected country when country_code changes to remove inconsistency
  // between what the user sees in the UI, and what's actually sent to the server.
  useEffect(() => {
    if (!selectedCountry || selectedCountry === store.country_code) return;
    setValue('state', stateOptions[0].value);
  }, [selectedCountry, stateOptions]);

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
      modalName={ModalNames.StoreDetails}
    >
      <Form.BaseForm
        name={formName}
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Details"
          subtitle={store.name}
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex mb={24}>
            <Form.TextField
              defaultValue={store.name}
              label="Name"
              name="name"
              width="100%"
              mr={8}
            />
            <Form.SelectField
              defaultValue={store.recreational ? 'recreational' : 'medical'}
              options={typeOptions}
              width="100%"
              ml={8}
              label="Type"
              name="type"
              required
            />
          </Flex>
          <Flex mb={24}>
            {/* TODO: Should we use Google address lookup here? It can autofill the city/state/zip as well */}
            <Form.TextField
              defaultValue={store.address}
              label="Street Address"
              name="address"
              width="100%"
              mr={8}
            />
            <Form.TextField
              defaultValue={store.address2 || ''}
              label="Address 2 (Optional)"
              name="additional_address"
              width="100%"
              ml={8}
            />
          </Flex>
          <Flex mb={24}>
            <Form.TextField
              defaultValue={store.city}
              label="City"
              name="city"
              width="100%"
            />
            <Form.SelectField
              defaultValue={store.state}
              options={stateOptions}
              width="100%"
              mx={16}
              label="State"
              name="state"
              required
            />
            <Form.TextField
              defaultValue={store.zip}
              label="Zip"
              name="zip"
              width="100%"
            />
          </Flex>
          <Flex mb={24}>
            <Form.SelectField
              defaultValue={store.country_code || ''}
              options={countryOptions}
              width="100%"
              mr={8}
              label="Country"
              name="country_code"
              required
            />
            <Form.SelectField
              defaultValue={store.time_zone_identifier}
              options={timezoneOptions}
              width="100%"
              ml={8}
              label="Timezone"
              name="time_zone_identifier"
              required
            />
          </Flex>
          <Flex mb={24}>
            <Form.TextField
              defaultValue={store.phone}
              label="Phone number"
              name="phone"
              type="tel"
              width="calc(33.3% - 8px)"
            />
          </Flex>
          <Flex mb={24}>
            <Form.TextAreaField
              defaultValue={store.description || ''}
              label="Description"
              name="description"
              width="100%"
            />
          </Flex>

          <Modal.ContentDivider />

          <Flex mb={24} flexDirection="column">
            <Typography mb={32} variant="body-bold">
              Accessibility
            </Typography>
            <Form.CheckboxField
              defaultChecked={store.wheelchair_access}
              name="wheelchair_access"
              label="Wheelchair access"
            />
          </Flex>

          <Modal.ContentDivider />

          <Flex mb={24} flexDirection="column">
            <Typography mb={32} variant="body-bold">
              Ownership
            </Typography>
            <Form.CheckboxField
              defaultChecked={ownership?.bipoc}
              mb={24}
              name="ownership_identifications.bipoc"
              label="BIPOC"
            />
            <Form.CheckboxField
              defaultChecked={ownership?.lgbtq}
              mb={24}
              name="ownership_identifications.lgbtq"
              label="LGBTQ"
            />
            <Form.CheckboxField
              defaultChecked={ownership?.veteran}
              mb={24}
              name="ownership_identifications.veteran"
              label="Veteran"
            />
            <Form.CheckboxField
              defaultChecked={ownership?.woman}
              name="ownership_identifications.woman"
              label="Woman"
            />
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import {
  useFetchCurrentBloomMenu,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import { businessPaths } from '@jane/business-admin/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import { useNavigateBack } from '@jane/shared/hooks';
import {
  Box,
  Button,
  ChevronLeftIcon,
  Flex,
  Link,
  Typography,
  useToast,
} from '@jane/shared/reefer';

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

const AgeGateFormContainer = styled(Box)(({ theme }) => ({
  paddingTop: '24px',
  paddingBottom: '40px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'none',
  borderRadius: '24px',
  boxShadow: `0px 2px 16px 0px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05)`,
  marginTop: '24px',
}));

const FormInput = styled.input(({ theme }) => ({
  width: '100%',
  display: 'flex',
  padding: '12px 16px',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  borderRadius: '12px',
  border: `1px solid ${theme.colors.grays.light}`,
  background: `${theme.colors.grays.white}`,
}));

export const PlusAgeGate = () => {
  const goBack = useNavigateBack(businessPaths.plus());

  const toast = useToast();

  const [discard, setDiscard] = useState(false);

  const bloomMenuQuery = useFetchCurrentBloomMenu();

  const { mutate: updateBloomMenu, isLoading } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );

  const defaultCustomAgeGateMessage =
    'Are you over 21 years old or a valid medical cannabis patient?';

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    reset,
  } = useForm({});

  const publish: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      updateBloomMenu(
        {
          theme: {
            ...bloomMenuQuery.data?.theme.bloom,
            age_gate_message: data['age_gate_message'],
          },
        },
        {
          onError: (e) => {
            toast.add({
              label: 'An error occurred.',
              variant: 'error',
            });
          },
          onSuccess: () => {
            toast.add({
              label: 'Menu updated!',
              variant: 'success',
            });
            reset({ age_gate_message: data['age_gate_message'] });
          },
        }
      );
    },
    [updateBloomMenu, bloomMenuQuery, toast, reset]
  );

  const ageGateMessage = watch('age_gate_message');

  const isDirty = bloomMenuQuery.data?.theme.bloom?.age_gate_message
    ? ageGateMessage !== bloomMenuQuery.data?.theme.bloom?.age_gate_message
    : true;

  const onRequestClose = useCallback(() => {
    if (isDirty) {
      setDiscard(true);
    } else {
      goBack();
    }
  }, [goBack, isDirty]);

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      const userMsg = bloomMenuQuery.data.theme.bloom?.age_gate_message;
      const displayMessage = userMsg || defaultCustomAgeGateMessage;
      reset({ age_gate_message: displayMessage });
    }
  }, [
    bloomMenuQuery.data?.theme.bloom?.age_gate_message,
    bloomMenuQuery.isSuccess,
    reset,
  ]);

  return (
    <Flex flexDirection="column" minHeight="100vh">
      <form>
        <BorderedFlex p={16} gap={16} flexDirection="row" alignItems="center">
          <ChevronLeftIcon onClick={() => onRequestClose()} altText="Go back" />
          <Typography variant="header-bold" branded>
            Age gate
          </Typography>

          <Flex ml="auto" flexDirection="row">
            <Button
              ml="auto"
              label="Publish"
              type="button"
              variant="primary"
              disabled={isLoading || !isValid || !isDirty}
              onClick={handleSubmit(publish)}
              loading={isLoading}
            />
          </Flex>
        </BorderedFlex>
        <Flex justifyContent="center">
          <Flex flexDirection="column" p={24} maxWidth={800}>
            <Typography>
              In the event your customer navigates to the Bloom menu directly
              and bypasses your main website, we want to make sure we're still
              compliant and display an age gap pop up. The settings below gives
              you the ability to customize the age gate pop up further.
            </Typography>

            <Controller
              name="age_gate_message"
              control={control}
              rules={{ required: true, pattern: /\S+/ }}
              render={({ field }) => (
                <AgeGateFormContainer borderRadius="sm">
                  <Flex flexDirection="column" gap={12}>
                    <Typography variant="body-bold">Text</Typography>
                    <FormInput
                      type="text"
                      onChange={(message) => field.onChange(message)}
                      value={field.value}
                    />
                    <Link
                      onClick={() =>
                        field.onChange(defaultCustomAgeGateMessage)
                      }
                    >
                      Revert to default text
                    </Link>
                  </Flex>
                </AgeGateFormContainer>
              )}
            />
          </Flex>
        </Flex>
      </form>

      <ConfirmChangeModal
        open={discard}
        title={
          <Typography variant="header-bold" mb={8}>
            Discard changes?
          </Typography>
        }
        onConfirm={() => goBack()}
        onCancel={() => setDiscard(false)}
        confirmButtonVariant="destructive"
        confirmLabel="Discard"
        subtitle="You have made changes without publishing. If you continue, you will lose your progress."
      />
    </Flex>
  );
};

import styled from '@emotion/styled';

import { spacing } from '../../styling';

interface StyledSearchListProps {
  width?: string;
}

export const StyledSearchField = styled.div({
  ...spacing({ pl: 16, pr: 16 }),
});

export const StyledSearchList = styled.ul<StyledSearchListProps>(
  ({ width }) => ({
    maxHeight: '420px',
    overflow: 'auto',
    ...(width && { width }),
    '> li:last-of-type > button': {
      ...spacing({ pb: 20 }),
    },
  })
);

export const StyledSearchListItem = styled.li<StyledSearchListProps>(
  ({ theme, width }) => ({
    listStyle: 'none',
    mark: {
      backgroundColor: 'transparent',
      color: theme.colors.primary.main,
    },
    transition: 'all 250ms',
    ...(width && { width }),
  })
);

export const StyledListButton = styled.button({
  backgroundColor: 'transparent',
  borderWidth: 0,
  height: '100%',
  outline: 'none',
  textAlign: 'left',
  textDecoration: 'none',
  width: '100%',
  ...spacing({ m: 0, px: 24, py: 12 }),
  '&:hover, &:focus, &:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  color: 'inherit',
  cursor: 'pointer',
  display: 'block',
});

export const StyledListButtonLabels = styled.span({
  display: 'flex',
  justifyContent: 'space-between',
  pointerEvents: 'none',
});

export const StyledListCheckbox = styled.div({
  '> div > div': {
    ...spacing({ m: 0, px: 24, py: 12 }),
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  cursor: 'pointer',
  label: {
    color: 'inherit',
    cursor: 'pointer',
    width: '100%',
  },
});

export const StyledLoadingWrapper = styled.div({
  height: '24px',
  position: 'relative',
  ...spacing({ mb: 16, mt: 12 }),
});

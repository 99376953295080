import includes from 'lodash/includes';
import isString from 'lodash/isString';

import type {
  AlgoliaProduct,
  JaneSearchState,
  KindFacet,
  PriceId as SearchPriceId,
} from '@jane/search/types';
import type {
  AppMode,
  MenuProduct,
  PriceId,
  ProductKind,
  ProductKindSoldByWeight,
  Store,
  StoreSpecial,
  StoreWithMenuProduct,
  WeightSelectorWeight,
} from '@jane/shared/models';
import { tPriceId, tProductWeight } from '@jane/shared/models';
import type { Store as ZodStore } from '@jane/shared/types';

import { formatCurrency } from './formatCurrency';
import { lowestPriceForMenuProduct } from './pricing/lowestPriceForMenuProduct';
import { getActualUnitPrice, getUnitPrice } from './pricing/pricing';

export interface Weight {
  id: PriceId;
  label: string;
  val: string;
}

// mirrors the defaults specified in the MenuProduct model
export const DEFAULT_FLOWER_WEIGHT = 'eighth_ounce';
export const DEFAULT_EXTRACT_WEIGHT = 'half_gram';

export const FLOWERS: Weight[] = [
  { id: 'gram', label: '1g', val: 'price_gram' },
  { id: 'two_gram', label: '2g', val: 'price_two_gram' },
  { id: 'eighth_ounce', label: '3.5g', val: 'price_eighth_ounce' },
  { id: 'quarter_ounce', label: '7g', val: 'price_quarter_ounce' },
  { id: 'half_ounce', label: '14g', val: 'price_half_ounce' },
  { id: 'ounce', label: '28g', val: 'price_ounce' },
];

export const EXTRACT: Weight[] = [
  { id: 'half_gram', label: '0.5g', val: 'price_half_gram' },
  { id: 'gram', label: '1g', val: 'price_gram' },
  { id: 'two_gram', label: '2g', val: 'price_two_gram' },
  { id: 'eighth_ounce', label: '3.5g', val: 'price_eighth_ounce' },
  { id: 'quarter_ounce', label: '7g', val: 'price_quarter_ounce' },
  { id: 'half_ounce', label: '14g', val: 'price_half_ounce' },
  { id: 'ounce', label: '28g', val: 'price_ounce' },
];

export const OTHERS: Weight[] = [
  { id: 'each', label: 'Each', val: 'price_each' },
];

export const ALL_VALS_IDS_AND_LABELS: Weight[] = [
  ...FLOWERS,
  ...EXTRACT,
  ...OTHERS,
];

export const WEIGHT_FILTER_VALUES: SearchPriceId[] = [
  'half gram',
  'gram',
  'two gram',
  'eighth ounce',
  'quarter ounce',
  'half ounce',
  'ounce',
];

const AllPriceTypes = [
  'sativa',
  'hybrid',
  'indica',
  'cbd',
  'extract',
  'vape',
  'flower',
] as const;
type PriceTypeTuple = typeof AllPriceTypes;
export type PriceType = PriceTypeTuple[number];

export const isPriceType = (value: string): value is PriceType => {
  return AllPriceTypes.includes(value as PriceType);
};

const PRICES: { [K in PriceType]: Weight[] } = {
  cbd: FLOWERS,
  extract: EXTRACT,
  flower: FLOWERS,
  hybrid: FLOWERS,
  indica: FLOWERS,
  sativa: FLOWERS,
  vape: EXTRACT,
};

export const bundleComplianceWord = (store?: Store | ZodStore | null) =>
  typeof store?.store_compliance_language_settings?.['bundle'] === 'string'
    ? store.store_compliance_language_settings['bundle']
    : 'bundle';

export const bundleSpecialComplianceWord = (store?: Store | ZodStore | null) =>
  `${bundleComplianceWord(store)} ${specialsComplianceWord(store)}`;

const specialsComplianceWord = (store?: Store | ZodStore | null) =>
  store?.store_compliance_language_settings?.['specials'] || 'special';

interface DefaultWeightIdArgs {
  appliedWeightFilter?: PriceId | '';
  menuProduct: MenuProduct | AlgoliaProduct;
  sortedByPrice?: boolean;
  special?: StoreSpecial;
}

export function defaultWeightIdForMenuProductFromFilters({
  appliedWeightFilters,
  menuProduct,
}: {
  appliedWeightFilters: string[];
  menuProduct: MenuProduct | AlgoliaProduct;
}) {
  if (!isSoldByWeight(menuProduct.kind)) return 'each';

  const formattedWeightFilters = appliedWeightFilters
    .map((weight) => weight && weight.replace(' ', '_'))
    .filter((weight) => tPriceId.is(weight)) as PriceId[];

  return lowestPriceForMenuProduct(
    menuProduct,
    formattedWeightFilters.filter((weight) => menuProduct[`price_${weight}`])
  );
}

export function defaultWeightIdForMenuProduct({
  menuProduct,
  special,
  appliedWeightFilter,
  sortedByPrice,
}: DefaultWeightIdArgs) {
  if (!isSoldByWeight(menuProduct.kind)) return 'each';

  const availableWeights: PriceId[] =
    availableWeightsForMenuProduct(menuProduct);

  if (appliedWeightFilter && appliedWeightFilter.length > 0)
    return appliedWeightFilter;

  // if sorting by price (low-to-high or high-to-low), choose cheapest option as initialPriceId
  if (sortedByPrice) {
    return lowestPriceForMenuProduct(menuProduct, availableWeights);
  }

  if (
    special &&
    special.conditions.product &&
    special.conditions.product.weights &&
    special.conditions.product.weights.length > 0
  ) {
    return smallestDiscountedWeight(
      availableWeights,
      special.conditions.product.weights
    );
  }

  const defaultWeight = findDefaultWeightForKind(menuProduct.kind);

  if (defaultWeight && includes(availableWeights, defaultWeight)) {
    return defaultWeight;
  }

  return availableWeights[0];
}

function smallestDiscountedWeight(
  availableWeights: PriceId[],
  weightConditions: PriceId[]
) {
  return availableWeights.find((weight) =>
    weightConditions.includes(weight)
  ) as PriceId;
}

export function availableWeightsForMenuProduct(
  menuProduct: MenuProduct | AlgoliaProduct
) {
  if (isSoldByWeight(menuProduct.kind)) {
    const weightIds = allWeightIds(menuProduct.kind);

    return weightIds.filter((weightId) => {
      const priceForWeight = getActualUnitPrice(menuProduct, weightId);
      return !!priceForWeight;
    });
  }
  return [];
}

export function getMenuPrices(type: PriceType): Weight[] {
  return PRICES[type] ? PRICES[type] : OTHERS;
}

export function prepareCartProduct(
  store: Store,
  menuProduct: MenuProduct,
  price_id: PriceId,
  count: number
) {
  return {
    product: {
      ...menuProduct,
      count,
      price_id,
      recommended: !!menuProduct.recommended,
    },
    store,
  };
}

export function allWeightIds(productKind: ProductKind): PriceId[] {
  return allWeightsByKind(productKind).map((record) => record.id);
}

function labelForFlowerWeightId(id: PriceId) {
  const found = findWeight('flower', id);
  return found ? found.label : undefined;
}

function labelForExtractWeightId(id: PriceId) {
  const found = findWeight('extract', id);
  return found ? found.label : undefined;
}

export function labelForWeightId(id: PriceId, amount?: string | null) {
  return amount
    ? 'Each'
    : labelForFlowerWeightId(id) || labelForExtractWeightId(id);
}

export function labelForVal(val: string) {
  const item = ALL_VALS_IDS_AND_LABELS.find((record) => record.val === val);
  return item && item.label;
}

export function priceIdAndCount(
  productKind: ProductKind,
  selectedQuantity: number,
  selectedWeight: PriceId
) {
  if (productKind === 'extract' || productKind === 'flower') {
    return {
      count: selectedQuantity,
      price_id: selectedWeight,
    };
  }

  return {
    count: selectedQuantity,
    price_id: 'each',
  };
}

function allWeightsByKind(productKind: ProductKind) {
  if (isSoldByWeight(productKind)) {
    return productKind === 'flower' ? FLOWERS : EXTRACT;
  }
  return [];
}

function findWeight(productKind: ProductKindSoldByWeight, id: string) {
  return allWeightsByKind(productKind).find((record) => record.id === id);
}

export function isSoldByWeight(
  productKind: ProductKind
): productKind is ProductKindSoldByWeight {
  return includes(['flower', 'extract', 'vape'], productKind);
}

export function isSoldByItem(productKind: ProductKind) {
  return !isSoldByWeight(productKind);
}

export function findDefaultWeightForKind(productKind: ProductKind) {
  if (isSoldByWeight(productKind)) {
    return productKind === 'flower'
      ? DEFAULT_FLOWER_WEIGHT
      : DEFAULT_EXTRACT_WEIGHT;
  }
  return undefined;
}

export function weightSelectorWeightsForMenuProduct(
  menuProduct: MenuProduct | AlgoliaProduct,
  special?: StoreSpecial
) {
  const weightIds = allWeightIds(menuProduct.kind as ProductKindSoldByWeight);

  return weightIds.reduce<WeightSelectorWeight[]>((accum, weightId) => {
    const price = getActualUnitPrice(menuProduct, weightId, special);

    accum.push({
      disabled: !price,
      price,
      value: weightId,
    });

    return accum;
  }, []);
}

export function enabledWeightsForMenuProduct(
  menuProduct: MenuProduct | AlgoliaProduct,
  special?: StoreSpecial
) {
  return weightSelectorWeightsForMenuProduct(menuProduct, special).filter(
    (weight) => !weight.disabled
  );
}
const hasPriceForWeight = (
  stores: readonly StoreWithMenuProduct[],
  weightId: PriceId
) => stores.some((store) => (store.menu_product as any)[`price_${weightId}`]);

export function weightSelectorWeightsForProductInStores(
  stores: readonly StoreWithMenuProduct[],
  productKind: ProductKindSoldByWeight
) {
  const weightIds = allWeightIds(productKind);
  const weights = weightIds.map((weightId) => ({
    disabled: !hasPriceForWeight(stores, weightId),
    value: weightId as PriceId,
  }));

  return weights;
}

export function priceDetailsLabel(
  menuProduct: MenuProduct,
  price_id: PriceId,
  count: number
) {
  const unitPrice = getUnitPrice(menuProduct, price_id);
  if (!unitPrice) return undefined;

  const unitLabel = `${formatCurrency(unitPrice)} each`;

  if (menuProduct.kind === 'flower') {
    return labelForWeightId(price_id);
  }

  if (menuProduct.kind === 'extract' || menuProduct.kind === 'vape') {
    const result =
      count > 1 ? `${labelForWeightId(price_id)} / ${unitLabel}` : unitLabel;

    return result;
  }

  return unitLabel;
}

const storesForWeight = (
  stores: readonly StoreWithMenuProduct[],
  weightId: PriceId
) =>
  stores.filter(
    (store) => (store.menu_product as any)[`price_${weightId}`] != null
  );

export type StoresByWeight = { [K in PriceId]: StoreWithMenuProduct[] };
export function categorizeStoresByWeight(
  stores: readonly StoreWithMenuProduct[],
  productKind: ProductKindSoldByWeight
): StoresByWeight {
  const weightIds = allWeightIds(productKind);
  return weightIds.reduce<{ [K in PriceId]: StoreWithMenuProduct[] }>(
    (weights, weightId) => {
      weights[weightId] = storesForWeight(stores, weightId);
      return weights;
    },
    {} as any
  );
}

export function defaultWeightIdForProductInStores(
  productKind: ProductKindSoldByWeight,
  storesByWeight: StoresByWeight
): PriceId {
  const defaultWeight = findDefaultWeightForKind(productKind);

  if (defaultWeight && storesByWeight[defaultWeight].length) {
    return defaultWeight;
  }

  const weightIds = allWeightIds(productKind);
  return (
    weightIds.find((weight) => storesByWeight[weight].length > 0) ||
    DEFAULT_FLOWER_WEIGHT
  );
}

export function availableStoresByWeight(
  storesByWeight: StoresByWeight,
  selectedWeight: PriceId
): StoreWithMenuProduct[] {
  return storesByWeight[selectedWeight] || [];
}

export function getAppliedWeightFilter(
  searchState: JaneSearchState<AlgoliaProduct> | undefined
): PriceId | '' {
  if (!searchState) return '';
  if (!searchState.filters) return '';
  if (!searchState.filters['available_weights']) return '';
  const weightFilter = searchState.filters['available_weights'][0];

  if (isString(weightFilter)) {
    const stringifiedWeight = weightFilter.replace(' ', '_');
    if (tProductWeight.is(stringifiedWeight)) {
      return stringifiedWeight;
    }
  }
  return '';
}

interface CustomLabelArgs<T> {
  appMode: AppMode;
  attribute?: string | null;
  fallback: T;
  isLineageLabel?: boolean;
  store?: Store | ZodStore | null;
}
export const generateCustomLabel = <T>({
  appMode,
  store,
  attribute,
  isLineageLabel,
  fallback,
}: CustomLabelArgs<T>) => {
  if (attribute === 'discountableBundleProducts')
    return `Your ${bundleSpecialComplianceWord(store)} applies to`;

  const isOhioMarketplace = store?.state === 'Ohio' && appMode === 'default';
  if (
    isOhioMarketplace &&
    attribute?.toLowerCase() === 'sale' &&
    store?.custom_product_type_labels
  ) {
    return (
      store.custom_product_type_labels[attribute.toLowerCase()] || fallback
    );
  }

  if (appMode === 'default') return fallback;

  if (!(store && attribute)) return fallback;

  if (isLineageLabel && store.custom_lineage_labels) {
    return store.custom_lineage_labels[attribute.toLowerCase()] || fallback;
  }
  if (store.custom_product_type_labels) {
    return (
      store.custom_product_type_labels[attribute.toLowerCase()] || fallback
    );
  }

  return fallback;
};

export const sortProductTypes = (
  arrayToSort: KindFacet[],
  referenceArray: string[] | null
) =>
  referenceArray && arrayToSort
    ? arrayToSort
        .concat()
        .sort(
          (kindFacetOne, kindFacetTwo) =>
            referenceArray.indexOf(kindFacetOne.name || kindFacetOne.kind) -
            referenceArray.indexOf(kindFacetTwo.name || kindFacetTwo.kind)
        )
    : arrayToSort;

import type { CategoryOption } from '../CategorySelectInputModal';

const partition = (
  array: CategoryOption[],
  filter: (
    item: CategoryOption,
    index: number,
    arr: CategoryOption[]
  ) => boolean
): [CategoryOption[], CategoryOption[]] => {
  const pass: CategoryOption[] = [];
  const fail: CategoryOption[] = [];
  array.forEach((currentElement, index, arr) =>
    (filter(currentElement, index, arr) ? pass : fail).push(currentElement)
  );
  return [pass, fail];
};

export const searchCategoryTree = (
  dataList: CategoryOption[],
  search: string
): CategoryOption[] => {
  search = search.toLowerCase();
  const labelSubstringMatch = (item: CategoryOption) => {
    return item.label.toLowerCase().indexOf(search) !== -1;
  };

  const [topLevelMatches, topLevelMisses] = partition(
    dataList,
    labelSubstringMatch
  );

  const lowerLevelMatches = topLevelMisses
    .map((item) => {
      if (item.subItems && item.subItems.length > 0) {
        const subtreeMatches = searchCategoryTree(item.subItems, search);
        if (subtreeMatches.length > 0) {
          return { ...item, subItems: subtreeMatches };
        } else {
          return null;
        }
      } else {
        return null;
      }
    })
    .filter(Boolean);
  return topLevelMatches.concat(lowerLevelMatches as CategoryOption[]);
};

import styled from '@emotion/styled';
import type { CSSObject } from '@emotion/styled';
import { Link } from 'react-router-dom';

import { spacing } from '../../../styling';
import type { ReeferTheme } from '../../../types';
import { Flex } from '../../flex';
import type { TabDirection } from '../tabs';

interface TabLabelStyleProps {
  direction: TabDirection;
  selected: boolean;
}

export const TabLabel = styled(Flex)<TabLabelStyleProps>(
  ({ direction, selected, theme: { colors } }) => ({
    ...(direction === 'vertical' && {
      boxShadow: selected ? `inset 2px 0 0 ${colors.primary.main}` : 'none',
      ...spacing({ px: 16 }),
    }),
  })
);

interface TabStyleProps {
  direction: TabDirection;
  disabled?: boolean;
  full?: boolean;
  selected: boolean;
  width?: number | string;
}

interface TabStylePropsWithTheme extends TabStyleProps {
  theme: ReeferTheme;
}

const sharedTabStyle = ({
  direction,
  disabled,
  theme: { colors },
  selected,
  width,
  full,
}: TabStylePropsWithTheme): CSSObject => ({
  background: 'transparent',
  borderWidth: 0,
  cursor: 'pointer',
  pointerEvents: selected ? 'none' : 'initial',
  width: width || 'auto',
  flexShrink: 0,
  overflow: 'hidden',
  transition: 'all 250ms',
  '&:hover, &:focus-visible': {
    backgroundColor: colors.grays.hover,
    outline: 'none',
  },
  ...(direction === 'vertical' && {
    textAlign: 'left',
    ...spacing({ py: 12, mr: 16 }),
  }),
  ...(direction === 'horizontal' && {
    boxShadow: selected
      ? `0px -2px inset ${colors.primary.main}`
      : `0px -1px inset ${colors.grays.light}`,
    textAlign: 'center',
    ...spacing({ px: 24, py: 12 }),
    ...(full && {
      flexGrow: 1,
      flexShrink: 0,
      maxWidth: 'none',
    }),
    ...(disabled && {
      opacity: '0.3',
      pointerEvents: 'none',
    }),
  }),
});

export const StyledTab = styled.button<TabStyleProps>(
  ({ direction, disabled, theme, selected, width, full }) => ({
    ...sharedTabStyle({ direction, disabled, theme, selected, width, full }),
  })
);

export const StyledRouterTab = styled(Link)<TabStyleProps>(
  ({ direction, theme, selected, width, full }) => ({
    ...sharedTabStyle({ direction, full, selected, theme, width }),
    textDecoration: 'none',
  })
);

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Relaxed24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29289 15.2929C7.68342 14.9024 8.31658 14.9024 8.70711 15.2929C10.5257 17.1115 13.4743 17.1115 15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C14.1074 19.3068 9.89256 19.3068 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289C7.42115 9.00694 8.57885 9.00694 9.29289 8.29289C9.68342 7.90237 10.3166 7.90237 10.7071 8.29289C11.0976 8.68342 11.0976 9.31658 10.7071 9.70711C9.21201 11.2022 6.78799 11.2022 5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2929 8.29289C13.6834 7.90237 14.3166 7.90237 14.7071 8.29289C15.4212 9.00694 16.5788 9.00694 17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711C17.212 11.2022 14.788 11.2022 13.2929 9.70711C12.9024 9.31658 12.9024 8.68342 13.2929 8.29289Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Relaxed24,
  sm: Relaxed24,
  lg: Relaxed24,
  xl: Relaxed24,
  xxl: Relaxed24,
  xxxl: Relaxed24,
};

export const RelaxedIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'relaxed-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { janeApiV2 } from '@jane/shared/data-access';
import type { Id, MenuProduct } from '@jane/shared/models';

import { STORES_URL } from './stores';

export const STORE_PRODUCTS_URL = '/business/stores';

export interface UnavailablePrice {
  _destroy?: boolean;
  id?: number;
  price_id: string;
}

export interface FlavorEffectKinds {
  _destroy?: boolean;
  id?: number;
  kind: string;
}

// TODO: add more to attributes and high-level keys as needed. Right now this just encompasses
// the 'Details' card
type UpdateMenuProductParams = {
  attributes: Pick<MenuProduct, 'store_notes' | 'brand_subtype' | 'name'> & {
    effects_attributes: FlavorEffectKinds[];
    flavors_attributes: FlavorEffectKinds[];
    lineage: string;
    unavailable_prices_attributes: UnavailablePrice[];
  };
};

const updateMenuProduct = (
  storeId: Id,
  id: Id,
  menuProduct: UpdateMenuProductParams
): Promise<null> =>
  janeApiV2.patch(`${STORES_URL}/${storeId}/products/${id}`, {
    ...menuProduct,
  });
export const useUpdateMenuProduct = (storeId: Id, id: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateMenuProductParams) =>
      updateMenuProduct(storeId, id, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['storeProducts', storeId]);
    },
  });
};

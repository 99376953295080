import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { CommunicationBanner } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  SettingNames,
  track,
} from '@jane/business-admin/util';
import { Form, Typography } from '@jane/shared/reefer';

import { RichEditor } from '../../../../RichEditor';

export const EXPANDED_STATES = {
  collapsed: 'Collapsed',
  expanded: 'Expanded',
} as const;

export const TextDisplay = ({
  commsBannerData,
}: {
  commsBannerData?: CommunicationBanner;
}) => {
  const trackTextChange = useDebouncedTrack(1000);

  return (
    <>
      <Typography mb={16} variant="body-bold">
        Display style
      </Typography>
      <Form.RadioFieldGroup
        name="is_expanded"
        row
        options={[
          {
            id: EXPANDED_STATES.collapsed,
            label: EXPANDED_STATES.collapsed,
            value: false,
          },
          {
            id: EXPANDED_STATES.expanded,
            label: EXPANDED_STATES.expanded,
            value: true,
          },
        ]}
        onChange={(value: boolean) => {
          const revert = value === !!commsBannerData?.is_expanded;
          track({
            event: EventNames.ModifiedSetting,
            modal_name: ModalNames.CommsBanner,
            setting_name: SettingNames.CommsBannerDisplayStyle,
            revert,
          });
        }}
      />
      <Form.TextField
        name="title"
        label="Banner header"
        placeholder="e.g. Special Announcement or Latest News"
        defaultValue={commsBannerData?.title}
        required
        onChange={(value) => {
          const revert = value === commsBannerData?.title;
          trackTextChange({
            event: EventNames.ModifiedSetting,
            modal_name: ModalNames.CommsBanner,
            setting_name: SettingNames.CommsBannerHeader,
            revert,
          });
        }}
      />
      <Typography mt={24} mb={16}>
        Message
      </Typography>
      <RichEditor
        defaultValue={commsBannerData?.message}
        onChange={(value) => {
          const revert = value === commsBannerData?.message;
          trackTextChange({
            event: EventNames.ModifiedSetting,
            modal_name: ModalNames.CommsBanner,
            setting_name: SettingNames.CommsBannerMessage,
            revert,
          });
        }}
      />
    </>
  );
};

import styled from '@emotion/styled';

import { hidden, mediaQueries, spacing } from '../../../../styling';
import type { MarginProperties } from '../../../../styling';
import { Typography } from '../../../typography';
import { HelperText } from '../helperText/helperText';

interface StyledFieldWrapperProps extends MarginProperties {
  // NOTE: This currently only applies to the search field
  disableMobileInputStyling?: boolean;
  disabled: boolean;
  isWrappingTextInput: boolean;
  width: string | number;
}

export const StyledFieldWrapper = styled.div<StyledFieldWrapperProps>(
  ({
    theme,
    disabled,
    width,
    isWrappingTextInput,
    disableMobileInputStyling,
    ...marginProps
  }) => ({
    opacity: disabled ? '0.3' : 'initial',
    ...spacing(marginProps),
    width,
    [mediaQueries.tablet('max')]: [
      {
        ...(isWrappingTextInput &&
          !disableMobileInputStyling && {
            ...spacing({ px: 24, py: 16 }),
            '&:focus-within': {
              borderBottom: `2px solid ${theme.colors.primary.main}`,
              marginBottom:
                typeof marginProps.mb === 'number' ? marginProps.mb - 1 : -1,
            },
            borderBottom: `1px solid ${theme.colors.grays.light}`,
          }),
      },
    ],
  })
);

interface StyledHelperTextProps {
  labelHidden: boolean;
  repositionMobileHelperText: boolean;
}

export const StyledHelperText = styled(HelperText)<StyledHelperTextProps>(
  ({ labelHidden, repositionMobileHelperText }) => ({
    [mediaQueries.tablet('max')]: {
      ...hidden(!labelHidden && repositionMobileHelperText),
    },
  })
);

export const StyledHelperTextMobile = styled(HelperText)({
  [mediaQueries.tablet('min')]: {
    ...hidden(),
  },
});

interface StyledLabelProps {
  disableMobileInputStyling: boolean;
  labelHidden: boolean;
  labelPosition: 't' | 'r';
}

export const StyledLabel = styled(Typography)<StyledLabelProps>(
  ({ disableMobileInputStyling, labelHidden, labelPosition }) => ({
    display: 'block',
    ...hidden(labelHidden),
    ...spacing({
      mb: labelPosition === 't' ? 12 : 0,
      ml: labelPosition === 'r' ? 12 : 0,
    }),
    [mediaQueries.tablet('max')]: [
      !disableMobileInputStyling && {
        ...spacing({
          mb: 0,
          ml: labelPosition === 'r' ? 8 : 0,
        }),
      },
    ],
  })
);

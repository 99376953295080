/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Topical24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 6.00061C4 4.89604 4.89543 4.00061 6 4.00061H18C19.1046 4.00061 20 4.89604 20 6.00061V22.0006C20 23.1052 19.1046 24.0006 18 24.0006H6C4.89543 24.0006 4 23.1052 4 22.0006V6.00061Z"
      fill="#CE349A"
    />
    <path
      d="M7.75 1.00061C7.75 0.448326 8.19772 0.000610352 8.75 0.000610352H14.75C15.3023 0.000610352 15.75 0.448326 15.75 1.00061V5.00061C15.75 5.5529 15.3023 6.00061 14.75 6.00061H8.75C8.19772 6.00061 7.75 5.5529 7.75 5.00061V1.00061Z"
      fill="#CE349A"
    />
    <path
      d="M8 10.0005C8 8.89592 8.89543 8.00049 10 8.00049H20V20.0005H10C8.89543 20.0005 8 19.1051 8 18.0005V10.0005Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const Topical48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.19211 13.0004C9.19211 10.9753 10.8495 9.33368 12.8941 9.33368H35.1059C37.1505 9.33368 38.8079 10.9753 38.8079 13.0004V42.3341C38.8079 44.3592 37.1505 46.0009 35.1059 46.0009H12.8941C10.8495 46.0009 9.19211 44.3592 9.19211 42.3341V13.0004Z"
      fill="#CE349A"
    />
    <path
      d="M16.1333 3.8336C16.1333 2.82107 16.962 2.00024 17.9843 2.00024H29.0902C30.1125 2.00024 30.9412 2.82107 30.9412 3.8336V11.167C30.9412 12.1796 30.1125 13.0004 29.0902 13.0004H17.9843C16.962 13.0004 16.1333 12.1796 16.1333 11.167V3.8336Z"
      fill="#CE349A"
    />
    <path
      d="M17.0316 20.4057C17.0316 18.3409 18.6565 16.6671 20.661 16.6671H38.8079V39.0988H20.661C18.6565 39.0988 17.0316 37.425 17.0316 35.3602V20.4057Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Topical24,
  sm: Topical24,
  xl: Topical48,
  lg: Topical48,
  xxl: Topical48,
  xxxl: Topical48,
};

export const TopicalIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'topical-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import type {
  AppMode,
  Cart,
  CustomerReservationHistory,
  DeepReadonly,
  Id,
  RouteAppMode,
  Store,
  _DeepReadonlyArray,
} from '@jane/shared/models';
import {
  Box,
  ChevronLeftIcon,
  ConditionalWrapper,
  Flex,
} from '@jane/shared/reefer';
import { shadows, spacing } from '@jane/shared/reefer-emotion';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import {
  PaginationCountContext,
  inIframe,
  monolithPaths,
  postMessageToIframeParent,
} from '@jane/shared/util';
import type { PartnerHostedConfig } from '@jane/shared/util';

import { PoweredByJane } from '../poweredByJane';
import { EmbeddedButton } from './embeddedButton';
import { UserActions } from './userActions';

export const EmbeddedHeaderContainer = styled(Flex)<{ clearZIndex?: boolean }>(
  ({ clearZIndex, theme }) => ({
    ...spacing({ px: 24 }),
    height: 50,
    width: '100%',
    zIndex: !clearZIndex ? 420 : 'initial',
    boxShadow: shadows.divider,
    backgroundColor: theme.colors.grays.white,
  })
);

interface SubdomainHeaderProps {
  disableInteraction?: boolean;
  store?: Store;
}

const SubdomainHeader = ({
  disableInteraction,
  store,
}: SubdomainHeaderProps) => {
  const theme = useTheme();
  const rootDomain = window.location.host.split('.').slice(-2).join('.');
  const parentUrl =
    store?.subdomain_setting?.home_url ||
    `${window.location.protocol}//${rootDomain}`;
  const { isPartnerHosted } = useRuntimeConfig();

  if (inIframe() || !store || isPartnerHosted) return null;

  return (
    <Flex m={16} alignItems="center" justifyContent="space-between">
      <>
        {disableInteraction ? (
          <div
            css={{
              width: 50,
              height: 50,
              backgroundImage: `url(${store.photo})`,
              backgroundSize: 'cover',
            }}
          ></div>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={parentUrl}
            aria-label="Store photo link"
            css={{
              width: 50,
              height: 50,
              backgroundImage: `url(${store.photo})`,
              backgroundSize: 'cover',
            }}
          />
        )}
        <ConditionalWrapper
          condition={!disableInteraction}
          wrapper={(children) => (
            <a
              css={{ color: theme.colors.grays.mid }}
              target="_blank"
              rel="noopener noreferrer"
              href={parentUrl}
            >
              {children}
            </a>
          )}
        >
          <Flex alignItems="center">
            <ChevronLeftIcon size="sm" color="grays-mid" />
            <span css={spacing({ ml: 4 })}>Back home</span>
          </Flex>
        </ConditionalWrapper>
      </>
    </Flex>
  );
};

interface Props {
  appMode: AppMode;
  cart: Cart & { status: string | undefined };
  customerId?: Id;
  disableAuthFeatures: boolean;
  disableInteraction?: boolean;
  hasUser: boolean;
  hidePrices: boolean;
  inProgressReservations: _DeepReadonlyArray<CustomerReservationHistory>;
  onCartClick: () => void;
  partnerId: Id;
  routeAppMode?: RouteAppMode;
  routePartnerHostedConfig: PartnerHostedConfig;
  store?: DeepReadonly<Store>;
}

export const EmbeddedHeader = ({
  appMode,
  cart,
  customerId,
  disableAuthFeatures,
  disableInteraction,
  hasUser,
  hidePrices,
  inProgressReservations,
  onCartClick,
  partnerId,
  routeAppMode,
  routePartnerHostedConfig,
  store,
}: Props) => {
  const { setPaginationCount } = useContext(PaginationCountContext);
  const { isPartnerHosted } = useRuntimeConfig();
  const menuUrlTest = `^\\/embed\\/stores\\/\\d{1,}\\/?(\\bmenu\\b.*)?$`;
  const menuUrlRegex = RegExp(menuUrlTest, 'i');
  const isMenuPage = menuUrlRegex.test(window.location.pathname);
  const location = useLocation();

  if (!routeAppMode || location.pathname.includes('checkout')) {
    return null;
  }

  return (
    // TODO: For testing purposes; remove once this is the default header
    <div className="new-header">
      <SubdomainHeader disableInteraction={disableInteraction} store={store} />
      <EmbeddedHeaderContainer
        alignItems="center"
        clearZIndex={isPartnerHosted}
      >
        {!isPartnerHosted && !isMenuPage && (
          <Box mr={8}>
            <EmbeddedButton
              onClick={() => {
                if (disableInteraction) return;

                postMessageToIframeParent({
                  messageType: 'shrinkNextResize',
                });
                setPaginationCount(1);
              }}
              to={
                disableInteraction
                  ? undefined
                  : monolithPaths.embeddedMenu(
                      routeAppMode,
                      routePartnerHostedConfig,
                      false
                    )
              }
            >
              Menu
            </EmbeddedButton>
          </Box>
        )}
        {!store?.white_label_enabled &&
          appMode !== 'operatorEmbed' &&
          !isPartnerHosted && <PoweredByJane />}
        <Box ml="auto">
          <UserActions
            cart={cart}
            customerId={customerId}
            disableAuthFeatures={disableAuthFeatures}
            disableInteraction={disableInteraction}
            hasUser={hasUser}
            hidePrices={hidePrices}
            inProgressReservations={inProgressReservations}
            onCartClick={onCartClick}
            partnerId={partnerId}
            routeAppMode={routeAppMode}
            routePartnerHostedConfig={routePartnerHostedConfig}
          />
        </Box>
      </EmbeddedHeaderContainer>
    </div>
  );
};

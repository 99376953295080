import { useTheme } from '@emotion/react';
import type {
  AuthEventEmitter,
  EmbedConfig,
} from '@thoughtspot/visual-embed-sdk';
import { AuthStatus, AuthType, init } from '@thoughtspot/visual-embed-sdk';
import { useEffect, useState } from 'react';

import {
  THOUGHTSPOT_HOST,
  createThoughtspotToken,
} from '@jane/business-admin/data-access';
import { effects } from '@jane/shared/reefer';
import type { ReeferTheme } from '@jane/shared/reefer';

type AuthState = 'Success' | 'Failed' | undefined;

const thoughtspotCSS = (theme: ReeferTheme) => {
  const {
    colors: { primary, grays },
  } = theme;

  return {
    variables: {
      '--ts-var-root-font-family':
        "'Source Sans Pro', Arial, Helvetica, sans-serif",
      '--ts-var-button--primary-color': grays.white,
      '--ts-var-root-background': grays.white,
      '--ts-var-root-color': grays.dark,
      '--ts-var-button--primary-background': primary.main,
      '--ts-var-button--primary-hover-background': effects.darken(
        primary.main,
        0.1
      ),
      '--ts-var-viz-background': grays.white,
      '--ts-var-button--secondary-color': grays.black,
      '--ts-var-button--secondary-background': grays.ultralight,
      '--ts-var-chip-background': grays.ultralight,
      '--ts-var-chip-color': grays.black,
      '--ts-var-chip--hover-background': effects.darken(grays.ultralight, 0.1),
      '--ts-var-viz-border-radius': '12px',
      '--ts-var-viz-box-shadow': `0 0 0 1px ${grays.light}`,
    },
    rules_UNSTABLE: {
      '[data-testid="layout-item"]': {
        'box-shadow': 'none',
      },
      '[data-testid="kpi_herodata"]': {
        'font-size': '36px',
        'line-height': '40px',
      },
      '[data-testid="pinboard-header"]': {
        'margin-top': '24px',
      },
    },
  };
};

const getAuthToken = async () => {
  const { token } = await createThoughtspotToken();
  return token;
};

export const useThoughtspot = ({
  username,
  readyToAuth = false,
}: {
  readyToAuth: boolean;
  username: string;
}) => {
  const [authState, setAuthState] = useState<AuthState>();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    let authStatus: undefined | AuthEventEmitter = undefined;
    const initConfig: EmbedConfig = {
      thoughtSpotHost: THOUGHTSPOT_HOST,
      authType: AuthType.TrustedAuthToken,
      username,
      getAuthToken,
      customizations: {
        style: {
          customCSS: thoughtspotCSS(theme),
        },
      },
    };

    if (!readyToAuth) return;

    authStatus = authStatus || init(initConfig);

    authStatus.on(AuthStatus.FAILURE, () => {
      setAuthState('Failed');
      setIsAuthenticating(false);
    });

    authStatus.on(AuthStatus.SDK_SUCCESS, () => {
      setAuthState('Success');
      setIsAuthenticating(false);
    });
  }, [username, readyToAuth]);

  return { authState, isAuthenticating };
};

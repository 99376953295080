import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import type { Spacing } from '../../../styling';
import type { ReeferBaseProps } from '../../../types';
import {
  DESKTOP_PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_VERTICAL,
} from '../drawer.styles';

const StyledContentDivider = styled.hr(({ theme }) => ({
  backgroundColor: theme.colors.grays.light,
  border: 'none',
  flex: '0 0 auto',
  height: 1,
  ...spacing({
    mx: -MOBILE_PADDING_HORIZONTAL as Spacing,
    my: PADDING_VERTICAL,
  }),
  [mediaQueries.tablet('min')]: {
    ...spacing({
      mx: -DESKTOP_PADDING_HORIZONTAL as Spacing,
      my: PADDING_VERTICAL,
    }),
  },
}));

export function DrawerContentDivider({
  'data-testid': testId,
  id,
}: ReeferBaseProps) {
  return <StyledContentDivider data-testid={testId} id={id} />;
}

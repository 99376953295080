import type { CSSObject } from '@emotion/react';

import { getColorValue, getComponentStyles } from '../../utils/componentStyles';
import type { ButtonStylePropsWithTheme } from './button.types';

const darkenContent = (
  opacity: number,
  borderRadius: string,
  borderWidth: string
): CSSObject => {
  const borderWidthNum: number = parseInt(borderWidth);

  return {
    '&:before': {
      backgroundColor: `rgba(0, 0, 0, ${opacity})`,
      borderRadius,
      content: '""',
      inset: -borderWidthNum,
      position: 'absolute',
      zIndex: 1,
    },
  };
};

export const getButtonVariantStyle = ({
  theme,
  variant,
}: ButtonStylePropsWithTheme): CSSObject => {
  const buttonStyles = getComponentStyles('Button', theme, variant);

  const { backgroundColor, borderColor, borderWidth, typographyColor } =
    buttonStyles;

  const background = getColorValue(backgroundColor, theme);
  const border = getColorValue(borderColor, theme);
  const color = getColorValue(typographyColor, theme);

  return {
    /**
     * Remove default blue outline, but create a more stark
     * visual difference when tabbing through buttons for
     * accessibility.
     */
    '&:focus-visible': {
      outline: 'none',
      ...darkenContent(0.1, 'inherit', borderWidth),
    },
    '@media (hover: hover)': {
      '&:hover': {
        ...darkenContent(0.1, 'inherit', borderWidth),
      },
    },
    background,
    border: `${borderWidth} solid ${border}`,
    color,
    position: 'relative',
  };
};

import styled from '@emotion/styled';
import type { FunctionComponent, ReactNode } from 'react';
import { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';

import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import type {
  AppMode,
  Brand,
  Id,
  MenuProduct,
  PendingCartProduct,
  Product,
  ReservationCartProduct,
  RouteAppMode,
  Store,
} from '@jane/shared/models';
import { useMobileMediaQuery } from '@jane/shared/reefer';
import type {
  Breadcrumb,
  EcommPaths,
  MenuProduct as ZMenuProduct,
} from '@jane/shared/types';
import { monolithPaths } from '@jane/shared/util';
import type { Categories, PartnerHostedConfig } from '@jane/shared/util';

export const MENU_PRODUCT_ITEM_HEIGHT = 475;
export const MENU_PRODUCT_MOBILE_ITEM_HEIGHT = 425;
export const MENU_PRODUCT_SPONSORED_HEIGHT = 32;

export const DEFAULT_MOBILE_CAROUSEL_CARD_WIDTH = 165;
export const DEFAULT_MOBILE_CARD_WIDTH = 150;
export const DEFAULT_DESKTOP_CARD_WIDTH = 200;
export const DEFAULT_MOBILE_GRID_GAP = 16;
export const DEFAULT_DESKTOP_GRID_GAP = 24;

interface PriceLookUp {
  [key: string]: string;
}

export type ProductCardMode = 'default' | 'storeMenu';

export const PRICE_LOOKUP: PriceLookUp = {
  HALF_GRAM: '.5g',
  GRAM: '1g',
  TWO_GRAM: '2g',
  EIGHTH_OUNCE: '3.5g',
  QUARTER_OUNCE: '7g',
  HALF_OUNCE: '14g',
  OUNCE: '28g',
};

export const ItemContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'relative',
  transition: 'box-shadow .3s',
  ':focus-visible': {
    boxShadow: '0 2px 11px 0 rgba(108, 108, 108, 0.5)',
    outline: 'none',
  },
});

interface StyledLinkType {
  appMode: AppMode;
  baseCardHeight: number;
  height?: string | number;
}

const StyledLink = styled(Link, {
  shouldForwardProp: (propName: string) =>
    propName !== 'appMode' && propName !== 'baseCardHeight',
})<StyledLinkType>(({ height, appMode, baseCardHeight, theme }) => ({
  position: 'relative',
  width: '100%',
  height,
  maxHeight: baseCardHeight - 200,
  pointerEvents: appMode === 'headless' ? 'none' : undefined,
  ':focus-visible': {
    outline: 'none',
  },
  ':focus-visible:before': {
    backgroundColor: theme.colors.grays.hover,
    borderRadius: 'inherit',
    content: '""',
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: '1',
  },
}));

export const productDetailLink = ({
  product,
  appMode,
  store,
  brand,
  fromAllSpecials,
  fromMenu,
  bundleSpecialId,
  fromSpecialId,
  routeAppMode,
  routePartnerHostedConfig,
}: {
  appMode: AppMode;
  brand?: Pick<Brand, 'id' | 'name'> | boolean;
  bundleSpecialId?: Id | null;
  fromAllSpecials?: boolean | null;
  fromMenu?: boolean;
  fromSpecialId?: Id | null;
  product: Product | MenuProduct | ZMenuProduct;
  routeAppMode: RouteAppMode;
  routePartnerHostedConfig: PartnerHostedConfig;
  store?: Pick<Store, 'id' | 'name'>;
}) => {
  const productNameAndSlugAndId = {
    id: (product as MenuProduct).product_id || (product as Product).id,
    name: product.name,
    slug: product.url_slug,
    brand: product.brand,
  };

  if (appMode === 'default') {
    if (store) {
      return monolithPaths.storeProduct({
        appMode: routeAppMode,
        store,
        partnerHostedConfig: routePartnerHostedConfig,
        product: productNameAndSlugAndId,
        fromAllSpecials,
        fromMenu,
        bundleSpecialId,
        fromSpecialId,
      });
    }
    if (brand) {
      return monolithPaths.brandProduct({
        appMode: routeAppMode,
        brand: brand as Brand,
        partnerHostedConfig: routePartnerHostedConfig,
        product: productNameAndSlugAndId,
        fromMenu,
        bundleSpecialId,
      });
    }
    return monolithPaths.product({
      appMode: routeAppMode,
      partnerHostedConfig: routePartnerHostedConfig,
      product: productNameAndSlugAndId,
      slug: product.url_slug,
      fromMenu,
      bundleSpecialId,
    });
  }

  return store && appMode === 'brandEmbed'
    ? monolithPaths.brandEmbedStoreProduct(
        routeAppMode,
        routePartnerHostedConfig,
        store,
        productNameAndSlugAndId,
        fromAllSpecials,
        fromMenu,
        bundleSpecialId,
        fromSpecialId
      )
    : monolithPaths.embeddedProductDetail({
        appMode: routeAppMode,
        partnerHostedConfig: routePartnerHostedConfig,
        product: productNameAndSlugAndId,
        fromAllSpecials,
        fromMenu,
        bundleSpecialId,
        fromSpecialId,
      });
};

export interface LinkProps {
  appMode: AppMode;
  brand?: Brand;
  breadcrumbs?: Breadcrumb[];
  bundleSpecialId?: Id | null;
  cartIsOpen: boolean;
  children: ReactNode;
  fromAllSpecials?: boolean | null;
  fromMenu?: boolean;
  fromSpecialId?: Id | null;
  height?: number | string;
  onClick?: () => void;
  onCloseCart?: () => void;
  onSetBreadcrumbs?: (searchState: JaneSearchState<AlgoliaProduct>) => void;
  pdpPath?: EcommPaths['menuProduct'];
  product: Product;
  routeAppMode: RouteAppMode;
  routePartnerHostedConfig: PartnerHostedConfig;
  searchState: JaneSearchState<AlgoliaProduct> | undefined;
  store?: Store;
}

export const ProductDetailPageLink: FunctionComponent<LinkProps> = ({
  appMode,
  brand,
  breadcrumbs,
  cartIsOpen,
  product,
  searchState,
  children,
  store,
  fromMenu,
  bundleSpecialId,
  fromAllSpecials,
  fromSpecialId,
  height,
  onCloseCart,
  onSetBreadcrumbs,
  pdpPath,
  routeAppMode,
  routePartnerHostedConfig,
}) => {
  const { brand_id, category } = useParams<{
    brand_id: string;
    category?: Categories;
  }>();

  const memoizedOnClick = useCallback(() => {
    if (cartIsOpen) {
      onCloseCart && onCloseCart();
    }
    onSetBreadcrumbs && searchState && onSetBreadcrumbs(searchState);
  }, [searchState, cartIsOpen]);

  const isMobile = useMobileMediaQuery({});

  const baseCardHeight = isMobile
    ? MENU_PRODUCT_MOBILE_ITEM_HEIGHT
    : MENU_PRODUCT_ITEM_HEIGHT;

  const isFromProductBrand = brand_id !== undefined;
  const pdpLink = pdpPath
    ? pdpPath(product)
    : productDetailLink({
        product,
        appMode,
        store,
        brand: isFromProductBrand ? brand : false,
        fromAllSpecials,
        fromMenu,
        bundleSpecialId,
        fromSpecialId,
        routeAppMode,
        routePartnerHostedConfig,
      });

  const locationState = {
    ...(category ? { fromCategory: category } : {}),
    ...(breadcrumbs ? { breadcrumbs } : {}),
  };

  return (
    <StyledLink
      appMode={appMode}
      baseCardHeight={baseCardHeight}
      height={height}
      to={pdpLink}
      onClick={memoizedOnClick}
      role="link"
      state={locationState}
    >
      {children}
    </StyledLink>
  );
};

export const sortByPriceId = (
  cartProduct: ReservationCartProduct[] | PendingCartProduct[],
  menuProduct: MenuProduct | ZMenuProduct
): ReservationCartProduct[] | PendingCartProduct[] =>
  cartProduct.sort((a, b) =>
    (menuProduct?.available_weights || []).indexOf(
      a.price_id.replace('_', ' ')
    ) >
    (menuProduct?.available_weights || []).indexOf(b.price_id.replace('_', ' '))
      ? 1
      : -1
  );

// Copied from monolith/shared/src/lib/customer/selectors.ts
export const checkEmbeddedMode = (appMode: AppMode) =>
  [
    'embedded',
    'whiteLabel',
    'brandEmbed',
    'operatorEmbed',
    'headless',
    'framelessEmbed',
  ].includes(appMode);

import get from 'lodash/get';

import type { SpecialRulesV2 } from '@jane/business-admin/types';
import { buildBundleConditions, buildRules } from '@jane/business-admin/util';
import type { BundleProductConditions } from '@jane/shared/models';

import { getApplyToSelection } from '../../form';

// TODO:
// Maybe move to form.ts file?
export const convertConditionsToRules = (
  bundleConditions?: BundleProductConditions
) => {
  if (!bundleConditions) {
    return;
  }

  const requiredRules = buildRules({
    excludeProduct: bundleConditions.independent?.excluded_product_ids,
    includeBrand: bundleConditions.independent?.brands,
    includeCategory: bundleConditions.independent?.kinds,
    includeLineage: bundleConditions.independent?.lineage
      ? [bundleConditions.independent.lineage]
      : false,
    includeProduct: bundleConditions.independent?.included_product_ids,
    includePrices: bundleConditions.independent?.product_threshold,
    includeWeight: bundleConditions.independent?.weights,
  });

  const discountedRules = buildRules({
    excludeProduct: bundleConditions.dependent?.excluded_product_ids,
    includeBrand: bundleConditions.dependent?.brands,
    includeCategory: bundleConditions.dependent?.kinds,
    includeLineage: bundleConditions.dependent?.lineage
      ? [bundleConditions.dependent.lineage]
      : false,
    includeProduct: bundleConditions.dependent?.included_product_ids,
    includePrices: bundleConditions.dependent?.product_threshold,
    includeWeight: bundleConditions.dependent?.weights,
  });

  return {
    required_rules: {
      apply_to: getApplyToSelection(requiredRules),
      rules: requiredRules,
    },
    discounted_rules: {
      apply_to: getApplyToSelection(discountedRules),
      rules: discountedRules,
    },
  };
};

export const convertRulesToConditions = ({
  discountedRules,
  max_number_of_discounted_products,
  requiredRules,
  threshold_number_of_items_in_cart,
  settings,
  max_applications_per_cart,
}: {
  discountedRules: SpecialRulesV2;
  max_applications_per_cart: number;
  max_number_of_discounted_products: number;
  requiredRules: SpecialRulesV2;
  settings: any;
  threshold_number_of_items_in_cart: number;
}) => {
  const dependentConditions = {
    brandConditions: get(discountedRules, 'includes.0.brands'),
    includedProductConditions: get(discountedRules, 'includes.0.product_ids'),
    excludedProductConditions: get(discountedRules, 'excludes.0.product_ids'),
    kindConditions: get(discountedRules, 'includes.0.kinds'),
    lineageConditions: get(discountedRules, 'includes.0.lineages'),
    priceConditions: get(discountedRules, 'product_threshold'),
    weightConditions: get(discountedRules, 'includes.0.weights'),
  };
  const independentConditions = {
    brandConditions: get(requiredRules, 'includes.0.brands'),
    includedProductConditions: get(requiredRules, 'includes.0.product_ids'),
    excludedProductConditions: get(requiredRules, 'excludes.0.product_ids'),
    kindConditions: get(requiredRules, 'includes.0.kinds'),
    lineageConditions: get(requiredRules, 'includes.0.lineages'),
    priceConditions: get(requiredRules, 'product_threshold'),
    weightConditions: get(requiredRules, 'includes.0.weights'),
  };

  return {
    dependent: {
      ...buildBundleConditions(dependentConditions),
      max_number_of_discounted_products,
    },
    independent: {
      ...buildBundleConditions(independentConditions),
      threshold_number_of_items_in_cart,
    },
    settings,
    max_applications_per_cart,
  };
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Indica16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.24454 4V12.502H8.75254V4H7.24454Z" fill="#666666" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4ZM4 1H12C13.6569 1 15 2.34315 15 4V12C15 13.6569 13.6569 15 12 15H4C2.34315 15 1 13.6569 1 12V4C1 2.34315 2.34315 1 4 1Z"
      fill="#666666"
    />
  </svg>
);

const Indica24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={22}
      height={22}
      rx={3}
      stroke="#0E0E0E"
      strokeWidth={2}
    />
    <path d="M11.6283 17V7.19H13.3683V17H11.6283Z" fill="#0E0E0E" />
  </svg>
);

const SIZE_MAP = {
  sm: Indica16,
  md: Indica24,
  lg: Indica24,
  xl: Indica24,
  xxl: Indica24,
  xxxl: Indica24,
};

export const IndicaIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'indica-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

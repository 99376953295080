/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Photos24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.75735 3.65142L20.3485 6.75725C21.4154 7.04313 22.0486 8.1398 21.7627 9.20673L20 15.7852V19.7308C20.7706 19.2104 21.3644 18.4205 21.624 17.4518L23.6945 9.72437C24.2663 7.59051 23 5.39716 20.8661 4.82539L9.27498 1.71956C7.14112 1.1478 4.94777 2.41413 4.376 4.54799L3.44067 8.03869C3.62344 8.01312 3.81016 7.9999 3.99996 7.9999H5.52162L6.30786 5.06563C6.59374 3.9987 7.69041 3.36553 8.75735 3.65142Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 10H4C2.89543 10 2 10.8954 2 12V20C2 21.1046 2.89543 22 4 22H16C17.1046 22 18 21.1046 18 20V12C18 10.8954 17.1046 10 16 10ZM4 8C1.79086 8 0 9.79086 0 12V20C0 22.2091 1.79086 24 4 24H16C18.2091 24 20 22.2091 20 20V12C20 9.79086 18.2091 8 16 8H4Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99993 14.0103L6.45362 17.9999H13.5462L9.99993 14.0103ZM4.9588 16.6711L8.50511 12.6815C9.30069 11.7865 10.6992 11.7865 11.4947 12.6815L15.0411 16.6711C16.1876 17.9609 15.2719 19.9999 13.5462 19.9999H6.45362C4.72792 19.9999 3.81231 17.9609 4.9588 16.6711Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Photos24,
  sm: Photos24,
  lg: Photos24,
  xl: Photos24,
  xxl: Photos24,
  xxxl: Photos24,
};

export const PhotosIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'photos-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

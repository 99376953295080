import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { PartnerLogo as PartnerLogoV1 } from '@jane/shared/reefer-v1';
import { PartnerLogo as PartnerLogoV2 } from '@jane/shared/reefer-v2';
import type { PartnerLogoProps } from '@jane/shared/reefer-v2';

export const PartnerLogo = (props: PartnerLogoProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PartnerLogoV2 {...props} />;
  }
  return <PartnerLogoV1 {...props} />;
};

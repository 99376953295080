import type { StoreCategory } from '@jane/shared/models';

import type { CategoryOption } from './CategoryConditions';
import { transformData } from './util/rules';

export const inputData_Full: StoreCategory[] = [
  {
    kind: 'flower',
    subtypes: [
      {
        subtype: 'flower',
        brand_subtypes: [],
      },
      {
        subtype: 'Baked Goods',
        brand_subtypes: ['bulk edit brand subtype', 'test QA'],
      },
      {
        subtype: 'Infused Flower',
        brand_subtypes: [],
      },
      {
        subtype: '(Subcategory not set)',
        brand_subtypes: ['bulk edit brand subtype', 'subtype test', 'test QA'],
      },
    ],
  },
  {
    kind: 'edible',
    subtypes: [
      {
        subtype: 'Bars',
        brand_subtypes: ['Bar', 'Barbaz2'],
      },
      {
        subtype: 'Candies',
        brand_subtypes: ['Camino Gummies', 'Caramel Chew'],
      },
      {
        subtype: 'Chocolates',
        brand_subtypes: ['Chocolate', 'Savor', 'Terra Bites'],
      },
      {
        subtype: 'Drinks',
        brand_subtypes: ['Bottled Tea'],
      },
      {
        subtype: 'Mints',
        brand_subtypes: ['Petra Mints'],
      },
      {
        subtype: 'Sublinguals',
        brand_subtypes: ['chewies'],
      },
      {
        subtype: 'Teas/Coffee',
        brand_subtypes: ['Teabags'],
      },
      {
        subtype: '(Subcategory not set)',
        brand_subtypes: [
          'Bar',
          'Barbaz2',
          'Bottled Tea',
          'Camino Gummies',
          'Caramel Chew',
          'chewies',
          'Chocolate',
          'Petra Mints',
          'Savor',
          'Teabags',
          'Terra Bites',
        ],
      },
    ],
  },
  {
    kind: 'extract',
    subtypes: [
      {
        subtype: 'Hash',
        brand_subtypes: ['Hash'],
      },
      {
        subtype: 'Live Resins',
        brand_subtypes: ['Live Resin'],
      },
      {
        subtype: 'Rosins',
        brand_subtypes: ['Rosin'],
      },
      {
        subtype: 'Shatters',
        brand_subtypes: ['Shatter'],
      },
      {
        subtype: 'Waxes',
        brand_subtypes: ['Wax'],
      },
      {
        subtype: '(Subcategory not set)',
        brand_subtypes: ['Hash', 'Live Resin', 'Rosin', 'Shatter', 'Wax'],
      },
    ],
  },
  {
    kind: 'tincture',
    subtypes: [
      {
        subtype: 'Sublinguals',
        brand_subtypes: ['Tincture 123'],
      },
      {
        subtype: '(Subcategory not set)',
        brand_subtypes: ['Tincture 123'],
      },
    ],
  },
  {
    kind: 'topical',
    subtypes: [
      {
        subtype: 'Balms',
        brand_subtypes: ['Balm'],
      },
      {
        subtype: '(Subcategory not set)',
        brand_subtypes: ['Balm'],
      },
    ],
  },
];

/*

updated_categories gets all the things to populate the select modal with
specials/special_id gets the specials data that has the rules key with everything that's already been selected already
legacy API response for rules:

"rules": {
    "includes": [
        {
            "kinds": [
                {
                    "kind": "tincture",
                    "root_subtype": "Sublinguals"
                },
                {
                    "kind": "topical",
                    "root_subtype": "Balms"
                }
            ]
        }
    ]
}
*/

export const MOCK_RULES_DATA: any = [
  {
    kind: 'flower',
    root_subtype: 'Baked Goods',
    brand_subtype: 'test QA',
  },
  {
    kind: 'flower',
    root_subtype: 'Baked Goods',
    brand_subtype: 'bulk edit brand subtype',
  },
  {
    kind: 'edible',
    root_subtype: 'Chocolates',
    brand_subtype: 'Chocolate',
  },
  {
    kind: 'edible',
    root_subtype: 'Chocolates',
    brand_subtype: 'Savor',
  },
  {
    kind: 'tincture',
    root_subtype: '(Subcategory not set)',
    brand_subtype: 'Tincture 123',
  },
];

export const PRODUCT_CATEGORIES_DEV_V2: CategoryOption[] =
  transformData(inputData_Full);

import { HttpResponse, http } from 'msw';

import type { AdFlight, Id } from '@jane/shared/models';

import type {
  ApiProduct,
  FetchCartToppersApiResponse,
} from '../../data-access/fetchCartToppers';

type MockUseCartToppersProps = {
  adFlights?: AdFlight[];
  products?: ApiProduct[];
  storeId: Id;
};

export const mockUseCartToppers = ({
  adFlights,
  storeId,
  products = [],
}: MockUseCartToppersProps) => {
  const apiReponse: FetchCartToppersApiResponse = {
    flights: adFlights,
    max_price_per_product: 25,
    products,
  };

  return http.get(`*/stores/${storeId}/toppers`, async () => {
    return HttpResponse.json(apiReponse, { status: 200 });
  });
};

import { css } from '@emotion/css';
import clsx from 'clsx';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import { Banner } from '../banner';
import { CheckIcon, ErrorIcon } from '../icon';
import {
  toastMiddleBottomIn,
  toastMiddleOut,
  toastMiddleTopIn,
  toastRightIn,
  toastRightOut,
} from './toast.styles';
import type { ToastProps, ToastVariants } from './toast.types';

const icons: { [Property in keyof ToastVariants as string]: ReactElement } = {
  error: <ErrorIcon />,
  success: <CheckIcon />,
};

/**
 * Toast is used to create a notification at the top right of the user's window.
 * Toasts are created using the useToast() hook.
 */

export function Toast({
  className,
  'data-testid': testId,
  label,
  id,
  onClose,
  position = 'top-right',
  style,
  time = 5000,
  variant = 'success',
}: ToastProps) {
  const toastAnimationIn = position.includes('right')
    ? toastRightIn
    : position.includes('top')
    ? toastMiddleTopIn
    : toastMiddleBottomIn;

  const toastAnimationOut = position.includes('right')
    ? toastRightOut
    : toastMiddleOut;

  const [animate, setAnimate] = useState(
    css`
      animation: ${toastAnimationIn} 0.25s ease;
    `
  );

  const handleClose = () => {
    setAnimate(
      css`
        animation: ${toastAnimationOut} 0.25s ease;
      `
    );
    setTimeout(() => onClose(id), 220);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (time) {
      timeout = setTimeout(() => handleClose(), time);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      className={clsx(className, animate)}
      data-testid={testId}
      id={id}
      style={style}
    >
      <Banner
        icon={icons[variant]}
        label={label}
        variant={variant}
        {...(!time && { onDismiss: handleClose })}
      />
    </div>
  );
}

import type { Id } from '@jane/shared/models';

import type {
  AddedProductToCartPayload,
  ClickedSeeAllPayload,
  ProductCardImpressionPayload,
  ProductDetailPagePayload,
} from './tracking';
import { EventNames, trackUserSessionEvent } from './tracking';

export type TrackUserSessionProductProps = {
  productId: Id;
  storeId: Id;
};
export const trackUserSessionAddedProductToCart = (
  props: TrackUserSessionProductProps
) => {
  const { productId, storeId } = props;
  const payload: AddedProductToCartPayload = {
    eventName: EventNames.AddedProductToCart,
    productId: Number(productId),
    storeId: Number(storeId),
  };
  trackUserSessionEvent(payload);
};
export const trackUserSessionViewedProduct = (
  props: TrackUserSessionProductProps
) => {
  const { productId, storeId } = props;
  const payload: ProductDetailPagePayload = {
    eventName: EventNames.ViewedProduct,
    productId: Number(productId),
    storeId: Number(storeId),
  };
  trackUserSessionEvent(payload);
};

export type TrackUserSessionSeeAllProps = {
  category: string;
  storeId: Id;
};
export const trackUserSessionClickedSeeAll = (
  props: TrackUserSessionSeeAllProps
) => {
  const { category, storeId } = props;
  const payload: ClickedSeeAllPayload = {
    eventName: EventNames.ClickedSeeAll,
    category,
    storeId: Number(storeId),
  };
  trackUserSessionEvent(payload);
};

export type TrackUserSessionProductCardImpressionProps = {
  adToken?: string;
  flightId?: number;
  hasJaneGoldDeal?: boolean;
  janeGoldLabel?: string | null;
  kevelToken?: string;
  location?: string;
  model?: string;
  myHighD?: string;
  position?: {
    x: number;
    y: number;
  };
  productId: Id;
  storeId: Id;
};
export const trackUserSessionProductCardImpression = (
  props: TrackUserSessionProductCardImpressionProps
) => {
  const {
    adToken,
    flightId,
    hasJaneGoldDeal,
    janeGoldLabel,
    kevelToken,
    model,
    myHighD,
    productId,
    storeId,
    position,
    location,
  } = props;
  const payload: ProductCardImpressionPayload = {
    eventName: EventNames.ViewedProductCard,
    adToken,
    flightId,
    hasJaneGoldDeal,
    janeGoldLabel,
    kevelToken,
    model,
    myHighD,
    productId: Number(productId),
    storeId: Number(storeId),
    position,
    location,
  };
  trackUserSessionEvent(payload);
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fKdHaUHxjEv0Ebk8_hi7 {\n  background-color: var(--colors-grays-white);\n  border-bottom: 1px solid var(--colors-grays-light);\n  height: var(--tableCell-height);\n  max-height: var(--tableCell-height);\n  padding: 8px 12px;\n  vertical-align: inherit;\n  width: var(--tableCell-width);\n}\n\n.JISMpXrm8uAUeUs7GSAA {\n  cursor: pointer;\n}\n\n.JISMpXrm8uAUeUs7GSAA:hover {\n  background-color: var(--colors-grays-light);\n}\n\n.fKdHaUHxjEv0Ebk8_hi7::-webkit-scrollbar {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-table/src/lib/tableCell/tableCell.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,kDAAkD;EAClD,+BAA+B;EAC/B,mCAAmC;EACnC,iBAAiB;EACjB,uBAAuB;EACvB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,aAAa;AACf","sourcesContent":[".tableCell {\n  background-color: var(--colors-grays-white);\n  border-bottom: 1px solid var(--colors-grays-light);\n  height: var(--tableCell-height);\n  max-height: var(--tableCell-height);\n  padding: 8px 12px;\n  vertical-align: inherit;\n  width: var(--tableCell-width);\n}\n\n.tableCell__clickable {\n  cursor: pointer;\n}\n\n.tableCell__clickable:hover {\n  background-color: var(--colors-grays-light);\n}\n\n.tableCell::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableCell": "fKdHaUHxjEv0Ebk8_hi7",
	"tableCell__clickable": "JISMpXrm8uAUeUs7GSAA"
};
export default ___CSS_LOADER_EXPORT___;

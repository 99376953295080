import { Helmet } from 'react-helmet-async';

import type { Store } from '@jane/shared/models';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import type { AppMode, StoreSeo } from '@jane/shared/types';
import {
  absolutePath,
  inIframe,
  isNoStore,
  monolithPaths,
} from '@jane/shared/util';

import { OpenGraph } from './openGraph';

const generateOpenGraphContent = (
  store: Store,
  customPageTitleSuffix: string | null | undefined,
  omitJane: boolean
) => ({
  description: generateMetaDescription(store, omitJane),
  omitJane,
  title: generateStoreDetailTitle(store, customPageTitleSuffix, omitJane),
  url: absolutePath(monolithPaths.store(store, '', store.url_slug)),
});

const generateMetaDescription = (
  {
    name,
    metro_area,
    delivery,
    recreational,
    medical,
  }: Pick<
    Store,
    'name' | 'metro_area' | 'delivery' | 'recreational' | 'medical'
  >,
  omitJane: boolean,
  customPageTitleSuffix?: string | null
) => {
  let storeType = 'medical and recreational';
  let fulfillmentType = 'pickup';
  let suffix = ' with Jane.';

  if (medical && !recreational) {
    storeType = 'medical';
  }

  if (recreational && !medical) {
    storeType = 'recreational';
  }

  if (omitJane || customPageTitleSuffix) {
    suffix = customPageTitleSuffix ? ` with ${customPageTitleSuffix}.` : '.';
  }

  if (delivery) {
    fulfillmentType = 'delivery and pickup';
  }

  return `Visit ${name}'s dispensary in ${metro_area} and order ${storeType} cannabis online for ${fulfillmentType}. Browse our online dispensary menu for flower, edibles, vape and more${suffix}`;
};

export const generateStoreDetailTitle = (
  { name, metro_area }: Pick<Store, 'name' | 'metro_area'>,
  customPageTitleSuffix?: string | null,
  omitJane?: boolean | null
) => {
  if (omitJane)
    return customPageTitleSuffix
      ? `Shop ${name} Dispensary ${metro_area} Browse Menu & Deals with ${customPageTitleSuffix}`
      : `Shop ${name} Dispensary ${metro_area} Browse Menu & Deals`;
  return `Shop ${name} Dispensary ${metro_area} Browse Menu & Deals with ${
    customPageTitleSuffix ?? 'Jane'
  }`;
};

export const shouldAllowBotIndexing = (
  store: Store,
  isPartnerHosted = false
) => {
  // Do not allow bot indexing if store is not visible.
  // Always indexing if menu is partner hosted, overriding store visibility.
  if (isPartnerHosted) {
    return true;
  }
  return store.visible;
};

export const StoreDetailMetaData = ({
  store,
  storeSeo,
  appMode,
}: {
  appMode: AppMode;
  store: Store;
  storeSeo: StoreSeo;
}) => {
  const { isPartnerHosted, partnerHostedPath } = useRuntimeConfig();

  const getCanonicalPath = () => {
    if (!store || isNoStore(store)) {
      return '';
    }

    const root = `${window.location.protocol}//${window.location.host}`;
    const storePath = monolithPaths.store(store, '', store.url_slug);

    if (inIframe()) {
      return absolutePath(storePath);
    } else if (appMode === 'framelessEmbed') {
      // make sure leading slash is present
      const hostedPath =
        !partnerHostedPath || partnerHostedPath.startsWith('/')
          ? partnerHostedPath
          : `/${partnerHostedPath}`;
      // trailing slash for compatibility with WP sites
      return `${root}${hostedPath}/`;
    } else {
      return `${root}${storePath}`;
    }
  };

  const robotsMeta =
    !isNoStore(store) &&
    shouldAllowBotIndexing(store, isPartnerHosted) ? null : (
      <meta name="robots" content="noindex,nofollow" />
    );

  const customPageTitleSuffix =
    appMode !== 'default' ? storeSeo?.page_title : undefined;

  const omitJane = appMode === 'framelessEmbed';

  return (
    <>
      <Helmet>
        <title>
          {generateStoreDetailTitle(store, customPageTitleSuffix, omitJane)}
        </title>
        <link rel="canonical" href={getCanonicalPath()} />
        {storeSeo?.disable_meta && appMode !== 'default' ? null : (
          <meta
            name="description"
            content={generateMetaDescription(
              store,
              omitJane,
              customPageTitleSuffix
            )}
          />
        )}
        {robotsMeta}
      </Helmet>
      <OpenGraph
        {...generateOpenGraphContent(store, customPageTitleSuffix, omitJane)}
      />
    </>
  );
};

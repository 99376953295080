import { useEffect, useState } from 'react';

import { Button } from '../button';
import { Flex } from '../flex';
import { Modal } from '../modal';
import { Typography } from '../typography';
import { DrawerContext } from './drawer.context';
import { StyledDrawer } from './drawer.styles';
import type { DrawerProps } from './drawer.types';

/**
 * `Drawer` component is a modal that slides from the side of the screen
 * with available headers and footers, or full-screen drawers.
 */
export const Drawer = ({
  allowScroll = false,
  background = 'grays-white',
  children,
  className,
  closeConfirmation = false,
  container = 'body',
  'data-testid': testId,
  fitScreen = true,
  full = false,
  height = 'fit-content',
  hideOverlay = false,
  id,
  open,
  onRequestClose,
  overlayClose = true,
  placement = 'right',
  style,
  width = 'fit-content',
}: DrawerProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [closing, setClosing] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;

  const handleClose = () => {
    setShowConfirmation(false);
    setClosing(true);
    if (!allowScroll) {
      document.body.style.overflow = 'unset';
    }
    timeout = setTimeout(() => {
      onRequestClose();
      setClosing(false);
    }, 475);
  };

  const onClose = () => {
    if (closeConfirmation) {
      setShowConfirmation(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (open && !allowScroll) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      setClosing(false);
      clearTimeout(timeout);
      if (!allowScroll) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [open]);

  return (
    <DrawerContext.Provider value={{ fitScreen, onRequestClose: onClose }}>
      <StyledDrawer
        background={background}
        className={className}
        closing={closing}
        container={container}
        data-testid={testId}
        fitScreen={fitScreen}
        full={full}
        height={height}
        hideOverlay={hideOverlay}
        id={id}
        onRequestClose={onClose}
        open={open}
        overlayClose={overlayClose}
        placement={placement}
        style={style}
        width={width}
      >
        {children}
      </StyledDrawer>

      {!!closeConfirmation && (
        <Modal
          open={showConfirmation}
          onRequestClose={handleClose}
          variant="dialogue"
        >
          <Modal.Content>
            <Typography textAlign="center" variant="header-bold">
              Are you sure?
            </Typography>

            <Typography>{closeConfirmation}</Typography>

            <Flex gap={16} mt={24}>
              <Button
                label="Cancel"
                full
                variant="secondary"
                onClick={() => setShowConfirmation(false)}
              />

              <Button label="Confirm" full onClick={handleClose} />
            </Flex>
          </Modal.Content>
        </Modal>
      )}
    </DrawerContext.Provider>
  );
};

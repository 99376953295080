import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type { ToastContentProps, ToastProviderProps } from '../toast.types';
import { renderToasts } from './renderToasts';
import { ToastContext } from './toastContext';

export const ToastProvider = ({
  children,
  position,
  render = renderToasts,
  verticalOffset,
}: ToastProviderProps) => {
  const [toasts, setToasts] = useState<ToastContentProps[]>([]);

  const add = useCallback(
    (content: Omit<ToastContentProps, 'id'>) => {
      setToasts((toasts: ToastContentProps[]) => [
        { id: uuid(), ...content },
        ...toasts,
      ]);
    },
    [setToasts]
  );

  const remove = useCallback(
    (id: string) => {
      setToasts((toasts: ToastContentProps[]) =>
        toasts.filter((t: ToastContentProps) => t.id !== id)
      );
    },
    [setToasts]
  );

  const clear = useCallback(() => setToasts([]), []);

  const contextValue = useMemo(
    () => ({
      add,
      clear,
      toasts,
    }),
    [add, clear, toasts]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {render({ remove, toasts, verticalOffset, position })}
    </ToastContext.Provider>
  );
};

import styled from '@emotion/styled';

import type { PartnerLogoSizeType } from './partnerLogo';

export interface StyledPartnerLogoProps {
  size: PartnerLogoSizeType;
}

export const PARTNER_LOGO_SIZES = {
  xs: 48,
  sm: 64,
  md: 72,
  lg: 96,
  xl: 120,
};

export const getBorderRadius = (size: PartnerLogoSizeType) =>
  size === 'md' ? 'lg' : size;

export const StyledPartnerLogo = styled.div<StyledPartnerLogoProps>(
  ({ size, theme }) => ({
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: PARTNER_LOGO_SIZES[size],
    justifyContent: 'space-around',
    overflow: 'hidden',
    width: PARTNER_LOGO_SIZES[size],
    backgroundColor: theme.colors.grays.white,
  })
);

import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { ModalNames } from '@jane/business-admin/util';
import { Button, Flex } from '@jane/shared/reefer';

import { AppearanceModal } from './AppearanceModal';
import { ArrangeModal } from './ArrangeModal';
import { CommsBannerModal } from './commsBannerModal/CommsBannerModal';
import { FiltersAndLabelsModal } from './filtersAndLabelsModal/FiltersAndLabelsModal';
import { MenuRowModal } from './menuRowModal/MenuRowModal';

export const StoreMenuHeader = () => {
  const {
    modalOpen: arrangeModalOpen,
    openModal: setArrangeModalOpen,
    closeModal: setArrangeModalClose,
  } = useModalActionsWithTracking(ModalNames.ArrangeMenuRows);
  const {
    modalOpen: commsBannerModalOpen,
    openModal: setCommsBannerModalOpen,
    closeModal: setCommsBannerModalClose,
  } = useModalActionsWithTracking(ModalNames.CommsBanner);
  const {
    modalOpen: appearanceModalOpen,
    openModal: setAppearanceModalOpen,
    closeModal: setAppearanceModalClose,
  } = useModalActionsWithTracking(ModalNames.Appearance);
  const {
    modalOpen: menuRowModalOpen,
    openModal: setMenuRowModalOpen,
    closeModal: setMenuRowModalClose,
  } = useModalActionsWithTracking(ModalNames.CreateMenuRow);
  const {
    modalOpen: filtersAndLabelsModalOpen,
    openModal: setFiltersAndLabelsModalOpen,
    closeModal: setFiltersAndLabelsModalClose,
  } = useModalActionsWithTracking(ModalNames.FiltersAndLabels);
  // const {
  //   modalOpen: previewModalOpen,
  //   openModal: setPreviewModalOpen,
  //   closeModal: setPreviewModalClose,
  // } = useModalActionsWithTracking(ModalNames.FiltersAndLabels);

  return (
    <>
      <Flex width="100%" justifyContent="space-between">
        <Flex gap={16}>
          <Button
            label="Customize appearance"
            variant="secondary"
            onClick={() => setAppearanceModalOpen()}
          />
          <Button
            label="Arrange"
            variant="secondary"
            onClick={() => setArrangeModalOpen()}
          />
          <Button
            label="Edit banner"
            variant="secondary"
            onClick={() => setCommsBannerModalOpen()}
          />
          <Button
            label="Edit filters and labels"
            onClick={() => setFiltersAndLabelsModalOpen()}
            variant="secondary"
          />
        </Flex>
        <Flex gap={16}>
          {/* <Button
            label="Preview"
            onClick={() => setPreviewModalOpen()}
            variant="tertiary"
          /> */}
          <Button label="Add menu row" onClick={() => setMenuRowModalOpen()} />
        </Flex>
      </Flex>

      {menuRowModalOpen && (
        <MenuRowModal closeModal={() => setMenuRowModalClose()} />
      )}

      {arrangeModalOpen && (
        <ArrangeModal closeModal={() => setArrangeModalClose()} />
      )}

      {filtersAndLabelsModalOpen && (
        <FiltersAndLabelsModal
          closeModal={() => setFiltersAndLabelsModalClose()}
        />
      )}

      {commsBannerModalOpen && (
        <CommsBannerModal closeModal={() => setCommsBannerModalClose()} />
      )}

      {appearanceModalOpen && (
        <AppearanceModal closeModal={() => setAppearanceModalClose()} />
      )}

      {/* {previewModalOpen && (
        <PreviewModal closeModal={() => setPreviewModalClose()} />
      )} */}
    </>
  );
};

import styled from '@emotion/styled';

interface StyledButtonToggleProps {
  full: boolean;
}

export const StyledButtonToggle = styled.div<StyledButtonToggleProps>(
  {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  ({ full }) => ({
    justifyContent: full ? 'space-between' : 'flex-start',
    width: full ? '100%' : 'auto',
  })
);

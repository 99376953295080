import { Box } from '../../box';
import { Flex } from '../../flex';
import { DismissIcon } from '../../icon';
import { Typography } from '../../typography';
import { useDrawerContext } from '../drawer.context';
import { MOBILE_PADDING_HORIZONTAL, PADDING_VERTICAL } from '../drawer.styles';
import type { DrawerHeaderProps } from '../drawer.types';
import { StyledDrawerHeader } from './drawerHeader.styles';

export const DrawerHeader = ({
  background = 'grays-white',
  centerContent,
  children,
  className,
  divider = false,
  id,
  rightContent,
  subtitle,
  'data-testid': testId,
  title,
}: DrawerHeaderProps) => {
  const { onRequestClose } = useDrawerContext();

  return (
    <StyledDrawerHeader
      background={background}
      className={className}
      divider={divider}
      flexDirection="column"
      flexWrap="wrap"
      id={id}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      minHeight="80px"
      px={MOBILE_PADDING_HORIZONTAL}
      py={PADDING_VERTICAL}
      data-testid={testId}
    >
      <Flex alignItems="center" width="100%" minHeight="48px">
        <Box position="absolute" left={MOBILE_PADDING_HORIZONTAL} zIndex={1}>
          <Flex alignSelf="center" alignItems="center">
            <DismissIcon
              altText="Close Drawer"
              color="inherit"
              onClick={onRequestClose}
              data-testid="drawer-header-close-icon"
            />
          </Flex>
        </Box>
        <Flex justifySelf="center">
          <Box
            position="absolute"
            width="100%"
            top={0}
            left={0}
            my={PADDING_VERTICAL}
          >
            {centerContent ? (
              centerContent
            ) : (
              <>
                {title && (
                  <Typography branded variant="header-bold">
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography color="text-light">{subtitle}</Typography>
                )}
              </>
            )}
          </Box>
        </Flex>

        {rightContent && (
          <Box position="absolute" right={MOBILE_PADDING_HORIZONTAL}>
            <Flex alignSelf="center" alignItems="center">
              {rightContent}
            </Flex>
          </Box>
        )}
      </Flex>

      {children && (
        <Flex width="100%" justifyContent="center">
          {children}
        </Flex>
      )}
    </StyledDrawerHeader>
  );
};

import type { CSSObject } from '@emotion/react';

export type Spacing =
  | -128
  | -96
  | -72
  | -64
  | -56
  | -48
  | -40
  | -32
  | -24
  | -20
  | -16
  | -12
  | -8
  | -4
  | -2
  | 0
  | 2
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 32
  | 40
  | 48
  | 56
  | 64
  | 72
  | 96
  | 128
  | 'auto';

const calculateProp = (prop?: Spacing): string | undefined => {
  if (prop === undefined) return;
  return !isNaN(parseInt(prop as string)) ? `${prop}px` : `${prop}`;
};

export interface MarginProperties {
  /** Set top, bottom, left and right margins */
  m?: Spacing;

  /** Set bottom margin */
  mb?: Spacing;

  /** Set left margin */
  ml?: Spacing;

  /** Set right margin */
  mr?: Spacing;

  /** Set top margin */
  mt?: Spacing;

  /** Set left and right margins */
  mx?: Spacing;

  /** Set top and bottom margins */
  my?: Spacing;
}

export const margin = ({
  m,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
}: MarginProperties): CSSObject => {
  if (
    m != null &&
    mx == null &&
    my == null &&
    mt == null &&
    mr == null &&
    mb == null &&
    ml == null
  )
    return { margin: calculateProp(m) };

  return {
    marginBottom: calculateProp(mb ?? my ?? m),
    marginLeft: calculateProp(ml ?? mx ?? m),
    marginRight: calculateProp(mr ?? mx ?? m),
    marginTop: calculateProp(mt ?? my ?? m),
  };
};

export interface PaddingProperties {
  /** Sets top, bottom, left and right padding */
  p?: Spacing;

  /** Sets bottom padding */
  pb?: Spacing;

  /** Sets left padding */
  pl?: Spacing;

  /** Sets right padding */
  pr?: Spacing;

  /** Sets top padding */
  pt?: Spacing;

  /** Sets left and right padding */
  px?: Spacing;

  /** Sets top and bottom padding */
  py?: Spacing;
}

export const padding = ({
  p,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
}: PaddingProperties): CSSObject => {
  if (
    p != null &&
    px == null &&
    py == null &&
    pt == null &&
    pr == null &&
    pb == null &&
    pl == null
  )
    return { padding: calculateProp(p) };

  return {
    paddingBottom: calculateProp(pb ?? py ?? p),
    paddingLeft: calculateProp(pl ?? px ?? p),
    paddingRight: calculateProp(pr ?? px ?? p),
    paddingTop: calculateProp(pt ?? py ?? p),
  };
};

export const spacing = (
  args: MarginProperties & PaddingProperties
): CSSObject => ({
  ...margin(args),
  ...padding(args),
});

export const negative = (spacing: Spacing): Spacing => -spacing as Spacing;

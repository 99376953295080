/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Extract24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 10.0006C2 4.47776 6.47715 0.000610352 12 0.000610352C17.5228 0.000610352 22 4.47776 22 10.0006V14.0006C22 15.1052 21.1046 16.0006 20 16.0006C18.8954 16.0006 18 15.1052 18 14.0006C18 12.896 17.1046 12.0006 16 12.0006C14.8954 12.0006 14 12.896 14 14.0006V22.0006C14 23.1052 13.1046 24.0006 12 24.0006C10.8954 24.0006 10 23.1052 10 22.0006V14.0006C10 12.896 9.10457 12.0006 8 12.0006C6.89543 12.0006 6 12.896 6 14.0006V18.0005C6 19.1051 5.10457 20.0005 4 20.0005C2.89543 20.0005 2 19.1051 2 18.0005V10.0006Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9.00049C17.4477 9.00049 17 8.55277 17 8.00049C17 6.34363 15.6569 5.00049 14 5.00049C13.4477 5.00049 13 4.55277 13 4.00049C13 3.4482 13.4477 3.00049 14 3.00049C16.7614 3.00049 19 5.23906 19 8.00049C19 8.55277 18.5523 9.00049 18 9.00049Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const Extract48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5 20.3346C5.5 10.2093 13.7827 2.00122 24 2.00122C34.2173 2.00122 42.5 10.2093 42.5 20.3346V27.6679C42.5 29.6929 40.8435 31.3346 38.8 31.3346C36.7565 31.3346 35.1 29.6929 35.1 27.6679C35.1 25.6428 33.4435 24.0012 31.4 24.0012C29.3565 24.0012 27.7 25.6428 27.7 27.6679V42.3346C27.7 44.3596 26.0435 46.0012 24 46.0012C21.9565 46.0012 20.3 44.3596 20.3 42.3346V27.6679C20.3 25.6428 18.6435 24.0012 16.6 24.0012C14.5565 24.0012 12.9 25.6428 12.9 27.6679V35.001C12.9 37.026 11.2435 38.6677 9.2 38.6677C7.15655 38.6677 5.5 37.026 5.5 35.001V20.3346Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.6667 18.0012C34.6541 18.0012 33.8333 17.1804 33.8333 16.1679C33.8333 13.1303 31.3709 10.6679 28.3333 10.6679C27.3208 10.6679 26.5 9.84708 26.5 8.83455C26.5 7.82203 27.3208 7.00122 28.3333 7.00122C33.3959 7.00122 37.5 11.1053 37.5 16.1679C37.5 17.1804 36.6792 18.0012 35.6667 18.0012Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Extract24,
  sm: Extract24,
  xl: Extract48,
  lg: Extract48,
  xxl: Extract48,
  xxxl: Extract48,
};

export const ExtractIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'extract-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  BearAvatar as BearAvatarV1,
  CatAvatar as CatAvatarV1,
  HoundDogAvatar as HoundDogAvatarV1,
  KoalaAvatar as KoalaAvatarV1,
  LionAvatar as LionAvatarV1,
  MonkeyAvatar as MonkeyAvatarV1,
  OwlAvatar as OwlAvatarV1,
  PineappleAvatar as PineappleAvatarV1,
  SeaLionAvatar as SeaLionAvatarV1,
  SkunkAvatar as SkunkAvatarV1,
  SlothAvatar as SlothAvatarV1,
  TerrierDogAvatar as TerrierDogAvatarV1,
} from '@jane/shared/reefer-v1';
import {
  BearAvatar as BearAvatarV2,
  CatAvatar as CatAvatarV2,
  HoundDogAvatar as HoundDogAvatarV2,
  KoalaAvatar as KoalaAvatarV2,
  LionAvatar as LionAvatarV2,
  MonkeyAvatar as MonkeyAvatarV2,
  OwlAvatar as OwlAvatarV2,
  PineappleAvatar as PineappleAvatarV2,
  SeaLionAvatar as SeaLionAvatarV2,
  SkunkAvatar as SkunkAvatarV2,
  SlothAvatar as SlothAvatarV2,
  TerrierDogAvatar as TerrierDogAvatarV2,
} from '@jane/shared/reefer-v2';
import type { StaticIconProps } from '@jane/shared/reefer-v2';

export const BearAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BearAvatarV2 {...props} />;
  }
  return <BearAvatarV1 {...props} />;
};
export const CatAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CatAvatarV2 {...props} />;
  }
  return <CatAvatarV1 {...props} />;
};
export const HoundDogAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <HoundDogAvatarV2 {...props} />;
  }
  return <HoundDogAvatarV1 {...props} />;
};
export const KoalaAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <KoalaAvatarV2 {...props} />;
  }
  return <KoalaAvatarV1 {...props} />;
};
export const LionAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <LionAvatarV2 {...props} />;
  }
  return <LionAvatarV1 {...props} />;
};
export const MonkeyAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <MonkeyAvatarV2 {...props} />;
  }
  return <MonkeyAvatarV1 {...props} />;
};
export const OwlAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <OwlAvatarV2 {...props} />;
  }
  return <OwlAvatarV1 {...props} />;
};
export const PineappleAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <PineappleAvatarV2 {...props} />;
  }
  return <PineappleAvatarV1 {...props} />;
};

export const SeaLionAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SeaLionAvatarV2 {...props} />;
  }
  return <SeaLionAvatarV1 {...props} />;
};
export const SkunkAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SkunkAvatarV2 {...props} />;
  }
  return <SkunkAvatarV1 {...props} />;
};
export const SlothAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <SlothAvatarV2 {...props} />;
  }
  return <SlothAvatarV1 {...props} />;
};

export const TerrierDogAvatar = (props: StaticIconProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TerrierDogAvatarV2 {...props} />;
  }
  return <TerrierDogAvatarV1 {...props} />;
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex as FlexV1 } from '@jane/shared/reefer-v1';
import { Flex as FlexV2 } from '@jane/shared/reefer-v2';
import type { FlexProps } from '@jane/shared/reefer-v2';

export const Flex = (props: FlexProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <FlexV2 {...props} />;
  }
  return <FlexV1 {...props} />;
};

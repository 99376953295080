import styled from '@emotion/styled';
import type { CSSProperties, ForwardedRef } from 'react';
import { forwardRef, useRef } from 'react';
import { InView } from 'react-intersection-observer';

import type { GoogleFontFamily } from '@jane/shared-ecomm/data-access';
import { ChevronDownIcon, List, Popover } from '@jane/shared/reefer';
import type { LabeledOption, Nullable } from '@jane/shared/types';
import { loadFont } from '@jane/shared/util';

import { TextWithFont } from './TextWithFont';

const ListItemButton = styled('button')(({ theme }) => ({
  height: '100%',
  padding: '12px 8px',
  textAlign: 'left',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.colors.grays.hover,
  },
}));

interface FontListItemProps {
  /** Name of the font family to display */
  displayName?: string;
  /** Underlying font family name to use in CSS rules */
  family: string;
  /** URL to the minimal font face needed to draw this font */
  fontUrl?: string;
  onClick: () => void;
}

const FontListItem = ({
  displayName,
  family,
  fontUrl,
  onClick,
}: FontListItemProps) => {
  return (
    <li>
      <ListItemButton onClick={onClick}>
        <InView
          onChange={(inView) =>
            inView && loadFont({ fontFamily: family, url: fontUrl })
          }
          triggerOnce
        >
          <TextWithFont $fontFamily={family}>
            {displayName ?? family}
          </TextWithFont>
        </InView>
      </ListItemButton>
    </li>
  );
};

const Field = styled('div')<{ $fontFamily?: string }>(
  ({ $fontFamily, theme }) => ({
    display: 'flex',
    fontFamily: $fontFamily,
    padding: '16px 12px',
    borderRadius: 12,
    border: `1px solid ${theme.colors.grays.light}`,
    '&:hover': {
      backgroundColor: theme.colors.grays.hover,
    },
  })
);

interface SelectedFontFieldProps {
  fontFamily: LabeledOption;
}

const SelectedFontField = forwardRef(
  (
    { fontFamily: { label, value } }: SelectedFontFieldProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Field ref={ref} $fontFamily={value}>
        {label}
        <ChevronDownIcon ml={128} />
      </Field>
    );
  }
);

export interface FontPickerProps {
  activeFontFamily?: Nullable<LabeledOption>;
  defaultFontFamily: LabeledOption;
  fontFamilies: GoogleFontFamily[];
  label: string;
  limit?: number;
  onChange: (activeFontFamily: Nullable<GoogleFontFamily>) => void;
}

const PopoverContent = styled(Popover.Content)<{
  $minWidth?: CSSProperties['minWidth'];
}>(({ $minWidth }) => ({
  minWidth: $minWidth,
}));

export const FontPicker = ({
  activeFontFamily,
  defaultFontFamily,
  fontFamilies,
  label,
  limit = 100,
  onChange,
}: FontPickerProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  return (
    <Popover
      label={label}
      target={
        <SelectedFontField
          ref={targetRef}
          fontFamily={activeFontFamily ?? defaultFontFamily}
        />
      }
    >
      {({ closePopover }) => (
        <PopoverContent $minWidth={targetRef.current?.clientWidth}>
          <List label={`${label}-list`}>
            <FontListItem
              onClick={() => {
                onChange(null);
                closePopover();
              }}
              displayName={defaultFontFamily.label}
              family={defaultFontFamily.value}
            />

            {fontFamilies.slice(0, limit).map((font) => (
              <FontListItem
                key={font.family}
                onClick={() => {
                  onChange(font);
                  closePopover();
                }}
                family={font.family}
                fontUrl={font.menu}
              />
            ))}
          </List>
        </PopoverContent>
      )}
    </Popover>
  );
};

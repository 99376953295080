import styled from '@emotion/styled';

import { Box } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const StyledCheckoutBox = styled(Box)({
  ...spacing({ p: 24, pt: 20, mb: 16 }),
  [mediaQueries.mobile('legacy', 'min')]: {
    ...spacing({ mb: 24 }),
  },
  [mediaQueries.tablet('min')]: {
    ...spacing({ p: 40 }),
  },
});

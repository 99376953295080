import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex, Typography, useMobileMediaQuery } from '@jane/shared/reefer';

import {
  DisplayMode,
  useProductCardContext,
} from '../../productCardProvider/productCardProvider';
import ListViewStateContainer from '../listViewStateContainer';
import ActionCardContent from './actionCardContent';
import EditingCard from './editingCard';
import { StyledCardGroup } from './listViewProductCard.styles';
import ProductInfoCardContent from './productInfoCardContent';

export const ListViewProductCard = ({ isAd }: { isAd: boolean }) => {
  const isMobile = useMobileMediaQuery({});
  const { displayMode } = useProductCardContext();
  const direction = isMobile ? 'column' : 'row';
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  return (
    <ListViewStateContainer>
      {({
        decrementQuantity,
        discountedPrice,
        incrementQuantity,
        multipleCountDisabled,
        noSelectedWeightDefault,
        onAddToCartPressed,
        onWeightSelected,
        originalPrice,
        selectedQuantity,
        selectedWeight,
        shoppingDisabled,
        soldByWeight,
        weights,
      }) => {
        return (
          <StyledCardGroup
            data-testid="list-view-product-card"
            width="100%"
            border="grays-light"
            mb={myHighMenu ? 0 : 16}
            flat={myHighMenu}
            myHighMenu={myHighMenu}
          >
            <Flex flexDirection={direction}>
              <Flex flexDirection="column" width="100%" p={24}>
                {myHighMenu && isAd && (
                  <Typography
                    variant="mini"
                    color="text-light"
                    data-janedm-tag="sponsored"
                    mb={16}
                  >
                    Sponsored
                  </Typography>
                )}
                <ProductInfoCardContent />
              </Flex>
              <ActionCardContent
                noSelectedWeightDefault={noSelectedWeightDefault}
                onWeightSelected={onWeightSelected}
                selectedWeight={selectedWeight}
                weights={weights}
              />
            </Flex>
            {displayMode === DisplayMode.Edit && (
              <EditingCard
                decrementQuantity={decrementQuantity}
                discountedPrice={discountedPrice}
                incrementQuantity={incrementQuantity}
                multipleCountDisabled={multipleCountDisabled}
                onAddToCartPressed={onAddToCartPressed}
                originalPrice={originalPrice}
                selectedQuantity={selectedQuantity}
                selectedWeight={selectedWeight}
                shoppingDisabled={shoppingDisabled}
                soldByWeight={soldByWeight}
              />
            )}
          </StyledCardGroup>
        );
      }}
    </ListViewStateContainer>
  );
};

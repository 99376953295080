/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Friends24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={8} r={8} fill="#CE349A" />
    <circle cx={16} cy={8} r={8} fill="white" fillOpacity={0.8} />
    <path
      d="M14.6667 6.00008C14.6667 6.73646 14.0697 7.33341 13.3333 7.33341C12.597 7.33341 12 6.73646 12 6.00008C12 5.2637 12.597 4.66675 13.3333 4.66675C14.0697 4.66675 14.6667 5.2637 14.6667 6.00008Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M11.2929 9.95964C11.6834 9.56912 12.3166 9.56912 12.7071 9.95964C14.5257 11.7783 17.4743 11.7783 19.2929 9.95964C19.6834 9.56912 20.3166 9.56912 20.7071 9.95964C21.0976 10.3502 21.0976 10.9833 20.7071 11.3739C18.1074 13.9735 13.8926 13.9735 11.2929 11.3739C10.9024 10.9833 10.9024 10.3502 11.2929 9.95964Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M18.6667 7.33341C19.4031 7.33341 20 6.73646 20 6.00008C20 5.2637 19.4031 4.66675 18.6667 4.66675C17.9303 4.66675 17.3334 5.2637 17.3334 6.00008C17.3334 6.73646 17.9303 7.33341 18.6667 7.33341Z"
      fill="black"
      fillOpacity={0.5}
    />
    <circle cx={8} cy={16} r={8} fill="#FFC220" />
    <path
      d="M6.66667 14.0001C6.66667 14.7365 6.06971 15.3334 5.33333 15.3334C4.59695 15.3334 4 14.7365 4 14.0001C4 13.2637 4.59695 12.6667 5.33333 12.6667C6.06971 12.6667 6.66667 13.2637 6.66667 14.0001Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M3.29289 17.9596C3.68342 17.5691 4.31658 17.5691 4.70711 17.9596C6.52572 19.7783 9.47428 19.7783 11.2929 17.9596C11.6834 17.5691 12.3166 17.5691 12.7071 17.9596C13.0976 18.3502 13.0976 18.9833 12.7071 19.3739C10.1074 21.9735 5.89256 21.9735 3.29289 19.3739C2.90237 18.9833 2.90237 18.3502 3.29289 17.9596Z"
      fill="black"
      fillOpacity={0.5}
    />
    <path
      d="M10.6667 15.3334C11.4031 15.3334 12 14.7365 12 14.0001C12 13.2637 11.4031 12.6667 10.6667 12.6667C9.93033 12.6667 9.33337 13.2637 9.33337 14.0001C9.33337 14.7365 9.93033 15.3334 10.6667 15.3334Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Friends24,
  sm: Friends24,
  lg: Friends24,
  xl: Friends24,
  xxl: Friends24,
  xxxl: Friends24,
};

export const FriendsIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'friends-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import type { CSSObject } from '@emotion/css';
import styled from '@emotion/styled';

import type { ReeferTheme } from '../../types';
import type { ImageProps } from './image.types';

export interface StyledImageProps
  extends Omit<ImageProps, 'altText' | 'borderRadius' | 'sizes'> {
  borderRadius: string;
}

interface StyledImageWrapperProps {
  border?: boolean;
  borderRadius: 'circular' | 'rounded' | 'rounded-small' | 'none' | string;
  height: string;
  width: string;
}

interface StyledImageBorderRadiusProps
  extends Pick<StyledImageWrapperProps, 'borderRadius'> {
  theme: ReeferTheme;
}

const imageBorderRadius = ({
  borderRadius,
  theme,
}: StyledImageBorderRadiusProps): CSSObject | undefined => {
  let radius = borderRadius;

  if (borderRadius === 'none') {
    return;
  }

  if (borderRadius === 'rounded') {
    radius = theme.borderRadius.lg as string;
  }

  if (borderRadius === 'rounded-small') {
    radius = theme.borderRadius.sm as string;
  }

  if (borderRadius === 'circular') {
    radius = '50%';
  }

  return {
    borderRadius: radius,
  };
};

export const StyledImageWrapper = styled.div<StyledImageWrapperProps>(
  ({ theme, height, width, border, borderRadius }) => ({
    flexShrink: 0,
    '&:before': {
      bottom: 0,
      content: "''",
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      ...(border && {
        border: '1px inset rgba(0, 0, 0, 0.05)',
      }),
      ...imageBorderRadius({ borderRadius, theme }),
    },
    height,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    width,
  })
);

export const StyledImage = styled.img<StyledImageProps>(
  ({ aspectRatio, objectFit, borderRadius, theme, objectPosition }) => ({
    aspectRatio,
    height: '100%',
    objectFit,
    objectPosition,
    width: '100%',
    ...imageBorderRadius({ borderRadius, theme }),
  })
);

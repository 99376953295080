/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Trash24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H20V17C20 19.7614 17.7614 22 15 22H9C6.23858 22 4 19.7614 4 17V8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H7.10002C7.56329 3.71776 9.58104 2 12 2C14.419 2 16.4367 3.71776 16.9 6ZM9.17071 6H14.8293C14.4175 4.83481 13.3062 4 12 4C10.6938 4 9.58254 4.83481 9.17071 6ZM6 17V8H18V17C18 18.6569 16.6569 20 15 20H9C7.34315 20 6 18.6569 6 17Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 11C14.5523 11 15 11.4477 15 12L15 16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16L13 12C13 11.4477 13.4477 11 14 11Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 11C10.5523 11 11 11.4477 11 12L11 16C11 16.5523 10.5523 17 10 17C9.44771 17 9 16.5523 9 16L9 12C9 11.4477 9.44772 11 10 11Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Trash24,
  sm: Trash24,
  lg: Trash24,
  xl: Trash24,
  xxl: Trash24,
  xxxl: Trash24,
};

export const TrashIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'trash-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import styled from '@emotion/styled';

import {
  getColorValue,
  textColorForBackgroundColor,
} from '../../../utils/componentStyles';
import type { CardContentProps } from '../card.types';

type StyledCardContentProps = Omit<CardContentProps, 'testId' | 'children'>;

export const StyledCardContent = styled.div<StyledCardContentProps>(
  ({ theme, background, border, borderWidth, height, onClick, width }) => ({
    background: getColorValue(background, theme),
    border:
      border === 'none' ? undefined : `solid ${getColorValue(border, theme)}`,
    borderWidth,
    color: textColorForBackgroundColor(background, theme),
    height: height,
    outline: 'none',
    padding: '16px',
    width: width,
    ...(onClick && {
      ':focus-visible': {
        background: `radial-gradient(transparent, transparent, ${theme.colors.grays.ultralight})`,
        transition: '250ms all',
      },
      cursor: 'pointer',
    }),
  })
);

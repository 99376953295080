import {
  Flex,
  Form,
  Modal,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

export const TemplateSection = ({
  isCreateMode,
}: {
  isCreateMode?: boolean;
}) => {
  const {
    clearErrors,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const isValidTemplateURL = !errors['published_template_url'];
  const templateOverrides = watch('template_overrides');

  return (
    <>
      <Typography variant="body-bold">Template</Typography>
      <Typography color="grays-mid" mb={24}>
        These fields can be used to display templates.
      </Typography>
      <Flex flexDirection="column" mb={24}>
        <Flex alignItems="flex-end" gap={24}>
          <Form.TextField
            disableMobileInputStyling
            type="text"
            autocomplete="off"
            label="URL of template to import"
            name="published_template_url"
            helperText="e.g: https://littlegreenpotshop.com/template-page"
          />
        </Flex>
      </Flex>
      {!isCreateMode && (
        <Form.TextAreaField
          autocomplete="off"
          label="HTML"
          name="published_template_html"
          rows={3}
          mb={24}
        />
      )}
      <Form.SwitchField
        label="Template overrides"
        name="template_overrides"
        mb={24}
        helperText="These fields can be used to resolve errors with template imports"
      />
      {!templateOverrides ? null : (
        <Form.TextField
          disableMobileInputStyling
          type="text"
          autocomplete="off"
          label="Content selector"
          name="content_selector"
          helperText="Defaults to #app"
          mb={24}
          onChange={(value) => {
            if (!isValidTemplateURL) {
              clearErrors('published_template_url');
            }
            setValue('content_selector', value, { shouldDirty: true });
          }}
        />
      )}
      <Modal.ContentDivider />
    </>
  );
};

import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FontPicker, TextWithFont } from '@jane/business-admin/components';
import {
  useFetchCurrentBloomMenu,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import type { BloomFontFamily } from '@jane/business-admin/types';
import { businessPaths } from '@jane/business-admin/util';
import type { GoogleFontFamily } from '@jane/shared-ecomm/data-access';
import { useGoogleFonts } from '@jane/shared-ecomm/data-access';
import { ConfirmChangeModal } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import { useNavigateBack } from '@jane/shared/hooks';
import {
  Box,
  Button,
  ChevronLeftIcon,
  Flex,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import type { LabeledOption, Nullable } from '@jane/shared/types';
import { loadFont } from '@jane/shared/util';

const toLabeledOption = (
  googleFont?: Nullable<GoogleFontFamily>
): Nullable<LabeledOption> =>
  googleFont
    ? {
        label: googleFont.family,
        value: googleFont.family,
      }
    : null;

const toBloomFontFamily = (
  googleFont?: Nullable<GoogleFontFamily>
): Nullable<BloomFontFamily> => {
  if (!googleFont) {
    return null;
  }
  /** @see https://developers.google.com/fonts/docs/getting_started#specifying_font_families_and_styles_in_a_stylesheet_url */
  const styleSheetUrl = `https://fonts.googleapis.com/css?${new URLSearchParams(
    {
      family: `${googleFont.family}:r,b`,
      display: 'swap',
    }
  ).toString()}`;

  return { font_family: googleFont.family, url: styleSheetUrl };
};

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

export const PlusFonts = () => {
  const goBack = useNavigateBack(businessPaths.plus());
  const [headerFont, setHeaderFont] = useState<Nullable<GoogleFontFamily>>();
  const [bodyFont, setBodyFont] = useState<Nullable<GoogleFontFamily>>();
  const [isDirty, setIsDirty] = useState(false);
  const [discard, setDiscard] = useState(false);
  const fontQuery = useGoogleFonts({ apiKey: config.googleFontsKey });
  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const { mutate: updateBloomMenu } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );
  const toast = useToast();

  const supportedFontFamilies = useMemo(
    () => fontQuery.data ?? [],
    [fontQuery.data]
  );

  const fontUpdates = useMemo(() => {
    return {
      heading: toBloomFontFamily(headerFont),
      body: toBloomFontFamily(bodyFont),
    };
  }, [bodyFont, headerFont]);

  const publish = useCallback(() => {
    updateBloomMenu(
      {
        theme: {
          ...bloomMenuQuery.data?.theme?.bloom,
          fonts: fontUpdates,
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          toast.add({
            label: 'Menu updated!',
            variant: 'success',
          });
          setIsDirty(false);
        },
      }
    );
  }, [bloomMenuQuery.data?.theme?.bloom, toast, updateBloomMenu, fontUpdates]);

  const preview = useCallback(() => {
    updateBloomMenu(
      {
        theme_draft: {
          ...bloomMenuQuery.data?.theme?.bloom,
          fonts: fontUpdates,
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          if (!bloomMenuQuery.data?.menu_url) {
            return;
          }
          const previewUrl = new URL(bloomMenuQuery.data.menu_url);
          previewUrl.searchParams.set('draft', 'true');
          const newWindow = window.open(
            previewUrl,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) {
            newWindow.opener = null;
          }
        },
      }
    );
  }, [
    bloomMenuQuery.data?.menu_url,
    bloomMenuQuery.data?.theme?.bloom,
    fontUpdates,
    toast,
    updateBloomMenu,
  ]);

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      if (headerFont === undefined) {
        setHeaderFont(
          supportedFontFamilies.find(
            ({ family }) =>
              family ===
              bloomMenuQuery.data?.theme?.bloom?.fonts?.heading?.font_family
          )
        );
      }
      if (bodyFont === undefined) {
        setBodyFont(
          supportedFontFamilies.find(
            ({ family }) =>
              family ===
              bloomMenuQuery.data?.theme?.bloom?.fonts?.body?.font_family
          )
        );
      }
    }
  }, [headerFont, bodyFont, bloomMenuQuery, supportedFontFamilies]);

  useEffect(() => {
    loadFont({
      fontFamily: headerFont?.family,
      url: headerFont?.files['regular'],
    });
    loadFont({
      fontFamily: bodyFont?.family,
      url: bodyFont?.files['regular'],
    });
  }, [headerFont, bodyFont]);

  const onRequestClose = useCallback(() => {
    if (isDirty) {
      setDiscard(true);
    } else {
      goBack();
    }
  }, [goBack, isDirty]);

  return (
    <>
      <Flex
        background="grays-ultralight"
        flexDirection="column"
        minHeight="100vh"
      >
        <BorderedFlex
          background="grays-white"
          p={16}
          gap={16}
          flexDirection="row"
          alignItems="center"
        >
          <ChevronLeftIcon onClick={() => onRequestClose()} altText="Go back" />
          <Typography variant="header-bold" branded>
            Fonts
          </Typography>

          <Flex ml="auto" flexDirection="row">
            <Button
              ml="auto"
              mr={16}
              label="Preview"
              type="button"
              variant="secondary"
              onClick={preview}
            />
            <Button
              ml="auto"
              label="Publish"
              type="button"
              variant="primary"
              onClick={publish}
            />
          </Flex>
        </BorderedFlex>

        {bloomMenuQuery.isSuccess && (
          <BorderedFlex
            background="grays-white"
            p={40}
            flexDirection="column"
            gap={24}
          >
            <Flex flexDirection="column" gap={8}>
              <Typography variant="body-bold">Headings</Typography>
              <FontPicker
                activeFontFamily={toLabeledOption(headerFont)}
                defaultFontFamily={{
                  label: 'Euclid Circular B (Default)',
                  value: 'Euclid Circular B',
                }}
                fontFamilies={fontQuery.data ?? []}
                label="heading-font"
                onChange={(font) => {
                  setHeaderFont(font);
                  setIsDirty(true);
                }}
              />
            </Flex>
            <Flex flexDirection="column" gap={8}>
              <Typography variant="body-bold">Paragraph</Typography>
              <FontPicker
                activeFontFamily={toLabeledOption(bodyFont)}
                defaultFontFamily={{
                  label: 'Source Sans Pro (Default)',
                  value: 'Jane Default',
                }}
                fontFamilies={fontQuery.data ?? []}
                label="body-font"
                onChange={(font) => {
                  setBodyFont(font);
                  setIsDirty(true);
                }}
              />
            </Flex>

            <Flex flexDirection="column" gap={8}>
              <Typography variant="body-bold">Preview</Typography>
              <Box p={16} borderRadius="sm" background="grays-ultralight">
                <TextWithFont
                  $fontFamily={headerFont?.family}
                  pb={16}
                  variant="title"
                >
                  Heading
                </TextWithFont>

                <TextWithFont $fontFamily={bodyFont?.family} variant="body">
                  This is how paragraph text will appear
                </TextWithFont>
              </Box>
            </Flex>
          </BorderedFlex>
        )}
      </Flex>
      <ConfirmChangeModal
        open={discard}
        title={
          <Typography variant="header-bold" mb={8}>
            Discard changes?
          </Typography>
        }
        onConfirm={() => goBack()}
        onCancel={() => setDiscard(false)}
        confirmButtonVariant="destructive"
        confirmLabel="Discard"
        subtitle="You have made changes without publishing. If you continue, you will lose your progress."
      />
    </>
  );
};

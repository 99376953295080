import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { PropsWithChildren } from 'react';

import { DragIcon, Flex } from '@jane/shared/reefer';

import type { Direction } from './types';

interface Props {
  direction?: Direction;
  disabled?: boolean;
  id: string;
  showDragHandle: boolean;
}

export const SortableElement = ({
  children,
  direction = 'horizontal',
  disabled,
  id,
  showDragHandle = true,
}: PropsWithChildren<Props>) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ disabled, id });
  const cursor = disabled ? 'default' : isDragging ? 'grabbing' : 'grab';

  const flexDirection = direction === 'horizontal' ? 'column' : 'row';

  const style = {
    cursor,
    ...(direction === 'vertical' && { width: '100%' }),
  };

  const content = (
    <div data-foo-bar style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );

  const dragHandle = showDragHandle ? (
    <div style={{ cursor }}>
      <DragIcon
        aria-label="drag handle"
        color="grays-light"
        size="md"
        {...listeners}
        {...attributes}
      />
    </div>
  ) : null;

  return (
    <li key={id} ref={setNodeRef}>
      <div
        style={{
          touchAction: 'none',
          transform: CSS.Transform.toString(transform),
          transition,
        }}
      >
        <Flex flexDirection={flexDirection} alignItems="center" gap={16}>
          {direction === 'vertical' ? (
            <>
              {dragHandle}
              {content}
            </>
          ) : (
            <>
              {content}
              {dragHandle}
            </>
          )}
        </Flex>
      </div>
    </li>
  );
};

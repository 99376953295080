import { forwardRef } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Drawer as DrawerV1,
  useDrawerContext as useDrawerContextV1,
} from '@jane/shared/reefer-v1';
import {
  Drawer as DrawerV2,
  useDrawerContext as useDrawerContextV2,
} from '@jane/shared/reefer-v2';
import type { DrawerContentProps, DrawerProps } from '@jane/shared/reefer-v2';

const BaseDrawer = (props: DrawerProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DrawerV2 {...props} />;
  }
  return <DrawerV1 {...props} />;
};

const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  (props: DrawerContentProps, ref) => {
    const reeferMigration = useFlag(FLAGS.reeferMigration);

    if (reeferMigration) {
      return <DrawerV2.Content {...props} ref={ref} />;
    }
    return <DrawerV1.Content {...props} ref={ref} />;
  }
);

const DrawerContentDivider = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DrawerV2.ContentDivider {...props} />;
  }
  return <DrawerV1.ContentDivider {...props} />;
};

const DrawerFooter = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DrawerV2.Footer {...props} />;
  }
  return <DrawerV1.Footer {...props} />;
};

const DrawerHeader = (props: any) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <DrawerV2.Header {...props} />;
  }
  return <DrawerV1.Header {...props} />;
};

export const Drawer = Object.assign(BaseDrawer, {
  Content: DrawerContent,
  ContentDivider: DrawerContentDivider,
  Footer: DrawerFooter,
  Header: DrawerHeader,
});

export const useDrawerContext = () => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  const resultV1 = useDrawerContextV1();
  const resultV2 = useDrawerContextV2();

  if (reeferMigration) {
    return resultV2;
  }
  return resultV1;
};

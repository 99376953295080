import { useShouldShowGold } from '@jane/shared/hooks';
import type { PriceId } from '@jane/shared/models';
import {
  Button,
  DismissIcon,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  formatCurrency,
  getMaxCartQuantity,
  getPriceIdBrandSpecial,
} from '@jane/shared/util';

import { JaneGoldTag } from '../../janeGoldTag';
import {
  DisplayMode,
  useProductCardContext,
} from '../../productCardProvider/productCardProvider';
import { QuantitySelector } from '../../quantitySelector/quantitySelector';

interface EditingCardProps {
  decrementQuantity: () => void;
  discountedPrice?: number;
  incrementQuantity: () => void;
  multipleCountDisabled: boolean;
  onAddToCartPressed: () => void;
  originalPrice?: number;
  selectedQuantity: number;
  selectedWeight: PriceId;
  shoppingDisabled: boolean;
  soldByWeight: boolean;
}

const EditingCard = ({
  decrementQuantity,
  discountedPrice,
  incrementQuantity,
  multipleCountDisabled,
  onAddToCartPressed,
  originalPrice,
  selectedQuantity,
  selectedWeight,
  shoppingDisabled,
  soldByWeight,
}: EditingCardProps) => {
  const {
    appMode,
    cartProduct,
    menuProduct,
    setDisplayMode,
    store,
    trackListViewClick,
  } = useProductCardContext();
  const shouldShowGold = useShouldShowGold({ product: menuProduct });
  const price = discountedPrice || originalPrice;
  const taxText = store?.tax_included ? `Taxes included` : '';
  const smallerThanVerticalTablet = useMobileMediaQuery({});

  const selectedWeightInCart = cartProduct?.find(
    (product) => product.price_id === selectedWeight
  );

  const alreadyInCart =
    selectedWeight === selectedWeightInCart?.price_id &&
    selectedQuantity === selectedWeightInCart?.count;

  let buttonText = shoppingDisabled
    ? 'Shopping disabled'
    : cartProduct && selectedQuantity === 0
    ? 'Remove'
    : cartProduct && selectedWeightInCart
    ? 'Update'
    : soldByWeight && !selectedWeight
    ? 'Select a weight'
    : 'Confirm';

  if (smallerThanVerticalTablet) {
    buttonText = `${formatCurrency(price)} - ${buttonText}`;
  }

  const priceIdSpecial =
    shouldShowGold &&
    menuProduct &&
    getPriceIdBrandSpecial({
      menuProduct,
      priceId: selectedWeight,
    });

  return (
    <Flex
      alignItems="center"
      background="grays-ultralight"
      justifyContent="flex-end"
      flexWrap="wrap"
      p={24}
      gap={16}
    >
      <Flex
        onClick={() => {
          setDisplayMode(
            (cartProduct || []).length > 0
              ? DisplayMode.Confirmation
              : DisplayMode.Product
          );
          trackListViewClick && trackListViewClick();
        }}
        justifyContent="center"
        ariaLabel="close"
        justifySelf="flex-start"
        mr="auto"
      >
        <DismissIcon color="text-main" />
      </Flex>
      {!smallerThanVerticalTablet && (
        <Flex height="100%" alignItems="center">
          {priceIdSpecial && (
            <JaneGoldTag label={priceIdSpecial.discount_label || ''} mr={16} />
          )}
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mr={16}
            ml={4}
          >
            {price !== undefined && !isNaN(price) && (
              <Typography branded variant="header-bold">
                {formatCurrency(price)}
              </Typography>
            )}
            <Typography variant="body">{taxText}</Typography>
          </Flex>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        {!multipleCountDisabled && (
          <Flex justifyContent="center" pr={16}>
            <QuantitySelector
              appMode={appMode}
              minQuantity={selectedWeightInCart ? 0 : 1}
              maxQuantity={
                menuProduct && getMaxCartQuantity(menuProduct, selectedWeight)
              }
              multipleCountDisabled={multipleCountDisabled}
              value={selectedQuantity}
              incrementQuantity={incrementQuantity}
              decrementQuantity={decrementQuantity}
            />
          </Flex>
        )}
      </Flex>
      {price !== undefined && !isNaN(price) && (
        <Button
          full={smallerThanVerticalTablet}
          label={buttonText}
          variant="primary"
          onClick={onAddToCartPressed}
          disabled={
            shoppingDisabled ||
            (soldByWeight && !selectedWeight) ||
            alreadyInCart
          }
          ariaLabel={buttonText.toLowerCase()}
        />
      )}
    </Flex>
  );
};

export default EditingCard;

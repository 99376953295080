import type { MenuProduct, Product } from '@jane/shared/models';
import {
  getResponsiveImageAttributes,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { productPhotoMetadata } from '@jane/shared/util';

export const useImage = (product?: Product | MenuProduct) => {
  const isMobile = useMobileMediaQuery({});

  const photoMetadata = product
    ? productPhotoMetadata(product)
    : { photoSource: 'jane', url: '' };

  // TODO: reefer image can generate srcset with `responsive` prop, this can be removed/refactored
  const { srcSet, imageSrc } = getResponsiveImageAttributes({
    src: photoMetadata.url,
    fallbackWidth: 200,
  });

  const imageDimension = isMobile ? '150px' : '200px';

  return {
    srcSet,
    imageSrc,
    imageDimension,
  };
};

import type { DeepReadonly, NoStore, Store } from '@jane/shared/models';
import { Tag } from '@jane/shared/reefer';
import { isNoStore } from '@jane/shared/util';

export const SpecialBadge = ({
  amount,
  isShorten,
  specialAmount,
  store,
}: {
  amount: string;
  complianceLabel?: string;
  isShorten?: boolean;
  specialAmount?: string;
  store: DeepReadonly<Store | NoStore>;
}) => {
  const shouldDisplayComplianceLanguage =
    specialAmount?.toUpperCase() === 'SALE';
  const complianceLanguage =
    !isNoStore(store) &&
    store?.store_compliance_language_settings?.['specials_badge'];

  const label =
    shouldDisplayComplianceLanguage && complianceLanguage
      ? complianceLanguage
      : amount;

  const renderLabel = (label: string) => {
    return isShorten && label.length > 7
      ? `${label.substring(0, 7)}...`
      : label;
  };

  return <Tag label={renderLabel(label)} color="text-info" background="info" />;
};

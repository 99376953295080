import styled from '@emotion/styled';

import type { MarginProperties, PaddingProperties } from '../../styling';
import { spacing } from '../../styling';
import { getColorValue } from '../../utils/componentStyles';
import type { BoxProps } from './box';

interface StyledBoxProps
  extends MarginProperties,
    PaddingProperties,
    Pick<
      BoxProps,
      | 'background'
      | 'border'
      | 'borderRadius'
      | 'borderWidth'
      | 'bottom'
      | 'height'
      | 'htmlFor'
      | 'left'
      | 'maxHeight'
      | 'maxWidth'
      | 'minHeight'
      | 'minWidth'
      | 'onClick'
      | 'position'
      | 'right'
      | 'top'
      | 'width'
      | 'zIndex'
      | 'overflow'
    > {}

export const StyledBox = styled.div<StyledBoxProps>(
  spacing,
  ({
    background,
    border,
    borderRadius,
    borderWidth,
    bottom,
    height,
    left,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    onClick,
    position,
    right,
    theme,
    top,
    width,
    zIndex,
    overflow,
  }) => ({
    backgroundColor: background ? getColorValue(background, theme) : 'none',
    border:
      !border || border === 'none'
        ? 'none'
        : `${borderWidth} solid ${getColorValue(border, theme)}`,
    ...(borderRadius && {
      borderRadius:
        borderRadius === 'sm' ? theme.borderRadius.sm : theme.borderRadius.lg,
    }),
    ...(onClick && {
      ':focus-visible': {
        border: `2px solid ${theme.colors.grays.black}`,
        ...(borderRadius && {
          borderRadius:
            borderRadius === 'sm'
              ? theme.borderRadius.sm
              : theme.borderRadius.lg,
        }),
      },
      cursor: 'pointer',
      outline: 'none',
    }),
    bottom,
    height,
    left,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    position,
    right,
    top,
    width,
    zIndex,
    overflow,
  })
);

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import type { ToastPosition } from './toast.types';

export const toastRightIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

export const toastRightOut = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const toastMiddleTopIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const toastMiddleBottomIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const toastMiddleOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

interface StyledToastWrapperProps {
  position: ToastPosition;
  verticalOffset: number;
}

export const StyledToastWrapper = styled.div<StyledToastWrapperProps>(
  ({ verticalOffset, position }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'fixed',
    alignItems: 'flex-end',
    pointerEvents: 'auto',
    right: 15,
    ...(position.includes('middle') && {
      alignItems: 'center',
      right: '24px',
      left: '24px',
    }),
    ...(position.includes('top') && {
      top: verticalOffset,
    }),
    ...(position.includes('bottom') && {
      bottom: verticalOffset,
    }),
  })
);

export const StyledToastOverlay = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  zIndex: '99999999',
  pointerEvents: 'none',
});

import styled from '@emotion/styled';
import type {
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useArchiveUnpublishedProduct,
  useAutoPublishSettings,
  useBulkPublishProducts,
  useStoreSettings,
  useUpdateBulkProductVisibility,
} from '@jane/business-admin/data-access';
import {
  useModalActionsWithTracking,
  useNavigateAndTrack,
} from '@jane/business-admin/hooks';
import { ProductsTableContext } from '@jane/business-admin/providers';
import {
  EventNames,
  ModalNames,
  NavigationSourceIds,
  businessPaths,
  track,
} from '@jane/business-admin/util';
import { Box, Button, ButtonToggle, Flex, useToast } from '@jane/shared/reefer';

import { ButtonToggleStylesOverrideWrapper } from '../../../../shared/tables/ButtonToggleStylesOverrideWrapper';
import { AutoPublishModal } from '../modal/AutoPublishModal';
import { BulkEditModal } from './BulkEditModal';
import { HiddenProductTable } from './HiddenProductTable';
import { PublishedProductTable } from './PublishedProductTable';
import { UnpublishedProductTable } from './UnpublishedProductTable';

const AutoPublishButtonWrapper = styled.div({
  minWidth: '181px',
});

const BulkEditButtonWrapper = styled.div({
  minWidth: '99px',
});

type ValueOf<T> = T[keyof T];

export const ProductTableType = {
  published: 'Published',
  hidden: 'Hidden',
  unpublished: 'Unpublished',
} as const;

export type ProductTableType = ValueOf<typeof ProductTableType>;

const isProductTableType = (test: unknown): test is ProductTableType => {
  return (
    typeof test === 'string' &&
    (Object.values(ProductTableType) as string[]).includes(test)
  );
};

const HeaderButton = ({
  onClick,
  selectedProducts,
  text,
}: {
  onClick: any;
  selectedProducts: any;
  text: string;
}) => {
  const selectedLength = Object.keys(selectedProducts).length;
  const hasSelected = selectedLength !== 0;
  const label = (buttonText: string) =>
    hasSelected ? `${buttonText} (${selectedLength})` : buttonText;

  return (
    <>
      {text === 'Publish' ? (
        <Button
          mr={16}
          label={label('Archive')}
          variant="secondary"
          disabled={!hasSelected}
          onClick={() => onClick('Archive')}
        />
      ) : null}
      <Button
        label={label(text)}
        variant="secondary"
        disabled={!hasSelected}
        onClick={onClick}
      />
    </>
  );
};

const buttonTextMapping: Record<ProductTableType, string> = {
  [ProductTableType.published]: 'Hide',
  [ProductTableType.hidden]: 'Make visible',
  [ProductTableType.unpublished]: 'Publish',
} as const;

export const ProductTable = () => {
  const { id: storeId = '' } = useParams<'id'>();
  const { canEditProducts } = useContext(ProductsTableContext);
  const navigate = useNavigateAndTrack();

  const { type = 'published' } = useParams<'type'>();
  const { product_id = '' } = useParams<'product_id'>();
  const [selected, setSelected] = useState<ProductTableType>(
    (capitalize(type) as ProductTableType) || ProductTableType.published
  );
  const [selectedProducts, setSelectedProducts] = useState<RowSelectionState>(
    {}
  );
  const [fullSelectedProducts, setFullSelectedProducts] = useState<any>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const hasArchivedFilter = useMemo(() => {
    return columnFilters.some((filter) => {
      return (
        filter.id === 'status' &&
        (filter.value as string[]).includes('archived')
      );
    });
  }, [JSON.stringify(columnFilters)]);

  // When tab is selected, update URL
  useEffect(() => {
    navigate(
      `${businessPaths.storeProducts(storeId, selected.toLowerCase())}${
        product_id ? '/' + product_id : ''
      }`,
      NavigationSourceIds.TableToggle,
      storeId
    );
  }, [selected, storeId]);

  // When page is loaded with tab in URL, update selected tab
  useEffect(() => {
    if (type === 'unpublished') {
      setSelected(ProductTableType.unpublished);
    } else if (type === 'hidden') {
      setSelected(ProductTableType.hidden);
    } else {
      setSelected(ProductTableType.published);
    }
  }, []);

  const toast = useToast();
  const { data: storePayload } = useStoreSettings(storeId);
  const { data: autoPublishSettings } = useAutoPublishSettings(storeId);
  const {
    modalOpen: autoPublishModalOpen,
    openModal: setAutoPublishModalOpen,
    closeModal: setAutoPublishModalClose,
  } = useModalActionsWithTracking(ModalNames.ProductAutoPublish);
  const {
    modalOpen: bulkEditModalOpen,
    openModal: setBulkEditModalOpen,
    closeModal: setBulkEditModalClose,
  } = useModalActionsWithTracking(ModalNames.BulkEditProducts);
  const {
    mutateAsync: bulkUpdateProductVisibility,
    isLoading: bulkUpdateProductVisibilityLoading,
    isSuccess: bulkUpdateProductVisibilitySuccess,
    isError: bulkUpdateProductVisibilityError,
  } = useUpdateBulkProductVisibility(storeId);
  const {
    mutateAsync: bulkPublishProducts,
    isLoading: bulkPublishProductsLoading,
    isSuccess: bulkPublishProductsSuccess,
    isError: bulkPublishProductsError,
  } = useBulkPublishProducts(storeId);
  const {
    mutateAsync: bulkArchiveProducts,
    isLoading: bulkArchiveProductsLoading,
    isSuccess: bulkArchiveProductsSuccess,
    isError: bulkArchiveProductsError,
  } = useArchiveUnpublishedProduct(storeId);

  const trackSubmit = (action: string) => {
    track({
      event: EventNames.BulkEdited,
      objects: Object.keys(selectedProducts),
      action: action,
    });
  };

  const table = (isBulkEditTable = false) =>
    selected === ProductTableType.published ? (
      <PublishedProductTable
        isBulkEditTable={isBulkEditTable}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        setColumnFiltersParent={setColumnFilters}
        columnFiltersParent={columnFilters}
        bulkEditModalOpen={bulkEditModalOpen}
      />
    ) : selected === ProductTableType.hidden ? (
      <HiddenProductTable
        isBulkEditTable={isBulkEditTable}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        setColumnFiltersParent={setColumnFilters}
        columnFiltersParent={columnFilters}
        bulkEditModalOpen={bulkEditModalOpen}
      />
    ) : selected === ProductTableType.unpublished ? (
      <UnpublishedProductTable
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        isBulkEditTable={isBulkEditTable}
        setFullSelectedProducts={setFullSelectedProducts}
        setColumnFiltersParent={setColumnFilters}
        columnFiltersParent={columnFilters}
        bulkEditModalOpen={bulkEditModalOpen}
      />
    ) : null;

  const buttonText = buttonTextMapping[selected];

  const onHeaderButtonClick = (action?: string) => {
    const selections = Object.keys(selectedProducts);

    if (selected === ProductTableType.published) {
      bulkUpdateProductVisibility({
        data: { visible: false },
        productIds: selections,
      });
      trackSubmit('hide');
      setBulkEditModalClose();
      setSelectedProducts({});
    } else if (selected === ProductTableType.hidden) {
      bulkUpdateProductVisibility({
        data: { visible: true },
        productIds: selections,
      });
      trackSubmit('make visible');
      setBulkEditModalClose();
      setSelectedProducts({});
    } else if (selected === ProductTableType.unpublished) {
      if (action === 'Archive') {
        bulkArchiveProducts(selections);
        trackSubmit('archive');
      } else {
        bulkPublishProducts(fullSelectedProducts);
        trackSubmit('publish');
      }
      setBulkEditModalClose();
      setSelectedProducts({});
    }
  };

  useEffect(() => {
    // hide & make visible
    if (bulkUpdateProductVisibilitySuccess) {
      toast.add({
        label: 'Bulk update succeeded!',
        variant: 'success',
      });
    }
    if (bulkUpdateProductVisibilityError) {
      toast.add({
        label: 'Bulk update failed!',
        variant: 'error',
      });
    }
    if (bulkUpdateProductVisibilityLoading) {
      toast.add({
        label: 'Updating visibility...',
        variant: 'success',
      });
    }

    // publish
    if (bulkPublishProductsSuccess) {
      toast.add({
        label: 'Bulk publish succeeded!',
        variant: 'success',
      });
    }
    if (bulkPublishProductsError) {
      toast.add({
        label: 'Bulk publish failed!',
        variant: 'error',
      });
    }
    if (bulkPublishProductsLoading) {
      toast.add({
        label: 'Publishing...',
        variant: 'success',
      });
    }

    // archive
    if (bulkArchiveProductsSuccess) {
      toast.add({
        label: 'Bulk archive succeeded!',
        variant: 'success',
      });
    }
    if (bulkArchiveProductsError) {
      toast.add({
        label: 'Bulk archive failed!',
        variant: 'error',
      });
    }
    if (bulkArchiveProductsLoading) {
      toast.add({
        label: 'Archiving...',
        variant: 'success',
      });
    }
  }, [
    bulkUpdateProductVisibilitySuccess,
    bulkUpdateProductVisibilityError,
    bulkUpdateProductVisibilityLoading,
    bulkPublishProductsLoading,
    bulkPublishProductsSuccess,
    bulkPublishProductsError,
    bulkArchiveProductsLoading,
    bulkArchiveProductsSuccess,
    bulkArchiveProductsError,
  ]);

  return (
    <>
      <Box width="100%" height="60%" ml={-64} position="absolute">
        <Flex justifyContent="space-between" mb={24} mx={64}>
          <Flex gap={16} alignItems="center">
            <ButtonToggleStylesOverrideWrapper>
              <ButtonToggle
                value={selected}
                onChange={(value) => {
                  if (isProductTableType(value)) {
                    setSelected(value);
                  }
                  setSelectedProducts({});
                }}
                full={false}
              >
                <ButtonToggle.Button
                  value={ProductTableType.published}
                  label="Published"
                />
                <ButtonToggle.Button
                  value={ProductTableType.hidden}
                  label="Hidden"
                />
                <ButtonToggle.Button
                  value={ProductTableType.unpublished}
                  label="Unpublished"
                />
              </ButtonToggle>
            </ButtonToggleStylesOverrideWrapper>
          </Flex>
          <Flex gap={16}>
            <BulkEditButtonWrapper>
              <Button
                key="bulk-edit"
                label="Bulk edit"
                variant="secondary"
                onClick={() => setBulkEditModalOpen()}
                disabled={!canEditProducts || hasArchivedFilter}
              />
            </BulkEditButtonWrapper>
            <AutoPublishButtonWrapper>
              <Button
                key="auto-publish"
                label="Manage auto-publish"
                variant="secondary"
                onClick={() => setAutoPublishModalOpen()}
              />
            </AutoPublishButtonWrapper>
          </Flex>
        </Flex>
        {table()}
      </Box>

      {bulkEditModalOpen ? (
        <BulkEditModal
          selectedProducts={selectedProducts}
          open={bulkEditModalOpen}
          headerButton={
            <HeaderButton
              text={buttonText}
              selectedProducts={selectedProducts}
              onClick={onHeaderButtonClick}
            />
          }
          productTable={table(true)}
          onClose={() => {
            setBulkEditModalClose();
            setSelectedProducts({});
          }}
        />
      ) : null}

      {autoPublishModalOpen ? (
        <AutoPublishModal
          open={autoPublishModalOpen}
          onClose={() => setAutoPublishModalClose()}
          productTypes={autoPublishSettings}
          storeName={storePayload?.store?.name}
        />
      ) : null}
    </>
  );
};

import { arrayMove } from '@dnd-kit/sortable';
import escape from 'lodash/escape';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useStoreMenu,
  useStoreSettings,
  useUpdateMenuRowOrder,
} from '@jane/business-admin/data-access';
import type { UpdateMenuRowOrderBody } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type { MenuRow } from '@jane/business-admin/types';
import {
  ErrorReasons,
  EventNames,
  ModalNames,
  SettingNames,
  normalizePath,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { SortableList } from '@jane/shared/components';
import type { SortableItem } from '@jane/shared/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  Flex,
  Form,
  FormValidationError,
  HideEyeIcon,
  List,
  Modal,
  MoreIcon,
  Popover,
  Skeleton,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../ConfirmWrapperWithTracking';
import { getRowLabel } from './landing/utils';

const FORM_ERROR_NAME = 'menu-row-arrange-errors';

const isCustomMenuRow = (row: MenuRow) => {
  return 'id' in row;
};

const ArrangeRow = ({
  rowEnabledChanged,
  moveToBottom,
  moveToTop,
  row,
  rowLabel,
}: {
  moveToBottom: (rank: number) => void;
  moveToTop: (rank: number) => void;
  row: MenuRow;
  rowEnabledChanged: (rank: number, enabled: boolean) => void;
  rowLabel: string;
}) => {
  const handleMoveToTop = (closePopover: () => void) => {
    const currentRank = row.ranking;
    moveToTop(currentRank);

    closePopover();
  };

  const handleMoveToBottom = (closePopover: () => void) => {
    const currentRank = row.ranking;
    moveToBottom(currentRank);

    closePopover();
  };

  const handleToggleVisibility = (enabled: boolean) => {
    rowEnabledChanged(row.ranking, enabled);

    track({
      event: EventNames.ToggleVisibility,
      final_state: enabled ? 'visible' : 'hidden',
      trigger_source_id: 'arrange modal',
      successful: true,
      object: 'menu row',
    });
  };

  return (
    <Flex data-nonworking-example width="100%" justifyContent="space-between">
      <Flex gap={16} alignItems="center">
        <Typography>{rowLabel}</Typography>
        {isCustomMenuRow(row) && !row.enabled ? <HideEyeIcon /> : null}
      </Flex>
      <Flex flexDirection={'row'} gap={16} alignItems="center">
        {isCustomMenuRow(row) && (
          <Form.SwitchField
            data-testid={`enabled-checkbox-${row.id}`}
            defaultChecked={row.enabled}
            labelHidden
            label="visible"
            name={escape(rowLabel)}
            onChange={handleToggleVisibility}
          />
        )}
        <Popover
          target={<Button.Icon icon={<MoreIcon />} />}
          openOn="click"
          alignment={{ horizontal: 'right' }}
          label="more options"
          closeOnTargetClick
        >
          {({ closePopover }: PopoverContextProps) => (
            <Popover.Content>
              <List label="more options items">
                <List.Item onClick={() => handleMoveToTop(closePopover)}>
                  <Typography>Bring to top</Typography>
                </List.Item>
                <List.Item onClick={() => handleMoveToBottom(closePopover)}>
                  <Typography>Bring to bottom</Typography>
                </List.Item>
              </List>
            </Popover.Content>
          )}
        </Popover>
      </Flex>
    </Flex>
  );
};

export const ArrangeModal = ({ closeModal }: { closeModal: () => void }) => {
  const { id = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(id);
  const { data: menuData, isFetched } = useStoreMenu(id);
  const formMethods = useForm();
  const [sortedMenuRows, setSortedMenuRows] = useState<MenuRow[]>([]);
  const originalRowData = useRef<MenuRow[]>();
  const { mutateAsync: updateMenuRowOrder, isSuccess: updateRowOrderSuccess } =
    useUpdateMenuRowOrder(id);
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating menu row order. Please try again.'
  );
  const toast = useToast();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  useEffect(() => {
    if (isFetched && !!menuData) {
      originalRowData.current = [
        // Seems ridiculous, but otherwise array objects get modified downstream and we can't revert to original data when changes are discarded
        ...JSON.parse(JSON.stringify(menuData['menu_rows'])),
      ];
      setSortedMenuRows(originalRowData.current);
    } else {
      setSortedMenuRows([]);
    }
  }, [isFetched, JSON.stringify(menuData)]);

  const onCloseDiscardChanges = () => {
    setSortedMenuRows(originalRowData.current || []);
  };

  const onReorder = (items: SortableItem<MenuRow>[]) => {
    const reformat = items.map((row) => row.item!);
    setSortedMenuRows(reformat);
    track({
      action: 'dragged',
      event: EventNames.ModifiedSetting,
      revert: false,
      setting_name: SettingNames.MenuRowOrder,
      modal_name: ModalNames.ArrangeMenuRows,
    });
  };

  useEffect(() => {
    if (updateRowOrderSuccess) {
      toast.add({
        label: 'Menu row order updated.',
        variant: 'success',
      });
      closeModal();
    }
  }, [updateRowOrderSuccess]);

  const onSubmit = () => {
    const formattedData: UpdateMenuRowOrderBody = [];
    sortedDisplayRows.forEach((row) => {
      formattedData.push({
        id: row.item.id,
        enabled: row.item.enabled,
        product_type: row.item.row_type,
        ranking: row.item.ranking,
      });
    });

    const changedKeys = Object.keys(dirtyFields);
    if (hasChangedOrder) {
      changedKeys.push('menuRowOrder');
    }

    const trackSubmit = (eventProps = {}) => {
      track({
        event: EventNames.EditedStoreMenuConfig,
        action: 'update',
        changed_attributes: changedKeys,
        modal_name: ModalNames.ArrangeMenuRows,
        setting_name: SettingNames.MenuRowOrder,
        successful: true,
        url: normalizePath(pathname, id),
        ...eventProps,
      });
    };

    return catchSubmitErrors({
      submitMethod: async () => {
        await updateMenuRowOrder(formattedData);
        trackSubmit();
      },
      requestData: formattedData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.InvalidParams,
        });
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.ServerError,
        });
        throw new Error('Error updating menu row order. Please try again.');
      },
    });
  };

  const moveToTop = (rank: number) => {
    const newIndex = 0;
    const newOrder = arrayMove(sortedMenuRows, rank, newIndex);
    setSortedMenuRows(newOrder);
    track({
      action: 'bring to top',
      event: EventNames.ModifiedSetting,
      setting_name: SettingNames.MenuRowOrder,
      revert: false,
      modal_name: ModalNames.ArrangeMenuRows,
    });
  };

  const moveToBottom = (rank: number) => {
    const newIndex = sortedMenuRows.length - 1;
    const newOrder = arrayMove(sortedMenuRows, rank, newIndex);
    setSortedMenuRows(newOrder);
    track({
      action: 'bring to bottom',
      event: EventNames.ModifiedSetting,
      setting_name: SettingNames.MenuRowOrder,
      revert: false,
      modal_name: ModalNames.ArrangeMenuRows,
    });
  };

  const changeRowEnabled = (rank: number, enabled: boolean) => {
    setSortedMenuRows((rows) => {
      rows[rank].enabled = enabled;
      return rows;
    });
  };

  const filteredDisplayRows = useMemo(() => {
    let displayRows = sortedMenuRows;
    if (storePayload && !storePayload.magic_row_enabled) {
      displayRows = displayRows.filter((row) => row.row_type !== 'magic_row');
    }

    if (!myHighMenu) {
      displayRows = displayRows.filter(
        (row) => row.row_type !== 'buy_again_row'
      );
    }

    return displayRows;
  }, [myHighMenu, sortedMenuRows, storePayload]);

  const sortedDisplayRows = useMemo(() => {
    return filteredDisplayRows.map((row, index) => {
      row.ranking = index;

      return {
        id: isCustomMenuRow(row) ? `${row.id}` : row.row_type,
        item: row,
      };
    });
  }, [JSON.stringify(filteredDisplayRows)]);

  const hasChangedOrder = useMemo(() => {
    if (isFetched && !!menuData) {
      return (
        JSON.stringify(sortedMenuRows) !== JSON.stringify(menuData['menu_rows'])
      );
    }
    return false;
  }, [isFetched, menuData, sortedMenuRows]);

  return (
    <ConfirmWrapperWithTracking
      open
      setOpen={closeModal}
      variant="standard"
      hasChanges={isDirty || hasChangedOrder}
      modalName={ModalNames.ArrangeMenuRows}
      onCloseDiscardChanges={onCloseDiscardChanges}
    >
      <Form.BaseForm
        name="arrange menu rows form"
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Arrange menu rows"
          subtitle={storeSettingsLoading ? '' : storePayload?.store.name}
          actions={
            <Form.SubmitButton variant="primary" label="Publish" ml={16} />
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          {isFetched && menuData ? (
            <SortableList
              direction="vertical"
              items={sortedDisplayRows}
              onChange={onReorder}
              renderItem={({ item }) => (
                <ArrangeRow
                  moveToTop={moveToTop}
                  row={item!}
                  rowLabel={getRowLabel(
                    item as MenuRow,
                    menuData.filters_and_labels?.custom_labels
                  )}
                  moveToBottom={moveToBottom}
                  rowEnabledChanged={changeRowEnabled}
                />
              )}
            />
          ) : (
            <ArrangeModalSkeleton />
          )}
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

const length10Array = Array(10)
  .fill(null)
  .map((_, index) => index);

function ArrangeModalSkeleton() {
  return (
    <Skeleton direction="column" animate>
      {length10Array.map((i) => (
        <Skeleton.Bone
          key={`skeleton-${i}`}
          mb={24}
          height="48px"
          width="100%"
          borderRadius="sm"
        />
      ))}
    </Skeleton>
  );
}

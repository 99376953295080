import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useEffect, useState } from 'react';

import type {
  MenuProduct,
  PriceId,
  WeightSelectorWeight,
} from '@jane/shared/models';
import {
  enabledWeightsForMenuProduct,
  getOriginalAndDiscountedTotalPrices,
  isSoldByWeight,
  usePDPDetector,
} from '@jane/shared/util';

import {
  DisplayMode,
  useProductCardContext,
} from '../productCardProvider/productCardProvider';
import { firstAvailablePriceIdFromFilters } from './utils/firstAvailablePriceIdFromFilters';
import initialPriceIdForProduct from './utils/initialPriceIdForProduct';

export interface RenderProps {
  decrementQuantity: () => void;
  discountedPrice?: number;
  incrementQuantity: () => void;
  multipleCountDisabled: boolean;
  noSelectedWeightDefault: boolean;
  onAddToCartPressed: () => void;
  onWeightSelected: (selectedWeight: PriceId) => void;
  originalPrice?: number;
  selectedQuantity: number;
  selectedWeight: PriceId;
  shoppingDisabled: boolean;
  soldByQuantityOnly: boolean;
  soldByWeight: boolean;
  weights: WeightSelectorWeight[];
}

interface Props {
  children: (arg: RenderProps) => JSX.Element;
  sortedByPrice?: boolean;
}

const ListViewStateContainer = ({ sortedByPrice, children }: Props) => {
  const {
    appliedWeightFilter,
    cartProduct,
    currentSpecial,
    disableInteraction,
    displayMode,
    listView,
    menuProduct,
    onAddToCart,
    onDeleteFromCart,
    setDisplayMode,
    store,
    trackListViewClick,
    searchState,
  } = useProductCardContext();
  const isPDP = usePDPDetector();

  const [selectedWeight, setSelectedWeight] = useState<PriceId | null>(null);

  const [selectedQuantity, setSelectedQuantity] = useState(
    cartProduct && cartProduct.length > 0 ? cartProduct[0].count : 1
  );
  const [noSelectedWeightDefault, setNoSelectedWeightDefault] = useState(
    !cartProduct
  );

  useEffect(() => {
    if (listView) {
      if (cartProduct && cartProduct.length === 0) {
        if (!selectedWeight) {
          setDisplayMode(DisplayMode.Product);
          setSelectedWeight(null);
        }
      } else {
        setDisplayMode(DisplayMode.Confirmation);
      }
    }
  }, [cartProduct, listView, setDisplayMode]);

  useEffect(() => {
    if (!listView && menuProduct) {
      let initialPriceId = initialPriceIdForProduct({
        menuProduct: menuProduct,
        cartProduct: cartProduct?.length ? cartProduct[0] : undefined,
        special: currentSpecial,
        appliedWeightFilter,
        sortedByPrice,
      });

      const priceKey = `price_${initialPriceId}`;

      if (get(menuProduct, priceKey) === null) {
        initialPriceId = firstAvailablePriceIdFromFilters({
          menuProduct,
          appliedFilters: get(
            searchState?.filters || {},
            'available_weights'
          ) as string[],
          defaultValue: initialPriceId,
        });
      }

      setSelectedWeight(initialPriceId);
      setSelectedQuantity(
        cartProduct && cartProduct.length > 0 ? cartProduct[0].count : 1
      );
    }
  }, [appliedWeightFilter, sortedByPrice, displayMode, menuProduct]);

  const onAddToCartPressed = debounce(
    () => {
      if (disableInteraction) return;

      if (selectedQuantity === 0 && cartProduct && cartProduct.length > 0) {
        onDeleteFromCart &&
          onDeleteFromCart({ itemId: cartProduct[0].id, selectedWeight });
        setSelectedQuantity(1);
      } else {
        onAddToCart &&
          selectedWeight &&
          store &&
          menuProduct &&
          onAddToCart({
            store: store,
            menuProduct: menuProduct,
            price_id: selectedWeight,
            count: selectedQuantity,
            special: currentSpecial,
            location: isPDP ? 'productDetailPage' : 'menu',
          });
      }
      if (!listView) {
        setDisplayMode(DisplayMode.Confirmation);
      }
      listView && trackListViewClick && trackListViewClick();
    },
    500,
    { leading: true, trailing: false }
  );

  const onWeightSelected = (selectedWeight: PriceId) => {
    if (disableInteraction) return;

    const isInCart = (cartProduct || []).find(
      (product) => product.price_id === selectedWeight
    );
    setNoSelectedWeightDefault(false);

    setSelectedWeight(selectedWeight);
    setSelectedQuantity(isInCart ? isInCart.count : 1);
    setDisplayMode(DisplayMode.Edit);
    listView && trackListViewClick && trackListViewClick();
  };

  const incrementQuantity = () => {
    if (disableInteraction) return;

    setSelectedQuantity((prevQuantity) => prevQuantity + 1);
    listView && trackListViewClick && trackListViewClick();
  };

  const decrementQuantity = () => {
    if (disableInteraction) return;

    setSelectedQuantity((prevQuantity) => prevQuantity - 1);
    listView && trackListViewClick && trackListViewClick();
  };

  const multipleCountDisabled =
    menuProduct?.kind === 'flower' && !menuProduct?.allow_multiple_flower_count;

  const weights = enabledWeightsForMenuProduct(
    menuProduct as MenuProduct,
    currentSpecial
  );

  const soldByWeight = menuProduct ? isSoldByWeight(menuProduct.kind) : false;
  const soldByQuantityOnly = !(multipleCountDisabled || soldByWeight);

  const { originalPrice, discountedPrice } =
    getOriginalAndDiscountedTotalPrices({
      menuProduct: menuProduct as MenuProduct,
      count: selectedQuantity,
      priceId: selectedWeight || undefined,
    });

  const shoppingDisabled = store?.hide_prices;

  const propsForChildren = {
    multipleCountDisabled,
    weights,
    soldByWeight,
    soldByQuantityOnly,
    shoppingDisabled,
    originalPrice,
    discountedPrice,
    onAddToCartPressed,
    incrementQuantity,
    decrementQuantity,
    onWeightSelected,
    setDisplayMode,
    noSelectedWeightDefault,
    displayMode,
    selectedWeight,
    selectedQuantity,
  };

  return children(propsForChildren as any);
};

export default ListViewStateContainer;

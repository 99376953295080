import type { IconConfig } from '../../types/icon';

export const Icon: IconConfig = {
  sizes: {
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '48px',
    xxl: '64px',
    xxxl: '96px',
  },
};

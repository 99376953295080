import type { MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import type { MarginProperties } from '../../styling';
import type { ColorKey, ReeferBaseProps, TypographyVariant } from '../../types';
import { fakeButtonProps, handleEnterKey } from '../../utils/handleEnterKey';
import type { TypographyProps } from '../typography/typography';
import { Typography } from '../typography/typography';
import {
  NewTabLabel,
  StyledButton,
  StyledLink,
  StyledRouterLink,
} from './link.styles';

export type LinkVariant = 'underline' | 'minimal';

export interface LinkProps
  extends MarginProperties,
    Pick<TypographyProps, 'branded'>,
    ReeferBaseProps {
  /** aria label */
  ariaLabel?: string;

  /** any valid ReactNode including text */
  children?: React.ReactNode;

  /** color variant */
  color?: ColorKey;

  /** a url: if passed, it renders an anchor tag and will open the URL in a new tab */
  href?: string;

  /** onClick action if needed */
  onClick?: MouseEventHandler;

  /** optional target value, defaults to _blank */
  target?: '_blank' | '_self' | '_parent' | '_top';

  /** a string that is set to the link's title attribute, displayed as a tooltip by the browser */
  title?: string;

  /** an internal route => if passed, it renders a `<Link>` from `react-router-dom` */
  to?: string;

  /** Link Typography style, defaults to inherit */
  typography?: TypographyVariant | 'inherit';

  /** Link style variant, defaults to underline */
  variant?: LinkVariant;
}

/**
 * `Link` components can be rendered as an`anchor` tag that opens in a new tab, as a `span` that accepts a router path or as a `button` to initiate an `onClick` action.
 */
export function Link({
  ariaLabel,
  branded,
  children,
  className,
  color,
  'data-testid': testId,
  href,
  id,
  onClick,
  style,
  target,
  title,
  to,
  typography = 'inherit',
  variant = 'underline',
  ...props
}: LinkProps) {
  const sharedProps = {
    'aria-label': ariaLabel,
    'data-testid': testId,
    onClick,
    title,
    ...props,
  };

  const renderedLabel =
    typography === 'inherit' ? (
      children
    ) : (
      <Typography
        variant={typography}
        branded={branded}
        as="span"
        color="inherit"
      >
        {children}
      </Typography>
    );

  if (to) {
    return (
      <StyledRouterLink
        className={className}
        color={color}
        id={id}
        variant={variant}
        style={style}
        {...props}
      >
        <RouterLink
          target={target}
          className="router-link"
          to={to}
          {...sharedProps}
        >
          {target === '_blank' && (
            <NewTabLabel>Opens in new window</NewTabLabel>
          )}
          {renderedLabel}
        </RouterLink>
      </StyledRouterLink>
    );
  }

  if (href) {
    return (
      <StyledLink
        className={className}
        color={color}
        href={href}
        id={id}
        rel="noopener noreferrer"
        style={style}
        target={target || '_blank'}
        variant={variant}
        {...sharedProps}
      >
        <NewTabLabel>Opens in new window</NewTabLabel>
        {renderedLabel}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      className={className}
      color={color}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      style={style}
      variant={variant}
      {...(onClick && fakeButtonProps)}
      {...sharedProps}
    >
      {renderedLabel}
    </StyledButton>
  );
}

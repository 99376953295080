import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import type {
  BloomFontFamily,
  BloomOperatorConfig,
} from '@jane/business-admin/types';
import { tBloomOperatorConfig } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Nullable } from '@jane/shared/types';

type ColorHash = {
  contrast: string | undefined;
  main: string;
};

type Link = {
  destination?: string;
  label: string;
};

interface MenuLink extends Link {
  links?: Link[];
}

type Theme = Partial<{
  age_gate_message: Nullable<string>;
  colors: Partial<{
    deals: Nullable<ColorHash>;
    header: Nullable<ColorHash>;
    lineage: {
      cbd: Nullable<ColorHash>;
      hybrid: Nullable<ColorHash>;
      indica: Nullable<ColorHash>;
      sativa: Nullable<ColorHash>;
    };
    primary: Nullable<ColorHash>;
    secondary: Nullable<ColorHash>;
    text: {
      primary_inverse: string | undefined;
    };
  }>;
  fonts: Partial<{
    body: Nullable<BloomFontFamily>;
    heading: Nullable<BloomFontFamily>;
  }>;
  hamburger_menu: MenuLink[];
}>;

type BloomUpdateParams = {
  logo?: string;
  theme?: Theme;
  theme_draft?: Theme;
};

const bloomMenusUrl = () => `/managers/bloom_menus`;
const bloomMenuUrl = (menuId: number) => `/managers/bloom_menus/${menuId}`;

export const saveCurrentBloomMenu = (newMenuId: string) => {
  localStorage.setItem('current_bloom_menu', newMenuId);
};
export const getCurrentBloomMenu = () => {
  return Number(localStorage.getItem('current_bloom_menu'));
};

const fetchBloomMenus = async (): Promise<BloomOperatorConfig[]> => {
  const tPayload = t.type({
    bloom_menus: t.array(tBloomOperatorConfig),
  });

  const data = await fetchWithDecode(
    janeApiV2.get(bloomMenusUrl()),
    tPayload,
    bloomMenusUrl()
  );

  return data['bloom_menus'];
};

export const useFetchBloomMenus = <TResult = BloomOperatorConfig[]>({
  select,
}: {
  select?: (configs: BloomOperatorConfig[]) => TResult;
} = {}) => {
  return useQuery({
    queryFn: () => fetchBloomMenus(),
    queryKey: ['bloom_menus'],
    select,
  });
};

export const useFetchCurrentBloomMenu = () => {
  const menuId = getCurrentBloomMenu();

  return useFetchBloomMenus({
    select: (configs) =>
      menuId
        ? configs.find((menu) => menu.id === menuId) ?? configs[0]
        : configs[0],
  });
};

const updateBloomMenu = async (
  menuId?: number,
  updates: BloomUpdateParams = {}
) => {
  if (!menuId) {
    return null;
  }
  return await janeApiV2.patch(bloomMenuUrl(menuId), updates);
};

export const useUpdateBloomMenu = (menuId?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args: BloomUpdateParams = {}) =>
      await updateBloomMenu(menuId, args),
    mutationKey: ['update-bloom-menu', menuId],
    onError: console.error,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['bloom_menus'],
        exact: true,
      });
    },
  });
};

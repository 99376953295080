import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { ReeferThemeProvider as ReeferThemeProviderV1 } from '@jane/shared/reefer-v1';
import type { ReeferThemeProviderProps as ReeferThemeProviderPropsV1 } from '@jane/shared/reefer-v1';
import { ReeferThemeProvider as ReeferThemeProviderV2 } from '@jane/shared/reefer-v2';
import type { ReeferThemeProviderProps } from '@jane/shared/reefer-v2';

export const ReeferThemeProvider = (props: ReeferThemeProviderProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ReeferThemeProviderV2 {...props} />;
  }
  return <ReeferThemeProviderV1 {...(props as ReeferThemeProviderPropsV1)} />;
};

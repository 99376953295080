// https://emailregex.com/
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// https://urlregex.com/
export const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;

export const numberRegex = /^\d*[.?|\d*]\d*$/;

/**
 * Tel validation:
 * - Will be valid for US phone numbers only.
 */
export const telRegex = /^(\+?1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

/**
 * Password validation:
 * - Minimum 10 characters (may include whitespace)
 */
export const passwordRegex = /(?=^.{10,}$).*/;

/**
 * Simple password validation:
 * - Minimum 8 characters (may include whitespace)
 */
export const simplePasswordRegex = /(?=^.{8,}$).*/;

/**
 * Date Regex
 * - mm/dd/yy or mm/dd/yyyy with optional delimiters of -, /, or .
 */
export const dateRegex =
  /^(0[1-9]|1[012])[-/.]?(0[1-9]|[12][0-9]|3[01])[-/.]?(18|19|20)?\d{2}$/;

import { useTheme } from '@emotion/react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { getNodeText, logger } from '../../internal/utils';
import { getComponentStyles } from '../../utils/componentStyles';
import { NewTabLabel } from '../link/link.styles';
import { Loading } from '../loading/loading';
import { Typography } from '../typography/typography';
import {
  IconLabelWrapper,
  LabelWrapper,
  LoadingLabel,
  StyledButton,
  StyledFakeButton,
  StyledLink,
  StyledRouterLink,
} from './button.styles';
import type { ButtonLabelProps, ButtonProps } from './button.types';
import { iconWithProps, loadingColor } from './button.utils';

const ButtonLabel = ({
  alignment = 'center',
  branded = false,
  endIcon,
  label,
  loading = false,
  size = 'default',
  startIcon,
  subLabel,
  variant = 'primary',
}: ButtonLabelProps) => {
  if (size === 'small' && subLabel) {
    logger.warn(
      'Button',
      'Inline button variants should not include a subLabel.'
    );
  }

  const theme = useTheme();
  const buttonStyles = getComponentStyles('Button', theme, variant);
  const sizeSpecificStyles = theme.components.Button.sizes[size];

  const stringLabel = getNodeText(label);

  const accessibleStartIcon = iconWithProps(startIcon, stringLabel);
  const accessibleEndIcon = iconWithProps(endIcon, stringLabel);

  return (
    <LoadingLabel isLoading={loading} subLabel={subLabel}>
      <IconLabelWrapper alignment={alignment}>
        {accessibleStartIcon}
        <LabelWrapper
          startIcon={accessibleStartIcon}
          endIcon={accessibleEndIcon}
        >
          <Typography
            variant={
              sizeSpecificStyles.typographyVariant ??
              buttonStyles.typographyVariant
            }
            branded={branded}
            textAlign={alignment}
            as="div"
            color="inherit"
          >
            {label}
          </Typography>
          {subLabel && (
            <Typography
              variant="mini"
              branded={branded}
              textAlign={alignment}
              as="div"
              mt={-4}
              color="inherit"
            >
              {subLabel}
            </Typography>
          )}
        </LabelWrapper>
        {accessibleEndIcon}
      </IconLabelWrapper>
    </LoadingLabel>
  );
};

/**
 * `Button` components are used to trigger actions throughout the interface.
 * `Button` components can be used to initiate `onClick` actions, for routing, or to link externally.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      alignment = 'center',
      ariaLabel,
      branded = false,
      className,
      'data-testid': testId,
      disableRefDeprecationWarning = false,
      disabled = false,
      endIcon,
      full,
      size = 'default',
      href,
      id,
      label,
      loading = false,
      onClick,
      startIcon,
      style,
      subLabel,
      target,
      to,
      type = 'button',
      variant = 'primary',
      ...props
    }: ButtonProps,
    ref
  ) => {
    const fakeButton = !onClick && type === 'button';

    const stringLabel = getNodeText(label);

    const sharedProps = {
      'aria-label': ariaLabel,
      'data-testid': testId,
      disabled,
      full,
      name: stringLabel,
      variant,
      style,
      size,
      ...props,
    };

    if (ref) {
      if (to || href) {
        throw Error(
          '[Reefer/Button] refs are not recommended and cannot be used in conjunction with "to" or "href"'
        );
      }
      if (!disableRefDeprecationWarning) {
        logger.warn(
          'Button',
          'refs are deprecated and will eventually be removed'
        );
      }
    }
    const buttonLabel = (
      <ButtonLabel
        alignment={alignment}
        branded={branded}
        startIcon={startIcon}
        endIcon={endIcon}
        label={label}
        loading={loading}
        subLabel={subLabel}
        size={size}
        variant={variant}
      />
    );

    if (to) {
      const { full, style, ...linkSharedProps } = sharedProps;
      return (
        <StyledRouterLink
          className={className}
          disabled={disabled}
          full={full}
          id={id}
          isLoading={loading}
          subLabel={subLabel}
          to={to}
          variant={variant}
          size={size}
          style={style}
          {...props}
        >
          <Link
            className="router-link"
            onClick={onClick}
            to={to}
            target={target || '_self'}
            {...linkSharedProps}
          >
            {loading && <Loading color={loadingColor(variant)} />}
            {buttonLabel}
          </Link>
        </StyledRouterLink>
      );
    }

    if (href) {
      return (
        <StyledLink
          className={className}
          href={href}
          id={id}
          target={target || '_blank'}
          onClick={onClick}
          rel="noopener noreferrer"
          isLoading={loading}
          subLabel={subLabel}
          {...sharedProps}
        >
          {loading && <Loading color={loadingColor(variant)} />}
          <NewTabLabel>Opens in new window</NewTabLabel>
          {buttonLabel}
        </StyledLink>
      );
    }

    if (!href && !to && fakeButton) {
      return (
        <StyledFakeButton
          className={className}
          id={id}
          isLoading={loading}
          subLabel={subLabel}
          {...sharedProps}
        >
          {loading && <Loading color={loadingColor(variant)} />}
          {buttonLabel}
        </StyledFakeButton>
      );
    }

    return (
      <StyledButton
        className={className}
        id={id}
        onClick={onClick}
        type={type}
        ref={ref}
        isLoading={loading}
        subLabel={subLabel}
        {...sharedProps}
      >
        {loading && <Loading color={loadingColor(variant)} />}
        {buttonLabel}
      </StyledButton>
    );
  }
);

export { Button };

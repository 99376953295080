import find from 'lodash/find';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';

import type { CustomLabel, MenuRow } from '@jane/business-admin/types';

export const CARD_WIDTH = 206;
export const CARD_HEIGHT = 427;

export const getRowLabel = (
  row: MenuRow,
  customProductTypeLabels?: CustomLabel[]
) => {
  if (row.is_category) {
    return get(
      find(customProductTypeLabels, {
        product_subtype: null,
        product_type: row.row_type,
      }),
      'custom_label',
      upperFirst(row.row_type)
    );
  } else {
    return row.custom_display_name || row.row_type;
  }
};

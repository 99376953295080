import styled from '@emotion/styled';
import startCase from 'lodash/startCase';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { BODY_LINE_HEIGHT, ModalNames } from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Skeleton, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { EditableCard } from '../../../../../EditableCard';
import { TableRowWithBorder } from '../../../../../TableWithBorderSeparator';
import { PaymentModal } from './PaymentModal';
import { SQUARE_OAUTH_REDIRECT_SEARCH_PARAM } from './SquareSettings';

const PaymentTable = styled.table({
  borderCollapse: 'collapse',
  ...spacing({ mb: 32 }),
});

const TableHeaderItem = ({ label }: { label: string }) => (
  <td width="50%">
    <Typography variant="caps" mb={16} ml={16} color="grays-mid">
      {label}
    </Typography>
  </td>
);

const TableContentItem = ({ label }: { label?: string }) => (
  <td width="50%">
    <Typography m={16}>{label}</Typography>
  </td>
);

const LoadingTableColumns = () => (
  <tr>
    <td>
      <Skeleton animate height="fit-content" width="320px">
        <Skeleton.Bone mb={4} height={BODY_LINE_HEIGHT} m={16} />
      </Skeleton>
    </td>
    <td>
      <Skeleton animate height="fit-content" width="320px">
        <Skeleton.Bone mb={4} height={BODY_LINE_HEIGHT} m={16} />
      </Skeleton>
    </td>
  </tr>
);
export const PaymentCard = () => {
  const janePay = useFlag(FLAGS.janePay);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.Payments
  );

  const [searchParams] = useSearchParams();
  const [openAfterSquareOAuthRedirect, setOpenAfterSquareOAuthRedirect] =
    useState<boolean>(false);

  useEffect(() => {
    const squareOAuthRedirect = searchParams.get(
      SQUARE_OAUTH_REDIRECT_SEARCH_PARAM
    );

    if (squareOAuthRedirect !== null) {
      setOpenAfterSquareOAuthRedirect(true);
    }
  }, [searchParams]);

  const closePaymentCardModal = () => {
    setOpenAfterSquareOAuthRedirect(false);
    closeModal();
  };

  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);

  type storePaymentOptionsType = {
    autocapture: string | null;
    name: string;
  };

  const storePaymentOptions: storePaymentOptionsType[] = useMemo(() => {
    const paymentOptions = [];

    if (janePay && storePayload?.jane_pay_integration?.enabled)
      paymentOptions.push({
        name: 'Jane Pay',
        autocapture: storePayload?.jane_pay_integration?.autocapture_on_checkout
          ? 'On checkout'
          : storePayload?.jane_pay_integration?.autocapture_on_completion
          ? 'On completion'
          : null,
      });
    if (storePayload?.store.canpay_enabled)
      paymentOptions.push({ name: 'CanPay', autocapture: null });
    if (storePayload?.aeropay_integration?.enabled)
      paymentOptions.push({
        name: 'AeroPay',
        autocapture: storePayload?.aeropay_integration?.autocapture_on_checkout
          ? 'On checkout'
          : null,
      });
    if (storePayload?.moneris_integration?.enabled)
      paymentOptions.push({
        name: 'Moneris',
        autocapture: storePayload?.moneris_integration?.autocapture_on_checkout
          ? 'On checkout'
          : null,
      });
    if (storePayload?.canpay_v2_integration?.enabled)
      paymentOptions.push({ name: 'CanPay V2', autocapture: null });
    if (storePayload?.canpay_v2remotepay_integration?.enabled)
      paymentOptions.push({ name: 'CanPay V2 RemotePay', autocapture: null });
    if (storePayload?.stronghold_integration?.enabled)
      paymentOptions.push({ name: 'Stronghold', autocapture: null });
    if (storePayload?.payfirma_integration?.enabled)
      paymentOptions.push({ name: 'Payfirma', autocapture: null });
    if (storePayload?.square_integration?.enabled)
      paymentOptions.push({ name: 'Square', autocapture: null });

    if (!paymentOptions.length)
      paymentOptions.push({
        name: 'Payment providers have not been specified for this store.',
        autocapture: null,
      });

    return paymentOptions;
  }, [JSON.stringify(storePayload)]);

  const additionalPaymentOptions = useMemo(() => {
    const keyedOptions: {
      [k: string]: string[];
    } = {};

    storePayload?.payment_options
      .filter((paymentOptions) => paymentOptions.enabled)
      .forEach((paymentOption) => {
        const name = paymentOption.payment_option;
        const value = paymentOption.reservation_mode;

        if (Array.isArray(keyedOptions[name])) {
          keyedOptions[name].push(value);
        } else {
          keyedOptions[name] = [value];
        }
      });

    let options: { modes: string | null; name: string }[] = [];

    if (!Object.keys(keyedOptions).length) {
      options.push({
        name: 'No additional payment options have been specified for this store.',
        modes: null,
      });

      return options;
    }

    options = Object.entries(keyedOptions).map(([name, modes]) => ({
      name,
      modes: modes.join(', '),
    }));

    return options;
  }, [JSON.stringify(storePayload?.payment_options)]);

  return (
    <>
      <EditableCard
        title="Payments"
        onEdit={() => openModal()}
        isLoading={storeSettingsLoading}
        showTextButton={!storeSettingsLoading && !storePaymentOptions}
        textButtonLabel="Enable payment method"
        disabled={!canEditStore}
      >
        <PaymentTable aria-label="payment table">
          <thead>
            <tr>
              <TableHeaderItem label="Payment Providers" />
              {!storeSettingsLoading && <TableHeaderItem label="Autocapture" />}
            </tr>
          </thead>
          <tbody>
            {storeSettingsLoading ? (
              <LoadingTableColumns />
            ) : (
              storePaymentOptions.map((option, optionIndex) => (
                <TableRowWithBorder
                  key={optionIndex}
                  hasBorder={optionIndex < storePaymentOptions.length - 1}
                >
                  <>
                    <TableContentItem label={option.name} />
                    <TableContentItem
                      label={option.autocapture || 'Disabled'}
                    />
                  </>
                </TableRowWithBorder>
              ))
            )}
          </tbody>
        </PaymentTable>
        <PaymentTable aria-label="additional payment table">
          <thead>
            <tr>
              <TableHeaderItem label="Additional Payment Options" />
              {!storeSettingsLoading &&
              additionalPaymentOptions.length === 1 &&
              additionalPaymentOptions[0].modes === null ? null : (
                <TableHeaderItem label="Reservation Modes" />
              )}
            </tr>
          </thead>
          <tbody>
            {storeSettingsLoading ? (
              <LoadingTableColumns />
            ) : (
              additionalPaymentOptions.map((option, optionIndex) => (
                <TableRowWithBorder
                  key={optionIndex}
                  hasBorder={optionIndex < additionalPaymentOptions.length - 1}
                >
                  <>
                    <TableContentItem label={option.name} />
                    <TableContentItem
                      label={
                        option.modes
                          ?.split(',')
                          .map((mode) => startCase(mode))
                          .join('   + ') || ''
                      }
                    />
                  </>
                </TableRowWithBorder>
              ))
            )}
          </tbody>
        </PaymentTable>
      </EditableCard>

      {(modalOpen || openAfterSquareOAuthRedirect) && (
        <PaymentModal
          open
          onClose={() => closePaymentCardModal()}
          storeId={storeId}
          storePayload={storePayload}
        />
      )}
    </>
  );
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Breadcrumbs as BreadcrumbsV1 } from '@jane/shared/reefer-v1';
import { Breadcrumbs as BreadcrumbsV2 } from '@jane/shared/reefer-v2';
import type { BreadcrumbsProps } from '@jane/shared/reefer-v2';

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <BreadcrumbsV2 {...props} />;
  }
  return <BreadcrumbsV1 {...props} />;
};

import styled from '@emotion/styled';

import type { SkeletonProps } from './skeleton';

type SkeletonStyleProps = Omit<SkeletonProps, 'children'>;

export const StyledSkeleton = styled.div<SkeletonStyleProps>(
  ({ direction, height, width }) => ({
    display: 'flex',
    flexDirection: direction,
    height: height || 'fit-content',
    width: width || 'fit-content',
  })
);

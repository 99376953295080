/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Profile24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9C19 12.866 15.866 16 12 16C8.13401 16 5 12.866 5 9ZM15.9946 17.0672C18.961 15.5955 21 12.5359 21 9C21 4.02944 16.9706 0 12 0C7.02944 0 3 4.02944 3 9C3 12.5359 5.03903 15.5955 8.00538 17.0672C5.61121 18.4496 4 21.0367 4 24H6C6 20.6863 8.68629 18 12 18C15.3137 18 18 20.6863 18 24H20C20 21.0367 18.3888 18.4496 15.9946 17.0672ZM10 9C10 9.55229 9.55228 10 9 10C8.44772 10 8 9.55229 8 9C8 8.44771 8.44772 8 9 8C9.55228 8 10 8.44771 10 9ZM15 10C15.5523 10 16 9.55229 16 9C16 8.44771 15.5523 8 15 8C14.4477 8 14 8.44771 14 9C14 9.55229 14.4477 10 15 10Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Profile24,
  sm: Profile24,
  lg: Profile24,
  xl: Profile24,
  xxl: Profile24,
  xxxl: Profile24,
};

export const ProfileIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'profile-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

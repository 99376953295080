import styled from '@emotion/styled';

import type { MarginProperties } from '../../../styling';
import { shadows, spacing } from '../../../styling';
import type { ColorKey } from '../../../types';
import {
  getBorderRadiusValue,
  getColorValue,
  getComponentStyles,
} from '../../../utils/componentStyles';

interface StyledCardGroupProps extends MarginProperties {
  border: ColorKey | 'none';
  borderWidth?: string;
  direction: 'column' | 'row';
  flat: boolean;
  height?: string | number;
  width?: string | number;
}

export const StyledCardGroup = styled.div<StyledCardGroupProps>(
  ({
    border,
    borderWidth,
    direction,
    flat,
    height,
    width,
    theme,
    ...marginProps
  }) => {
    const cardStyles = getComponentStyles('Card', theme);
    const borderRadius = getBorderRadiusValue(cardStyles.borderRadius, theme);
    return {
      border:
        border === 'none'
          ? 'none'
          : `${borderWidth} solid ${getColorValue(border, theme)}`,
      borderRadius,
      boxShadow: flat ? 'none' : shadows.item,
      display: 'inline-flex',
      flexDirection: direction === 'column' ? 'column' : 'row',
      height: height ? height : 'fit-content',
      overflow: 'hidden',
      position: 'relative',
      width: width ? width : 'fit-content',
      ...spacing(marginProps),
    };
  }
);

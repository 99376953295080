/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Gear24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00862 3.00406C7.00862 1.89758 7.9056 1.00061 9.01207 1.00061H13.019C14.1254 1.00061 15.0224 1.89758 15.0224 3.00406V9.95352C16.2722 10.5746 17.3579 11.4775 18.196 12.5794L19.6279 11.7527L19.1271 10.8854C18.8505 10.4063 19.0147 9.79361 19.4938 9.51699C19.9729 9.24037 20.5856 9.40453 20.8622 9.88365L22.8656 13.3537C23.1422 13.8328 22.9781 14.4455 22.499 14.7221C22.0199 14.9987 21.4072 14.8346 21.1306 14.3554L20.6296 13.4877L19.2244 14.299C19.7424 15.4362 20.031 16.7001 20.031 18.0317C20.031 18.9806 19.8841 19.8972 19.6109 20.7588C19.1177 22.3146 17.5965 23.0403 16.2239 23.0403H5.80719C4.4345 23.0403 2.91333 22.3146 2.4201 20.7588C2.14696 19.8972 2 18.9806 2 18.0317C2 14.4902 4.04186 11.4277 7.00862 9.95352V3.00406Z"
      fill="#63B8D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 13.0006C11 13.5529 10.5523 14.0006 10 14.0006C8.34315 14.0006 7 15.3438 7 17.0006C7 17.5529 6.55228 18.0006 6 18.0006C5.44772 18.0006 5 17.5529 5 17.0006C5 14.2392 7.23858 12.0006 10 12.0006C10.5523 12.0006 11 12.4483 11 13.0006Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const Gear48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8017 6.0003C13.8017 3.79133 15.4675 2.00061 17.5224 2.00061H24.9638C27.0187 2.00061 28.6845 3.79133 28.6845 6.0003V19.8742C31.0056 21.1141 33.0219 22.9167 34.5782 25.1165L37.2375 23.466L36.3075 21.7346C35.7938 20.778 36.0987 19.555 36.9885 19.0027C37.8783 18.4505 39.016 18.7782 39.5298 19.7347L43.2504 26.6624C43.7642 27.6189 43.4593 28.842 42.5695 29.3942C41.6797 29.9465 40.542 29.6187 40.0282 28.6622L39.0978 26.9298L36.4881 28.5495C37.4501 30.8198 37.9862 33.343 37.9862 36.0014C37.9862 37.8959 37.7133 39.7258 37.206 41.4458C36.29 44.5519 33.465 46.0006 30.9157 46.0006H11.5705C9.02121 46.0006 6.19618 44.5519 5.28019 41.4458C4.77293 39.7258 4.5 37.8959 4.5 36.0014C4.5 28.9313 8.29202 22.8173 13.8017 19.8742V6.0003Z"
      fill="#63B8D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 25.0006C21.5 26.1052 20.6792 27.0006 19.6667 27.0006C16.6291 27.0006 14.1667 29.6869 14.1667 33.0006C14.1667 34.1052 13.3459 35.0006 12.3333 35.0006C11.3208 35.0006 10.5 34.1052 10.5 33.0006C10.5 27.4778 14.6041 23.0006 19.6667 23.0006C20.6792 23.0006 21.5 23.896 21.5 25.0006Z"
      className="details"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Gear24,
  sm: Gear24,
  xl: Gear48,
  lg: Gear48,
  xxl: Gear48,
  xxxl: Gear48,
};

export const GearIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'gear-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Lgbtq24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8C14.268 8 8 14.268 8 22H10C10 15.3726 15.3726 10 22 10V8Z"
      fill="#4BA279"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2C10.9543 2 2 10.9543 2 22H4C4 12.0589 12.0589 4 22 4V2Z"
      fill="#FF3333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 4C12.0589 4 4 12.0589 4 22H6C6 13.1634 13.1634 6 22 6V4Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6C13.1634 6 6 13.1634 6 22H8C8 14.268 14.268 8 22 8V6Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 10C15.3726 10 10 15.3726 10 22H12C12 16.4772 16.4772 12 22 12V10Z"
      fill="#4485DA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C16.4772 12 12 16.4772 12 22H14C14 17.5817 17.5817 14 22 14V12Z"
      fill="#8E00E6"
    />
  </svg>
);

const SIZE_MAP = {
  md: Lgbtq24,
  sm: Lgbtq24,
  lg: Lgbtq24,
  xl: Lgbtq24,
  xxl: Lgbtq24,
  xxxl: Lgbtq24,
};

export const LgbtqIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'lgbtq-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import difference from 'lodash/difference';

import type { AbbreviatedMenuRow } from '@jane/business-admin/types';

/* Find the difference between the two arrays:
  - Anything missing in the form has been removed
  - Anything new in the form will be an added row
**/
export const parseCustomRows = (
  productRows: AbbreviatedMenuRow[],
  formRows: AbbreviatedMenuRow[]
) => {
  const menuProductRowIds = productRows.map((row) => row.id);
  const formDataRowIds = formRows.map((row) => row.id);
  const removedRows = difference(menuProductRowIds, formDataRowIds).map(
    (id) => ({
      store_custom_menu_row_setting_id: id,
      enabled: false,
    })
  );
  const addedRows = difference(formDataRowIds, menuProductRowIds).map((id) => ({
    store_custom_menu_row_setting_id: id,
    enabled: true,
  }));
  return [...addedRows, ...removedRows];
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UnpublishedProduct } from '@jane/business-admin/types';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Id } from '@jane/shared/models';

import { STORES_URL } from './stores';

type BulkPublishProductParams = {
  brand: string;
  brand_subtype: string;
  category: string;
  cbd_dosage_milligrams: number;
  description: string;
  dosage: string;
  kind: string;
  lineage: string;
  name: string;
  percent_cbd: number;
  percent_thc: number;
  size: string;
  subcategory: string;
  thc_dosage_milligrams: number;
  unmapped_product_id: string;
};

type UpdateUnpublishedProductParams = {
  product: Pick<UnpublishedProduct, 'unmapped_product_id'> &
    Omit<BulkPublishProductParams, 'unmapped_product_id'>;
};

const updateUnpublishedProduct = (
  storeId: Id,
  product: UpdateUnpublishedProductParams
): Promise<null> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/unpublished_products/publish`, {
    ...product,
  });

export const useUpdateUnpublishedProduct = (storeId: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateUnpublishedProductParams) =>
      updateUnpublishedProduct(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['storeUnpublishedProducts', storeId]);
    },
  });
};

const bulkPublishProducts = (
  storeId: Id,
  products: BulkPublishProductParams[]
): Promise<null> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/unpublished_products/bulk_publish`, {
    ...products,
  });

export const useBulkPublishProducts = (storeId: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: BulkPublishProductParams[]) =>
      bulkPublishProducts(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['storeUnpublishedProducts', storeId]);
    },
  });
};

const archiveUnpublishedProduct = (
  storeId: Id,
  unmapped_product_ids: string[]
): Promise<null> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/unpublished_products/archive`, {
    unmapped_product_ids,
  });
export const useArchiveUnpublishedProduct = (storeId: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: string[]) =>
      archiveUnpublishedProduct(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['storeUnpublishedProducts', storeId]);
    },
  });
};

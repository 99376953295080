import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Grid as GridV1 } from '@jane/shared/reefer-v1';
import { Grid as GridV2 } from '@jane/shared/reefer-v2';
import type { GridContainerProps, GridItemProps } from '@jane/shared/reefer-v2';

export const GridContainer = (props: GridContainerProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <GridV2.Container {...props} />;
  }
  return <GridV1.Container {...props} />;
};

export const GridItem = (props: GridItemProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <GridV2.Item {...props} />;
  }
  return <GridV1.Item {...props} />;
};

export const Grid = Object.assign(
  {},
  {
    Container: GridContainer,
    Item: GridItem,
  }
);

import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BoostConfig } from '@jane/business-admin/types';
import { janeApiV2 } from '@jane/shared/data-access';

const STORES_URL = `/business/stores`;

const createStoreBoostConfig = async (
  storeId: string,
  data: BoostConfig
): Promise<null> =>
  await janeApiV2.post<null>(`${STORES_URL}/${storeId}/boost_configs`, data);

export const useCreateStoreBoostConfig = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: BoostConfig) => {
      return createStoreBoostConfig(storeId, { ...data, enabled: true });
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

const updateStoreBoostConfig = async (
  storeId: string,
  configId: string,
  data: BoostConfig
): Promise<null> =>
  await janeApiV2.put<null>(
    `${STORES_URL}/${storeId}/boost_configs/${configId}`,
    data
  );

export const useUpdateStoreBoostConfig = (
  storeId: string,
  configId: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: BoostConfig) => {
      return updateStoreBoostConfig(storeId, configId, data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

const deleteStoreBoostConfig = async (
  storeId: string,
  configId: number
): Promise<null> =>
  await janeApiV2.delete<null>(
    `${STORES_URL}/${storeId}/boost_configs/${configId}`
  );

export const useDeleteStoreBoostConfig = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (configId: number) => {
      return deleteStoreBoostConfig(storeId, configId);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

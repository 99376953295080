import { Controller } from 'react-hook-form';

import {
  Box,
  Button,
  Flex,
  Form,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import type { StoreReviewFormProps } from '.';
import { StarRatingInput } from '../starRatingInput';

const getReviewLabel = (rating?: number) => {
  if (!rating) return 'Leave a review (optional)';
  switch (true) {
    case rating < 4:
      return 'What went wrong? (optional)';
    case rating < 5:
      return 'What can we do better next time? (optional)';
    case rating === 5:
      return 'What made it great? (optional)';
    default:
      return 'Leave a review (optional)';
  }
};

export const ReviewForm = ({
  initialRating,
  onCancel,
}: Pick<StoreReviewFormProps, 'initialRating' | 'onCancel'>) => {
  const { watch, reset } = useFormContext();

  const handleCancel = () => {
    reset();
    onCancel();
  };

  return (
    <Box>
      <Box my={24}>
        <Typography mb={12}>Select a rating</Typography>
        <Controller
          defaultValue={initialRating}
          name="rating"
          render={({ field }) => (
            <StarRatingInput
              size="md"
              onChange={(value) => field.onChange(value)}
              initialRating={initialRating}
            />
          )}
        />
      </Box>

      <Box mb={24}>
        <Form.TextAreaField
          name="review"
          label={getReviewLabel(watch('rating'))}
          disableMobileInputStyling
        />
      </Box>

      <Flex>
        <Button
          onClick={handleCancel}
          label="Cancel"
          variant="secondary"
          full
          mr={8}
        />
        <Form.SubmitButton
          disabled={watch('rating') === 0}
          label="Submit review"
          full
          ml={8}
        />
      </Flex>
    </Box>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const HalfStar24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_13422_59394"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={25}
    >
      <path
        d="M10.7132 0.839562C11.2396 -0.274971 12.7604 -0.274971 13.2868 0.839562L15.8833 6.33764C16.0923 6.78022 16.4964 7.08698 16.9638 7.15795L22.7698 8.03961C23.9468 8.21833 24.4168 9.72983 23.5651 10.5974L19.3638 14.877C19.0256 15.2215 18.8713 15.7179 18.9511 16.2043L19.9429 22.2473C20.1439 23.4723 18.9136 24.4064 17.8609 23.8281L12.6677 20.975C12.2497 20.7453 11.7503 20.7453 11.3323 20.975L6.13914 23.8281C5.08643 24.4064 3.85606 23.4723 4.05711 22.2473L5.04891 16.2043C5.12875 15.7179 4.97442 15.2215 4.63623 14.877L0.434904 10.5974C-0.416759 9.72983 0.0531993 8.21833 1.23017 8.03961L7.03625 7.15795C7.50363 7.08698 7.90766 6.78022 8.11668 6.33764L10.7132 0.839562Z"
        fill="#FFC220"
      />
    </mask>
    <g mask="url(#mask0_13422_59394)">
      <path
        d="M10.7132 0.835899C11.2396 -0.278633 12.7604 -0.278633 13.2868 0.835899L15.8833 6.33397C16.0923 6.77656 16.4964 7.08332 16.9638 7.15429L22.7698 8.03594C23.9468 8.21467 24.4168 9.72617 23.5651 10.5937L19.3638 14.8734C19.0256 15.2179 18.8713 15.7142 18.9511 16.2007L19.9429 22.2436C20.1439 23.4686 18.9136 24.4028 17.8609 23.8244L12.6677 20.9713C12.2497 20.7416 11.7503 20.7416 11.3323 20.9713L6.13914 23.8244C5.08643 24.4028 3.85606 23.4686 4.05711 22.2436L5.04891 16.2007C5.12875 15.7142 4.97442 15.2179 4.63623 14.8734L0.434904 10.5937C-0.416759 9.72617 0.0531993 8.21467 1.23017 8.03594L7.03625 7.15429C7.50363 7.08332 7.90766 6.77656 8.11668 6.33397L10.7132 0.835899Z"
        fill="#FFC220"
      />
      <path
        d="M15.8833 6.33411L13.2867 0.836036C13.0236 0.278762 12.5118 0.000128433 12 0.000136569L12 21C12 21 12.2497 20.7418 12.6677 20.9714L17.8608 23.8246C18.9135 24.4029 20.1439 23.4688 19.9429 22.2438L18.9511 16.2008C18.8712 15.7144 19.0256 15.218 19.3637 14.8735L23.5651 10.5939C24.4167 9.72631 23.9468 8.21481 22.7698 8.03608L16.9637 7.15442C16.4963 7.08345 16.0923 6.77669 15.8833 6.33411Z"
        fill="#DADADA"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: HalfStar24,
  sm: HalfStar24,
  lg: HalfStar24,
  xl: HalfStar24,
  xxl: HalfStar24,
  xxxl: HalfStar24,
};

export const HalfStarIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'half-star-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

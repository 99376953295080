interface BasePayload<E extends EventNames> {
  eventName: E;
}
export enum EventNames {
  AddedProductToCart = 'Added Product to Cart',
  ClickedSeeAll = 'Clicked See All',
  ViewedProduct = 'Viewed Product',
  ViewedProductCard = 'Viewed Product Card',
}

export interface AddedProductToCartPayload
  extends BasePayload<EventNames.AddedProductToCart> {
  productId: number;
  storeId: number | undefined;
}

export interface ProductDetailPagePayload
  extends BasePayload<EventNames.ViewedProduct> {
  productId: number;
  storeId: number | undefined;
}

export interface ClickedSeeAllPayload
  extends BasePayload<EventNames.ClickedSeeAll> {
  category: string;
  storeId: number | undefined;
}

export interface ProductCardImpressionPayload
  extends BasePayload<EventNames.ViewedProductCard> {
  adToken: string | undefined;
  flightId: number | undefined;
  hasJaneGoldDeal: boolean | undefined;
  janeGoldLabel: string | null | undefined;
  kevelToken: string | undefined;
  location: string | undefined;
  model: string | undefined;
  myHighD: string | undefined;
  position:
    | {
        x: number;
        y: number;
      }
    | undefined;
  productId: number;
  storeId: number;
}

type UserSessionEventPayload =
  | ProductDetailPagePayload
  | AddedProductToCartPayload
  | ClickedSeeAllPayload
  | ProductCardImpressionPayload;
export const trackUserSessionEvent = (payload: UserSessionEventPayload) => {
  const { eventName, ...props } = payload;
  userSessionMixpanel.track(eventName, props);
};

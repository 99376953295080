/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Archive24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 22C3.45 22 2.979 21.8043 2.587 21.413C2.19567 21.021 2 20.55 2 20V6.5C2 6.25 2.04167 6.025 2.125 5.825C2.20833 5.625 2.31667 5.43333 2.45 5.25L3.85 2.55C3.98333 2.36667 4.15 2.229 4.35 2.137C4.55 2.04567 4.76667 2 5 2H19C19.2333 2 19.45 2.04567 19.65 2.137C19.85 2.229 20.0167 2.36667 20.15 2.55L21.55 5.25C21.6833 5.43333 21.7917 5.625 21.875 5.825C21.9583 6.025 22 6.25 22 6.5V20C22 20.55 21.8043 21.021 21.413 21.413C21.021 21.8043 20.55 22 20 22H4ZM4.4 6H19.6L18.75 4H5.25L4.4 6ZM4 20H20V8H4V20ZM12 18C12.1333 18 12.2583 17.979 12.375 17.937C12.4917 17.8957 12.6 17.825 12.7 17.725L15.3 15.125C15.4833 14.9417 15.575 14.7083 15.575 14.425C15.575 14.1417 15.4833 13.9083 15.3 13.725C15.1167 13.5417 14.8833 13.45 14.6 13.45C14.3167 13.45 14.0833 13.5417 13.9 13.725L13 14.625V11C13 10.7167 12.9043 10.479 12.713 10.287C12.521 10.0957 12.2833 10 12 10C11.7167 10 11.4793 10.0957 11.288 10.287C11.096 10.479 11 10.7167 11 11V14.625L10.1 13.725C9.91667 13.5417 9.68333 13.45 9.4 13.45C9.11667 13.45 8.88333 13.5417 8.7 13.725C8.51667 13.9083 8.425 14.1417 8.425 14.425C8.425 14.7083 8.51667 14.9417 8.7 15.125L11.3 17.725C11.4 17.825 11.5083 17.8957 11.625 17.937C11.7417 17.979 11.8667 18 12 18Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Archive24,
  sm: Archive24,
  lg: Archive24,
  xl: Archive24,
  xxl: Archive24,
  xxxl: Archive24,
};

export const ArchiveIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'archive-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useTheme } from '@emotion/react';
import find from 'lodash/find';
import has from 'lodash/has';

import { ObjectKeys, ensure } from '../../internal/utils';
import type {
  ColorKey,
  TypographyComponent,
  TypographyVariant,
} from '../../types';
import { baseTypographyVariants } from '../../types/typography';
import type { BoxProps } from '../box';
import { StyledTypography } from './typography.styles';

export interface TypographyProps<T extends string = TypographyVariant>
  extends Omit<
    BoxProps,
    | 'accessibilityComponentName'
    | 'ariaLabel'
    | 'ariaLabelledBy'
    | 'as'
    | 'onClick'
  > {
  /** Component changes the actual HTML tag that is rendered */
  as?: TypographyComponent;

  /**
   * Changes between branded and default (generic) font families. For `title` and `title-bold` variants, `branded` defaults
   * to `true` and you must explicitly pass `branded` as `false` to turn switch to the `generic` font.
   * For all other variants, the default is `false`
   *
   */
  branded?: boolean;

  /** Color changes the text color  */
  color?: ColorKey;

  /** Ellipsizes text after it has wrapped to N number of lines */
  maxLines?: number | 'none';

  /** Add `text-decoration` `line-through` to text */
  strikeThrough?: boolean;

  /** Aligns text */
  textAlign?: 'center' | 'left' | 'right' | 'initial';

  /** Truncates text at given width with an ellipsis **/
  truncateAt?: string;

  /** Variant changes the style of the typography */
  variant?: T;

  /** Set CSS `white-space` attribute, supports any valid `white-space` value. **/
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces'
    | 'inherit';
}

/**
 * Typography component allows you to style your text the way you need, while rendering the appropriate HTML tag
 * for accessibility. This component is **responsive** - font size will change appropriately based on device size.
 * Defaults to `variant='body'`.
 */
export function Typography<T extends string = TypographyVariant>({
  as,
  branded,
  children,
  color,
  htmlFor,
  strikeThrough,
  style,
  textAlign,
  truncateAt,
  variant,
  whiteSpace,
  ...boxProps
}: TypographyProps<T>) {
  const {
    components: {
      Typography: { componentMapping, variants: themeVariants },
    },
  } = useTheme();

  const variants = ObjectKeys(themeVariants) || baseTypographyVariants;
  const defaultVariant = has(variants, 'body') ? 'body' : variants[0];
  const baseVariant = ensure(
    find(variants, (base) => {
      /**
       * @todo
       * monolith (and derived) TS config thinks 'base' & 'baseVariant'
       * are a huge union type including non-strings. This & the check
       * below for 'renderAs' make it happy.
       */
      if (typeof base === 'string' && !base.includes('-')) {
        if (variant) {
          return variant.includes(base);
        } else {
          return defaultVariant.includes(base);
        }
      }
      return false;
    })
  );

  const renderAs =
    as ||
    (typeof baseVariant === 'string' && componentMapping[baseVariant]) ||
    'p';

  return (
    <StyledTypography<T>
      as={renderAs}
      branded={branded}
      color={color}
      htmlFor={htmlFor}
      strikeThrough={strikeThrough}
      style={style}
      textAlign={textAlign}
      truncateAt={truncateAt}
      variant={(variant || defaultVariant) as T}
      whiteSpace={whiteSpace}
      {...boxProps}
    >
      {children}
    </StyledTypography>
  );
}

import styled from '@emotion/styled';

import { Box, Flex } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledPdpContainer = styled(Flex)({
  flexDirection: 'column',
  textAlign: 'center',

  [mediaQueries.tablet('min')]: {
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const StyledPdpInnerContainer = styled(Flex)({
  flexDirection: 'column',

  [mediaQueries.tablet('min')]: {
    flexDirection: 'row',
    width: 'calc(100% - 185px)',
  },
});

export const StyledPdpTitleDescriptionWrapper = styled(Flex)<{
  userHasLinkedBank: boolean | undefined;
}>(({ userHasLinkedBank }) => ({
  alignItems: 'center',
  marginBottom: userHasLinkedBank ? 0 : 16,
  marginTop: 16,

  [mediaQueries.tablet('min')]: {
    alignItems: 'flex-start',
    marginLeft: 16,
    marginBottom: 0,
    marginTop: 0,
  },
}));

export const StyledPdpDescriptionWrapper = styled(Box)({
  maxWidth: '500px',

  [mediaQueries.tablet('min')]: {
    maxWidth: '100%',
  },
});

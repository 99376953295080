import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useMemo } from 'react';

import {
  FilterListCheckbox,
  FilterListCheckboxArrow,
  FilterListItem,
} from '@jane/shared-ecomm/components';
import { CheckboxField, ChevronDownIcon } from '@jane/shared/reefer';

import type { CategoryOption } from './CategorySelectInputModal';
import { CategorySelectInputModalContext } from './CategorySelectInputModalProvider';
import { findKeysWithSubstring, separateKeys } from './util/rules';

interface CategoryCheckboxProps {
  category: CategoryOption;
  hasSubItems?: boolean;
  isSubItem?: boolean;
  parents: Array<string>;
}

export const CategoryCheckbox = ({
  category,
  hasSubItems,
  isSubItem,
  parents,
}: CategoryCheckboxProps) => {
  const {
    selectionMap,
    setSelectionMap,
    setHideSubItems,
    hideSubItems,
    onDirty,
    indeterminates,
    setIndeterminates,
  } = useContext(CategorySelectInputModalContext);
  const { label, value } = category;

  const mapKey = useMemo(
    () =>
      isEmpty(parents)
        ? category.value
        : `${parents.join(':')}:${category.value}`,
    [category]
  );

  const isChecked = selectionMap.get(mapKey);

  const getChildrenString = () => {
    const keysWithSubString = findKeysWithSubstring(
      parents.join(':'),
      selectionMap.keys()
    );
    const { childrenString } = separateKeys(keysWithSubString, mapKey);

    return childrenString;
  };

  useEffect(() => {
    if (!selectionMap.has(mapKey)) {
      selectionMap.set(mapKey, false);
    }
    const childrenMapResults = getChildrenString().map((childKey) => {
      return selectionMap.get(childKey);
    });

    if (childrenMapResults.length > 0) {
      // if every child/no child is selected it is not indeterminate
      const allChildrenSelected = childrenMapResults.every((child) => child);

      if (allChildrenSelected) {
        selectionMap.set(mapKey, true);
      }
    }

    // initial setup of selectionMap
    setSelectionMap(new Map(selectionMap));
  }, [mapKey]);

  const updateHideSubItems = (key: string, value: boolean) => {
    setHideSubItems((prevHiddenSubItems: Record<string, boolean>) => {
      return {
        ...prevHiddenSubItems,
        [key]: value,
      };
    });
  };

  const updateChildren = (nestedValue: boolean) => {
    const childrenMapResults = getChildrenString();

    if (childrenMapResults.length > 0) {
      // if every child/no child is selected it is not indeterminate
      childrenMapResults.forEach((childKey) => {
        selectionMap.set(childKey, nestedValue);
      });
    }
  };

  /*
    if mapKey = foo:bar:baz, this will find all possible permutations of foo and bar
    Ex: foo, foo:bar, bar
  */
  const updateParents = (nestedValue: boolean) => {
    const allParentStringPermutations: string[] = [];

    parents.forEach((parentStr) => {
      const newEntries: string[] = [];
      allParentStringPermutations.forEach((existingStr) => {
        const concatenatedStr = `${existingStr}:${parentStr}`;
        if (
          !allParentStringPermutations.includes(concatenatedStr) &&
          !newEntries.includes(concatenatedStr)
        ) {
          newEntries.push(concatenatedStr);
        }
      });
      if (!allParentStringPermutations.includes(parentStr)) {
        newEntries.push(parentStr);
      }
      allParentStringPermutations.push(...newEntries);
    });

    allParentStringPermutations.forEach((parentKey: string) => {
      if (selectionMap.has(parentKey)) {
        selectionMap.set(parentKey, nestedValue);
      }
    });
  };

  const isIndeterminate = () => {
    const childrenMapResults = getChildrenString().map((childKey) => {
      return selectionMap.get(childKey);
    });

    if (!(childrenMapResults.length > 0)) {
      return false;
    }

    // if every child/no child is selected it is not indeterminate
    const allChildrenSelected = childrenMapResults.every((child) => child);
    const noChildrenSelected = childrenMapResults.every((child) => !child);
    const anyChildrenSelected = childrenMapResults.some((child) => child);

    if (noChildrenSelected || allChildrenSelected) {
      if (indeterminates.has(mapKey)) {
        indeterminates.delete(mapKey);
      }
      return false;
    } else if (anyChildrenSelected) {
      indeterminates.add(mapKey);
      return true;
    }

    return false;
  };

  return (
    <FilterListItem>
      <FilterListCheckbox subItem={isSubItem} hasSubItems={hasSubItems}>
        <CheckboxField
          checked={isChecked}
          name={mapKey}
          label={label}
          onChange={(checked) => {
            onDirty && onDirty(true);
            // this is needed to set current checkbox value
            selectionMap.set(mapKey, checked);

            updateChildren(checked);
            updateParents(checked);

            setSelectionMap(new Map(selectionMap));
            setIndeterminates(new Set(indeterminates));
          }}
          indeterminate={isIndeterminate()}
        />
        {hasSubItems && (
          <FilterListCheckboxArrow>
            <ChevronDownIcon
              onClick={() =>
                updateHideSubItems(
                  value,
                  !hideSubItems[category.value as keyof typeof hideSubItems]
                )
              }
              rotate={
                hideSubItems[category.value as keyof typeof hideSubItems]
                  ? 'down'
                  : 'up'
              }
              altText={`Click to ${
                hideSubItems[category.value as keyof typeof hideSubItems]
                  ? 'hide'
                  : 'show'
              } subcategory filters`}
            />
          </FilterListCheckboxArrow>
        )}
      </FilterListCheckbox>
    </FilterListItem>
  );
};

import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { useEffect, useRef, useState } from 'react';

import { marginCSSVars } from '@jane/shared/reefer-v2';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import '../../../reefer-v2/src/styles/theme.css';
import styles from './table.module.css';
import type { TableProps } from './table.types';

export function Table({
  children,
  className,
  'data-testid': testId,
  freezeLastColumn = false,
  freezeFirstColumn = false,
  minWidth = 'auto',
  maxWidth = 'none',
  scrollable = true,
  style,
  ...marginProps
}: TableProps) {
  const [firstCellCheckbox, setFirstCellCheckbox] = useState(false);
  const tableRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    // check if the first cell is a checkbox cell to determine if the frozenFirstColumn is actually the second column
    if (freezeFirstColumn) {
      const headers = tableRef?.current?.querySelectorAll(
        'th'
      ) as NodeListOf<HTMLTableCellElement>;

      if (Array.from(headers)[0].getAttribute('data-id') === 'checkbox-cell') {
        setFirstCellCheckbox(true);
      }
    }
  }, [freezeFirstColumn]);

  return (
    <table
      className={clsx(className, styles.table, {
        [styles.table__scrollable]:
          scrollable || freezeFirstColumn || freezeLastColumn,
        [styles.table__freezeColumn__first]: freezeFirstColumn,
        [styles.table__freezeColumn__last]: freezeLastColumn,
        [styles.table__freezeColumn__first__firstCellCheckbox]:
          freezeFirstColumn && firstCellCheckbox,
      })}
      data-testid={testId}
      style={
        {
          ...marginCSSVars(marginProps),
          '--table-max-width': maxWidth,
          '--table-min-width': minWidth,
          ...style,
        } as CSSProperties
      }
      ref={tableRef}
    >
      {children}
    </table>
  );
}

import { useEffect } from 'react';

export const useWindowListener = <Event extends keyof WindowEventMap>(
  event: Event,
  listener: (event: WindowEventMap[Event]) => void,
  shouldListen = true
) => {
  useEffect(() => {
    if (shouldListen) {
      window.addEventListener(event, listener, false);
    }

    return () => window.removeEventListener(event, listener);
  }, [event, listener, shouldListen]);
};

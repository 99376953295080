import styled from '@emotion/styled';

import type { BoostConfig } from '@jane/business-admin/types';
import { config } from '@jane/shared/config';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { FlexProps } from '@jane/shared/reefer';
import {
  Button,
  Card,
  CopyIcon,
  EditIcon,
  Flex,
  TrashIcon,
  Typography,
} from '@jane/shared/reefer';
import { copyToClipboard } from '@jane/shared/util';

import { CardSection } from '../../../../../CardSection';

export interface BoostConfigItemProps extends FlexProps {
  boostConfig: BoostConfig;
  index: number;
  onDelete?: (configId: number) => void;
  onEdit?: (boostConfig: BoostConfig) => void;
  paddingBottom?: boolean;
  paddingTop?: boolean;
}

export interface BoostConfigItemInfoProps {
  infoLabel: string;
  infoText: string;
}

interface CardContentWithPaddingStyle {
  paddingBottom: boolean;
  paddingTop: boolean;
}

const CardContentWithPadding = styled(
  Card.Content
)<CardContentWithPaddingStyle>(({ paddingTop, paddingBottom }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: paddingBottom ? 40 : 8,
  paddingTop: paddingTop ? 40 : 12,
}));

const getWordpressProxyUrl = (id: number) => {
  return `${config.framelessEmbedApiUrl}/v1/client/frameless_embeds/${id}`;
};

export const BoostConfigItemInfo = ({
  infoText,
  infoLabel,
}: BoostConfigItemInfoProps) => {
  return (
    <Flex pt={24} alignItems="center">
      <CardSection label={infoLabel} width="100%">
        <Typography truncateAt="auto">{infoText}</Typography>
      </CardSection>
      <Button.Icon
        label="copy"
        type="button"
        variant="tertiary"
        icon={<CopyIcon size="lg" />}
        onClick={() => copyToClipboard(infoText)}
      />
    </Flex>
  );
};

export const BoostConfigItem = ({
  boostConfig,
  index,
  onEdit,
  onDelete,
  paddingTop = true,
  paddingBottom = true,
  ...flexProps
}: BoostConfigItemProps) => {
  const hasBoostSubdomainAccess = useFlag(FLAGS.scUseNewBoostTables);

  const boostWordpressConfigInfos = [
    {
      label: 'STORE URL',
      text: boostConfig.partner_menu_url || '',
    },
    {
      label: 'PROXY URL',
      text: getWordpressProxyUrl(boostConfig.legacy_id || boostConfig.id),
    },
    {
      label: 'SITEMAP URL',
      text: boostConfig.sitemap_url || '',
    },
  ];

  const boostSubdomainConfigInfos = [
    {
      label: 'STORE URL',
      text: boostConfig.partner_menu_url || '',
    },
    {
      label: 'SITEMAP URL',
      text: boostConfig.sitemap_url || '',
    },
  ];

  return (
    <CardContentWithPadding
      border="grays-light"
      borderWidth="1px 0 0 0"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Flex alignItems="center" {...flexProps}>
        <Typography width="100%" variant="body-bold">
          {`Configuration #${index + 1}`}
        </Typography>
        <Button.Icon
          label="delete"
          type="button"
          icon={<TrashIcon size="lg" />}
          variant="tertiary"
          mr={16}
          onClick={async () => onDelete && onDelete(boostConfig.id)}
        />
        <Button.Icon
          label="edit"
          type="button"
          icon={<EditIcon size="lg" />}
          variant="secondary"
          onClick={() => onEdit && onEdit(boostConfig)}
        />
      </Flex>
      {hasBoostSubdomainAccess
        ? boostConfig.integration_method === 'wordpress'
          ? boostWordpressConfigInfos.map((item, index) => (
              <BoostConfigItemInfo
                key={index}
                infoLabel={item.label}
                infoText={item.text}
              />
            ))
          : boostSubdomainConfigInfos.map((item, index) => (
              <BoostConfigItemInfo
                key={index}
                infoLabel={item.label}
                infoText={item.text}
              />
            ))
        : boostWordpressConfigInfos.map((item, index) => (
            <BoostConfigItemInfo
              key={index}
              infoLabel={item.label}
              infoText={item.text}
            />
          ))}
    </CardContentWithPadding>
  );
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Card as CardV1 } from '@jane/shared/reefer-v1';
import { Card as CardV2 } from '@jane/shared/reefer-v2';
import type {
  CardActionProps,
  CardContentProps,
  CardGroupProps,
  CardImageProps,
  CardProps,
} from '@jane/shared/reefer-v2';

const BaseCard = (props: CardProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CardV2 {...props} />;
  }
  return <CardV1 {...props} />;
};

const CardAction = (props: CardActionProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CardV2.Action {...props} />;
  }
  return <CardV1.Action {...props} />;
};

const CardGroup = (props: CardGroupProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CardV2.Group {...props} />;
  }
  return <CardV1.Group {...props} />;
};

const CardContent = (props: CardContentProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CardV2.Content {...props} />;
  }
  return <CardV1.Content {...props} />;
};

const CardImage = (props: CardImageProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <CardV2.Image {...props} />;
  }
  return <CardV1.Image {...props} />;
};

export const Card = Object.assign(BaseCard, {
  Action: CardAction,
  Content: CardContent,
  Group: CardGroup,
  Image: CardImage,
});

import {
  BLACK,
  DARK,
  EMBER,
  EMERALD,
  GOLD,
  GRAPE,
  HOVER,
  LIGHT,
  MID,
  NEGATIVE,
  PACIFIC,
  PALM,
  POSITIVE,
  PURPLE,
  SEAFOAM,
  SUNSET,
  TANGERINE,
  TRANSPARENT_BLACK_FIFTY,
  TRANSPARENT_BLACK_FIVE,
  TRANSPARENT_BLACK_TEN,
  TRANSPARENT_BLACK_TWENTY,
  TRANSPARENT_WHITE_TEN,
  ULTRALIGHT,
  WHITE,
  effects,
} from '../styling';

export const colors = {
  brand: {
    ember: {
      dark: effects.darken(EMBER, 0.2),
      light: effects.lighten(EMBER, 0.8),
      main: EMBER,
    },
    gold: {
      dark: effects.darken(GOLD, 0.2),
      light: effects.lighten(GOLD, 0.8),
      main: GOLD,
    },
    grape: {
      dark: effects.darken(GRAPE, 0.2),
      light: effects.lighten(GRAPE, 0.8),
      main: GRAPE,
    },
    purple: {
      dark: effects.darken(PURPLE, 0.2),
      light: effects.lighten(PURPLE, 0.8),
      main: PURPLE,
    },
    sunset: {
      dark: effects.darken(SUNSET, 0.2),
      light: effects.lighten(SUNSET, 0.8),
      main: SUNSET,
    },
    tangerine: {
      dark: effects.darken(TANGERINE, 0.2),
      light: effects.lighten(TANGERINE, 0.8),
      main: TANGERINE,
    },
  },
  grays: {
    black: BLACK,
    dark: DARK,
    hover: HOVER,
    light: LIGHT,
    mid: MID,
    ultralight: ULTRALIGHT,
    white: WHITE,
  },
  system: {
    negative: {
      dark: effects.darken(NEGATIVE, 0.2),
      light: effects.lighten(NEGATIVE, 0.8),
      main: NEGATIVE,
    },
    positive: {
      dark: effects.darken(POSITIVE, 0.2),
      light: effects.lighten(POSITIVE, 0.8),
      main: POSITIVE,
    },
  },
  tertiary: {
    emerald: {
      dark: effects.darken(EMERALD, 0.2),
      light: effects.lighten(EMERALD, 0.8),
      main: EMERALD,
    },
    pacific: {
      dark: effects.darken(PACIFIC, 0.2),
      light: effects.lighten(PACIFIC, 0.8),
      main: PACIFIC,
    },
    palm: {
      dark: effects.darken(PALM, 0.2),
      light: effects.lighten(PALM, 0.8),
      main: PALM,
    },
    seafoam: {
      dark: effects.darken(SEAFOAM, 0.2),
      light: effects.lighten(SEAFOAM, 0.8),
      main: SEAFOAM,
    },
  },
  transparent: {
    black: {
      five: TRANSPARENT_BLACK_FIVE,
      ten: TRANSPARENT_BLACK_TEN,
      twenty: TRANSPARENT_BLACK_TWENTY,
      fifty: TRANSPARENT_BLACK_FIFTY,
    },
    white: {
      ten: TRANSPARENT_WHITE_TEN,
    },
  },
};

import { createContext, useContext, useMemo, useState } from 'react';
import type { ReactNode } from 'react';

import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import type {
  AppMode,
  Brand,
  DeepReadonly,
  Id,
  MenuProduct,
  PriceId,
  Product,
  ReservationCartProduct,
  RouteAppMode,
  Store,
  StoreSpecial,
  UserLocation,
} from '@jane/shared/models';
import type {
  Breadcrumb,
  EcommPaths,
  MenuProduct as ZMenuProduct,
} from '@jane/shared/types';
import type { PartnerHostedConfig } from '@jane/shared/util';

export enum DisplayMode {
  Confirmation = 'confirmation',
  Edit = 'edit',
  Product = 'product',
}

export type AddData = {
  count: number;
  location: 'menu' | 'productDetailPage' | 'pendingCart' | 'marketplaceProduct';
  menuProduct: MenuProduct | ZMenuProduct;
  price_id: PriceId;
  special?: StoreSpecial;
  store: DeepReadonly<Store>;
};
export type DeleteData = {
  itemId: number;
  selectedWeight: PriceId | null;
};

export interface ProductCardContextProps {
  appMode: AppMode;
  appliedWeightFilter: PriceId | '';
  brand?: Brand;
  breadcrumbs?: Breadcrumb[];
  carouselView?: boolean;
  cartIsOpen: boolean;
  cartProduct?: ReservationCartProduct[];
  currentSpecial?: StoreSpecial;
  customerId: number | null;
  disableInteraction?: boolean;
  displayMode: DisplayMode;
  fromAllSpecials?: boolean | null;
  fromSpecialId?: Id | null;
  hideActions?: boolean;
  janeGoldLabel?: string | null;
  listView?: boolean;
  maxWeightOption?: number | null;
  menuProduct?: MenuProduct;
  onAddToCart?: (addData: AddData) => void;
  onCloseCart?: () => void;
  onDeleteFromCart?: (deleteData: DeleteData) => void;
  onSetBreadcrumbs?: (searchState: JaneSearchState<AlgoliaProduct>) => void;
  pdpPath?: EcommPaths['menuProduct'];
  product?: Product;
  routeAppMode?: RouteAppMode;
  routePartnerHostedConfig: PartnerHostedConfig;
  searchState: JaneSearchState<AlgoliaProduct>;
  setDisplayMode: (arg: DisplayMode) => void;
  showOnlyWeights?: string[] | null;
  store?: DeepReadonly<Store>;
  trackListViewClick?: () => void;
  userLocation: UserLocation | null;
}

const defaultContext: ProductCardContextProps = {
  appMode: 'default',
  appliedWeightFilter: '',
  carouselView: false,
  cartIsOpen: false,
  cartProduct: [],
  customerId: null,
  displayMode: DisplayMode.Product,
  listView: false,
  routePartnerHostedConfig: { isPartnerHosted: false },
  searchState: {},
  setDisplayMode: () => {
    /**   */
  },
  userLocation: null,
};

export const ProductCardContext =
  createContext<ProductCardContextProps>(defaultContext);

export const ProductCardProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: Partial<ProductCardContextProps>;
}) => {
  const [displayMode, setDisplayMode] = useState(DisplayMode.Product);

  const providerValue = useMemo(
    () => ({
      ...defaultContext,
      ...value,
      displayMode: value.displayMode || displayMode,
      setDisplayMode,
    }),
    [displayMode, value, setDisplayMode]
  );

  return (
    <ProductCardContext.Provider value={providerValue}>
      {children}
    </ProductCardContext.Provider>
  );
};

export const useProductCardContext = () => {
  const context = useContext(ProductCardContext);

  if (context === undefined) {
    throw new Error(
      'useProductCardContext must be used within a ProductCardProvider'
    );
  }

  return context;
};

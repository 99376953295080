/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const NotHigh24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#DADADA" />
    <circle cx={8} cy={9} r={2} fill="black" fillOpacity={0.5} />
    <circle cx={16} cy={9} r={2} fill="black" fillOpacity={0.5} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 17H6V15H18V17Z"
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: NotHigh24,
  sm: NotHigh24,
  lg: NotHigh24,
  xl: NotHigh24,
  xxl: NotHigh24,
  xxxl: NotHigh24,
};

export const NotHighIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'not-high-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

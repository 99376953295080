import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useFetchProductDetails,
  useStoreSettings,
  useUpdateMenuProduct,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useHasPermissions,
  useModalActionsWithTracking,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import {
  EventNames,
  MODAL_CARD_WIDTH,
  ModalNames,
  NavigationSourceIds,
  businessPaths,
  getNewImages,
  normalizePath,
  orderImages,
  parseBannedPhraseError,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import {
  Button,
  Card,
  ExternalIcon,
  Flex,
  Form,
  FormValidationError,
  Modal,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import { SwitchWithBorder } from '../../../../SwitchWithBorder';
import { DetailsCard, LoadingDetailsCard } from './DetailsCard';
import { ImagesCard } from './ImagesCard';
import { LabTestResultsCard } from './LabTestResultsCard';
import { MenuRowsCard } from './MenuRowsCard';
import { VariantsCard } from './VariantsCard';
import { parseCustomRows } from './utils';

interface ProductModalProps {
  menuProduct?: MenuProductForProductsTable;
  tableType: 'published' | 'hidden' | 'unpublished' | string;
}

const FORM_ERROR_NAME = 'menu-product-edit-error';

export const ProductModal = ({ tableType }: ProductModalProps) => {
  const { type = 'published' } = useParams<'type'>();
  const { storeId } = useContext(StoreDetailsContext);
  const { product_id = '' } = useParams<'product_id'>();
  const toast = useToast();

  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading: menuProductLoading } = useFetchProductDetails(
    storeId,
    { menuProductId: product_id }
  );
  const menuProduct = useMemo(() => data?.product, [data]);
  // Navigate and show error if there is no menu product when loading is finished
  useEffect(() => {
    if (!menuProductLoading && !menuProduct) {
      toast.add({
        label: 'Product could not be found.',
        variant: 'error',
      });
      navigate(businessPaths.storeProducts(storeId, type));
      setOpened(false);
    }
  }, [menuProductLoading, menuProduct]);

  useEffect(() => {
    if (product_id) {
      setOpened(true);
    }
  }, [product_id]);

  const { pathname } = useLocation();
  const handleClose = () => {
    track({
      event: EventNames.ClosedModal,
      modal_name: ModalNames.UpdateMenuProduct,
      url: normalizePath(pathname, storeId),
    });
    navigate(businessPaths.storeProducts(storeId, type));
    setOpened(false);
  };

  const userCanEditProducts: any = useHasPermissions([Permission.EditProducts]);

  const {
    modalOpen: menuRowModalOpen,
    openModal: setMenuRowModalOpen,
    closeModal: setMenuRowModalClose,
  } = useModalActionsWithTracking(ModalNames.UpdateMenuProduct);

  const isCreateMode = !menuProduct;
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error updating menu product. Please try again.`
  );

  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);

  const formMethods = useForm({
    defaultValues: {
      name: menuProduct?.name || '',
      brand: menuProduct?.brand || '',
      brand_subtype: menuProduct?.brand_subtype || '',
      category: menuProduct?.category || '',
      subcategory: menuProduct?.subcategory || '',
      description: menuProduct?.description || '',
      lineage: menuProduct?.lineage || '',
      visible: menuProduct?.visible,
      percent_cbd: menuProduct?.percent_cbd || '',
      percent_cbda: menuProduct?.percent_cbda || '',
      percent_thc: menuProduct?.percent_thc || '',
      percent_thca: menuProduct?.percent_thca || '',
      photos: menuProduct?.photos?.map((photo) => photo?.urls.original) || [],
      effects_attributes: menuProduct?.effects || [],
      flavors_attributes: menuProduct?.flavors || [],
      unavailable_prices_attributes: menuProduct?.unavailable_prices || [],
      images_radio_field: menuProduct?.photos?.length
        ? 'custom'
        : 'jane-catalog',
    },
    values: {
      ...menuProduct,
      percent_thc: menuProduct?.percent_thc || '',
      percent_cbd: menuProduct?.percent_cbd || '',
      percent_thca: menuProduct?.percent_thca || '',
      percent_cbda: menuProduct?.percent_cbda || '',
      effects_attributes: menuProduct?.effects,
      flavors_attributes: menuProduct?.flavors,
      unavailable_prices_attributes: menuProduct?.unavailable_prices,
      photos: menuProduct?.photos?.map((photo) => photo?.urls.original) || [],
      images_radio_field: menuProduct?.photos?.length
        ? 'custom'
        : 'jane-catalog',
    },
  });

  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const {
    mutateAsync: updateMenuProduct,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateMenuProduct(storeId as string, menuProduct?.id as number);

  const isEditable = useMemo(() => userCanEditProducts, [userCanEditProducts]);

  const onSubmit = (formData: any) => {
    const requestData: any = {
      attributes: {
        ...omit(formData, ['photos']),
        custom_rows: parseCustomRows(
          menuProduct?.custom_rows || [],
          formData.custom_rows
        ),
        lineage: formData.lineage === '' ? 'none' : formData.lineage,
      },
      photos: [],
      photos_order: {},
      deleted_photos: [],
    };

    requestData.photos = getNewImages({
      photos: formData.photos,
      menuProduct,
    });

    // Update image positions that are currently assigned to menu product
    const existingImagesOrder = orderImages({
      photos: formData.photos,
      menuProduct,
    });

    existingImagesOrder?.map(
      (image: any) => (requestData['photos_order'][image.id] = image.position)
    );

    // if Jane default images is selected, remove all custom photos
    if (formData['images_radio_field'] !== 'custom') {
      requestData['deleted_photos'] = menuProduct?.photos;
    } else {
      // Find photos that have been removed by comparing form state and existing photos on menu product
      requestData['deleted_photos'] = differenceWith(
        menuProduct?.photos,
        formData.photos,
        (a, b) => isEqual(a.urls.original, b)
      );
    }

    const submitMethod = () => {
      track({
        action: 'edit',
        event: EventNames.EditedStoreProduct,
        changed_attributes: Object.keys(dirtyFields),
        modal_name: ModalNames.EditProduct,
        type: 'edited store level product',
        url: normalizePath(pathname, storeId),
        successful: isSuccess,
      });

      return updateMenuProduct(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      callback: (validationErrors) => {
        const bannedPhraseErrors = parseBannedPhraseError(
          validationErrors.errors
        );
        if (bannedPhraseErrors) {
          throw new FormValidationError(FORM_ERROR_NAME, bannedPhraseErrors);
        } else {
          throw new Error(validationErrors.errors);
        }
      },
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  useMutationStatusToasts({
    isMutating: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: 'Menu product successfully updated',
    errorMessage: 'Error updating menu product. Please try again.',
  });

  const isSuccess = useMemo(
    () => !isCreateMode && isUpdateSuccess,
    [isUpdateSuccess]
  );

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  const isLoading = storeSettingsLoading || menuProductLoading;

  return (
    <ConfirmWrapperWithTracking
      open={opened}
      setOpen={handleClose}
      hasChanges={isDirty}
      variant="full-screen"
      background="grays-ultralight"
      modalName={ModalNames['UpdateMenuProduct']}
    >
      <Form.BaseForm
        name="menu product settings"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={menuProduct?.name || ''}
          subtitle={storePayload?.store.name}
          actions={
            <>
              <SwitchWithBorder
                label="Visible"
                name="visible"
                defaultChecked={menuProduct?.visible}
                onChange={(visible: any) => {
                  track({
                    event: EventNames.ToggleVisibility,
                    final_state: visible ? 'visible' : 'hidden',
                    object: 'menu product',
                    successful: true,
                    trigger_source_id: 'row workspace',
                  });
                }}
                disabled={isLoading}
                mr={8}
              />
              {isEditable && (
                <>
                  {menuProduct?.pos_product_link && (
                    <Button
                      variant="secondary"
                      label="View in POS"
                      data-testid="menu-product-pos-view"
                      mr={8}
                      disabled={isLoading}
                      endIcon={<ExternalIcon color="inherit" />}
                      href={menuProduct.pos_product_link}
                      onClick={() =>
                        track({
                          event: EventNames.OpenExternalLink,
                          from_url: normalizePath(
                            pathname,
                            storeId,
                            menuProduct.id.toString()
                          ),
                          to_url: 'POS Product URL',
                          trigger_source_id:
                            NavigationSourceIds.ProductDetailModal,
                        })
                      }
                    />
                  )}
                  <Form.SubmitButton
                    disabled={isLoading}
                    label="Publish"
                    variant="primary"
                    data-testid="menu-product-edit-save"
                  />
                </>
              )}
            </>
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex alignItems="center" flexDirection="column">
            <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
              <Card.Content>
                <Flex p={24} flexDirection="column">
                  {isLoading || !menuProduct ? (
                    <LoadingDetailsCard />
                  ) : (
                    <DetailsCard
                      attributes={{
                        amount: menuProduct.amount,
                        description: menuProduct.description,
                        productBrandSubtype: menuProduct.brand_subtype,
                        defaultProductName: menuProduct.default_product_name,
                        dosage: menuProduct.dosage,
                        brand: menuProduct.brand,
                        lineage: menuProduct.lineage,
                      }}
                      tableType={tableType}
                      menuProduct={menuProduct}
                      isEditable={isEditable}
                      customLabels={storePayload?.custom_labels}
                      isSelfPublished={menuProduct.is_self_published}
                    />
                  )}
                </Flex>
              </Card.Content>
            </Card>
            <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
              <Card.Content>
                <Flex p={24} flexDirection="column">
                  <LabTestResultsCard
                    menuProduct={menuProduct}
                    isLoading={isLoading}
                    tableType={tableType}
                  />
                </Flex>
              </Card.Content>
            </Card>
            <Flex p={24} flexDirection="column">
              <ImagesCard isLoading={isLoading} menuProduct={menuProduct} />
            </Flex>

            <VariantsCard isLoading={isLoading} menuProduct={menuProduct} />
            <MenuRowsCard
              store={storePayload}
              rows={menuProduct?.custom_rows}
              isLoading={isLoading}
              menuRowModalOpen={menuRowModalOpen}
              setMenuRowModalOpen={setMenuRowModalOpen}
              setMenuRowModalClose={setMenuRowModalClose}
            />
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Sparkle32 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.2332 12.5812C19.4508 11.8063 20.5492 11.8063 20.7668 12.5812L21.477 15.1108C22.0776 17.2504 23.7496 18.9224 25.8892 19.523L28.4188 20.2332C29.1937 20.4508 29.1937 21.5492 28.4188 21.7668L25.8892 22.477C23.7496 23.0776 22.0776 24.7496 21.477 26.8892L20.7668 29.4188C20.5492 30.1937 19.4508 30.1937 19.2332 29.4188L18.523 26.8892C17.9224 24.7496 16.2504 23.0776 14.1108 22.477L11.5812 21.7668C10.8063 21.5492 10.8063 20.4508 11.5812 20.2332L14.1108 19.523C16.2504 18.9224 17.9224 17.2504 18.523 15.1108L19.2332 12.5812Z"
      fill="#0E0E0E"
    />
    <path
      d="M7.4888 5.38744C7.63383 4.87085 8.36617 4.87085 8.5112 5.38744L8.98465 7.0739C9.38509 8.50024 10.4998 9.61491 11.9261 10.0153L13.6126 10.4888C14.1291 10.6338 14.1291 11.3662 13.6126 11.5112L11.9261 11.9847C10.4998 12.3851 9.38509 13.4998 8.98465 14.9261L8.5112 16.6126C8.36617 17.1291 7.63383 17.1291 7.4888 16.6126L7.01535 14.9261C6.61491 13.4998 5.50024 12.3851 4.0739 11.9847L2.38744 11.5112C1.87085 11.3662 1.87085 10.6338 2.38744 10.4888L4.0739 10.0153C5.50024 9.61491 6.61491 8.50024 7.01535 7.0739L7.4888 5.38744Z"
      fill="#0E0E0E"
    />
    <path
      d="M24.574 2.32287C24.6949 1.89238 25.3051 1.89238 25.426 2.32287L25.8205 3.72825C26.1542 4.91687 27.0831 5.84576 28.2718 6.17945L29.6771 6.574C30.1076 6.69486 30.1076 7.30514 29.6771 7.426L28.2718 7.82055C27.0831 8.15424 26.1542 9.08313 25.8205 10.2718L25.426 11.6771C25.3051 12.1076 24.6949 12.1076 24.574 11.6771L24.1795 10.2718C23.8458 9.08313 22.9169 8.15424 21.7282 7.82055L20.3229 7.426C19.8924 7.30514 19.8924 6.69486 20.3229 6.574L21.7282 6.17945C22.9169 5.84576 23.8458 4.91687 24.1795 3.72825L24.574 2.32287Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  lg: Sparkle32,
  md: Sparkle32,
  sm: Sparkle32,
  xl: Sparkle32,
  xxl: Sparkle32,
  xxxl: Sparkle32,
};

export const SparkleIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sparkle-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

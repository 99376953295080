import type { ColumnDef, Row, SortingState } from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import upperCase from 'lodash/upperCase';
import { useContext } from 'react';

import { ProductsTableContext } from '@jane/business-admin/providers';
import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import { EventNames, track } from '@jane/business-admin/util';
import { Flex, Image, SwitchField, Typography } from '@jane/shared/reefer';
import type {
  BooleanFieldProps,
  CheckboxFieldProps,
} from '@jane/shared/reefer';

import { DefaultBrandLogo, ImageWrapper } from './DefaultBrandLogo';
import { ProductCell } from './ProductCell';
import { SortableHeader } from './UnpublishedColumns';
import { WeightCell } from './WeightCell';

const preventPropagationToRowOnClick: Required<CheckboxFieldProps>['onClick'] =
  (event) => {
    event.stopPropagation();
  };

const BrandCell = ({ row }: { row: Row<any> }) => (
  <Flex flexDirection="row" gap={4}>
    {row.original.brand_logo ? (
      <ImageWrapper visible={row.original.visible}>
        <Image
          borderRadius="100%"
          height="24px"
          width="24px"
          src={row.original.brand_logo}
          altText={`${row.original.brand} logo`}
        />
      </ImageWrapper>
    ) : (
      <DefaultBrandLogo
        brand={row.original.brand}
        visible={row.original.visible}
      />
    )}
    <Typography color={row.original.visible ? 'grays-black' : 'grays-light'}>
      {row.original.brand}
    </Typography>
  </Flex>
);

const MainCell = ({
  content,
  visible,
}: {
  content: string;
  visible: boolean;
}) => (
  <Typography color={visible ? 'grays-black' : 'grays-light'}>
    {content}
  </Typography>
);

const ActionCell = ({
  isDisabled = false,
  row,
  updateProductVisibility,
}: {
  isDisabled?: boolean;
  row: Row<any>;
  updateProductVisibility: (productId: string, visible: boolean) => void;
}) => {
  const { canEditProducts } = useContext(ProductsTableContext);

  const handleClick: Required<BooleanFieldProps>['onClick'] = (event) => {
    preventPropagationToRowOnClick(event);
    track({
      event: EventNames.ToggleVisibility,
      final_state: !row.original.visible === true ? 'visible' : 'hidden',
      object: row.original.id,
      trigger_source_id: `${
        row.original.visible ? 'published' : 'hidden'
      } product table visibility toggle`,
      successful: true,
    });
    updateProductVisibility(`${row.original.id}`, !row.original.visible);
    row.original.visible = !row.original.visible;
  };

  return (
    <Flex>
      <SwitchField
        defaultChecked={row.original.visible === true}
        name={`${row.original.id}-visible`}
        label={row.original.visible === true ? 'Visible' : 'Hidden'}
        onClick={handleClick}
        mr={8}
        disabled={!canEditProducts || isDisabled}
      />
    </Flex>
  );
};

export const buildPublishedAndHiddenColumns = ({
  isBulkEditTable = false,
  isFetched,
  sorting,
  totalRowCount,
  updateProductVisibility,
}: {
  isBulkEditTable?: boolean;
  isFetched: boolean;
  sorting: SortingState;
  totalRowCount: number;
  updateProductVisibility: (productId: string, visible: boolean) => void;
}): ColumnDef<MenuProductForProductsTable>[] => [
  {
    accessorKey: 'name',
    enableColumnFilter: true,
    id: 'name',
    header: ({ column }) => {
      return (
        <SortableHeader
          sorting={sorting}
          column={column}
          headerText={!isFetched ? 'xxx Products' : `${totalRowCount} Products`}
        />
      );
    },
    cell: ({ row }) => {
      const handleClick: Required<CheckboxFieldProps>['onClick'] = (event) => {
        if ((event.target as HTMLInputElement).checked) {
          track({
            event: EventNames.SelectedObject,
            objects: row.original.name,
            trigger_source_id: 'checkbox',
          });
        } else {
          track({
            event: EventNames.DeselectedObject,
            objects: row.original.name,
            trigger_source_id: 'checkbox',
          });
        }

        preventPropagationToRowOnClick(event);
        row.toggleSelected();
      };

      return (
        <ProductCell
          row={row}
          handleClick={handleClick}
          isBulkEditTable={isBulkEditTable}
        />
      );
    },
  },
  {
    accessorKey: 'visible',
    id: 'visible',
    header: () => (
      <Typography variant="caps" color="grays-mid">
        action
      </Typography>
    ),
    cell: ({ row }) => (
      <ActionCell
        isDisabled={isBulkEditTable}
        row={row}
        updateProductVisibility={updateProductVisibility}
      />
    ),
    enableColumnFilter: false,
  },
  {
    header: ({ column }) => (
      <SortableHeader sorting={sorting} column={column} headerText="brand" />
    ),
    cell: ({ row }) => {
      return row.original.brand ? <BrandCell row={row} /> : null;
    },
    accessorKey: 'brand',
    id: 'brand',
    footer: 'Brand',
  },
  {
    header: ({ column }) => (
      <SortableHeader sorting={sorting} column={column} headerText="category" />
    ),
    cell: ({ row }) => {
      return row.original.category ? (
        <MainCell
          visible={row.original.visible}
          content={capitalize(row.original.category)}
        />
      ) : null;
    },
    accessorKey: 'category',
    id: 'category',
    footer: 'Category',
  },
  {
    header: ({ column }) => (
      <SortableHeader
        sorting={sorting}
        column={column}
        headerText="subcategory"
      />
    ),
    cell: ({ row }) => (
      <MainCell
        visible={row.original.visible}
        content={row.original.subcategory || ''}
      />
    ),
    accessorKey: 'subcategory',
    id: 'subcategory',
    footer: 'Subcategory',
  },
  {
    header: ({ column }) => (
      <SortableHeader sorting={sorting} column={column} headerText="lineage" />
    ),
    cell: ({ row }) => {
      return row.original.lineage ? (
        <MainCell
          visible={row.original.visible}
          content={
            row.original.lineage === 'cbd'
              ? upperCase(row.original.lineage)
              : capitalize(row.original.lineage)
          }
        />
      ) : null;
    },
    accessorKey: 'lineage',
    id: 'lineage',
    footer: 'Lineage',
  },
  {
    header: ({ column }) => (
      <SortableHeader
        sorting={sorting}
        column={column}
        headerText="content source"
      />
    ),
    cell: ({ row }) => (
      <MainCell
        visible={row.original.visible}
        content={row.original.content_type}
      />
    ),
    accessorKey: 'content_type',
    id: 'content_type',
    footer: 'Content',
  },
  {
    header: () => (
      <Typography variant="caps" color="grays-mid">
        pos sku
      </Typography>
    ),
    cell: ({ row }) => {
      const variants = row.original.pos_sku;
      const extraLabel =
        variants.length > 1 ? ` (+${variants.length - 1})` : '';
      const firstVariant = get(variants, [0, 1], 'N/A');
      return (
        <MainCell
          visible={row.original.visible}
          content={firstVariant ? `${firstVariant}${extraLabel}` : 'N/A'}
        />
      );
    },
    enableColumnFilter: false,
    accessorKey: 'pos_sku',
    id: 'pos_sku',
  },
  {
    header: () => <Typography variant="caps">each</Typography>,
    cell: ({ row }) => {
      return row.original.price_each ? (
        <WeightCell
          columnPrice={row.original.price_each}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="each"
        />
      ) : null;
    },
    accessorKey: 'price_each',
    enableColumnFilter: false,
    id: 'price_each',
  },
  {
    header: () => <Typography variant="caps">0.5g</Typography>,
    cell: ({ row }) => {
      return row.original.price_half_gram ? (
        <WeightCell
          columnPrice={row.original.price_half_gram}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="half_gram"
        />
      ) : null;
    },
    accessorKey: 'price_half_gram',
    enableColumnFilter: false,
    id: 'price_half_gram',
  },
  {
    header: () => <Typography variant="caps">1g</Typography>,
    cell: ({ row }) => {
      return row.original.price_gram ? (
        <WeightCell
          columnPrice={row.original.price_gram}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="gram"
        />
      ) : null;
    },
    accessorKey: 'price_gram',
    enableColumnFilter: false,
    id: 'price_gram',
  },
  {
    header: () => <Typography variant="caps">2g</Typography>,
    cell: ({ row }) => {
      return row.original.price_two_gram ? (
        <WeightCell
          columnPrice={row.original.price_two_gram}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="two_gram"
        />
      ) : null;
    },
    accessorKey: 'price_two_gram',
    enableColumnFilter: false,
    id: 'price_two_gram',
  },
  {
    header: () => <Typography variant="caps">3.5g</Typography>,
    cell: ({ row }) => {
      return row.original.price_eighth_ounce ? (
        <WeightCell
          columnPrice={row.original.price_eighth_ounce}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="eighth_ounce"
        />
      ) : null;
    },
    accessorKey: 'price_eighth_ounce',
    enableColumnFilter: false,
    id: 'price_eighth_ounce',
  },
  {
    header: () => <Typography variant="caps">7g</Typography>,
    cell: ({ row }) => {
      return row.original.price_quarter_ounce ? (
        <WeightCell
          columnPrice={row.original.price_quarter_ounce}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="quarter_ounce"
        />
      ) : null;
    },
    accessorKey: 'price_quarter_ounce',
    enableColumnFilter: false,
    id: 'price_quarter_ounce',
  },
  {
    header: () => <Typography variant="caps">14g</Typography>,
    cell: ({ row }) => {
      return row.original.price_half_ounce ? (
        <WeightCell
          columnPrice={row.original.price_half_ounce}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="half_ounce"
        />
      ) : null;
    },
    accessorKey: 'price_half_ounce',
    enableColumnFilter: false,
    id: 'price_half_ounce',
  },
  {
    header: () => <Typography variant="caps">28g</Typography>,
    cell: ({ row }) => {
      return row.original.price_ounce ? (
        <WeightCell
          columnPrice={row.original.price_ounce}
          unavailablePrices={row.original.unavailable_prices}
          visible={row.original.visible}
          includesPrice="ounce"
        />
      ) : null;
    },
    accessorKey: 'price_ounce',
    enableColumnFilter: false,
    id: 'price_ounce',
  },
  {
    accessorFn: (row) => {
      const arr: string[] = [];
      row.custom_rows.map((custom_row) =>
        arr.push(custom_row.custom_display_name)
      );
      return arr.join(', ');
    },
    header: () => null,
    cell: () => null,
    id: 'custom_rows',
    footer: 'Custom Rows',
  },
];

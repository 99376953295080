import { Link } from '../../link';
import { Loading } from '../../loading';
import { Typography } from '../../typography';
import { StyledLoadingWrapper, StyledSearchList } from '../typeAhead.styles';
import type { TypeAheadResultsProps } from '../typeAhead.types';
import { useTypeAheadContext } from '../typeAheadContext';
import { ResultListItem } from './resultListItem';

export function TypeAheadResults({
  className,
  'data-testid': testId,
  hideDeselect,
  id,
  popoverFooter,
  preResultsText,
  style,
}: TypeAheadResultsProps) {
  const {
    listAriaLabel,
    optionType,
    onSelection,
    loading,
    options,
    width,
    query,
  } = useTypeAheadContext();

  return loading ? (
    <StyledLoadingWrapper>
      <Loading />
    </StyledLoadingWrapper>
  ) : options && options.length > 0 ? (
    <StyledSearchList
      aria-label={listAriaLabel}
      className={className}
      data-testid={testId}
      id={id}
      style={style}
      width={width}
    >
      {options?.map((option, index) => (
        <ResultListItem
          key={index}
          label={option.label}
          subLabel={option.subLabel}
        />
      ))}
      {optionType === 'checkbox' && !hideDeselect && (
        <Link mx={24} my={8} onClick={() => onSelection && onSelection([])}>
          Deselect all
        </Link>
      )}
      {popoverFooter && popoverFooter}
    </StyledSearchList>
  ) : (
    <StyledSearchList
      aria-label={listAriaLabel}
      className={className}
      id={id}
      width={width}
    >
      <Typography mx={24} color="text-light">
        {!query?.length && preResultsText ? preResultsText : 'No Results'}
      </Typography>
      {popoverFooter && popoverFooter}
    </StyledSearchList>
  );
}

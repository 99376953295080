import { useEffect } from 'react';

import {
  useCreateCheckoutAgreementReport,
  useManager,
} from '@jane/business-admin/data-access';
import type { CreateCheckoutAgreementReportParams } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type { StoreV2 } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Banner,
  Flex,
  Form,
  FormValidationError,
  InfoIcon,
  Modal,
  useToast,
} from '@jane/shared/reefer';
import { format as formatDate } from '@jane/shared/util';

const FORM_ERROR_NAME = 'checkout-opt-in-report-error';

export const OptInReportModal = ({
  agreementId,
  store = null,
  open = false,
  onClose,
}: {
  agreementId: number | null;
  onClose: () => void;
  open: boolean;
  store: StoreV2 | null;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error sending opt-in report. Please try again.'
  );

  const toast = useToast();
  const storeId = store?.id?.toString() ?? '';
  const { mutateAsync: createReport, isSuccess } =
    useCreateCheckoutAgreementReport(storeId, agreementId?.toString());
  const { data: manager } = useManager();
  const today = formatDate(new Date(), 'YYYY-MM-DD');
  const managerEmail = manager?.manager?.email ?? `your account's address`;

  const onSubmit = (data: CreateCheckoutAgreementReportParams) =>
    catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.CheckoutAgreementReportModal,
          changed_attributes: [],
        });
        return createReport(data);
      },
      requestData: data,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });

  useEffect(() => {
    if (isSuccess) {
      toast.add({
        label: `Opt-in agreement report will be emailed to ${managerEmail}`,
        variant: 'success',
      });
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={open}
      onRequestClose={onClose}
      variant="standard-dialogue"
      appId="root"
    >
      <Form name="Send opt-in report" onSubmit={onSubmit}>
        <Modal.Header
          title="Send opt-in report"
          subtitle={store?.name}
          actions={<Form.SubmitButton label="Generate and send" />}
        />

        <Modal.Content>
          <Flex flexDirection="row" gap={24} mb={24}>
            <Flex width="50%">
              <Form.DateTimeField
                required
                label="Start date"
                name="start_date"
                width="100%"
                max={today}
              />
            </Flex>
            <Flex width="50%">
              <Form.DateTimeField
                required
                label="End date"
                name="end_date"
                width="100%"
                max={today}
              />
            </Flex>
          </Flex>
          <Banner
            full
            icon={<InfoIcon />}
            label={`Report will be sent to ${managerEmail}`}
          />
        </Modal.Content>
      </Form>
    </Modal>
  );
};

import styled from '@emotion/styled';
import type { MouseEventHandler } from 'react';

import type { MarginProperties } from '../../styling';
import { spacing } from '../../styling';
import type { ColorKey } from '../../types';
import { getColorValue } from '../../utils/componentStyles';
import type { IconSize, Rotate } from './icon.types';

export const transform = (rotate?: Rotate) => {
  switch (rotate) {
    case 'left':
      return 'rotate(-90deg)';
    case 'right':
      return 'rotate(90deg)';
    case 'down':
      return 'rotate(-180deg)';
    default:
      return 'none';
  }
};

export interface StyledIconProps {
  color?: ColorKey | 'inherit';
  rotate?: Rotate;
}

interface StyledContainerProps extends MarginProperties {
  color?: ColorKey | 'inherit';
  height?: string | number;
  onClick?: MouseEventHandler<HTMLElement>;
  rotate?: Rotate;
  size: IconSize;
  width?: string | number;
}

export const StyledContainer = styled.div<StyledContainerProps>(
  ({ color, size, onClick, rotate, height, width, theme, ...marginProps }) => ({
    alignItems: 'center',
    cursor: onClick ? 'pointer' : 'inherit',
    display: 'flex',
    height: height ? height : theme.components.Icon.sizes[size],
    justifyContent: 'center',
    outline: 'none',
    position: 'relative',
    textAlign: 'center',
    width: width ? width : theme.components.Icon.sizes[size],
    ...spacing(marginProps),
    ':focus-visible': {
      '&:after': {
        backgroundColor: 'transparent',
        border: `2px solid ${
          color === 'inherit' || color === undefined
            ? theme.colors.primary.main
            : getColorValue(color, theme)
        }`,
        borderRadius: theme.borderRadius.lg,
        bottom: '-4px',
        content: `''`,
        display: 'inherit',
        left: '-4px',
        position: 'absolute',
        right: '-4px',
        top: '-4px',
      },
    },
    svg: {
      display: 'inline-block',
      flexShrink: 0,
      height: '100%',
      overflow: 'hidden',
      textAlign: 'center',
      transform: rotate ? transform(rotate) : 'none',
      userSelect: 'none',
      width: '100%',
      ...(color && {
        '& path:not(.details), rect.inherit': {
          fill:
            color === 'inherit' ? 'currentColor' : getColorValue(color, theme),
        },
        '& rect, circle': {
          stroke:
            color === 'inherit' ? 'currentColor' : getColorValue(color, theme),
        },
      }),
    },
  })
);

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { ConditionalWrapper as ConditionalWrapperV1 } from '@jane/shared/reefer-v1';
import { ConditionalWrapper as ConditionalWrapperV2 } from '@jane/shared/reefer-v2';
import type { ConditionalWrapperProps } from '@jane/shared/reefer-v2';

export const ConditionalWrapper = (props: ConditionalWrapperProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <ConditionalWrapperV2 {...props} />;
  }
  return <ConditionalWrapperV1 {...props} />;
};

import styled from '@emotion/styled';
import difference from 'lodash/difference';
import pluralise from 'pluralise';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  Skeleton,
  Tag,
  Typography,
} from '@jane/shared/reefer';

import { StoreSelectModalFilters } from './StoreSelectModalFilters';

const StyledStoreWrapper = styled(Flex)<{ isHidden: boolean }>(
  ({ isHidden }) => ({
    ...(isHidden && { display: 'none' }),
  })
);

type ExtraColumnOptions = {
  label: string;
  render: (store: Store) => string | null;
};

const StoreRow = ({
  extraColumn,
  handleChange,
  isChecked,
  isHidden = false,
  isLast = false,
  store,
  storeAddress,
}: {
  extraColumn?: ExtraColumnOptions;
  handleChange: (checked: boolean) => void;
  isChecked?: boolean;
  isHidden?: boolean;
  isLast?: boolean;
  store: Store;
  storeAddress: string;
}) => {
  const stringifiedId = StoreIds.asString(store.id);

  const tagProps = store.recreational
    ? ({
        label: 'Rec',
        color: 'primary-dark',
        background: 'primary-light',
      } as const)
    : ({
        label: 'Med',
        color: 'palm-dark',
        background: 'palm-light',
      } as const);

  const extraColumnMarkup = extraColumn?.render(store);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledStoreWrapper
          isHidden={isHidden}
          my={16}
          alignItems="center"
          gap={16}
        >
          <CheckboxField
            label={stringifiedId}
            labelHidden
            name={stringifiedId}
            defaultChecked={isChecked}
            checked={isChecked}
            onChange={handleChange}
          />
          <Tag {...tagProps} />
          <div>
            <Typography variant="body-bold">{store.name}</Typography>
            <Typography color="grays-mid">{storeAddress}</Typography>
          </div>
        </StyledStoreWrapper>
        {extraColumnMarkup ? (
          <StyledStoreWrapper
            isHidden={isHidden}
            my={16}
            alignItems="center"
            gap={16}
          >
            <Typography>{extraColumnMarkup}</Typography>
          </StyledStoreWrapper>
        ) : null}
      </Flex>
      {!isHidden && !isLast ? <Modal.ContentDivider padding={false} /> : null}
    </>
  );
};

const lengthEightArray = Array(8).fill(null);

const StoreSelectorSkeleton = () => {
  return (
    <Skeleton animate>
      <Skeleton.Bone height={48} mt={40} mb={16} />
      {lengthEightArray.map((_, i) => (
        <Skeleton.Bone key={`skeleton-${i}`} height={48} my={16} />
      ))}
    </Skeleton>
  );
};

export const enum SUBMIT_BUTTON_VARIANTS {
  save,
  saveTo,
  select,
}

const SUBMIT_BUTTON_MAPS: { [k in SUBMIT_BUTTON_VARIANTS]: string } = {
  [SUBMIT_BUTTON_VARIANTS.save]: 'Save',
  [SUBMIT_BUTTON_VARIANTS.saveTo]: 'Save to',
  [SUBMIT_BUTTON_VARIANTS.select]: 'Select',
};

const StoreIds = {
  asString: (id: number | string) => (typeof id === 'number' ? `${id}` : id),
  asNumber: (id: number | string) =>
    typeof id === 'string' ? parseInt(id, 10) : id,
};

export interface Store {
  address: string;
  address2?: string | null;
  cart_limit_policy?: { id: string | number; name: string | null } | null;
  city?: string | null;
  id: string | number;
  name: string;
  recreational: boolean;
  state?: string | null;
  zip?: string | undefined;
}

enum FilterNames {
  StoreState = 'Store State',
  StoreType = 'Store Type',
}
export interface StoreSelectModalProps {
  closeModal: () => void;
  disableSubmit?: boolean;
  extraColumn?: ExtraColumnOptions;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  initialSearchFilter?: string;
  isFetchingStores: boolean;
  onFilterChange?: (filterName: FilterNames, value?: string) => void;
  onSearchCallback?: (query: string, successful?: boolean) => void;
  onSubmit: (formDataEnabledIds: number[], isDirty: boolean) => void;
  onToggleAll?: (selected: boolean) => void;
  onToggleStore?: (storeId: string, selected: boolean) => void;
  searchFilterPlaceholder?: string;
  selectedStoreIds?: string[];
  storesData: Store[];
  submitButtonType?: SUBMIT_BUTTON_VARIANTS;
  subtitle?: string;
}

const concatAddress = (store: Store): string => {
  return `${store.address}${store.address2 ? `, ${store.address2}` : ''}, ${
    store.city
  }, ${store.state} ${store.zip}`;
};

export const StoreSelectModal = ({
  closeModal,
  disableSubmit = false,
  extraColumn,
  fetchNextPage,
  hasNextPage,
  onFilterChange,
  onSubmit,
  onSearchCallback,
  onToggleAll,
  onToggleStore,
  searchFilterPlaceholder = 'Search name, city or id',
  selectedStoreIds = [],
  submitButtonType = SUBMIT_BUTTON_VARIANTS.saveTo,
  subtitle,
  storesData,
  initialSearchFilter = '',
  isFetchingStores,
}: StoreSelectModalProps) => {
  const [searchFilter, setSearchFilter] = useState(initialSearchFilter);
  const [typeFilter, setTypeFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');

  const prevSearchFilter = useRef('');
  const handleSearchChange = (value: string) => {
    setSearchFilter((prev) => {
      prevSearchFilter.current = prev;
      return value;
    });
  };

  const anyFilterSet = useMemo(() => {
    return !!(searchFilter || typeFilter || stateFilter);
  }, [searchFilter, typeFilter, stateFilter]);

  const { ref: fetchMoreRef } = useInView({
    onChange: (inView) => {
      if (inView && !anyFilterSet && fetchNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const handleStateChange = (value: string) => {
    setStateFilter(value);
    onFilterChange && onFilterChange(FilterNames.StoreState, value);
  };

  const handleTypeChange = (value: string) => {
    setTypeFilter(value);
    onFilterChange && onFilterChange(FilterNames.StoreType, value);
  };

  type StoreSelectionsRecord = Record<number | string, boolean>;

  const [storeSelections, setStoreSelections] = useState<StoreSelectionsRecord>(
    selectedStoreIds.reduce<Record<string, boolean>>((acc, curr) => {
      acc[`${curr}`] = true;
      return acc;
    }, {})
  );

  const stores = useMemo(
    () =>
      storesData?.map(({ id, ...restStore }) => ({
        id: `${id}`,
        ...restStore,
      })),
    [storesData]
  );

  const isStoreSelected = useCallback(
    (store: Store) => storeSelections[StoreIds.asString(store.id)] === true,
    [storeSelections]
  );

  const numStoresSelected = useMemo(
    () => Object.values(storeSelections).filter((val) => val).length,
    [storeSelections]
  );

  const submitButtonTypeLabel = SUBMIT_BUTTON_MAPS[submitButtonType];

  const submitLabel =
    submitButtonTypeLabel === 'Save'
      ? 'Save'
      : `${submitButtonTypeLabel} ${numStoresSelected} ${pluralise(
          numStoresSelected,
          'store'
        )}`;

  const filteredStores = useMemo(() => {
    let storesForUser = stores?.filter(
      (store) =>
        store.name.toLowerCase().includes(searchFilter) ||
        store.city?.toLowerCase().includes(searchFilter) ||
        StoreIds.asString(store.id) === searchFilter
    );

    if (typeFilter && typeFilter !== 'All types') {
      const recreationPredicate = typeFilter === 'Recreational';

      storesForUser = storesForUser?.filter(
        (store) => store.recreational === recreationPredicate
      );
    }
    if (stateFilter) {
      storesForUser = storesForUser?.filter((store) =>
        store.state?.includes(stateFilter)
      );
    }
    return storesForUser || [];
  }, [stores, searchFilter, typeFilter, stateFilter]);

  const lastFilteredStoreId = filteredStores[filteredStores.length - 1]?.id;

  const formDataEnabledIds = useMemo(
    () =>
      Object.entries(storeSelections)
        .filter(([_, selected]) => selected)
        .map(([id]) => StoreIds.asNumber(id)),
    [storeSelections]
  );

  const isDirty = useMemo(() => {
    const hasDifferentFormEnabledIds =
      difference(
        formDataEnabledIds,
        selectedStoreIds.map((id) => StoreIds.asNumber(id))
      ).length > 0;

    const hasDifferentSelectedStoreIds =
      difference(
        selectedStoreIds.map((id) => StoreIds.asNumber(id)),
        formDataEnabledIds
      ).length > 0;

    return hasDifferentFormEnabledIds || hasDifferentSelectedStoreIds;
  }, [selectedStoreIds, formDataEnabledIds]);

  const allSelected = useMemo(
    () =>
      filteredStores.every(
        ({ id }) => !!storeSelections[StoreIds.asNumber(id)]
      ),
    [filteredStores, storeSelections]
  );

  const someSelected =
    !allSelected && Object.values(storeSelections).filter(Boolean).length !== 0;

  const isIndeterminate = filteredStores.length === 0 || someSelected;

  const handleSubmit = () => {
    onSubmit(formDataEnabledIds, isDirty);
  };

  const isTogglingAll = useRef(false);

  const handleToggleAll = (selected: boolean) => {
    const update: { [key: string]: boolean } = {};
    filteredStores.map((store) => {
      update[store.id] = selected;
    });

    isTogglingAll.current = true;
    setStoreSelections(update);
    onToggleAll && onToggleAll(selected);
  };

  useEffect(() => {
    isTogglingAll.current = false;
  }, [storeSelections]);

  useEffect(() => {
    if (searchFilter !== prevSearchFilter.current && onSearchCallback) {
      onSearchCallback(searchFilter, filteredStores.length > 0);
    }
  }, [searchFilter, filteredStores, onSearchCallback]);

  return (
    <Modal
      appId="root"
      onRequestClose={closeModal}
      contentLabel="select stores"
      open
    >
      <>
        <Modal.Header
          title="Select stores"
          subtitle={subtitle}
          actions={
            <Button
              variant="primary"
              data-testid="store-select-submit"
              label={submitLabel}
              ml={16}
              onClick={handleSubmit}
              disabled={disableSubmit}
            />
          }
        />
        <Modal.Content>
          <>
            <Flex>
              <StoreSelectModalFilters
                typeFilter={typeFilter}
                stateFilter={stateFilter}
                searchFilter={searchFilter}
                searchFilterPlaceholder={searchFilterPlaceholder}
                setTypeFilter={handleTypeChange}
                setStateFilter={handleStateChange}
                setSearchFilter={handleSearchChange}
                filteredStores={filteredStores}
                isFetchingStores={isFetchingStores}
                disablePadding
              />
            </Flex>
            {!isFetchingStores ? (
              <>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt={24}
                  mb={8}
                >
                  <Flex alignItems="center">
                    <CheckboxField
                      label="Select all"
                      labelHidden
                      name="select_all"
                      checked={allSelected}
                      indeterminate={isIndeterminate}
                      onChange={handleToggleAll}
                    />
                    <Typography
                      ml={72}
                      mt={24}
                      mb={16}
                      variant="caps"
                      color="grays-mid"
                    >
                      name
                    </Typography>
                  </Flex>
                  {extraColumn ? (
                    <Typography
                      mt={24}
                      mb={16}
                      variant="caps"
                      color="grays-mid"
                    >
                      {extraColumn.label}
                    </Typography>
                  ) : null}
                </Flex>
                <Modal.ContentDivider padding={false} />
                <Flex flexDirection="column">
                  {stores?.map((store) => {
                    const storeId = StoreIds.asNumber(store.id);
                    const isChecked = isStoreSelected(store);

                    const isHidden = !filteredStores.find(
                      ({ id }) => id === store.id
                    );

                    const changeHandler = (checked: boolean) => {
                      if (isTogglingAll.current) return;

                      setStoreSelections((currentSelections) => ({
                        ...currentSelections,
                        [storeId]: checked,
                      }));

                      onToggleStore &&
                        onToggleStore(storeId.toString(), checked);
                    };

                    return (
                      <StoreRow
                        isLast={store.id === lastFilteredStoreId}
                        isHidden={isHidden}
                        isChecked={isChecked}
                        store={store}
                        key={`${store.name}.${store.id}`}
                        storeAddress={concatAddress(store)}
                        extraColumn={extraColumn}
                        handleChange={changeHandler}
                      />
                    );
                  })}
                </Flex>
                <span ref={fetchMoreRef}></span>
              </>
            ) : (
              <StoreSelectorSkeleton />
            )}
          </>
        </Modal.Content>
      </>
    </Modal>
  );
};

import styled from '@emotion/styled';

import { shadows } from '../../../styling';
import { Flex } from '../../flex';

interface StyledDrawerHeaderProps {
  divider: boolean;
}

export const StyledDrawerHeader = styled(Flex)<StyledDrawerHeaderProps>(
  {
    textAlign: 'center',
  },
  ({ divider }) => ({
    boxShadow: divider ? shadows.divider : 'none',
  })
);

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Play24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2864 10.3039C18.5397 11.0873 18.5397 12.9126 17.2864 13.6959L7.06 20.0874C5.7279 20.92 4 19.9623 4 18.3914L4 5.60842C4 4.03755 5.7279 3.07987 7.06 3.91243L17.2864 10.3039Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Play24,
  sm: Play24,
  lg: Play24,
  xl: Play24,
  xxl: Play24,
  xxxl: Play24,
};

export const PlayIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'play-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

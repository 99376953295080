import compact from 'lodash/compact';
import get from 'lodash/get';
import join from 'lodash/join';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useFetchStoreCategories } from '@jane/business-admin/data-access';
import { ModalNames } from '@jane/business-admin/util';
import type { KindCondition, StoreCategory } from '@jane/shared/models';

import { ConfirmWrapperWithTracking } from '../../../../../../ConfirmWrapperWithTracking';
import { ConditionType } from '../../form';
import { convertRulesToString } from '../convertRulesToString';
import { ConditionByModal } from '../generic_components/ConditionByModal';
import { LoadingCondition } from '../generic_components/LoadingCondition';
import { CategorySelectInputModal } from './CategorySelectInputModal';
import { CategorySelectInputModalProvider } from './CategorySelectInputModalProvider';
import { PRODUCT_CATEGORIES_DEV_V2 } from './MOCK_CATEGORIES';
import { convertCategoryConditionToRules } from './util/rules';

export type CategoryOption = {
  label: string;
  subItems?: CategoryOption[];
  value: string;
};

export type CategorySelectInputProps = {
  categoryOptions: CategoryOption[];
  onDirty?: (dirty: boolean) => void;
  onSave: (selectedCategories: any) => void;
  selectedCategories: string[] | CategoryOption[];
  useDefaultModal?: boolean;
};

export const CategoryConditions = ({
  fieldPrefix,
  type,
  onConditionsUpdate,
}: {
  fieldPrefix: string;
  onConditionsUpdate: (
    conditions: KindCondition,
    includesOrExcludes: 'includes' | 'excludes'
  ) => void;
  type: ConditionType.Categories | ConditionType.ExcludeCategories;
}) => {
  const { id: storeId = '' } = useParams<'id'>();
  const [categoryConditionsModalOpen, setCategoryConditionsModalOpen] =
    useState(false);
  const [selectedCategories, setSelectedCategories] = useState<StoreCategory[]>(
    []
  );

  const { data: allCategories, isFetching } = useFetchStoreCategories(storeId);

  const { watch } = useFormContext();

  const rulesWatch = watch(`${fieldPrefix}.rules`);

  const includesOrExcludes =
    type === ConditionType.Categories ? 'includes' : 'excludes';

  const rules =
    rulesWatch && rulesWatch[includesOrExcludes]
      ? rulesWatch[includesOrExcludes]
      : null;

  const categoryMap = useMemo(() => {
    return get(rulesWatch, `${includesOrExcludes}.0.kinds`, []);
  }, [convertRulesToString(rulesWatch)]);

  const selectedCategoryWithDetails = allCategories?.filter(
    (category: StoreCategory) => categoryMap.includes(category)
  );

  const categoryConditions = categoryMap?.map((category: any) => {
    const parts = compact([
      category.kind,
      category.root_subtype,
      category.brand_subtype,
    ]);
    const value = join(parts, ':');
    return { value, label: value };
  });

  const onSubmit = (data: any) => {
    const formattedKinds = convertCategoryConditionToRules(data);

    onConditionsUpdate(formattedKinds, includesOrExcludes);

    setCategoryConditionsModalOpen(false);
  };

  useEffect(() => {
    if (!selectedCategoryWithDetails) {
      return;
    }

    setSelectedCategories(selectedCategoryWithDetails);
  }, [JSON.stringify(selectedCategoryWithDetails)]);

  return (
    <>
      {!categoryConditions ? (
        <LoadingCondition />
      ) : (
        <ConditionByModal
          onOpenModal={() => setCategoryConditionsModalOpen(true)}
          type={type}
          conditions={categoryConditions}
          conditionsLoading={isFetching}
          onConditionsUpdate={(data) => onSubmit(data)}
        />
      )}
      <ConfirmWrapperWithTracking
        open={categoryConditionsModalOpen}
        setOpen={() => {
          setCategoryConditionsModalOpen(false);
        }}
        hasChanges={selectedCategories.length !== categoryConditions?.length}
        modalName={ModalNames.CategoryConditions}
        overlayClose={true}
      >
        <CategorySelectInputModalProvider rules={rules?.[0]?.kinds}>
          <CategorySelectInputModal
            categoryOptions={PRODUCT_CATEGORIES_DEV_V2}
            onSave={onSubmit}
          />
        </CategorySelectInputModalProvider>
      </ConfirmWrapperWithTracking>
    </>
  );
};

import split from 'lodash/split';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useShouldShowGold } from '@jane/shared/hooks';
import type { MenuProduct, Store } from '@jane/shared/models';
import {
  Flex,
  Image,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  generateCustomLabel,
  getJaneGoldTagLabel,
  productPhotoMetadata,
  titleize,
} from '@jane/shared/util';

import { JaneGoldTag } from '../../janeGoldTag';
import { Category, SpecialBadge } from '../../legacyProductCard';
import { useProductCardContext } from '../../productCardProvider/productCardProvider';
import { potencyInfo } from '../../productPotency';
import { FiveStarRating } from '../../starRatings';
import { productDetailLink } from '../utils/productCardHelper';
import { useProductReviewsEnabled } from '../utils/useProductReviewsEnabled';
import { StyledCardContent } from './listViewProductCard.styles';

const getDiscountAmount = (special_amount: string, store: Store) => {
  const specialString =
    split(special_amount, ' ').length <= 2 ? special_amount : 'SALE';

  if (special_amount?.toUpperCase() === 'SALE') {
    return (
      store?.store_compliance_language_settings?.['specials'] || specialString
    );
  }

  return specialString;
};

export const ProductInfoCardContent = () => {
  const {
    appMode,
    currentSpecial,
    disableInteraction,
    janeGoldLabel,
    menuProduct,
    onSetBreadcrumbs,
    store,
    searchState,
    trackListViewClick,
    userLocation,
    routeAppMode,
    routePartnerHostedConfig,
  } = useProductCardContext();
  const { special_amount, brand, category, kind, brand_subtype, name } =
    menuProduct as MenuProduct;
  const discountAmount =
    store && special_amount && getDiscountAmount(special_amount, store);
  const isVerySmallMobile = useMobileMediaQuery({ width: 'max', size: 'sm' });
  const smallerThanVerticalTablet = useMobileMediaQuery({});
  const navigate = useNavigate();
  const shouldShowGold = useShouldShowGold({ product: menuProduct });

  const setProductBreadcrumbs = useCallback(() => {
    onSetBreadcrumbs && onSetBreadcrumbs(searchState);
  }, [searchState]);

  const productLineage = generateCustomLabel({
    appMode,
    store,
    attribute: category,
    isLineageLabel: true,
    fallback: titleize(category),
  });

  const specialApplies = discountAmount && currentSpecial;

  const photoMetadata = productPhotoMetadata(menuProduct);

  const reviewsEnabled = useProductReviewsEnabled(appMode, userLocation, store);

  const onClickHandler = () => {
    if (disableInteraction || !routeAppMode) return;

    trackListViewClick && trackListViewClick();
    setProductBreadcrumbs();
    navigate(
      productDetailLink({
        routeAppMode,
        routePartnerHostedConfig,
        product: menuProduct as MenuProduct,
        appMode,
        store,
      })
    );
  };

  const janeGoldTagLabel = shouldShowGold
    ? janeGoldLabel ||
      (menuProduct &&
        getJaneGoldTagLabel({
          menuProduct: menuProduct,
        }))
    : false;

  return (
    <StyledCardContent
      onClick={onClickHandler}
      ariaLabel="Click to view product details"
    >
      <Flex
        flexDirection={
          isVerySmallMobile
            ? 'column'
            : smallerThanVerticalTablet
            ? 'row-reverse'
            : 'row'
        }
        alignItems={isVerySmallMobile ? 'center' : 'flex-start'}
      >
        <Flex position="relative">
          <Image
            src={photoMetadata.url}
            altText={menuProduct?.name || ''}
            border
            borderRadius="rounded"
            height="96px"
            width="96px"
            loading="lazy"
            responsive
            sizes="thumbnail"
          />
          <Flex position="absolute" top={8} right={8}>
            {specialApplies && discountAmount && store && (
              <SpecialBadge
                amount={discountAmount}
                isShorten
                specialAmount={menuProduct?.special_amount || undefined}
                store={store}
              />
            )}
          </Flex>
        </Flex>
        <Flex
          pl={smallerThanVerticalTablet ? 0 : 16}
          flexDirection="column"
          width="100%"
        >
          <Flex flexDirection={'column'}>
            {category && (
              <Category
                title={category || ''}
                category={category}
                editingMode={false}
              >
                {productLineage}
              </Category>
            )}
            <Typography variant="header-bold" branded>
              {name}
            </Typography>

            <Flex flexWrap="wrap">
              {!!brand && <Typography color="purple">{brand}</Typography>}
              {janeGoldTagLabel && (
                <JaneGoldTag my={2} ml={4} label={janeGoldTagLabel} />
              )}
            </Flex>

            <Flex flexWrap="wrap">
              <Typography>
                {brand_subtype ? brand_subtype : titleize(kind)}
              </Typography>
              <Typography color="text-light" ml={16}>
                {menuProduct && potencyInfo(menuProduct)}
              </Typography>
            </Flex>
          </Flex>

          {reviewsEnabled && menuProduct && menuProduct.aggregate_rating ? (
            <Flex alignItems="center">
              <FiveStarRating rating={menuProduct.aggregate_rating} />

              <Typography mx={4}>{menuProduct.aggregate_rating}</Typography>
              <Typography color="text-light">
                ({menuProduct.review_count})
              </Typography>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </StyledCardContent>
  );
};

export default ProductInfoCardContent;

import { useMemo } from 'react';

import {
  useGetJaneDeviceId,
  useUserSegmentIds,
} from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { BrandSpecial } from '@jane/shared/models';
import {
  shouldBrandShowSpecials,
  shouldBrandSpecialShowForUser,
  shouldProductShowSpecial,
} from '@jane/shared/util';
import type { ProductWithBrandSpecialSegments } from '@jane/shared/util';

export interface ShouldShowGoldProps {
  brandSpecial?: Pick<BrandSpecial, 'rules'>;
  brandSpecials?: Pick<BrandSpecial, 'rules'>[];
  product?: ProductWithBrandSpecialSegments;
}

export const useShouldShowGold = (props?: ShouldShowGoldProps): boolean => {
  const product = props?.product;
  const brandSpecial = props?.brandSpecial;
  const brandSpecials = props?.brandSpecials;
  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);
  const janeGoldFlag = useFlag(FLAGS.janeGold);
  const { data: jdid } = useGetJaneDeviceId();
  const { data: userSegments } = useUserSegmentIds(jdid, {
    enabled: !!product || !!brandSpecial,
    // 1 hour
    staleTime: 60 * 60 * 1000,
  });

  return useMemo(() => {
    if (!janeGoldSegmentation) return janeGoldFlag;

    if (product) {
      return (
        janeGoldFlag && shouldProductShowSpecial({ product, userSegments })
      );
    }

    if (brandSpecial) {
      return (
        janeGoldFlag &&
        shouldBrandSpecialShowForUser({
          brandSpecial,
          userSegments,
        })
      );
    }

    if (brandSpecials) {
      return (
        janeGoldFlag &&
        shouldBrandShowSpecials({
          brandSpecials,
          userSegments,
        })
      );
    }

    return janeGoldFlag;
  }, [
    product,
    brandSpecial,
    brandSpecials,
    userSegments,
    janeGoldFlag,
    janeGoldSegmentation,
  ]);
};

import { forwardRef, useEffect, useState } from 'react';
import type { ChangeEvent, FocusEvent } from 'react';

import type { TextAreaFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import {
  StyledTextArea,
  StyledTextAreaBorder,
  StyledTextAreaContainer,
} from './textAreaField.styles';

export interface TextAreaFieldPropsInternal extends TextAreaFieldProps {
  onUpdate?: (value: string) => void;
  value?: string;
}

export const TextAreaFieldInternal = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldPropsInternal
>(
  (
    {
      autoFocus = false,
      children,
      className,
      defaultValue = '',
      disabled = false,
      autocomplete,
      borderRadius = 'sm',
      disableMobileInputStyling = false,
      helperText,
      id,
      label,
      labelHidden = false,
      name,
      onBlur,
      onChange,
      onUpdate,
      placeholder,
      readOnly = false,
      resize = true,
      rows,
      'data-testid': testId,
      value: externalValue,
      width = 'auto',
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== value) {
        setValue(externalValue);
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, value]);

    const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onBlur && onBlur(event.target.value);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onChange && onChange(event.target.value);
    };

    return (
      <FieldWrapper
        className={className}
        disabled={disabled}
        disableMobileInputStyling={disableMobileInputStyling}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        labelPosition="t"
        name={name}
        isWrappingTextInput
        render={(renderProps) => (
          <StyledTextAreaContainer>
            <StyledTextArea
              autoFocus={autoFocus}
              disabled={disabled}
              disableMobileInputStyling={disableMobileInputStyling}
              autoComplete={autocomplete}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder}
              readOnly={readOnly}
              resize={resize}
              rows={rows}
              ref={ref}
              value={value}
              {...renderProps}
            />
            <StyledTextAreaBorder
              disableMobileInputStyling={disableMobileInputStyling}
              role="presentation"
              radiusSize={borderRadius}
              readOnly={readOnly}
            />
          </StyledTextAreaContainer>
        )}
        data-testid={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);

/**
 * TextArea fields define multi-line text input controls.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.TextAreaField`](/story/components-forms-form-textareafield--default).*
 */
export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>((props, ref) => <TextAreaFieldInternal ref={ref} {...props} />);

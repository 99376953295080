// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z3hXapFE9C8FuSEfoUeJ {\n  border: var(--card-border);\n  border-radius: var(--card-border-radius);\n  box-shadow: var(--shadows-item);\n  cursor: initial;\n  display: inline-flex;\n  flex-direction: column;\n  height: var(--card-height);\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  overflow: hidden;\n  outline: none;\n  position: relative;\n  width: var(--card-width);\n}\n\n.IrfM2y51A4fFHYDhIGjD {\n  cursor: pointer;\n}\n\n.IrfM2y51A4fFHYDhIGjD:focus-visible {\n  background: radial-gradient(\n    transparent,\n    transparent,\n    var(--colors-grays-ultralight)\n  );\n  transition: 250ms all;\n}\n\n.n8dtZ_5nj3c2jNERLKzK {\n  box-shadow: none;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/card/card.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,wCAAwC;EACxC,+BAA+B;EAC/B,eAAe;EACf,oBAAoB;EACpB,sBAAsB;EACtB,0BAA0B;EAC1B,mCAAmC;EACnC,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;EAC7B,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;;;;GAIC;EACD,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".card {\n  border: var(--card-border);\n  border-radius: var(--card-border-radius);\n  box-shadow: var(--shadows-item);\n  cursor: initial;\n  display: inline-flex;\n  flex-direction: column;\n  height: var(--card-height);\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  overflow: hidden;\n  outline: none;\n  position: relative;\n  width: var(--card-width);\n}\n\n.card__clickable {\n  cursor: pointer;\n}\n\n.card__clickable:focus-visible {\n  background: radial-gradient(\n    transparent,\n    transparent,\n    var(--colors-grays-ultralight)\n  );\n  transition: 250ms all;\n}\n\n.card__flat {\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Z3hXapFE9C8FuSEfoUeJ",
	"card__clickable": "IrfM2y51A4fFHYDhIGjD",
	"card__flat": "n8dtZ_5nj3c2jNERLKzK"
};
export default ___CSS_LOADER_EXPORT___;

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Soil24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4264 3.44107C15.956 3.77206 16.117 4.4697 15.786 4.99928L14.8271 4.39997C15.786 4.99928 15.7858 4.99954 15.786 4.99928L15.7837 5.00301L15.766 5.03236C15.7493 5.06035 15.723 5.10505 15.6884 5.16577C15.6193 5.28725 15.5173 5.47262 15.3933 5.7164C15.1452 6.20434 14.8102 6.92398 14.474 7.83167C13.8002 9.65087 13.1309 12.201 13.1309 15.1423H10.8694C10.8694 11.8644 11.6136 9.04337 12.3532 7.0462C12.7238 6.04569 13.0955 5.24585 13.3774 4.69136C13.5185 4.41393 13.6374 4.19734 13.7229 4.04717C13.7656 3.97206 13.8 3.91351 13.8247 3.87221L13.8543 3.82326L13.8633 3.80864L13.8662 3.80381L13.8674 3.80203C13.8676 3.8017 13.8682 3.80066 14.8271 4.39997L13.8682 3.80066C14.1992 3.27108 14.8968 3.11008 15.4264 3.44107Z"
      fill="#4BA279"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.72576 2.98925C7.19252 2.57435 7.90725 2.61639 8.32216 3.08315L7.477 3.8344C8.32216 3.08315 8.3219 3.08287 8.32216 3.08315L8.32477 3.0861L8.32902 3.09092L8.34237 3.10616C8.35337 3.11878 8.36856 3.13633 8.38764 3.15868C8.42581 3.20337 8.47962 3.2673 8.54681 3.34949C8.68115 3.51382 8.86935 3.75155 9.09337 4.05492C9.5408 4.66082 10.1347 5.53326 10.7286 6.6098C11.9087 8.74874 13.1309 11.7688 13.1309 15.1422H10.8693C10.8693 12.2962 9.82993 9.66244 8.74847 7.70231C8.21165 6.72932 7.67476 5.94094 7.27411 5.39838C7.07409 5.12753 6.90892 4.91914 6.7959 4.7809C6.73942 4.71181 6.69604 4.66034 6.66796 4.62745L6.63759 4.59222L6.63185 4.58565C6.21737 4.11887 6.25914 3.40402 6.72576 2.98925Z"
      fill="#4BA279"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 24.0001C18.245 24.0001 23.3077 18.9375 23.3077 12.6924H0.692139C0.692139 18.9375 5.7548 24.0001 11.9999 24.0001Z"
      fill="#FF9900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 24.0001C18.245 24.0001 23.3077 18.9375 23.3077 12.6924H0.692139C0.692139 18.9375 5.7548 24.0001 11.9999 24.0001Z"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      d="M16.9787 4.16057C16.398 5.32088 15.3056 6.10899 13.9103 6.10719C13.0726 4.9915 13.0486 3.64464 13.6293 2.48433C14.21 1.32401 15.3024 0.535903 16.6976 0.537702C17.5354 1.6534 17.5594 3.00026 16.9787 4.16057Z"
      fill="#4BA279"
    />
    <path
      d="M4.61235 5.89522C3.15187 4.43475 2.50985 2.38951 3.21203 0.285272C5.31627 -0.416912 7.3615 0.225117 8.82197 1.68559C10.2824 3.14606 10.9245 5.1913 10.2223 7.29553C8.11805 7.99772 6.07282 7.35569 4.61235 5.89522Z"
      fill="#4BA279"
    />
    <path
      d="M11.9999 11.1846C5.21524 11.1846 0.692139 12.6923 0.692139 12.6923L23.3077 12.6923C23.3077 12.6923 18.7846 11.1846 11.9999 11.1846Z"
      fill="#FF9900"
    />
    <path
      d="M11.9999 11.1846C5.21524 11.1846 0.692139 12.6923 0.692139 12.6923L23.3077 12.6923C23.3077 12.6923 18.7846 11.1846 11.9999 11.1846Z"
      fill="black"
      fillOpacity={0.2}
    />
    <ellipse
      cx={12.0002}
      cy={19.6652}
      rx={1.13078}
      ry={1.13078}
      fill="black"
      fillOpacity={0.5}
    />
    <ellipse
      cx={7.47697}
      cy={16.2731}
      rx={1.13078}
      ry={1.13078}
      fill="black"
      fillOpacity={0.5}
    />
    <ellipse
      cx={16.5231}
      cy={16.2731}
      rx={1.13078}
      ry={1.13078}
      fill="black"
      fillOpacity={0.5}
    />
  </svg>
);

const SIZE_MAP = {
  md: Soil24,
  sm: Soil24,
  lg: Soil24,
  xl: Soil24,
  xxl: Soil24,
  xxxl: Soil24,
};

export const SoilIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'soil-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

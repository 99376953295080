/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Leaf24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13041_31282)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.88795 18.4323C7.18324 18.2441 6.53711 17.8613 5.80117 17.115C5.62468 17.232 5.44805 17.3407 5.27129 17.4411C4.65398 17.8042 3.98984 18.1101 3.27886 18.3589C2.57572 18.6159 1.79245 18.8712 0.929051 19.1246C0.622437 19.214 0.398803 19.3574 0.25815 19.5547C0.117319 19.76 0.0442414 19.9828 0.0389171 20.2232C0.0280911 20.712 0.207251 21.1288 0.576396 21.4737C0.945364 21.8266 1.41029 22.0092 1.97118 22.0217C2.39586 22.0311 2.88356 21.9096 3.43428 21.6572C3.98482 21.4129 4.54507 21.0926 5.11501 20.6964C5.68478 20.3082 6.21474 19.9071 6.70491 19.493C7.185 19.0875 7.57935 18.7339 7.88795 18.4323Z"
        fill="#1D5A53"
      />
      <path
        d="M5.21379 6.14902C10.9327 0.430155 19.297 0.624675 23.4986 1.55837C24.4323 5.75999 24.6268 14.1243 18.908 19.8432C13.1891 25.5621 8.09268 22.0996 5.52502 19.532C2.95737 16.9643 -0.505077 11.8679 5.21379 6.14902Z"
        fill="#1D5A53"
      />
      <path
        d="M19.2194 7.54969C18.7467 8.02238 17.9803 8.02238 17.5076 7.54969C17.0349 7.077 17.0349 6.31061 17.5076 5.83792C17.9803 5.36523 18.7467 5.36523 19.2194 5.83792C19.6921 6.31061 19.6921 7.077 19.2194 7.54969Z"
        fill="#4BA279"
      />
      <path
        d="M13.2282 18.6762C12.7555 19.1489 11.9891 19.1489 11.5164 18.6762C11.0438 18.2035 11.0438 17.4371 11.5164 16.9644C11.9891 16.4917 12.7555 16.4917 13.2282 16.9644C13.7009 17.4371 13.7009 18.2035 13.2282 18.6762Z"
        fill="#4BA279"
      />
      <path
        d="M8.0929 13.5409C7.62021 14.0136 6.85382 14.0136 6.38113 13.5409C5.90844 13.0682 5.90844 12.3018 6.38113 11.8291C6.85382 11.3564 7.62021 11.3564 8.0929 11.8291C8.56559 12.3018 8.56559 13.0682 8.0929 13.5409Z"
        fill="#4BA279"
      />
      <path
        d="M17.5076 14.3968C17.0349 14.8695 16.2686 14.8695 15.7959 14.3968C15.3232 13.9241 15.3232 13.1577 15.7959 12.685C16.2686 12.2123 17.0349 12.2123 17.5076 12.685C17.9803 13.1577 17.9803 13.9241 17.5076 14.3968Z"
        fill="#4BA279"
      />
      <path
        d="M12.3723 9.26146C11.8996 9.73415 11.1332 9.73415 10.6606 9.26146C10.1879 8.78877 10.1879 8.02238 10.6606 7.54969C11.1332 7.077 11.8996 7.077 12.3723 7.54969C12.845 8.02238 12.845 8.78877 12.3723 9.26146Z"
        fill="#4BA279"
      />
    </g>
    <defs>
      <clipPath id="clip0_13041_31282">
        <rect
          width={24}
          height={24}
          fill="white"
          transform="translate(0 0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Leaf24,
  sm: Leaf24,
  lg: Leaf24,
  xl: Leaf24,
  xxl: Leaf24,
  xxxl: Leaf24,
};

export const LeafIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'leaf-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

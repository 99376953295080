// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OG4OvSnQbolkl2yv6blX {\n  background-color: var(--colors-grays-white);\n  border-bottom: 1px solid var(--colors-grays-light);\n  height: var(--tableHeaderCell-height);\n  max-height: var(--tableHeaderCell-height);\n  padding: 8px 12px;\n  vertical-align: inherit;\n  width: var(--tableHeaderCell-width);\n}\n\n.OG4OvSnQbolkl2yv6blX::--webkit-scrollbar {\n  display: none;\n}\n\n.unIoiin5rwY7gKFrBRWp {\n  cursor: pointer;\n}\n\n.yJKhkv7H6hUywGSNugjm {\n  display: flex;\n  align-items: center;\n  float: left;\n  text-align: left;\n  cursor: unset;\n  font-weight: normal;\n}\n\n.pwPXobgm0g1opWzJjWWV {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-table/src/lib/tableHeaderCell/tableHeaderCell.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,kDAAkD;EAClD,qCAAqC;EACrC,yCAAyC;EACzC,iBAAiB;EACjB,uBAAuB;EACvB,mCAAmC;AACrC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".tableHeaderCell {\n  background-color: var(--colors-grays-white);\n  border-bottom: 1px solid var(--colors-grays-light);\n  height: var(--tableHeaderCell-height);\n  max-height: var(--tableHeaderCell-height);\n  padding: 8px 12px;\n  vertical-align: inherit;\n  width: var(--tableHeaderCell-width);\n}\n\n.tableHeaderCell::--webkit-scrollbar {\n  display: none;\n}\n\n.tableHeaderCell__clickable {\n  cursor: pointer;\n}\n\n.tableHeaderCell_label {\n  display: flex;\n  align-items: center;\n  float: left;\n  text-align: left;\n  cursor: unset;\n  font-weight: normal;\n}\n\n.tableHeaderCell_label__sorted {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderCell": "OG4OvSnQbolkl2yv6blX",
	"tableHeaderCell__clickable": "unIoiin5rwY7gKFrBRWp",
	"tableHeaderCell_label": "yJKhkv7H6hUywGSNugjm",
	"tableHeaderCell_label__sorted": "pwPXobgm0g1opWzJjWWV"
};
export default ___CSS_LOADER_EXPORT___;

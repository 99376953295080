import { useState } from 'react';

import { Button, Modal } from '@jane/shared/reefer';
import { CheckboxField, Flex, Typography } from '@jane/shared/reefer-v1';

interface Props {
  archiveButton: (onArchiveClick: () => void) => void;
  bulkMode?: boolean;
  onArchiveSpecial: () => void;
}

export const ArchiveWithConfirm = ({
  archiveButton,
  bulkMode,
  onArchiveSpecial,
}: Props) => {
  const storageKey = bulkMode ? 'skipBulkArchivePrompt' : 'skipArchivePrompt';
  const [confirmChangeModalOpen, setConfirmChangeModalOpen] = useState(false);

  const onConfirmToggle = () => {
    setConfirmChangeModalOpen(false);
    onArchiveSpecial();
  };

  const skipArchiveConfirmation = (skip: boolean) => {
    localStorage.setItem(storageKey, skip.toString());
  };

  const onArchiveClick = () => {
    const shouldSkip = localStorage.getItem(storageKey);

    if (shouldSkip === 'true') {
      onArchiveSpecial();
    } else {
      setConfirmChangeModalOpen(true);
    }
  };

  return (
    <>
      {archiveButton(onArchiveClick)}

      <Modal
        onRequestClose={() => setConfirmChangeModalOpen(false)}
        open={confirmChangeModalOpen}
        variant="dialogue"
        appId={'parent'}
      >
        <Modal.Content>
          <Flex width="100%" flexDirection="column" alignItems="center">
            <Typography variant="title-bold">Archiving Specials</Typography>
            <Typography textAlign="center">
              Archiving will permanently disable{' '}
              {bulkMode ? 'selected specials' : 'this special'}.<br />
              You will not be able to unarchive.
            </Typography>

            <Flex mt={24} width="100%">
              <Button
                mr={16}
                full
                variant="tertiary"
                label="Cancel"
                onClick={() => setConfirmChangeModalOpen(false)}
              />
              <Button
                full
                data-testid="confirm-change-button"
                variant="destructive"
                label="Archive"
                onClick={onConfirmToggle}
              />
            </Flex>
            <CheckboxField
              mt={20}
              onChange={skipArchiveConfirmation}
              name="skip_archive_confirmation"
              label="Don't show this message in the future"
            />
          </Flex>
        </Modal.Content>
      </Modal>
    </>
  );
};

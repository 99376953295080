/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Edible24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 1.00061C6.88679 1.00061 2.71986 3.13995 2.5356 5.81571C2.47102 6.0245 2.42285 6.24105 2.39267 6.46396L1.0388 16.464C0.852282 17.8416 1.38754 19.1322 2.33544 19.9819C3.95024 21.7578 7.66975 23.0006 12.0002 23.0006C16.3306 23.0006 20.0501 21.7578 21.6649 19.9819C22.6128 19.1322 23.1481 17.8416 22.9616 16.464L21.6077 6.46396C21.5775 6.24105 21.5293 6.02451 21.4648 5.81571C21.2805 3.13995 17.1136 1.00061 12.0002 1.00061Z"
      fill="#FF9900"
    />
    <ellipse
      cx={12}
      cy={4.00061}
      rx={3}
      ry={1}
      className="details"
      fill="white"
      fillOpacity={0.8}
    />
    <ellipse
      cx={12}
      cy={17.0006}
      rx={9}
      ry={4}
      className="details"
      fill="black"
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.36358 5.36419C5.71505 5.01272 6.2849 5.01272 6.63637 5.36419C6.97813 5.70595 8.93947 7.10059 12 7.10059C15.0605 7.10059 17.0218 5.70595 17.3636 5.36419C17.7151 5.01272 18.2849 5.01272 18.6364 5.36419C18.9878 5.71566 18.9878 6.28551 18.6364 6.63698C17.9781 7.29522 15.5669 8.90059 12 8.90059C8.43306 8.90059 6.02182 7.29522 5.36358 6.63698C5.01211 6.28551 5.01211 5.71566 5.36358 5.36419Z"
      className="details"
      fill="black"
      fillOpacity={0.2}
    />
  </svg>
);

const Edible48 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15898_10870)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9997 2.33997C13.9349 2.33997 5.73238 6.54905 5.3631 11.8148C5.23489 12.228 5.13932 12.6567 5.07958 13.098L2.41362 32.7894C2.04605 35.5044 3.10203 38.0476 4.97137 39.7207C8.15311 43.2155 15.4749 45.661 23.9989 45.661C32.5292 45.661 39.8556 43.2119 43.0335 39.7129C44.8974 38.0397 45.9496 35.5001 45.5825 32.7894L42.9166 13.098C42.8575 12.6614 42.7633 12.2373 42.6372 11.8282C42.2809 6.55616 34.0729 2.33997 23.9997 2.33997Z"
        fill="#FF9900"
      />
      <path
        d="M23.9996 10.2166C27.2621 10.2166 29.907 9.33498 29.907 8.24746C29.907 7.15993 27.2621 6.27832 23.9996 6.27832C20.737 6.27832 18.0922 7.15993 18.0922 8.24746C18.0922 9.33498 20.737 10.2166 23.9996 10.2166Z"
        fill="#FF9900"
      />
      <path
        d="M23.9996 10.2166C27.2621 10.2166 29.907 9.33498 29.907 8.24746C29.907 7.15993 27.2621 6.27832 23.9996 6.27832C20.737 6.27832 18.0922 7.15993 18.0922 8.24746C18.0922 9.33498 20.737 10.2166 23.9996 10.2166Z"
        className="details"
        fill="white"
        fillOpacity={0.8}
      />
      <path
        d="M23.9996 41.7227C33.7873 41.7227 41.7218 38.1962 41.7218 33.8462C41.7218 29.4961 33.7873 25.9696 23.9996 25.9696C14.2119 25.9696 6.27734 29.4961 6.27734 33.8462C6.27734 38.1962 14.2119 41.7227 23.9996 41.7227Z"
        fill="#FF9900"
      />
      <path
        d="M23.9996 41.7227C33.7873 41.7227 41.7218 38.1962 41.7218 33.8462C41.7218 29.4961 33.7873 25.9696 23.9996 25.9696C14.2119 25.9696 6.27734 29.4961 6.27734 33.8462C6.27734 38.1962 14.2119 41.7227 23.9996 41.7227Z"
        className="details"
        fill="black"
        fillOpacity={0.2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9309 10.9325C11.623 10.2404 12.7451 10.2404 13.4372 10.9325C14.1102 11.6055 17.9723 14.3517 23.9989 14.3517C30.0254 14.3517 33.8875 11.6055 34.5606 10.9325C35.2526 10.2404 36.3748 10.2404 37.0669 10.9325C37.759 11.6246 37.759 12.7467 37.0669 13.4388C35.7707 14.735 31.0226 17.8962 23.9989 17.8962C16.9752 17.8962 12.2271 14.735 10.9309 13.4388C10.2388 12.7467 10.2388 11.6246 10.9309 10.9325Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9309 10.9325C11.623 10.2404 12.7451 10.2404 13.4372 10.9325C14.1102 11.6055 17.9723 14.3517 23.9989 14.3517C30.0254 14.3517 33.8875 11.6055 34.5606 10.9325C35.2526 10.2404 36.3748 10.2404 37.0669 10.9325C37.759 11.6246 37.759 12.7467 37.0669 13.4388C35.7707 14.735 31.0226 17.8962 23.9989 17.8962C16.9752 17.8962 12.2271 14.735 10.9309 13.4388C10.2388 12.7467 10.2388 11.6246 10.9309 10.9325Z"
        className="details"
        fill="black"
        fillOpacity={0.2}
      />
    </g>
    <defs>
      <clipPath id="clip0_15898_10870">
        <rect width={44} height={44} fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE_MAP = {
  md: Edible24,
  sm: Edible24,
  xl: Edible48,
  lg: Edible48,
  xxl: Edible48,
  xxxl: Edible48,
};

export const EdibleIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'edible-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

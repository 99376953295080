import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import type { ManagerNotification } from '@jane/business-admin/types';
import { EventNames, track } from '@jane/business-admin/util';
import { Button, Flex, List, Typography } from '@jane/shared/reefer';

type NotificationistItemProps = {
  closePopover: () => void;
  notification: ManagerNotification;
  type: string;
};

const Dot = styled(Flex)<{ visible: boolean }>(({ theme, visible }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.brand.purple.main,
  borderRadius: '50%',
  height: 12,
  width: 12,
  minWidth: 12,
  visibility: visible ? 'visible' : 'hidden',
}));

const NotificationListItem = ({
  closePopover,
  notification,
  type,
}: NotificationistItemProps) => {
  const theme = useTheme();

  const buttonVariant = ({
    type,
    notification,
  }: {
    notification: ManagerNotification;
    type: string;
  }) => {
    if (type === 'alert') {
      return notification.critical && notification.action_enabled
        ? 'destructive'
        : 'secondary';
    }
    return 'tertiary';
  };

  return (
    <List.Item pt={12} px={0} aria-label={`${type} list item`}>
      <Flex flexDirection="row" alignItems="center" gap={24}>
        <Dot
          aria-label="unseen individual notification"
          theme={theme}
          visible={!notification.seen}
        />
        <Flex flexDirection="column" alignItems="flex-start" grow={1}>
          <Typography color="grays-black" variant="body-bold">
            {notification.header}
          </Typography>
          <Flex maxWidth={350}>
            <Typography
              color={type === 'alert' ? 'grays-black' : 'grays-mid'}
              variant="mini"
            >
              {notification.subheader}
            </Typography>
          </Flex>
        </Flex>
        <Flex minWidth={150}>
          <Button
            label={notification.action_text}
            variant={buttonVariant({ type, notification })}
            full
            onClick={() => {
              if (notification.action_url) {
                window.open(notification.action_url, '_blank');
                track({
                  critical_state: notification.critical,
                  event: EventNames.OpenedNotificationLink,
                  notification_header: notification.header,
                  notification_subtext: notification.subheader,
                  notification_type: notification.notification_type,
                  to_url: notification.action_url,
                });
              }
              closePopover();
            }}
          />
        </Flex>
      </Flex>
    </List.Item>
  );
};

type NotificationListProps = {
  closePopover: () => void;
  isOpen?: boolean;
  listKey: string;
  notifications: ManagerNotification[];
  type: string;
};

export const NotificationList = ({
  notifications,
  listKey,
  type,
  closePopover,
}: NotificationListProps) => {
  return (
    <List label={`notifications ${listKey} list`} key={`${listKey} list`}>
      {notifications.map((item, index) => {
        return (
          item && (
            <NotificationListItem
              notification={item}
              type={type}
              closePopover={closePopover}
              key={`${listKey}-${index}`}
            />
          )
        );
      })}
    </List>
  );
};

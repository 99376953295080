import styled from '@emotion/styled';

import { mediaQueries, spacing } from '../../../styling';
import type { Spacing } from '../../../styling';
import { getColorValue } from '../../../utils/componentStyles';
import type { ModalContentDividerProps } from '../modal.types';
import { DESKTOP_PADDING, MOBILE_PADDING } from './modalContent.styles';

const StyledContentDivider = styled.hr<ModalContentDividerProps>(
  ({ theme, height = 1, padding = true, color }) => [
    {
      backgroundColor: getColorValue(color, theme),
      border: 'none',
      height,
    },
    padding && {
      ...spacing({ mx: -MOBILE_PADDING as Spacing, my: MOBILE_PADDING }),
      [mediaQueries.tablet('min')]: {
        ...spacing({ mx: -DESKTOP_PADDING as Spacing, my: DESKTOP_PADDING }),
      },
    },
  ]
);

export const ModalContentDivider = ({
  color = 'grays-light',
  'data-testid': testId,
  height = 1,
  id,
  padding = true,
}: ModalContentDividerProps) => (
  <StyledContentDivider
    color={color}
    data-testid={testId}
    height={height}
    id={id}
    padding={padding}
  />
);

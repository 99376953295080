import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ColorPicker } from '@jane/business-admin/components';
import {
  useFetchCurrentBloomMenu,
  useFetchIframeHeader,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import type { BloomTheme } from '@jane/business-admin/types';
import { businessPaths, normalizeUrl } from '@jane/business-admin/util';
import { contrastingColor } from '@jane/shared-ecomm/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import { useNavigateBack } from '@jane/shared/hooks';
import {
  Button,
  Card,
  ChevronLeftIcon,
  Flex,
  Form,
  FormValidationError,
  Loading,
  Tabs,
  Typography,
  useToast,
} from '@jane/shared/reefer';

interface FormData {
  [key: string]: unknown;
}

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

const StyledTextField = styled(Form.TextField)<{ error: boolean }>(
  ({ theme, error }) => ({
    div: {
      borderColor: error ? theme.colors.system.negative.main : 'inherit',
      '&:focus-within': {
        borderColor: error ? theme.colors.system.negative.main : 'inherit',
      },
    },
  })
);

const StyledTabs = styled(Tabs)(({ theme }) => ({
  margin: '40px auto 0',
  backgroundColor: `${theme.colors.grays.ultralight}`,
  borderRadius: `${theme.borderRadius.sm}`,
}));

const StyledTab = styled(Tabs.Tab)<{ selected: boolean }>(
  ({ theme, selected }) => ({
    boxShadow: 'none',
    border: selected ? `2px solid ${theme.colors.primary.main}` : 'none',
    borderRadius: `${theme.borderRadius.sm}`,

    span: {
      fontWeight: 600,
    },
  })
);

const defaultValues = {
  primaryHex: '#000000',
  secondaryHex: '#FFFFFF',
  dealsHex: '#CE349A',
  headerHex: '#FFFFFF',
  indicaHex: '#000000',
  hybridHex: '#000000',
  sativaHex: '#000000',
  cbdHex: '#000000',
};

export const PlusColors = () => {
  const {
    control,
    formState: { dirtyFields },
    reset,
    watch,
  } = useForm({
    defaultValues,
  });

  const primaryHex = watch('primaryHex');
  const secondaryHex = watch('secondaryHex');
  const dealsHex = watch('dealsHex');
  const headerHex = watch('headerHex');
  const indicaHex = watch('indicaHex');
  const hybridHex = watch('hybridHex');
  const sativaHex = watch('sativaHex');
  const cbdHex = watch('cbdHex');

  const goBack = useNavigateBack(businessPaths.plus());
  const [selectedTab, setSelectedTab] = useState<
    'color-scheme' | 'lineage-tags'
  >('color-scheme');
  const toast = useToast();
  // colors for colors-scheme tab
  const primaryContrast = contrastingColor(primaryHex, ['#0E0E0E', '#FFFFFF']);
  const inverseText = primaryContrast === '#0E0E0E' ? '#FFFFFF' : '#000000';
  const secondaryTextContrast = contrastingColor(secondaryHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);
  const dealsTextContrast = contrastingColor(dealsHex, ['#0E0E0E', '#FFFFFF']);
  const headerTextContrast = contrastingColor(headerHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);

  // colors for lineage-tags tab
  const hybridTextContrast = contrastingColor(hybridHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);
  const indicaTextContrast = contrastingColor(indicaHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);
  const sativaHexContrast = contrastingColor(sativaHex, ['#0E0E0E', '#FFFFFF']);
  const cbdHexContrast = contrastingColor(cbdHex, ['#0E0E0E', '#FFFFFF']);

  const [retailerUrl, setRetailerUrl] = useState('');
  const [isiFrameLoading, setIsiFrameLoading] = useState(false);
  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const { mutate: updateBloomMenu } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );
  const { mutateAsync: fetchIframeHeader } = useFetchIframeHeader();
  const [isIframeError, setIsIframeError] = useState(false);

  const colorUpdates = useMemo(() => {
    return {
      primary: {
        main: primaryHex,
        contrast: primaryContrast,
      },
      secondary: {
        main: secondaryHex,
        contrast: secondaryTextContrast,
      },
      header: {
        main: headerHex,
        contrast: headerTextContrast,
      },
      deals: {
        main: dealsHex,
        contrast: dealsTextContrast,
      },
      text: {
        primary_inverse: inverseText,
      },
      lineage: {
        cbd: {
          main: cbdHex,
          contrast: cbdHexContrast,
        },
        hybrid: {
          main: hybridHex,
          contrast: hybridTextContrast,
        },
        indica: {
          main: indicaHex,
          contrast: indicaTextContrast,
        },
        sativa: {
          main: sativaHex,
          contrast: sativaHexContrast,
        },
      },
    };
  }, [
    cbdHex,
    cbdHexContrast,
    dealsHex,
    dealsTextContrast,
    headerHex,
    headerTextContrast,
    hybridHex,
    hybridTextContrast,
    indicaHex,
    indicaTextContrast,
    inverseText,
    primaryContrast,
    primaryHex,
    sativaHex,
    sativaHexContrast,
    secondaryHex,
    secondaryTextContrast,
  ]);

  const publish = useCallback(() => {
    updateBloomMenu(
      {
        theme: {
          ...bloomMenuQuery.data?.theme?.bloom,
          colors: colorUpdates,
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          toast.add({
            label: 'Menu updated!',
            variant: 'success',
          });
          reset({
            primaryHex,
            secondaryHex,
            dealsHex,
            headerHex,
            indicaHex,
            hybridHex,
            sativaHex,
            cbdHex,
          });
        },
      }
    );
  }, [
    primaryHex,
    secondaryHex,
    dealsHex,
    headerHex,
    cbdHex,
    hybridHex,
    indicaHex,
    sativaHex,
    bloomMenuQuery,
    updateBloomMenu,
    colorUpdates,
    reset,
  ]);

  const preview = useCallback(() => {
    updateBloomMenu(
      {
        theme_draft: {
          ...bloomMenuQuery.data?.theme?.bloom,
          colors: {
            primary: {
              main: primaryHex,
              contrast: primaryContrast,
            },
            secondary: {
              main: secondaryHex,
              contrast: secondaryTextContrast,
            },
            header: {
              main: headerHex,
              contrast: headerTextContrast,
            },
            deals: {
              main: dealsHex,
              contrast: dealsTextContrast,
            },
            text: {
              primary_inverse: inverseText,
            },
            lineage: {
              cbd: {
                main: cbdHex,
                contrast: cbdHexContrast,
              },
              hybrid: {
                main: hybridHex,
                contrast: hybridTextContrast,
              },
              indica: {
                main: indicaHex,
                contrast: indicaTextContrast,
              },
              sativa: {
                main: sativaHex,
                contrast: sativaHexContrast,
              },
            },
          },
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          if (!bloomMenuQuery.data?.menu_url) {
            return;
          }
          const previewUrl = new URL(bloomMenuQuery.data.menu_url);
          previewUrl.searchParams.set('draft', 'true');
          const newWindow = window.open(
            previewUrl,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) {
            newWindow.opener = null;
          }
        },
      }
    );
  }, [
    primaryHex,
    secondaryHex,
    dealsHex,
    headerHex,
    cbdHex,
    hybridHex,
    indicaHex,
    sativaHex,
    bloomMenuQuery,
    updateBloomMenu,
  ]);

  const initializeColors = useCallback(
    (bloomTheme: BloomTheme) => {
      reset({
        primaryHex:
          bloomTheme?.colors?.primary?.main ?? defaultValues.primaryHex,
        secondaryHex:
          bloomTheme?.colors?.secondary?.main ?? defaultValues.secondaryHex,
        dealsHex: bloomTheme?.colors?.deals?.main ?? defaultValues.dealsHex,
        headerHex: bloomTheme?.colors?.header?.main ?? defaultValues.headerHex,
        indicaHex:
          bloomTheme?.colors?.lineage?.indica?.main ?? defaultValues.indicaHex,
        hybridHex:
          bloomTheme?.colors?.lineage?.hybrid?.main ?? defaultValues.hybridHex,
        sativaHex:
          bloomTheme?.colors?.lineage?.sativa?.main ?? defaultValues.sativaHex,
        cbdHex: bloomTheme?.colors?.lineage?.cbd?.main ?? defaultValues.cbdHex,
      });
    },
    [reset]
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      setIsiFrameLoading(true);
      if (typeof data['retailer_url'] === 'string') {
        const normalizedUrl = normalizeUrl(data['retailer_url']);
        setRetailerUrl(normalizedUrl);
        const result = await fetchIframeHeader(normalizedUrl);
        if (!result?.header && result?.ok) {
          setIsIframeError(false);
          return;
        }
        setIsIframeError(true);
        throw new FormValidationError('iframe_form', [
          {
            name: 'retailer_url',
            message:
              "Looks like this website couldn't load. In the meantime, you can use the eyedropper to grab colors from other browser windows and software.",
          },
        ]);
      }
    },
    [fetchIframeHeader]
  );

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      initializeColors(bloomMenuQuery.data?.theme?.bloom || {});
    }
  }, [bloomMenuQuery.isSuccess, initializeColors]);

  const [discard, setDiscard] = useState(false);

  const onRequestClose = useCallback(() => {
    if (!isEmpty(dirtyFields)) {
      setDiscard(true);
    } else {
      goBack();
    }
  }, [dirtyFields, goBack]);

  return (
    <>
      <Flex flexDirection="column" minHeight="100vh">
        <form>
          <BorderedFlex p={16} gap={16} flexDirection="row" alignItems="center">
            <ChevronLeftIcon
              onClick={() => onRequestClose()}
              altText="Go back"
            />
            <Typography variant="header-bold" branded>
              Colors
            </Typography>

            <Flex ml="auto" flexDirection="row">
              <Button
                ml="auto"
                mr={16}
                label="Preview"
                type="button"
                variant="secondary"
                onClick={preview}
              />
              <Button
                ml="auto"
                label="Publish"
                type="button"
                variant="primary"
                onClick={publish}
              />
            </Flex>
          </BorderedFlex>

          <Flex>
            <StyledTabs value={selectedTab}>
              <StyledTab
                label="Color scheme"
                selected={selectedTab === 'color-scheme'}
                value="color-scheme"
                onClick={() => setSelectedTab('color-scheme')}
              />
              <StyledTab
                label="Lineage tags"
                selected={selectedTab === 'lineage-tags'}
                value="lineage-tags"
                onClick={() => setSelectedTab('lineage-tags')}
              />
            </StyledTabs>
          </Flex>

          {selectedTab === 'color-scheme' && (
            <BorderedFlex justifyContent="center" py={40}>
              <Flex maxWidth={660} mx={16} grow={1}>
                <Controller
                  name="primaryHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="Primary"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="secondaryHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="Secondary"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="dealsHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="Deals"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="headerHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="Header (optional)"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Flex>
            </BorderedFlex>
          )}

          {selectedTab === 'lineage-tags' && (
            <BorderedFlex justifyContent="center" py={40}>
              <Flex maxWidth={660} mx={16} grow={1}>
                <Controller
                  name="hybridHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="Hybrid"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="indicaHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="Indica"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="sativaHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="Sativa"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <Controller
                  name="cbdHex"
                  control={control}
                  render={({ field }) => (
                    <ColorPicker
                      label="CBD"
                      onChange={(hex) => {
                        field.onChange(hex);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Flex>
            </BorderedFlex>
          )}
        </form>

        <BorderedFlex py={40} justifyContent="center">
          <Form
            maxWidth={660}
            width="100%"
            name="iframe_form"
            onSubmit={onSubmit}
          >
            <StyledTextField
              startIcon={<Typography>https://</Typography>}
              label="Main website source"
              name="retailer_url"
              onFocus={() => setIsIframeError(false)}
              onBlur={() => setIsIframeError(false)}
              onChange={() => setIsIframeError(false)}
              helperText={
                isIframeError
                  ? undefined
                  : 'Provide the URL of your main website to directly match the color palette'
              }
              defaultValue={retailerUrl}
              endIcon={<Typography color="grays-mid">URL</Typography>}
              error={isIframeError}
            />
          </Form>
        </BorderedFlex>

        <BorderedFlex
          alignItems="center"
          flexDirection="column"
          grow={2}
          background="grays-ultralight"
          p={64}
        >
          {!retailerUrl && (
            <Card>
              <Card.Content>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  px={40}
                  py={40}
                >
                  <Typography branded variant="header-bold">
                    Don't know your brand colors?
                  </Typography>
                  <Typography>
                    Enter the URL for your main website above to directly
                    reference the colors.
                  </Typography>
                </Flex>
              </Card.Content>
            </Card>
          )}

          {retailerUrl && (
            <Card width="100%">
              <Card.Content>
                {isiFrameLoading && <Loading color="black" />}

                <iframe
                  onLoad={() => setIsiFrameLoading(false)}
                  src={retailerUrl}
                  title="Preview"
                  width="100%"
                  height="720px"
                  style={{ border: 0 }}
                />
              </Card.Content>
            </Card>
          )}
        </BorderedFlex>
      </Flex>
      <ConfirmChangeModal
        open={discard}
        title={
          <Typography variant="header-bold" mb={8}>
            Discard changes?
          </Typography>
        }
        onConfirm={() => goBack()}
        onCancel={() => setDiscard(false)}
        confirmButtonVariant="destructive"
        confirmLabel="Discard"
        subtitle="You have made changes without publishing. If you continue, you will lose your progress."
      />
    </>
  );
};

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import type { SpecialRulesV2 } from '@jane/business-admin/types';
import { buildRules } from '@jane/business-admin/util';
import { RULE_OPTIONS } from '@jane/shared/util';

export const MAX_MESSAGE_LENGTH = 300;
export const FORM_FIELD_DEFAULT_MARGIN = 24;

export enum SPECIAL_TYPES {
  BulkPricing = 'bulk_pricing',
  Bundle = 'bundle',
  CartTotal = 'cart_total',
  Product = 'product',
  QualifiedGroup = 'qualified_group',
}

export const SHOW_PROMO_CODE_SPECIAL_TYPES = [
  SPECIAL_TYPES.BulkPricing,
  SPECIAL_TYPES.CartTotal,
  SPECIAL_TYPES.Product,
];

export enum ConditionType {
  Brands = 'brands',
  Categories = 'categories',
  ExcludeBrands = 'exclude brands',
  ExcludeCategories = 'exclude categories',
  ExcludeProducts = 'exclude products',
  Lineage = 'lineage',
  Price = 'price',
  Products = 'products',
  Weight = 'weight',
}

/**
 * Used when rendering the form, will return which "Apply To" radio selection should be set based on the special rules.
 * @param rules SpecialRulesV2
 * @returns One of the RULE_OPTIONS values
 */
export const getApplyToSelection = (rules?: SpecialRulesV2 | null) => {
  if (!rules) {
    return RULE_OPTIONS[0].value;
  }

  const excludes = rules['excludes'] || [];
  const includes = rules['includes'] || [];

  const hasExclusions = !isEmpty(excludes);
  const hasInclusions = !isEmpty(includes);
  const firstExclusion = excludes[0] || [];
  const firstInclusion = includes[0] || [];

  const hasAdvancedInclusions =
    !isEmpty(firstInclusion['kinds']) ||
    !isEmpty(firstInclusion['brands']) ||
    !isEmpty(firstInclusion['lineages']);
  const hasAdvancedExclusions =
    !isEmpty(firstExclusion['kinds']) || !isEmpty(firstExclusion['brands']);

  // Has product inclusions, should be INDIVIDUAL
  if (hasInclusions && !isEmpty(firstInclusion['product_ids'])) {
    return RULE_OPTIONS[1].value;
    // Has category or brands or lineage inclusions, or category or brands exclusions, should be CONDITIONS
  } else if (
    (hasInclusions && hasAdvancedInclusions) ||
    (hasExclusions && hasAdvancedExclusions)
  ) {
    return RULE_OPTIONS[2].value;
  } else {
    return RULE_OPTIONS[0].value;
  }
};

/**
 * Used when saving the form, will return a rules object based on which "Apply To" selection was made.
 * @param rules SpecialRulesV2
 * @param applyTo One of the RULE_OPTIONS values
 * @returns The rules that should be used when saving
 */
export const parseRulesFromApplyTo = (
  rules: SpecialRulesV2,
  applyTo: 'ALL' | 'INDIVIDUAL' | 'CONDITIONS'
) => {
  if (applyTo === 'CONDITIONS') {
    return rules;
  }

  if (applyTo === 'ALL') {
    const includes = pick(get(rules, 'includes.0'), ['weights']);
    const excludes = pick(get(rules, 'excludes.0'), ['product_ids']);

    return {
      product_threshold: rules.product_threshold,
      ...buildRules({
        excludeProduct: excludes.product_ids,
        includeWeight: includes.weights,
      }),
    };
  } else if (applyTo === 'INDIVIDUAL') {
    const includes = pick(get(rules, 'includes.0'), ['weights', 'product_ids']);

    return {
      product_threshold: rules.product_threshold,
      ...buildRules({
        includeProduct: includes.product_ids,
        includeWeight: includes.weights,
      }),
    };
  }
  return rules;
};

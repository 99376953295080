import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  useFetchCurrentBloomMenu,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import {
  MAX_IMAGE_SIZE,
  VALID_IMAGE_TYPES,
  businessPaths,
} from '@jane/business-admin/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import { useNavigateBack } from '@jane/shared/hooks';
import {
  AlertIcon,
  Banner,
  Box,
  Button,
  ChevronLeftIcon,
  EditIcon,
  Flex,
  Image,
  Loading,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { ImagePreview } from '@jane/shared/util';

const imgTooLarge = (file: File) =>
  file.size <= MAX_IMAGE_SIZE || 'Your file exceeded the 2mb limit.';
const imgWrongFormat = (file: File) =>
  VALID_IMAGE_TYPES.includes(file.type) ||
  'Your file type is not supported. Try using .jpg or .png files.';

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

const ImageContainer = styled(Box)({
  background:
    'linear-gradient(45deg, #efefef 25%, transparent 25%), linear-gradient(-45deg, #efefef 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #efefef 75%), linear-gradient(-45deg, transparent 75%, #efefef 75%)',
  backgroundSize: '24px 24px',
  backgroundPosition: '0 0, 0 12px, 12px -12px, -12px 0px',
});

const EditButtonContainer = styled.div({
  position: 'absolute',
  top: 16,
  right: 16,
});

export const PlusLogo = () => {
  const goBack = useNavigateBack(businessPaths.plus());
  const toast = useToast();

  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const { mutate: updateBloomMenu, isLoading } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );

  const isUploading = useIsMutating({
    mutationKey: ['update-bloom-menu', bloomMenuQuery.data?.id],
  });

  const [isPublishable, setIsPublishable] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageErrors, setImageErrors] = useState<(string | boolean)[]>();
  const [discard, setDiscard] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (
      !imageUrl &&
      !bloomMenuQuery.isFetching &&
      bloomMenuQuery.data?.logo?.large
    ) {
      setImageUrl(bloomMenuQuery.data?.logo?.large);
    }
  }, [bloomMenuQuery.data?.logo?.large, bloomMenuQuery.isFetching, imageUrl]);

  const onDrop = useCallback(async ([file]: File[]) => {
    const errorMessages = [imgTooLarge(file), imgWrongFormat(file)].filter(
      (result) => result !== true
    );

    if (errorMessages.length > 0) {
      setImageErrors(errorMessages);
    } else {
      const image = await ImagePreview.parse(file);
      setImageUrl(image.url);
      setIsPublishable(true);
      setImageErrors([]);
      setIsDirty(true);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  const publish = useCallback(() => {
    updateBloomMenu(
      {
        logo: imageUrl,
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          setIsPublishable(false);
          toast.add({
            label: 'Menu logo updated!',
            variant: 'success',
          });
          setIsDirty(false);
        },
      }
    );
  }, [imageUrl, toast, updateBloomMenu]);

  const onRequestClose = useCallback(() => {
    if (isDirty) {
      setDiscard(true);
    } else {
      goBack();
    }
  }, [goBack, isDirty]);

  return (
    <>
      <Flex flexDirection="column" minHeight="100vh">
        <BorderedFlex p={16} gap={16} flexDirection="row" alignItems="center">
          <ChevronLeftIcon onClick={() => onRequestClose()} altText="Go back" />
          <Typography variant="header-bold" branded>
            Logo
          </Typography>

          <Flex ml="auto" flexDirection="row">
            <Button
              ml="auto"
              label="Publish"
              type="button"
              variant="primary"
              disabled={!isPublishable || isLoading}
              onClick={publish}
              loading={!!isUploading}
            />
          </Flex>
        </BorderedFlex>

        <Flex
          p={40}
          gap={24}
          flexDirection="column"
          alignItems="center"
          alignContent="center"
        >
          <Typography width={'100%'}>
            Upload your logo that will appear in the header of your homepage.
          </Typography>

          {imageErrors?.map((error, i) => (
            <Banner
              full
              icon={<AlertIcon />}
              label={error}
              variant="error"
              onDismiss={() =>
                setImageErrors(imageErrors.filter((e) => e !== error))
              }
              key={i}
            />
          ))}

          <div {...getRootProps()}>
            {imageUrl ? (
              <ImageContainer
                borderRadius="sm"
                border="grays-light"
                mb={16}
                p={16}
                position="relative"
              >
                <Image
                  key={imageUrl}
                  src={imageUrl}
                  altText="Menu Logo"
                  height="264px"
                  width="264px"
                  borderRadius="16px"
                  objectFit="contain"
                />
                <EditButtonContainer>
                  <Button.Icon
                    label="Edit"
                    variant="secondary"
                    icon={<EditIcon />}
                    onClick={open}
                  />
                  <input {...getInputProps()} />
                </EditButtonContainer>
              </ImageContainer>
            ) : (
              <Box
                p={16}
                mb={16}
                background="grays-ultralight"
                borderRadius="sm"
                height={264}
                width={264}
              >
                {bloomMenuQuery.isLoading ||
                (bloomMenuQuery.isFetching && !imageUrl) ? (
                  <Flex
                    position="relative"
                    justifyContent="center"
                    flexDirection="column"
                    alignContent="center"
                    height="100%"
                  >
                    <Loading />
                  </Flex>
                ) : (
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    alignContent="center"
                    height="100%"
                    justifyContent="center"
                  >
                    <Button
                      mb={24}
                      label="Upload from computer"
                      type="button"
                      variant="secondary"
                      onClick={open}
                    />
                    <input {...getInputProps()} />
                    <Typography color="grays-mid" variant="body" mb={8}>
                      .jpg or .png
                    </Typography>
                    <Typography color="grays-mid" variant="body">
                      2mb file limit
                    </Typography>
                  </Flex>
                )}
              </Box>
            )}
          </div>
        </Flex>
      </Flex>
      <ConfirmChangeModal
        open={discard}
        title={
          <Typography variant="header-bold" mb={8}>
            Discard changes?
          </Typography>
        }
        onConfirm={() => goBack()}
        onCancel={() => setDiscard(false)}
        confirmButtonVariant="destructive"
        confirmLabel="Discard"
        subtitle="You have made changes without publishing. If you continue, you will lose your progress."
      />
    </>
  );
};

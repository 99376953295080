import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import type {
  BloomMenuTemplate,
  BloomMenuTemplateType,
} from '@jane/business-admin/types';
import { tBloomMenuTemplate } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';

import { getCurrentBloomMenu } from './bloomMenus';

const bloomMenuTemplatesUrl = (menuId: number) =>
  `/managers/bloom_menus/${menuId}/templates`;

const bloomMenuTemplateUrl = (
  menuId: number,
  templateType: BloomMenuTemplateType
) => `/managers/bloom_menus/${menuId}/templates/${templateType}`;

const bloomMenuTemplateQueryKey = (
  menuId: number,
  templateType?: BloomMenuTemplateType
) => ['bloom_menus', menuId, 'templates', templateType];

const fetchBloomMenuTemplate = async (
  menuId: number,
  templateType: BloomMenuTemplateType
): Promise<BloomMenuTemplate> => {
  const tPayload = t.type({
    bloom_menu_template: tBloomMenuTemplate,
  });

  const footerUrl = bloomMenuTemplateUrl(menuId, templateType);

  const data = await fetchWithDecode(
    janeApiV2.get(footerUrl),
    tPayload,
    footerUrl
  );

  return data['bloom_menu_template'];
};

export const useFetchBloomMenuTemplate = (
  templateType: BloomMenuTemplateType
) => {
  const menuId = getCurrentBloomMenu();

  return useQuery<BloomMenuTemplate>({
    queryFn: () => fetchBloomMenuTemplate(menuId, templateType),
    queryKey: bloomMenuTemplateQueryKey(menuId, templateType),
    useErrorBoundary: true,
  });
};

export interface UpsertBloomMenuTemplateArgs extends Record<string, unknown> {
  source_content_selector: string;
  source_url: string;
  template_type: string;
}

const upsertBloomMenuTemplate = async (
  updates: UpsertBloomMenuTemplateArgs,
  menuId: number
) => await janeApiV2.put(bloomMenuTemplatesUrl(menuId), updates);

interface UseUpsertBloomMenuTemplateArgs {
  /** Essentially required, but made optional to allow for react-query / react-router providing an undefined param. */
  menuId?: number;
}

export const useUpsertBloomMenuTemplate = ({
  menuId = -1,
}: UseUpsertBloomMenuTemplateArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpsertBloomMenuTemplateArgs) =>
      await upsertBloomMenuTemplate(data, menuId),
    onError: console.error,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: bloomMenuTemplateQueryKey(menuId),
      });
    },
  });
};

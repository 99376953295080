/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const TagFilled16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.414307 2.41431V7.58588C0.414307 8.11631 0.62502 8.62502 1.00009 9.00009L7.00009 15.0001C7.78114 15.7811 9.04747 15.7811 9.82852 15.0001L15.0001 9.82852C15.7811 9.04747 15.7811 7.78114 15.0001 7.00009L9.00009 1.00009C8.62502 0.62502 8.11631 0.414307 7.58588 0.414307H2.41431C1.30974 0.414307 0.414307 1.30974 0.414307 2.41431ZM4.41431 6.41431C5.51888 6.41431 6.41431 5.51888 6.41431 4.41431C6.41431 3.30974 5.51888 2.41431 4.41431 2.41431C3.30974 2.41431 2.41431 3.30974 2.41431 4.41431C2.41431 5.51888 3.30974 6.41431 4.41431 6.41431Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  sm: TagFilled16,
  md: TagFilled16,
  lg: TagFilled16,
  xl: TagFilled16,
  xxl: TagFilled16,
  xxxl: TagFilled16,
};

export const TagFilledIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'tag-filled-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import type { FlexItemProperties, FlexProperties } from '../../styling';
import type { BoxProps } from '../box';
import { StyledFlex } from './flex.styles';

export interface FlexProps
  extends FlexProperties,
    FlexItemProperties,
    BoxProps {}

/**
 * The `Flex` component is a container component that extends the `Box`
 * component with `flex` layout properties to allow for easy use of any `flex`
 * parent and child related properties.
 * */
export const Flex = ({
  accessibilityComponentName = 'Flex',
  children,
  ...props
}: FlexProps) => {
  return (
    <StyledFlex
      accessibilityComponentName={accessibilityComponentName}
      {...props}
    >
      {children}
    </StyledFlex>
  );
};

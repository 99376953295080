/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Pizza24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.6529 1C22.2118 1 22.6529 1.46179 22.6529 2.02074V21.8047C22.6529 23.3443 20.9862 24.3065 19.6529 23.5367L2.75304 13.7796C1.78827 13.2226 1.45818 11.9789 2.10539 11.0721C6.45744 4.97506 13.591 1 21.6529 1Z"
      fill="#FFC220"
    />
    <circle cx={17.1528} cy={9.5} r={2.5} fill="#FF6334" />
    <circle cx={12.1528} cy={14.5} r={2.5} fill="#FF6334" />
    <path
      d="M22.653 2.02073C22.653 1.46179 22.2119 1 21.653 1C13.591 1 6.4575 4.97506 2.10545 11.0721C1.45824 11.9789 1.78833 13.2226 2.7531 13.7796L4.46494 14.7679C7.95484 8.91813 14.3463 5 21.653 5C21.9883 5 22.3217 5.00825 22.653 5.02457V2.02073Z"
      fill="#FFC220"
    />
    <path
      d="M22.653 2.02073C22.653 1.46179 22.2119 1 21.653 1C13.591 1 6.4575 4.97506 2.10545 11.0721C1.45824 11.9789 1.78833 13.2226 2.7531 13.7796L4.46494 14.7679C7.95484 8.91813 14.3463 5 21.653 5C21.9883 5 22.3217 5.00825 22.653 5.02457V2.02073Z"
      fill="black"
      fillOpacity={0.2}
    />
    <circle cx={20.1528} cy={16.5} r={2.5} fill="#FF6334" />
    <path
      d="M19.983 23.665C20.6733 22.4693 20.2636 20.9403 19.0679 20.25C17.8722 19.5596 16.3432 19.9693 15.6528 21.165L19.983 23.665Z"
      fill="#FF6334"
    />
  </svg>
);

const SIZE_MAP = {
  md: Pizza24,
  sm: Pizza24,
  lg: Pizza24,
  xl: Pizza24,
  xxl: Pizza24,
  xxxl: Pizza24,
};

export const PizzaIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pizza-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};

import styled from '@emotion/styled';

import chevronDown from '../../../../assets/icons/dynamic/chevron-down-24.svg';
import { shadows, spacing } from '../../../../styling';
import type { StyledFieldProps } from '../field.types';

export const StyledSelectContainer = styled.div({
  position: 'relative',
});

interface Props extends StyledFieldProps {
  disabled: boolean;
}

export const StyledSelect = styled.select<Props>(
  {
    WebkitAppearance: 'none',
    appearance: 'none',
    backgroundImage: `url(${chevronDown})`,
    backgroundPosition: 'calc(100% - 10px) center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '24px',
    boxShadow: shadows.drop,
    position: 'relative',
    width: '100%',
    ...spacing({ pl: 16, pr: 24, py: 12 }),
  },
  ({ theme, disabled, radiusSize }) => ({
    '&:active:not(:disabled)': {
      border: `1px solid ${theme.colors.primary.main}`,
    },
    '&:focus, &:focus-visible': {
      outline: 'none',
    },
    '&:focus-visible': {
      border: `1px solid ${theme.colors.primary.main}`,
    },
    backgroundColor: theme.colors.grays.white,
    border: `1px solid ${theme.colors.grays.light}`,
    borderRadius: theme.borderRadius[radiusSize],
    cursor: disabled ? 'default' : 'pointer',
  })
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xbu79UsjW0KjX7gNc4QQ {\n  margin-bottom: var(--grid-container-margin-bottom);\n  margin-left: var(--grid-container-margin-left);\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-v2/src/lib/components/grid/gridContainer/gridContainer.module.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,8CAA8C;AAChD","sourcesContent":[".gridContainer {\n  margin-bottom: var(--grid-container-margin-bottom);\n  margin-left: var(--grid-container-margin-left);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": "Xbu79UsjW0KjX7gNc4QQ"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import { Flex } from '../flex';
import { DismissIcon } from '../icon';
import { Typography } from '../typography';
import { StyledBanner, colorMap } from './banner.styles';
import type { BannerProps } from './banner.types';

/**
 * Banner displays a short, important message with different variants depending on the type of alert.
 */

export function Banner({
  actions,
  className,
  'data-testid': testId,
  full = false,
  icon,
  id,
  label,
  onDismiss,
  style,
  typography,
  variant = 'info',
  ...props
}: BannerProps) {
  const isText = typeof label === 'string';

  return (
    <StyledBanner
      className={className}
      data-testid={testId}
      id={id}
      variant={variant}
      full={full}
      style={style}
      {...props}
    >
      <Flex>
        {icon && (
          <div>
            {React.cloneElement(icon, {
              color: colorMap[variant],
              mr: 16,
              ...icon.props,
            })}
          </div>
        )}
        <Typography
          as={isText ? 'p' : 'div'}
          color={colorMap[variant]}
          variant={
            typography ? typography : variant === 'info' ? 'body' : 'body-bold'
          }
        >
          {label}
        </Typography>
      </Flex>
      {actions}
      {onDismiss && (
        <Flex>
          <DismissIcon
            ml={16}
            onClick={onDismiss}
            color={colorMap[variant]}
            altText="dismiss banner"
          />
        </Flex>
      )}
    </StyledBanner>
  );
}

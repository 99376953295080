/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Copy24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 18V20C18 21.1046 17.1046 22 16 22H4C2.89543 22 2 21.1046 2 20V8C2 6.89543 2.89543 6 4 6H6V4C6 2.89543 6.89543 2 8 2H20C21.1046 2 22 2.89543 22 4V16C22 17.1046 21.1046 18 20 18H18ZM20 4H8V6H16C17.1046 6 18 6.89543 18 8V16H20V4ZM4 8H16V20H4V8Z"
      fill="#0E0E0E"
    />
  </svg>
);

const SIZE_MAP = {
  md: Copy24,
  sm: Copy24,
  lg: Copy24,
  xl: Copy24,
  xxl: Copy24,
  xxxl: Copy24,
};

export const CopyIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'copy-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Tag as TagV1 } from '@jane/shared/reefer-v1';
import { Tag as TagV2 } from '@jane/shared/reefer-v2';
import type { TagProps } from '@jane/shared/reefer-v2';

export const Tag = (props: TagProps) => {
  const reeferMigration = useFlag(FLAGS.reeferMigration);

  if (reeferMigration) {
    return <TagV2 {...props} />;
  }
  return <TagV1 {...props} />;
};

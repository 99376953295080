import debounce from 'lodash/debounce';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useValidatePromoCode } from '@jane/business-admin/data-access';
import {
  SpecialsModalContext,
  StoreDetailsContext,
} from '@jane/business-admin/providers';
import {
  BODY_LINE_HEIGHT,
  MODAL_CARD_WIDTH,
  businessPaths,
} from '@jane/business-admin/util';
import {
  Banner,
  Card,
  ErrorIcon,
  Flex,
  Form,
  Link,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';

import {
  FORM_FIELD_DEFAULT_MARGIN,
  SHOW_PROMO_CODE_SPECIAL_TYPES,
} from './form';

const PROMO_CODE_MAX_LENGTH = 40;

interface Props {
  isCreateMode: boolean;
  isDuplicateMode: boolean;
  isLoading: boolean;
}
export const PromoCodeCard = ({
  isCreateMode,
  isDuplicateMode,
  isLoading,
}: Props) => {
  const { storeId } = useContext(StoreDetailsContext);
  const { special_id: specialId = '' } = useParams<'special_id'>();
  const {
    posSyncMap: { isJanePosSynced },
  } = useContext(SpecialsModalContext);

  const { watch, setError, clearErrors } = useFormContext();
  const switchEnabled = watch('display.promo_code_enabled');
  const specialTypeWatch = watch('special_type');

  const { mutateAsync } = useValidatePromoCode(storeId);
  const [validationError, setValidationError] = useState<string>();
  const [existingSpecial, setExistingSpecial] = useState<{
    id: number;
    title: string;
  }>();

  const resetErrorState = () => {
    clearErrors('promo_code');
    setValidationError(undefined);
    setExistingSpecial(undefined);
  };

  const validate = useMemo(
    () =>
      debounce(
        async (promoCode) => {
          resetErrorState();

          try {
            await mutateAsync({
              promoCode,
              specialId:
                isDuplicateMode || isCreateMode ? undefined : specialId,
            });
          } catch (error: any) {
            if (error?.response?.status === 409) {
              const body = await error?.response.json();
              setExistingSpecial(body.existing_special);

              setValidationError(
                'This promo code is already in use. Remove this code or assign a new code to continue.'
              );
              setError('promo_code', {
                message:
                  'This promo code is already in use. Remove this code or assign a new code to continue.',
              });
            }
          }
        },
        500,
        {
          // On initial load, we want to check validation right away
          // After that, we want to check validation when user is done typing
          leading: isLoading,
          trailing: !isLoading,
        }
      ),
    [isLoading]
  );

  useEffect(() => {
    // Reset validations if promo code is disabled
    if (!switchEnabled) {
      resetErrorState();
    }
  }, [switchEnabled]);

  const validateUniquePromoCode = (promo_code: string) => {
    if (promo_code && !isJanePosSynced) {
      validate(promo_code);
    } else {
      resetErrorState();
    }
  };

  // when creating: only show once special_type has been selected, otherwise it will show when nothing is selected
  // when editing: only hide once special_type has been set, otherwise it will lose existing special promo_code
  if (
    (isCreateMode && !specialTypeWatch) ||
    (specialTypeWatch &&
      !SHOW_PROMO_CODE_SPECIAL_TYPES.includes(specialTypeWatch))
  ) {
    return null;
  }

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <>
            <Flex mb={12}>
              <Typography variant="header-bold">Promo Code</Typography>
              <Typography ml={4} variant="header" color="grays-mid">
                (Optional)
              </Typography>
            </Flex>
            {switchEnabled && existingSpecial && (
              <Banner
                mb={20}
                variant="error"
                label={
                  <Flex>
                    <Typography variant="body-bold" color="error-dark">
                      This promo code is in use by{' '}
                      <Link
                        color="error-dark"
                        href={businessPaths.storeSpecialDetail(
                          storeId,
                          specialId
                        )}
                      >
                        {existingSpecial.title || ''}
                      </Link>
                    </Typography>
                  </Flex>
                }
                icon={<ErrorIcon />}
              />
            )}
            {isLoading ? (
              <Skeleton animate>
                <Skeleton.Bone width="30%" height={BODY_LINE_HEIGHT} />
              </Skeleton>
            ) : (
              <>
                <Form.SwitchField
                  label="Enable promo code"
                  name="display.promo_code_enabled"
                  mb={switchEnabled ? 40 : 0}
                  disabled={isJanePosSynced}
                />
                {switchEnabled && (
                  <>
                    <Flex
                      alignItems={'center'}
                      gap={24}
                      mb={FORM_FIELD_DEFAULT_MARGIN}
                    >
                      <Form.TextField
                        name="promo_code"
                        label="Promo code"
                        width="100%"
                        maxLength={PROMO_CODE_MAX_LENGTH}
                        disabled={isJanePosSynced}
                        onChange={validateUniquePromoCode}
                        validate={() => validationError || true}
                        // Don't remove field from formData when switch is disabled, otherwise it will lose any changes to these fields
                        shouldUnregister={false}
                      />
                      <Form.CheckboxField
                        mt={32}
                        width="100%"
                        name="multiple_use_promo_code"
                        label="Customers can use this promo code multiple times"
                        disabled={isJanePosSynced}
                        // Don't remove field from formData when switch is disabled, otherwise it will lose any changes to these fields
                        shouldUnregister={false}
                      />
                    </Flex>
                    <Flex gap={24} mb={FORM_FIELD_DEFAULT_MARGIN}>
                      <Form.NumberField
                        width="100%"
                        name="promo_code_max_number_of_uses"
                        label="Maximum number of uses across all customers (optional)"
                        helperText="Leave blank to not restrict number of uses."
                        // TODO: This validation doesn't work for the value 0
                        min={0}
                        disabled={isJanePosSynced}
                        // Don't remove field from formData when switch is disabled, otherwise it will lose any changes to these fields
                        shouldUnregister={false}
                      />
                      {/* Needed to get the spacing right */}
                      <Flex width="100%">&nbsp;</Flex>
                    </Flex>
                  </>
                )}
              </>
            )}
          </>
        </Flex>
      </Card.Content>
    </Card>
  );
};

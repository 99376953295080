import styled from '@emotion/styled';
import {
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  type BulkUpdateSpecialParams,
  useBulkUpdateSpecial,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ModalNames } from '@jane/business-admin/util';
import { Banner, Button, Flex, Modal, useToast } from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../ConfirmWrapperWithTracking';
import { ArchiveWithConfirm } from '../ArchiveWithConfirm';
import { StackingSettingsModal } from './StackingSettingsModal';

const ModalHeaderWrapper = styled.div(({ theme }) => ({
  '& > [data-id="modal-header"]': {
    backgroundColor: theme.colors.brand.purple.main,
    color: theme.colors.text.inverse,
    '& h2': {
      color: 'inherit',
    },
  },
}));

export const BulkEditModal = ({
  bulkSelectedSpecials,
  children,
  onClose,
}: {
  bulkSelectedSpecials: number[];
  children: ReactNode;
  onClose: () => void;
}) => {
  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const [showBulkError, setShowBulkError] = useState(false);

  const catchSubmitErrors = useCatchErrorsWithManager('');
  const {
    mutateAsync: bulkUpdate,
    isSuccess: bulkUpdateSuccess,
    isLoading: bulkUpdateLoading,
  } = useBulkUpdateSpecial(storeId);

  useEffect(() => {
    if (bulkUpdateSuccess) {
      onClose();
      toast.add({
        label: 'Bulk update succeeded!',
        variant: 'success',
      });
    }
  }, [bulkUpdateSuccess]);

  const numberOfSpecials = useMemo(
    () => bulkSelectedSpecials.length,
    [bulkSelectedSpecials]
  );

  const {
    modalOpen: stackingSettingsModalOpen,
    openModal: setStackingSettingsModalOpen,
    closeModal: setStackingSettingsModalClose,
  } = useModalActionsWithTracking(ModalNames.BulkEditStackingSettings);

  const onBulkAction = (action: 'archive' | 'enable' | 'disable') => {
    setShowBulkError(false);
    const params: BulkUpdateSpecialParams = {
      special_ids: bulkSelectedSpecials,
    };
    switch (action) {
      case 'archive':
        params['archive'] = true;
        break;
      case 'enable':
        params['attributes'] = {
          enabled: true,
        };
        break;
      case 'disable':
        params['attributes'] = {
          enabled: false,
        };
        break;
      default:
        console.error('Bulk action not found');
    }
    catchSubmitErrors({
      submitMethod: () => bulkUpdate(params),
      requestData: params,
      callback: () => {
        setShowBulkError(true);
      },
      onValidationError: () => null,
    });
  };

  return (
    <ConfirmWrapperWithTracking
      open
      setOpen={onClose}
      hasChanges={numberOfSpecials > 0}
      variant="full-screen"
      background="text-inverse"
      modalName={ModalNames.BulkEditSpecials}
    >
      <>
        <ModalHeaderWrapper>
          <Modal.Header
            title="Select specials"
            actions={
              <Flex gap={16}>
                <ArchiveWithConfirm
                  onArchiveSpecial={() => onBulkAction('archive')}
                  bulkMode={true}
                  archiveButton={(onArchiveClick) => (
                    <Button
                      label={`Archive ${
                        numberOfSpecials ? `(${numberOfSpecials})` : ''
                      }`}
                      variant="secondary"
                      disabled={numberOfSpecials === 0 || bulkUpdateLoading}
                      onClick={() => onArchiveClick()}
                    />
                  )}
                />
                {status !== 'disabled' && (
                  <Button
                    label={`Disable ${
                      numberOfSpecials ? `(${numberOfSpecials})` : ''
                    }`}
                    variant="secondary"
                    disabled={numberOfSpecials === 0 || bulkUpdateLoading}
                    onClick={() => onBulkAction('disable')}
                  />
                )}
                {status === 'disabled' && (
                  <Button
                    label={`Enable ${
                      numberOfSpecials ? `(${numberOfSpecials})` : ''
                    }`}
                    variant="secondary"
                    disabled={numberOfSpecials === 0 || bulkUpdateLoading}
                    onClick={() => onBulkAction('enable')}
                  />
                )}
                <Button
                  label={`Manage settings ${
                    numberOfSpecials ? `(${numberOfSpecials})` : ''
                  }`}
                  variant="secondary"
                  disabled={numberOfSpecials === 0 || bulkUpdateLoading}
                  onClick={() => setStackingSettingsModalOpen()}
                />
              </Flex>
            }
          />
        </ModalHeaderWrapper>
        <Modal.Content padding={false}>
          {showBulkError && (
            <Flex width="93vw" mb={0} ml={48} mt={48}>
              <Banner
                full
                variant="error"
                label="Error bulk updating. Please try again."
              />
            </Flex>
          )}
          {children}
          {stackingSettingsModalOpen && (
            <StackingSettingsModal
              bulkSelectedSpecials={bulkSelectedSpecials}
              onClose={setStackingSettingsModalClose}
              closeBulkEditModal={onClose}
            />
          )}
        </Modal.Content>
      </>
    </ConfirmWrapperWithTracking>
  );
};

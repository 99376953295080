import type { CSSObject } from '@emotion/styled';
import styled from '@emotion/styled';

import { spacing } from '../../../styling';
import type { ReeferTheme } from '../../../types';

type ButtonToggleVariant = 'primary' | 'inverse' | 'tertiary';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  full: boolean;
  selected: boolean;
  variant: ButtonToggleVariant;
}

export const variants = (
  theme: ReeferTheme,
  selected: boolean,
  disabled?: boolean
): { [key in ButtonToggleVariant]: CSSObject } => {
  const { colors } = theme;
  return {
    inverse: {
      color: disabled ? colors.grays.ultralight : colors.grays.white,
      ...(selected && {
        border: `2px solid ${colors.grays.white}`,
      }),
      '&:hover, &:focus-visible': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        outline: 'none',
      },
    },
    primary: {
      color: disabled ? colors.grays.dark : colors.grays.black,
      ...(selected && {
        border: `2px solid ${colors.primary.main}`,
      }),
      '&:hover, &:focus-visible': {
        backgroundColor: colors.grays.hover,
        outline: 'none',
      },
    },
    tertiary: {
      backgroundColor: colors.grays.ultralight,
      color: disabled ? colors.grays.dark : colors.grays.black,
      ...(selected && {
        border: `2px solid ${colors.primary.main}`,
      }),
      '&:hover, &:focus-visible': {
        backgroundColor: colors.grays.hover,
        outline: 'none',
      },
    },
  };
};

export const StyledButton = styled.button<ButtonProps>(
  ({ disabled, full, selected, theme, variant }) => ({
    background: 'transparent',
    border: '2px solid transparent',
    borderRadius: theme.borderRadius.lg,
    cursor: 'pointer',
    height: '48px',
    outline: 'none',
    textAlign: 'center',
    width: full ? '100%' : 'auto',
    ...variants(theme, selected, disabled)[variant],
    ...spacing({ mr: 16, px: 16 }),
    ...(selected && {
      pointerEvents: 'none',
    }),
    ...(disabled && {
      opacity: '0.3',
      pointerEvents: 'none',
    }),
    ':last-of-type': spacing({ mr: 0 }),
  })
);

import type { ReeferTheme } from '../types/theme';
import { borderRadius } from './borderRadius';
import { colors } from './colors';
import { components } from './components';

export const BASE_CONFIG: ReeferTheme = {
  borderRadius,
  colors: {
    background: colors.grays.white,
    brand: colors.brand,
    error: colors.system.negative,
    grays: colors.grays,
    info: colors.brand.sunset,
    primary: colors.brand.purple,
    secondary: colors.brand.gold,
    success: colors.system.positive,
    system: colors.system,
    tertiary: colors.tertiary,
    text: {
      info: colors.grays.white,
      inverse: colors.grays.white,
      light: colors.grays.mid,
      main: colors.grays.black,
    },
    transparent: {
      black: {
        fifty: colors.transparent.black.fifty,
        five: colors.transparent.black.five,
        ten: colors.transparent.black.ten,
        twenty: colors.transparent.black.twenty,
      },
      white: {
        ten: colors.transparent.white.ten,
      },
    },
  },
  components,
};

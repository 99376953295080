import {
  BoostConfigCard,
  SubdomainCard,
} from '@jane/business-admin/components';
import { useManager } from '@jane/business-admin/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsSubdomain = () => {
  const { data: currentManager } = useManager();
  const showBoostSubdomainOption = useFlag(FLAGS.scShowBoostSubdomainOption);

  return (
    <Flex flexDirection="column">
      <BoostConfigCard />
      {!showBoostSubdomainOption || currentManager?.manager?.internal ? (
        <SubdomainCard />
      ) : null}
    </Flex>
  );
};

import type {
  BrandSpecialPrices,
  MenuProduct,
  PriceId,
} from '@jane/shared/models';

import { brandSpecialHasOneDiscount, getFirstBrandSpecial } from './util';

/* 
This function returns a label for a brand discount
It can be passed either a menuProduct or a brandSpecials object, but not both.
If the menuProduct does not have a brand discount, then it will return null, and therefor should suffice as an equality check.
If you want to make sure a specific priceId exists within a brand special before getting its label,
use the getPriceIdBrandSpecial helper to return the whole special for that price id, if it has one.
If you want to extract a generic tag label for multiple priceIds, pass only the brand special.
It will return the discount amount if all the priceIds share a discount id, 
otherwise it will return the fallback label.
*/

type Props = {
  brandSpecials?: BrandSpecialPrices;
  fallbackLabel?: string;
  menuProduct?: MenuProduct;
  priceId?: PriceId;
} & (
  | {
      brandSpecials: BrandSpecialPrices;
      menuProduct?: never;
    }
  | { brandSpecials?: never; menuProduct: MenuProduct }
);

export const getJaneGoldTagLabel = ({
  fallbackLabel = 'Cash Back',
  brandSpecials,
  menuProduct,
  priceId,
}: Props) => {
  if (!menuProduct && !brandSpecials) return null;

  let brandSpecialsPrices = brandSpecials;

  if (menuProduct) {
    if (!menuProduct.brand_special_prices || !menuProduct.has_brand_discount)
      return null;

    brandSpecialsPrices = menuProduct.brand_special_prices;
  }

  if (!priceId)
    return getLabelForMultiplePriceIds(
      brandSpecialsPrices as BrandSpecialPrices,
      fallbackLabel
    );

  const priceIdSpecial = (brandSpecialsPrices as BrandSpecialPrices)[priceId];

  return priceIdSpecial?.discount_label || fallbackLabel;
};

const getLabelForMultiplePriceIds = (
  brandSpecials: BrandSpecialPrices,
  fallbackLabel: string
) => {
  const hasOneDiscountId = brandSpecialHasOneDiscount(brandSpecials);

  if (hasOneDiscountId)
    return getFirstBrandSpecial(brandSpecials)?.discount_label || fallbackLabel;

  return fallbackLabel;
};

import styled from '@emotion/styled';
import snakeCase from 'lodash/snakeCase';

import { Card, Flex, Typography } from '@jane/shared/reefer';
import type { LabeledOption } from '@jane/shared/types';

import { EyedropperIcon } from './Eyedropper';

export interface ColorPickerProps extends LabeledOption {
  onChange: (hex: string) => void;
}

const ColorPreview = styled(Card.Content)<{ hexColor: string }>(
  ({ hexColor }) => ({
    backgroundColor: hexColor,
    minHeight: '69px',
    minWidth: '147px',
  })
);

const HexField = styled('input')({
  borderStyle: 'none',
});

export const ColorPicker = ({ label, onChange, value }: ColorPickerProps) => {
  const inputId = snakeCase(label);
  return (
    <label htmlFor={inputId}>
      <Flex alignItems="center" flexDirection="column" mr={24}>
        <Typography variant="mini-bold" color="grays-mid">
          {label}
        </Typography>
        <Card.Group>
          <ColorPreview hexColor={value}>&nbsp;</ColorPreview>
          <Card.Content>
            <Flex background="grays-white" justifyContent="space-between">
              <HexField
                type="text"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                value={value}
                maxLength={7}
                size={7}
              />
              <EyedropperIcon />
            </Flex>
            <input
              id={inputId}
              type="color"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              style={{
                position: 'absolute',
                left: '-20%', // somewhat centers the native color picker beneath
                visibility: 'hidden',
              }}
            />
          </Card.Content>
        </Card.Group>
      </Flex>
    </label>
  );
};

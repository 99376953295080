import styled from '@emotion/styled';

import { shadows } from '../../../../styling';

export const StyledInput = styled.input(
  {
    appearance: 'none',
    cursor: 'pointer',
    height: '24px',
    outline: 'none',
    position: 'relative',
    width: '24px',
    zIndex: '1',
  },
  ({ theme: { colors } }) => ({
    '&:disabled': {
      cursor: 'default',
    },
    '&:focus-visible': {
      '&:after': {
        border: `1px solid ${colors.primary.main}`,
        bottom: '-3px',
        backgroundColor: 'transparent',
        content: `''`,
        borderRadius: '50%',
        display: 'inherit',
        left: '-3px',
        position: 'absolute',
        right: '-3px',
        top: '-3px',
      },
    },
  })
);

export const StyledRadio = styled.div(
  {
    ':after': {
      borderRadius: '50%',
      content: '""',
      display: 'block',
      height: '12px',
      'input:checked + &': {},
      left: '6px',
      position: 'absolute',
      top: '6px',
      width: '12px',
    },
    borderRadius: '50%',
    height: '24px',
    'input:checked + &': {
      border: 'none',
      boxShadow: 'none',
    },
    position: 'absolute',
    top: '0',
    width: '24px',
  },
  ({ theme: { colors } }) => ({
    ':after': {
      'input:checked + &': {
        backgroundColor: colors.grays.white,
      },
    },
    backgroundColor: colors.grays.white,
    border: `2px solid ${colors.grays.mid}`,
    boxShadow: shadows.inner,
    'input:checked + &': {
      backgroundColor: colors.primary.main,
    },
  })
);

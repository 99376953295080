import { useContext } from 'react';

import { ModalContext } from '../modal';
import type { ModalFooterProps } from '../modal.types';
import { StyledModalFooter } from './modalFooter.styles';

export function ModalFooter({
  children,
  className,
  id,
  'data-testid': testId,
  style,
}: ModalFooterProps) {
  const { variant } = useContext(ModalContext);
  return (
    <StyledModalFooter
      className={className}
      data-id="modal-footer"
      data-testid={testId}
      id={id}
      style={style}
      variant={variant}
    >
      {children}
    </StyledModalFooter>
  );
}

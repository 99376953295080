import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';

import type { TypographyProps } from '.';
import { mediaQueries, spacing } from '../../styling';
import type { ReeferTheme, TypographyVariant } from '../../types';
import { getColorValue } from '../../utils/componentStyles';
import { Box } from '../box';

type StyleProps<T extends string = TypographyVariant> = TypographyProps<T>;

interface StylePropsWithTheme<T extends string = TypographyVariant>
  extends StyleProps<T> {
  theme: ReeferTheme;
}

const maxLineStyles = (maxLines?: number): CSSObject => {
  if (maxLines === undefined) {
    return {};
  }

  if (maxLines === 1) {
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  }

  return {
    [`@supports (-webkit-line-clamp: ${maxLines})`]: {
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: maxLines,
      display: '-webkit-box',
      overflow: 'hidden',
    },
  };
};

const truncate = (
  truncateAt?: string,
  whiteSpace?: CSSObject['whiteSpace']
): CSSObject => {
  return truncateAt
    ? {
        maxWidth: truncateAt,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: whiteSpace || 'nowrap',
      }
    : {};
};

export const getStyles = <T extends string = TypographyVariant>({
  branded,
  color,
  maxLines,
  strikeThrough,
  textAlign,
  theme,
  truncateAt,
  variant,
  whiteSpace,
  ...marginProps
}: StylePropsWithTheme<T>) => {
  const { fonts, variants } = theme.components.Typography;
  const variantStyles = variants[variant || 'body'];
  const {
    desktopStyles,
    mobileStyles,
    branded: originalBranded,
    ...sharedStyles
  } = variantStyles;

  const isBranded = branded !== undefined ? branded : originalBranded;

  const fontFamily = isBranded
    ? fonts.branded.fontFamily
    : fonts.default.fontFamily;

  const styles: CSSObject = {
    color: getColorValue(color || 'text-main', theme),
    fontFamily: `${fontFamily}, Source Sans Pro, Helvetica, Arial, sans-serif`,
    ...(fontFamily?.includes('Jane Default') && {
      webkitFontFeatureSettings: "'ss01' 1, 'ss02' 1, 'ss03' 1",
      mozFontFeatureSettings: "'ss01' 1, 'ss02' 1, 'ss03' 1",
      fontFeatureSettings: "'ss01' 1, 'ss02' 1, 'ss03' 1",
    }),
    ...(variant === 'title-bold' &&
      !isBranded && {
        fontWeight: 700,
      }),
    ...spacing(marginProps),
    ...truncate(truncateAt, whiteSpace),
    ...sharedStyles,
    ...mobileStyles,
    ...(typeof maxLines === 'number' && maxLineStyles(maxLines)),
    [mediaQueries.desktop('sm', 'min')]: desktopStyles,
    textAlign,
    textDecoration: strikeThrough ? 'line-through' : undefined,
    ...(whiteSpace && { whiteSpace }),
  };

  return styles;
};

export const InnerStyledTypography = (<
  T extends string = TypographyVariant
>() => styled(Box)<StyleProps<T>>((props) => getStyles(props)))();

export const StyledTypography = <T extends string = TypographyVariant>({
  variant,
  ...rest
}: TypographyProps<T>) => (
  <InnerStyledTypography variant={variant as TypographyVariant} {...rest} />
);

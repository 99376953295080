import styled from '@emotion/styled';

export const StyledImage = styled.div(({ onClick }) => ({
  ...(onClick && {
    ':focus-visible': {
      filter: 'grayscale(0.5) opacity(0.7)',
      transition: '250ms all',
    },
    cursor: 'pointer',
    outline: 'none',
  }),
}));

import { useEffect, useState } from 'react';

import { Storage } from '@jane/shared/util';

import type { PromptsType } from './prompt.util';

// 1 day
const DISMISS_MILLISECONDS = 24 * 60 * 60 * 1000;

export const usePrompt = (
  prompt: PromptsType,
  dismissible: boolean
): [boolean, () => void] => {
  const [showPrompt, setShowPrompt] = useState(false);

  const dismissPrompt = () => {
    const now = new Date();
    const newExpireTime = now.getTime() + DISMISS_MILLISECONDS;
    Storage.set(prompt, newExpireTime);

    setShowPrompt(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const expirationTime = Storage.get(prompt);
    const expirationReached = expirationTime
      ? expirationTime <= new Date().getTime()
      : true;

    if (expirationReached) {
      Storage.remove(prompt);
      setShowPrompt(true);

      if (!dismissible) {
        timer = setTimeout(() => {
          dismissPrompt();
        }, 10 * 1000);
      }
    }

    return () => timer && clearTimeout(timer);
  }, []);

  return [showPrompt, dismissPrompt];
};
